import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { TEquipmentGroupHistoryData, TEquipmentGroupHistoryDataResponse, adaptEquipmentGroupHistoryDataFromResponse } from './equipment-group-history-data.type';
import { adaptEquipmentGroupFromResponse, TEquipmentGroup, TEquipmentGroupResponse } from './equipment-group.type';


export const EquipmentsGroupsHistorySortCol = {
	CREATED_AT: 'created_at',
};

export type TEquipmentsGroupsHistorySortCol = TTableSortCols<typeof EquipmentsGroupsHistorySortCol>;

export type TEquipmentGroupHistoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsGroupsHistorySortCol;
};

export type TEquipmentsGroupsHistoryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TEquipmentGroupHistoryQueryPayload = {
	groupId: number;
	query: TEquipmentGroupHistoryQuery;
};

export function adaptEquipmentsGroupsHistoryQueryToRequest(data?: TEquipmentGroupHistoryQuery): TEquipmentsGroupsHistoryQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentGroupHistory = {
	id: number;
	description?: string;
	createdAt: string;

	group: TEquipmentGroup;
	dataBefore?: TEquipmentGroupHistoryData;
	dataAfter?: TEquipmentGroupHistoryData;
	createdBy: TUserMeta;
};

export type TEquipmentGroupHistoryResponse = {
	id: number;
	description?: string;
	created_at: string;

	group: TEquipmentGroupResponse;
	data_before?: TEquipmentGroupHistoryDataResponse;
	data_after?: TEquipmentGroupHistoryDataResponse;
	created_by: TUserMetaResponse;
};

export type TEquipmentGroupHistoryListResponse = TListResponse<TEquipmentGroupHistoryResponse>;

export type TEquipmentGroupHistoryList = TListResponse<TEquipmentGroupHistory>;

export type TEquipmentGroupHistoryParams = {
	groupId: number;
	id: number;
};

export function adaptEquipmentGroupHistoryFromListResponse(data: TEquipmentGroupHistoryListResponse): TEquipmentGroupHistoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentGroupHistoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentGroupHistoryFromResponse(data: TEquipmentGroupHistoryResponse): TEquipmentGroupHistory {
	const {
		id,
		description,
		created_at: createdAt,

		group: groupRaw,
		data_before: dataBeforeRaw,
		data_after: dataAfterRaw,

		created_by: createdByRaw,
	} = data;

	const group = adaptEquipmentGroupFromResponse(groupRaw);
	const dataBefore = dataBeforeRaw ? adaptEquipmentGroupHistoryDataFromResponse(dataBeforeRaw) : undefined;
	const dataAfter = dataAfterRaw ? adaptEquipmentGroupHistoryDataFromResponse(dataAfterRaw) : undefined;
	const createdBy = adaptUserMetaFromResponse(createdByRaw);

	return {
		id,
		description,
		createdAt,

		group,
		dataBefore,
		dataAfter,

		createdBy,
	};
}
