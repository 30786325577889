import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const SOURCE_USER_CATEGORY_RATE = '1';
export const SOURCE_USER_CATEGORY_INDIVIDUAL = '2';
export const SOURCE_USER_CATEGORY_DEFAULT = SOURCE_USER_CATEGORY_RATE;

export const UsersCategoriesSortCol = {
	NAME: 'name',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersCategoriesSortCol = TTableSortCols<typeof UsersCategoriesSortCol>;

export type TUserCategoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersCategoriesSortCol;
};

export type TUsersCategoriesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersCategoriesQueryToRequest(data?: TUserCategoryQuery): TUsersCategoriesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserCategory = {
  id: number;
  name: string;
  rate: number;
  description?: string;
	createdAt: string;
  updatedAt: string;
};

export type TUserCategoryResponse = {
  id: number;
  name: string;
  rate: number;
  description?: string;
  created_at: string;
  updated_at: string;
};

export type TUserCategoryListResponse = TListResponse<TUserCategoryResponse>;

export type TUserCategoryList = TListResponse<TUserCategory>;

export type TUserCategoryBodyRequest = {
	name: string;
  rate: number;
	description?: string;
};

export type TUserCategoryRequest = {
	name: string;
  rate: number;
	description?: string;
};

export type TUserCategoryUpdate = {
	id: number;
	data: TUserCategoryBodyRequest;
};

export type TUserCategorySet = {
	nickname: string;
	categoryId: number;
};

export function adaptUserCategoryFromListResponse(data: TUserCategoryListResponse): TUserCategoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserCategoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserCategoryFromResponse(data: TUserCategoryResponse): TUserCategory {
	const {
		id,
		name,
		rate,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	return {
		id,
		name,
		rate,
		description,
		createdAt,
		updatedAt,
	};
}

export function adaptUserCategoryToRequest(data: TUserCategoryBodyRequest): TUserCategoryRequest {
	const {
		name,
		rate,
		description = undefined,
	} = data;

	return {
		name,
		rate,
		description,
	};
}
