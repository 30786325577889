import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserExpenseUpdatePage = lazy(() => import('../views/update/UserExpenseUpdatePage'));

export function UserExpenseUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserExpenseUpdatePage />
		</PageSuspense>
	);
}
