import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { BACKEND_SIGN_OUT } from 'src/store/backend-paths';

import { useAppSelector } from '../../hooks';


export const signOutApi = createAsyncThunk(
	`POST${BACKEND_SIGN_OUT}`,
	(payload, thunkApi) => {
		return HttpService.post(BACKEND_SIGN_OUT, payload).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isDone?: boolean,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	isDone: undefined,
	isLoading: false,
	error: undefined,
};

export const signOutSlice = createSlice({
	name: 'signOutSlice',
	initialState,
	reducers: {
		clearSignOut(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[signOutApi.pending.type]: (state) => {
			state.isDone = false;
			state.isLoading = true;
			state.error = undefined;
		},

		[signOutApi.fulfilled.type]: (state) => {
			state.isDone = true;
			state.isLoading = false;
		},

		[signOutApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSignOutSelector = () => useAppSelector(state => state[signOutSlice.name]);

export const { clearSignOut } = signOutSlice.actions;
