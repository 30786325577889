import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TEquipmentSource, TEquipmentSourceResponse, adaptEquipmentSourceFromResponse } from './equipment-source.type';


export const EquipmentsGroupsSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsGroupsSourceSortCol = TTableSortCols<typeof EquipmentsGroupsSourceSortCol>;

export type TEquipmentGroupSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsGroupsSourceSortCol;
};

export type TEquipmentsGroupsSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsGroupsSourceQueryToRequest(data?: TEquipmentGroupSourceQuery): TEquipmentsGroupsSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentGroupSource = {
  id: number;
  name: string;
  description: string;
  amount?: number;

  equipments: TEquipmentSource[];
};

export type TEquipmentGroupSourceResponse = {
  id: number;
  name: string;
  description?: string;
  amount?: number;

  equipments: TEquipmentSourceResponse[];
};

export type TEquipmentGroupSourceListResponse = TListResponse<TEquipmentGroupSourceResponse>;

export type TEquipmentGroupSourceList = TListResponse<TEquipmentGroupSource>;

export function adaptEquipmentGroupSourceFromListResponse(data: TEquipmentGroupSourceListResponse): TEquipmentGroupSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentGroupSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentGroupSourceFromResponse(data: TEquipmentGroupSourceResponse): TEquipmentGroupSource {
	const {
		id,
		name,
		description = '',
		amount,

		equipments: equipmentsRaw = [],
	} = data;

	const equipments = equipmentsRaw.map(item => adaptEquipmentSourceFromResponse(item));

	return {
		id,
		name,
		description,
		amount,
		equipments,
	};
}
