import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Preloader } from 'src/components';
import { clearSignUpAction, signUpEmailConfirmApi, useAppDispatch, useSignUpSelector } from 'src/store';
import { isInvalidHashcode } from 'src/toolkit';
import { ErrorWidget } from 'src/pages/components';
import { setTitle } from 'src/common/helpers';
import { PATH_SIGN_IN } from 'src/routes';

import { AuthLayer, SignUpStageSuccess } from '../../components';


export function SignUpEmailConfirmPage() {
	const dispatch = useAppDispatch();
	const { isLoading, error } = useSignUpSelector();
	const { hashcode = '' } = useParams();

	const isInvalidHash = isInvalidHashcode(hashcode, 128);

	useEffect(() => {
		if (!isInvalidHash) {
			dispatch(signUpEmailConfirmApi(hashcode));
		}

		return () => {
			dispatch(clearSignUpAction());
		};
	}, []);

	if (isInvalidHash) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading) {
		return (
			<Preloader noLayout />
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle('Авторизация');

	return (
		<AuthLayer
			contentLinkPath={ PATH_SIGN_IN }
			headline="Вход в аккаунт"
			contentLeftHalf="Вы можете авторизироваться, если у вас уже есть аккаунт, или "
			contentLink="Зарегистрироваться"
			contentRightHalf=" если вы этого ещё не сделали."
		>
			<SignUpStageSuccess
				headline="Регистрация завершена и Ваш аккаунт подтвержён успешно!"
			/>
		</AuthLayer>
	);
}

