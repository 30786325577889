import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ProductionCalendarsSortCol = {
	YEAR_NUMBER: 'year_number',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProductionCalendarsSortCol = TTableSortCols<typeof ProductionCalendarsSortCol>;

export type TProductionCalendarQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProductionCalendarsSortCol;
};

export type TProductionCalendarsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProductionCalendar = {
  yearNumber: number;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProductionCalendarResponse = {
  year_number: number;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProductionCalendarListResponse = TListResponse<TProductionCalendarResponse>;

export type TProductionCalendarList = TListResponse<TProductionCalendar>;

export type TProductionCalendarBodyRequest = {
	yearNumber: number;
};

export type TProductionCalendarRequest = {
	year_number: number;
};

export type TProductionCalendarUpdate = {
	id: number;
	data: TProductionCalendarBodyRequest;
};

export function adaptProductionCalendarFromListResponse(data: TProductionCalendarListResponse): TProductionCalendarList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProductionCalendarFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProductionCalendarFromResponse(data: TProductionCalendarResponse): TProductionCalendar {
	const {
		year_number: yearNumber,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		yearNumber,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptProductionCalendarToRequest(data: TProductionCalendarBodyRequest): TProductionCalendarRequest {
	const {
		yearNumber,
	} = data;

	return {
		year_number: yearNumber,
	};
}
