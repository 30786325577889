// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageDescription_pageDescription__fqAkq {\n  color: rgb(80, 130, 130);\n  font-size: 1em;\n  margin: 0.5em auto 2em 0.5em;\n  text-indent: 1em;\n}\n\n.PageDescription_margin__DDcTf {\n  margin: 0.5em auto 1.5em;\n}\n\n.PageDescription_small__7MFjk {\n  font-size: 1em;\n}\n\n.PageDescription_center__Dhsi3 {\n  text-align: center;\n}\n\n.PageDescription_bold__\\+EqvW {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/page/page-description/PageDescription.module.scss"],"names":[],"mappings":"AAAA;EACC,wBAAA;EACA,cAAA;EACA,4BAAA;EACA,gBAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".pageDescription {\n\tcolor: rgba(80, 130, 130, 1);\n\tfont-size: 1em;\n\tmargin: 0.5em auto 2em 0.5em;\n\ttext-indent: 1em;\n}\n\n.margin {\n\tmargin: 0.5em auto 1.5em;\n}\n\n.small {\n\tfont-size: 1em;\n}\n\n.center {\n\ttext-align: center;\n}\n\n.bold {\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageDescription": "PageDescription_pageDescription__fqAkq",
	"margin": "PageDescription_margin__DDcTf",
	"small": "PageDescription_small__7MFjk",
	"center": "PageDescription_center__Dhsi3",
	"bold": "PageDescription_bold__+EqvW"
};
export default ___CSS_LOADER_EXPORT___;
