import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserInternationalPassport, TUserInternationalPassportResponse, adaptUserInternationalPassportFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userInternationalPassportReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/international-passport`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/international-passport`;

		return HttpService.get<TUserInternationalPassportResponse>(path).then(result => {
			return adaptUserInternationalPassportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserInternationalPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userInternationalPassportReadSlice = createSlice({
	name: 'userInternationalPassportReadSlice',
	initialState,
	reducers: {
		setUserInternationalPassportRead(state, action: PayloadAction<TUserInternationalPassport>) {
			state.item = action.payload;
		},

		clearUserInternationalPassportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userInternationalPassportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userInternationalPassportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserInternationalPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userInternationalPassportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserInternationalPassportReadSelector = () => useAppSelector(state => state[userInternationalPassportReadSlice.name]);

export const { clearUserInternationalPassportRead, setUserInternationalPassportRead } = userInternationalPassportReadSlice.actions;
