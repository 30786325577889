import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingBookkeepingWorkDayHoursCount, TSystemSettingBookkeepingWorkDayHoursCountPayload, TSystemSettingBookkeepingWorkDayHoursCountResponse, adaptSystemSettingBookkeepingWorkDayHoursCountFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT } from '../../backend-paths';


export const systemSettingBookkeepingWorkDayHoursCountUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT}`,
	(payload: TSystemSettingBookkeepingWorkDayHoursCountPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT;

		return HttpService.put<TSystemSettingBookkeepingWorkDayHoursCountResponse>(path, payload).then(result => {
			return adaptSystemSettingBookkeepingWorkDayHoursCountFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingBookkeepingWorkDayHoursCount> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingBookkeepingWorkDayHoursCountUpdateSlice = createSlice({
	name: 'systemSettingBookkeepingWorkDayHoursCountUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingBookkeepingWorkDayHoursCountUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingBookkeepingWorkDayHoursCountUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingBookkeepingWorkDayHoursCountUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingBookkeepingWorkDayHoursCount>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingBookkeepingWorkDayHoursCountUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingBookkeepingWorkDayHoursCountUpdateSelector = () => useAppSelector(state => state[systemSettingBookkeepingWorkDayHoursCountUpdateSlice.name]);

export const { clearSystemSettingBookkeepingWorkDayHoursCountUpdate } = systemSettingBookkeepingWorkDayHoursCountUpdateSlice.actions;
