import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserShiftReportReadPage = lazy(() => import('../views/read/UserShiftReportReadPage'));

export function UserShiftReportReadPageLazy() {
	return (
		<PageSuspense>
			<UserShiftReportReadPage />
		</PageSuspense>
	);
}
