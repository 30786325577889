import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountCategoryChangesPage = lazy(() => import('../views/list/AccountCategoryChangesPage'));

export function AccountCategoryChangesPageLazy() {
	return (
		<PageSuspense>
			<AccountCategoryChangesPage />
		</PageSuspense>
	);
}
