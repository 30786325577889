import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserOverwork, TUserOverworkResponse, adaptUserOverworkFromResponse } from '../../types';
import { BACKEND_USERS_OVERWORKS } from '../../backend-paths';


export const userOverworkReadGetApi = createAsyncThunk(
	`GET/${BACKEND_USERS_OVERWORKS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_USERS_OVERWORKS}/${payload}`;

		return HttpService.get<TUserOverworkResponse>(path).then(result => {
			return adaptUserOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userOverworkReadSlice = createSlice({
	name: 'userOverworkReadSlice',
	initialState,
	reducers: {
		setUserOverworkRead(state, action: PayloadAction<TUserOverwork>) {
			state.item = action.payload;
		},

		clearUserOverworkRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userOverworkReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userOverworkReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userOverworkReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserOverworkReadSelector = () => useAppSelector(state => state[userOverworkReadSlice.name]);

export const { clearUserOverworkRead, setUserOverworkRead } = userOverworkReadSlice.actions;
