// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormTextareaFieldReadonly_nowrap__tARPf {\n  white-space: nowrap;\n}\n\n.FormTextareaFieldReadonly_colorRed__1szxq {\n  color: rgb(200, 50, 50) !important;\n}\n.FormTextareaFieldReadonly_colorRed__1szxq:focus, .FormTextareaFieldReadonly_colorRed__1szxq:active, .FormTextareaFieldReadonly_colorRed__1szxq:hover {\n  color: rgb(200, 50, 50) !important;\n}\n\n.FormTextareaFieldReadonly_colorGreen__lbqiG {\n  color: rgb(50, 200, 50) !important;\n}\n.FormTextareaFieldReadonly_colorGreen__lbqiG:focus, .FormTextareaFieldReadonly_colorGreen__lbqiG:active, .FormTextareaFieldReadonly_colorGreen__lbqiG:hover {\n  color: rgb(50, 200, 50) !important;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-textarea-field-readonly/FormTextareaFieldReadonly.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD;;AAEA;EACC,kCAAA;AACD;AACC;EAGC,kCAAA;AADF;;AAKA;EACC,kCAAA;AAFD;AAIC;EAGC,kCAAA;AAJF","sourcesContent":[".nowrap {\n\twhite-space: nowrap;\n}\n\n.colorRed {\n\tcolor: rgba(200, 50, 50, 1) !important;\n\n\t&:focus,\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(200, 50, 50, 1) !important;\n\t}\n}\n\n.colorGreen {\n\tcolor: rgba(50, 200, 50, 1) !important;\n\n\t&:focus,\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(50, 200, 50, 1) !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nowrap": "FormTextareaFieldReadonly_nowrap__tARPf",
	"colorRed": "FormTextareaFieldReadonly_colorRed__1szxq",
	"colorGreen": "FormTextareaFieldReadonly_colorGreen__lbqiG"
};
export default ___CSS_LOADER_EXPORT___;
