import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const FinancialChangesTypesSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TFinancialChangesTypesSortCol = TTableSortCols<typeof FinancialChangesTypesSortCol>;

export type TFinancialChangeTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TFinancialChangesTypesSortCol;
};

export type TFinancialChangesTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptFinancialChangesTypesQueryToRequest(data?: TFinancialChangeTypeQuery): TFinancialChangesTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TFinancialChangeType = {
  id: number;
  name: string;
  description?: string;
	isNegative: boolean;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TFinancialChangeTypeResponse = {
  id: number;
  name: string;
  description?: string;
	is_negative: boolean;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TFinancialChangeTypeListResponse = TListResponse<TFinancialChangeTypeResponse>;

export type TFinancialChangeTypeList = TListResponse<TFinancialChangeType>;

export type TFinancialChangeTypeBodyRequest = {
	name: string;
	description?: string;
	isNegative: boolean;
};

export type TFinancialChangeTypeRequest = {
	name: string;
	description?: string;
	is_negative: boolean;
};

export type TFinancialChangeTypeUpdate = {
	id: number;
	data: TFinancialChangeTypeBodyRequest;
};

export function adaptFinancialChangeTypeFromListResponse(data: TFinancialChangeTypeListResponse): TFinancialChangeTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptFinancialChangeTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptFinancialChangeTypeFromResponse(data: TFinancialChangeTypeResponse): TFinancialChangeType {
	const {
		id,
		name,
		description,
		is_negative: isNegative,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		isNegative,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptFinancialChangeTypeToRequest(data: TFinancialChangeTypeBodyRequest): TFinancialChangeTypeRequest {
	const {
		name,
		description = undefined,
		isNegative,
	} = data;

	return {
		name,
		description,
		is_negative: isNegative,
	};
}
