import { useEffect } from 'react';
import { PATH_SIGN_IN } from 'src/routes';
import { clearSignUpAction, useAppDispatch } from 'src/store';
import { setTitle } from 'src/common/helpers';

import { AuthLayer } from '../../components';

import { SignUpRouter } from './components';


export function SignUpPage() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearSignUpAction());
		};
	}, []);

	setTitle('Регистрация');

	return (
		<AuthLayer
			contentLinkPath={ PATH_SIGN_IN }
			headline="Регистрация аккаунта"
			contentLeftHalf="Вы можете зарегистрироваться, если ещё не сделали этого, или "
			contentLink="Войти"
			contentRightHalf=" в аккаунт, если уже зарегистрировались."
		>
			<SignUpRouter />
		</AuthLayer>
	);
}

