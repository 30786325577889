import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountCredentialResponse, TAccountCredentials, adaptAccountCredentialsFromResponse } from 'src/store/types';
import { BACKEND_ACCOUNT_CREDENTIALS } from 'src/store/backend-paths';

import { useAppSelector } from '../../hooks';


export const accountCredentialsApi = createAsyncThunk(
	`FETCH${BACKEND_ACCOUNT_CREDENTIALS}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountCredentialResponse>(BACKEND_ACCOUNT_CREDENTIALS).then(result => {
			return adaptAccountCredentialsFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	credentials?: TAccountCredentials;
	isLoading: boolean;
	error?: HttpError;
};

const initialState: TInitialState = {
	credentials: undefined,
	isLoading: true,
	error: undefined,
};

export const accountCredentialsSlice = createSlice({
	name: 'accountCredentialsSlice',
	initialState,
	reducers: {
		setAccountCredentials(state, action: PayloadAction<TAccountCredentials>) {
			const credentials = { ...state.credentials, ...action.payload };

			return { ...initialState, credentials };
		},

		clearAccountCredentials(state) {
			return { ...initialState, isLoading: false };
		},
	},

	extraReducers: {
		[accountCredentialsApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountCredentialsApi.fulfilled.type]: (state, action: PayloadAction<TAccountCredentials>) => {
			state.isLoading = false;
			state.credentials = action.payload;
		},

		[accountCredentialsApi.rejected.type]: (state) => {
			state.isLoading = false;
		},
	},
});

export const useAccountCredentialsSelector = () => useAppSelector(state => state[accountCredentialsSlice.name]);
export const useAccountRolesSelector = () => useAppSelector(state => {
	return state[accountCredentialsSlice.name].credentials?.permissions ?? [];
});

export const { setAccountCredentials, clearAccountCredentials } = accountCredentialsSlice.actions;
