import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TGroupShiftExpenseType, TGroupShiftExpenseTypeResponse, adaptGroupShiftExpenseTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES } from '../../backend-paths';


export const groupShiftExpenseTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES}/${payload}`;

		return HttpService.get<TGroupShiftExpenseTypeResponse>(path).then(result => {
			return adaptGroupShiftExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TGroupShiftExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const groupShiftExpenseTypeReadSlice = createSlice({
	name: 'groupShiftExpenseTypeReadSlice',
	initialState,
	reducers: {
		setGroupShiftExpenseTypeRead(state, action: PayloadAction<TGroupShiftExpenseType>) {
			state.item = action.payload;
		},

		clearGroupShiftExpenseTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[groupShiftExpenseTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[groupShiftExpenseTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[groupShiftExpenseTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useGroupShiftExpenseTypeReadSelector = () => useAppSelector(state => state[groupShiftExpenseTypeReadSlice.name]);

export const { clearGroupShiftExpenseTypeRead, setGroupShiftExpenseTypeRead } = groupShiftExpenseTypeReadSlice.actions;
