import { MouseEventHandler } from 'react';
import { AsideItem, TAsideItem } from '../aside-item/AsideItem';

import styles from './AsideItemList.module.scss';


export type TAsideItemList = {
	items: TAsideItem[];
	onClick: MouseEventHandler<HTMLElement>;
	scroll: number;
};

export function AsideItemList(props: TAsideItemList) {
	const { items, onClick, scroll } = props;

	return (
		<div className={ styles.list }>
			{
				items.map((item, index) => <AsideItem { ...item } key={ index } onClick={ onClick } scroll={ scroll } />)
			}
		</div>
	);
}
