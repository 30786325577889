import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountShiftExpense, TAccountShiftExpensePayloadCreate, TAccountShiftExpenseResponse, adaptAccountShiftExpenseFromResponse, adaptAccountShiftExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFTS, BACKEND_ACCOUNT_SHIFT_EXPENSE } from '../../backend-paths';


export const accountShiftExpenseCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_SHIFTS}/:shiftId/expenses`,
	(payload: TAccountShiftExpensePayloadCreate, thunkApi) => {
		const { data, shiftId, files } = payload;
		const path = `${BACKEND_ACCOUNT_SHIFTS}/${shiftId}/expenses`;

		const dataRequest = adaptAccountShiftExpenseToRequest(data);

		return HttpService.post<TAccountShiftExpenseResponse>(path, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ACCOUNT_SHIFT_EXPENSE}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountShiftExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountShiftExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftExpenseCreateSlice = createSlice({
	name: 'accountShiftExpenseCreateSlice',
	initialState,
	reducers: {
		clearAccountShiftExpenseCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftExpenseCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountShiftExpenseCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftExpenseCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountShiftExpenseCreateSelector = () => useAppSelector(state => state[accountShiftExpenseCreateSlice.name]);

export const { clearAccountShiftExpenseCreate } = accountShiftExpenseCreateSlice.actions;
