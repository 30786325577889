import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserWorkData, TUserWorkDataResponse, adaptUserWorkDataFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userWorkDataReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/work-data`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/work-data`;

		return HttpService.get<TUserWorkDataResponse>(path).then(result => {
			return adaptUserWorkDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserWorkData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userWorkDataReadSlice = createSlice({
	name: 'userWorkDataReadSlice',
	initialState,
	reducers: {
		setUserWorkDataRead(state, action: PayloadAction<TUserWorkData>) {
			state.item = action.payload;
		},

		clearUserWorkDataRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userWorkDataReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userWorkDataReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserWorkData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userWorkDataReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserWorkDataReadSelector = () => useAppSelector(state => state[userWorkDataReadSlice.name]);

export const { clearUserWorkDataRead, setUserWorkDataRead } = userWorkDataReadSlice.actions;
