import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_PHONE_NUMBER } from '../../backend-paths';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountPhoneNumber, TAccountPhoneNumberResponse, TAccountPhoneNumberUpdate, adaptAccountPhoneNumberFromResponse, adaptAccountPhoneNumberToRequest } from '../../types';


export const accountPhoneNumberUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_PHONE_NUMBER}`,
	(payload: TAccountPhoneNumberUpdate, thunkApi) => {
		const body = adaptAccountPhoneNumberToRequest(payload);

		return HttpService.put<TAccountPhoneNumberResponse>(BACKEND_ACCOUNT_PHONE_NUMBER, body).then(result => {
			return adaptAccountPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = TSliceInitialState<TAccountPhoneNumber> & {
	noticeConfirmed: string;
};

const initialState: TInitialState = {
	item: undefined,
	noticeConfirmed: '',
	isLoading: false,
	error: undefined,
};

export const accountPhoneNumberUpdateSlice = createSlice({
	name: 'accountPhoneNumberUpdateSlice',
	initialState,
	reducers: {
		clearAccountPhoneNumberUpdate(state) {
			return { ...initialState };
		},

		setAccountPhoneNumberNoticeUpdate(state, action: PayloadAction<string>) {
			state.noticeConfirmed = action.payload;
		},
	},

	extraReducers: {
		[accountPhoneNumberUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPhoneNumberUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountPhoneNumberUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountPhoneNumberUpdateSelector = () => useAppSelector(state => state[accountPhoneNumberUpdateSlice.name]);

export const { clearAccountPhoneNumberUpdate, setAccountPhoneNumberNoticeUpdate } = accountPhoneNumberUpdateSlice.actions;
