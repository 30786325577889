import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectStatus, TProjectStatusRequest, TProjectStatusResponse, adaptProjectStatusFromResponse, adaptProjectStatusToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_STATUSES } from '../../backend-paths';


export const projectStatusCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_PROJECTS_STATUSES}`,
	(payload: TProjectStatusRequest, thunkApi) => {
		const dataRequest = adaptProjectStatusToRequest(payload);

		return HttpService.post<TProjectStatusResponse>(BACKEND_ADMIN_PROJECTS_STATUSES, dataRequest).then(result => {
			return adaptProjectStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectStatusCreateSlice = createSlice({
	name: 'projectStatusCreateSlice',
	initialState,
	reducers: {
		clearProjectStatusCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectStatusCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectStatusCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectStatusCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectStatusCreateSelector = () => useAppSelector(state => state[projectStatusCreateSlice.name]);

export const { clearProjectStatusCreate } = projectStatusCreateSlice.actions;
