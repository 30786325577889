import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectStatus, TProjectStatusUpdate, TProjectStatusResponse, adaptProjectStatusFromResponse, adaptProjectStatusToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_STATUSES } from '../../backend-paths';


export const projectStatusUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_PROJECTS_STATUSES}/:id`,
	(payload: TProjectStatusUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_PROJECTS_STATUSES}/${id}`;
		const dataRequest = adaptProjectStatusToRequest(data);

		return HttpService.put<TProjectStatusResponse>(path, dataRequest).then(result => {
			return adaptProjectStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectStatusUpdateSlice = createSlice({
	name: 'projectStatusUpdateSlice',
	initialState,
	reducers: {
		clearProjectStatusUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectStatusUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectStatusUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectStatusUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectStatusUpdateSelector = () => useAppSelector(state => state[projectStatusUpdateSlice.name]);

export const { clearProjectStatusUpdate } = projectStatusUpdateSlice.actions;
