import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroupShift, TProjectOrderGroupShiftPayloadCreate, TProjectOrderGroupShiftResponse, adaptProjectOrderGroupShiftFromResponse, adaptProjectOrderGroupShiftToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupShiftCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts`,
	(payload: TProjectOrderGroupShiftPayloadCreate, thunkApi) => {
		const { groupId, orderId, projectId, data } = payload;
		const dataRequest = adaptProjectOrderGroupShiftToRequest(data);
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts`;

		return HttpService.post<TProjectOrderGroupShiftResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderGroupShiftFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShift> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftCreateSlice = createSlice({
	name: 'projectOrderGroupShiftCreateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupShiftCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectOrderGroupShiftCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShift>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectOrderGroupShiftCreateSelector = () => useAppSelector(state => state[projectOrderGroupShiftCreateSlice.name]);

export const { clearProjectOrderGroupShiftCreate } = projectOrderGroupShiftCreateSlice.actions;
