import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './FormSelectRangeBoxField.module.scss';


export type TFormSelectRangeBoxFieldListOption = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	selected?: boolean;
};

export function FormSelectRangeBoxFieldListItem(props: TProps) {
	const {
		children,
		selected = false,
		...restProps
	} = props;

	const classNames = [styles.item];

	if (selected) {
		classNames.push(styles.selected);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			{ children }
		</div>
	);
}
