import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountPage = lazy(() => import('../views/update/AccountPage'));

export function AccountPageLazy() {
	return (
		<PageSuspense>
			<AccountPage />
		</PageSuspense>
	);
}
