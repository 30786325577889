import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { TEquipmentOrderHistoryData, TEquipmentOrderHistoryDataResponse, adaptEquipmentOrderHistoryDataFromResponse } from './equipment-order-history-data.type';
import { adaptEquipmentOrderFromResponse } from './equipment-order.type';
import { TEquipmentOrder, TEquipmentOrderResponse } from './equipment-order.type';


export const EquipmentsOrdersHistorySortCol = {
	CREATED_AT: 'created_at',
};

export type TEquipmentsOrdersHistorySortCol = TTableSortCols<typeof EquipmentsOrdersHistorySortCol>;

export type TEquipmentOrderHistoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsOrdersHistorySortCol;
};

export type TEquipmentsOrdersHistoryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TEquipmentOrderHistoryQueryPayload = {
	orderId: number;
	query: TEquipmentOrderHistoryQuery;
};

export function adaptEquipmentsOrdersHistoryQueryToRequest(data?: TEquipmentOrderHistoryQuery): TEquipmentsOrdersHistoryQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentOrderHistory = {
	id: number;
	description?: string;
	createdAt: string;

	order: TEquipmentOrder;
	dataBefore?: TEquipmentOrderHistoryData;
	dataAfter?: TEquipmentOrderHistoryData;
	createdBy: TUserMeta;
};

export type TEquipmentOrderHistoryResponse = {
	id: number;
	description?: string;
	created_at: string;

	order: TEquipmentOrderResponse;
	data_before?: TEquipmentOrderHistoryDataResponse;
	data_after?: TEquipmentOrderHistoryDataResponse;
	created_by: TUserMetaResponse;
};

export type TEquipmentOrderHistoryListResponse = TListResponse<TEquipmentOrderHistoryResponse>;

export type TEquipmentOrderHistoryList = TListResponse<TEquipmentOrderHistory>;

export type TEquipmentOrderHistoryParams = {
	orderId: number;
	id: number;
};

export function adaptEquipmentOrderHistoryFromListResponse(data: TEquipmentOrderHistoryListResponse): TEquipmentOrderHistoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentOrderHistoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentOrderHistoryFromResponse(data: TEquipmentOrderHistoryResponse): TEquipmentOrderHistory {
	const {
		id,
		description,
		created_at: createdAt,

		order: orderRaw,
		data_before: dataBeforeRaw,
		data_after: dataAfterRaw,

		created_by: createdByRaw,
	} = data;

	const order = adaptEquipmentOrderFromResponse(orderRaw);
	const dataBefore = dataBeforeRaw ? adaptEquipmentOrderHistoryDataFromResponse(dataBeforeRaw) : undefined;
	const dataAfter = dataAfterRaw ? adaptEquipmentOrderHistoryDataFromResponse(dataAfterRaw) : undefined;
	const createdBy = adaptUserMetaFromResponse(createdByRaw);

	return {
		id,
		description,
		createdAt,

		order,
		dataBefore,
		dataAfter,

		createdBy,
	};
}
