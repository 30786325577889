import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ArticlesPage = lazy(() => import('../views/list/ArticlesPage'));

export function ArticlesPageLazy() {
	return (
		<PageSuspense>
			<ArticlesPage />
		</PageSuspense>
	);
}
