import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserExpenseType, TUserExpenseTypeResponse, adaptUserExpenseTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_EXPENSES_TYPES } from '../../backend-paths';


export const userExpenseTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_EXPENSES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_EXPENSES_TYPES}/${payload}`;

		return HttpService.get<TUserExpenseTypeResponse>(path).then(result => {
			return adaptUserExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseTypeReadSlice = createSlice({
	name: 'userExpenseTypeReadSlice',
	initialState,
	reducers: {
		setUserExpenseTypeRead(state, action: PayloadAction<TUserExpenseType>) {
			state.item = action.payload;
		},

		clearUserExpenseTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userExpenseTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserExpenseTypeReadSelector = () => useAppSelector(state => state[userExpenseTypeReadSlice.name]);

export const { clearUserExpenseTypeRead, setUserExpenseTypeRead } = userExpenseTypeReadSlice.actions;
