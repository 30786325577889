import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProject, adaptProjectFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PROJECTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PROJECTS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectDeleteSlice = createSlice({
	name: 'projectDeleteSlice',
	initialState,
	reducers: {
		clearProjectDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectDeleteSelector = () => useAppSelector(state => state[projectDeleteSlice.name]);

export const { clearProjectDelete } = projectDeleteSlice.actions;
