import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingCalendarEventColorBirthday, TSystemSettingCalendarEventColorBirthdayPayload, TSystemSettingCalendarEventColorBirthdayResponse, adaptSystemSettingCalendarEventColorBirthdayFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY } from '../../backend-paths';


export const systemSettingCalendarEventColorBirthdayUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY}`,
	(payload: TSystemSettingCalendarEventColorBirthdayPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY;

		return HttpService.put<TSystemSettingCalendarEventColorBirthdayResponse>(path, payload).then(result => {
			return adaptSystemSettingCalendarEventColorBirthdayFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingCalendarEventColorBirthday> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingCalendarEventColorBirthdayUpdateSlice = createSlice({
	name: 'systemSettingCalendarEventColorBirthdayUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingCalendarEventColorBirthdayUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingCalendarEventColorBirthdayUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingCalendarEventColorBirthdayUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingCalendarEventColorBirthday>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingCalendarEventColorBirthdayUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingCalendarEventColorBirthdayUpdateSelector = () => useAppSelector(state => state[systemSettingCalendarEventColorBirthdayUpdateSlice.name]);

export const { clearSystemSettingCalendarEventColorBirthdayUpdate } = systemSettingCalendarEventColorBirthdayUpdateSlice.actions;
