import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountShiftReport, TAccountShiftReportPayloadCreate, TAccountShiftReportResponse, adaptAccountShiftReportFromResponse, adaptAccountShiftReportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftReportCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_SHIFTS}/:shiftId/report`,
	(payload: TAccountShiftReportPayloadCreate, thunkApi) => {
		const { data, shiftId, files } = payload;
		const path = `${BACKEND_ACCOUNT_SHIFTS}/${shiftId}/report`;

		const dataRequest = adaptAccountShiftReportToRequest(data);

		return HttpService.post<TAccountShiftReportResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountShiftReportResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountShiftReportFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftReportCreateSlice = createSlice({
	name: 'accountShiftReportCreateSlice',
	initialState,
	reducers: {
		clearAccountShiftReportCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftReportCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountShiftReportCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftReportCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountShiftReportCreateSelector = () => useAppSelector(state => state[accountShiftReportCreateSlice.name]);

export const { clearAccountShiftReportCreate } = accountShiftReportCreateSlice.actions;
