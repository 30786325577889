import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersExpensesTypesPage = lazy(() => import('../views/list/UsersExpensesTypesPage'));

export function UsersExpensesTypesPageLazy() {
	return (
		<PageSuspense>
			<UsersExpensesTypesPage />
		</PageSuspense>
	);
}
