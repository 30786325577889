import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingShiftPage = lazy(() => import('../views/list/SystemSettingShiftPage'));

export function SystemSettingShiftPageLazy() {
	return (
		<PageSuspense>
			<SystemSettingShiftPage />
		</PageSuspense>
	);
}
