import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersCategoriesPage = lazy(() => import('../views/list/UsersCategoriesPage'));

export function UsersCategoriesPageLazy() {
	return (
		<PageSuspense>
			<UsersCategoriesPage />
		</PageSuspense>
	);
}
