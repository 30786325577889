// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignUpStageSuccess_succesContainer__At8kR {\n  background-color: rgb(10, 10, 10);\n  border: 1px solid rgb(60, 60, 60);\n  box-sizing: border-box;\n  margin: auto;\n  padding: 1em;\n  max-width: 480px;\n}\n\n.SignUpStageSuccess_paragraph__Kw5Wg {\n  color: rgb(90, 90, 90);\n  text-align: center;\n}\n\n.SignUpStageSuccess_percepted__vbHX\\+ {\n  color: rgb(0, 150, 50);\n}\n\n.SignUpStageSuccess_button__R4rbJ {\n  margin: 1em 0 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/auth/components/success-stage/SignUpStageSuccess.module.scss"],"names":[],"mappings":"AAAA;EACC,iCAAA;EACA,iCAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EAEA,gBAAA;AAAD;;AAGA;EACC,sBAAA;EACA,kBAAA;AAAD;;AAGA;EACC,sBAAA;AAAD;;AAGA;EACC,eAAA;AAAD","sourcesContent":[".succesContainer {\n\tbackground-color: rgba(10, 10, 10, 1);\n\tborder: 1px solid rgba(60, 60, 60, 1);\n\tbox-sizing: border-box;\n\tmargin: auto;\n\tpadding: 1em;\n\n\tmax-width: 480px;\n}\n\n.paragraph {\n\tcolor: rgba(90, 90, 90, 1);\n\ttext-align: center;\n}\n\n.percepted {\n\tcolor: rgba(0, 150, 50, 1);\n}\n\n.button {\n\tmargin: 1em 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"succesContainer": "SignUpStageSuccess_succesContainer__At8kR",
	"paragraph": "SignUpStageSuccess_paragraph__Kw5Wg",
	"percepted": "SignUpStageSuccess_percepted__vbHX+",
	"button": "SignUpStageSuccess_button__R4rbJ"
};
export default ___CSS_LOADER_EXPORT___;
