import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TScheduleEvent, TScheduleEventResponse, adaptScheduleEventFromResponse } from '../../types';
import { BACKEND_ADMIN_SCHEDULE_EVENTS } from '../../backend-paths';


export const scheduleEventReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SCHEDULE_EVENTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_SCHEDULE_EVENTS}/${payload}`;

		return HttpService.get<TScheduleEventResponse>(path).then(result => {
			return adaptScheduleEventFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEvent> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventReadSlice = createSlice({
	name: 'scheduleEventReadSlice',
	initialState,
	reducers: {
		setScheduleEventRead(state, action: PayloadAction<TScheduleEvent>) {
			state.item = action.payload;
		},

		clearScheduleEventRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventReadGetApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEvent>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventReadSelector = () => useAppSelector(state => state[scheduleEventReadSlice.name]);

export const { clearScheduleEventRead, setScheduleEventRead } = scheduleEventReadSlice.actions;
