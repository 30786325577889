import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentCategoryUpdatePage = lazy(() => import('../views/update/EquipmentCategoryUpdatePage'));

export function EquipmentCategoryUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentCategoryUpdatePage />
		</PageSuspense>
	);
}
