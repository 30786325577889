import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserDepartmentChangeUpdatePage = lazy(() => import('../views/update/UserDepartmentChangeUpdatePage'));

export function UserDepartmentChangeUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserDepartmentChangeUpdatePage />
		</PageSuspense>
	);
}
