import { DetailedHTMLProps, HTMLAttributes, MouseEvent } from 'react';

import { FormSelectRangeBoxFieldListItem, TFormSelectRangeBoxFieldListOption } from './FormSelectRangeBoxFieldListItem';

import styles from './FormSelectRangeBoxField.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TFormSelectRangeBoxFieldListOption[];
	value: string;
	values: string[];
	setValue: (value: string) => void;
	setIsActive: (value: boolean) => void;
};

export function FormSelectRangeBoxFieldList(props: TProps) {
	const {
		value: currentValue,
		values,
		items,
		setValue,
		setIsActive,
		onBlur,
		onFocus,
		...restProps
	} = props;

	const onMousePress = (e: MouseEvent<HTMLDivElement>) => {
		if (e.button !== 0) {
			setIsActive(false);
		}
	};

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map(item => {
					const { text, value } = item;

					return (
						<FormSelectRangeBoxFieldListItem
							key={ value }
							selected={ values.includes(value) }
							onMouseDown={ e => onMousePress(e) }
							onBlur={ onBlur }
							onFocus={ onFocus }
							onClick={ e => setValue(value) }
							tabIndex={ -1 }
						>{ text }</FormSelectRangeBoxFieldListItem>
					);
				})
			}
		</div>
	);
}
