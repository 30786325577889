import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersDepartmentsPage = lazy(() => import('../views/list/UsersDepartmentsPage'));

export function UsersDepartmentsPageLazy() {
	return (
		<PageSuspense>
			<UsersDepartmentsPage />
		</PageSuspense>
	);
}
