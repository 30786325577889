import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectOrderGroupShiftReport, TProjectOrderGroupShiftReportPayload, TProjectOrderGroupShiftReportResponse, adaptProjectOrderGroupShiftReportFromResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupShiftReportReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts/:shiftId/report`,
	(payload: TProjectOrderGroupShiftReportPayload, thunkApi) => {
		const { shiftId, groupId, orderId, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts/${shiftId}/report`;

		return HttpService.get<TProjectOrderGroupShiftReportResponse>(path).then(result => {
			return adaptProjectOrderGroupShiftReportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftReportReadSlice = createSlice({
	name: 'projectOrderGroupShiftReportReadSlice',
	initialState,
	reducers: {
		setProjectOrderGroupShiftReportRead(state, action: PayloadAction<TProjectOrderGroupShiftReport>) {
			state.item = action.payload;
		},

		clearProjectOrderGroupShiftReportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftReportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftReportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftReportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftReportReadSelector = () => useAppSelector(state => state[projectOrderGroupShiftReportReadSlice.name]);

export const { clearProjectOrderGroupShiftReportRead, setProjectOrderGroupShiftReportRead } = projectOrderGroupShiftReportReadSlice.actions;
