import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingsPage = lazy(() => import('../views/list/SystemSettingsPage'));

export function SystemSettingsPageLazy() {
	return (
		<PageSuspense>
			<SystemSettingsPage />
		</PageSuspense>
	);
}
