import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TWorkArea, adaptWorkAreaFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_WORK_AREAS } from '../../backend-paths';


export const workAreaDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_WORK_AREAS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_WORK_AREAS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptWorkAreaFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TWorkArea> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const workAreaDeleteSlice = createSlice({
	name: 'workAreaDeleteSlice',
	initialState,
	reducers: {
		clearWorkAreaDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[workAreaDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[workAreaDeleteApi.fulfilled.type]: (state, action: PayloadAction<TWorkArea>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[workAreaDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useWorkAreaDeleteSelector = () => useAppSelector(state => state[workAreaDeleteSlice.name]);

export const { clearWorkAreaDelete } = workAreaDeleteSlice.actions;
