import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingCalendarEventColorProject, TSystemSettingCalendarEventColorProjectResponse, adaptSystemSettingCalendarEventColorProjectFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT } from '../../backend-paths';


export const systemSettingCalendarEventColorProjectReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT;

		return HttpService.get<TSystemSettingCalendarEventColorProjectResponse>(path).then(result => {
			return adaptSystemSettingCalendarEventColorProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingCalendarEventColorProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingCalendarEventColorProjectReadSlice = createSlice({
	name: 'systemSettingCalendarEventColorProjectReadSlice',
	initialState,
	reducers: {
		setSystemSettingCalendarEventColorProjectRead(state, action: PayloadAction<TSystemSettingCalendarEventColorProject>) {
			state.item = action.payload;
		},

		clearSystemSettingCalendarEventColorProjectRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingCalendarEventColorProjectReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingCalendarEventColorProjectReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingCalendarEventColorProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingCalendarEventColorProjectReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingCalendarEventColorProjectReadSelector = () => useAppSelector(state => state[systemSettingCalendarEventColorProjectReadSlice.name]);

export const { clearSystemSettingCalendarEventColorProjectRead, setSystemSettingCalendarEventColorProjectRead } = systemSettingCalendarEventColorProjectReadSlice.actions;
