import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TTableControl, TableControl } from '../controls';
import { TTablePagination, TablePagination } from '../pagination';
import { TTableHead, TableHead } from '../thead';
import { TableScroll } from '../scroll';
import { Table } from '../Table';
import { TableBody } from '../tbody';
import { TablePreloader } from '../preloader';

import styles from './TableBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	isLoading?: boolean;
	control?: TTableControl;
	pagination?: TTablePagination;
	thead?: TTableHead;
};

export function TableBox(props: TProps) {
	const {
		className: classNameOuter = '',
		isLoading = false,
		control,
		pagination,
		thead,
		children,
		onScroll,
		...restProps
	} = props;

	const limit = pagination && pagination.limit ? pagination.limit : 10;
	const isPagination = pagination && ((pagination.count > limit) || (limit >= pagination.count && pagination.itemsLength < pagination.count));
	const classNames = [styles.tableBox];

	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ control && <TableControl { ...control } isLoading={ isLoading } /> }

			<TableScroll onScroll={ onScroll }>
				<Table>
					{ thead && <TableHead { ...thead } /> }

					<TableBody>
						{ children }
					</TableBody>
				</Table>

				{ isLoading && <TablePreloader /> }
			</TableScroll>

			{ isPagination && <TablePagination { ...pagination } isLoading={ isLoading } /> }
		</div>
	);
}
