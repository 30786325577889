import { BreadCrumbsItem, TBreadCrumbsItem } from './bread-crumbs-item';
import styles from './BreadCrumbs.module.scss';


export type TBreadCrumbs = {
	items?: TBreadCrumbsItem[];
};

export function BreadCrumbs(props: TBreadCrumbs) {
	const { items = [] } = props;

	return (
		<div className={ styles.breadCrumbs }>
			{
				items.map(item => <BreadCrumbsItem { ...item } key={ item.to } />)
			}
		</div>
	);
}
