import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { libPriceToInt } from 'src/toolkit';


export const UserSalarySortCol = {
	VALUE: 'value',
	DATE_START: 'date_start',
	DATE_END: 'date_end',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUserSalarySortCol = TTableSortCols<typeof UserSalarySortCol>;

export type TUserSalaryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserSalarySortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TUserSalaryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptUserSalaryQueryRequest(data: TUserSalaryQuery): TUserSalaryQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserSalaryListResponse = TListResponse<TUserSalaryResponse>;

export type TUserSalaryList = TListResponse<TUserSalary>;

export function adaptUserSalaryFromListResponse(data: TUserSalaryListResponse): TUserSalaryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserSalaryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TUserSalary = {
  id: number;
  value: string;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserSalaryResponse = {
  id: number;
  value: string;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserSalaryBodyRequest = {
	value: string;
	dateStart: string;
	dateEnd?: string;
};

export type TUserSalaryRequest = {
	value: number;
	date_start: string;
	date_end?: string;
};

type TUserSalaryParam = {
	userNickname: string;
};

export type TUserSalaryParams = TUserSalaryParam & {
	id: number;
};

export type TUserSalariesPayload = TUserSalaryParam & {
	query: TUserSalaryQuery;
};

export type TUserSalaryCreatePayload = TUserSalaryParam & {
	data: TUserSalaryBodyRequest;
};

export type TUserSalaryUpdatePayload = TUserSalaryParams & {
	data: TUserSalaryBodyRequest;
};

export function adaptUserSalaryFromResponse(data: TUserSalaryResponse): TUserSalary {
	const {
		id,
		value,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		value,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserSalaryToRequest(data: TUserSalaryBodyRequest): TUserSalaryRequest {
	const {
		value = '',
		dateStart,
		dateEnd,
	} = data;

	return {
		value: libPriceToInt(value),
		date_start: dateStart,
		date_end: dateEnd,
	};
}
