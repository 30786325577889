import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormButton, FormButtonWrap, FormField, FormInput, FormNotice, FormPassword, Link } from 'src/components';
import { formValidateLogin, formValidatePassword, useInputFormField, useFocus, formHandleServerError, LocalStorage, LC_KEY_IS_AUTH } from 'src/toolkit';
import { useSignInSelector, useAppDispatch, signInApi, useSignUpSelector, clearSignIn, setAccountCredentials } from 'src/store';
import { PATH_ACCOUNT } from 'src/routes';

import styles from './SignInForm.module.scss';


export function SignInForm() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { credentials, isLoading, error } = useSignInSelector();
	const { login } = useSignUpSelector();
	const [notice, setNotice] = useState('');

	const loginField = useInputFormField({
		name: 'login',
		defaultValue: login,
		validation: formValidateLogin,
	});

	const passwordField = useInputFormField({
		name: 'password',
		validation: formValidatePassword,
	});

	const [loginRef, setLoginFocus] = useFocus();

	useEffect(() => {
		setLoginFocus();

		return () => {
			dispatch(clearSignIn());
		};
	}, []);

	useEffect(() => {
		if (error) {
			LocalStorage.set(LC_KEY_IS_AUTH, false);

			formHandleServerError(error, setNotice,
				loginField,
				passwordField
			);
		}
	}, [error]);

	useEffect(() => {
		if (credentials) {
			dispatch(setAccountCredentials(credentials));

			LocalStorage.set(LC_KEY_IS_AUTH, true);

			navigate(PATH_ACCOUNT);
		}
	}, [credentials]);

	const submit = () => {
		const errors = [
			loginField.validate(),
			passwordField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			login: loginField.value,
			password: passwordField.value,
		};

		dispatch(signInApi(dataRequest));
	};

	return (
		<Form className={ styles.signInForm } onSubmit={ submit }>
			<FormField
				legend="Логин"
				errors={ loginField.errors }
				required
			>
				<FormInput
					ref={ loginRef }
					placeholder="Логин"
					name={ loginField.name }
					title="Введите ваш никнейм, E-Mail or номер телефона"
					value={ loginField.value }
					onChange={ e => loginField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			<FormPassword
				legend="Пароль"
				errors={ passwordField.errors }
				placeholder="••••••••"
				name={ passwordField.name }
				title="Введите ваш пароль"
				value={ passwordField.value }
				onChange={ e => passwordField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<p className={ styles.recoveryText }>
				<Link to="/account/recovery">Забыли пароль?</Link>
			</p>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>Войти</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
