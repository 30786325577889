import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectOrderGroupShiftReportUpdatePage = lazy(() => import('../views/update/ProjectOrderGroupShiftReportUpdatePage'));

export function ProjectOrderGroupShiftReportUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectOrderGroupShiftReportUpdatePage />
		</PageSuspense>
	);
}
