import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './PageHeadline.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	margin?: boolean;
	small?: boolean;
	center?: boolean;
	bold?: boolean;
};

export function PageHeadline(props: TProps) {
	const {
		className = '',
		children,
		margin = false,
		small = false,
		center = false,
		bold = false,
		...restFields
	} = props;
	const classNames = [styles.pageHeadline];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	if (margin) {
		classNames.push(styles.margin);
	}

	if (small) {
		classNames.push(styles.small);
	}

	if (center) {
		classNames.push(styles.center);
	}

	if (bold) {
		classNames.push(styles.bold);
	}

	return (
		<div className={ classNames.join(' ') } { ...restFields }>
			{ children }
		</div>
	);
}
