import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentOrder, TEquipmentOrderBodyRequest, TEquipmentOrderResponse, adaptEquipmentOrderFromResponse, adaptEquipmentOrderToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS } from '../../backend-paths';


export const equipmentOrderCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EQUIPMENTS_ORDERS}`,
	(payload: TEquipmentOrderBodyRequest, thunkApi) => {
		const dataRequest = adaptEquipmentOrderToRequest(payload);

		return HttpService.post<TEquipmentOrderResponse>(BACKEND_ADMIN_EQUIPMENTS_ORDERS, dataRequest).then(result => {
			return adaptEquipmentOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderCreateSlice = createSlice({
	name: 'equipmentOrderCreateSlice',
	initialState,
	reducers: {
		clearEquipmentOrderCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[equipmentOrderCreateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useEquipmentOrderCreateSelector = () => useAppSelector(state => state[equipmentOrderCreateSlice.name]);

export const { clearEquipmentOrderCreate } = equipmentOrderCreateSlice.actions;
