import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderTagType, TProjectOrderTagTypeRequest, TProjectOrderTagTypeResponse, adaptProjectOrderTagTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES } from '../../backend-paths';


export const projectOrderTagTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES}`,
	(payload: TProjectOrderTagTypeRequest, thunkApi) => {
		return HttpService.post<TProjectOrderTagTypeResponse>(BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES, payload).then(result => {
			return adaptProjectOrderTagTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderTagType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderTagTypeCreateSlice = createSlice({
	name: 'projectOrderTagTypeCreateSlice',
	initialState,
	reducers: {
		clearProjectOrderTagTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderTagTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectOrderTagTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderTagType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderTagTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectOrderTagTypeCreateSelector = () => useAppSelector(state => state[projectOrderTagTypeCreateSlice.name]);

export const { clearProjectOrderTagTypeCreate } = projectOrderTagTypeCreateSlice.actions;
