import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountInternationalPassport, TAccountInternationalPassportPayload, TAccountInternationalPassportResponse, adaptAccountInternationalPassportFromResponse, adaptAccountInternationalPassportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT } from '../../backend-paths';


export const accountInternationalPassportUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT}`,
	(payload: TAccountInternationalPassportPayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptAccountInternationalPassportToRequest(data);

		return HttpService.put<TAccountInternationalPassportResponse>(BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT, dataRequest).then(result => {
			const pathFiles = `${BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountInternationalPassportResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountInternationalPassportFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountInternationalPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountInternationalPassportUpdateSlice = createSlice({
	name: 'accountInternationalPassportUpdateSlice',
	initialState,
	reducers: {
		clearAccountInternationalPassportUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountInternationalPassportUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountInternationalPassportUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountInternationalPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountInternationalPassportUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountInternationalPassportUpdateSelector = () => useAppSelector(state => state[accountInternationalPassportUpdateSlice.name]);

export const { clearAccountInternationalPassportUpdate } = accountInternationalPassportUpdateSlice.actions;
