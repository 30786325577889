import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TFormImageFieldExistedValue } from '../../FormImageField';

import { FormImageFieldListValueRow } from './components';

import styles from '../styles/FormImageFieldListValue.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	baseUrl: string;
	files: TFormImageFieldExistedValue[];
	onDelete: (value: TFormImageFieldExistedValue) => void;
	show: (src: string, name: string) => void;
};

export function FormImageFieldListValue(props: TProps) {
	const {
		onDelete,
		baseUrl,
		show,
		files = [],
	} = props;

	if (files.length <= 0) {
		return (
			<></>
		);
	}

	const classNames = [styles.list];

	return (
		<div className={ classNames.join(' ') }>
			{
				files.map(item => {
					return (
						<FormImageFieldListValueRow
							key={ item.path }
							baseUrl={ baseUrl }
							file={ item }
							onDelete={ onDelete }
							show={ show }
							tabIndex={ -1 }
						/>
					);
				})
			}
		</div>
	);
}
