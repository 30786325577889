import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TExpenseType, adaptExpenseTypeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EXPENSES_TYPES } from '../../backend-paths';


export const expenseTypeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_EXPENSES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EXPENSES_TYPES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const expenseTypeDeleteSlice = createSlice({
	name: 'expenseTypeDeleteSlice',
	initialState,
	reducers: {
		clearExpenseTypeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[expenseTypeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[expenseTypeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[expenseTypeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useExpenseTypeDeleteSelector = () => useAppSelector(state => state[expenseTypeDeleteSlice.name]);

export const { clearExpenseTypeDelete } = expenseTypeDeleteSlice.actions;
