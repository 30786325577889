import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TGroupShiftReportTagType, adaptGroupShiftReportTagTypeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES } from '../../backend-paths';


export const groupShiftReportTagTypeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptGroupShiftReportTagTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TGroupShiftReportTagType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const groupShiftReportTagTypeDeleteSlice = createSlice({
	name: 'groupShiftReportTagTypeDeleteSlice',
	initialState,
	reducers: {
		clearGroupShiftReportTagTypeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[groupShiftReportTagTypeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[groupShiftReportTagTypeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftReportTagType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[groupShiftReportTagTypeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useGroupShiftReportTagTypeDeleteSelector = () => useAppSelector(state => state[groupShiftReportTagTypeDeleteSlice.name]);

export const { clearGroupShiftReportTagTypeDelete } = groupShiftReportTagTypeDeleteSlice.actions;
