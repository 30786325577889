import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const EquipmentsCategoriesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsCategoriesSourceSortCol = TTableSortCols<typeof EquipmentsCategoriesSourceSortCol>;

export type TEquipmentCategorySourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsCategoriesSourceSortCol;
};

export type TEquipmentsCategoriesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsCategoriesSourceQueryToRequest(data?: TEquipmentCategorySourceQuery): TEquipmentsCategoriesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentCategorySource = {
  id: number;
  name: string;
  parents: string[];
};

export type TEquipmentCategorySourceResponse = {
  id: number;
  name: string;
  parents?: string[];
};

export type TEquipmentCategorySourceListResponse = TListResponse<TEquipmentCategorySourceResponse>;

export type TEquipmentCategorySourceList = TListResponse<TEquipmentCategorySource>;

export function adaptEquipmentCategorySourceFromListResponse(data: TEquipmentCategorySourceListResponse): TEquipmentCategorySourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentCategorySourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentCategorySourceFromResponse(data: TEquipmentCategorySourceResponse): TEquipmentCategorySource {
	const {
		id,
		name,
		parents = [],
	} = data;

	return {
		id,
		name,
		parents,
	};
}
