import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderGroupShiftReportFile } from './project-order-group-shift-report.type';
import { adaptGroupShiftReportTagTypeSourceFromResponse, TGroupShiftReportTagTypeSource, TGroupShiftReportTagTypeSourceResponse } from './group-shift-report-tag-type-source.type';


export type TAccountShiftReport = {
	shiftId: number;
	datetimeStart: string;
	datetimeEnd: string;
	equipmentsContent: string;
	taskContent?: string;
	reportContent?: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	files?: TProjectOrderGroupShiftReportFile[];
	tags: TGroupShiftReportTagTypeSource[];

	date: string;
	orderName: string;
	projectName: string;
};

export type TAccountShiftReportResponse = {
	shift_id: number;
	datetime_start: string;
	datetime_end: string;
	equipments_content: string;
	task_content?: string;
	report_content?: string;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;

	files?: TProjectOrderGroupShiftReportFile[];
	tags?: TGroupShiftReportTagTypeSourceResponse[];

	date: string;
	order_name: string;
	project_name: string;
};

export type TAccountShiftReportListResponse = TListResponse<TAccountShiftReportResponse>;

export type TAccountShiftReportList = TListResponse<TAccountShiftReport>;

export type TAccountShiftReportBodyRequest = {
	datetimeStart: string;
	datetimeEnd: string;
	equipmentsContent: string;
	taskContent: string;
	reportContent: string;
	tagsIds: number[];
};

export type TAccountShiftReportRequest = {
	datetime_start: string;
	datetime_end: string;
	equipments_content: string;
	task_content: string;
	report_content: string;
	tags_ids: number[];
};

export type TAccountShiftReportFileRequest = Array<File | string>;

export type TAccountShiftReportPayload = {
	shiftId: number;
};

export type TAccountShiftReportPayloadCreate = TAccountShiftReportPayload & {
	data: TAccountShiftReportBodyRequest;
	files: TAccountShiftReportFileRequest;
};

export function adaptAccountShiftReportFromListResponse(data: TAccountShiftReportListResponse): TAccountShiftReportList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountShiftReportFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountShiftReportFromResponse(data: TAccountShiftReportResponse): TAccountShiftReport {
	const {
		shift_id: shiftId,
		datetime_start: datetimeStart,
		datetime_end: datetimeEnd,
		equipments_content: equipmentsContent,
		task_content: taskContent,
		report_content: reportContent,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		files = [],
		tags: tagsRaw = [],

		date,
		order_name: orderName,
		project_name: projectName,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);
	const tags = tagsRaw.map(item => adaptGroupShiftReportTagTypeSourceFromResponse(item));

	return {
		shiftId,
		datetimeStart,
		datetimeEnd,
		equipmentsContent,
		taskContent,
		reportContent,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		files,
		tags,

		date,
		orderName,
		projectName,
	};
}

export function adaptAccountShiftReportToRequest(data: TAccountShiftReportBodyRequest): TAccountShiftReportRequest {
	const {
		datetimeStart,
		datetimeEnd,
		equipmentsContent,
		taskContent,
		reportContent,
		tagsIds,
	} = data;

	return {
		datetime_start: datetimeStart,
		datetime_end: datetimeEnd,
		equipments_content: equipmentsContent,
		task_content: taskContent,
		report_content: reportContent,
		tags_ids: tagsIds,
	};
}
