import { TListResponse } from 'src/common/types';


export type TAccountTaxationResponse = {
	id: string;
	name: string;
	description: string;
};

export type TAccountTaxation = {
	id: string;
	name: string;
	description: string;
};

export type TAccountTaxationListResponse = TListResponse<TAccountTaxation>;

export function adaptAccountTaxationFromResponse(data: TAccountTaxationResponse): TAccountTaxation {
	const { id, name, description } = data;

	return { id, name, description };
}

export function adaptAccountTaxationsFromListResponse(data: TAccountTaxationListResponse): TAccountTaxation[] {
	const { items } = data;

	return items.map(item => adaptAccountTaxationFromResponse(item));
}
