import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserRequisitesPage = lazy(() => import('../views/update/UserRequisitesPage'));

export function UserRequisitesPageLazy() {
	return (
		<PageSuspense>
			<UserRequisitesPage />
		</PageSuspense>
	);
}
