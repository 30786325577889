import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsCategoriesPage = lazy(() => import('../views/list/EquipmentsCategoriesPage'));

export function EquipmentsCategoriesPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsCategoriesPage />
		</PageSuspense>
	);
}
