import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormField, FormInputMask, FormNotice } from 'src/components';
import { signUpPhoneNumberConfirmApi, useAppDispatch, useSignUpSelector } from 'src/store';
import { formHandleServerError, formValidateCode, libNumberMask, useInputField } from 'src/toolkit';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpStagePhoneNumberConfirm() {
	const dispatch = useAppDispatch();
	const { phoneNumber, isLoading, error } = useSignUpSelector();

	const codeField = useInputField('code');
	const [notice, setNotice] = useState('');

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, codeField);
		}
	}, [error]);

	const submit = () => {
		const errors = [];

		errors.push(formValidateCode(codeField.value));
		codeField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm || !phoneNumber) {
			return;
		}

		const dataRequest = {
			code: codeField.value,
			phoneNumberExpected: phoneNumber,
		};

		dispatch(signUpPhoneNumberConfirmApi(dataRequest));
	};

	const phoneNumberformat = libNumberMask(phoneNumber, { template: '+_ ___ *** ** __' });

	return (
		<Form className={ styles.signUpForm } onSubmit={ submit }>
			<p className={ styles.paragraph }>На ваш номер телефона { phoneNumberformat } было отправлено сообщение с SMS-кодом. Пожалуйста, введите полученный код в поле ниже:</p>

			<FormField
				legend="SMS-код"
				errors={ codeField.errors }
				required
			>
				<FormInputMask
					placeholder="___ - ___"
					mask="999 - 999"
					title="Введите код из SMS для подтверждения"
					name={ codeField.name }
					value={ codeField.value }
					onChange={ e => codeField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>Отправить</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
