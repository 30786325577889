import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserType, TUserTypeUpdate, TUserTypeResponse, adaptUserTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_TYPES } from '../../backend-paths';


export const userTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_TYPES}/:id`,
	(payload: TUserTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_TYPES}/${id}`;

		return HttpService.put<TUserTypeResponse>(path, data).then(result => {
			return adaptUserTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeUpdateSlice = createSlice({
	name: 'userTypeUpdateSlice',
	initialState,
	reducers: {
		clearUserTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeUpdateSelector = () => useAppSelector(state => state[userTypeUpdateSlice.name]);

export const { clearUserTypeUpdate } = userTypeUpdateSlice.actions;
