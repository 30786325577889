import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TExpenseType, TExpenseTypeRequest, TExpenseTypeResponse, adaptExpenseTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EXPENSES_TYPES } from '../../backend-paths';


export const expenseTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EXPENSES_TYPES}`,
	(payload: TExpenseTypeRequest, thunkApi) => {
		return HttpService.post<TExpenseTypeResponse>(BACKEND_ADMIN_EXPENSES_TYPES, payload).then(result => {
			return adaptExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const expenseTypeCreateSlice = createSlice({
	name: 'expenseTypeCreateSlice',
	initialState,
	reducers: {
		clearExpenseTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[expenseTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[expenseTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[expenseTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useExpenseTypeCreateSelector = () => useAppSelector(state => state[expenseTypeCreateSlice.name]);

export const { clearExpenseTypeCreate } = expenseTypeCreateSlice.actions;
