import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ExpenseTypeUpdatePage = lazy(() => import('../views/update/ExpenseTypeUpdatePage'));

export function ExpenseTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<ExpenseTypeUpdatePage />
		</PageSuspense>
	);
}
