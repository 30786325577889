import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TEquipmentCategorySourceList, TEquipmentCategorySourceListResponse, TEquipmentCategorySourceQuery, adaptEquipmentCategorySourceFromListResponse, adaptEquipmentsCategoriesQueryToRequest } from '../../types';
import { BACKEND_SOURCE_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const sourceEquipmentCategoryGetApi = createAsyncThunk(
	BACKEND_SOURCE_EQUIPMENTS_CATEGORIES,
	(payload: TEquipmentCategorySourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsCategoriesQueryToRequest(payload);

		return HttpService.get<TEquipmentCategorySourceListResponse>(BACKEND_SOURCE_EQUIPMENTS_CATEGORIES, { params }).then(result => {
			return adaptEquipmentCategorySourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentCategorySourceList;
	query: TEquipmentCategorySourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceEquipmentCategorySlice = createSlice({
	name: 'sourceEquipmentCategorySlice',
	initialState,
	reducers: {
		setSourceEquipmentCategoryQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceEquipmentCategory(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceEquipmentCategoryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceEquipmentCategoryGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategorySourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceEquipmentCategoryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceEquipmentCategorySelector = () => useAppSelector(state => state[sourceEquipmentCategorySlice.name]);

export const { clearSourceEquipmentCategory, setSourceEquipmentCategoryQuerySearch } = sourceEquipmentCategorySlice.actions;
