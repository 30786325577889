import { libClearIntstr, libFormatDate } from 'src/toolkit';


export type TUserInternationalPassportFile = {
	name: string;
	path: string;
};

export type TUserInternationalPassportFileRequest = Array<File | string>;

export type TUserInternationalPassport = {
	name: string;
  passportNumber: string;
  expiredAt: string;
  issuedBy: string;
  files: TUserInternationalPassportFile[];
	createdAt: string;
	updatedAt: string;
};

export type TUserInternationalPassportBodyRequest = {
	name?: string;
  passportNumber?: string;
  expiredAt?: string;
  issuedBy?: string;
};

export type TUserInternationalPassportResponse = {
	name: string;
	passport_number: string;
  expired_at: string;
  issued_by: string;
  files: TUserInternationalPassportFile[];
	created_at: string;
	updated_at: string;
};

export type TUserInternationalPassportRequest = Omit<TUserInternationalPassportResponse, 'files' | 'created_at' | 'updated_at'>;

export type TUserInternationalPassportPayload = {
	nickname: string;
	data: TUserInternationalPassportBodyRequest;
	files: TUserInternationalPassportFileRequest;
};

export function adaptUserInternationalPassportFromResponse(data: TUserInternationalPassportResponse): TUserInternationalPassport {
	const {
		name = '',
		passport_number: passportNumber = '',
		expired_at: expiredAtRaw,
		issued_by: issuedBy = '',
		files = [],
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const expiredAt = expiredAtRaw ? libFormatDate(expiredAtRaw, { template: 'yyyy-MM-dd' }) : '';

	return {
		name,
		passportNumber,
		expiredAt,
		issuedBy,
		files,
		createdAt,
		updatedAt,
	};
}

export function adaptUserInternationalPassportToRequest(data: TUserInternationalPassportBodyRequest): TUserInternationalPassportRequest {
	const {
		name = '',
		passportNumber = '',
		expiredAt = '',
		issuedBy = '',
	} = data;

	return {
		name: name,
		passport_number: libClearIntstr(passportNumber),
		expired_at: expiredAt,
		issued_by: issuedBy,
	};
}

