import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountTypeChangeReadPage = lazy(() => import('../views/read/AccountTypeChangeReadPage'));

export function AccountTypeChangeReadPageLazy() {
	return (
		<PageSuspense>
			<AccountTypeChangeReadPage />
		</PageSuspense>
	);
}
