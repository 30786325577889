import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountExpense, TAccountExpenseResponse, adaptAccountExpenseFromResponse } from '../../types';
import { BACKEND_ACCOUNT_EXPENSES } from '../../backend-paths';


export const accountExpenseReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ACCOUNT_EXPENSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_EXPENSES}/${payload}`;

		return HttpService.get<TAccountExpenseResponse>(path).then(result => {
			return adaptAccountExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExpenseReadSlice = createSlice({
	name: 'accountExpenseReadSlice',
	initialState,
	reducers: {
		setAccountExpenseRead(state, action: PayloadAction<TAccountExpense>) {
			state.item = action.payload;
		},

		clearAccountExpenseRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExpenseReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExpenseReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExpenseReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExpenseReadSelector = () => useAppSelector(state => state[accountExpenseReadSlice.name]);

export const { clearAccountExpenseRead, setAccountExpenseRead } = accountExpenseReadSlice.actions;
