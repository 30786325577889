import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ScheduleEventsSortCol, TScheduleEventList, TScheduleEventListResponse, TScheduleEventQuery, TScheduleEventsSortCol, adaptScheduleEventFromListResponse, adaptScheduleEventsQueryToRequest } from '../../types';
import { BACKEND_ADMIN_SCHEDULE_EVENTS } from '../../backend-paths';


export const scheduleEventsGetApi = createAsyncThunk(
	BACKEND_ADMIN_SCHEDULE_EVENTS,
	(payload: TScheduleEventQuery | undefined, thunkApi) => {
		const params = payload ? adaptScheduleEventsQueryToRequest(payload) : {};

		return HttpService.get<TScheduleEventListResponse>(BACKEND_ADMIN_SCHEDULE_EVENTS, { params }).then(result => {
			return adaptScheduleEventFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TScheduleEventList;
	query: TScheduleEventQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.DESC,
		sortCol: ScheduleEventsSortCol.CREATED_AT,
	},
};

export const scheduleEventsSlice = createSlice({
	name: 'scheduleEventsSlice',
	initialState,
	reducers: {
		setScheduleEventsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setScheduleEventsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setScheduleEventsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setScheduleEventsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setScheduleEventsQuerySortCol(state, action: PayloadAction<TScheduleEventsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearScheduleEvents(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[scheduleEventsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventsGetApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[scheduleEventsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventsSelector = () => useAppSelector(state => state[scheduleEventsSlice.name]);

export const { clearScheduleEvents, setScheduleEventsQueryLimit, setScheduleEventsQueryOffset, setScheduleEventsQuerySort, setScheduleEventsQuerySortCol, setScheduleEventsQuerySearch } = scheduleEventsSlice.actions;
