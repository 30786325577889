import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentOrderStatus, TEquipmentOrderStatusUpdate, TEquipmentOrderStatusResponse, adaptEquipmentOrderStatusFromResponse, adaptEquipmentOrderStatusToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES } from '../../backend-paths';


export const equipmentOrderStatusUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES}/:id`,
	(payload: TEquipmentOrderStatusUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES}/${id}`;
		const dataRequest = adaptEquipmentOrderStatusToRequest(data);

		return HttpService.put<TEquipmentOrderStatusResponse>(path, dataRequest).then(result => {
			return adaptEquipmentOrderStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrderStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderStatusUpdateSlice = createSlice({
	name: 'equipmentOrderStatusUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentOrderStatusUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderStatusUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentOrderStatusUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderStatusUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentOrderStatusUpdateSelector = () => useAppSelector(state => state[equipmentOrderStatusUpdateSlice.name]);

export const { clearEquipmentOrderStatusUpdate } = equipmentOrderStatusUpdateSlice.actions;
