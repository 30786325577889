import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderSource, TProjectOrderSourceResponse, adaptProjectOrderSourceFromResponse } from './project-order-source.type';
import { TFinancialChangeTypeSource, TFinancialChangeTypeSourceResponse, adaptFinancialChangeTypeSourceFromResponse } from './financial-change-type-source.type';


export const UsersFinancialChangesSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersFinancialChangesSortCol = TTableSortCols<typeof UsersFinancialChangesSortCol>;

export type TUserFinancialChangeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersFinancialChangesSortCol;
};

export type TUsersFinancialChangesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersFinancialChangesQueryToRequest(data?: TUserFinancialChangeQuery): TUsersFinancialChangesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserFinancialChange = {
  id: number;
	date: string;
	value: string;
	shortDescription: string;
	description: string;
	createdAt: string;
  updatedAt: string;

	user: TUserMeta;
	projectOrder?: TProjectOrderSource;
	changeType: TFinancialChangeTypeSource;
	files: TUserFinancialChangeFile[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserFinancialChangeResponse = {
  id: number;
	date: string;
	value: string;
	short_description: string;
	description: string;
  created_at: string;
  updated_at: string;

	user: TUserMetaResponse;
	project_order: TProjectOrderSourceResponse;
	change_type: TFinancialChangeTypeSourceResponse;
	files?: TUserFinancialChangeFile[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserFinancialChangeListResponse = TListResponse<TUserFinancialChangeResponse>;

export type TUserFinancialChangeList = TListResponse<TUserFinancialChange>;

export type TUserFinancialChangeFile = {
	name: string;
	path: string;
};

export type TUserFinancialChangeFileRequest = Array<File | string>;

export type TUserFinancialChangePayload = {
	data: TUserFinancialChangeBodyRequest;
	files: TUserFinancialChangeFileRequest;
};

export type TUserFinancialChangeBodyRequest = {
	userNickname: string;
	projectOrderId: number;
	changeTypeId: number;
	date: string;
	value: number;
	shortDescription: string;
	description: string;
};

export type TUserFinancialChangeRequest = {
	user_nickname: string;
	project_order_id: number;
	change_type_id: number;
	date: string;
	value: number;
	short_description: string;
	description: string;
};

export type TUserFinancialChangeUpdate = {
	id: number;
	data: TUserFinancialChangeBodyRequest;
	files: TUserFinancialChangeFileRequest;
};

export function adaptUserFinancialChangeFromListResponse(data: TUserFinancialChangeListResponse): TUserFinancialChangeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserFinancialChangeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserFinancialChangeFromResponse(data: TUserFinancialChangeResponse): TUserFinancialChange {
	const {
		id,
		date: date,
		value: value,
		short_description: shortDescription,
		description,
		created_at: createdAt,
		updated_at: updatedAt,

		user: userRaw,
		project_order: projectOrderRaw,
		change_type: changeTypeRaw,
		files = [],
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const user = adaptUserMetaFromResponse(userRaw);
	const projectOrder = projectOrderRaw ? adaptProjectOrderSourceFromResponse(projectOrderRaw) : undefined;
	const changeType = adaptFinancialChangeTypeSourceFromResponse(changeTypeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		date,
		value,
		shortDescription,
		description,
		createdAt,
		updatedAt,

		user,
		projectOrder,
		changeType,
		files,
		createdBy,
		updatedBy,
	};
}

export function adaptUserFinancialChangeToRequest(data: TUserFinancialChangeBodyRequest): TUserFinancialChangeRequest {
	const {
		userNickname,
		projectOrderId,
		changeTypeId,
		date,
		value,
		shortDescription,
		description,
	} = data;

	return {
		user_nickname: userNickname,
		project_order_id: projectOrderId,
		change_type_id: changeTypeId,
		date,
		value,
		short_description: shortDescription,
		description,
	};
}
