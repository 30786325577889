import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountShiftReadPage = lazy(() => import('../views/read/AccountShiftReadPage'));

export function AccountShiftReadPageLazy() {
	return (
		<PageSuspense>
			<AccountShiftReadPage />
		</PageSuspense>
	);
}
