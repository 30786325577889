import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentCategory, TEquipmentCategoryRequest, TEquipmentCategoryResponse, adaptEquipmentCategoryFromResponse, adaptEquipmentCategoryToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentCategoryCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}`,
	(payload: TEquipmentCategoryRequest, thunkApi) => {
		const dataRequest = adaptEquipmentCategoryToRequest(payload);

		return HttpService.post<TEquipmentCategoryResponse>(BACKEND_ADMIN_EQUIPMENTS_CATEGORIES, dataRequest).then(result => {
			return adaptEquipmentCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentCategoryCreateSlice = createSlice({
	name: 'equipmentCategoryCreateSlice',
	initialState,
	reducers: {
		clearEquipmentCategoryCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentCategoryCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[equipmentCategoryCreateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentCategoryCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useEquipmentCategoryCreateSelector = () => useAppSelector(state => state[equipmentCategoryCreateSlice.name]);

export const { clearEquipmentCategoryCreate } = equipmentCategoryCreateSlice.actions;
