import { TListResponse } from 'src/common/types';
import { adaptSystemSettingValueCommonFromResponse, TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';


export type TSystemSettingUsersValue = TSystemSettingValueCommon & {
  value?: number;
  text?: string;
};


export type TSystemSettingUsersValueResponse = TSystemSettingValueCommonResponse & {
  value: number;
  text?: string;
};

export type TSystemSettingUsersValueListResponse = TListResponse<TSystemSettingUsersValueResponse>;

export type TSystemSettingUsersValueList = TListResponse<TSystemSettingUsersValue>;

export function adaptSystemSettingUsersValueFromListResponse(data: TSystemSettingUsersValueListResponse): TSystemSettingUsersValueList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptSystemSettingUsersValueFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptSystemSettingUsersValueFromResponse(data: TSystemSettingUsersValueResponse): TSystemSettingUsersValue {
	const {
		value,
		text,
		...restFields
	} = data;

	const commonFields = adaptSystemSettingValueCommonFromResponse(restFields);

	return {
		...commonFields,
		text,
		value,
	};
}
