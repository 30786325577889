import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserSalary, TUserSalaryCreatePayload, TUserSalaryResponse, adaptUserSalaryFromResponse, adaptUserSalaryToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userSalaryCreateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:user_nickname/salaries`,
	(payload: TUserSalaryCreatePayload, thunkApi) => {
		const { userNickname, data } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/salaries`;

		const dataRequest = adaptUserSalaryToRequest(data);

		return HttpService.post<TUserSalaryResponse>(path, dataRequest).then(result => {
			return adaptUserSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserSalary> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userSalaryCreateSlice = createSlice({
	name: 'userSalaryCreateSlice',
	initialState,
	reducers: {
		clearUserSalaryCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userSalaryCreateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userSalaryCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userSalaryCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserSalaryCreateSelector = () => useAppSelector(state => state[userSalaryCreateSlice.name]);

export const { clearUserSalaryCreate } = userSalaryCreateSlice.actions;
