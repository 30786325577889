import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSetting, TSystemSettingUpdate, TSystemSettingResponse, adaptSystemSettingFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS } from '../../backend-paths';


export const systemSettingUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS}/:id`,
	(payload: TSystemSettingUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_SYSTEM_SETTINGS}/${id}`;

		return HttpService.put<TSystemSettingResponse>(path, data).then(result => {
			return adaptSystemSettingFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSetting> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingUpdateSlice = createSlice({
	name: 'systemSettingUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSetting>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingUpdateSelector = () => useAppSelector(state => state[systemSettingUpdateSlice.name]);

export const { clearSystemSettingUpdate } = systemSettingUpdateSlice.actions;
