import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_SOURCE_USER_CATEGORY } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from '../../types';


export const sourceUserCategoryGetApi = createAsyncThunk(
	`FETCH/${BACKEND_SOURCE_USER_CATEGORY}/:nickname`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_SOURCE_USER_CATEGORY}/${payload}`;

		return HttpService.get<TUserCategoryResponse>(path).then(result => {
			return adaptUserCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	item?: TUserCategory,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
};

export const sourceUserCategorySlice = createSlice({
	name: 'sourceUserCategorySlice',
	initialState,
	reducers: {
		clearSourceUserCategoryItem(state) {
			return { ...initialState, error: state.error };
		},

		clearSourceUserCategory(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[sourceUserCategoryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceUserCategoryGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.error = undefined;
		},

		[sourceUserCategoryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceUserCategorySelector = () => useAppSelector(state => state[sourceUserCategorySlice.name]);

export const { clearSourceUserCategory, clearSourceUserCategoryItem } = sourceUserCategorySlice.actions;
