import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TPaymentType, TPaymentTypeUpdate, TPaymentTypeResponse, adaptPaymentTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PAYMENTS_TYPES } from '../../backend-paths';


export const paymentTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_PAYMENTS_TYPES}/:id`,
	(payload: TPaymentTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_PAYMENTS_TYPES}/${id}`;

		return HttpService.put<TPaymentTypeResponse>(path, data).then(result => {
			return adaptPaymentTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TPaymentType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const paymentTypeUpdateSlice = createSlice({
	name: 'paymentTypeUpdateSlice',
	initialState,
	reducers: {
		clearPaymentTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[paymentTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[paymentTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TPaymentType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[paymentTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const usePaymentTypeUpdateSelector = () => useAppSelector(state => state[paymentTypeUpdateSlice.name]);

export const { clearPaymentTypeUpdate } = paymentTypeUpdateSlice.actions;
