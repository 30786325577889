import { NavLink, useLocation } from 'react-router-dom';

import { useAccountRolesSelector } from 'src/store';

import { TAsideItemOption } from '../../../../AsideItem';

import styles from './AsideItemMenuOption.module.scss';


export function AsideItemMenuOption(props: TAsideItemOption) {
	const roles = useAccountRolesSelector();
	const { pathname } = useLocation();

	const {
		href: optionHref = '#',
		text: optionText = 'Default',
		links: optionLinks = [],
		re: optionRe = '',
		permission: optionRole,
		onClick = () => {},
	} = props;

	if (optionRole) {
		if (!roles.includes(optionRole)) {
			return (
				<></>
			);
		}
	}

	const classNamesRow = [styles.row];

	const isActive = optionHref === pathname || optionLinks.includes(pathname) || (optionRe && optionRe.test(pathname));

	if (isActive) {
		classNamesRow.push(styles.active);
	}

	return (
		<div className={ classNamesRow.join(' ') }>
			<NavLink
				className={ styles.link }
				onClick={ onClick }
				to={ optionHref }
			>
				{ optionText }
			</NavLink>
		</div>
	);
}
