import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroup, TProjectOrderGroupPayloadCreate, TProjectOrderGroupResponse, adaptProjectOrderGroupFromResponse, adaptProjectOrderGroupToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups`,
	(payload: TProjectOrderGroupPayloadCreate, thunkApi) => {
		const { orderId, projectId, data } = payload;
		const dataRequest = adaptProjectOrderGroupToRequest(data);
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups`;

		return HttpService.post<TProjectOrderGroupResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderGroupFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroup> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupCreateSlice = createSlice({
	name: 'projectOrderGroupCreateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectOrderGroupCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroup>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectOrderGroupCreateSelector = () => useAppSelector(state => state[projectOrderGroupCreateSlice.name]);

export const { clearProjectOrderGroupCreate } = projectOrderGroupCreateSlice.actions;
