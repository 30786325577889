import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingTypesPage = lazy(() => import('../views/list/SystemSettingTypesPage'));

export function SystemSettingTypesPageLazy() {
	return (
		<PageSuspense>
			<SystemSettingTypesPage />
		</PageSuspense>
	);
}
