import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserFinancialChange, TUserFinancialChangePayload, TUserFinancialChangeResponse, adaptUserFinancialChangeFromResponse, adaptUserFinancialChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_FINANCIAL_CHANGES } from '../../backend-paths';


export const userFinancialChangeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}`,
	(payload: TUserFinancialChangePayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptUserFinancialChangeToRequest(data);

		return HttpService.post<TUserFinancialChangeResponse>(BACKEND_ADMIN_USERS_FINANCIAL_CHANGES, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserFinancialChangeResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserFinancialChangeFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserFinancialChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userFinancialChangeCreateSlice = createSlice({
	name: 'userFinancialChangeCreateSlice',
	initialState,
	reducers: {
		clearUserFinancialChangeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userFinancialChangeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userFinancialChangeCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserFinancialChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userFinancialChangeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserFinancialChangeCreateSelector = () => useAppSelector(state => state[userFinancialChangeCreateSlice.name]);

export const { clearUserFinancialChangeCreate } = userFinancialChangeCreateSlice.actions;
