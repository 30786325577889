import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingAmocrmClientSecret, TSystemSettingAmocrmClientSecretResponse, adaptSystemSettingAmocrmClientSecretFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET } from '../../backend-paths';


export const systemSettingAmocrmClientSecretReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET;

		return HttpService.get<TSystemSettingAmocrmClientSecretResponse>(path).then(result => {
			return adaptSystemSettingAmocrmClientSecretFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingAmocrmClientSecret> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingAmocrmClientSecretReadSlice = createSlice({
	name: 'systemSettingAmocrmClientSecretReadSlice',
	initialState,
	reducers: {
		setSystemSettingAmocrmClientSecretRead(state, action: PayloadAction<TSystemSettingAmocrmClientSecret>) {
			state.item = action.payload;
		},

		clearSystemSettingAmocrmClientSecretRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingAmocrmClientSecretReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingAmocrmClientSecretReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingAmocrmClientSecret>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingAmocrmClientSecretReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingAmocrmClientSecretReadSelector = () => useAppSelector(state => state[systemSettingAmocrmClientSecretReadSlice.name]);

export const { clearSystemSettingAmocrmClientSecretRead, setSystemSettingAmocrmClientSecretRead } = systemSettingAmocrmClientSecretReadSlice.actions;
