import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserSalary, TUserSalaryParams, TUserSalaryResponse, adaptUserSalaryFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userSalaryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/salaries/:id`,
	(payload: TUserSalaryParams, thunkApi) => {
		const { userNickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/salaries/${id}`;

		return HttpService.get<TUserSalaryResponse>(path).then(result => {
			return adaptUserSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserSalary> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userSalaryReadSlice = createSlice({
	name: 'userSalaryReadSlice',
	initialState,
	reducers: {
		setUserSalaryRead(state, action: PayloadAction<TUserSalary>) {
			state.item = action.payload;
		},

		clearUserSalaryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userSalaryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userSalaryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userSalaryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserSalaryReadSelector = () => useAppSelector(state => state[userSalaryReadSlice.name]);

export const { clearUserSalaryRead, setUserSalaryRead } = userSalaryReadSlice.actions;
