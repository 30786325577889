import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProductionCalendar, adaptProductionCalendarFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PRODUCTION_CALENDARS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PRODUCTION_CALENDARS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptProductionCalendarFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProductionCalendar> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarDeleteSlice = createSlice({
	name: 'productionCalendarDeleteSlice',
	initialState,
	reducers: {
		clearProductionCalendarDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[productionCalendarDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendar>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProductionCalendarDeleteSelector = () => useAppSelector(state => state[productionCalendarDeleteSlice.name]);

export const { clearProductionCalendarDelete } = productionCalendarDeleteSlice.actions;
