import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsOrdersPage = lazy(() => import('../views/list/EquipmentsOrdersPage'));

export function EquipmentsOrdersPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsOrdersPage />
		</PageSuspense>
	);
}
