import { getExperienceTime } from 'src/common/helpers';

import styles from './CvExperienceCount.module.scss';


type TProps = {
	headline?: string;
	className?: string;
	classNames?: string[];
	value: number[];
};

export function CvExperienceCount(props: TProps) {
	const {
		headline,
		className = '',
		classNames: classNamesOuter = [],
		value,
	} = props;

	const count = getExperienceTime(value, 'Нет', ['год', 'года', 'лет'], ['месяц', 'месяца', 'месяцев'], ['день', 'дня', 'дней']);

	const classNames = [styles.box, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			{ headline && <div className={ styles.headline }>{ headline }</div> }

			<div className={ styles.text }>{ count }</div>
		</div>
	);
}
