import { DetailedHTMLProps, SelectHTMLAttributes } from 'react';

import styles from './FormSelect.module.scss';

type TOptionData = {
	value: string | number;
	text?: string | number;
};

type TOptionValue = string | number;

type TProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
	classNames?: string[];
	items?: TOptionData[] | TOptionValue[];
};

function isOptionData(value: TOptionValue[] | TOptionData[]): value is TOptionData[] {
	const item = value[0];

  return typeof (item as TOptionData) === 'object';
}

export function FormSelect(props: TProps) {
	const {
		items = [],
		className: classNameOuter = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.formSelect, ...classNamesOuter];
	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	if (isOptionData(items)) {
		return (
			<select
				className={ classNames.join(' ') }
				{ ...restProps }
			>
				{ items.length > 0 &&
					items.map(item => {
						const { value } = item;
						const text = item.text || value;

						return (
							<option key={ value } value={ value }>{ text }</option>
						);
					})
				}
			</select>
		);
	}

	return (
		<select
				className={ classNames.join(' ') }
			{ ...restProps }
		>
			{ items.length > 0 &&
				items.map(item => {
					return (
						<option key={ item }>{ item }</option>
					);
				})
			}
		</select>
	);
}
