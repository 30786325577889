import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersTaxationsSortCol, TUserTaxationList, TUserTaxationListResponse, TUserTaxationQuery, TUsersTaxationsQueryRequest, TUsersTaxationsSortCol, adaptUserTaxationFromListResponse } from '../../types';
import { BACKEND_ADMIN_USERS_TAXATIONS } from '../../backend-paths';


export const usersTaxationsGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_TAXATIONS,
	(payload: TUserTaxationQuery | undefined, thunkApi) => {
		const params = payload ? toUsersTaxationsQueryRequest(payload) : {};

		return HttpService.get<TUserTaxationListResponse>(BACKEND_ADMIN_USERS_TAXATIONS, { params }).then(result => {
			return adaptUserTaxationFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toUsersTaxationsQueryRequest(data: TUserTaxationQuery): TUsersTaxationsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	isLoading: boolean,
	list: TUserTaxationList,
	query: TUserTaxationQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersTaxationsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersTaxationsSlice = createSlice({
	name: 'usersTaxationsSlice',
	initialState,
	reducers: {
		setUsersTaxationsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersTaxationsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersTaxationsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersTaxationsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersTaxationsQuerySortCol(state, action: PayloadAction<TUsersTaxationsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersTaxations(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersTaxationsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersTaxationsGetApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersTaxationsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersTaxationsSelector = () => useAppSelector(state => state[usersTaxationsSlice.name]);

export const { clearUsersTaxations, setUsersTaxationsQueryLimit, setUsersTaxationsQueryOffset, setUsersTaxationsQuerySort, setUsersTaxationsQuerySortCol, setUsersTaxationsQuerySearch } = usersTaxationsSlice.actions;
