import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const WorkAreasSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TWorkAreasSortCol = TTableSortCols<typeof WorkAreasSortCol>;

export type TWorkAreaQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TWorkAreasSortCol;
};

export type TWorkAreasQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptWorkAreasQueryToRequest(data?: TWorkAreaQuery): TWorkAreasQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TWorkArea = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TWorkAreaResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TWorkAreaListResponse = TListResponse<TWorkAreaResponse>;

export type TWorkAreaList = TListResponse<TWorkArea>;

export type TWorkAreaBodyRequest = {
	name: string;
	description?: string;
};

export type TWorkAreaRequest = {
	name: string;
	description?: string;
};

export type TWorkAreaUpdate = {
	id: number;
	data: TWorkAreaBodyRequest;
};

export type TWorkDataCategoryPayload = {
	categoryId: number;
	dateStart: string;
	dateEnd: string;
};

export type TWorkDataCategoryPayloadRequest = {
	category_id: number;
	date_start: string;
	date_end?: string;
};

export type TWorkDataCategoryCreate = {
	nickname: string;
	data: TWorkDataCategoryPayload;
};

export type TWorkDataCategoryUpdate = {
	nickname: string;
	id: number;
	data: TWorkDataCategoryPayload;
};

export type TWorkDataCategoryDelete = {
	nickname: string;
	id: number;
};

export type TWorkDataPositionUpdate = {
	nickname: string;
	positionId: number;
};

export function adaptWorkAreaFromListResponse(data: TWorkAreaListResponse): TWorkAreaList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptWorkAreaFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptWorkAreaFromResponse(data: TWorkAreaResponse): TWorkArea {
	const {
		id,
		name,
		description,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptWorkAreaToRequest(data: TWorkAreaBodyRequest): TWorkAreaRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}

export function adaptWorkAreaUserCategoryToRequest(data: TWorkDataCategoryPayload): TWorkDataCategoryPayloadRequest {
	const {
		categoryId,
		dateStart,
		dateEnd,
	} = data;

	return {
		category_id: categoryId,
		date_start: dateStart,
		date_end: dateEnd ? dateEnd : undefined,
	};
}
