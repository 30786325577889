import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingBookkeepingBillChangesConstraint, TSystemSettingBookkeepingBillChangesConstraintPayload, TSystemSettingBookkeepingBillChangesConstraintResponse, adaptSystemSettingBookkeepingBillChangesConstraintFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT } from '../../backend-paths';


export const systemSettingBookkeepingBillChangesConstraintUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT}`,
	(payload: TSystemSettingBookkeepingBillChangesConstraintPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT;

		return HttpService.put<TSystemSettingBookkeepingBillChangesConstraintResponse>(path, payload).then(result => {
			return adaptSystemSettingBookkeepingBillChangesConstraintFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingBookkeepingBillChangesConstraint> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingBookkeepingBillChangesConstraintUpdateSlice = createSlice({
	name: 'systemSettingBookkeepingBillChangesConstraintUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingBookkeepingBillChangesConstraintUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingBookkeepingBillChangesConstraintUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingBookkeepingBillChangesConstraintUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingBookkeepingBillChangesConstraint>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingBookkeepingBillChangesConstraintUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingBookkeepingBillChangesConstraintUpdateSelector = () => useAppSelector(state => state[systemSettingBookkeepingBillChangesConstraintUpdateSlice.name]);

export const { clearSystemSettingBookkeepingBillChangesConstraintUpdate } = systemSettingBookkeepingBillChangesConstraintUpdateSlice.actions;
