// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBodyRow_tableBodyRow__vH1Ik {\n  border: 1px solid rgb(60, 60, 60);\n  border-top: 1px solid rgb(60, 60, 60);\n  box-sizing: border-box;\n  color: rgb(0, 0, 0);\n  line-height: 1em;\n  padding: 1em 0.5em;\n}\n.TableBodyRow_tableBodyRow__vH1Ik:hover {\n  background-color: rgb(0, 10, 10);\n}\n.TableBodyRow_tableBodyRow__vH1Ik:first-child {\n  border-left: 1px solid rgb(60, 60, 60);\n}\n.TableBodyRow_tableBodyRow__vH1Ik:last-child {\n  border-right: 1px solid rgb(60, 60, 60);\n}\n.TableBodyRow_tableBodyRow__vH1Ik:nth-child(odd) {\n  background-color: rgb(20, 20, 20);\n}\n.TableBodyRow_tableBodyRow__vH1Ik:nth-child(odd):hover {\n  background-color: rgb(0, 10, 10);\n}", "",{"version":3,"sources":["webpack://./src/components/table/tbody/row/TableBodyRow.module.scss"],"names":[],"mappings":"AAGA;EACC,iCAAA;EACA,qCAAA;EACA,sBAAA;EACA,mBAPO;EAQP,gBAAA;EACA,kBAAA;AAFD;AAIC;EACC,gCAAA;AAFF;AAKC;EACC,sCAAA;AAHF;AAMC;EACC,uCAAA;AAJF;AAOC;EACC,iCAAA;AALF;AAOE;EACC,gCAAA;AALH","sourcesContent":["$color: rgba(0, 0, 0, 1);\n$borderColor: rgba(60, 60, 60, 1);\n\n.tableBodyRow {\n\tborder: 1px solid $borderColor;\n\tborder-top: 1px solid $borderColor;\n\tbox-sizing: border-box;\n\tcolor: $color;\n\tline-height: 1em;\n\tpadding: 1em 0.5em;\n\n\t&:hover {\n\t\tbackground-color: rgba(0, 10, 10, 1);\n\t}\n\n\t&:first-child {\n\t\tborder-left: 1px solid $borderColor;\n\t}\n\n\t&:last-child {\n\t\tborder-right: 1px solid $borderColor;\n\t}\n\n\t&:nth-child(odd) {\n\t\tbackground-color: rgba(20, 20, 20, 1);\n\n\t\t&:hover {\n\t\t\tbackground-color: rgba(0, 10, 10, 1);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBodyRow": "TableBodyRow_tableBodyRow__vH1Ik"
};
export default ___CSS_LOADER_EXPORT___;
