import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TGroupShiftExpenseType, TGroupShiftExpenseTypeBodyRequest, TGroupShiftExpenseTypeResponse, adaptGroupShiftExpenseTypeFromResponse, adaptGroupShiftExpenseTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES } from '../../backend-paths';


export const groupShiftExpenseTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES}`,
	(payload: TGroupShiftExpenseTypeBodyRequest, thunkApi) => {
		const dataRequest = adaptGroupShiftExpenseTypeToRequest(payload);

		return HttpService.post<TGroupShiftExpenseTypeResponse>(BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES, dataRequest).then(result => {
			return adaptGroupShiftExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TGroupShiftExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const groupShiftExpenseTypeCreateSlice = createSlice({
	name: 'groupShiftExpenseTypeCreateSlice',
	initialState,
	reducers: {
		clearGroupShiftExpenseTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[groupShiftExpenseTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[groupShiftExpenseTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[groupShiftExpenseTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useGroupShiftExpenseTypeCreateSelector = () => useAppSelector(state => state[groupShiftExpenseTypeCreateSlice.name]);

export const { clearGroupShiftExpenseTypeCreate } = groupShiftExpenseTypeCreateSlice.actions;
