import { libClearIntstr } from 'src/toolkit';

export type TAccountData = {
	trustedPhoneNumber: string;
  trustedName: string;
  carData: string;
  telegram: string;
};

export type TAccountDataBodyRequest = {
	trustedPhoneNumber?: string;
  trustedName?: string;
  carData?: string;
  telegram?: string;
};

export type TAccountDataResponse = {
	trusted_phone_number: string;
  trusted_name: string;
  car_data: string;
  telegram: string;
};

export type TAccountDataRequest = {
	trusted_phone_number?: string;
  trusted_name?: string;
  car_data?: string;
  telegram?: string;
};

export type TAccountDataPayload = {
	data: TAccountDataBodyRequest;
};

export function adaptAccountDataFromResponse(data: TAccountDataResponse): TAccountData {
	const {
		trusted_phone_number: trustedPhoneNumber = '',
		trusted_name: trustedName,
		car_data: carData,
		telegram,
	} = data;

	return {
		trustedPhoneNumber,
		trustedName,
		carData,
		telegram,
	};
}

export function adaptAccountDataToRequest(data: TAccountDataBodyRequest): TAccountDataRequest {
	const {
		trustedPhoneNumber,
		trustedName,
		carData,
		telegram,
	} = data;

	return {
		trusted_phone_number: libClearIntstr(trustedPhoneNumber),
		trusted_name: trustedName,
		car_data: carData,
		telegram,
	};
}

