import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TWorkArea, TWorkAreaResponse, adaptWorkAreaFromResponse } from '../../types';
import { BACKEND_ADMIN_WORK_AREAS } from '../../backend-paths';


export const workAreaReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_WORK_AREAS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_WORK_AREAS}/${payload}`;

		return HttpService.get<TWorkAreaResponse>(path).then(result => {
			return adaptWorkAreaFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TWorkArea> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const workAreaReadSlice = createSlice({
	name: 'workAreaReadSlice',
	initialState,
	reducers: {
		setWorkAreaRead(state, action: PayloadAction<TWorkArea>) {
			state.item = action.payload;
		},

		clearWorkAreaRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[workAreaReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[workAreaReadGetApi.fulfilled.type]: (state, action: PayloadAction<TWorkArea>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[workAreaReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useWorkAreaReadSelector = () => useAppSelector(state => state[workAreaReadSlice.name]);

export const { clearWorkAreaRead, setWorkAreaRead } = workAreaReadSlice.actions;
