import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountExpenseUpdatePage = lazy(() => import('../views/update/AccountExpenseUpdatePage'));

export function AccountExpenseUpdatePageLazy() {
	return (
		<PageSuspense>
			<AccountExpenseUpdatePage />
		</PageSuspense>
	);
}
