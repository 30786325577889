import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingAmocrmPage = lazy(() => import('../views/list/SystemSettingAmocrmPage'));

export function SystemSettingAmocrmPageLazy() {
	return (
		<PageSuspense>
			<SystemSettingAmocrmPage />
		</PageSuspense>
	);
}
