// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalClose_modalClose__chW41 {\n  box-sizing: border-box;\n  color: rgb(200, 200, 200);\n  cursor: pointer;\n  font-size: 3em;\n  line-height: 1.65em;\n  height: 1.5em;\n  width: 1.5em;\n  text-align: center;\n  position: absolute;\n  top: 1em;\n  right: 1em;\n  -webkit-user-select: none;\n          user-select: none;\n  animation-timing-function: ease-in-out;\n  transition-duration: 0.1s;\n  transition-property: border, color;\n}\n.ModalClose_modalClose__chW41:active, .ModalClose_modalClose__chW41:hover {\n  color: rgb(255, 50, 50);\n}\n.ModalClose_modalClose__chW41:active {\n  transition-duration: 0s;\n}\n@media screen and (max-width: 480px) {\n  .ModalClose_modalClose__chW41 {\n    font-size: 2em;\n    top: 0.25em;\n    right: 0.25em;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/modal/modal-close/ModalClose.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,yBAAA;EACA,eAAA;EAEA,cAAA;EACA,mBAAA;EAEA,aAAA;EACA,YAAA;EAEA,kBAAA;EAEA,kBAAA;EACA,QAAA;EACA,UAAA;EAEA,yBAAA;UAAA,iBAAA;EAEA,sCAAA;EACA,yBAAA;EACA,kCAAA;AAND;AAQC;EAEC,uBAAA;AAPF;AAUC;EACC,uBAAA;AARF;AAWC;EAjCD;IAkCE,cAAA;IAEA,WAAA;IACA,aAAA;EATA;AACF","sourcesContent":[".modalClose {\n\tbox-sizing: border-box;\n\n\tcolor: rgba(200, 200, 200, 1);\n\tcursor: pointer;\n\n\tfont-size: 3em;\n\tline-height: 1.65em;\n\n\theight: 1.5em;\n\twidth: 1.5em;\n\n\ttext-align: center;\n\n\tposition: absolute;\n\ttop: 1em;\n\tright: 1em;\n\n\tuser-select: none;\n\n\tanimation-timing-function: ease-in-out;\n\ttransition-duration: 0.1s;\n\ttransition-property: border, color;\n\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(255, 50, 50, 1);\n\t}\n\n\t&:active {\n\t\ttransition-duration: 0s;\n\t}\n\n\t@media screen and (max-width: 480px) {\n\t\tfont-size: 2em;\n\n\t\ttop: 0.25em;\n\t\tright: 0.25em;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalClose": "ModalClose_modalClose__chW41"
};
export default ___CSS_LOADER_EXPORT___;
