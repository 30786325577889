import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const FinancialChangeTypeUpdatePage = lazy(() => import('../views/update/FinancialChangeTypeUpdatePage'));

export function FinancialChangeTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<FinancialChangeTypeUpdatePage />
		</PageSuspense>
	);
}
