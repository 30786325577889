import { useEffect, useState } from 'react';


type TUseIsMobile = {
	isMobile: boolean;
	isTablet: boolean;
	width: number;
	height: number;
};

const enum ScreenWidth {
	MOBILE = 480,
	TABLET = 767,
}

export function useIsMobile(): TUseIsMobile {
	const [width, setWidth] = useState<number>(window.innerWidth || document.body.clientWidth);
	const [height, setHeight] = useState<number>(window.innerHeight || document.body.clientHeight);

	const onChange = () => {
		setWidth(window.innerWidth || document.body.clientWidth);
		setHeight(window.innerHeight || document.body.clientHeight);
	}

	useEffect(() => {
		window.addEventListener('resize', onChange);

		return () => {
			window.removeEventListener('resize', onChange);
		}
	}, []);

	return {
		isMobile: width <= ScreenWidth.MOBILE,
		isTablet: width <= ScreenWidth.TABLET,
		width,
		height,
	};
}
