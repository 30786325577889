import { DetailedHTMLProps, HTMLAttributes, MouseEvent } from 'react';

import { FormSelectBoxFieldListItem, TFormSelectBoxFieldListOption } from './FormSelectBoxFieldListItem';

import styles from './FormSelectBoxField.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TFormSelectBoxFieldListOption[];
	value: string;
	setValue: (value: string) => void;
	setIsActive: (value: boolean) => void;
};

export function FormSelectBoxFieldList(props: TProps) {
	const {
		value: currentValue,
		items,
		setValue,
		setIsActive,
		...restProps
	} = props;

	const onMousePress = (e: MouseEvent<HTMLDivElement>) => {
		if (e.button !== 0) {
			setIsActive(false);
		}
	};

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map(item => {
					const { text, value } = item;

					return (
						<FormSelectBoxFieldListItem
							key={ value }
							disabled={ value === currentValue }
							onMouseDown={ e => onMousePress(e) }
							onClick={ e => setValue(value) }
						>{ text }</FormSelectBoxFieldListItem>
					);
				})
			}
		</div>
	);
}
