import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingReportsPhotoNotAttachingPenaltyUpdatePage = lazy(() => import('../views/update-photo-not-attaching-penalty/SystemSettingReportsPhotoNotAttachingPenaltyUpdatePage'));

export function SystemSettingReportsPhotoNotAttachingPenaltyUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingReportsPhotoNotAttachingPenaltyUpdatePage />
		</PageSuspense>
	);
}
