import { libClearIntstr } from 'src/toolkit';

export type TAccountRequisites = {
	accountNumber: string;
  bankName: string;
  bik: string;
  corAccount: string;
};

export type TAccountRequisitesBodyRequest = {
	accountNumber?: string;
  bankName?: string;
  bik?: string;
  corAccount?: string;
};

export type TAccountRequisitesResponse = {
	account_number: string;
  bank_name: string;
  bik: string;
  cor_account: string;
};

export type TAccountRequisitesRequest = {
	account_number?: string;
  bank_name?: string;
  bik?: string;
  cor_account?: string;
};

export type TAccountRequisitesPayload = {
	data: TAccountRequisitesBodyRequest;
};

export function adaptAccountRequisitesFromResponse(data: TAccountRequisitesResponse): TAccountRequisites {
	const {
		account_number: accountNumber = '',
		bank_name: bankName = '',
		bik = '',
		cor_account: corAccount = '',
	} = data;

	return {
		accountNumber,
		bankName,
		bik,
		corAccount,
	};
}

export function adaptAccountRequisitesToRequest(data: TAccountRequisitesBodyRequest): TAccountRequisitesRequest {
	const {
		accountNumber,
		bankName,
		bik,
		corAccount,
	} = data;

	return {
		account_number: libClearIntstr(accountNumber),
		bank_name: bankName,
		bik: libClearIntstr(bik),
		cor_account: libClearIntstr(corAccount),
	};
}

