import { FormField } from 'src/components';
import { TEquipmentOrderRelationEquipmentCreate } from 'src/store';

import { EquipmentListBoxWrap } from './EquipmentListBoxWrap';

import styles from './EquipmentListBox.module.scss';
import { libCloneObject } from 'src/toolkit';


type TProps = {
	legend?: string;
	title?: string;
	placeholder?: string;

	setErrors?: (value: string[]) => void;
	errors?: string[];
	isLoading?: boolean;
	required?: boolean;

	items: TEquipmentOrderRelationEquipmentCreate[];
	setItems: (values: TEquipmentOrderRelationEquipmentCreate[]) => void;
};

export function EquipmentListBox(props: TProps) {
	const {
		items,
		setItems,

		errors,
		setErrors,
		isLoading,
		required,

		legend,
		title,
		placeholder,
	} = props;

	const onDelete = (value: number) => {
		const filtered = items.filter(item => item.equipment.id !== value);

		setItems(filtered);

		if (setErrors) {
			setErrors([]);
		}
	};

	const changeAmount = (equipmentId: number, amount: number) => {
		let i = 0;

		const equipmentRelation = items.find((item, index) => {
			i = index;

			return item.equipment.id === equipmentId;
		});

		if (equipmentRelation && equipmentRelation.equipment.amount && amount <= equipmentRelation.equipment.amount) {
			const equipmentsRelationsClonned = libCloneObject(items);
			const equipmentRelationClone = equipmentsRelationsClonned[i];

			equipmentRelationClone.amount = amount;

			setItems(equipmentsRelationsClonned);

			if (setErrors) {
				setErrors([]);
			}
		}
	};

	const classNames = [styles.box];

	if (isLoading) {
		classNames.push(styles.isLoading);
	}

	return (
		<FormField
			legend={ legend }
			required={ required }
			requiredMark={ false }
			className={ classNames.join(' ') }
			errors={ errors }
		>

			<EquipmentListBoxWrap
				title={ title }
				placeholder={ placeholder }
				items={ items }
				onDelete={ onDelete }
				changeAmount={ changeAmount }
			/>
		</FormField>
	);
}
