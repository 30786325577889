import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_SOURCE_USER_DEPARTMENT } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserDepartment, TUserDepartmentList, TUserDepartmentListResponse, adaptUserDepartmentFromListResponse } from '../../types';


export const sourceUserDepartmentListGetApi = createAsyncThunk(
	`FETCH/${BACKEND_SOURCE_USER_DEPARTMENT}`,
	(payload, thunkApi) => {
		return HttpService.get<TUserDepartmentListResponse>(BACKEND_SOURCE_USER_DEPARTMENT).then(result => {
			return adaptUserDepartmentFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TUserDepartment[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
};

export const sourceUserDepartmentListSlice = createSlice({
	name: 'sourceUserDepartmentListSlice',
	initialState,
	reducers: {
		clearSourceUserDepartmentList(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[sourceUserDepartmentListGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceUserDepartmentListGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartmentList>) => {
			state.isLoading = false;
			state.items = action.payload.items;
			state.error = undefined;
		},

		[sourceUserDepartmentListGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceUserDepartmentListSelector = () => useAppSelector(state => state[sourceUserDepartmentListSlice.name]);

export const { clearSourceUserDepartmentList } = sourceUserDepartmentListSlice.actions;
