// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_table__3L4Ny {\n  background-color: rgb(10, 10, 10);\n  border-collapse: collapse;\n  color: rgb(0, 0, 0);\n  font-size: 1em;\n  width: 100%;\n  margin: 0 auto;\n}\n.Table_table__3L4Ny tbody {\n  color: rgb(0, 0, 0);\n}", "",{"version":3,"sources":["webpack://./src/components/table/Table.module.scss"],"names":[],"mappings":"AAGA;EACC,iCAAA;EACC,yBAAA;EACD,mBANO;EAON,cAAA;EACA,WAAA;EACA,cAAA;AAFF;AAIC;EACC,mBAZM;AAUR","sourcesContent":["$color: rgba(0, 0, 0, 1);\n$borderColor: rgba(180, 180, 180, 1);\n\n.table {\n\tbackground-color: rgba(10, 10, 10, 1);\n  border-collapse: collapse;\n\tcolor: $color;\n  font-size: 1em;\n  width: 100%;\n  margin: 0 auto;\n\n\t& tbody {\n\t\tcolor: $color;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "Table_table__3L4Ny"
};
export default ___CSS_LOADER_EXPORT___;
