import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectOrderGroupShiftUpdatePage = lazy(() => import('../views/update/ProjectOrderGroupShiftUpdatePage'));

export function ProjectOrderGroupShiftUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectOrderGroupShiftUpdatePage />
		</PageSuspense>
	);
}
