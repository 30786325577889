import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TGroupShiftExpenseType, TGroupShiftExpenseTypeUpdate, TGroupShiftExpenseTypeResponse, adaptGroupShiftExpenseTypeFromResponse, adaptGroupShiftExpenseTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES } from '../../backend-paths';


export const groupShiftExpenseTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES}/:id`,
	(payload: TGroupShiftExpenseTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES}/${id}`;

		const dataRequest = adaptGroupShiftExpenseTypeToRequest(data);

		return HttpService.put<TGroupShiftExpenseTypeResponse>(path, dataRequest).then(result => {
			return adaptGroupShiftExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TGroupShiftExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const groupShiftExpenseTypeUpdateSlice = createSlice({
	name: 'groupShiftExpenseTypeUpdateSlice',
	initialState,
	reducers: {
		clearGroupShiftExpenseTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[groupShiftExpenseTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[groupShiftExpenseTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[groupShiftExpenseTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useGroupShiftExpenseTypeUpdateSelector = () => useAppSelector(state => state[groupShiftExpenseTypeUpdateSlice.name]);

export const { clearGroupShiftExpenseTypeUpdate } = groupShiftExpenseTypeUpdateSlice.actions;
