// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_modal__YwX8Y {\n  background-color: rgba(0, 0, 0, 0.5);\n  box-sizing: border-box;\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 20;\n}\n\n.Modal_wrap__c6MQz {\n  box-sizing: border-box;\n  position: relative;\n  height: 100%;\n  width: 100%;\n}\n\n.Modal_content__aobLq {\n  background-color: rgb(10, 10, 10);\n  border: 1px solid rgb(60, 60, 60);\n  box-sizing: border-box;\n  max-height: 100%;\n  max-width: 100%;\n  min-width: 300px;\n  padding: 1em;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n}\n@media screen and (max-width: 480px) {\n  .Modal_content__aobLq {\n    height: 100%;\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,sBAAA;EAEA,eAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EAEA,WAAA;AADD;;AAIA;EACC,sBAAA;EAEA,kBAAA;EACA,YAAA;EACA,WAAA;AAFD;;AAKA;EACC,iCAAA;EACA,iCAAA;EACA,sBAAA;EAEA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EAEA,kBAAA;EACA,QAAA;EACA,SAAA;EAEA,qCAAA;AALD;AAOC;EAhBD;IAiBE,YAAA;IACA,WAAA;EAJA;AACF","sourcesContent":[".modal {\n\tbackground-color: rgba(0, 0, 0, 0.5);\n\tbox-sizing: border-box;\n\n\tposition: fixed;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\n\tz-index: 20;\n}\n\n.wrap {\n\tbox-sizing: border-box;\n\n\tposition: relative;\n\theight: 100%;\n\twidth: 100%;\n}\n\n.content {\n\tbackground-color: rgba(10, 10, 10, 1);\n\tborder: 1px solid rgba(60, 60, 60, 1);\n\tbox-sizing: border-box;\n\n\tmax-height: 100%;\n\tmax-width: 100%;\n\tmin-width: 300px;\n\tpadding: 1em;\n\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\n\ttransform: translate3d(-50%, -50%, 0);\n\n\t@media screen and (max-width: 480px) {\n\t\theight: 100%;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal_modal__YwX8Y",
	"wrap": "Modal_wrap__c6MQz",
	"content": "Modal_content__aobLq"
};
export default ___CSS_LOADER_EXPORT___;
