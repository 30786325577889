import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TFinancialChangeType, adaptFinancialChangeTypeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES } from '../../backend-paths';


export const financialChangeTypeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptFinancialChangeTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TFinancialChangeType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const financialChangeTypeDeleteSlice = createSlice({
	name: 'financialChangeTypeDeleteSlice',
	initialState,
	reducers: {
		clearFinancialChangeTypeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[financialChangeTypeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[financialChangeTypeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TFinancialChangeType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[financialChangeTypeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useFinancialChangeTypeDeleteSelector = () => useAppSelector(state => state[financialChangeTypeDeleteSlice.name]);

export const { clearFinancialChangeTypeDelete } = financialChangeTypeDeleteSlice.actions;
