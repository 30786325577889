import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { AccountPositionChangeSortCol, TAccountPositionChangeList, TAccountPositionChangeQuery, TAccountPositionChangeListResponse, TAccountPositionChangeSortCol, adaptAccountPositionChangeFromListResponse, adaptAccountPositionChangeQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_POSITION_CHANGES } from '../../backend-paths';


export const accountPositionChangesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_POSITION_CHANGES}`,
	(payload: TAccountPositionChangeQuery, thunkApi) => {
		const params = payload ? adaptAccountPositionChangeQueryRequest(payload) : {};

		return HttpService.get<TAccountPositionChangeListResponse>(BACKEND_ACCOUNT_POSITION_CHANGES, { params }).then(result => {
			return adaptAccountPositionChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TAccountPositionChangeList,
	query: TAccountPositionChangeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: AccountPositionChangeSortCol.CREATED_AT,
	},
};

export const accountPositionChangesSlice = createSlice({
	name: 'accountPositionChangesSlice',
	initialState,
	reducers: {
		setAccountPositionChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountPositionChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountPositionChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountPositionChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountPositionChangesQuerySortCol(state, action: PayloadAction<TAccountPositionChangeSortCol>) {
			state.query.sortCol = action.payload;
		},

		setAccountPositionChangesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountPositionChangesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearAccountPositionChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountPositionChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPositionChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountPositionChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountPositionChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountPositionChangeSelector = () => useAppSelector(state => state[accountPositionChangesSlice.name]);

export const { clearAccountPositionChanges, setAccountPositionChangesQueryLimit, setAccountPositionChangesQueryOffset, setAccountPositionChangesQuerySort, setAccountPositionChangesQuerySortCol, setAccountPositionChangesQuerySearch, setAccountPositionChangesQueryDateStart, setAccountPositionChangesQueryDateEnd } = accountPositionChangesSlice.actions;
