import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TListResponse } from 'src/common/types';


export const ProjectsClientsContactsSortCol = {
	ID: 'id',
	NAME: 'name',
	POSITION: 'official_name',
	PHONE_NUMBER: 'phone_number',
	EMAIL: 'email',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsClientsContactsSortCol = TTableSortCols<typeof ProjectsClientsContactsSortCol>;

export type TProjectClientContactQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsClientsContactsSortCol;
};

export type TProjectClientContactQueryPayload = {
	clientId: number;
	data?: TProjectClientContactQuery;
};

export type TProjectsClientsContactsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsClientsContactsQueryToRequest(data: TProjectClientContactQuery): TProjectsClientsContactsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectClientContact = {
  id: number;
  clientId: number;
  name: string;
  position?: string;
  description?: string;
  phoneNumber?: string;
  email?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectClientContactResponse = {
  id: number;
  client_id: number;
  name: string;
  position?: string;
  description?: string;
  phone_number?: string;
	email?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectClientContactListResponse = TListResponse<TProjectClientContactResponse>;

export type TProjectClientContactList = TListResponse<TProjectClientContact>;

export type TProjectClientContactBodyRequest = {
	name: string;
	position?: string;
	description?: string;
	phoneNumber?: string;
	email?: string;
};

export type TProjectClientContactRequest = {
	name: string;
	position?: string;
	description?: string;
	phone_number?: string;
	email?: string;
};

export type TProjectClientContactPayloadParams = {
	id: number;
	clientId: number;
};

export type TProjectClientContactPayloadCreate = {
	clientId: number;
	data: TProjectClientContactBodyRequest;
};

export type TProjectClientContactPayloadUpdate = TProjectClientContactPayloadCreate & {
	id: number;
};

export function adaptProjectClientContactFromListResponse(data: TProjectClientContactListResponse): TProjectClientContactList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectClientContactFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectClientContactFromResponse(data: TProjectClientContactResponse): TProjectClientContact {
	const {
		id,
		client_id: clientId,
		name,
		position,
		description,
		phone_number: phoneNumber,
		email,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		clientId,
		name,
		position,
		description,
		phoneNumber,
		email,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptProjectClientContactToRequest(data: TProjectClientContactBodyRequest): TProjectClientContactRequest {
	const {
		name,
		position = undefined,
		description = undefined,
		phoneNumber = undefined,
		email = undefined,
	} = data;

	return {
		name,
		position,
		description,
		phone_number: phoneNumber,
		email,
	};
}
