import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingTypeUpdatePage = lazy(() => import('../views/update/SystemSettingTypeUpdatePage'));

export function SystemSettingTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingTypeUpdatePage />
		</PageSuspense>
	);
}
