import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsUsersPage = lazy(() => import('../views/list/EquipmentsUsersPage'));

export function EquipmentsUsersPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsUsersPage />
		</PageSuspense>
	);
}
