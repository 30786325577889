import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TExpenseType, TExpenseTypeUpdate, TExpenseTypeResponse, adaptExpenseTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EXPENSES_TYPES } from '../../backend-paths';


export const expenseTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EXPENSES_TYPES}/:id`,
	(payload: TExpenseTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EXPENSES_TYPES}/${id}`;

		return HttpService.put<TExpenseTypeResponse>(path, data).then(result => {
			return adaptExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const expenseTypeUpdateSlice = createSlice({
	name: 'expenseTypeUpdateSlice',
	initialState,
	reducers: {
		clearExpenseTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[expenseTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[expenseTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[expenseTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useExpenseTypeUpdateSelector = () => useAppSelector(state => state[expenseTypeUpdateSlice.name]);

export const { clearExpenseTypeUpdate } = expenseTypeUpdateSlice.actions;
