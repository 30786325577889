import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserFinancialChangeUpdatePage = lazy(() => import('../views/update/UserFinancialChangeUpdatePage'));

export function UserFinancialChangeUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserFinancialChangeUpdatePage />
		</PageSuspense>
	);
}
