import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserFinancialChange, adaptUserFinancialChangeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_FINANCIAL_CHANGES } from '../../backend-paths';


export const userFinancialChangeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptUserFinancialChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserFinancialChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userFinancialChangeDeleteSlice = createSlice({
	name: 'userFinancialChangeDeleteSlice',
	initialState,
	reducers: {
		clearUserFinancialChangeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userFinancialChangeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userFinancialChangeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserFinancialChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userFinancialChangeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserFinancialChangeDeleteSelector = () => useAppSelector(state => state[userFinancialChangeDeleteSlice.name]);

export const { clearUserFinancialChangeDelete } = userFinancialChangeDeleteSlice.actions;
