import { TEquipmentSource, TEquipmentSourceResponse, adaptEquipmentSourceFromResponse } from './equipment-source.type';


export type TEquipmentOrderHistoryDataRelationEquipment = {
  amount: number;

  equipment: TEquipmentSource;
};

export type TEquipmentOrderHistoryDataRelationEquipmentResponse = {
  amount: number;

	equipment: TEquipmentSourceResponse;
};

export type TEquipmentOrderHistoryDataRelationEquipmentCreate = Pick<TEquipmentOrderHistoryDataRelationEquipment, 'amount' | 'equipment'>;

export function adaptEquipmentOrderHistoryDataRelationEquipmentFromResponse(data: TEquipmentOrderHistoryDataRelationEquipmentResponse): TEquipmentOrderHistoryDataRelationEquipment {
	const {
		amount,

		equipment: equipmentRaw,
	} = data;

	const equipment = adaptEquipmentSourceFromResponse(equipmentRaw);

	return {
		amount,
		equipment,
	};
}
