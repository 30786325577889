import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserTypeHistory, TUserTypeHistoryParams, TUserTypeHistoryResponse, adaptUserTypeHistoryFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userTypeHistoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/history/type/:id`,
	(payload: TUserTypeHistoryParams, thunkApi) => {
		const { userNickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/history/type/${id}`;

		return HttpService.get<TUserTypeHistoryResponse>(path).then(result => {
			return adaptUserTypeHistoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTypeHistory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeHistoryReadSlice = createSlice({
	name: 'userTypeHistoryReadSlice',
	initialState,
	reducers: {
		setUserTypeHistoryRead(state, action: PayloadAction<TUserTypeHistory>) {
			state.item = action.payload;
		},

		clearUserTypeHistoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeHistoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeHistoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserTypeHistory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTypeHistoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeHistoryReadSelector = () => useAppSelector(state => state[userTypeHistoryReadSlice.name]);

export const { clearUserTypeHistoryRead, setUserTypeHistoryRead } = userTypeHistoryReadSlice.actions;
