import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentStorage, TEquipmentStorageUpdate, TEquipmentStorageResponse, adaptEquipmentStorageFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_STORAGES } from '../../backend-paths';


export const equipmentStorageUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS_STORAGES}/:id`,
	(payload: TEquipmentStorageUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_STORAGES}/${id}`;

		return HttpService.put<TEquipmentStorageResponse>(path, data).then(result => {
			return adaptEquipmentStorageFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentStorage> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentStorageUpdateSlice = createSlice({
	name: 'equipmentStorageUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentStorageUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentStorageUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentStorageUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentStorage>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentStorageUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentStorageUpdateSelector = () => useAppSelector(state => state[equipmentStorageUpdateSlice.name]);

export const { clearEquipmentStorageUpdate } = equipmentStorageUpdateSlice.actions;
