import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserOverwork, TUserOverworkPayloadCreate, TUserOverworkResponse, adaptUserOverworkFromResponse, adaptUserOverworkToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_OVERWORKS } from '../../backend-paths';


export const userOverworkCreateApi = createAsyncThunk(
	`POST/${BACKEND_USERS_OVERWORKS}`,
	(payload: TUserOverworkPayloadCreate, thunkApi) => {
		const { data } = payload;

		const dataRequest = adaptUserOverworkToRequest(data);

		return HttpService.post<TUserOverworkResponse>(BACKEND_USERS_OVERWORKS, dataRequest).then(result => {
			return adaptUserOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userOverworkCreateSlice = createSlice({
	name: 'userOverworkCreateSlice',
	initialState,
	reducers: {
		clearUserOverworkCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userOverworkCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userOverworkCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userOverworkCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserOverworkCreateSelector = () => useAppSelector(state => state[userOverworkCreateSlice.name]);

export const { clearUserOverworkCreate } = userOverworkCreateSlice.actions;
