import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { HttpError } from 'src/toolkit';

import { store } from '../store';


type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export type TSliceInitialState<T> = {
	item?: T;
	isLoading: boolean;
	error?: HttpError;
};


export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
