import { ReactNode, UIEvent, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'src/components';
import { TAccountCredentials, clearAccountCredentials, clearSignOut, signOutApi, useAppDispatch, useSignOutSelector } from 'src/store';
import { PATH_ACCOUNT, PATH_ACCOUNT_BOOKKEEPING, PATH_ACCOUNT_DATA, PATH_ACCOUNT_INTERNATIONAL_PASSPORT, PATH_ACCOUNT_PASSPORT, PATH_ACCOUNT_REQUISITES, PATH_ACCOUNT_SHIFTS, PATH_ACCOUNT_TAXATION_DATA, PATH_ACCOUNT_WORK_DATA, PATH_ARTICLES, PATH_BOOKKEEPING, PATH_EXPENSES_TYPES, PATH_ARTICLES_PUBLIC, PATH_PAYMENTS_TYPES, PATH_PRODUCTION_CALENDARS, PATH_PROJECTS, PATH_PROJECTS_CLIENTS, PATH_USERS, PATH_USERS_CATEGORIES, PATH_USERS_POSITIONS, PATH_USERS_SHIFTS, PATH_USERS_TAXATIONS, PATH_USERS_TYPES, PATH_WORK_AREAS, PERMISSIONS, PATH_SCHEDULE, PATH_SCHEDULE_EVENTS_TYPES, PATH_SCHEDULE_EVENTS, PATH_EQUIPMENTS_STORAGES, PATH_EQUIPMENTS_CATEGORIES_HIERARCHY, PATH_EQUIPMENTS, PATH_EQUIPMENTS_GROUPS, PATH_EQUIPMENTS_ORDERS_STATUSES, PATH_EQUIPMENTS_ORDERS, PATH_EQUIPMENTS_HIERARCHY, PATH_EQUIPMENTS_CATEGORIES, PATH_GROUPS_SHIFTS_EXPENSES_TYPES, PATH_FINANCIAL_CHANGES_TYPES, PATH_USERS_FINANCIAL_CHANGES, PATH_USERS_EXPENSES_TYPES, PATH_USERS_EXPENSES, PATH_ACCOUNT_EXPENSES, PATH_USERS_DEPARTMENTS, PATH_USERS_OVERWORKS, PATH_ACCOUNT_OVERWORKS, PATH_SYSTEM_SETTINGS_TYPES, PATH_SYSTEM_SETTINGS, PATH_SYSTEM_SETTINGS_USERS, PATH_SYSTEM_SETTINGS_AMOCRM, PATH_SYSTEM_SETTINGS_CALENDAR_EVENTS, PATH_SYSTEM_SETTINGS_BOOKKEEPING, PATH_SYSTEM_SETTINGS_REPORTS, PATH_SYSTEM_SETTINGS_SHIFTS, PATH_PROJECTS_STATUSES, PATH_ACCOUNT_TAXATION_RECEIPTS, PATH_USERS_TAXATIONS_RECEIPTS, PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES, PATH_PROJECTS_ORDERS_TAGS_TYPES } from 'src/routes';
import { useIsMobile } from 'src/toolkit';

import { AsideItemList } from '../aside-item-list';
import { TAsideItem } from '../aside-item';

import navMenuStyles from '../NavMenu.module.scss';

import styles from './Aside.module.scss';


export const ASIDE_PORTAL_CONTAINER_ID = 'aside-menu-box';
export const ASIDE_SCROLL_CONTAINER_ID = 'aside-scroll-box';
export const ASIDE_MENU_ROW_CLASS_NAME = 'aside-menu-row-box';

type TAsideProps = {
	children?: ReactNode;
	style?: Record<string, string>;
	isVisible?: boolean;
	credentials: TAccountCredentials;
	onClick?: () => void;
};

function getMenu(): TAsideItem[][] {
	return [
		[
			{
				text: 'Аккаунт',
				icon: 'icon-gears',
				includes: [PATH_ACCOUNT],

				options: [
					{ text: 'Настройки', href: PATH_ACCOUNT },
					{ text: 'Личные данные', permission: PERMISSIONS.ACCOUNT_DATA, href: PATH_ACCOUNT_DATA },
					{ text: 'Рабочие данные', permission: PERMISSIONS.ACCOUNT_WORK_DATA, href: PATH_ACCOUNT_WORK_DATA },
					{ text: 'Налогообложение', permission: PERMISSIONS.ACCOUNT_TAXATION_DATA, href: PATH_ACCOUNT_TAXATION_DATA },
					{ text: 'Мои налоговые чеки', permission: PERMISSIONS.ACCOUNT_TAXATION_RECEIPTS, href: PATH_ACCOUNT_TAXATION_RECEIPTS },
					{ text: 'Реквизиты', permission: PERMISSIONS.ACCOUNT_REQUISITES, href: PATH_ACCOUNT_REQUISITES },
					{ text: 'Паспортные данные', permission: PERMISSIONS.ACCOUNT_PASSPORT, href: PATH_ACCOUNT_PASSPORT },
					{ text: 'Загран. паспорт', permission: PERMISSIONS.ACCOUNT_INTERNATIONAL_PASSPORT, href: PATH_ACCOUNT_INTERNATIONAL_PASSPORT },
					{ text: 'Мои отчёты', permission: PERMISSIONS.ACCOUNT_REPORTS, href: PATH_ACCOUNT_SHIFTS },
					{ text: 'Финансовый отчёт', permission: PERMISSIONS.ACCOUNT_BOOKKEEPING, href: PATH_ACCOUNT_BOOKKEEPING },
					{ text: 'Мои расходы', permission: PERMISSIONS.ACCOUNT_EXPENSES, href: PATH_ACCOUNT_EXPENSES },
					{ text: 'Мои переработки', permission: PERMISSIONS.ACCOUNT_OVERWORKS, href: PATH_ACCOUNT_OVERWORKS },
				],
			},
		],

		[
			{
				text: 'Расписание',
				icon: 'icon-calendar',
				permission: PERMISSIONS.RETRIEVE_SCHEDULE,
				includes: [PATH_SCHEDULE],

				options: [
					{ text: 'Все', permission: PERMISSIONS.RETRIEVE_SCHEDULE, href: PATH_SCHEDULE },
					{ text: 'События', permission: PERMISSIONS.RETRIEVE_SCHEDULE_EVENTS, href: PATH_SCHEDULE_EVENTS },
					{ text: 'Типы событий', permission: PERMISSIONS.RETRIEVE_SCHEDULE_EVENTS_TYPES, href: PATH_SCHEDULE_EVENTS_TYPES },
				],
			},

			{
				text: 'Пользователи',
				icon: 'icon-users',
				permission: PERMISSIONS.RETRIEVE_USERS,
				includes: [PATH_USERS, PATH_USERS_TYPES, PATH_USERS_POSITIONS, PATH_USERS_CATEGORIES, PATH_USERS_DEPARTMENTS, PATH_USERS_TAXATIONS],

				options: [
					{ text: 'Сотрудники', permission: PERMISSIONS.RETRIEVE_USERS, href: PATH_USERS },
					{ text: 'Типы', permission: PERMISSIONS.RETRIEVE_USERS_TYPES, href: PATH_USERS_TYPES },
					{ text: 'Должности', permission: PERMISSIONS.RETRIEVE_USERS_POSITIONS, href: PATH_USERS_POSITIONS },
					{ text: 'Категории', permission: PERMISSIONS.RETRIEVE_USERS_CATEGORIES, href: PATH_USERS_CATEGORIES },
					{ text: 'Отделы сотрудников', permission: PERMISSIONS.RETRIEVE_USERS_DEPARTMENTS, href: PATH_USERS_DEPARTMENTS },
					{ text: 'Налогообложение', permission: PERMISSIONS.RETRIEVE_USERS_TAXATIONS, href: PATH_USERS_TAXATIONS },
				],
			},

			{
				text: 'Проекты',
				icon: 'icon-briefcase',
				permission: PERMISSIONS.RETRIEVE_PROJECTS,
				includes: [PATH_PROJECTS, PATH_PROJECTS_CLIENTS, PATH_PAYMENTS_TYPES, PATH_EXPENSES_TYPES, PATH_GROUPS_SHIFTS_EXPENSES_TYPES, PATH_WORK_AREAS, PATH_FINANCIAL_CHANGES_TYPES, PATH_USERS_EXPENSES_TYPES, PATH_USERS_EXPENSES, PATH_PROJECTS_STATUSES],

				options: [
					{ text: 'Все', permission: PERMISSIONS.RETRIEVE_PROJECTS, href: PATH_PROJECTS },
					{ text: 'Смены', permission: PERMISSIONS.RETRIEVE_USERS_SHIFTS, href: PATH_USERS_SHIFTS },
					{ text: 'Клиенты', permission: PERMISSIONS.RETRIEVE_PROJECTS_CLIENTS, href: PATH_PROJECTS_CLIENTS },
					{ text: 'Статусы проектов', permission: PERMISSIONS.RETRIEVE_PROJECTS_STATUSES, href: PATH_PROJECTS_STATUSES },
					{ text: 'Типы оплат', permission: PERMISSIONS.RETRIEVE_PAYMENTS_TYPES, href: PATH_PAYMENTS_TYPES },
					{ text: 'Типы расходов', permission: PERMISSIONS.RETRIEVE_EXPENSES_TYPES, href: PATH_EXPENSES_TYPES },
					{ text: 'Типы расходов для смен', permission: PERMISSIONS.RETRIEVE_GROUPS_SHIFTS_EXPENSES_TYPES, href: PATH_GROUPS_SHIFTS_EXPENSES_TYPES },
					{ text: 'Типы расходов пользователей', permission: PERMISSIONS.RETRIEVE_USERS_EXPENSES_TYPES, href: PATH_USERS_EXPENSES_TYPES },
					{ text: 'Расходы пользователей', permission: PERMISSIONS.RETRIEVE_USERS_EXPENSES, href: PATH_USERS_EXPENSES },
					{ text: 'Направления работ', permission: PERMISSIONS.RETRIEVE_WORK_AREAS, href: PATH_WORK_AREAS },
					{ text: 'Типы финансовых изменений', permission: PERMISSIONS.RETRIEVE_FINANCIAL_CHANGES_TYPES, href: PATH_FINANCIAL_CHANGES_TYPES },
					{ text: 'Типы тегов отчётов', permission: PERMISSIONS.RETRIEVE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES, href: PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES },
					{ text: 'Типы тегов заявок', permission: PERMISSIONS.RETRIEVE_PROJECTS_ORDERS_TAGS_TYPES, href: PATH_PROJECTS_ORDERS_TAGS_TYPES },
				],
			},

			{
				text: 'Бухгалтерия',
				icon: 'icon-sack',
				permission: PERMISSIONS.RETRIEVE_BOOKKEEPING,
				includes: [PATH_BOOKKEEPING, PATH_USERS_FINANCIAL_CHANGES, PATH_PRODUCTION_CALENDARS],

				options: [
					{ text: 'Все', permission: PERMISSIONS.RETRIEVE_BOOKKEEPING, href: PATH_BOOKKEEPING },
					{ text: 'Финансовые изменения сотрудников', permission: PERMISSIONS.RETRIEVE_USERS_FINANCIAL_CHANGES, href: PATH_USERS_FINANCIAL_CHANGES },
					{ text: 'Производственные календари', permission: PERMISSIONS.RETRIEVE_PRODUCTION_CALENDARS, href: PATH_PRODUCTION_CALENDARS },
					{ text: 'Переработки сотрудников', permission: PERMISSIONS.RETRIEVE_USERS_OVERWORKS, href: PATH_USERS_OVERWORKS },
					{ text: 'Чеки сотрудников', permission: PERMISSIONS.RETRIEVE_USERS_TAXATIONS_RECEIPTS, href: PATH_USERS_TAXATIONS_RECEIPTS },
				],
			},

			{
				text: 'Склад',
				icon: 'icon-stock',
				permission: PERMISSIONS.RETRIEVE_EQUIPMENTS,
				includes: [
					PATH_EQUIPMENTS,
					PATH_EQUIPMENTS_HIERARCHY,
					PATH_EQUIPMENTS_CATEGORIES,
					PATH_EQUIPMENTS_CATEGORIES_HIERARCHY,
					PATH_EQUIPMENTS_GROUPS,
					PATH_EQUIPMENTS_ORDERS,
					PATH_EQUIPMENTS_ORDERS_STATUSES,
					PATH_EQUIPMENTS_STORAGES,
				],

				options: [
					{ text: 'Оборудование', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS, href: PATH_EQUIPMENTS_HIERARCHY, links: [PATH_EQUIPMENTS, PATH_EQUIPMENTS_HIERARCHY] },
					{ text: 'Категории оборудования', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS_CATEGORIES, href: PATH_EQUIPMENTS_CATEGORIES_HIERARCHY, links: [PATH_EQUIPMENTS_CATEGORIES, PATH_EQUIPMENTS_CATEGORIES_HIERARCHY] },
					{ text: 'Комплекты оборудования', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS_GROUPS, href: PATH_EQUIPMENTS_GROUPS },
					{ text: 'Заявки на оборудование', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS_ORDERS, href: PATH_EQUIPMENTS_ORDERS },
					{ text: 'Статусы заявок на оборудование', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS_ORDERS_STATUSES, href: PATH_EQUIPMENTS_ORDERS_STATUSES },
					{ text: 'Склады', permission: PERMISSIONS.RETRIEVE_EQUIPMENTS_STORAGES, href: PATH_EQUIPMENTS_STORAGES },
				],
			},

			{
				text: 'Информация',
				icon: 'icon-info',
				permission: PERMISSIONS.RETRIEVE_PROJECTS,
				includes: [PATH_ARTICLES_PUBLIC],

				options: [
					{ text: 'Просмотр', permission: PERMISSIONS.ACCOUNT_ARTICLES, href: PATH_ARTICLES_PUBLIC },
					{ text: 'Редактор', permission: PERMISSIONS.RETRIEVE_ARTICLES, href: PATH_ARTICLES },
				],
			},

			{
				text: 'Настройки',
				icon: 'icon-cogs',
				permission: PERMISSIONS.RETRIEVE_PROJECTS,
				includes: [PATH_SYSTEM_SETTINGS],

				options: [
					{ text: 'Типы системных настроек', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_TYPES, href: PATH_SYSTEM_SETTINGS_TYPES },
					{ text: 'Cистемные настройки', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS, href: PATH_SYSTEM_SETTINGS },
					{ text: 'Настройки пользователей', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_USERS, href: PATH_SYSTEM_SETTINGS_USERS },
					{ text: 'Настройки смен', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS, href: PATH_SYSTEM_SETTINGS_SHIFTS },
					{ text: 'Настройки отчётов', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS, href: PATH_SYSTEM_SETTINGS_REPORTS },
					{ text: 'Настройки бухгалтерии', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_BOOKKEEPING, href: PATH_SYSTEM_SETTINGS_BOOKKEEPING },
					{ text: 'Настройки событий календаря', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_CALENDAR_EVENTS, href: PATH_SYSTEM_SETTINGS_CALENDAR_EVENTS },
					{ text: 'Настройки AmoCRM', permission: PERMISSIONS.RETRIEVE_SYSTEM_SETTINGS_AMOCRM, href: PATH_SYSTEM_SETTINGS_AMOCRM },
				],
			},
		],
	];
}

export function Aside(props: TAsideProps) {
	const dispatch = useAppDispatch();
	const { isTablet } = useIsMobile();
	const { isDone } = useSignOutSelector();
	const [scroll, setScroll] = useState(0);

	const {
		isVisible = false,
		style = {},
		credentials,
		onClick: hideAside = () => {},
	} = props;

	const { nickname: name = '', permissions, image = '' } = credentials;

	const onScroll = (e: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
		const elem = e.target;

		if (elem instanceof HTMLElement) {
			setScroll(elem.scrollTop);
		}
	}

	const onClick = () => {
		if (isTablet) {
			hideAside();
		}
	}

	const onLogout = () => {
		onClick();

		dispatch(signOutApi());
	};

	const menu = getMenu();

	useEffect(() => {
		if (isDone === true) {
			dispatch(clearAccountCredentials());
			dispatch(clearSignOut());
		}
	}, [isDone]);

	const protectedMenu = useMemo(() => {
		return menu.filter(item => {
			const links = item.filter(item => {
				const { permission: role } = item;

				return role ? permissions.includes(role) : true;
			});

			return links.length > 0;
		});
	}, [menu, credentials]);

	const classNames = [styles.aside, navMenuStyles.asideShow];

	if (isVisible) {
		classNames.push(styles.active);
	}

	const linkClassNames = [styles.asideLinkStyle, styles.avatarLink];
	const scrollListClassNames = ['scroll-box', styles.scrollList];

	return (
		<aside className={ classNames.join(' ') } style={ style }>
			<div className={ styles.asideRelativeBox }>
				<div className={ styles.header }>
					<div className={ styles.avatarWrapLink }>
						<NavLink
							className={ linkClassNames.join(' ') }
							to={ PATH_ACCOUNT }
							onClick={ onClick }
						>
							<span className={ styles.avatarWrap }>
								{ image && <img src={ image } className={ styles.avatarImage } alt="user_image" /> }
								{ !image && <span className={ styles.symbol }>{ name.slice(0, 1).toUpperCase() }</span> }
							</span>
							{ name }
						</NavLink>
					</div>
				</div>

				<div id={ ASIDE_PORTAL_CONTAINER_ID } className={ styles.portalContainer }></div>

				<div id={ ASIDE_SCROLL_CONTAINER_ID } className={ scrollListClassNames.join(' ') } onScroll={ onScroll }>
					{
						protectedMenu.map((items, index) => <AsideItemList key={ index } items={ items } onClick={ onClick } scroll={ scroll } />)
					}

					<div className={ styles.exit } onClick={ onLogout }>
						<span>
							<Icon icon="icon-exit" className={ styles.iconLink }/>
							Выход
						</span>
					</div>
				</div>
			</div>
		</aside>
	);
}
