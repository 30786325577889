import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountTypeChange, TAccountTypeChangeResponse, adaptAccountTypeChangeFromResponse } from '../../types';
import { BACKEND_ACCOUNT_TYPE_CHANGES } from '../../backend-paths';


export const accountTypeChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_TYPE_CHANGES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_TYPE_CHANGES}/${payload}`;

		return HttpService.get<TAccountTypeChangeResponse>(path).then(result => {
			return adaptAccountTypeChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTypeChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTypeChangeReadSlice = createSlice({
	name: 'accountTypeChangeReadSlice',
	initialState,
	reducers: {
		setAccountTypeChangeRead(state, action: PayloadAction<TAccountTypeChange>) {
			state.item = action.payload;
		},

		clearAccountTypeChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTypeChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountTypeChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountTypeChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTypeChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountTypeChangeReadSelector = () => useAppSelector(state => state[accountTypeChangeReadSlice.name]);

export const { clearAccountTypeChangeRead, setAccountTypeChangeRead } = accountTypeChangeReadSlice.actions;
