import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountEmailConfirmPage = lazy(() => import('../views/email-confirm/AccountEmailConfirmPage'));

export function AccountEmailConfirmPageLazy() {
	return (
		<PageSuspense>
			<AccountEmailConfirmPage />
		</PageSuspense>
	);
}
