import { RefObject, useRef, useState } from 'react';

import { FormButton, FormField, FormInputFile, FormRow, Icon } from 'src/components';
import { TInputFileField, libFileToBase64 } from 'src/toolkit';

import { FormImageSvgDisplay } from './components';


function clearInput(ref: RefObject<HTMLInputElement>): void {
	const elem = ref.current;

	if (elem) {
		elem.value = '';
	}
};

type TProps = TInputFileField & {
	setFile: (value: File) => void;
	legend?: string;
	selectImageText?: string;
	changeImageText?: string;
	defaultSrc?: string;
	accept?: string;
	isButton?: boolean;
};

export function FormImageSvg(props: TProps) {
	const {
		legend = '',
		required = false,
		value,
		setValue,
		name,
		errors,
		setFile,
		selectImageText = 'Select image',
		changeImageText = 'Change image',
		defaultSrc = '',
		accept = '.svg',
		isButton: isButtonRaw,
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [src, setSrc] = useState(value);

	const remove = () => {
		setSrc(defaultSrc);
		setValue('');
		clearInput(inputRef);
	};

	const onChange = (files: FileList | null) => {
		if (!files) {
			return;
		}

		const file = files[0] || undefined;
		setFile(file);

		if (!file) {
			return;
		}

		clearInput(inputRef);

		libFileToBase64(file).then(result => {
			setSrc(result);
		}, error => {
			console.log('error');
			console.log(error);
		});
	};

	const isEmpty = src === defaultSrc;
	const buttonText = isEmpty ? selectImageText : changeImageText;
	const isButton = isButtonRaw === undefined ? true : isButtonRaw;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
		>
			<FormImageSvgDisplay src={ src } alt={ name } />
			{/* <img className={ styles.img } onLoad={ onLoad } src={ src } alt={ name } /> */}

			{ isButton &&
				<FormRow>
					<FormButton file>
						{ buttonText }
						<FormInputFile
							type="file"
							accept={ accept }
							name={ name }
							onChange={ e => onChange(e.target.files) }
							ref={ inputRef }
						/>
					</FormButton>

					{ !isEmpty &&
						<FormButton
							onClick={ remove }
							tabIndex={ -1 }

							type="button"
							mini
						>
							<Icon icon="icon-bin"/>
						</FormButton>
					}
				</FormRow>
			}
		</FormField>
	);
}
