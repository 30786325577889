import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProductionCalendarDatesOff, TProductionCalendarDatesOffUpdate, TProductionCalendarDatesOffResponse, adaptProductionCalendarDatesOffFromResponse, adaptProductionCalendarDatesOffToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarDatesOffUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PRODUCTION_CALENDARS}/:id/dates-off`,
	(payload: TProductionCalendarDatesOffUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_PRODUCTION_CALENDARS}/${id}/dates-off`;

		const dataRequest = adaptProductionCalendarDatesOffToRequest(data, id);

		return HttpService.put<TProductionCalendarDatesOffResponse>(path, dataRequest).then(result => {

			return adaptProductionCalendarDatesOffFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProductionCalendarDatesOff> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarDatesOffUpdateSlice = createSlice({
	name: 'productionCalendarDatesOffUpdateSlice',
	initialState,
	reducers: {
		clearProductionCalendarDatesOffUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarDatesOffUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[productionCalendarDatesOffUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendarDatesOff>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarDatesOffUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProductionCalendarDatesOffUpdateSelector = () => useAppSelector(state => state[productionCalendarDatesOffUpdateSlice.name]);

export const { clearProductionCalendarDatesOffUpdate } = productionCalendarDatesOffUpdateSlice.actions;
