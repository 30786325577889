import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountRequisites, TAccountRequisitesResponse, adaptAccountRequisitesFromResponse } from '../../types';
import { BACKEND_ACCOUNT_REQUISITES } from '../../backend-paths';


export const accountRequisitesReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_REQUISITES}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountRequisitesResponse>(BACKEND_ACCOUNT_REQUISITES).then(result => {
			return adaptAccountRequisitesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountRequisites> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountRequisitesReadSlice = createSlice({
	name: 'accountRequisitesReadSlice',
	initialState,
	reducers: {
		setAccountRequisitesRead(state, action: PayloadAction<TAccountRequisites>) {
			state.item = action.payload;
		},

		clearAccountRequisitesRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountRequisitesReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountRequisitesReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountRequisites>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountRequisitesReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountRequisitesReadSelector = () => useAppSelector(state => state[accountRequisitesReadSlice.name]);

export const { clearAccountRequisitesRead, setAccountRequisitesRead } = accountRequisitesReadSlice.actions;
