import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountShiftExpensesPage = lazy(() => import('../views/list/AccountShiftExpensesPage'));

export function AccountShiftExpensesPageLazy() {
	return (
		<PageSuspense>
			<AccountShiftExpensesPage />
		</PageSuspense>
	);
}
