import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserPositionChangePayloadUpdate, TUserResponse, adaptUserFromResponse, adaptUserPositionChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPositionChangeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/positions/:id`,
	(payload: TUserPositionChangePayloadUpdate, thunkApi) => {
		const { nickname, id, data } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/positions/${id}`;
		const dataRequest = adaptUserPositionChangeToRequest(data);

		return HttpService.put<TUserResponse>(path, dataRequest).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionChangeUpdateSlice = createSlice({
	name: 'userPositionChangeUpdateSlice',
	initialState,
	reducers: {
		clearUserPositionChangeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionChangeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionChangeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionChangeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionChangeUpdateSelector = () => useAppSelector(state => state[userPositionChangeUpdateSlice.name]);

export const { clearUserPositionChangeUpdate } = userPositionChangeUpdateSlice.actions;
