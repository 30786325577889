import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProductionCalendar, TProductionCalendarBodyRequest, TProductionCalendarResponse, adaptProductionCalendarFromResponse, adaptProductionCalendarToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarCreateApi = createAsyncThunk(
	`POST/${BACKEND_PRODUCTION_CALENDARS}`,
	(payload: TProductionCalendarBodyRequest, thunkApi) => {
		const dataRequest = adaptProductionCalendarToRequest(payload);

		return HttpService.post<TProductionCalendarResponse>(BACKEND_PRODUCTION_CALENDARS, dataRequest).then(result => {
			return adaptProductionCalendarFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProductionCalendar> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarCreateSlice = createSlice({
	name: 'productionCalendarCreateSlice',
	initialState,
	reducers: {
		clearProductionCalendarCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[productionCalendarCreateApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendar>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProductionCalendarCreateSelector = () => useAppSelector(state => state[productionCalendarCreateSlice.name]);

export const { clearProductionCalendarCreate } = productionCalendarCreateSlice.actions;
