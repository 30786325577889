import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const PaymentsTypesPage = lazy(() => import('../views/list/PaymentsTypesPage'));

export function PaymentsTypesPageLazy() {
	return (
		<PageSuspense>
			<PaymentsTypesPage />
		</PageSuspense>
	);
}
