import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TEquipmentCategory, adaptEquipmentCategoryFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentCategoryDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptEquipmentCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentCategoryDeleteSlice = createSlice({
	name: 'equipmentCategoryDeleteSlice',
	initialState,
	reducers: {
		clearEquipmentCategoryDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentCategoryDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentCategoryDeleteApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentCategoryDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentCategoryDeleteSelector = () => useAppSelector(state => state[equipmentCategoryDeleteSlice.name]);

export const { clearEquipmentCategoryDelete } = equipmentCategoryDeleteSlice.actions;
