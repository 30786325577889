import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import { FormButtonLink, FormField, FormInput, FormRow, Icon } from 'src/components';

import styles from './FormInputFieldReadonly.module.scss';


export const enum FormInputFieldReadonlyFontColor {
	DEFAULT = 0,
	RED = 1,
	GREEN = 2,
}

export type TFormInputFieldReadonlyFontColor = typeof FormInputFieldReadonlyFontColor[keyof typeof FormInputFieldReadonlyFontColor];

type TProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
	legend?: string;
	marginLeft?: boolean;
	marginRight?: boolean;
	nowrap?: boolean;

	to?: string;
	icon?: string;
	buttonDisabled?: boolean;
	buttonTitle?: string;

	fontColor?: TFormInputFieldReadonlyFontColor;
};

export function FormInputFieldReadonly(props: TProps) {
	const {
		legend = '',
		ref,
		marginLeft = false,
		marginRight = false,
		nowrap = false,

		to,
		icon,
		buttonDisabled,
		buttonTitle,

		fontColor,
		className: inputClassNameOuter = [],

		...restProps
	} = props;

	const classNames = [];

	if (nowrap) {
		classNames.push(styles.nowrap);
	}

	const classNamesInput = inputClassNameOuter ? [inputClassNameOuter] : [];

	if (fontColor) {
		if (fontColor === FormInputFieldReadonlyFontColor.RED) {
			classNamesInput.push(styles.colorRed);
		} else if (fontColor === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesInput.push(styles.colorGreen);
		}
	}

	if (icon && to) {
		return (
			<FormField
				className={ classNames.join(' ') }
				legend={ legend }
				width100
				marginRight={ marginRight }
				marginLeft={ marginLeft }
			>
				<FormRow>
					<FormInput className={ classNamesInput.join(' ') } { ...restProps } />

					<FormButtonLink
						mini
						type="button"
						disabled={ buttonDisabled }
						tabIndex={ -1 }
						to={ to }
						title={ buttonTitle }
					>
						<Icon icon={ icon } />
					</FormButtonLink>
				</FormRow>
			</FormField>
		);
	}

	return (
		<FormField
			className={ classNames.join(' ') }
			legend={ legend }
			marginRight={ marginRight }
			marginLeft={ marginLeft }
		>
			<FormInput className={ classNamesInput.join(' ') } { ...restProps } />
		</FormField>
	);
}
