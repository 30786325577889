import { PageCol } from 'src/components';
import { HeadlineBlock, TextBlock } from 'src/pages/components';

import styles from './ReportContent.module.scss';


type TProps = {
	title?: string;
	text?: string;
};

export function ReportContent(props: TProps) {
	const {
		title = '',
		text = '',
	} = props;

	return (
		<PageCol className={ styles.box }>
			<HeadlineBlock>{ title }</HeadlineBlock>
			<TextBlock>{ text }</TextBlock>
		</PageCol>
	);
}
