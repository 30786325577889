// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Image_img__SxIx7 {\n  box-sizing: border-box;\n  border: 1px solid rgb(60, 60, 60);\n}", "",{"version":3,"sources":["webpack://./src/components/image/Image.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,iCAAA;AACD","sourcesContent":[".img {\n\tbox-sizing: border-box;\n\tborder: 1px solid rgba(60, 60, 60, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "Image_img__SxIx7"
};
export default ___CSS_LOADER_EXPORT___;
