import { ComponentType, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ErrorWidget } from 'src/pages/components';
import { useAccountCredentialsSelector, useAccountRolesSelector } from 'src/store';
import { LC_KEY_IS_AUTH, LocalStorage } from 'src/toolkit';

import { PATH_ACCOUNT, PATH_SIGN_IN } from './paths';


export type TRoute = {
	path: string;
	element: ComponentType;
	permission?: string;
	props?: Record<string, unknown>;
	redirect?: string;
	isForAuthenticated?: boolean;
};

type TProps = {
	items?: TRoute[];
};

function protectItems(items: TRoute[], isAuth?: boolean, permissionsParam?: string[]) {
	const userPermissions = permissionsParam || [];

	return items.map(item => {
		const { permission, redirect, isForAuthenticated } = item;

		if (permission) {
			if (userPermissions.includes(permission)) {
				return item;
			}
		} else {
			const isRedirect = isAuth && isForAuthenticated === false;

			if (!isRedirect) {
				return item;
			}
		}

		if (redirect) {
			return {
				...item,
				element: () => <Navigate to={ PATH_ACCOUNT } replace />,
			};
		}

		if (!isAuth) {
			return {
				...item,
				element: () => <Navigate to={ PATH_SIGN_IN } replace />,
			};
		}

		return {
			...item,
			element: ErrorWidget,
			props: {
				status: 403,
				statusText: 'Forbidden',
				statusTextRu: 'Страница недоступна',
			},
		};
	});
}


export function Router(props: TProps) {
	const { items = [] } = props;
	const { credentials } = useAccountCredentialsSelector();
	const permissions = useAccountRolesSelector();
	const isAuth = !!credentials;

	LocalStorage.set(LC_KEY_IS_AUTH, isAuth);

	const protectedItems = protectItems(items, isAuth, permissions);

	return (
		<Routes>
			{
				protectedItems.map(item => {
					const {
						element: Element,
						props = {},
						...restProps
					} = item;

					return (
						<Route
							key={ item.path }
							element={ <Suspense fallback={ <p>Loading</p>}><Element { ...props } /></Suspense> }
							{ ...restProps }
						/>
					);
				})
			}
		</Routes>
	);
}
