// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MetaRow_name__rQiAr {\n  margin-right: 0.125em;\n}\n\n.MetaRow_date__H8rF5 {\n  margin-left: 0.125em;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.MetaRow_colorRed__JMzEe {\n  color: rgb(200, 50, 50) !important;\n}\n.MetaRow_colorRed__JMzEe:focus, .MetaRow_colorRed__JMzEe:active, .MetaRow_colorRed__JMzEe:hover {\n  color: rgb(200, 50, 50) !important;\n}\n\n.MetaRow_colorGreen__AWWYJ {\n  color: rgb(50, 200, 50) !important;\n}\n.MetaRow_colorGreen__AWWYJ:focus, .MetaRow_colorGreen__AWWYJ:active, .MetaRow_colorGreen__AWWYJ:hover {\n  color: rgb(50, 200, 50) !important;\n}\n\n@media screen and (max-width: 767px) {\n  .MetaRow_box__wiRNd {\n    flex-direction: row !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/components/meta-row/MetaRow.module.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;AACD;;AAEA;EACC,oBAAA;EAEA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAD;;AAGA;EACC,kCAAA;AAAD;AAEC;EAGC,kCAAA;AAFF;;AAMA;EACC,kCAAA;AAHD;AAKC;EAGC,kCAAA;AALF;;AASA;EACC;IACC,8BAAA;EANA;AACF","sourcesContent":[".name {\n\tmargin-right: 0.125em;\n}\n\n.date {\n\tmargin-left: 0.125em;\n\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n\toverflow: hidden;\n}\n\n.colorRed {\n\tcolor: rgba(200, 50, 50, 1) !important;\n\n\t&:focus,\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(200, 50, 50, 1) !important;\n\t}\n}\n\n.colorGreen {\n\tcolor: rgba(50, 200, 50, 1) !important;\n\n\t&:focus,\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(50, 200, 50, 1) !important;\n\t}\n}\n\n@media screen and (max-width: 767px) {\n\t.box {\n\t\tflex-direction: row !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "MetaRow_name__rQiAr",
	"date": "MetaRow_date__H8rF5",
	"colorRed": "MetaRow_colorRed__JMzEe",
	"colorGreen": "MetaRow_colorGreen__AWWYJ",
	"box": "MetaRow_box__wiRNd"
};
export default ___CSS_LOADER_EXPORT___;
