import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserShift, TUserShiftResponse, adaptUserShiftFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_SHIFTS } from '../../backend-paths';


export const usersShiftReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_SHIFTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_SHIFTS}/${payload}`;

		return HttpService.get<TUserShiftResponse>(path).then(result => {
			return adaptUserShiftFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserShift> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const usersShiftReadSlice = createSlice({
	name: 'usersShiftReadSlice',
	initialState,
	reducers: {
		setUserShiftRead(state, action: PayloadAction<TUserShift>) {
			state.item = action.payload;
		},

		clearUserShiftRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[usersShiftReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersShiftReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserShift>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[usersShiftReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserShiftReadSelector = () => useAppSelector(state => state[usersShiftReadSlice.name]);

export const { clearUserShiftRead, setUserShiftRead } = usersShiftReadSlice.actions;
