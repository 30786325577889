import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingUsersValue, TSystemSettingUsersAccountUserTypePayload, TSystemSettingUsersValueResponse, adaptSystemSettingUsersValueFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE } from '../../backend-paths';


export const systemSettingUsersAccountUserTypeUpdate = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE}`,
	(payload: TSystemSettingUsersAccountUserTypePayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE;

		return HttpService.put<TSystemSettingUsersValueResponse>(path, payload).then(result => {
			return adaptSystemSettingUsersValueFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingUsersValue> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingUsersAccountUserTypeUpdateSlice = createSlice({
	name: 'systemSettingUsersAccountUserTypeUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingUsersAccountUserTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingUsersAccountUserTypeUpdate.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingUsersAccountUserTypeUpdate.fulfilled.type]: (state, action: PayloadAction<TSystemSettingUsersValue>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingUsersAccountUserTypeUpdate.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingUsersAccountUserTypeUpdateSelector = () => useAppSelector(state => state[systemSettingUsersAccountUserTypeUpdateSlice.name]);

export const { clearSystemSettingUsersAccountUserTypeUpdate } = systemSettingUsersAccountUserTypeUpdateSlice.actions;
