import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroup, TProjectOrderGroupPayloadUpdate, TProjectOrderGroupResponse, adaptProjectOrderGroupFromResponse, adaptProjectOrderGroupToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:id`,
	(payload: TProjectOrderGroupPayloadUpdate, thunkApi) => {
		const { id, orderId, projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${id}`;

		const dataRequest = adaptProjectOrderGroupToRequest(data);

		return HttpService.put<TProjectOrderGroupResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderGroupFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroup> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupUpdateSlice = createSlice({
	name: 'projectOrderGroupUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroup>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupUpdateSelector = () => useAppSelector(state => state[projectOrderGroupUpdateSlice.name]);

export const { clearProjectOrderGroupUpdate } = projectOrderGroupUpdateSlice.actions;
