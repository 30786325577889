import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountCategoryChange, TAccountCategoryChangeResponse, adaptAccountCategoryChangeFromResponse } from '../../types';
import { BACKEND_ACCOUNT_CATEGORY_CHANGES } from '../../backend-paths';


export const accountCategoryChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CATEGORY_CHANGES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_CATEGORY_CHANGES}/${payload}`;

		return HttpService.get<TAccountCategoryChangeResponse>(path).then(result => {
			return adaptAccountCategoryChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountCategoryChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCategoryChangeReadSlice = createSlice({
	name: 'accountCategoryChangeReadSlice',
	initialState,
	reducers: {
		setAccountCategoryChangeRead(state, action: PayloadAction<TAccountCategoryChange>) {
			state.item = action.payload;
		},

		clearAccountCategoryChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCategoryChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCategoryChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCategoryChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCategoryChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountCategoryChangeReadSelector = () => useAppSelector(state => state[accountCategoryChangeReadSlice.name]);

export const { clearAccountCategoryChangeRead, setAccountCategoryChangeRead } = accountCategoryChangeReadSlice.actions;
