import { lazy } from 'react';

import { PageSuspense } from 'src/components';

const SystemSettingBookkeepingWorkDayHoursCountUpdatePage = lazy(() => import('../views/update-work-day-hours-count/SystemSettingBookkeepingWorkDayHoursCountUpdatePage'));

export function SystemSettingBookkeepingWorkDayHoursCountUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingBookkeepingWorkDayHoursCountUpdatePage />
		</PageSuspense>
	);
}
