import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectStatus, TProjectStatusResponse, adaptProjectStatusFromResponse } from '../../types';
import { BACKEND_ADMIN_PROJECTS_STATUSES } from '../../backend-paths';


export const projectStatusReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_PROJECTS_STATUSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_PROJECTS_STATUSES}/${payload}`;

		return HttpService.get<TProjectStatusResponse>(path).then(result => {
			return adaptProjectStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectStatusReadSlice = createSlice({
	name: 'projectStatusReadSlice',
	initialState,
	reducers: {
		setProjectStatusRead(state, action: PayloadAction<TProjectStatus>) {
			state.item = action.payload;
		},

		clearProjectStatusRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectStatusReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectStatusReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectStatusReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectStatusReadSelector = () => useAppSelector(state => state[projectStatusReadSlice.name]);

export const { clearProjectStatusRead, setProjectStatusRead } = projectStatusReadSlice.actions;
