import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TEquipmentOrderStatusSourceList, TEquipmentOrderStatusSourceListResponse, TEquipmentOrderStatusSourceQuery, adaptEquipmentOrderStatusSourceFromListResponse, adaptEquipmentsOrdersStatusesQueryToRequest } from '../../types';
import { BACKEND_SOURCE_EQUIPMENTS_ORDERS_STATUSES } from '../../backend-paths';


export const sourceEquipmentOrderStatusListGetApi = createAsyncThunk(
	BACKEND_SOURCE_EQUIPMENTS_ORDERS_STATUSES,
	(payload: TEquipmentOrderStatusSourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsOrdersStatusesQueryToRequest(payload);

		return HttpService.get<TEquipmentOrderStatusSourceListResponse>(BACKEND_SOURCE_EQUIPMENTS_ORDERS_STATUSES, { params }).then(result => {
			return adaptEquipmentOrderStatusSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentOrderStatusSourceList;
	query: TEquipmentOrderStatusSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceEquipmentOrderStatusListSlice = createSlice({
	name: 'sourceEquipmentOrderStatusListSlice',
	initialState,
	reducers: {
		setSourceEquipmentOrderStatusQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceEquipmentOrderStatusList(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceEquipmentOrderStatusListGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceEquipmentOrderStatusListGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderStatusSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceEquipmentOrderStatusListGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceEquipmentOrderStatusListSelector = () => useAppSelector(state => state[sourceEquipmentOrderStatusListSlice.name]);

export const { clearSourceEquipmentOrderStatusList, setSourceEquipmentOrderStatusQuerySearch } = sourceEquipmentOrderStatusListSlice.actions;
