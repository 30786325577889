import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountWorkData, TAccountWorkDataPayload, TAccountWorkDataResponse, adaptAccountWorkDataFromResponse, adaptAccountWorkDataToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_WORK_DATA } from '../../backend-paths';


export const accountWorkDataUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_WORK_DATA}`,
	(payload: TAccountWorkDataPayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptAccountWorkDataToRequest(data);

		return HttpService.put<TAccountWorkDataResponse>(BACKEND_ACCOUNT_WORK_DATA, dataRequest).then(result => {
			const pathFiles = `${BACKEND_ACCOUNT_WORK_DATA}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountWorkDataResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountWorkDataFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountWorkData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountWorkDataUpdateSlice = createSlice({
	name: 'accountWorkDataUpdateSlice',
	initialState,
	reducers: {
		clearAccountWorkDataUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountWorkDataUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountWorkDataUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountWorkData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountWorkDataUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountWorkDataUpdateSelector = () => useAppSelector(state => state[accountWorkDataUpdateSlice.name]);

export const { clearAccountWorkDataUpdate } = accountWorkDataUpdateSlice.actions;
