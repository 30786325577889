import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectOrderTagTypeUpdatePage = lazy(() => import('../views/update/ProjectOrderTagTypeUpdatePage'));

export function ProjectOrderTagTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectOrderTagTypeUpdatePage />
		</PageSuspense>
	);
}
