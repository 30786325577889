import { InputHTMLAttributes, RefAttributes, forwardRef, useState } from 'react';

import { FormButton, FormField, FormInput, FormRow, Icon } from 'src/components';
import { useFocus } from 'src/toolkit';


type TProps = InputHTMLAttributes<HTMLInputElement> & RefAttributes<HTMLInputElement> & {
	legend?: string;
	defaultType?: string;
	errors: string[];
};

const defaultIcon = 'icon-eye';

export const FormPassword = forwardRef<HTMLInputElement, TProps>((props, ref) => {
	const {
		legend = '',
		defaultType = 'password',
		errors = [],
		required = false,
		disabled = false,
		...restFields
	} = props;

	const [type, setType] = useState(defaultType);
	const [icon, setIcon] = useState(defaultIcon);

	const [inputRef, setFocus] = useFocus();

	const onClick = () => {
		setFocus();

		if (type === defaultType) {
			setType('text');
			setIcon('icon-eye-blocked');

			return;
		}

		setType(defaultType);
		setIcon(defaultIcon);
	};

	return (
		<FormField
			legend={ legend }
			required={ required }
			errors={ errors }
		>
			<FormRow>
				<FormInput
					ref={ inputRef }
					type={ type }
					disabled={ disabled }
					{ ...restFields }
				/>

				<FormButton
					mini
					type="button"
					disabled={ disabled }
					onClick={ onClick }
					tabIndex={ -1 }
				>
					<Icon icon={ icon } />
				</FormButton>
			</FormRow>
		</FormField>
	);
});
