import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ArticlesSortCol, TArticleList, TArticleListResponse, TArticleQuery, toArticlesQueryRequest, TArticlesSortCol, adaptArticleFromListResponse } from '../../types';
import { BACKEND_ARTICLES_PUBLIC } from '../../backend-paths';


export const articlesPublicGetApi = createAsyncThunk(
	BACKEND_ARTICLES_PUBLIC,
	(payload: TArticleQuery | undefined, thunkApi) => {
		const params = payload ? toArticlesQueryRequest(payload) : {};

		return HttpService.get<TArticleListResponse>(BACKEND_ARTICLES_PUBLIC, { params }).then(result => {
			return adaptArticleFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TArticleList,
	query: TArticleQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.DESC,
		sortCol: ArticlesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const articlesPublicSlice = createSlice({
	name: 'articlesPublicSlice',
	initialState,
	reducers: {
		setArticlesPublicQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setArticlesPublicQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setArticlesPublicQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setArticlesPublicQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setArticlesPublicQuerySortCol(state, action: PayloadAction<TArticlesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearArticlesPublic(state) {
			return { ...initialState, query: state.query };
		},
	},

	extraReducers: {
		[articlesPublicGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[articlesPublicGetApi.fulfilled.type]: (state, action: PayloadAction<TArticleList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[articlesPublicGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useArticlesPublicSelector = () => useAppSelector(state => state[articlesPublicSlice.name]);

export const { clearArticlesPublic, setArticlesPublicQueryLimit, setArticlesPublicQueryOffset, setArticlesPublicQuerySort, setArticlesPublicQuerySortCol, setArticlesPublicQuerySearch } = articlesPublicSlice.actions;
