import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const FinancialChangesTypesPage = lazy(() => import('../views/list/FinancialChangesTypesPage'));

export function FinancialChangesTypesPageLazy() {
	return (
		<PageSuspense>
			<FinancialChangesTypesPage />
		</PageSuspense>
	);
}
