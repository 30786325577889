import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserDepartmentUpdatePage = lazy(() => import('../views/update/UserDepartmentUpdatePage'));

export function UserDepartmentUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserDepartmentUpdatePage />
		</PageSuspense>
	);
}
