import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ArticlesSortCol = {
	TITLE: 'title',
	PUBLISHED_AT: 'published_at',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TArticlesSortCol = TTableSortCols<typeof ArticlesSortCol>;

export type TArticleQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TArticlesSortCol;
};

export type TArticlesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function toArticlesQueryRequest(data: TArticleQuery): TArticlesQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TArticle = {
  alias: string;
  title: string;
  description: string;
  isPublished: boolean;
  publishedAt: string;
	createdAt: string;
  updatedAt: string;

	publishedBy?: TUserMeta;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TArticleResponse = {
  alias: string;
  title: string;
  description: string;
  is_published: boolean;
  published_at: string;
  created_at: string;
  updated_at: string;

	published_by: TUserMetaResponse;
	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TArticleListResponse = TListResponse<TArticleResponse>;

export type TArticleList = TListResponse<TArticle>;

export type TArticleBodyRequest = {
	alias: string;
	title: string;
	description: string;
	isPublished: boolean;
};

export type TArticleRequest = {
	alias: string;
	title: string;
	description: string;
	is_published: boolean;
};

export type TArticleUpdate = {
	alias: string;
	data: TArticleBodyRequest;
};

export function adaptArticleFromListResponse(data: TArticleListResponse): TArticleList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptArticleFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptArticleFromResponse(data: TArticleResponse): TArticle {
	const {
		alias,
		title,
		description,
		is_published: isPublished,
		published_by: publishedByRaw,
		published_at: publishedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const publishedBy = adaptUserMetaUndefinedFromResponse(publishedByRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		alias,
		title,
		description,
		isPublished,
		publishedBy,
		publishedAt,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptArticleToRequest(data: TArticleBodyRequest): TArticleRequest {
	const {
		alias,
		title,
		description,
		isPublished,
	} = data;

	return {
		alias,
		title,
		description,
		is_published: isPublished,
	};
}
