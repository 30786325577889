import { TEquipmentGroupSource, TEquipmentGroupSourceResponse, adaptEquipmentGroupSourceFromResponse } from './equipment-group-source.type';


export type TEquipmentOrderHistoryDataRelationEquipmentGroup = {
  amount: number;

  group: TEquipmentGroupSource;
};

export type TEquipmentOrderHistoryDataRelationEquipmentGroupResponse = {
  amount: number;

	group: TEquipmentGroupSourceResponse;
};

export type TEquipmentOrderHistoryDataRelationEquipmentGroupCreate = Pick<TEquipmentOrderHistoryDataRelationEquipmentGroup, 'amount' | 'group'>;

export function adaptEquipmentOrderHistoryDataRelationEquipmentGroupFromResponse(data: TEquipmentOrderHistoryDataRelationEquipmentGroupResponse): TEquipmentOrderHistoryDataRelationEquipmentGroup {
	const {
		amount,

		group: groupRaw,
	} = data;

	const group = adaptEquipmentGroupSourceFromResponse(groupRaw);

	return {
		amount,
		group,
	};
}
