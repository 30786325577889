import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './ModalButtonWrap.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function ModalButtonWrap(props: TProps) {
	const {
		children,
		className: outerClassName = '',
		...restProps
	} = props;

	const classNames = [styles.modalButtonWrap];

	if (outerClassName) {
		classNames.push(outerClassName);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</div>
	);
}
