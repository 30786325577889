type TLibDeclOfNum = number | string;


export function libMsToFormat(milliseconds: number): string {
	const ms = Math.max(1000, milliseconds);
	const str = libFormatClock(ms, 'hh:mm:ss');
	const arr = str.split(':');

	const h = parseInt(arr[0]) || 0;
	const m = parseInt(arr[1]) || 0;
	const s = parseInt(arr[2]) || 0;

	let ret = '';
	let i = 0;

	if (h > 0) {
		ret += h + ' ' + libDeclOfNum(h, ['час', 'часа', 'часов']);
		i += 1;
	}

	if (m > 0) {
		if (i > 0) {
			ret += ' ';
		}

		ret += m + ' ' + libDeclOfNum(m, ['минуту', 'минуты', 'минут']);
		i += 1;
	}

	if (s > 0) {
		if (i > 0) {
			ret += ' ';
		}

		ret += s + ' ' + libDeclOfNum(s, ['секунду', 'секунды', 'секунд']);
		i += 1;
	}

	if (i <= 0) {
		ret = '0 секунд';
	}

	return ret;
}

export function libFormatClock(ms: number, template?: string): string {
	template = typeof template === 'string' ? template : 'hh:mm';

	if (ms < 0) {
		return '—';
	}

	const date = new Date(ms);
	const s = libPad(date.getUTCSeconds());
	const m = libPad(date.getUTCMinutes());
	const h = libPad(date.getUTCHours());

	const temp = template
		.replace(/hh/ig, h)
		.replace(/mm/ig, m)
		.replace(/ss/ig, s);

	return temp;
}

export function libFormatMsToClock(ms: number, template?: string): string {
	template = typeof template === 'string' ? template : 'hh:mm';

	if (ms < 0) {
		return '—';
	}

	const secAll = Math.floor(ms / 1000);
	const minAll = Math.floor(secAll / 60);

	const sec = secAll < 60 ? secAll : secAll % 60;
	const min = minAll < 60 ? minAll : minAll % 60;
	const hours = minAll < 60 ? 0 : minAll / 60;

	const s = libPad(sec);
	const m = libPad(min);
	const h = libPad(hours);

	const temp = template
		.replace(/hh/ig, h)
		.replace(/mm/ig, m)
		.replace(/ss/ig, s);

	return temp;
}

type TLibFormatDateOption = {
	template?: string;
	months?: string[];
};

export function libFormatDate(value?: Date | string, customOptions?: string | TLibFormatDateOption): string {
	if (!value) {
		return '—';
	}

	const date = typeof value === 'string' ? new Date(value) : value;

	if (!date) {
		return '—';
	}

	const defaultTemplate = 'hh:mm:ss dd/MM/yyyy';
	const config = customOptions || { template: defaultTemplate };
	const options = typeof config === 'string' ? { template: config } : config;
	const { template = defaultTemplate, months = [] } = options;

	const y = date.getFullYear().toString();
	const monthIndex = date.getMonth();
	const M = months.length > 0 ? months[monthIndex] : libPad(monthIndex + 1);
	const d = date.getDate();
	const dut = libPad(d);

	const s = libPad(date.getUTCSeconds());
	const m = libPad(date.getUTCMinutes());
	const h = libPad(date.getUTCHours());

	return template.replace(/yyyy/g, y)
		.replace(/MM/g, M)
		.replace(/ddt/g, d.toString())
		.replace(/dd/g, dut)
		.replace(/hh/ig, h)
		.replace(/mm/ig, m)
		.replace(/ss/ig, s);
}

export function libExtractFileName(value: string): string {
	return value.slice(value.lastIndexOf('/') + 1);
}

export function libDeclOfNum(x: number, arr: TLibDeclOfNum[]): TLibDeclOfNum {
	const cases = [2, 0, 1, 1, 1, 2];
	const bool = x % 100 > 4 && x % 100 < 20;
	const index = bool ? 2 : cases[(x % 10 < 5) ? x % 10 : 5];

	return arr[index];
}

export function libPad(value: string | number, int?: number): string {
	const length = typeof int === 'number' ? int : 2;

	const arr = new Array(length).fill(0);
	const prefix = arr.join('');
	const val = `${prefix}${value}`;

	return val.slice(-length);
}

export function replaceAt(str: string, i: number, value: string): string {
	return str.slice(0, i) + value + str.slice(i + 1);
}

export function ucFirst(value: string): string {
	return value[0].toUpperCase() + value.slice(1);
}

type TLibFormatName = {
	nickname: string;
	lastName: string;
	firstName: string;
	thirdName: string;
};

export function libFormatName(data?: TLibFormatName): string {
	if (!data) {
		return '—';
	}

	const {
		nickname,
		lastName,
		firstName,
		thirdName,
	} = data;

	if (lastName && firstName && thirdName) {
		return `${lastName} ${firstName[0]}. ${thirdName[0]}.`;
	}

	return nickname;
}

// 1, 2, 5
export function libDeclinationByNum(x: number, arr: [string, string, string]) {
	const cases = [2, 0, 1, 1, 1, 2];

	return arr[
		(x % 100 > 4 && x % 100 < 20) ?
		2 :
			cases[(x % 10 < 5) ?
				x % 10 :
				5
			]
	];
}
