import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ArticleUpdatePage = lazy(() => import('../views/update/ArticleUpdatePage'));

export function ArticleUpdatePageLazy() {
	return (
		<PageSuspense>
			<ArticleUpdatePage />
		</PageSuspense>
	);
}
