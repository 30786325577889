import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserDepartmentChangePayload, TUserResponse, adaptUserFromResponse, adaptUserDepartmentChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDepartmentChangeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS}/:nickname/departments`,
	(payload: TUserDepartmentChangePayload, thunkApi) => {
		const { nickname, data } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/departments`;
		const dataRequest = adaptUserDepartmentChangeToRequest(data);

		return HttpService.post<TUserResponse>(path, dataRequest).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDepartmentChangeCreateSlice = createSlice({
	name: 'userDepartmentChangeCreateSlice',
	initialState,
	reducers: {
		clearUserDepartmentChangeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDepartmentChangeCreateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDepartmentChangeCreateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDepartmentChangeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDepartmentChangeCreateSelector = () => useAppSelector(state => state[userDepartmentChangeCreateSlice.name]);

export const { clearUserDepartmentChangeCreate } = userDepartmentChangeCreateSlice.actions;
