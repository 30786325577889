import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TScheduleEventType, TScheduleEventTypeRequest, TScheduleEventTypeResponse, adaptScheduleEventTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES } from '../../backend-paths';


export const scheduleEventTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES}`,
	(payload: TScheduleEventTypeRequest, thunkApi) => {
		return HttpService.post<TScheduleEventTypeResponse>(BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES, payload).then(result => {
			return adaptScheduleEventTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEventType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventTypeCreateSlice = createSlice({
	name: 'scheduleEventTypeCreateSlice',
	initialState,
	reducers: {
		clearScheduleEventTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[scheduleEventTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useScheduleEventTypeCreateSelector = () => useAppSelector(state => state[scheduleEventTypeCreateSlice.name]);

export const { clearScheduleEventTypeCreate } = scheduleEventTypeCreateSlice.actions;
