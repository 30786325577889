import styles from './FormImageFieldPreloader.module.scss';


export function FormImageFieldPreloader() {
	return (
		<span className={ styles.formImagePreloaderWrapper }>
			<div className={ styles.formImagePreloader }>
				<div className={ styles.formImagePreloaderContainer }>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</span>
	);
}
