import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const GroupsShiftsReportsTagsTypesSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TGroupsShiftsReportsTagsTypesSortCol = TTableSortCols<typeof GroupsShiftsReportsTagsTypesSortCol>;

export type TGroupShiftReportTagTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TGroupsShiftsReportsTagsTypesSortCol;
};

export type TGroupsShiftsReportsTagsTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptGroupsShiftsReportsTagsTypesQueryToRequest(data?: TGroupShiftReportTagTypeQuery): TGroupsShiftsReportsTagsTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TGroupShiftReportTagType = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TGroupShiftReportTagTypeResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TGroupShiftReportTagTypeListResponse = TListResponse<TGroupShiftReportTagTypeResponse>;

export type TGroupShiftReportTagTypeList = TListResponse<TGroupShiftReportTagType>;

export type TGroupShiftReportTagTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TGroupShiftReportTagTypeRequest = {
	name: string;
	description?: string;
};

export type TGroupShiftReportTagTypeUpdate = {
	id: number;
	data: TGroupShiftReportTagTypeBodyRequest;
};

export function adaptGroupShiftReportTagTypeFromListResponse(data: TGroupShiftReportTagTypeListResponse): TGroupShiftReportTagTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptGroupShiftReportTagTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptGroupShiftReportTagTypeFromResponse(data: TGroupShiftReportTagTypeResponse): TGroupShiftReportTagType {
	const {
		id,
		name,
		description,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptGroupShiftReportTagTypeToRequest(data: TGroupShiftReportTagTypeBodyRequest): TGroupShiftReportTagTypeRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
