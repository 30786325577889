import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from './user-category.type';


export const UserCategoryChangeSortCol = {
	CREATED_AT: 'created_at',
};

export type TUserCategoryChangeSortCol = TTableSortCols<typeof UserCategoryChangeSortCol>;

export type TUserCategoryChangeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserCategoryChangeSortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TUserCategoryChangeQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptUserCategoryChangeQueryRequest(data: TUserCategoryChangeQuery): TUserCategoryChangeQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserCategoryChangeListResponse = TListResponse<TUserCategoryChangeResponse>;

export type TUserCategoryChangeList = TListResponse<TUserCategoryChange>;

export function adaptUserCategoryChangeFromListResponse(data: TUserCategoryChangeListResponse): TUserCategoryChangeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserCategoryChangeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TUserCategoryChange = {
  id: number;
  category: TUserCategory;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserCategoryChangeResponse = {
  id: number;
  category: TUserCategoryResponse;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserCategoryChangeBodyPayload = {
	categoryId: number;
	dateStart: string;
	dateEnd?: string;
};

export type TUserCategoryChangeBodyPayloadRequest = {
	category_id: number;
	date_start: string;
	date_end?: string;
};

type TUserCategoryChangeParam = {
	nickname: string;
};

export type TUserCategoryChangePayload = TUserCategoryChangeParam & {
	data: TUserCategoryChangeBodyPayload;
};

export type TUserCategoryChangeParams = TUserCategoryChangeParam & {
	id: number;
};

export type TUserCategoryChangePayloadUpdate = TUserCategoryChangeParams & {
	nickname: string;
	data: TUserCategoryChangeBodyPayload;
};

export type TUserCategoryChangesPayload = TUserCategoryChangeParam & {
	query: TUserCategoryChangeQuery;
};

export function adaptUserCategoryChangeFromResponse(data: TUserCategoryChangeResponse): TUserCategoryChange {
	const {
		id,
		category: categoryRaw,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const category = adaptUserCategoryFromResponse(categoryRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		category,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserCategoryChangeToRequest(data: TUserCategoryChangeBodyPayload): TUserCategoryChangeBodyPayloadRequest {
	const {
		categoryId,
		dateStart,
		dateEnd,
	} = data;

	return {
		category_id: categoryId,
		date_start: dateStart,
		date_end: dateEnd,
	};
}
