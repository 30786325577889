import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

import { libFileToBase64 } from 'src/toolkit';

import { FormImageFieldPreview } from '../../../form-image-field-preview';

import styles from '../../../styles/FormImageFieldListValueRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	file: File;
	onDelete: (value: File) => void;
};

const enum Size {
	KB = 1000,
	MB = 1000000,
	GB = 1000000000,
}

function formatSize(value: number = 0): [string, string] {
	if (value < Size.KB) {
		return [value.toString(), 'Byte'];
	}

	if (value < Size.MB) {
		const shortValue = value / Size.KB;

		return [shortValue.toFixed(2), 'Kb'];
	}

	if (value < Size.GB) {
		const shortValue = value / Size.MB;

		return [shortValue.toFixed(2), 'Mb'];
	}

	const shortValue = value / Size.GB;

	return [shortValue.toFixed(2), 'Gb'];
}

export function FormImageFieldListRow(props: TProps) {
	const [src, setSrc] = useState('');

	const { onDelete, file } = props;

	const classNames = [styles.row];
	const { name, size } = file;
	const [fixedSize, fixedSizeName] = formatSize(size);

	libFileToBase64(file).then(result => {
		setSrc(result);
	});

	return (
		<div className={ classNames.join(' ') }>
			<FormImageFieldPreview src={ src } alt={ name } isLoading={ !src } />

			{ name }

			<span className={ styles.nowrap }>
				<span className={ styles.size }>({ fixedSize } { fixedSizeName })</span>
				<span className={ styles.delete } onClick={ e => onDelete(file) }>X</span>
			</span>
		</div>
	);
}
