import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountShiftReport, TAccountShiftReportPayloadCreate, TAccountShiftReportResponse, adaptAccountShiftReportFromResponse, adaptAccountShiftReportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftReportUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_SHIFTS}/:shiftId/report`,
	(payload: TAccountShiftReportPayloadCreate, thunkApi) => {
		const { shiftId, data, files } = payload;
		const path = `${BACKEND_ACCOUNT_SHIFTS}/${shiftId}/report`;

		const dataRequest = adaptAccountShiftReportToRequest(data);

		return HttpService.put<TAccountShiftReportResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountShiftReportResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountShiftReportFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftReportUpdateSlice = createSlice({
	name: 'accountShiftReportUpdateSlice',
	initialState,
	reducers: {
		clearAccountShiftReportUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftReportUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftReportUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftReportUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftReportUpdateSelector = () => useAppSelector(state => state[accountShiftReportUpdateSlice.name]);

export const { clearAccountShiftReportUpdate } = accountShiftReportUpdateSlice.actions;
