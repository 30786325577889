import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingCalendarEventColorBirthdayUpdatePage = lazy(() => import('../views/update-event-color-birthday/SystemSettingCalendarEventColorBirthdayUpdatePage'));

export function SystemSettingCalendarEventColorBirthdayUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingCalendarEventColorBirthdayUpdatePage />
		</PageSuspense>
	);
}
