import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountShiftExpensesSortCol, TAccountShiftExpenseList, TAccountShiftExpenseListResponse, TAccountShiftExpensesQuery, TAccountShiftExpensesQueryPayload, TAccountShiftExpensesSortCol, adaptAccountShiftExpenseFromListResponse, adaptAccountShiftExpensesQueryToRequest } from '../../types';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftExpensesGetApi = createAsyncThunk(
	`${BACKEND_ACCOUNT_SHIFTS}/:shiftId/expenses`,
	(payload: TAccountShiftExpensesQueryPayload, thunkApi) => {
		const { data, shiftId } = payload;
		const params = adaptAccountShiftExpensesQueryToRequest(data);

		const path = `${BACKEND_ACCOUNT_SHIFTS}/${shiftId}/expenses`;

		return HttpService.get<TAccountShiftExpenseListResponse>(path, { params }).then(result => {
			return adaptAccountShiftExpenseFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TAccountShiftExpenseList;
	query: TAccountShiftExpensesQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountShiftExpensesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const accountShiftExpensesSlice = createSlice({
	name: 'accountShiftExpensesSlice',
	initialState,
	reducers: {
		setAccountShiftExpensesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountShiftExpensesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountShiftExpensesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountShiftExpensesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountShiftExpensesQuerySortCol(state, action: PayloadAction<TAccountShiftExpensesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountShiftExpenses(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountShiftExpensesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftExpensesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftExpenseList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountShiftExpensesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftExpensesSelector = () => useAppSelector(state => state[accountShiftExpensesSlice.name]);

export const { clearAccountShiftExpenses, setAccountShiftExpensesQueryLimit, setAccountShiftExpensesQueryOffset, setAccountShiftExpensesQuerySort, setAccountShiftExpensesQuerySortCol, setAccountShiftExpensesQuerySearch } = accountShiftExpensesSlice.actions;
