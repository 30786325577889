import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountShiftReport, adaptAccountShiftReportFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftReportDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_SHIFTS}/:shiftId/report`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SHIFTS}/${payload}/report`;

		return HttpService.delete(path).then(result => {
			return adaptAccountShiftReportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftReportDeleteSlice = createSlice({
	name: 'accountShiftReportDeleteSlice',
	initialState,
	reducers: {
		clearAccountShiftReportDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftReportDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftReportDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftReportDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftReportDeleteSelector = () => useAppSelector(state => state[accountShiftReportDeleteSlice.name]);

export const { clearAccountShiftReportDelete } = accountShiftReportDeleteSlice.actions;
