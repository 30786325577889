import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrder, TProjectOrderResponse, adaptProjectOrderFromResponse } from './project-order.type';
import { libFormatDate } from 'src/toolkit';


export const ProjectsOrdersGroupsSortCol = {
	ID: 'id',
	DATE: 'date',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersGroupsSortCol = TTableSortCols<typeof ProjectsOrdersGroupsSortCol>;

export type TProjectOrderGroupQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersGroupsSortCol;
};

export type TProjectsOrdersGroupsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderGroupPayloadQuery = {
	projectId: number;
	orderId: number;
	data?: TProjectOrderGroupQuery;
};

export function adaptProjectsOrdersGroupsQueryRequest(data: TProjectOrderGroupQuery): TProjectsOrdersGroupsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderGroup = {
  id: number;
  orderId: number;
  date: string;
	order: TProjectOrder;
	createdAt?: string;
  updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectOrderGroupResponse = {
  id: number;
  order_id: number;
  date: string;
	order: TProjectOrderResponse;
  created_at?: string;
  updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TProjectOrderGroupListResponse = TListResponse<TProjectOrderGroupResponse>;

export type TProjectOrderGroupList = TListResponse<TProjectOrderGroup>;

export type TProjectOrderGroupBodyRequest = {
	date: string;
};

export type TProjectOrderGroupRequest = {
	date: string;
};

export type TProjectOrderGroupPayload = {
	id: number;
	orderId: number;
	projectId: number;
};

export type TProjectOrderGroupPayloadCreate = {
	orderId: number;
	projectId: number;
	data: TProjectOrderGroupBodyRequest;
};

export type TProjectOrderGroupPayloadUpdate = TProjectOrderGroupPayloadCreate & {
	id: number;
};

export function adaptProjectOrderGroupFromListResponse(data: TProjectOrderGroupListResponse): TProjectOrderGroupList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderGroupFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderGroupFromResponse(data: TProjectOrderGroupResponse): TProjectOrderGroup {
	const {
		id,
		order_id: orderId,
		date: dateRaw,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		order: orderRaw,
	} = data;

	const date = dateRaw ? libFormatDate(dateRaw, { template: 'yyyy-MM-dd' }) : '';
	const order = adaptProjectOrderFromResponse(orderRaw);

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		orderId,
		date,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
		order,
	};
}

export function adaptProjectOrderGroupToRequest(data: TProjectOrderGroupBodyRequest): TProjectOrderGroupRequest {
	const {
		date,
	} = data;

	return {
		date,
	};
}
