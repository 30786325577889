import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TTableHeadItem, TTableSort, TableHeadCol } from './col';

import styles from './TableHead.module.scss';


export type TTableHead = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	sort?: TTableSort,
	setSort?: (value: TTableSort) => void,
	sortCol?: string,
	setSortCol?: <T extends string>(value: T) => void,
	items: TTableHeadItem[];
};

export function TableHead(props: TTableHead) {
	const { items = [], ...restProps } = props;

	if (items.length <= 0) {
		return (
			<></>
		);
	}

	return (
		<thead
			className={ styles.tableHead }
		>
			<tr>
			 { items.map((item, index) => <TableHeadCol key={ `${item.name}-${index}` } { ...item } { ...restProps } />) }
			</tr>
		</thead>
	);
}
