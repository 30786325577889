import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsOrdersGroupsShiftsSortCol, TProjectOrderGroupShiftList, TProjectOrderGroupShiftListResponse, TProjectOrderGroupShiftPayloadQuery, TProjectOrderGroupShiftQuery, TProjectsOrdersGroupsShiftsSortCol, adaptProjectOrderGroupShiftFromListResponse, adaptProjectsOrdersGroupsShiftsQueryRequest } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectsOrdersGroupsShiftsGetApi = createAsyncThunk(
	`${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts`,
	(payload: TProjectOrderGroupShiftPayloadQuery, thunkApi) => {
		const { groupId, orderId, projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts`;
		const params = data ? adaptProjectsOrdersGroupsShiftsQueryRequest(data) : {};

		return HttpService.get<TProjectOrderGroupShiftListResponse>(path, { params }).then(result => {
			return adaptProjectOrderGroupShiftFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectOrderGroupShiftList,
	query: TProjectOrderGroupShiftQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsOrdersGroupsShiftsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectsOrdersGroupsShiftsSlice = createSlice({
	name: 'projectsOrdersGroupsShiftsSlice',
	initialState,
	reducers: {
		setProjectsOrdersGroupsShiftsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsOrdersGroupsShiftsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsOrdersGroupsShiftsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsOrdersGroupsShiftsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsOrdersGroupsShiftsQuerySortCol(state, action: PayloadAction<TProjectsOrdersGroupsShiftsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectsOrdersGroupsShifts(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsOrdersGroupsShiftsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsOrdersGroupsShiftsGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsOrdersGroupsShiftsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsOrdersGroupsShiftsSelector = () => useAppSelector(state => state[projectsOrdersGroupsShiftsSlice.name]);

export const { clearProjectsOrdersGroupsShifts, setProjectsOrdersGroupsShiftsQueryLimit, setProjectsOrdersGroupsShiftsQueryOffset, setProjectsOrdersGroupsShiftsQuerySort, setProjectsOrdersGroupsShiftsQuerySortCol, setProjectsOrdersGroupsShiftsQuerySearch } = projectsOrdersGroupsShiftsSlice.actions;
