import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TPaymentType, TPaymentTypeRequest, TPaymentTypeResponse, adaptPaymentTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PAYMENTS_TYPES } from '../../backend-paths';


export const paymentTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_PAYMENTS_TYPES}`,
	(payload: TPaymentTypeRequest, thunkApi) => {
		return HttpService.post<TPaymentTypeResponse>(BACKEND_ADMIN_PAYMENTS_TYPES, payload).then(result => {
			return adaptPaymentTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TPaymentType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const paymentTypeCreateSlice = createSlice({
	name: 'paymentTypeCreateSlice',
	initialState,
	reducers: {
		clearPaymentTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[paymentTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[paymentTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TPaymentType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[paymentTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const usePaymentTypeCreateSelector = () => useAppSelector(state => state[paymentTypeCreateSlice.name]);

export const { clearPaymentTypeCreate } = paymentTypeCreateSlice.actions;
