// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelectField_box__0Suvj {\n  margin-left: 0.1em;\n}\n\n.FormSelectField_width100__8HeOs {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-select-field/FormSelectField.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".box {\n\tmargin-left: 0.1em;\n}\n\n.width100 {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "FormSelectField_box__0Suvj",
	"width100": "FormSelectField_width100__8HeOs"
};
export default ___CSS_LOADER_EXPORT___;
