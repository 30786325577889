import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserExpenseType, TUserExpenseTypeUpdate, TUserExpenseTypeResponse, adaptUserExpenseTypeFromResponse, adaptUserExpenseTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_EXPENSES_TYPES } from '../../backend-paths';


export const userExpenseTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_EXPENSES_TYPES}/:id`,
	(payload: TUserExpenseTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_EXPENSES_TYPES}/${id}`;

		const dataRequest = adaptUserExpenseTypeToRequest(data);

		return HttpService.put<TUserExpenseTypeResponse>(path, dataRequest).then(result => {
			return adaptUserExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseTypeUpdateSlice = createSlice({
	name: 'userExpenseTypeUpdateSlice',
	initialState,
	reducers: {
		clearUserExpenseTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userExpenseTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserExpenseTypeUpdateSelector = () => useAppSelector(state => state[userExpenseTypeUpdateSlice.name]);

export const { clearUserExpenseTypeUpdate } = userExpenseTypeUpdateSlice.actions;
