import { ReactNode } from 'react';

import { HeaderCore } from '../header-core';

import styles from './HeaderHome.module.scss';
import { Icon } from 'src/components/icon';


type TProps = {
	children?: ReactNode;
	style?: Record<string, string>;
};

export function HeaderHome(props: TProps) {
	const top = [styles.fade, styles.top];
	const bottom = [styles.fade, styles.bottom];

	return (
		<HeaderCore className={ styles.headerHome }>
			<div className={ styles.background }></div>

			<div className={ styles.promoText }>LIGHT YOUR MIND</div>

			<h2 className={ styles.title }>
				<Icon icon="icon-logo" className={ styles.icon } />
			</h2>

			<div className={ styles.headline }>Light • Your • Mind</div>

			<div className={ top.join(' ') }></div>
			<div className={ bottom.join(' ') }></div>
		</HeaderCore>
	);
}
