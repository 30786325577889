import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { UserTypeHistorySortCol, TUserTypeHistoryList, TUserTypeHistoryQuery, TUserTypeHistoryListResponse, TUserTypeHistorySortCol, adaptUserTypeHistoryFromListResponse, adaptUserTypeHistoryQueryRequest, TUserTypeHistoriesPayload } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userTypeHistoriesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/history/type`,
	(payload: TUserTypeHistoriesPayload, thunkApi) => {
		const { userNickname, query } = payload;
		const params = query ? adaptUserTypeHistoryQueryRequest(query) : {};
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/history/type`;

		return HttpService.get<TUserTypeHistoryListResponse>(path, { params }).then(result => {
			return adaptUserTypeHistoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserTypeHistoryList,
	query: TUserTypeHistoryQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: UserTypeHistorySortCol.CREATED_AT,
	},
};

export const usersTypeHistoriesSlice = createSlice({
	name: 'usersTypeHistoriesSlice',
	initialState,
	reducers: {
		setUserTypeHistoriesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUserTypeHistoriesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUserTypeHistoriesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUserTypeHistoriesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUserTypeHistoriesQuerySortCol(state, action: PayloadAction<TUserTypeHistorySortCol>) {
			state.query.sortCol = action.payload;
		},

		setUserTypeHistoriesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserTypeHistoriesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearUserTypeHistories(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[userTypeHistoriesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeHistoriesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserTypeHistoryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[userTypeHistoriesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeHistorySelector = () => useAppSelector(state => state[usersTypeHistoriesSlice.name]);

export const { clearUserTypeHistories, setUserTypeHistoriesQueryLimit, setUserTypeHistoriesQueryOffset, setUserTypeHistoriesQuerySort, setUserTypeHistoriesQuerySortCol, setUserTypeHistoriesQuerySearch, setUserTypeHistoriesQueryDateStart, setUserTypeHistoriesQueryDateEnd } = usersTypeHistoriesSlice.actions;
