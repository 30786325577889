import { TEquipmentOrder, TEquipmentOrderResponse, adaptEquipmentOrderFromResponse } from './equipment-order.type';


export type TEquipmentUser = {
  amount: number;

	order: TEquipmentOrder;
};

export type TEquipmentUserResponse = {
  amount: number;

	order: TEquipmentOrderResponse;
};

export function adaptEquipmentUserFromResponse(data: TEquipmentUserResponse): TEquipmentUser {
	const {
		amount,
		order: orderRaw,
	} = data;

	const order = adaptEquipmentOrderFromResponse(orderRaw);

	return {
		amount,
		order,
	};
}
