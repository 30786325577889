import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountPassport, TAccountPassportResponse, adaptAccountPassportFromResponse } from '../../types';
import { BACKEND_ACCOUNT_PASSPORT } from '../../backend-paths';


export const accountPassportReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_PASSPORT}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountPassportResponse>(BACKEND_ACCOUNT_PASSPORT).then(result => {
			return adaptAccountPassportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountPassportReadSlice = createSlice({
	name: 'accountPassportReadSlice',
	initialState,
	reducers: {
		setAccountPassportRead(state, action: PayloadAction<TAccountPassport>) {
			state.item = action.payload;
		},

		clearAccountPassportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountPassportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPassportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountPassportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountPassportReadSelector = () => useAppSelector(state => state[accountPassportReadSlice.name]);

export const { clearAccountPassportRead, setAccountPassportRead } = accountPassportReadSlice.actions;
