import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserShiftReport, TUserShiftReportResponse, adaptUserShiftReportFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_SHIFTS } from '../../backend-paths';


export const userShiftReportReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ADMIN_USERS_SHIFTS}/:shiftId/report`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_SHIFTS}/${payload}/report`;

		return HttpService.get<TUserShiftReportResponse>(path).then(result => {
			return adaptUserShiftReportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userShiftReportReadSlice = createSlice({
	name: 'userShiftReportReadSlice',
	initialState,
	reducers: {
		setUserShiftReportRead(state, action: PayloadAction<TUserShiftReport>) {
			state.item = action.payload;
		},

		clearUserShiftReportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userShiftReportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userShiftReportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userShiftReportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserShiftReportReadSelector = () => useAppSelector(state => state[userShiftReportReadSlice.name]);

export const { clearUserShiftReportRead, setUserShiftReportRead } = userShiftReportReadSlice.actions;
