import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TScheduleEventTypeSource, TUserSchedulePayload, adaptScheduleEventTypeSourceFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_SCHEDULE } from '../../backend-paths';


export const userScheduleCreateApi = createAsyncThunk(
	`POST/${BACKEND_SCHEDULE}/:user_nickname/:date`,
	(payload: TUserSchedulePayload, thunkApi) => {
		const { userNickname, date, typeId } = payload;
		const path = `${BACKEND_SCHEDULE}/${userNickname}/${date}`;
		const dataRequest = { type_id: typeId };

		return HttpService.post(path, dataRequest).then(result => {
			return adaptScheduleEventTypeSourceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEventTypeSource> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userScheduleCreateSlice = createSlice({
	name: 'userScheduleCreateSlice',
	initialState,
	reducers: {
		clearUserScheduleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userScheduleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userScheduleCreateApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventTypeSource>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userScheduleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserScheduleCreateSelector = () => useAppSelector(state => state[userScheduleCreateSlice.name]);

export const { clearUserScheduleCreate } = userScheduleCreateSlice.actions;
