import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const EquipmentsOrdersStatusesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsOrdersStatusesSourceSortCol = TTableSortCols<typeof EquipmentsOrdersStatusesSourceSortCol>;

export type TEquipmentOrderStatusSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsOrdersStatusesSourceSortCol;
};

export type TEquipmentsOrdersStatusesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsOrdersStatusesSourceQueryToRequest(data?: TEquipmentOrderStatusSourceQuery): TEquipmentsOrdersStatusesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentOrderStatusSource = {
  id: number;
  name: string;
};

export type TEquipmentOrderStatusSourceResponse = {
  id: number;
  name: string;
};

export type TEquipmentOrderStatusSourceListResponse = TListResponse<TEquipmentOrderStatusSourceResponse>;

export type TEquipmentOrderStatusSourceList = TListResponse<TEquipmentOrderStatusSource>;

export function adaptEquipmentOrderStatusSourceFromListResponse(data: TEquipmentOrderStatusSourceListResponse): TEquipmentOrderStatusSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentOrderStatusSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentOrderStatusSourceFromResponse(data: TEquipmentOrderStatusSourceResponse): TEquipmentOrderStatusSource {
	const { id, name } = data;

	return { id, name };
}
