import { FormField, FormInputMask } from 'src/components';
import { TInputFormField } from 'src/toolkit';


export type TFormInputMaskField = TInputFormField & {
	legend?: string;
	placeholder?: string;
	title?: string;
	mask: string;

	required?: boolean;
	readOnly?: boolean;

	isLoading?: boolean;
};

export function FormInputMaskField(props: TFormInputMaskField) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		required,

		legend = '',
		placeholder = '',
		title = '',
		mask = '',

		readOnly = false,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			requiredMark={ required }
		>
			<FormInputMask
				placeholder={ placeholder }
				mask={ mask }
				title={ title }
				name={ name }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
				readOnly={ readOnly }
			/>
		</FormField>
	);
}
