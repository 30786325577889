import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersCategoriesSortCol, TUserCategoryList, TUserCategoryListResponse, TUserCategoryQuery, TUsersCategoriesSortCol, adaptUserCategoryFromListResponse, adaptUsersCategoriesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_USERS_CATEGORIES } from '../../backend-paths';


export const usersCategoriesGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_CATEGORIES,
	(payload: TUserCategoryQuery | undefined, thunkApi) => {
		const params = adaptUsersCategoriesQueryToRequest(payload);

		return HttpService.get<TUserCategoryListResponse>(BACKEND_ADMIN_USERS_CATEGORIES, { params }).then(result => {
			return adaptUserCategoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserCategoryList;
	query: TUserCategoryQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersCategoriesSortCol.CREATED_AT,
	},
};

export const usersCategoriesSlice = createSlice({
	name: 'usersCategoriesSlice',
	initialState,
	reducers: {
		setUsersCategoriesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersCategoriesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersCategoriesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersCategoriesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersCategoriesQuerySortCol(state, action: PayloadAction<TUsersCategoriesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersCategories(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersCategoriesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersCategoriesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategoryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersCategoriesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersCategoriesSelector = () => useAppSelector(state => state[usersCategoriesSlice.name]);

export const { clearUsersCategories, setUsersCategoriesQueryLimit, setUsersCategoriesQueryOffset, setUsersCategoriesQuerySort, setUsersCategoriesQuerySortCol, setUsersCategoriesQuerySearch } = usersCategoriesSlice.actions;
