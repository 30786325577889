import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectOrder, TProjectOrderPayload, TProjectOrderResponse, adaptProjectOrderFromResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS}/:projectId/orders/:id`,
	(payload: TProjectOrderPayload, thunkApi) => {
		const { id, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${id}`;

		return HttpService.get<TProjectOrderResponse>(path).then(result => {
			return adaptProjectOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderReadSlice = createSlice({
	name: 'projectOrderReadSlice',
	initialState,
	reducers: {
		setProjectOrderRead(state, action: PayloadAction<TProjectOrder>) {
			state.item = action.payload;
		},

		clearProjectOrderRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderReadSelector = () => useAppSelector(state => state[projectOrderReadSlice.name]);

export const { clearProjectOrderRead, setProjectOrderRead } = projectOrderReadSlice.actions;
