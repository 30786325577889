import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProject, TProjectBodyRequest, TProjectResponse, adaptProjectFromResponse, adaptProjectToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS}`,
	(payload: TProjectBodyRequest, thunkApi) => {
		const dataRequest = adaptProjectToRequest(payload);

		return HttpService.post<TProjectResponse>(BACKEND_PROJECTS, dataRequest).then(result => {
			return adaptProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectCreateSlice = createSlice({
	name: 'projectCreateSlice',
	initialState,
	reducers: {
		clearProjectCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectCreateApi.fulfilled.type]: (state, action: PayloadAction<TProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectCreateSelector = () => useAppSelector(state => state[projectCreateSlice.name]);

export const { clearProjectCreate } = projectCreateSlice.actions;
