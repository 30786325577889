import { FormButtonLink } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


type TProps = {
	email: string;
};

export function SignUpStageEmailSuccess(props: TProps) {
	const { email } = props;

	return (
		<div className={ styles.succesContainer }>
			<p className={ styles.paragraph }>
				На Ваш электронный адрес «{ email }» было отправлено письмо со ссылкой для подтверждения регистрации. Пройдите по ссылке внутри письма и подтвердите Ваш аккаунт.
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>Готово</FormButtonLink>
		</div>
	);
}
