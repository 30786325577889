import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EMAIL } from '../../backend-paths';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountEmail, TAccountEmailResponse, TAccountEmailUpdate, adaptAccountEmailFromResponse, adaptAccountEmailToRequest } from '../../types';


export const accountEmailUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EMAIL}`,
	(payload: TAccountEmailUpdate, thunkApi) => {
		const body = adaptAccountEmailToRequest(payload);

		return HttpService.put<TAccountEmailResponse>(BACKEND_ACCOUNT_EMAIL, body).then(result => {
			return adaptAccountEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = TSliceInitialState<TAccountEmail> & {
	noticeConfirmed: string;
};

const initialState: TInitialState = {
	item: undefined,
	noticeConfirmed: '',
	isLoading: false,
	error: undefined,
};

export const accountEmailUpdateSlice = createSlice({
	name: 'accountEmailUpdateSlice',
	initialState,
	reducers: {
		clearAccountEmailUpdate(state) {
			const { noticeConfirmed } = state;

			return { ...initialState, noticeConfirmed };
		},

		setAccountEmailNoticeUpdate(state, action: PayloadAction<string>) {
			state.noticeConfirmed = action.payload;
		},

		clearAccountEmailNoticeUpdate(state) {
			state.noticeConfirmed = '';
		},
	},

	extraReducers: {
		[accountEmailUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEmailUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEmailUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountEmailUpdateSelector = () => useAppSelector(state => state[accountEmailUpdateSlice.name]);

export const { clearAccountEmailUpdate, setAccountEmailNoticeUpdate, clearAccountEmailNoticeUpdate } = accountEmailUpdateSlice.actions;
