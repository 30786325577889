import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserEmailPage = lazy(() => import('../views/update/UserEmailPage'));

export function UserEmailPageLazy() {
	return (
		<PageSuspense>
			<UserEmailPage />
		</PageSuspense>
	);
}
