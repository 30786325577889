import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProductionCalendar, TProductionCalendarUpdate, TProductionCalendarResponse, adaptProductionCalendarFromResponse, adaptProductionCalendarToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PRODUCTION_CALENDARS}/:id`,
	(payload: TProductionCalendarUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_PRODUCTION_CALENDARS}/${id}`;

		const dataRequest = adaptProductionCalendarToRequest(data);

		return HttpService.put<TProductionCalendarResponse>(path, dataRequest).then(result => {
			return adaptProductionCalendarFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);
const initialState: TSliceInitialState<TProductionCalendar> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarUpdateSlice = createSlice({
	name: 'productionCalendarUpdateSlice',
	initialState,
	reducers: {
		clearProductionCalendarUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[productionCalendarUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendar>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProductionCalendarUpdateSelector = () => useAppSelector(state => state[productionCalendarUpdateSlice.name]);

export const { clearProductionCalendarUpdate } = productionCalendarUpdateSlice.actions;
