import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingAmocrmClientSecret, TSystemSettingAmocrmClientSecretPayload, TSystemSettingAmocrmClientSecretResponse, adaptSystemSettingAmocrmClientSecretFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET } from '../../backend-paths';


export const systemSettingAmocrmClientSecretUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET}`,
	(payload: TSystemSettingAmocrmClientSecretPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET;

		return HttpService.put<TSystemSettingAmocrmClientSecretResponse>(path, payload).then(result => {
			return adaptSystemSettingAmocrmClientSecretFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingAmocrmClientSecret> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingAmocrmClientSecretUpdateSlice = createSlice({
	name: 'systemSettingAmocrmClientSecretUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingAmocrmClientSecretUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingAmocrmClientSecretUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingAmocrmClientSecretUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingAmocrmClientSecret>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingAmocrmClientSecretUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingAmocrmClientSecretUpdateSelector = () => useAppSelector(state => state[systemSettingAmocrmClientSecretUpdateSlice.name]);

export const { clearSystemSettingAmocrmClientSecretUpdate } = systemSettingAmocrmClientSecretUpdateSlice.actions;
