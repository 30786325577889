import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountExpensesPage = lazy(() => import('../views/list/AccountExpensesPage'));

export function AccountExpensesPageLazy() {
	return (
		<PageSuspense>
			<AccountExpensesPage />
		</PageSuspense>
	);
}
