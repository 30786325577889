import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserPosition, TUserPositionResponse, adaptUserPositionFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_POSITIONS } from '../../backend-paths';


export const userPositionReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_POSITIONS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_POSITIONS}/${payload}`;

		return HttpService.get<TUserPositionResponse>(path).then(result => {
			return adaptUserPositionFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPosition> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionReadSlice = createSlice({
	name: 'userPositionReadSlice',
	initialState,
	reducers: {
		setUserPositionRead(state, action: PayloadAction<TUserPosition>) {
			state.item = action.payload;
		},

		clearUserPositionRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPosition>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionReadSelector = () => useAppSelector(state => state[userPositionReadSlice.name]);

export const { clearUserPositionRead, setUserPositionRead } = userPositionReadSlice.actions;
