import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentGroupUpdatePage = lazy(() => import('../views/update/EquipmentGroupUpdatePage'));

export function EquipmentGroupUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentGroupUpdatePage />
		</PageSuspense>
	);
}
