import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProjectOrderTagType, adaptProjectOrderTagTypeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES } from '../../backend-paths';


export const projectOrderTagTypeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectOrderTagTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderTagType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderTagTypeDeleteSlice = createSlice({
	name: 'projectOrderTagTypeDeleteSlice',
	initialState,
	reducers: {
		clearProjectOrderTagTypeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderTagTypeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderTagTypeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderTagType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderTagTypeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderTagTypeDeleteSelector = () => useAppSelector(state => state[projectOrderTagTypeDeleteSlice.name]);

export const { clearProjectOrderTagTypeDelete } = projectOrderTagTypeDeleteSlice.actions;
