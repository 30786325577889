import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersExpensesSortCol, TUserExpenseList, TUserExpenseListResponse, TUsersExpensesQuery, TUsersExpensesQueryPayload, TUsersExpensesSortCol, adaptUserExpenseFromListResponse, adaptUsersExpensesQueryToRequest } from '../../types';
import { BACKEND_USERS_EXPENSES } from '../../backend-paths';


export const usersExpensesGetApi = createAsyncThunk(
	`${BACKEND_USERS_EXPENSES}/:id`,
	(payload: TUsersExpensesQueryPayload, thunkApi) => {
		const { data } = payload;
		const params = adaptUsersExpensesQueryToRequest(data);

		const path = `${BACKEND_USERS_EXPENSES}`;

		return HttpService.get<TUserExpenseListResponse>(path, { params }).then(result => {
			return adaptUserExpenseFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserExpenseList;
	query: TUsersExpensesQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersExpensesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersExpensesSlice = createSlice({
	name: 'usersExpensesSlice',
	initialState,
	reducers: {
		setUsersExpensesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersExpensesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersExpensesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersExpensesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersExpensesQuerySortCol(state, action: PayloadAction<TUsersExpensesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersExpenses(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersExpensesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersExpensesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserExpenseList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersExpensesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersExpensesSelector = () => useAppSelector(state => state[usersExpensesSlice.name]);

export const { clearUsersExpenses, setUsersExpensesQueryLimit, setUsersExpensesQueryOffset, setUsersExpensesQuerySort, setUsersExpensesQuerySortCol, setUsersExpensesQuerySearch } = usersExpensesSlice.actions;
