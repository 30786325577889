import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserInternationalPassport, TUserInternationalPassportPayload, TUserInternationalPassportResponse, adaptUserInternationalPassportFromResponse, adaptUserInternationalPassportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userInternationalPassportUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/international-passport`,
	(payload: TUserInternationalPassportPayload, thunkApi) => {
		const { nickname, data, files = [] } = payload;

		const path = `${BACKEND_ADMIN_USERS}/${nickname}/international-passport`;

		const dataRequest = adaptUserInternationalPassportToRequest(data);

		return HttpService.put<TUserInternationalPassportResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserInternationalPassportResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserInternationalPassportFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserInternationalPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userInternationalPassportUpdateSlice = createSlice({
	name: 'userInternationalPassportUpdateSlice',
	initialState,
	reducers: {
		clearUserInternationalPassportUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userInternationalPassportUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userInternationalPassportUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserInternationalPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userInternationalPassportUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserInternationalPassportUpdateSelector = () => useAppSelector(state => state[userInternationalPassportUpdateSlice.name]);

export const { clearUserInternationalPassportUpdate } = userInternationalPassportUpdateSlice.actions;
