import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserPositionChange, TUserPositionChangeResponse, adaptUserPositionChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPositionChangeCurrentReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/position`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/position`;

		return HttpService.get<TUserPositionChangeResponse>(path).then(result => {
			return adaptUserPositionChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPositionChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionChangeCurrentReadSlice = createSlice({
	name: 'userPositionChangeCurrentReadSlice',
	initialState,
	reducers: {
		setUserPositionChangeCurrentRead(state, action: PayloadAction<TUserPositionChange>) {
			state.item = action.payload;
		},

		clearUserPositionChangeCurrentRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionChangeCurrentReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionChangeCurrentReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPositionChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionChangeCurrentReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionChangeCurrentReadSelector = () => useAppSelector(state => state[userPositionChangeCurrentReadSlice.name]);

export const { clearUserPositionChangeCurrentRead, setUserPositionChangeCurrentRead } = userPositionChangeCurrentReadSlice.actions;
