import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const WorkAreasSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TWorkAreasSourceSortCol = TTableSortCols<typeof WorkAreasSourceSortCol>;

export type TWorkAreaSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TWorkAreasSourceSortCol;
};

export type TWorkAreasSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptWorkAreasSourceQueryToRequest(data?: TWorkAreaSourceQuery): TWorkAreasSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TWorkAreaSource = {
  id: number;
  name: string;
};

export type TWorkAreaSourceResponse = {
  id: number;
  name: string;
};

export type TWorkAreaSourceListResponse = TListResponse<TWorkAreaSourceResponse>;

export type TWorkAreaSourceList = TListResponse<TWorkAreaSource>;

export function adaptWorkAreaSourceFromListResponse(data: TWorkAreaSourceListResponse): TWorkAreaSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptWorkAreaSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptWorkAreaSourceFromResponse(data: TWorkAreaSourceResponse): TWorkAreaSource {
	const { id, name } = data;

	return { id, name };
}
