import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ScheduleEventsTypesSortCol, TScheduleEventTypeList, TScheduleEventTypeListResponse, TScheduleEventTypeQuery, TScheduleEventsTypesSortCol, adaptScheduleEventTypeFromListResponse, adaptScheduleEventsTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES } from '../../backend-paths';


export const scheduleEventsTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES,
	(payload: TScheduleEventTypeQuery | undefined, thunkApi) => {
		const params = payload ? adaptScheduleEventsTypesQueryToRequest(payload) : {};

		return HttpService.get<TScheduleEventTypeListResponse>(BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES, { params }).then(result => {
			return adaptScheduleEventTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TScheduleEventTypeList;
	query: TScheduleEventTypeQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ScheduleEventsTypesSortCol.CREATED_AT,
	},
};

export const scheduleEventsTypesSlice = createSlice({
	name: 'scheduleEventsTypesSlice',
	initialState,
	reducers: {
		setScheduleEventsTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setScheduleEventsTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setScheduleEventsTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setScheduleEventsTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setScheduleEventsTypesQuerySortCol(state, action: PayloadAction<TScheduleEventsTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearScheduleEventsTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[scheduleEventsTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventsTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[scheduleEventsTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventsTypesSelector = () => useAppSelector(state => state[scheduleEventsTypesSlice.name]);

export const { clearScheduleEventsTypes, setScheduleEventsTypesQueryLimit, setScheduleEventsTypesQueryOffset, setScheduleEventsTypesQuerySort, setScheduleEventsTypesQuerySortCol, setScheduleEventsTypesQuerySearch } = scheduleEventsTypesSlice.actions;
