import { PATH_SIGN_IN } from 'src/routes';
import { NavButton } from '../nav-button';
import { NavItem } from '../nav-item';
import { NavLogo } from '../nav-logo';

import styles from './Nav.module.scss';


type TNavProps = {
	onClick?: () => void;
	style?: Record<string, string>;
	isVisibleButton?: boolean;
	active?: boolean;
};

export function Nav(props: TNavProps) {
	const {
		onClick = () => {},
		isVisibleButton = false,
		active = false,
		style = {},
	} = props;

	// const items = [
		// { text: 'Главная', icon: 'icon-home', to: PATH_HOME },
	// ];

	return (
		<nav className={ styles.nav } style={ style }>
			<div className={ styles.wrap }>
				<div className={ styles.relative }>
					{ isVisibleButton && <NavButton active={ active } onClick={ onClick } /> }

					{ !isVisibleButton &&
						<div className={ styles.menu }>
							<NavItem icon="icon-enter" to={ PATH_SIGN_IN }>Вход</NavItem>
						</div>
					}
				</div>

				{/* <div className={ styles.menu }>
					{
						items.map(item => <NavItem { ...item } key={ item.to } />)
					}
				</div> */}

				<NavLogo />
			</div>
		</nav>
	);
}
