import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersDepartmentsSortCol, TUserDepartmentList, TUserDepartmentListResponse, TUserDepartmentQuery, TUsersDepartmentsSortCol, adaptUserDepartmentFromListResponse, adaptUsersDepartmentsQueryToRequest } from '../../types';
import { BACKEND_ADMIN_USERS_DEPARTMENTS } from '../../backend-paths';


export const usersDepartmentsGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_DEPARTMENTS,
	(payload: TUserDepartmentQuery | undefined, thunkApi) => {
		const params = adaptUsersDepartmentsQueryToRequest(payload);

		return HttpService.get<TUserDepartmentListResponse>(BACKEND_ADMIN_USERS_DEPARTMENTS, { params }).then(result => {
			return adaptUserDepartmentFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserDepartmentList;
	query: TUserDepartmentQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersDepartmentsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersDepartmentsSlice = createSlice({
	name: 'usersDepartmentsSlice',
	initialState,
	reducers: {
		setUsersDepartmentsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersDepartmentsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersDepartmentsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersDepartmentsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersDepartmentsQuerySortCol(state, action: PayloadAction<TUsersDepartmentsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersDepartments(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersDepartmentsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersDepartmentsGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartmentList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersDepartmentsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersDepartmentsSelector = () => useAppSelector(state => state[usersDepartmentsSlice.name]);

export const { clearUsersDepartments, setUsersDepartmentsQueryLimit, setUsersDepartmentsQueryOffset, setUsersDepartmentsQuerySort, setUsersDepartmentsQuerySortCol, setUsersDepartmentsQuerySearch } = usersDepartmentsSlice.actions;
