import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountData, TAccountDataPayload, TAccountDataResponse, adaptAccountDataFromResponse, adaptAccountDataToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_DATA } from '../../backend-paths';


export const accountDataUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_DATA}`,
	(payload: TAccountDataPayload, thunkApi) => {
		const { data } = payload;

		const dataRequest = adaptAccountDataToRequest(data);

		return HttpService.put<TAccountDataResponse>(BACKEND_ACCOUNT_DATA, dataRequest).then(result => {
			return adaptAccountDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountDataUpdateSlice = createSlice({
	name: 'accountDataUpdateSlice',
	initialState,
	reducers: {
		clearAccountDataUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountDataUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountDataUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountDataUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountDataUpdateSelector = () => useAppSelector(state => state[accountDataUpdateSlice.name]);

export const { clearAccountDataUpdate } = accountDataUpdateSlice.actions;
