import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserData, TUserDataResponse, adaptUserDataFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDataReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/data`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/data`;

		return HttpService.get<TUserDataResponse>(path).then(result => {
			return adaptUserDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDataReadSlice = createSlice({
	name: 'userDataReadSlice',
	initialState,
	reducers: {
		setUserDataRead(state, action: PayloadAction<TUserData>) {
			state.item = action.payload;
		},

		clearUserDataRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDataReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDataReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDataReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDataReadSelector = () => useAppSelector(state => state[userDataReadSlice.name]);

export const { clearUserDataRead, setUserDataRead } = userDataReadSlice.actions;
