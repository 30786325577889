import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingReportsWritingDelayPenalty, TSystemSettingReportsWritingDelayPenaltyResponse, adaptSystemSettingReportsWritingDelayPenaltyFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_WRITING_DELAY_PENALTY } from '../../backend-paths';


export const systemSettingReportsWritingDelayPenaltyReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_WRITING_DELAY_PENALTY}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_WRITING_DELAY_PENALTY;

		return HttpService.get<TSystemSettingReportsWritingDelayPenaltyResponse>(path).then(result => {
			return adaptSystemSettingReportsWritingDelayPenaltyFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingReportsWritingDelayPenalty> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingReportsWritingDelayPenaltyReadSlice = createSlice({
	name: 'systemSettingReportsWritingDelayPenaltyReadSlice',
	initialState,
	reducers: {
		setSystemSettingReportsWritingDelayPenaltyRead(state, action: PayloadAction<TSystemSettingReportsWritingDelayPenalty>) {
			state.item = action.payload;
		},

		clearSystemSettingReportsWritingDelayPenaltyRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingReportsWritingDelayPenaltyReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingReportsWritingDelayPenaltyReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingReportsWritingDelayPenalty>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingReportsWritingDelayPenaltyReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingReportsWritingDelayPenaltyReadSelector = () => useAppSelector(state => state[systemSettingReportsWritingDelayPenaltyReadSlice.name]);

export const { clearSystemSettingReportsWritingDelayPenaltyRead, setSystemSettingReportsWritingDelayPenaltyRead } = systemSettingReportsWritingDelayPenaltyReadSlice.actions;
