import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserDepartmentChangeParams, TUserResponse, adaptUserFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDepartmentChangeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:nickname/departments/:id`,
	(payload: TUserDepartmentChangeParams, thunkApi) => {
		const { nickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/departments/${id}`;

		return HttpService.delete<TUserResponse>(path).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDepartmentChangeDeleteSlice = createSlice({
	name: 'userDepartmentChangeDeleteSlice',
	initialState,
	reducers: {
		clearUserDepartmentChangeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDepartmentChangeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDepartmentChangeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDepartmentChangeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDepartmentChangeDeleteSelector = () => useAppSelector(state => state[userDepartmentChangeDeleteSlice.name]);

export const { clearUserDepartmentChangeDelete } = userDepartmentChangeDeleteSlice.actions;
