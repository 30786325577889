import { adaptUserCategoryFromResponse, TUserCategory, TUserCategoryResponse } from "./user-category.type";


export type TUserDayCategoryQueryPayload = {
	nickname: string;
	query: TUserDayCategoryQuery;
};

export type TUserDayCategoryQuery = {
	dateStart: string;
	dateEnd?: string;
};

export type TUserDayCategoryQueryRequest = {
	date_start: string;
	date_end?: string;
};

export function adaptUserDayCategoryQueryToRequest(data: TUserDayCategoryQuery): TUserDayCategoryQueryRequest {
	const {
		dateStart,
		dateEnd,
	} = data;

	return {
		date_start: dateStart,
		date_end: dateEnd,
	};
}

export type TUserDayCategory = {
  dayCategory?: TUserCategory;
};

export type TUserDayCategoryResponse = {
  day_category?: TUserCategoryResponse;
};

export function adaptUserDayCategoryFromResponse(data: TUserDayCategoryResponse): TUserDayCategory {
	const { day_category: dayCategoryRaw } = data;

	const dayCategory = dayCategoryRaw ? adaptUserCategoryFromResponse(dayCategoryRaw) : undefined;

	return { dayCategory };
}
