import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const EquipmentsSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsSourceSortCol = TTableSortCols<typeof EquipmentsSourceSortCol>;

export type TEquipmentSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsSourceSortCol;
};

export type TEquipmentsSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsSourceQueryToRequest(data?: TEquipmentSourceQuery): TEquipmentsSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentSource = {
  id: number;
  name: string;
  publicId: string;
	serialNumber?: string;
  storagePlace?: string;
  amount?: number;

  categories: string[];
  storageName?: string;
};

export type TEquipmentSourceResponse = {
  id: number;
  name: string;
  public_id: string;
	serial_number?: string;
  storage_place?: string;
  amount?: number;

  categories: string[];
  storage_name?: string;
};

export type TEquipmentSourceListResponse = TListResponse<TEquipmentSourceResponse>;

export type TEquipmentSourceList = TListResponse<TEquipmentSource>;

export function adaptEquipmentSourceFromListResponse(data: TEquipmentSourceListResponse): TEquipmentSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentSourceFromResponse(data: TEquipmentSourceResponse): TEquipmentSource {
	const {
		id,
		name,
		public_id: publicId,
		serial_number: serialNumber,
		storage_place: storagePlace,
		amount,

		categories,
		storage_name: storageName,
	} = data;

	return {
		id,
		name,
		publicId,
		serialNumber,
		storagePlace,
		amount,
		categories,
		storageName,
	};
}
