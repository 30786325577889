import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountShiftExpense, TAccountShiftExpensePayloadUpdate, TAccountShiftExpenseResponse, adaptAccountShiftExpenseFromResponse, adaptAccountShiftExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFT_EXPENSE } from '../../backend-paths';


export const accountShiftExpenseUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_SHIFT_EXPENSE}/:shiftId`,
	(payload: TAccountShiftExpensePayloadUpdate, thunkApi) => {
		const { id, data, files } = payload;
		const path = `${BACKEND_ACCOUNT_SHIFT_EXPENSE}/${id}`;

		const dataRequest = adaptAccountShiftExpenseToRequest(data);

		return HttpService.put<TAccountShiftExpenseResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountShiftExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountShiftExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftExpenseUpdateSlice = createSlice({
	name: 'accountShiftExpenseUpdateSlice',
	initialState,
	reducers: {
		clearAccountShiftExpenseUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftExpenseUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftExpenseUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftExpenseUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftExpenseUpdateSelector = () => useAppSelector(state => state[accountShiftExpenseUpdateSlice.name]);

export const { clearAccountShiftExpenseUpdate } = accountShiftExpenseUpdateSlice.actions;
