import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { FormSearchBoxFieldListItem, TFormSearchBoxFieldListOption } from './FormSearchBoxFieldListItem';

import styles from './FormSearchBoxField.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TFormSearchBoxFieldListOption[];
	value: string;
	setValue: (value: string) => void;
};

export function FormSearchBoxFieldList(props: TProps) {
	const {
		value: currentValue,
		items,
		setValue,
		...restProps
	} = props;

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map(item => {
					const { text, value } = item;

					return (
						<FormSearchBoxFieldListItem
							key={ value }
							disabled={ value === currentValue }
							onClick={ e => setValue(value) }
						>{ text }</FormSearchBoxFieldListItem>
					);
				})
			}
		</div>
	);
}
