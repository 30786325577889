import { createPortal } from 'react-dom';

import { TTagListBoxItem } from '../TagListBox';

import styles from './TagListBoxModal.module.scss';


type TProps = {
	item: TTagListBoxItem;
	parent?: Element;
};

export function TagListBoxModal(props: TProps) {
	const { item, parent: parentElement } = props;
	const {
		name: nameRaw,
		description: descriptionRaw,
	} = item;

	const title = nameRaw ? nameRaw : '—';
	const description = descriptionRaw ? descriptionRaw : 'Нет описания';
	const classNamesDescription = [styles.text];

	if (!descriptionRaw) {
		classNamesDescription.push(styles.empty);
	}

	const elem = (
		<div className={ styles.veil }>
			<div className={ styles.container }>
				<div className={ styles.headline }>
					<span className={ styles.title }>{ title }</span>
				</div>
				<p className={ classNamesDescription.join(' ') }>{ description }</p>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
