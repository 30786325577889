import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { createPortal } from 'react-dom';

import { ASIDE_PORTAL_CONTAINER_ID } from 'src/components/nav/aside/Aside';

import { TAsideItemOption } from '../../AsideItem';

import { AsideItemMenuOption } from './components';

import styles from './AsideItemMenu.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items?: TAsideItemOption[];
	position: number;
};

export function AsideItemMenu(props: TProps) {
	const {
		items = [],
		position,
		...restProps
	} = props;

	const portalContainer = document.getElementById(ASIDE_PORTAL_CONTAINER_ID);

	if (!portalContainer) {
		return (
			<></>
		);
	}

	const style = { top: `${position}px` };

	const elem = (
		<div className={ styles.box } { ...restProps } style={ style }>
			{ items.map((item, index) => <AsideItemMenuOption key={ index } { ...item } />) }
		</div>
	);

	return createPortal(elem, portalContainer);
}
