import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountDepartmentChangeReadPage = lazy(() => import('../views/read/AccountDepartmentChangeReadPage'));

export function AccountDepartmentChangeReadPageLazy() {
	return (
		<PageSuspense>
			<AccountDepartmentChangeReadPage />
		</PageSuspense>
	);
}
