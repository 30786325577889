import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TProjectSourceList, TProjectSourceListResponse, TProjectSourceQuery, adaptProjectSourceFromListResponse, adaptProjectsSourceQueryToRequest } from '../../types';
import { BACKEND_SOURCE_PROJECTS } from '../../backend-paths';


export const sourceProjectGetApi = createAsyncThunk(
	BACKEND_SOURCE_PROJECTS,
	(payload: TProjectSourceQuery | undefined, thunkApi) => {
		const params = adaptProjectsSourceQueryToRequest(payload);

		return HttpService.get<TProjectSourceListResponse>(BACKEND_SOURCE_PROJECTS, { params }).then(result => {
			return adaptProjectSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TProjectSourceList;
	query: TProjectSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceProjectSlice = createSlice({
	name: 'sourceProjectSlice',
	initialState,
	reducers: {
		setSourceProjectQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceProject(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceProjectGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceProjectGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceProjectGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceProjectSelector = () => useAppSelector(state => state[sourceProjectSlice.name]);

export const { clearSourceProject, setSourceProjectQuerySearch } = sourceProjectSlice.actions;
