import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrder, TProjectOrderPayloadUpdate, TProjectOrderResponse, adaptProjectOrderFromResponse, adaptProjectOrderToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS}/:projectId/orders/:id`,
	(payload: TProjectOrderPayloadUpdate, thunkApi) => {
		const { id, projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${id}`;

		const dataRequest = adaptProjectOrderToRequest(data);

		return HttpService.put<TProjectOrderResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderUpdateSlice = createSlice({
	name: 'projectOrderUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderUpdateSelector = () => useAppSelector(state => state[projectOrderUpdateSlice.name]);

export const { clearProjectOrderUpdate } = projectOrderUpdateSlice.actions;
