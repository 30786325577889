import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProjectClient, adaptProjectClientFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PROJECTS_CLIENTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PROJECTS_CLIENTS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectClientFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectClient> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientDeleteSlice = createSlice({
	name: 'projectClientDeleteSlice',
	initialState,
	reducers: {
		clearProjectClientDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectClientDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProjectClient>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectClientDeleteSelector = () => useAppSelector(state => state[projectClientDeleteSlice.name]);

export const { clearProjectClientDelete } = projectClientDeleteSlice.actions;
