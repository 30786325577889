import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingReportsPhotoNotAttachingPenalty = TSystemSettingValueCommon & {
  value?: number;
};

export type TSystemSettingReportsPhotoNotAttachingPenaltyResponse = TSystemSettingValueCommonResponse & {
  value?: number;
};

export type TSystemSettingReportsPhotoNotAttachingPenaltyPayload = {
	value?: number;
};

export type TSystemSettingReportsPhotoNotAttachingPenaltyRequest = {
	value?: number;
};

export function adaptSystemSettingReportsPhotoNotAttachingPenaltyFromResponse(data: TSystemSettingReportsPhotoNotAttachingPenaltyResponse): TSystemSettingReportsPhotoNotAttachingPenalty {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingReportsPhotoNotAttachingPenaltyToRequest(data: TSystemSettingReportsPhotoNotAttachingPenaltyPayload): TSystemSettingReportsPhotoNotAttachingPenaltyRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
