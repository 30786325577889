import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';

import { adaptEquipmentOrderRelationEquipmentFromResponse } from './equipment-order-relation-equipment.type';
import { TEquipmentOrderRelationEquipment, TEquipmentOrderRelationEquipmentResponse } from './equipment-order-relation-equipment.type';


export const EquipmentsGroupsSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsGroupsSortCol = TTableSortCols<typeof EquipmentsGroupsSortCol>;

export type TEquipmentGroupQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsGroupsSortCol;
};

export type TEquipmentsGroupsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsGroupsQueryToRequest(data?: TEquipmentGroupQuery): TEquipmentsGroupsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentGroup = {
  id: number;
  name: string;
  description?: string;
  amount: number;
	createdAt: string;
  updatedAt: string;

	equipmentsRelations: TEquipmentOrderRelationEquipment[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentGroupResponse = {
  id: number;
  name: string;
  description?: string;
  amount: number;
  created_at: string;
  updated_at: string;

	equipments_relations: TEquipmentOrderRelationEquipmentResponse[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentGroupListResponse = TListResponse<TEquipmentGroupResponse>;

export type TEquipmentGroupList = TListResponse<TEquipmentGroup>;

type TEquipmentDataPayload = {
	id?: number;
	amount: number;
	equipmentId: number;
};

type TEquipmentData = {
	id?: number;
	amount: number;
	equipment_id: number;
};

export type TEquipmentGroupBodyRequest = {
	name: string;
	description?: string;
	amount: number;
	equipments: TEquipmentDataPayload[];
};

export type TEquipmentGroupRequest = {
	name: string;
	description?: string;
	amount: number;
	equipments: TEquipmentData[];
};

export type TEquipmentGroupUpdate = {
	id: number;
	data: TEquipmentGroupBodyRequest;
};

export function adaptEquipmentGroupFromListResponse(data: TEquipmentGroupListResponse): TEquipmentGroupList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentGroupFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentGroupFromResponse(data: TEquipmentGroupResponse): TEquipmentGroup {
	const {
		id,
		name,
		description,
		amount,
		equipments_relations: equipmentsRelationsRaw = [],
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const equipmentsRelations = equipmentsRelationsRaw.map(item => adaptEquipmentOrderRelationEquipmentFromResponse(item));
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		amount,
		equipmentsRelations,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentGroupToRequest(data: TEquipmentGroupBodyRequest): TEquipmentGroupRequest {
	const {
		name,
		description = undefined,
		amount,
		equipments: equipmentsRaw = [],
	} = data;

	const equipments = equipmentsRaw.map(item => {
		const { id, amount, equipmentId } = item;

		return {
			id,
			amount,
			equipment_id: equipmentId,
		};
	});

	return {
		name,
		amount,
		description,
		equipments,
	};
}
