// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormInputField_marginLeft__RTHTZ {\n  margin-left: 0.125em;\n}\n\n.FormInputField_marginRight__AyKDb {\n  margin-right: 0.125em;\n}\n\n.FormInputField_nowrap__BiTU9 {\n  white-space: nowrap;\n}\n\n.FormInputField_width100__GxoEe {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-input-field/FormInputField.module.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;AACD;;AAEA;EACC,qBAAA;AACD;;AAEA;EACC,mBAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".marginLeft {\n\tmargin-left: 0.125em;\n}\n\n.marginRight {\n\tmargin-right: 0.125em;\n}\n\n.nowrap {\n\twhite-space: nowrap;\n}\n\n.width100 {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginLeft": "FormInputField_marginLeft__RTHTZ",
	"marginRight": "FormInputField_marginRight__AyKDb",
	"nowrap": "FormInputField_nowrap__BiTU9",
	"width100": "FormInputField_width100__GxoEe"
};
export default ___CSS_LOADER_EXPORT___;
