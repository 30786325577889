import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const FinancialChangesTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TFinancialChangesTypesSourceSortCol = TTableSortCols<typeof FinancialChangesTypesSourceSortCol>;

export type TFinancialChangeTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TFinancialChangesTypesSourceSortCol;
};

export type TFinancialChangesTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptFinancialChangesTypesSourceQueryToRequest(data?: TFinancialChangeTypeSourceQuery): TFinancialChangesTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TFinancialChangeTypeSource = {
  id: number;
  name: string;
  description: string;
  isNegative: boolean;
};

export type TFinancialChangeTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
  is_negative?: boolean;
};

export type TFinancialChangeTypeSourceListResponse = TListResponse<TFinancialChangeTypeSourceResponse>;

export type TFinancialChangeTypeSourceList = TListResponse<TFinancialChangeTypeSource>;

export function adaptFinancialChangeTypeSourceFromListResponse(data: TFinancialChangeTypeSourceListResponse): TFinancialChangeTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptFinancialChangeTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptFinancialChangeTypeSourceFromResponse(data: TFinancialChangeTypeSourceResponse): TFinancialChangeTypeSource {
	const {
		id,
		name,
		description = '',
		is_negative: isNegative = false,
	} = data;

	return {
		id,
		name,
		description,
		isNegative,
	};
}
