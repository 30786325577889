import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsOrdersHistoryPage = lazy(() => import('../views/list/EquipmentsOrdersHistoryPage'));

export function EquipmentsOrdersHistoryPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsOrdersHistoryPage />
		</PageSuspense>
	);
}
