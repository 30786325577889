import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_SOURCE_TAXATIONS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountTaxation, TAccountTaxationListResponse, adaptAccountTaxationsFromListResponse } from '../../types';


export const accountSourceTaxationsGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_TAXATIONS}`,
	(payload, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_TAXATIONS}`;

		return HttpService.get<TAccountTaxationListResponse>(path).then(result => {
			return adaptAccountTaxationsFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TAccountTaxation[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	items: undefined,
	error: undefined,
};

export const accountSourceTaxationsSlice = createSlice({
	name: 'accountSourceTaxationsSlice',
	initialState,
	reducers: {
		clearAccountSourceAccountTaxation(state) {
			return { ...initialState, list: state.items };
		},
	},

	extraReducers: {
		[accountSourceTaxationsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSourceTaxationsGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxation[]>) => {
			state.isLoading = false;
			state.items = action.payload;
			state.error = undefined;
		},

		[accountSourceTaxationsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountSourceTaxationsSelector = () => useAppSelector(state => state[accountSourceTaxationsSlice.name]);

export const { clearAccountSourceAccountTaxation } = accountSourceTaxationsSlice.actions;
