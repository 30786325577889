import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './TableBodyRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function TableBodyRow(props: TProps) {
	const {
		children,
		className: classNameOuter = '',
	} = props;

	const classNames = [styles.tableBodyRow];

	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	return (
		<tr className={ classNames.join(' ') }>
			{ children }
		</tr>
	);
}
