import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { AccountSalarySortCol, TAccountSalaryList, TAccountSalaryQuery, TAccountSalaryListResponse, TAccountSalarySortCol, adaptAccountSalaryFromListResponse, adaptAccountSalaryQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_SALARY_HISTORY } from '../../backend-paths';


export const accountSalariesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SALARY_HISTORY}`,
	(payload: TAccountSalaryQuery, thunkApi) => {
		const params = payload ? adaptAccountSalaryQueryRequest(payload) : {};

		return HttpService.get<TAccountSalaryListResponse>(BACKEND_ACCOUNT_SALARY_HISTORY, { params }).then(result => {
			return adaptAccountSalaryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TAccountSalaryList,
	query: TAccountSalaryQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: AccountSalarySortCol.CREATED_AT,
	},
};

export const accountSalariesSlice = createSlice({
	name: 'accountSalariesSlice',
	initialState,
	reducers: {
		setAccountSalariesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountSalariesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountSalariesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountSalariesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountSalariesQuerySortCol(state, action: PayloadAction<TAccountSalarySortCol>) {
			state.query.sortCol = action.payload;
		},

		setAccountSalariesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountSalariesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearAccountSalaries(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountSalariesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSalariesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountSalaryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountSalariesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountSalarySelector = () => useAppSelector(state => state[accountSalariesSlice.name]);

export const { clearAccountSalaries, setAccountSalariesQueryLimit, setAccountSalariesQueryOffset, setAccountSalariesQuerySort, setAccountSalariesQuerySortCol, setAccountSalariesQuerySearch, setAccountSalariesQueryDateStart, setAccountSalariesQueryDateEnd } = accountSalariesSlice.actions;
