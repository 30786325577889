import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserTaxationReceipt, TUserTaxationReceiptPayloadUpdate, TUserTaxationReceiptResponse, adaptUserTaxationReceiptFromResponse, adaptUserTaxationReceiptToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_TAXATIONS_RECEIPTS } from '../../backend-paths';


export const userTaxationReceiptUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_USERS_TAXATIONS_RECEIPTS}/:id`,
	(payload: TUserTaxationReceiptPayloadUpdate, thunkApi) => {
		const { id, data } = payload;
		const path = `${BACKEND_USERS_TAXATIONS_RECEIPTS}/${id}`;

		const dataRequest = adaptUserTaxationReceiptToRequest(data);

		return HttpService.put<TUserTaxationReceiptResponse>(path, dataRequest).then(result => {
			return adaptUserTaxationReceiptFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxationReceipt> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationReceiptUpdateSlice = createSlice({
	name: 'userTaxationReceiptUpdateSlice',
	initialState,
	reducers: {
		clearUserTaxationReceiptUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationReceiptUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTaxationReceiptUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationReceipt>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationReceiptUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTaxationReceiptUpdateSelector = () => useAppSelector(state => state[userTaxationReceiptUpdateSlice.name]);

export const { clearUserTaxationReceiptUpdate } = userTaxationReceiptUpdateSlice.actions;
