import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const UsersTaxationsReceiptsSortCol = {
	USER_NAME: 'user_name',
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersTaxationsReceiptsSortCol = TTableSortCols<typeof UsersTaxationsReceiptsSortCol>;

export type TUsersTaxationsReceiptsQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersTaxationsReceiptsSortCol;
};

export type TUsersTaxationsReceiptsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUsersTaxationsReceiptsQueryPayload = {
	data?: TUsersTaxationsReceiptsQuery;
	// userId?: number;
};

export function adaptUsersTaxationsReceiptsQueryToRequest(data?: TUsersTaxationsReceiptsQuery): TUsersTaxationsReceiptsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserTaxationReceipt = {
	id: number;
  path: string;
  date: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;

	user: TUserMeta;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserTaxationReceiptResponse = {
	id: number;
  path: string;
  date: string;
	description?: string;
	created_at?: string;
	updated_at?: string;

	user: TUserMetaResponse;
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserTaxationReceiptListResponse = TListResponse<TUserTaxationReceiptResponse>;

export type TUserTaxationReceiptList = TListResponse<TUserTaxationReceipt>;

export type TUserTaxationReceiptBodyRequest = {
	userNickname: string;
	path: string;
	date: string;
	description?: string;
};

export type TUserTaxationReceiptRequest = {
	user_nickname: string;
	path: string;
	date: string;
	description?: string;
};

export type TUserTaxationReceiptPayloadCreate = {
	data: TUserTaxationReceiptBodyRequest;
};

export type TUserTaxationReceiptPayloadUpdate = {
	id: number;
	data: TUserTaxationReceiptBodyRequest;
};

export function adaptUserTaxationReceiptFromListResponse(data: TUserTaxationReceiptListResponse): TUserTaxationReceiptList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserTaxationReceiptFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserTaxationReceiptFromResponse(data: TUserTaxationReceiptResponse): TUserTaxationReceipt {
	const {
		id,
		path,
		date,
		description: descriptionRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		user: userRaw,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const user = adaptUserMetaFromResponse(userRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		path,
		date,
		description,
		createdAt,
		updatedAt,

		user,
		createdBy,
		updatedBy,
	};
}

export function adaptUserTaxationReceiptToRequest(data: TUserTaxationReceiptBodyRequest): TUserTaxationReceiptRequest {
	const {
		userNickname,
		path,
		date,
		description,
	} = data;

	return {
		user_nickname: userNickname,
		path,
		date,
		description,
	};
}
