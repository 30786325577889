import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService, LC_KEY_SCHEDULE_FILTER, libFormatDate, LocalStorage } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TScheduleList, TScheduleListResponse, TScheduleQuery, TScheduleQueryRequest, adaptScheduleFromListResponse, adaptScheduleQueryToRequest } from '../../types';
import { BACKEND_SCHEDULE } from '../../backend-paths';


function defaultStartDate() {
	const localQuery = LocalStorage.get(LC_KEY_SCHEDULE_FILTER);

	if (localQuery && localQuery.dateStart) {
		return localQuery.dateStart;
	}

	const date = new Date();

	while (date.getDay() !== 1) {
		date.setDate(date.getDate() - 1);
	}

	return libFormatDate(date, 'yyyy-MM-dd');
}

function defaultEndDate() {
	const localQuery = LocalStorage.get(LC_KEY_SCHEDULE_FILTER);

	if (localQuery && localQuery.dateEnd) {
		return localQuery.dateEnd;
	}

	const date = new Date();

	while (date.getDay() !== 0) {
		date.setDate(date.getDate() + 1);
	}

	return libFormatDate(date, 'yyyy-MM-dd');
}

export const scheduleGetApi = createAsyncThunk(
	BACKEND_SCHEDULE,
	(payload: TScheduleQuery, thunkApi) => {
		const params = payload ? adaptScheduleQueryToRequest(payload) : {};

		return HttpService.get<TScheduleListResponse>(BACKEND_SCHEDULE, { params }).then(result => {
			return adaptScheduleFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TScheduleQueryRequestKeys = keyof TScheduleQueryRequest;
type TScheduleQueryKeys = keyof TScheduleQuery;

type TScheduleQueryDictionaryKeys = [TScheduleQueryRequestKeys, TScheduleQueryKeys];

export type TScheduleQueryDictionary = TScheduleQueryDictionaryKeys[];
export type TScheduleQuerySearchParams = Partial<Record<TScheduleQueryRequestKeys, string>>;

type TInitialState = {
	isLoading: boolean;
	list: TScheduleList;
	query: TScheduleQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	error: undefined,
	isLoading: false,
	list: {
		items: [],
		datesOff: [],
	},
	query: {
		dateStart: defaultStartDate(),
		dateEnd: defaultEndDate(),
	},
};

export const scheduleSlice = createSlice({
	name: 'scheduleSlice',
	initialState,
	reducers: {
		setScheduleQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setScheduleQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearSchedule(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[scheduleGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleGetApi.fulfilled.type]: (state, action: PayloadAction<TScheduleList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[scheduleGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleSelector = () => useAppSelector(state => state[scheduleSlice.name]);

export const { clearSchedule, setScheduleQueryDateStart, setScheduleQueryDateEnd } = scheduleSlice.actions;
