import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

import { FormButtonLink, FormField, FormTextarea, FormRow, Icon, TFormInputFieldReadonlyFontColor, FormInputFieldReadonlyFontColor } from 'src/components';

import styles from './FormTextareaFieldReadonly.module.scss';


type TProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
	legend?: string;
	marginLeft?: boolean;
	marginRight?: boolean;
	nowrap?: boolean;

	to?: string;
	icon?: string;
	buttonDisabled?: boolean;
	buttonTitle?: string;

	fontColor?: TFormInputFieldReadonlyFontColor;
};

export function FormTextareaFieldReadonly(props: TProps) {
	const {
		legend = '',
		ref,
		marginLeft = false,
		marginRight = false,
		nowrap = false,

		to,
		icon,
		buttonDisabled,
		buttonTitle,

		fontColor,
		className: textareaClassNameOuter = [],

		...restProps
	} = props;

	const classNames = [];

	if (nowrap) {
		classNames.push(styles.nowrap);
	}

	const classNamesTextarea = textareaClassNameOuter ? [textareaClassNameOuter] : [];

	if (fontColor) {
		if (fontColor === FormInputFieldReadonlyFontColor.RED) {
			classNamesTextarea.push(styles.colorRed);
		} else if (fontColor === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesTextarea.push(styles.colorGreen);
		}
	}

	if (icon && to) {
		return (
			<FormField
				className={ classNames.join(' ') }
				legend={ legend }
				width100
				marginRight={ marginRight }
				marginLeft={ marginLeft }
			>
				<FormRow>
					<FormTextarea className={ classNamesTextarea.join(' ') } { ...restProps } />

					<FormButtonLink
						mini
						type="button"
						disabled={ buttonDisabled }
						tabIndex={ -1 }
						to={ to }
						title={ buttonTitle }
					>
						<Icon icon={ icon } />
					</FormButtonLink>
				</FormRow>
			</FormField>
		);
	}

	return (
		<FormField
			className={ classNames.join(' ') }
			legend={ legend }
			marginRight={ marginRight }
			marginLeft={ marginLeft }
		>
			<FormTextarea className={ classNamesTextarea.join(' ') } { ...restProps } />
		</FormField>
	);
}
