import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderSource, TProjectOrderSourceResponse, adaptProjectOrderSourceFromResponse } from './project-order-source.type';
import { TEquipmentOrderStatusSource, TEquipmentOrderStatusSourceResponse, adaptEquipmentOrderStatusSourceFromResponse } from './equipment-order-status-source.type';
import { TEquipmentOrderHistoryDataRelationEquipment, TEquipmentOrderHistoryDataRelationEquipmentResponse, adaptEquipmentOrderHistoryDataRelationEquipmentFromResponse } from './equipment-order-history-data-relation-equipment.type';
import { TEquipmentOrderHistoryDataRelationEquipmentGroup, TEquipmentOrderHistoryDataRelationEquipmentGroupResponse, adaptEquipmentOrderHistoryDataRelationEquipmentGroupFromResponse } from './equipment-order-history-data-relation-equipment-group.type';


export const EquipmentsOrdersHistoryDataSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsOrdersHistoryDataSortCol = TTableSortCols<typeof EquipmentsOrdersHistoryDataSortCol>;

export type TEquipmentOrderHistoryDataQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsOrdersHistoryDataSortCol;
};

export type TEquipmentsOrdersHistoryDataQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsOrdersHistoryDataQueryToRequest(data?: TEquipmentOrderHistoryDataQuery): TEquipmentsOrdersHistoryDataQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentOrderHistoryData = {
  id: number;
	dateStart: string;
	dateEnd: string;
	createdAt: string;
  updatedAt: string;

	user: TUserMeta;
	projectOrder: TProjectOrderSource;
	status: TEquipmentOrderStatusSource;
	equipmentsRelations: TEquipmentOrderHistoryDataRelationEquipment[];
	equipmentsGroupsRelations: TEquipmentOrderHistoryDataRelationEquipmentGroup[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentOrderHistoryDataResponse = {
  id: number;
	date_start: string;
	date_end: string;
  created_at: string;
  updated_at: string;

	user: TUserMetaResponse;
	project_order: TProjectOrderSourceResponse;
	status: TEquipmentOrderStatusSourceResponse;
	equipments_relations: TEquipmentOrderHistoryDataRelationEquipmentResponse[];
	equipments_groups_relations: TEquipmentOrderHistoryDataRelationEquipmentGroupResponse[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentOrderHistoryDataListResponse = TListResponse<TEquipmentOrderHistoryDataResponse>;

export type TEquipmentOrderHistoryDataList = TListResponse<TEquipmentOrderHistoryData>;

type TEquipmentDataPayload = {
	id?: number;
	amount: number;
	equipmentId: number;
};

type TEquipmentData = {
	id?: number;
	amount: number;
	equipment_id: number;
};

export type TEquipmentOrderHistoryDataBodyRequest = {
	userNickname: string;
	projectOrderId: number;
	dateStart: string;
	timeStart: string;
	dateEnd: string;
	timeEnd: string;
	statusId: number;
	equipments: TEquipmentDataPayload[];
};

export type TEquipmentOrderHistoryDataRequest = {
	user_nickname: string;
	project_order_id: number;
	date_start: string;
	date_end: string;
	status_id: number;
	equipments: TEquipmentData[];
};

export type TEquipmentOrderHistoryDataUpdate = {
	id: number;
	data: TEquipmentOrderHistoryDataBodyRequest;
};

export function adaptEquipmentOrderHistoryDataFromListResponse(data: TEquipmentOrderHistoryDataListResponse): TEquipmentOrderHistoryDataList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentOrderHistoryDataFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentOrderHistoryDataFromResponse(data: TEquipmentOrderHistoryDataResponse): TEquipmentOrderHistoryData {
	const {
		id,
		date_start: dateStart,
		date_end: dateEnd,
		user: userRaw,
		project_order: projectOrderRaw,
		status: statusRaw,
		equipments_relations: equipmentsRelationsRaw = [],
		equipments_groups_relations: equipmentsGroupsRelationsRaw = [],
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const user = adaptUserMetaFromResponse(userRaw);
	const projectOrder = adaptProjectOrderSourceFromResponse(projectOrderRaw);
	const status = adaptEquipmentOrderStatusSourceFromResponse(statusRaw);
	const equipmentsRelations = equipmentsRelationsRaw.map(item => adaptEquipmentOrderHistoryDataRelationEquipmentFromResponse(item));
	const equipmentsGroupsRelations = equipmentsGroupsRelationsRaw.map(item => adaptEquipmentOrderHistoryDataRelationEquipmentGroupFromResponse(item));
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		dateStart,
		dateEnd,
		user,
		projectOrder,
		status,
		equipmentsRelations,
		equipmentsGroupsRelations,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}
