import { FormField, FormInput, FormInputFieldReadonlyFontColor, PageRow, TFormInputFieldReadonlyFontColor } from 'src/components';
import { TUserMeta } from 'src/store';
import { libFormatDate, libFormatName } from 'src/toolkit';

import styles from './MetaRow.module.scss';


type TMetaRow = {
	userLegend: string;
	userTitle: string;
	userPlaceholder?: string;

	user?: TUserMeta;

	dateLegend: string;
	dateTitle: string;
	datePlaceholder?: string;

	date: string;

	fontColor?: TFormInputFieldReadonlyFontColor;
};

export function MetaRow(props: TMetaRow) {
	const {
		userLegend = '',
		userTitle = '',
		userPlaceholder = '—',
		user,

		dateLegend = '',
		dateTitle = '',
		datePlaceholder = '—',
		date = '',

		fontColor,
	} = props;

	const classNamesInput = [];

	if (fontColor) {
		if (fontColor === FormInputFieldReadonlyFontColor.RED) {
			classNamesInput.push(styles.colorRed);
		} else if (fontColor === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesInput.push(styles.colorGreen);
		}
	}

	return (
		<PageRow className={ styles.box }>
			<FormField
				className={ styles.name }
				legend={ userLegend }
			>
				<FormInput
					className={ classNamesInput.join(' ') }
					placeholder={ userPlaceholder }
					title={ userTitle }
					value={ libFormatName(user) }
					readOnly
				/>
			</FormField>

			<FormField
				className={ styles.date }
				legend={ dateLegend }
			>
				<FormInput
					className={ classNamesInput.join(' ') }
					placeholder={ datePlaceholder }
					title={ dateTitle }
					value={ libFormatDate(date) }
					readOnly
				/>
			</FormField>
		</PageRow>
	);
}
