import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountTaxationReceiptsPage = lazy(() => import('../views/list/AccountTaxationReceiptsPage'));

export function AccountTaxationReceiptsPageLazy() {
	return (
		<PageSuspense>
			<AccountTaxationReceiptsPage />
		</PageSuspense>
	);
}
