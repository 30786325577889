import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserShiftExpensesPage = lazy(() => import('../views/list/UserShiftExpensesPage'));

export function UserShiftExpensesPageLazy() {
	return (
		<PageSuspense>
			<UserShiftExpensesPage />
		</PageSuspense>
	);
}
