import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TEquipmentOrderStatus, adaptEquipmentOrderStatusFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES } from '../../backend-paths';


export const equipmentOrderStatusDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptEquipmentOrderStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrderStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderStatusDeleteSlice = createSlice({
	name: 'equipmentOrderStatusDeleteSlice',
	initialState,
	reducers: {
		clearEquipmentOrderStatusDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderStatusDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentOrderStatusDeleteApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderStatusDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentOrderStatusDeleteSelector = () => useAppSelector(state => state[equipmentOrderStatusDeleteSlice.name]);

export const { clearEquipmentOrderStatusDelete } = equipmentOrderStatusDeleteSlice.actions;
