import { LocalizationTypes } from 'src/common/types';
import { TLocaleError } from '../hooks';

import { VALIDATION_IS_DATE, VALIDATION_STRINGS_LENGTH_CENTER, VALIDATION_STRINGS_LENGTH_END, VALIDATION_STRINGS_LENGTH_START, VALIDATION_NAME, VALIDATION_PASSPORT_NUMBER, VALIDATION_NUMBERS_LENGTH_START, VALIDATION_NUMBERS_LENGTH_CENTER, VALIDATION_NUMBERS_LENGTH_END, VALIDATION_STRING_LENGTH_START, VALIDATION_STRING_LENGTH_END, VALIDATION_IS_NUMBER, VALIDATION_DATE_MORE_MAX, VALIDATION_DATE_LESS_MIN, VALIDATION_IS_TIME, VALIDATION_TIME_HOURS_MORE_MAX, VALIDATION_TIME_HOURS_LESS_MIN, VALIDATION_TIME_MINUTES_MORE_MAX, VALIDATION_TIME_MINUTES_LESS_MIN } from './text';


export type TStringNull = string | null;

export type TObjectNull = Object | null;

export type TFormField = {
	value: string;
	required: boolean;
	errors: Array<string>;
};

export type TFormContext = {
	[key: string]: TFormField;
};

export type TFormValues = {
	[key: string]: string | number;
};

export type TRecordUnknown = Record<string, unknown>;

export type TRecordAny = Record<string, any>;

export type TFormFieldValidation = {
	field_name: string;
	message: string;
};

export type TFormError = {
	[key: string]: Array<string>;
};


export function formValidateCode(value: string, length?: number): string[] {
	const errors = [];
	const len = length || 6;

	if (isInvalidCode(value, len)) {
		errors.push(`The field must contain a numbers with ${len} length`);
	}

	return errors;
}

type TFormValidateDate = {
	required: boolean;
	maxYear?: number;
	minYear?: number;
	maxDate?: string;
	minDate?: string;
};

type TValidateValue = string | number | boolean | object | undefined | null;

export function isInvalidDate(value: TValidateValue, rawOptions?: TFormValidateDate): boolean {
	const result = formValidateDate(value, rawOptions);

	return result.length > 0;
}

export function formValidateDate(value: TValidateValue, rawOptions?: TFormValidateDate): string[] {
	const errors = [];
	const options = {
		required: false,
		maxYear: null,
		minYear: null,
		maxDate: null,
		minDate: null,
		...rawOptions || {}
	};

	const {
		required,
		maxYear,
		minYear,
		maxDate,
		minDate,
	} = options;

	if (required) {
		const isValidString = typeof value === 'string' && value.length > 0;

		if (!isValidString) {
			errors.push(VALIDATION_IS_DATE);

			return errors;
		}

		const valueRE = /^[\d]{4}-[0-1]{1}[\d]{1}-[0-3]{1}[\d]{1}$/i;
		const getMaxDateOfMonth = (year: number, month: number): number => {
			const getMaxDateOfFebruary = (year: number, month: number): number => {
				const twentyNine = 29;
				const isTwentyNine = new Date(year, month, twentyNine).getDate() === twentyNine;

				return isTwentyNine ? twentyNine : 28;
			};

			const getMaxDateOfRestMonths = (month: number): number => {
				const threetyDays = [3, 5, 8, 10];

				return threetyDays.includes(month) ? 30 : 31;
			};

			const isFebruary = month === 1;

			return isFebruary ? getMaxDateOfFebruary(year, month) : getMaxDateOfRestMonths(month);
		};

		const arr = value.split('-');
		const y = parseInt(arr[0]);
		const m = parseInt(arr[1]) - 1;
		const d = parseInt(arr[2]);

		const isMaxYear = typeof maxYear === 'number' ? y <= maxYear : true;
		const isMinYear = typeof minYear === 'number' ? y >= minYear : true;
		const isYear = isMaxYear && isMinYear;

		const isMonth = m >= 0 && m <= 11;

		const isDay = d >= 1 && d <= getMaxDateOfMonth(y, m);

		const isValidValue = valueRE.test(value) && isYear && isMonth && isDay;

		if (!isValidValue) {
			errors.push(VALIDATION_IS_DATE);
		}

		if (maxDate && value > maxDate) {
			errors.push(`${VALIDATION_DATE_MORE_MAX} «${maxDate}»`);
		}

		if (minDate && value > minDate) {
			errors.push(`${VALIDATION_DATE_LESS_MIN} «${minDate}»`);
		}
	}

	return errors;
}

type TFormValidateTime = {
	required: boolean;
	maxHour?: number;
	minHour?: number;
	maxMinute?: number;
	minMinute?: number;
};

export function formValidateTime(value: string, rawOptions?: TFormValidateTime): string[] {
	const errors = [];
	const options = {
		required: false,
		maxHour: 23,
		minHour: 0,
		maxMinute: 59,
		minMinute: 0,
		...rawOptions || {}
	};

	const {
		required,
		maxHour,
		minHour,
		maxMinute,
		minMinute,
	} = options;

	if (required) {
		const isValidString = typeof value === 'string' && value.length === 5 && value.includes(':');

		if (!isValidString) {
			errors.push(VALIDATION_IS_TIME);

			return errors;
		}

		const arrTime = value.split(':');

		if (arrTime.length !== 2) {
			errors.push(VALIDATION_IS_TIME);

			return errors;
		}

		const [hString, mString] = arrTime;

		if (hString.length !== 2 || mString.length !== 2) {
			errors.push(VALIDATION_IS_TIME);

			return errors;
		}

		const h = parseInt(hString);

		if (h > maxHour) {
			errors.push(`${VALIDATION_TIME_HOURS_MORE_MAX} «${maxHour}»`);
		}

		if (h < minHour) {
			errors.push(`${VALIDATION_TIME_HOURS_LESS_MIN} «${minHour}»`);
		}

		const m = parseInt(mString);

		if (m > maxMinute) {
			errors.push(`${VALIDATION_TIME_MINUTES_MORE_MAX} «${maxMinute}»`);
		}

		if (m < minMinute) {
			errors.push(`${VALIDATION_TIME_MINUTES_LESS_MIN} «${minMinute}»`);
		}
	}

	return errors;
}

type TFormValidateInt = {
	min?: number;
	max?: number;
	required?: boolean;
};

export function formValidateInt(valueRaw: string, options?: TFormValidateInt): string[] {
	const errors = [];

	const value = parseInt(valueRaw);
	const { required = false, min, max } = options || {};

	if (required && isNaN(value)) {
		errors.push('The field must contain of an integer value');
	}

	if (typeof min === 'number' && value < min) {
		errors.push(`The value must be greate than or equal ${min}`);
	}

	if (typeof max === 'number' && value > max) {
		errors.push(`The value must be less than or equal ${max}`);
	}

	return errors;
}

export function formValidateEmail(value: string): string[] {
	const errors = [];

	if (isInvalidEmail(value)) {
		errors.push('The field must contain of E-Mail');
	}

	return errors;
}

export function formValidateHashcode(field: TFormField, length?: number): boolean {
	const { value, required, errors } = field;
	errors.length = 0;

	if (required) {
		const len = length || 64;

		if (isInvalidHashcode(value, len)) {
			errors.push('The field must contain of Hashcode confirm');
		}
	}

	return errors.length === 0;
}

export function formValidateLogin(value: string): string[] {
	const errors = [];

	const arrValidation = [
		isInvalidNickname(value),
		isInvalidEmail(value),
		isInvalidPhoneNumber(value),
	];

	if (!arrValidation.includes(false)) {
		errors.push('The field must contain of Nickname, E-Mail or Phone number');
	}

	return errors;
}

export function formValidateLocaleLogin(value: string): TLocaleError[] {
	const errors = [];

	const arrValidation = [
		isInvalidNickname(value),
		isInvalidEmail(value),
		isInvalidPhoneNumber(value),
	];

	if (!arrValidation.includes(false)) {
		errors.push({
			[LocalizationTypes.EN]: 'The field must contain of Nickname, E-Mail or Phone number',
			[LocalizationTypes.RU]: 'Введите никнейм, электронную почту или номер телефона',
		});
	}

	return errors;
}

export function formValidateRadio(value: string, values: string[]): string[] {
	const errors = [];

	if (!values.includes(value)) {
		errors.push('The field must be selected');
	}

	return errors;
}

export function formValidatePhoneNumberEmail(value: string): string[] {
	const errors = [];

	const arrValidation = [
		isInvalidEmail(value),
		isInvalidPhoneNumber(value),
	];

	if (!arrValidation.includes(false)) {
		errors.push('The field must contain of E-Mail or Phone number');
	}

	return errors;
}


type TFormValidateLink = {
	required: boolean;
};

export function formValidateLink(value: string, rawOptions?: TFormValidateLink): string[] {
	const errors = [];
	const options = {
		required: false,
		...rawOptions || {}
	};

	const { required } = options;

	if (required) {
		const pattern = new RegExp(
			'^(https?:\\/\\/)?'+ 																 // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ 											 // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 									 // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ 												 // query string
			'(\\#[-a-z\\d_]*)?$','i' 														 // fragment locator
		);

		if (!pattern.test(value)) {
			errors.push('The field must contain URL string');
		}
	}

	return errors;
}

type TFormValidateNickname = {
	required?: boolean;
	min?: number;
	max?: number;
};

export function formValidateNickname(value: string, customOptions?: TFormValidateNickname): string[] {
	const errors: string[] = [];

	const options = {
		required: true,
		min: 4,
		max: 16,
		...customOptions,
	};

	const { required, min, max } = options;

	if (!required) {
		return errors;
	}

	const nicknameRE = /^[a-zA-Z\d_-]*$/i;

	if (!nicknameRE.test(value)) {
		errors.push('The field must contain a-zA-Z characters, 0-9 numbers and "_-" simbols only');
	}

	const len = value.length;
	if (len < min || len > max) {
		if (min === max) {
			errors.push(`${VALIDATION_STRING_LENGTH_START}${min}${VALIDATION_STRING_LENGTH_END}`);
		} else {
			errors.push(`${VALIDATION_STRINGS_LENGTH_START}${min}${VALIDATION_STRINGS_LENGTH_CENTER}${max}${VALIDATION_STRINGS_LENGTH_END}`);
		}
	}

	return errors;
}

type TFormValidateNumberStringCleared = {
	required?: boolean;
	min?: number;
	max?: number;
};

export function formValidateNumberStringCleared(valueRaw: string, customOptions: TFormValidateNumberStringCleared = {}): string[] {
	const errors: string[] = [];

	const options = {
		required: true,
		min: 10,
		max: 10,
		...customOptions,
	};

	const { required, min, max } = options;

	if (!required) {
		return errors;
	}

	const value = valueRaw || '';
	const clearValue = value.replace(/\D/ig, '');

	const len = clearValue.length;
	if (len < min || len > max) {
		if (min === max) {
			errors.push(`${VALIDATION_STRING_LENGTH_START}${min}${VALIDATION_STRING_LENGTH_END}`);
		} else {
			errors.push(`${VALIDATION_NUMBERS_LENGTH_START}${min}${VALIDATION_NUMBERS_LENGTH_CENTER}${max}${VALIDATION_NUMBERS_LENGTH_END}`);
		}
	}

	return errors;
}

type TFormValidateName = {
	min?: number;
	max?: number;
};

export function formValidateName(value: string, customOptions: TFormValidateName = {}): string[] {
	const errors = [];

	const options = {
		min: 1,
		max: 128,
		...customOptions,
	};

	const { min, max } = options;

	const len = value.length;
	if (len < min || len > max) {
		const text = `${VALIDATION_STRINGS_LENGTH_START}${min}${VALIDATION_STRINGS_LENGTH_CENTER}${max}${VALIDATION_STRINGS_LENGTH_END}`;

		errors.push(text);
	}

	if (value) {
		const regExp = /^[а-яёА-ЯЁa-zA-Z0-9\s-]*$/i;

		if (!regExp.test(value)) {
			errors.push(VALIDATION_NAME);
		}
	}

	return errors;
}

type TFormValidateText = {
	required?: boolean;
	min?: number;
	max?: number;
};

export function formValidateText(value: string, customOptions: TFormValidateText = {}): string[] {
	const errors = [];

	const options = {
		required: false,
		min: 1,
		max: 128,
		...customOptions,
	};

	const { required, min, max } = options;

	if (required) {
		const stringValue = value || '';
		const len = stringValue.length;
		if (len < min || len > max) {
			const text = `${VALIDATION_STRINGS_LENGTH_START}${min}${VALIDATION_STRINGS_LENGTH_CENTER}${max}${VALIDATION_STRINGS_LENGTH_END}`;

			errors.push(text);
		}
	}

	return errors;
}

export function formValidatePassportNumber(value: string): string[] {
	const errors = [];

	if (value && value.length > 0) {
		const clearValue = value.replace(/\D/ig, '');
		const clearLength = clearValue.length;

		if (clearLength !== 10) {
			errors.push(VALIDATION_PASSPORT_NUMBER);
		}
	}

	return errors;
}

type TFormValidatePrice = {
	required?: boolean;
};

export function formValidatePrice(value: string, rawOptions?: TFormValidatePrice): string[] {
	const errors = [];

	const options = {
		required: false,
		...rawOptions || {}
	};

	const { required } = options;

	if (required || value && value.length > 0) {
		const clearValue = value.replace(/\D/ig, '');
		const numberValue = parseInt(clearValue);
		const isNumber = numberValue >= 0;

		if (!isNumber) {
			errors.push(VALIDATION_IS_NUMBER);
		}
	}

	return errors;
}

type TFormValidatePassword = {
	min?: number;
};

export function formValidatePassword(value: string, customOptions: TFormValidatePassword = {}): string[] {
	const errors = [];

	const options = {
		min: 8,
		...customOptions,
	};

	const { min } = options;

	if (value.length < min) {
		errors.push(`The password must be ${min} characters length`);
		// errors.push(`Длина пароля должна быть не менее ${min} символов`);
	}

	return errors;
}

export function formValidatePasswordConfirm(first: string, second: string): string[] {
	const errors = [];

	if (first !== second) {
		errors.push('Passwords do not match');
	}

	return errors;
}

export function formValidatePasswordUnConfirm(first: string, second: string): string[] {
	const errors = [];

	if (first === second) {
		errors.push('The new password should not repeat current one');
	}

	return errors;
}

export function formValidatePhoneNumber(value: string): string[] {
	const errors = [];

	if (isInvalidPhoneNumber(value)) {
		errors.push('The field must contain of phone number');
	}

	return errors;
}

export function formValidatePid(value: string): string[] {
	const errors = [];

	if (isInvalidPid(value)) {
		errors.push('The field must contain of PID');
	}

	return errors;
}

type TFormValidateId = {
	required?: boolean;
};

export function formValidateId(value: number | string, customOptions: TFormValidateId = {}): string[] {
	const errors = [];

	const options = {
		required: true,
		...customOptions,
	};

	const { required } = options;

	if (required && isInvalidId(value)) {
		errors.push('The field must contains number');
	}

	return errors;
}

export function isInvalidHashcode(valueRaw?: string, lengthRaw?: number): boolean {
	const value = valueRaw || '';
	const length = lengthRaw || 64;
	const re = /^[a-zA-Z\d]+$/;
	const isValid = value.length === length && re.test(value);

	return !isValid;
}

export function isInvalidPhoneNumber(valueRaw: string): boolean {
	const value = valueRaw || '';
	const clearValue = value.replace(/\D/ig, '');
	const isFullNumbers = clearValue.indexOf('7') === 0 || clearValue.indexOf('8') === 0;
	const length = clearValue.length;
	const isPhoneNumber = isFullNumbers ? length === 11 : length === 10;

	return !isPhoneNumber;
}

export function isInvalidEmail(valueRaw: string): boolean {
	const value = valueRaw || '';
	const emailRE = /^[\w]{1}[\w-\\.]*@[\w-]+\.[a-z]{2,4}$/i;

	return !emailRE.test(value);
}

export function isInvalidNickname(valueRaw: string): boolean {
	const value = valueRaw || '';
	const nicknameRE = /^[a-zA-Z\d_-]{3,32}$/i;

	return !nicknameRE.test(value);
}

export function isInvalidRole(valueRaw: string): boolean {
	const value = valueRaw || '';
	const roleRE = /^[A-Z_]{4,64}$/i;

	return !roleRE.test(value);
}

export function isInvalidCode(valueRaw: string, length: number): boolean {
	const value = valueRaw || '';
	const clearValue = value.replace(/\D/ig, '');

	return clearValue.length !== length;
}

export function isInvalidPid(valueRaw?: string): boolean {
	const value = valueRaw || '';
	const nicknameRE = /^[a-z\d-]{36}$/i;

	return !nicknameRE.test(value);
}

export function isInvalidLocale(valueRaw: string): boolean {
	const value = valueRaw || '';
	const nicknameRE = /^[a-z]{2}$/i;

	return !nicknameRE.test(value);
}

export function isInvalidId(valueRaw: number | string): boolean {
	const value = typeof valueRaw === 'string' ? parseInt(valueRaw) : valueRaw;

	return !(value > 0);
}
