import { lazy } from 'react';

import { PageSuspense } from 'src/components';

const SystemSettingCalendarEventColorProjectUpdatePage = lazy(() => import('../views/update-event-color-project/SystemSettingCalendarEventColorProjectUpdatePage'));

export function SystemSettingCalendarEventColorProjectUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingCalendarEventColorProjectUpdatePage />
		</PageSuspense>
	);
}
