export type TAccountCredentials = {
	nickname: string;
	permissions: string[];
	image?: string;
};

export type TAccountCredentialResponse = {
	nickname: string;
	permissions: string[];
	image?: string;
};

export function adaptAccountCredentialsFromResponse(data: TAccountCredentialResponse): TAccountCredentials {
	const {
		nickname,
		image = undefined,
		permissions = [],
	} = data;

	return { nickname, image, permissions };
}
