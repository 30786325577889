import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersTaxationsPage = lazy(() => import('../views/list/UsersTaxationsPage'));

export function UsersTaxationsPageLazy() {
	return (
		<PageSuspense>
			<UsersTaxationsPage />
		</PageSuspense>
	);
}
