import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProductionCalendar, TProductionCalendarResponse, adaptProductionCalendarFromResponse } from '../../types';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PRODUCTION_CALENDARS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PRODUCTION_CALENDARS}/${payload}`;

		return HttpService.get<TProductionCalendarResponse>(path).then(result => {
			return adaptProductionCalendarFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProductionCalendar> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarReadSlice = createSlice({
	name: 'productionCalendarReadSlice',
	initialState,
	reducers: {
		setProductionCalendarRead(state, action: PayloadAction<TProductionCalendar>) {
			state.item = action.payload;
		},

		clearProductionCalendarRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[productionCalendarReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendar>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProductionCalendarReadSelector = () => useAppSelector(state => state[productionCalendarReadSlice.name]);

export const { clearProductionCalendarRead, setProductionCalendarRead } = productionCalendarReadSlice.actions;
