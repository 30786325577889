import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserShiftReport, TUserShiftReportResponse, adaptUserShiftReportFromResponse } from './user-shift-report.type';
import { TUserBookkeeping, TUserBookkeepingResponse, adaptUserBookkeepingUndefinedFromResponse } from './user-bookkeeping.type';
import { ShiftReportListStatus } from './account-shift.type';


export const UserShiftsBookkeepingSortCol = {
	ID: 'id',
	PROJECT_NAME: 'project_name',
	ORDER_NAME: 'order_name',
	DATE: 'date',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUserShiftsBookkeepingSortCol = TTableSortCols<typeof UserShiftsBookkeepingSortCol>;
export type TUserShiftsBookkeepingStatus = TTableSortCols<typeof ShiftReportListStatus>;

export type TUserShiftBookkeepingQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserShiftsBookkeepingSortCol;

	types: TUserShiftsBookkeepingStatus[],
	dateStart?: string;
	dateEnd?: string;
};

export type TUsersShiftsBookkeepingQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	types?: string;
	date_start?: string;
	date_end?: string;
};

export type TUserShiftBookkeepingPayloadQuery = {
	groupId: number;
	orderId: number;
	projectId: number;
	data?: TUserShiftBookkeepingQuery;
};

export function adaptUsersShiftsBookkeepingQueryRequest(data: TUserShiftBookkeepingQuery): TUsersShiftsBookkeepingQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		types,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		types: types && types.length > 0 ? types.join(',') : undefined,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserSalaryBookkeeping = {
	value: number;
	date: string;
};

export type TUserSalaryBookkeepingResponse = {
	value: number;
	date: string;
};

export type TUserSalaryBookkeepingListResponse = TListResponse<TUserSalaryBookkeepingResponse>;

export type TUserSalaryBookkeepingList = TListResponse<TUserSalaryBookkeeping>;

export type TUserShiftBookkeeping = {
	id: number;
	groupId: number;
	date: string;
	orderName: string;
	projectName: string;
	rateTypeName: string;
	rate: number;
	isSalaryDayNotCount: boolean;
	overwork: number;
	deadlinePenalty: number;
	photoPenalty: number;
	isChecked: boolean;
	checkDescription?: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserBookkeeping;
	updatedBy?: TUserBookkeeping;

	report?: TUserShiftReport;
	user?: TUserBookkeeping;
	expenses: TUserShiftExpenseBookkeeping[];
};

export type TUserShiftExpenseBookkeeping = {
	id: number;
	value: number;
	typeId: number;
	name: string;
	description: string;
};

export type TUserShiftBookkeepingResponse = {
	id: number;
	group_id: number;
	date: string;
	order_name: string;
	project_name: string;
	rate_type_name?: string;
	rate: number;
	is_salary_day_not_count: boolean;
	overwork?: number;
	deadline_penalty?: number;
	photo_penalty?: number;
	is_checked: boolean;
	check_description: string;
	created_at?: string;
	updated_at?: string;

	created_by: TUserBookkeepingResponse;
	updated_by: TUserBookkeepingResponse;

	report?: TUserShiftReportResponse;
	user?: TUserBookkeepingResponse;
	expenses?: TUserShiftExpenseBookkeepingResponse[];
};

export type TUserShiftExpenseBookkeepingResponse = {
	id: number;
	value: number;
	type_id: number;
	name: string;
	description: string;
};

export type TUserShiftBookkeepingListResponse = TListResponse<TUserShiftBookkeepingResponse>;

export type TUserShiftBookkeepingList = TListResponse<TUserShiftBookkeeping>;

export function adaptUserShiftBookkeepingFromResponse(data: TUserShiftBookkeepingResponse): TUserShiftBookkeeping {
	const {
		id,
		group_id: groupId,
		date,
		order_name: orderName,
		project_name: projectName,
		rate_type_name: rateTypeName = '',
		rate = 0,
		is_salary_day_not_count: isSalaryDayNotCount,
		overwork = 0,
		deadline_penalty: deadlinePenalty = 0,
		photo_penalty: photoPenalty = 0,
		is_checked: isChecked,
		check_description: checkDescription,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		report: reportRaw,
		user: userRaw,
		expenses: expensesRaw,
	} = data;

	const report = reportRaw ? adaptUserShiftReportFromResponse(reportRaw) : undefined;
	const expenses = adaptUserShiftExpenseBookkeepingListFromResponse(expensesRaw);
	const createdBy = adaptUserBookkeepingUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserBookkeepingUndefinedFromResponse(updatedByRaw);
	const user = adaptUserBookkeepingUndefinedFromResponse(userRaw);

	return {
		id,
		groupId,
		date,
		orderName,
		projectName,
		rateTypeName,
		rate,
		isSalaryDayNotCount,
		overwork,
		deadlinePenalty,
		photoPenalty,
		isChecked,
		checkDescription,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		report,
		user,
		expenses,
	};
}

export function adaptUserSalaryBookkeepingFromResponse(data: TUserSalaryBookkeepingResponse): TUserSalaryBookkeeping {
	const {
		value,
		date,
	} = data;

	return {
		value,
		date,
	};
}

export function adaptUserShiftExpenseBookkeepingListFromResponse(list?: TUserShiftExpenseBookkeepingResponse[]): TUserShiftExpenseBookkeeping[] {
	if (!list) {
		return [];
	}

	return list.map(item => adaptUserShiftExpenseBookkeepingFromResponse(item));
}

export function adaptUserShiftExpenseBookkeepingFromResponse(data: TUserShiftExpenseBookkeepingResponse): TUserShiftExpenseBookkeeping {
	const {
		id,
		value,
		type_id: typeId,
		name,
		description,
	} = data;

	return {
		id,
		value,
		typeId,
		name,
		description,
	};
}
