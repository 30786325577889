import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS_TYPES } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserTypeRolesPayloadUpdate, TUserRole, TUserRolesList, adaptUserRolesListFromResponse } from '../../types';


export const userTypeRolesUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_TYPES}/:id/roles`,
	(payload: TUserTypeRolesPayloadUpdate, thunkApi) => {
		const { items, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_TYPES}/${id}/roles`;
		const dataRequest = { items };

		return HttpService.put<TUserRolesList>(path, dataRequest).then(result => {
			return adaptUserRolesListFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	items: TUserRole[] | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	items: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeRolesUpdateSlice = createSlice({
	name: 'userTypeRolesUpdateSlice',
	initialState,
	reducers: {
		clearUserTypeRolesUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeRolesUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeRolesUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserRolesList>) => {
			const { items } = action.payload;

			state.items = items;
			state.isLoading = false;
		},

		[userTypeRolesUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeRolesUpdateSelector = () => useAppSelector(state => state[userTypeRolesUpdateSlice.name]);

export const { clearUserTypeRolesUpdate } = userTypeRolesUpdateSlice.actions;
