import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice, FormPassword } from 'src/components';
import { formValidatePassword, formValidatePasswordConfirm, useInputField, formHandleServerError } from 'src/toolkit';
import { recoveryEmailConfirmApi, useAppDispatch, useRecoverySelector } from 'src/store';

import styles from '../styles/RecoveryStage.module.scss';


type TProps = {
	hashcode: string;
};

export function RecoveryStageFormEmail(props: TProps) {
	const dispatch = useAppDispatch();
	const { isLoading, error } = useRecoverySelector();

	const { hashcode } = props;
	const passwordField = useInputField('password');
	const passwordConfirmField = useInputField('password_confirm');

	const [notice, setNotice] = useState('');

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, passwordField);
		}
	}, [error]);

	const submit = async () => {
		const errors = [];

		errors.push(formValidatePassword(passwordField.value));
		passwordField.setErrors(errors[errors.length - 1]);

		errors.push(formValidatePasswordConfirm(passwordField.value, passwordConfirmField.value));
		passwordConfirmField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm) {
			return;
		}

		const payload = {
			hashcode,
			password: passwordField.value,
		};

		dispatch(recoveryEmailConfirmApi(payload));
	};

	return (
		<Form className={ styles.RecoveryPageStageForm }>
			<FormPassword
				legend="Новый пароль"
				placeholder="••••••••"
				title="Введите новый пароль"
				name={ passwordField.name }
				errors={ passwordField.errors }
				value={ passwordField.value }
				onChange={ e => passwordField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<FormPassword
				legend="Подтвердите новый пароль"
				placeholder="••••••••"
				title="Повторите новый пароль"
				errors={ passwordConfirmField.errors }
				name={ passwordConfirmField.name }
				value={ passwordConfirmField.value }
				onChange={ e => passwordConfirmField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton onClick={ submit } busy={ isLoading }>Сменить пароль</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
