import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { SystemSettingTypesSortCol, TSystemSettingTypeList, TSystemSettingTypeListResponse, TSystemSettingTypeQuery, TSystemSettingTypesSortCol, adaptSystemSettingTypeFromListResponse, adaptSystemSettingTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES } from '../../backend-paths';


export const systemSettingTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES,
	(payload: TSystemSettingTypeQuery | undefined, thunkApi) => {
		const params = adaptSystemSettingTypesQueryToRequest(payload);

		return HttpService.get<TSystemSettingTypeListResponse>(BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES, { params }).then(result => {
			return adaptSystemSettingTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TSystemSettingTypeList;
	query: TSystemSettingTypeQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: SystemSettingTypesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const systemSettingTypesSlice = createSlice({
	name: 'systemSettingTypesSlice',
	initialState,
	reducers: {
		setSystemSettingTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setSystemSettingTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setSystemSettingTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setSystemSettingTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setSystemSettingTypesQuerySortCol(state, action: PayloadAction<TSystemSettingTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearSystemSettingTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[systemSettingTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[systemSettingTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingTypesSelector = () => useAppSelector(state => state[systemSettingTypesSlice.name]);

export const { clearSystemSettingTypes, setSystemSettingTypesQueryLimit, setSystemSettingTypesQueryOffset, setSystemSettingTypesQuerySort, setSystemSettingTypesQuerySortCol, setSystemSettingTypesQuerySearch } = systemSettingTypesSlice.actions;
