import styles from './ImagePreviewPreloader.module.scss';


export function ImagePreviewPreloader() {
	return (
		<span className={ styles.formImagePreloaderWrapper }>
			<div className={ styles.formImagePreloader }>
				<div className={ styles.formImagePreloaderContainer }>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</span>
	);
}
