import { FormField, FormTextarea } from 'src/components';
import { TInputField } from 'src/toolkit';


type TProps = TInputField & {
	legend?: string;
	placeholder?: string;
	title?: string;

	isLoading?: boolean;
	readOnly?: boolean;
};

export function FormTextareaField(props: TProps) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		readOnly,

		legend = '',
		placeholder = '',
		title = '',
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
		>
			<FormTextarea
				placeholder={ placeholder }
				name={ name }
				title={ title }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
				readOnly={ readOnly }
			/>
		</FormField>
	);
}
