// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeadlineBlock_text__2Fhu2 {\n  box-sizing: border-box;\n  color: rgb(200, 200, 200);\n  margin: 0.25em auto;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/headline-block/HeadlineBlock.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,yBAAA;EAEA,mBAAA;EACA,WAAA;AAAD","sourcesContent":[".text {\n\tbox-sizing: border-box;\n\tcolor: rgba(200, 200, 200, 1);\n\n\tmargin: 0.25em auto;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "HeadlineBlock_text__2Fhu2"
};
export default ___CSS_LOADER_EXPORT___;
