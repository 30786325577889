import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

import { FormInput } from 'src/components';
import { Icon } from 'src/components/icon';
import { TEquipmentOrderRelationEquipmentCreate } from 'src/store';
import { libPriceToInt, libHandlePriceInt } from 'src/toolkit';

import styles from './EquipmentListBox.module.scss';


export type TEquipmentListBoxItem = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	index: number;
	item: TEquipmentOrderRelationEquipmentCreate;
	changeAmount: (equipmentId: number, amount: number) => void;
};

export function EquipmentListBoxItem(props: TProps) {
	const {
		item,
		index,
		onClick = () => {},
		changeAmount,
		...restProps
	} = props;

	const { amount, equipment } = item;
	const { name, categories, publicId, amount: maxAmount } = equipment;

	useEffect(() => {
		onChange(amount.toString());
	}, [amount]);

	const [value, setValue] = useState(amount.toString());

	const onChange = (value: string) => {
		const formatted = libHandlePriceInt(value, { min: 1, max: maxAmount });

		setValue(formatted);

		changeAmount(item.equipment.id, libPriceToInt(formatted));
	};

	const classNames = [styles.item];

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			<div className={ styles.text }>
				<span className={ styles.index }>{ index }.</span>
				<span className={ styles.categories }>{ categories.join(' / ') } /</span>
				<span className={ styles.name }>{ name }</span>
				<span className={ styles.categories }>•</span>
				<span className={ styles.publicId }>{ publicId }</span>
			</div>

			<FormInput
				className={ styles.amount }
				type="text"
				placeholder="1"
				name="amount"
				title="Укажите количество"
				value={ value }
				onChange={ e => onChange(e.target.value) }
				autoComplete="off"
				// disabled={ isLoading }
				// readOnly={ readonly }
			/>

			<Icon className={ styles.icon } onClick={ onClick } icon="icon-cancel"/>
		</div>
	);
}
