import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProjectOrder, TProjectOrderPayload, adaptProjectOrderFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PROJECTS}/:projectId/orders/:id`,
	(payload: TProjectOrderPayload, thunkApi) => {
		const { id, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${id}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderDeleteSlice = createSlice({
	name: 'projectOrderDeleteSlice',
	initialState,
	reducers: {
		clearProjectOrderDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderDeleteSelector = () => useAppSelector(state => state[projectOrderDeleteSlice.name]);

export const { clearProjectOrderDelete } = projectOrderDeleteSlice.actions;
