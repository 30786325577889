import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const PaymentsTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TPaymentsTypesSourceSortCol = TTableSortCols<typeof PaymentsTypesSourceSortCol>;

export type TPaymentTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TPaymentsTypesSourceSortCol;
};

export type TPaymentsTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptPaymentsTypesSourceQueryToRequest(data?: TPaymentTypeSourceQuery): TPaymentsTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TPaymentTypeSource = {
  id: number;
  name: string;
  description: string;
};

export type TPaymentTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TPaymentTypeSourceListResponse = TListResponse<TPaymentTypeSourceResponse>;

export type TPaymentTypeSourceList = TListResponse<TPaymentTypeSource>;

export function adaptPaymentTypeSourceFromListResponse(data: TPaymentTypeSourceListResponse): TPaymentTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPaymentTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPaymentTypeSourceFromResponse(data: TPaymentTypeSourceResponse): TPaymentTypeSource {
	const {
		id = 0,
		name = '—',
		description = '',
	} = data || {};

	return {
		id,
		name,
		description,
	};
}
