import { useEffect, useState } from 'react';

import { FormButtonLink, FormField, FormRow, FormSelect, Icon } from 'src/components';
import { TInputField } from 'src/toolkit';

import styles from './FormSelectField.module.scss';


type TProps = TInputField & {
	legend: string;
	title: string;

	isLoading?: boolean;

	items: TOptionItem[];
	width100: boolean;

	disabled?: boolean;
	icon?: string;
	to?: string;
};

export type TOptionItem = {
	text: string;
	value: string;
};

export function FormSelectField(props: TProps) {
	const [options, setOptions] = useState<TOptionItem[]>([]);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		items,
		legend,
		title,
		width100,
		disabled,
		icon,
		to,
	} = props;

	useEffect(() => {
		setOptions(items);
	}, [items]);

	const classNames = [styles.selectBox, styles.box];

	if (width100) {
		classNames.push(styles.width100);
	}

	if (icon && to) {
		return (
			<FormField
				legend={ legend }
				errors={ errors }
				required
				className={ styles.box }
			>
				<FormRow>
					<FormSelect
						title={ title }
						classNames={ classNames }
						items={ options }
						onChange={ e => setValue(e.target.value) }
						value={ value }
						name={ name }
						disabled={ isLoading }
					/>

					<FormButtonLink
						mini
						type="button"
						disabled={ disabled }
						tabIndex={ -1 }
						to={ to }
					>
						<Icon icon={ icon } />
					</FormButtonLink>
				</FormRow>
			</FormField>
		);
	}

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
			className={ styles.box }
		>
			<FormSelect
				title={ title }
				classNames={ classNames }
				items={ options }
				onChange={ e => setValue(e.target.value) }
				value={ value }
				name={ name }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
