import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserTaxation, adaptUserTaxationFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_TAXATIONS } from '../../backend-paths';


export const userTaxationDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS_TAXATIONS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_TAXATIONS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptUserTaxationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxation> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationDeleteSlice = createSlice({
	name: 'userTaxationDeleteSlice',
	initialState,
	reducers: {
		clearUserTaxationDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTaxationDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTaxationDeleteSelector = () => useAppSelector(state => state[userTaxationDeleteSlice.name]);

export const { clearUserTaxationDelete } = userTaxationDeleteSlice.actions;
