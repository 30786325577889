import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { AccountCategoryChangeSortCol, TAccountCategoryChangeList, TAccountCategoryChangeQuery, TAccountCategoryChangeListResponse, TAccountCategoryChangeSortCol, adaptAccountCategoryChangeFromListResponse, adaptAccountCategoryChangeQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_CATEGORY_CHANGES } from '../../backend-paths';


export const accountCategoryChangesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CATEGORY_CHANGES}`,
	(payload: TAccountCategoryChangeQuery, thunkApi) => {
		const params = payload ? adaptAccountCategoryChangeQueryRequest(payload) : {};

		return HttpService.get<TAccountCategoryChangeListResponse>(BACKEND_ACCOUNT_CATEGORY_CHANGES, { params }).then(result => {
			return adaptAccountCategoryChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TAccountCategoryChangeList,
	query: TAccountCategoryChangeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: AccountCategoryChangeSortCol.CREATED_AT,
	},
};

export const accountCategoryChangesSlice = createSlice({
	name: 'accountCategoryChangesSlice',
	initialState,
	reducers: {
		setAccountCategoryChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountCategoryChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountCategoryChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountCategoryChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountCategoryChangesQuerySortCol(state, action: PayloadAction<TAccountCategoryChangeSortCol>) {
			state.query.sortCol = action.payload;
		},

		setAccountCategoryChangesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountCategoryChangesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearAccountCategoryChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountCategoryChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCategoryChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCategoryChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountCategoryChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountCategoryChangeSelector = () => useAppSelector(state => state[accountCategoryChangesSlice.name]);

export const { clearAccountCategoryChanges, setAccountCategoryChangesQueryLimit, setAccountCategoryChangesQueryOffset, setAccountCategoryChangesQuerySort, setAccountCategoryChangesQuerySortCol, setAccountCategoryChangesQuerySearch, setAccountCategoryChangesQueryDateStart, setAccountCategoryChangesQueryDateEnd } = accountCategoryChangesSlice.actions;
