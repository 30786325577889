import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Icon } from 'src/components/icon';

import { TTagListBoxItem } from './TagListBox';

import styles from './TagListBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	item: TTagListBoxItem;
	onFocus: (item: TTagListBoxItem) => void;
	onBlur: () => void;
	readonly?: boolean;
};

export function TagListBoxItem(props: TProps) {
	const {
		item,
		readonly,
		onClick = () => {},
		onFocus,
		onBlur,
		...restProps
	} = props;

	const { name } = item;

	const classNames = [styles.item];

	const iconTitle = `Удалить тег «${name}»`;

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			<div className={ styles.itemRow }>
				<div
					className={ styles.name }
					title="Смотреть описание тега"
					onFocus={ () => onFocus(item) }
					onBlur={ onBlur }
					tabIndex={ -1 }
				>{ name }</div>

				{ !readonly && <Icon title={ iconTitle } className={ styles.icon } onClick={ onClick } icon="icon-cancel"/> }
			</div>
		</div>
	);
}
