import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { useAppSelector } from '../../hooks';


export type TAccountPassword = {
  password: string;
  passwordNew: string;
};

export type TAccountPasswordRequest = {
  password: string;
  password_new: string;
};

const BACKEND_ACCOUNT_PASSWORD = '/account/password';

function toRequest(data: TAccountPassword): TAccountPasswordRequest {
	const { passwordNew, ...restFields } = data;

	return {
		password_new: passwordNew,
		...restFields
	};
}

export const accountPasswordApi = createAsyncThunk(
	BACKEND_ACCOUNT_PASSWORD,
	(payload: TAccountPassword, thunkApi) => {
		const dataRequest = toRequest(payload);

		return HttpService.put(BACKEND_ACCOUNT_PASSWORD, dataRequest).then(result => {
			return;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isSuccess: boolean;
	isLoading: boolean;
	error?: HttpError;
};

const initialState: TInitialState = {
	isSuccess: false,
	isLoading: false,
	error: undefined,
};

export const accountPasswordSlice = createSlice({
	name: 'accountPasswordSlice',
	initialState,
	reducers: {
		setAccountPasswordHideSuccessAction(state) {
			state.isSuccess = false;
		},
	},

	extraReducers: {
		[accountPasswordApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
			state.isSuccess = false;
		},

		[accountPasswordApi.fulfilled.type]: (state) => {
			state.error = undefined;
			state.isLoading = false;
			state.isSuccess = true;
		},

		[accountPasswordApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
			state.isSuccess = false;
		},
	},
});

export const useAccountPasswordSelector = () => useAppSelector(state => state[accountPasswordSlice.name]);

export const { setAccountPasswordHideSuccessAction } = accountPasswordSlice.actions;
