import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserDepartment, TUserDepartmentResponse, adaptUserDepartmentFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_DEPARTMENTS } from '../../backend-paths';


export const userDepartmentReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_DEPARTMENTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_DEPARTMENTS}/${payload}`;

		return HttpService.get<TUserDepartmentResponse>(path).then(result => {
			return adaptUserDepartmentFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserDepartment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDepartmentReadSlice = createSlice({
	name: 'userDepartmentReadSlice',
	initialState,
	reducers: {
		setUserDepartmentRead(state, action: PayloadAction<TUserDepartment>) {
			state.item = action.payload;
		},

		clearUserDepartmentRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDepartmentReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDepartmentReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDepartmentReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDepartmentReadSelector = () => useAppSelector(state => state[userDepartmentReadSlice.name]);

export const { clearUserDepartmentRead, setUserDepartmentRead } = userDepartmentReadSlice.actions;
