import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountExpense, TAccountExpensePayloadUpdate, TAccountExpenseResponse, adaptAccountExpenseFromResponse, adaptAccountExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPENSES } from '../../backend-paths';


export const accountExpenseUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EXPENSES}/:id`,
	(payload: TAccountExpensePayloadUpdate, thunkApi) => {
		const { id, data, files } = payload;
		const path = `${BACKEND_ACCOUNT_EXPENSES}/${id}`;

		const dataRequest = adaptAccountExpenseToRequest(data);

		return HttpService.put<TAccountExpenseResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExpenseUpdateSlice = createSlice({
	name: 'accountExpenseUpdateSlice',
	initialState,
	reducers: {
		clearAccountExpenseUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExpenseUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExpenseUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExpenseUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExpenseUpdateSelector = () => useAppSelector(state => state[accountExpenseUpdateSlice.name]);

export const { clearAccountExpenseUpdate } = accountExpenseUpdateSlice.actions;
