import { TAsideItemOption } from '../../AsideItem';

import { AsideItemOption } from './components';

import styles from './AsideItemOptions.module.scss';


type TProps = {
	items?: TAsideItemOption[];
};

export function AsideItemOptions(props: TProps) {
	const { items = [] } = props;

	return (
		<div className={ styles.box }>
			{ items.map((item, index) => <AsideItemOption key={ index } { ...item } />) }
		</div>
	);
}
