import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const GroupShiftExpenseTypeUpdatePage = lazy(() => import('../views/update/GroupShiftExpenseTypeUpdatePage'));

export function GroupShiftExpenseTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<GroupShiftExpenseTypeUpdatePage />
		</PageSuspense>
	);
}
