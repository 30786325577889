import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProductionCalendarsDatesOffUpdatePage = lazy(() => import('../views/update/ProductionCalendarsDatesOffUpdatePage'));

export function ProductionCalendarsDatesOffUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProductionCalendarsDatesOffUpdatePage />
		</PageSuspense>
	);
}
