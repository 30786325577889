import { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from 'react';

import { ContactBubble } from './components';

import styles from './Contact.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	text: string;
	value: ReactNode;
};

export function Contact(props: TProps) {
	const [isActive, setIsActive] = useState(false);

	const {
		children = '',
		className = '',
		classNames: classNamesOuter = [],
		text = '',
		value = '',
		...restProps
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
		const elem: HTMLSpanElement = e.currentTarget;

		const value = elem.textContent;

		if (!value) {
			return;
		}

		navigator.clipboard.writeText(value);

		setIsActive(true);

		setTimeout(() => setIsActive(false), 500);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ children }
			<span className={ styles.text }>{ text }: </span>
			<span className={ styles.value } onClick={ onClick } title="Нажмите чтобы скопировать">
				{ value }
				{ isActive && <ContactBubble>Скопировано</ContactBubble> }
			</span>
		</div>
	);
}
