import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TArticle, TArticleResponse, adaptArticleFromResponse } from '../../types';
import { BACKEND_ARTICLES } from '../../backend-paths';


export const articleReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ARTICLES}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ARTICLES}/${payload}`;

		return HttpService.get<TArticleResponse>(path).then(result => {
			return adaptArticleFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TArticle> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const articleReadSlice = createSlice({
	name: 'articleReadSlice',
	initialState,
	reducers: {
		setArticleRead(state, action: PayloadAction<TArticle>) {
			state.item = action.payload;
		},

		clearArticleRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[articleReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[articleReadGetApi.fulfilled.type]: (state, action: PayloadAction<TArticle>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[articleReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useArticleReadSelector = () => useAppSelector(state => state[articleReadSlice.name]);

export const { clearArticleRead, setArticleRead } = articleReadSlice.actions;
