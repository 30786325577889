import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from './user-type.type';


export const AccountTypeChangeSortCol = {
	VALUE: 'value',
	DATE_START: 'date_start',
	DATE_END: 'date_end',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountTypeChangeSortCol = TTableSortCols<typeof AccountTypeChangeSortCol>;

export type TAccountTypeChangeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountTypeChangeSortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TAccountTypeChangeQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptAccountTypeChangeQueryRequest(data: TAccountTypeChangeQuery): TAccountTypeChangeQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TAccountTypeChangeListResponse = TListResponse<TAccountTypeChangeResponse>;

export type TAccountTypeChangeList = TListResponse<TAccountTypeChange>;

export function adaptAccountTypeChangeFromListResponse(data: TAccountTypeChangeListResponse): TAccountTypeChangeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountTypeChangeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TAccountTypeChange = {
  id: number;
  type: TUserType;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountTypeChangeResponse = {
  id: number;
  type: TUserTypeResponse;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TAccountTypeChangeBodyRequest = {
	value: string;
	dateStart: string;
	dateEnd?: string;
};

export function adaptAccountTypeChangeFromResponse(data: TAccountTypeChangeResponse): TAccountTypeChange {
	const {
		id,
		type: typeRaw,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const type = adaptUserTypeFromResponse(typeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		type,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}
