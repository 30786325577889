import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountWorkData, TAccountWorkDataResponse, adaptAccountWorkDataFromResponse } from '../../types';
import { BACKEND_ACCOUNT_WORK_DATA } from '../../backend-paths';


export const accountWorkDataReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_WORK_DATA}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountWorkDataResponse>(BACKEND_ACCOUNT_WORK_DATA).then(result => {
			return adaptAccountWorkDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountWorkData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountWorkDataReadSlice = createSlice({
	name: 'accountWorkDataReadSlice',
	initialState,
	reducers: {
		setAccountWorkDataRead(state, action: PayloadAction<TAccountWorkData>) {
			state.item = action.payload;
		},

		clearAccountWorkDataRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountWorkDataReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountWorkDataReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountWorkData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountWorkDataReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountWorkDataReadSelector = () => useAppSelector(state => state[accountWorkDataReadSlice.name]);

export const { clearAccountWorkDataRead, setAccountWorkDataRead } = accountWorkDataReadSlice.actions;
