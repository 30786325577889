import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserCategoryChange, TUserCategoryChangeParams, TUserCategoryChangeResponse, adaptUserCategoryChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCategoryChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/categories/:id`,
	(payload: TUserCategoryChangeParams, thunkApi) => {
		const { nickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/categories/${id}`;

		return HttpService.get<TUserCategoryChangeResponse>(path).then(result => {
			return adaptUserCategoryChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategoryChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryChangeReadSlice = createSlice({
	name: 'userCategoryChangeReadSlice',
	initialState,
	reducers: {
		setUserCategoryChangeRead(state, action: PayloadAction<TUserCategoryChange>) {
			state.item = action.payload;
		},

		clearUserCategoryChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategoryChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryChangeReadSelector = () => useAppSelector(state => state[userCategoryChangeReadSlice.name]);

export const { clearUserCategoryChangeRead, setUserCategoryChangeRead } = userCategoryChangeReadSlice.actions;
