import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TAccountCategory, TAccountCategoryResponse, adaptAccountCategoryFromResponse } from './account-category.type';


export const AccountCategoryChangeSortCol = {
	VALUE: 'value',
	DATE_START: 'date_start',
	DATE_END: 'date_end',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountCategoryChangeSortCol = TTableSortCols<typeof AccountCategoryChangeSortCol>;

export type TAccountCategoryChangeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountCategoryChangeSortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TAccountCategoryChangeQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptAccountCategoryChangeQueryRequest(data: TAccountCategoryChangeQuery): TAccountCategoryChangeQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TAccountCategoryChangeListResponse = TListResponse<TAccountCategoryChangeResponse>;

export type TAccountCategoryChangeList = TListResponse<TAccountCategoryChange>;

export function adaptAccountCategoryChangeFromListResponse(data: TAccountCategoryChangeListResponse): TAccountCategoryChangeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountCategoryChangeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TAccountCategoryChange = {
  id: number;
  category: TAccountCategory;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountCategoryChangeResponse = {
  id: number;
  category: TAccountCategoryResponse;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TAccountCategoryChangeBodyRequest = {
	value: string;
	dateStart: string;
	dateEnd?: string;
};

export function adaptAccountCategoryChangeFromResponse(data: TAccountCategoryChangeResponse): TAccountCategoryChange {
	const {
		id,
		category: categoryRaw,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const category = adaptAccountCategoryFromResponse(categoryRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		category,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}
