import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingAmocrmClientIdUpdatePage = lazy(() => import('../views/update-client-id/SystemSettingAmocrmClientIdUpdatePage'));

export function SystemSettingAmocrmClientIdUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingAmocrmClientIdUpdatePage />
		</PageSuspense>
	);
}
