import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TScheduleEvent, adaptScheduleEventFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SCHEDULE_EVENTS } from '../../backend-paths';


export const scheduleEventDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_SCHEDULE_EVENTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_SCHEDULE_EVENTS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptScheduleEventFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEvent> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventDeleteSlice = createSlice({
	name: 'scheduleEventDeleteSlice',
	initialState,
	reducers: {
		clearScheduleEventDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventDeleteApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEvent>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventDeleteSelector = () => useAppSelector(state => state[scheduleEventDeleteSlice.name]);

export const { clearScheduleEventDelete } = scheduleEventDeleteSlice.actions;
