import { Icon } from 'src/components/icon';

import styles from './FormError.module.scss';


type TProps = {
	className?: string;
	items?: string[];
};

export function FormError(props: TProps) {
	const { className = '', items = [] } = props;
	const classNames = [styles.formError];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	return (
		<small className={ classNames.join(' ') }>
			<Icon icon="icon-warning" />
			{ items.join('. ') }
		</small>
	);
}
