import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingAmocrmClientSecretUpdatePage = lazy(() => import('../views/update-client-secret/SystemSettingAmocrmClientSecretUpdatePage'));

export function SystemSettingAmocrmClientSecretUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingAmocrmClientSecretUpdatePage />
		</PageSuspense>
	);
}
