import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersFinancialChangesSortCol, TUserFinancialChangeList, TUserFinancialChangeListResponse, TUserFinancialChangeQuery, TUsersFinancialChangesSortCol, adaptUserFinancialChangeFromListResponse, adaptUsersFinancialChangesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_USERS_FINANCIAL_CHANGES } from '../../backend-paths';


export const usersFinancialChangesGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_FINANCIAL_CHANGES,
	(payload: TUserFinancialChangeQuery | undefined, thunkApi) => {
		const params = adaptUsersFinancialChangesQueryToRequest(payload);

		return HttpService.get<TUserFinancialChangeListResponse>(BACKEND_ADMIN_USERS_FINANCIAL_CHANGES, { params }).then(result => {
			return adaptUserFinancialChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserFinancialChangeList;
	query: TUserFinancialChangeQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersFinancialChangesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersFinancialChangesSlice = createSlice({
	name: 'usersFinancialChangesSlice',
	initialState,
	reducers: {
		setUsersFinancialChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersFinancialChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersFinancialChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersFinancialChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersFinancialChangesQuerySortCol(state, action: PayloadAction<TUsersFinancialChangesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersFinancialChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersFinancialChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersFinancialChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserFinancialChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersFinancialChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersFinancialChangesSelector = () => useAppSelector(state => state[usersFinancialChangesSlice.name]);

export const { clearUsersFinancialChanges, setUsersFinancialChangesQueryLimit, setUsersFinancialChangesQueryOffset, setUsersFinancialChangesQuerySort, setUsersFinancialChangesQuerySortCol, setUsersFinancialChangesQuerySearch } = usersFinancialChangesSlice.actions;
