import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TUserExpenseTypeSource, TUserExpenseTypeSourceResponse, adaptUserExpenseTypeSourceFromResponse } from './user-expense-type-source.type';
import { TPaymentTypeSource, TPaymentTypeSourceResponse, adaptPaymentTypeSourceFromResponse } from './payment-type-source.type';
import { TProjectOrderSource, TProjectOrderSourceResponse, adaptProjectOrderSourceFromResponse } from './project-order-source.type';


export const UsersExpensesSortCol = {
	USER_NAME: 'user_name',
	USER_EXPENSE_TYPE_ID: 'expense_type_id',
	PAYMENT_TYPE_ID: 'payment_type_id',
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersExpensesSortCol = TTableSortCols<typeof UsersExpensesSortCol>;

export type TUsersExpensesQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersExpensesSortCol;
};

export type TUsersExpensesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUsersExpensesQueryPayload = {
	data?: TUsersExpensesQuery;
	// userId?: number;
};

export function adaptUsersExpensesQueryToRequest(data?: TUsersExpensesQuery): TUsersExpensesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserExpense = {
	id: number;
	date: string;
	value: number;
	description?: string;
	user: TUserMeta;
	projectOrder?: TProjectOrderSource;
	paymentType?: TPaymentTypeSource;
	userExpenseType: TUserExpenseTypeSource;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	files?: TUserExpenseFile[];
};

export type TUserExpenseResponse = {
	id: number;
	date: string;
	value: number;
	description?: string;
	user: TUserMetaResponse;
	project_order?: TProjectOrderSourceResponse;
	payment_type?: TPaymentTypeSourceResponse;
	user_expense_type: TUserExpenseTypeSourceResponse;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;

	files?: TUserExpenseFile[];
};

export type TUserExpenseListResponse = TListResponse<TUserExpenseResponse>;

export type TUserExpenseList = TListResponse<TUserExpense>;

export type TUserExpenseBodyRequest = {
	projectOrderId?: number;
	userNickname: string;
	value: number;
	date: string;
	paymentTypeId: number;
	description?: string;
	userExpenseTypeId: number;
};

export type TUserExpenseRequest = {
	project_order_id?: number;
	user_nickname: string;
	date: string;
	value: number;
	description?: string;
	payment_type_id: number;
	user_expense_type_id: number;
};

export type TUserExpenseFile = {
	name: string;
	path: string;
};

export type TUserExpenseFileRequest = Array<File | string>;

export type TUserExpensePayloadCreate = {
	data: TUserExpenseBodyRequest;
	files: TUserExpenseFileRequest;
};

export type TUserExpensePayloadUpdate = {
	id: number;
	data: TUserExpenseBodyRequest;
	files: TUserExpenseFileRequest;
};

export function adaptUserExpenseFromListResponse(data: TUserExpenseListResponse): TUserExpenseList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserExpenseFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserExpenseFromResponse(data: TUserExpenseResponse): TUserExpense {
	const {
		id,
		date,
		value: value,
		description: descriptionRaw,
		project_order: projectOrderRaw,
		user_expense_type: userExpenseTypeRaw,
		payment_type: paymentTypeRaw,
		user: userRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		files = [],
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const paymentType = paymentTypeRaw ? adaptPaymentTypeSourceFromResponse(paymentTypeRaw) : undefined;
	const user = adaptUserMetaFromResponse(userRaw);
	const userExpenseType = adaptUserExpenseTypeSourceFromResponse(userExpenseTypeRaw);
	const projectOrder = projectOrderRaw ? adaptProjectOrderSourceFromResponse(projectOrderRaw) : undefined;
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		date,
		value,
		description,
		projectOrder,
		user,
		paymentType,
		userExpenseType,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		files,
	};
}

export function adaptUserExpenseToRequest(data: TUserExpenseBodyRequest): TUserExpenseRequest {
	const {
		projectOrderId = undefined,
		userNickname,
		date,
		value,
		description,
		userExpenseTypeId,
		paymentTypeId,
	} = data;

	return {
		project_order_id: projectOrderId,
		user_nickname: userNickname,
		date,
		value,
		description,
		user_expense_type_id: userExpenseTypeId,
		payment_type_id: paymentTypeId,
	};
}
