import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const GroupShiftReportTagTypeUpdatePage = lazy(() => import('../views/update/GroupShiftReportTagTypeUpdatePage'));

export function GroupShiftReportTagTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<GroupShiftReportTagTypeUpdatePage />
		</PageSuspense>
	);
}
