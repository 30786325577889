import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingBookkeepingWorkDayHoursCount, TSystemSettingBookkeepingWorkDayHoursCountResponse, adaptSystemSettingBookkeepingWorkDayHoursCountFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT } from '../../backend-paths';


export const systemSettingBookkeepingWorkDayHoursCountReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT;

		return HttpService.get<TSystemSettingBookkeepingWorkDayHoursCountResponse>(path).then(result => {
			return adaptSystemSettingBookkeepingWorkDayHoursCountFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingBookkeepingWorkDayHoursCount> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingBookkeepingWorkDayHoursCountReadSlice = createSlice({
	name: 'systemSettingBookkeepingWorkDayHoursCountReadSlice',
	initialState,
	reducers: {
		setSystemSettingBookkeepingWorkDayHoursCountRead(state, action: PayloadAction<TSystemSettingBookkeepingWorkDayHoursCount>) {
			state.item = action.payload;
		},

		clearSystemSettingBookkeepingWorkDayHoursCountRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingBookkeepingWorkDayHoursCountReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingBookkeepingWorkDayHoursCountReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingBookkeepingWorkDayHoursCount>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingBookkeepingWorkDayHoursCountReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingBookkeepingWorkDayHoursCountReadSelector = () => useAppSelector(state => state[systemSettingBookkeepingWorkDayHoursCountReadSlice.name]);

export const { clearSystemSettingBookkeepingWorkDayHoursCountRead, setSystemSettingBookkeepingWorkDayHoursCountRead } = systemSettingBookkeepingWorkDayHoursCountReadSlice.actions;
