import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const UsersTaxationsSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersTaxationsSortCol = TTableSortCols<typeof UsersTaxationsSortCol>;

export type TUserTaxationQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersTaxationsSortCol;
};

export type TUsersTaxationsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUserTaxation = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;
};

export type TUserTaxationResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
};

export type TUserTaxationListResponse = TListResponse<TUserTaxationResponse>;

export type TUserTaxationList = TListResponse<TUserTaxation>;

export type TUserTaxationBodyRequest = {
	name: string;
	description?: string;
};

export type TUserTaxationRequest = {
	name: string;
	description?: string;
};

export type TUserTaxationUpdate = {
	id: number;
	data: TUserTaxationBodyRequest;
};

export function adaptUserTaxationFromListResponse(data: TUserTaxationListResponse): TUserTaxationList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserTaxationFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserTaxationFromResponse(data: TUserTaxationResponse): TUserTaxation {
	const {
		id,
		name,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data || {};

	return {
		id,
		name,
		description,
		createdAt,
		updatedAt,
	};
}

export function adaptUserTaxationToRequest(data: TUserTaxationBodyRequest): TUserTaxationRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
