import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingCalendarEventColorProject = TSystemSettingValueCommon & {
  value?: number;
};

export type TSystemSettingCalendarEventColorProjectResponse = TSystemSettingValueCommonResponse & {
  value?: number;
};

export type TSystemSettingCalendarEventColorProjectPayload = {
	value?: number;
};

export type TSystemSettingCalendarEventColorProjectRequest = {
	value?: number;
};

export function adaptSystemSettingCalendarEventColorProjectFromResponse(data: TSystemSettingCalendarEventColorProjectResponse): TSystemSettingCalendarEventColorProject {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingCalendarEventColorProjectToRequest(data: TSystemSettingCalendarEventColorProjectPayload): TSystemSettingCalendarEventColorProjectRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
