import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TProjectOrderSourceList, TProjectOrderSourceListResponse, TProjectOrderSourceQuery, TProjectOrderSourceQueryPayload, adaptProjectOrderSourceFromListResponse, adaptProjectsOrdersSourceQueryToRequest } from '../../types';
import { BACKEND_SOURCE_PROJECTS } from '../../backend-paths';


export const sourceProjectOrderGetApi = createAsyncThunk(
	`${BACKEND_SOURCE_PROJECTS}/:project_id/orders`,
	(payload: TProjectOrderSourceQueryPayload, thunkApi) => {
		const { projectId, data } = payload;
		const params = adaptProjectsOrdersSourceQueryToRequest(data);
		const path = `${BACKEND_SOURCE_PROJECTS}/${projectId}/orders`;

		return HttpService.get<TProjectOrderSourceListResponse>(path, { params }).then(result => {
			return adaptProjectOrderSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TProjectOrderSourceList;
	query: TProjectOrderSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceProjectOrderSlice = createSlice({
	name: 'sourceProjectOrderSlice',
	initialState,
	reducers: {
		setSourceProjectOrderQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceProjectOrder(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceProjectOrderGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceProjectOrderGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceProjectOrderGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceProjectOrderSelector = () => useAppSelector(state => state[sourceProjectOrderSlice.name]);

export const { clearSourceProjectOrder, setSourceProjectOrderQuerySearch } = sourceProjectOrderSlice.actions;
