import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_TYPES } from '../../backend-paths';


export const userTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_TYPES}/${payload}`;

		return HttpService.get<TUserTypeResponse>(path).then(result => {
			return adaptUserTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeReadSlice = createSlice({
	name: 'userTypeReadSlice',
	initialState,
	reducers: {
		setUserTypeRead(state, action: PayloadAction<TUserType>) {
			state.item = action.payload;
		},

		clearUserTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeReadSelector = () => useAppSelector(state => state[userTypeReadSlice.name]);

export const { clearUserTypeRead, setUserTypeRead } = userTypeReadSlice.actions;
