import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountCredentialResponse, TAccountCredentials, TSignIn, adaptAccountCredentialsFromResponse } from 'src/store/types';
import { BACKEND_SIGN_IN } from 'src/store/backend-paths';

import { useAppSelector } from '../../hooks';


export const signInApi = createAsyncThunk(
	BACKEND_SIGN_IN,
	(payload: TSignIn, thunkApi) => {
		return HttpService.post<TAccountCredentialResponse>(BACKEND_SIGN_IN, payload).then(result => {
			return adaptAccountCredentialsFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	credentials?: TAccountCredentials;
	isLoading: boolean;
	error?: HttpError,
};

const initialState: TInitialState = {
	credentials: undefined,
	isLoading: false,
	error: undefined,
};

export const signInSlice = createSlice({
	name: 'signInSlice',
	initialState,
	reducers: {
		clearSignIn(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[signInApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[signInApi.fulfilled.type]: (state, action: PayloadAction<TAccountCredentials>) => {
			state.error = undefined;
			state.isLoading = false;
			state.credentials = { ...action.payload };
		},

		[signInApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = { ...action.payload };
			state.isLoading = false;
		},
	},
});

export const useSignInSelector = () => useAppSelector(state => state[signInSlice.name]);

export const { clearSignIn } = signInSlice.actions;
