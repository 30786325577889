import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectsOrdersGroupsShiftsPage = lazy(() => import('../views/list/ProjectsOrdersGroupsShiftsPage'));

export function ProjectsOrdersGroupsShiftsPageLazy() {
	return (
		<PageSuspense>
			<ProjectsOrdersGroupsShiftsPage />
		</PageSuspense>
	);
}
