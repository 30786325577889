export function libCalculateAge(birthday: Date, dateStart?: Date): number {
	const ms = dateStart ? dateStart.getTime() : Date.now();
	const ageDifMs = ms - birthday.getTime();
	const ageDate = new Date(ageDifMs);

	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function libFirstDate(y: number, m: number): Date {
	return new Date(Date.UTC(y, m, 1));
}

export function libLastDate(y: number, m: number): Date {
	return new Date(Date.UTC(y, m + 1, 0));
}

export function libGetWeekNumber(value: Date): number {
	const date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
	date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

	const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

	const time = date.getTime() - yearStart.getTime();
	const days = time / 86400000;
	const weeks = (days + 1) / 7;

	return Math.ceil(weeks);
}

type TLibDatesDifferenceMS = Date | string;

export function libDatesDifferenceMS(dateStartRaw: TLibDatesDifferenceMS, dateEndRaw: TLibDatesDifferenceMS): number {
	const dateStart = typeof dateStartRaw === 'string' ? new Date(dateStartRaw) : dateStartRaw;
	const dateEnd = typeof dateEndRaw === 'string' ? new Date(dateEndRaw) : dateEndRaw;

	return dateEnd.getTime() - dateStart.getTime();
}

export function libDatesDifferenceDate(dateStart: TLibDatesDifferenceMS, dateEnd: TLibDatesDifferenceMS): Date {
	const ms = libDatesDifferenceMS(dateStart, dateEnd);

	return new Date(ms);
}
