export type TUserOverworkBookkeeping = {
	value: number;
	date: string;
};

export type TUserOverworkBookkeepingResponse = TUserOverworkBookkeeping;

export function adaptUserOverworkBookkeepingListFromResponse(list?: TUserOverworkBookkeepingResponse[]): TUserOverworkBookkeeping[] {
	if (!list) {
		return [];
	}

	return list.map(item => adaptUserOverworkBookkeepingFromResponse(item));
}

export function adaptUserOverworkBookkeepingFromResponse(data: TUserOverworkBookkeepingResponse): TUserOverworkBookkeeping {
	const {
		value,
		date,
	} = data;

	return {
		value,
		date,
	};
}
