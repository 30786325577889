import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersPositionsPage = lazy(() => import('../views/list/UsersPositionsPage'));

export function UsersPositionsPageLazy() {
	return (
		<PageSuspense>
			<UsersPositionsPage />
		</PageSuspense>
	);
}
