// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignInForm_signInForm__f-UGO {\n  background-color: rgb(10, 10, 10);\n  border: 1px solid rgb(60, 60, 60);\n  box-sizing: border-box;\n  margin: auto;\n  padding: 1em;\n}\n\n.SignInForm_recoveryText__-pRoV {\n  text-align: right;\n  font-size: 0.875em;\n  margin: 1em 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/auth/views/sign-in/components/form/SignInForm.module.scss"],"names":[],"mappings":"AAAA;EACC,iCAAA;EACA,iCAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;AACD;;AAEA;EACC,iBAAA;EACA,kBAAA;EACA,aAAA;AACD","sourcesContent":[".signInForm {\n\tbackground-color: rgba(10, 10, 10, 1);\n\tborder: 1px solid rgba(60, 60, 60, 1);\n\tbox-sizing: border-box;\n\tmargin: auto;\n\tpadding: 1em;\n}\n\n.recoveryText {\n\ttext-align: right;\n\tfont-size: 0.875em;\n\tmargin: 1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signInForm": "SignInForm_signInForm__f-UGO",
	"recoveryText": "SignInForm_recoveryText__-pRoV"
};
export default ___CSS_LOADER_EXPORT___;
