import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserRole, TUserRolesList, adaptUserRolesListFromResponse } from '../../types';


export const userRolesReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/roles`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/roles`;

		return HttpService.get<TUserRolesList>(path).then(result => {
			return adaptUserRolesListFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	items: TUserRole[] | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	items: undefined,
	isLoading: false,
	error: undefined,
};

export const userRolesReadSlice = createSlice({
	name: 'userRolesReadSlice',
	initialState,
	reducers: {
		setUserRolesRead(state, action: PayloadAction<TUserRolesList>) {
			const { items } = action.payload;

			state.items = items;
		},

		clearUserRolesRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userRolesReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userRolesReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserRolesList>) => {
			const { items } = action.payload;

			state.items = items;
			state.isLoading = false;
		},

		[userRolesReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserRolesReadSelector = () => useAppSelector(state => state[userRolesReadSlice.name]);

export const { clearUserRolesRead, setUserRolesRead } = userRolesReadSlice.actions;
