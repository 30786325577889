import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountSalary, TAccountSalaryResponse, adaptAccountSalaryFromResponse } from '../../types';
import { BACKEND_ACCOUNT_SALARY_HISTORY } from '../../backend-paths';


export const accountSalaryHistoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SALARY_HISTORY}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SALARY_HISTORY}/${payload}`;

		return HttpService.get<TAccountSalaryResponse>(path).then(result => {
			return adaptAccountSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountSalary> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountSalaryHistoryReadSlice = createSlice({
	name: 'accountSalaryHistoryReadSlice',
	initialState,
	reducers: {
		setAccountSalaryHistoryRead(state, action: PayloadAction<TAccountSalary>) {
			state.item = action.payload;
		},

		clearAccountSalaryHistoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountSalaryHistoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSalaryHistoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountSalaryHistoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountSalaryHistoryReadSelector = () => useAppSelector(state => state[accountSalaryHistoryReadSlice.name]);

export const { clearAccountSalaryHistoryRead, setAccountSalaryHistoryRead } = accountSalaryHistoryReadSlice.actions;
