import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TArticle, TArticleUpdate, TArticleResponse, adaptArticleFromResponse, adaptArticleToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ARTICLES } from '../../backend-paths';


export const articleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ARTICLES}/:alias`,
	(payload: TArticleUpdate, thunkApi) => {
		const { data, alias } = payload;
		const path = `${BACKEND_ARTICLES}/${alias}`;

		const dataRequest = adaptArticleToRequest(data);

		return HttpService.put<TArticleResponse>(path, dataRequest).then(result => {
			return adaptArticleFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TArticle> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const articleUpdateSlice = createSlice({
	name: 'articleUpdateSlice',
	initialState,
	reducers: {
		clearArticleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[articleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[articleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TArticle>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[articleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useArticleUpdateSelector = () => useAppSelector(state => state[articleUpdateSlice.name]);

export const { clearArticleUpdate } = articleUpdateSlice.actions;
