import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountDataPage = lazy(() => import('../views/update/AccountDataPage'));

export function AccountDataPageLazy() {
	return (
		<PageSuspense>
			<AccountDataPage />
		</PageSuspense>
	);
}
