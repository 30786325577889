import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const GroupsShiftsReportsTagsTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TGroupsShiftsReportsTagsTypesSourceSortCol = TTableSortCols<typeof GroupsShiftsReportsTagsTypesSourceSortCol>;

export type TGroupShiftReportTagTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TGroupsShiftsReportsTagsTypesSourceSortCol;
};

export type TGroupsShiftsReportsTagsTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptGroupsShiftsReportsTagsTypesSourceQueryToRequest(data?: TGroupShiftReportTagTypeSourceQuery): TGroupsShiftsReportsTagsTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TGroupShiftReportTagTypeSource = {
  id: number;
  name: string;
  description?: string;
};

export type TGroupShiftReportTagTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TGroupShiftReportTagTypeSourceListResponse = TListResponse<TGroupShiftReportTagTypeSourceResponse>;

export type TGroupShiftReportTagTypeSourceList = TListResponse<TGroupShiftReportTagTypeSource>;

export function adaptGroupShiftReportTagTypeSourceFromListResponse(data: TGroupShiftReportTagTypeSourceListResponse): TGroupShiftReportTagTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptGroupShiftReportTagTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptGroupShiftReportTagTypeSourceFromResponse(data: TGroupShiftReportTagTypeSourceResponse): TGroupShiftReportTagTypeSource {
	const {
		id,
		name,
		description,
	} = data;

	return {
		id,
		name,
		description,
	};
}
