import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Icon } from 'src/components/icon';

import styles from './ImageModal.module.scss';


type TProps = {
	src: string;
	alt: string;
	hide: () => void;
	parent?: Element;
};

export function ImageModal(props: TProps) {
	const { src, alt, hide, parent: parentElement } = props;

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'visible';
		};
	}, []);

	const elem = (
		<div className={ styles.veil }>
			<div className={ styles.container }>
				<img className={ styles.image } src={ src } alt={ alt } />
			</div>

			<div
				onClick={ hide }
				className={ styles.modalClose }
			>
				<Icon icon="icon-cancel"></Icon>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
