// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShiftMark_box__zIuEK {\n  background-color: rgb(200, 200, 200);\n  border: 1px solid rgb(0, 0, 0);\n  border-radius: 25%;\n  box-sizing: border-box;\n  display: inline-block;\n  margin: auto 0.25em;\n  height: 1em;\n  width: 1em;\n  vertical-align: middle;\n}\n\n.ShiftMark_none__jYPM0 {\n  background-color: rgb(200, 50, 50);\n}\n\n.ShiftMark_created__LDJiO {\n  background-color: rgb(200, 200, 200);\n}\n\n.ShiftMark_checked__3bRwe {\n  background-color: rgb(0, 200, 0);\n}", "",{"version":3,"sources":["webpack://./src/pages/components/shift-mark/ShiftMark.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EAEA,8BAAA;EACA,kBAAA;EACA,sBAAA;EAEA,qBAAA;EACA,mBAAA;EAEA,WAAA;EACA,UAAA;EAEA,sBAAA;AAHD;;AAMA;EACC,kCAAA;AAHD;;AAMA;EACC,oCAAA;AAHD;;AAMA;EACC,gCAAA;AAHD","sourcesContent":[".box {\n\tbackground-color: rgba(200, 200, 200, 1);\n\t// border: 1px solid rgba(160, 100, 0, 1);\n\tborder: 1px solid rgba(0, 0, 0, 1);\n\tborder-radius: 25%;\n\tbox-sizing: border-box;\n\n\tdisplay: inline-block;\n\tmargin: auto 0.25em;\n\n\theight: 1em;\n\twidth: 1em;\n\n\tvertical-align: middle;\n}\n\n.none {\n\tbackground-color: rgba(200, 50, 50, 1);\n}\n\n.created {\n\tbackground-color: rgba(200, 200, 200, 1);\n}\n\n.checked {\n\tbackground-color: rgba(0, 200, 0, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "ShiftMark_box__zIuEK",
	"none": "ShiftMark_none__jYPM0",
	"created": "ShiftMark_created__LDJiO",
	"checked": "ShiftMark_checked__3bRwe"
};
export default ___CSS_LOADER_EXPORT___;
