import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectsClientsContactsPage = lazy(() => import('../views/list/ProjectsClientsContactsPage'));

export function ProjectsClientsContactsPageLazy() {
	return (
		<PageSuspense>
			<ProjectsClientsContactsPage />
		</PageSuspense>
	);
}
