import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ArticleContentUpdatePage = lazy(() => import('../views/update/ArticleContentUpdatePage'));

export function ArticleContentUpdatePageLazy() {
	return (
		<PageSuspense>
			<ArticleContentUpdatePage />
		</PageSuspense>
	);
}
