import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from './user-type.type';
import { TUserPosition, TUserPositionResponse, adaptUserPositionFromResponse } from './user-position.type';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from './user-category.type';
import { adaptUserDepartmentFromResponse, TUserDepartment, TUserDepartmentResponse } from './user-department.type';


export type TAccountCard = {
	type?: TUserType;
	position?: TUserPosition;
	category?: TUserCategory;
	department?: TUserDepartment;
	salary?: number;
};

export type TAccountCardResponse = {
	type?: TUserTypeResponse;
	position?: TUserPositionResponse;
	category?: TUserCategoryResponse;
	department?: TUserDepartmentResponse;
	salary?: number;
};

export function adaptAccountCardFromResponse(data: TAccountCardResponse): TAccountCard {
	const {
		type: typeRaw,
		position: positionRaw,
		category: categoryRaw,
		department: departmentRaw,
		salary,
	} = data;

	const type = typeRaw ? adaptUserTypeFromResponse(typeRaw) : undefined;
	const position = positionRaw ? adaptUserPositionFromResponse(positionRaw) : undefined;
	const category = categoryRaw ? adaptUserCategoryFromResponse(categoryRaw) : undefined;
	const department = departmentRaw ? adaptUserDepartmentFromResponse(departmentRaw) : undefined;

	return {
		type,
		position,
		category,
		department,
		salary,
	};
}
