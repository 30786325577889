import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TFinancialChangeType, TFinancialChangeTypeUpdate, TFinancialChangeTypeResponse, adaptFinancialChangeTypeFromResponse, adaptFinancialChangeTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES } from '../../backend-paths';


export const financialChangeTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/:id`,
	(payload: TFinancialChangeTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/${id}`;
		const dataRequest = adaptFinancialChangeTypeToRequest(data);

		return HttpService.put<TFinancialChangeTypeResponse>(path, dataRequest).then(result => {
			return adaptFinancialChangeTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TFinancialChangeType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const financialChangeTypeUpdateSlice = createSlice({
	name: 'financialChangeTypeUpdateSlice',
	initialState,
	reducers: {
		clearFinancialChangeTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[financialChangeTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[financialChangeTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TFinancialChangeType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[financialChangeTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useFinancialChangeTypeUpdateSelector = () => useAppSelector(state => state[financialChangeTypeUpdateSlice.name]);

export const { clearFinancialChangeTypeUpdate } = financialChangeTypeUpdateSlice.actions;
