import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TEquipment, adaptEquipmentFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS } from '../../backend-paths';


export const equipmentDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_EQUIPMENTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptEquipmentFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentDeleteSlice = createSlice({
	name: 'equipmentDeleteSlice',
	initialState,
	reducers: {
		clearEquipmentDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentDeleteApi.fulfilled.type]: (state, action: PayloadAction<TEquipment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentDeleteSelector = () => useAppSelector(state => state[equipmentDeleteSlice.name]);

export const { clearEquipmentDelete } = equipmentDeleteSlice.actions;
