import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectOrderUpdatePage = lazy(() => import('../views/update/ProjectOrderUpdatePage'));

export function ProjectOrderUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectOrderUpdatePage />
		</PageSuspense>
	);
}
