// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconInfo_icon__iYqZU {\n  color: rgb(90, 90, 90);\n  cursor: pointer;\n  display: inline-block;\n  font-size: 1.25em;\n  margin: 0.15em 0.25em 0 0.25em !important;\n  transition: color 0.2s ease-in-out;\n}\n.IconInfo_icon__iYqZU:hover {\n  color: rgb(255, 200, 30);\n}\n.IconInfo_icon__iYqZU:active {\n  color: rgb(255, 200, 30);\n}\n.IconInfo_icon__iYqZU:focus {\n  color: rgb(255, 200, 30) !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/icon-info/IconInfo.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;EACA,yCAAA;EAEA,kCAAA;AAAD;AAEC;EACC,wBAAA;AAAF;AAGC;EACC,wBAAA;AADF;AAIC;EACC,mCAAA;AAFF","sourcesContent":[".icon {\n\tcolor: rgba(90, 90, 90, 1);\n\tcursor: pointer;\n\tdisplay: inline-block;\n\tfont-size: 1.25em;\n\tmargin: 0.15em 0.25em 0 0.25em !important;\n\n\ttransition: color 0.2s ease-in-out;\n\n\t&:hover {\n\t\tcolor: rgba(255, 200, 30, 1);\n\t}\n\n\t&:active {\n\t\tcolor: rgba(255, 200, 30, 1);\n\t}\n\n\t&:focus {\n\t\tcolor: rgba(255, 200, 30, 1) !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "IconInfo_icon__iYqZU"
};
export default ___CSS_LOADER_EXPORT___;
