import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserTaxationDataPage = lazy(() => import('../views/update/UserTaxationDataPage'));

export function UserTaxationDataPageLazy() {
	return (
		<PageSuspense>
			<UserTaxationDataPage />
		</PageSuspense>
	);
}
