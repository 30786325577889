import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsHistoryPage = lazy(() => import('../views/list/EquipmentsHistoryPage'));

export function EquipmentsHistoryPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsHistoryPage />
		</PageSuspense>
	);
}
