import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserPositionChangeUpdatePage = lazy(() => import('../views/update/UserPositionChangeUpdatePage'));

export function UserPositionChangeUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserPositionChangeUpdatePage />
		</PageSuspense>
	);
}
