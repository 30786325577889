import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingShiftsIsNoticeUsersByShiftChangesUpdatePage = lazy(() => import('../views/update-is-notice-users-by-shift-changes/SystemSettingShiftsIsNoticeUsersByShiftChangesUpdatePage'));

export function SystemSettingShiftsIsNoticeUsersByShiftChangesUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingShiftsIsNoticeUsersByShiftChangesUpdatePage />
		</PageSuspense>
	);
}
