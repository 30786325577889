import { PATH_STATIC_USER_IMAGES } from 'src/routes';

import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from './user-type.type';
import { TUserPosition, TUserPositionResponse, adaptUserPositionFromResponse } from './user-position.type';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from './user-category.type';
import { adaptUserDepartmentFromResponse, TUserDepartment, TUserDepartmentResponse } from './user-department.type';


export type TUserCard = {
  nickname: string;
  isActive?: boolean;
  image: string;
	createdAt: string;
  updatedAt: string;

	phoneNumber?: string;
	email?: string;
	type?: TUserType;
	position?: TUserPosition;
	category?: TUserCategory;
	department?: TUserDepartment;
	salary?: number;
};

export type TUserCardResponse = {
  nickname: string;
  is_active: boolean;
  image?: string;
  created_at: string;
  updated_at: string;

	phone_number?: string;
	email?: string;
	type?: TUserTypeResponse;
	position?: TUserPositionResponse;
	category?: TUserCategoryResponse;
	department?: TUserDepartmentResponse;
	salary?: number;
};

export function adaptUserCardFromResponse(data: TUserCardResponse): TUserCard {
	const {
		nickname,
		is_active: isActive,
		created_at: createdAt,
		updated_at: updatedAt,
		image: imageRaw,
		phone_number: phoneNumber,
		email,
		type: typeRaw,
		position: positionRaw,
		category: categoryRaw,
		department: departmentRaw,
		salary,
	} = data;

	const image = imageRaw ? `${PATH_STATIC_USER_IMAGES}/${imageRaw}` : PATH_STATIC_USER_IMAGES;
	const type = typeRaw ? adaptUserTypeFromResponse(typeRaw) : undefined;
	const position = positionRaw ? adaptUserPositionFromResponse(positionRaw) : undefined;
	const category = categoryRaw ? adaptUserCategoryFromResponse(categoryRaw) : undefined;
	const department = departmentRaw ? adaptUserDepartmentFromResponse(departmentRaw) : undefined;

	return {
		nickname,
		isActive,
		image,
		phoneNumber,
		email,
		type,
		position,
		category,
		department,
		salary,
		createdAt,
		updatedAt,
	};
}
