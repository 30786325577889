import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService, libFirstDate, libFormatDate, libLastDate } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountBookkeepingResponse, TAccountBookkeepingQuery, adaptAccountBookkeepingFromResponse, TUserShiftBookkeepingStatus, adaptAccountBookkeepingQueryToRequest, TAccountBookkeeping, TAccountBookkeepingQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_BOOKKEEPING } from '../../backend-paths';


function defaultStartDate() {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	const firstDate = libFirstDate(date.getFullYear(), date.getMonth());

	return libFormatDate(firstDate, 'yyyy-MM-dd');
}

function defaultEndDate() {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	const lastDate = libLastDate(date.getFullYear(), date.getMonth());

	return libFormatDate(lastDate, 'yyyy-MM-dd');
}

export const accountBookkeepingGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_BOOKKEEPING,
	(payload: TAccountBookkeepingQuery, thunkApi) => {
		const params = payload ? adaptAccountBookkeepingQueryToRequest(payload) : {};

		return HttpService.get<TAccountBookkeepingResponse>(BACKEND_ACCOUNT_BOOKKEEPING, { params }).then(result => {
			return adaptAccountBookkeepingFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TUserBookkeepingQueryRequestKeys = keyof TAccountBookkeepingQueryRequest;
type TUserBookkeepingQueryKeys = keyof TAccountBookkeepingQuery;

type TUserBookkeepingQueryDictionaryKeys = [TUserBookkeepingQueryRequestKeys, TUserBookkeepingQueryKeys];

export type TAccountBookkeepingQueryDictionary = TUserBookkeepingQueryDictionaryKeys[];
export type TAccountBookkeepingQuerySearchParams = Partial<Record<TUserBookkeepingQueryRequestKeys, string>>;

type TInitialState = {
	isLoading: boolean,
	item?: TAccountBookkeeping,
	query: TAccountBookkeepingQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	query: {
		dateStart: defaultStartDate(),
		dateEnd: defaultEndDate(),
		types: [
			// ShiftReportListStatus.CHECKED,
			// ShiftReportListStatus.NONE,
			// ShiftReportListStatus.CREATED,
		].join(','),
	},
};

export const accountBookkeepingSlice = createSlice({
	name: 'accountBookkeepingSlice',
	initialState,
	reducers: {
		setAccountBookkeepingQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountBookkeepingQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		setAccountBookkeepingQueryTypes(state, action: PayloadAction<TUserShiftBookkeepingStatus[]>) {
			state.query.types = action.payload.filter(item => !!item).join(',');
		},

		clearAccountBookkeeping(state) {
			return { ...initialState, query: state.query };
		},
	},

	extraReducers: {
		[accountBookkeepingGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountBookkeepingGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountBookkeeping>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.error = undefined;
		},

		[accountBookkeepingGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountBookkeepingSelector = () => useAppSelector(state => state[accountBookkeepingSlice.name]);

export const { clearAccountBookkeeping, setAccountBookkeepingQueryDateStart, setAccountBookkeepingQueryDateEnd, setAccountBookkeepingQueryTypes } = accountBookkeepingSlice.actions;
