export type TUserExpenseBookkeeping = {
	name: string;
	value: number;
	date: string;
};

export type TUserExpenseBookkeepingResponse = TUserExpenseBookkeeping;

export function adaptUserExpenseBookkeepingListFromResponse(list?: TUserExpenseBookkeepingResponse[]): TUserExpenseBookkeeping[] {
	if (!list) {
		return [];
	}

	return list.map(item => adaptUserExpenseBookkeepingFromResponse(item));
}

export function adaptUserExpenseBookkeepingFromResponse(data: TUserExpenseBookkeepingResponse): TUserExpenseBookkeeping {
	const {
		name,
		value,
		date,
	} = data;

	return {
		name,
		value,
		date,
	};
}
