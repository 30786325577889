import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const UsersExpensesTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersExpensesTypesSourceSortCol = TTableSortCols<typeof UsersExpensesTypesSourceSortCol>;

export type TUserExpenseTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersExpensesTypesSourceSortCol;
};

export type TUsersExpensesTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersExpensesTypesSourceQueryToRequest(data?: TUserExpenseTypeSourceQuery): TUsersExpensesTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserExpenseTypeSource = {
  id: number;
  name: string;
  description: string;
};

export type TUserExpenseTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TUserExpenseTypeSourceListResponse = TListResponse<TUserExpenseTypeSourceResponse>;

export type TUserExpenseTypeSourceList = TListResponse<TUserExpenseTypeSource>;

export function adaptUserExpenseTypeSourceFromListResponse(data: TUserExpenseTypeSourceListResponse): TUserExpenseTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserExpenseTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserExpenseTypeSourceFromResponse(data: TUserExpenseTypeSourceResponse): TUserExpenseTypeSource {
	const {
		id,
		name,
		description = '',
	} = data;

	return {
		id,
		name,
		description,
	};
}
