import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TEquipment, TEquipmentResponse, adaptEquipmentFromResponse } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS } from '../../backend-paths';


export const equipmentsUsersReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_EQUIPMENTS}/:id/use-schedule`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS}/${payload}/use-schedule`;

		return HttpService.get<TEquipmentResponse>(path).then(result => {
			return adaptEquipmentFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentsUsersReadSlice = createSlice({
	name: 'equipmentsUsersReadSlice',
	initialState,
	reducers: {
		clearEquipmentsUsersRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentsUsersReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsUsersReadGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentsUsersReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsUsersReadSelector = () => useAppSelector(state => state[equipmentsUsersReadSlice.name]);

export const { clearEquipmentsUsersRead } = equipmentsUsersReadSlice.actions;
