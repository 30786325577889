import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { PaymentsTypesSortCol, TPaymentTypeList, TPaymentTypeListResponse, TPaymentTypeQuery, TPaymentsTypesSortCol, adaptPaymentTypeFromListResponse, adaptPaymentsTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_PAYMENTS_TYPES } from '../../backend-paths';


export const paymentsTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_PAYMENTS_TYPES,
	(payload: TPaymentTypeQuery | undefined, thunkApi) => {
		const params = payload ? adaptPaymentsTypesQueryToRequest(payload) : {};

		return HttpService.get<TPaymentTypeListResponse>(BACKEND_ADMIN_PAYMENTS_TYPES, { params }).then(result => {
			return adaptPaymentTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TPaymentTypeList,
	query: TPaymentTypeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: PaymentsTypesSortCol.CREATED_AT,
	},
};

export const paymentsTypesSlice = createSlice({
	name: 'paymentsTypesSlice',
	initialState,
	reducers: {
		setPaymentsTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setPaymentsTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setPaymentsTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setPaymentsTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setPaymentsTypesQuerySortCol(state, action: PayloadAction<TPaymentsTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearPaymentsTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[paymentsTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[paymentsTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TPaymentTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[paymentsTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const usePaymentsTypesSelector = () => useAppSelector(state => state[paymentsTypesSlice.name]);

export const { clearPaymentsTypes, setPaymentsTypesQueryLimit, setPaymentsTypesQueryOffset, setPaymentsTypesQuerySort, setPaymentsTypesQuerySortCol, setPaymentsTypesQuerySearch } = paymentsTypesSlice.actions;
