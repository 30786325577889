import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserPositionChangesPage = lazy(() => import('../views/list/UserPositionChangesPage'));

export function UserPositionChangesPageLazy() {
	return (
		<PageSuspense>
			<UserPositionChangesPage />
		</PageSuspense>
	);
}
