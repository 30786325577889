import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserShiftReport, TUserShiftReportResponse, adaptUserShiftReportFromResponse } from './user-shift-report.type';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { ShiftReportListStatus } from './account-shift.type';


export const UserShiftsSortCol = {
	ID: 'id',
	PROJECT_NAME: 'project_name',
	ORDER_NAME: 'order_name',
	USER_NAME: 'user_name',
	DATE: 'date',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUserShiftsSortCol = TTableSortCols<typeof UserShiftsSortCol>;
export type TUserShiftsStatus = TTableSortCols<typeof ShiftReportListStatus>;

export type TUserShiftQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserShiftsSortCol;

	types: TUserShiftsStatus[],
	dateStart?: string;
	dateEnd?: string;
};

export type TUsersShiftsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	types?: string;
	date_start?: string;
	date_end?: string;
};

export type TUserShiftPayloadQuery = {
	groupId: number;
	orderId: number;
	projectId: number;
	data?: TUserShiftQuery;
};

export function adaptUsersShiftsQueryRequest(data: TUserShiftQuery): TUsersShiftsQueryRequest {
	const {
		sortCol,
		types,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		types: types && types.length > 0 ? types.join(',') : undefined,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserShift = {
	id: number;
	groupId: number;
	date: string;
	orderName: string;
	projectName: string;
	rateTypeName?: string;
	rate?: number;
	isSalaryDayNotCount: boolean;
	overwork?: number;
	deadlinePenalty?: number;
	photoPenalty?: number;
	isChecked: boolean;
	checkDescription?: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	report?: TUserShiftReport;
	user?: TUserMeta;

	totalExpenses?: number;
};

export type TUserShiftResponse = {
	id: number;
	group_id: number;
	date: string;
	order_name: string;
	project_name: string;
	rate_type_name?: string;
	rate: number;
	is_salary_day_not_count: boolean;
	overwork?: number;
	deadline_penalty?: number;
	photo_penalty?: number;
	is_checked: boolean;
	check_description: string;
	created_at?: string;
	updated_at?: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;

	report?: TUserShiftReportResponse;
	user?: TUserMetaResponse;

	total_expenses?: number;
};

export type TUserShiftListResponse = TListResponse<TUserShiftResponse>;

export type TUserShiftList = TListResponse<TUserShift>;

export type TUserShiftBodyRequest = {
	// rateTypeId: number;
	// rate: number;
	// isSalaryDayNotCount: boolean;
	overwork: number;
	checkDescription: string;
	isChecked: boolean;
	deadlinePenalty: number;
	photoPenalty: number;
};

export type TUserShiftRequest = {
	// rate_type_id: number;
	// rate: number;
	// is_salary_day_not_count: boolean;
	overwork: number;
	check_description: string;
	is_checked: boolean;
	deadline_penalty: number;
	photo_penalty: number;
};

export type TUserShiftPayload = {
	id: number;
	groupId: number;
	orderId: number;
	projectId: number;
};

export type TUserShiftPayloadUpdate = {
	id: number;
	data: TUserShiftBodyRequest;
};

export function adaptUserShiftFromListResponse(data: TUserShiftListResponse): TUserShiftList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserShiftFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserShiftFromResponse(data: TUserShiftResponse): TUserShift {
	const {
		id,
		group_id: groupId,
		date,
		order_name: orderName,
		project_name: projectName,
		rate_type_name: rateTypeName,
		rate,
		is_salary_day_not_count: isSalaryDayNotCount,
		overwork,
		deadline_penalty: deadlinePenalty,
		photo_penalty: photoPenalty,
		is_checked: isChecked,
		check_description: checkDescription,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		report: reportRaw,
		user: userRaw,

		total_expenses: totalExpenses,
	} = data;

	const report = reportRaw ? adaptUserShiftReportFromResponse(reportRaw) : undefined;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);
	const user = adaptUserMetaUndefinedFromResponse(userRaw);

	return {
		id,
		groupId,
		date,
		orderName,
		projectName,
		rateTypeName,
		rate,
		isSalaryDayNotCount,
		overwork,
		deadlinePenalty,
		photoPenalty,
		isChecked,
		checkDescription,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		report,
		user,

		totalExpenses,
	};
}

export function adaptUserShiftToRequest(data: TUserShiftBodyRequest): TUserShiftRequest {
	const {
		// rateTypeId,
		// rate,
		// isSalaryDayNotCount,
		overwork,
		checkDescription,
		isChecked,
		deadlinePenalty,
		photoPenalty,
	} = data;

	return {
		// rate_type_id: rateTypeId,
		// rate: rate,
		// is_salary_day_not_count: isSalaryDayNotCount,
		overwork: overwork,
		check_description: checkDescription,
		is_checked: isChecked,
		deadline_penalty: deadlinePenalty,
		photo_penalty: photoPenalty,
	};
}
