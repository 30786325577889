import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TProjectClientSourceList, TProjectClientSourceListResponse, TProjectClientSourceQuery, adaptProjectClientSourceFromListResponse, adaptProjectsClientsSourceQueryToRequest } from '../../types';
import { BACKEND_SOURCE_PROJECTS_CLIENTS } from '../../backend-paths';


export const sourceProjectsClientsGetApi = createAsyncThunk(
	BACKEND_SOURCE_PROJECTS_CLIENTS,
	(payload: TProjectClientSourceQuery | undefined, thunkApi) => {
		const params = adaptProjectsClientsSourceQueryToRequest(payload);

		return HttpService.get<TProjectClientSourceListResponse>(BACKEND_SOURCE_PROJECTS_CLIENTS, { params }).then(result => {
			return adaptProjectClientSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TProjectClientSourceList;
	query: TProjectClientSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceProjectsClientsSlice = createSlice({
	name: 'sourceProjectsClientsSlice',
	initialState,
	reducers: {
		setSourceProjectsClientsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceProjectsClients(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceProjectsClientsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceProjectsClientsGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceProjectsClientsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceProjectsClientsSelector = () => useAppSelector(state => state[sourceProjectsClientsSlice.name]);

export const { clearSourceProjectsClients, setSourceProjectsClientsQuerySearch } = sourceProjectsClientsSlice.actions;
