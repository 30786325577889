import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipment, TEquipmentPayload, TEquipmentResponse, adaptEquipmentFromResponse, adaptEquipmentToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS } from '../../backend-paths';


export const equipmentCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EQUIPMENTS}`,
	(payload: TEquipmentPayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptEquipmentToRequest(data);

		return HttpService.post<TEquipmentResponse>(BACKEND_ADMIN_EQUIPMENTS, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ADMIN_EQUIPMENTS}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TEquipmentResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptEquipmentFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentCreateSlice = createSlice({
	name: 'equipmentCreateSlice',
	initialState,
	reducers: {
		clearEquipmentCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[equipmentCreateApi.fulfilled.type]: (state, action: PayloadAction<TEquipment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useEquipmentCreateSelector = () => useAppSelector(state => state[equipmentCreateSlice.name]);

export const { clearEquipmentCreate } = equipmentCreateSlice.actions;
