import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserCategoryChange, TUserCategoryChangeResponse, adaptUserCategoryChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCategoryChangeCurrentReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/category`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/category`;

		return HttpService.get<TUserCategoryChangeResponse>(path).then(result => {
			return adaptUserCategoryChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategoryChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryChangeCurrentReadSlice = createSlice({
	name: 'userCategoryChangeCurrentReadSlice',
	initialState,
	reducers: {
		setUserCategoryChangeCurrentRead(state, action: PayloadAction<TUserCategoryChange>) {
			state.item = action.payload;
		},

		clearUserCategoryChangeCurrentRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryChangeCurrentReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryChangeCurrentReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategoryChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryChangeCurrentReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryChangeCurrentReadSelector = () => useAppSelector(state => state[userCategoryChangeCurrentReadSlice.name]);

export const { clearUserCategoryChangeCurrentRead, setUserCategoryChangeCurrentRead } = userCategoryChangeCurrentReadSlice.actions;
