import { Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';

import { SignUpFormMain } from './components/form/SignUpFormMain';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpStageMain() {
	return (
		<>
			<SignUpFormMain />

			<p className={ styles.underText }>
				<span>Уже есть логин и пароль? </span>
				<Link to={ PATH_SIGN_IN }>Войти</Link>
			</p>
		</>
	);
}

