import { TagListBoxItem } from './TagListBoxItem';
import { TTagListBoxItem } from './TagListBox';

import styles from './TagListBox.module.scss';


type TProps = {
	placeholder?: string;
	items: TTagListBoxItem[];
	onDelete: (value: number) => void;
	onFocus: (item: TTagListBoxItem) => void;
	onBlur: () => void;
	readonly?: boolean;
};

export function TagListBoxWrap(props: TProps) {
	const {
		items = [],
		placeholder,
		onDelete,
		onFocus,
		onBlur,
		readonly,
		...restProps
	} = props;

	if (items.length <= 0) {
		return (
			<div className={ styles.empty } { ...restProps }>
				{ placeholder }
			</div>
		);
	}

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map(item => {
					const { id } = item;

					return (
						<TagListBoxItem
							key={ id }
							onClick={ e => onDelete(id) }
							onFocus={ () => onFocus(item) }
							onBlur={ onBlur }
							item={ item }
							readonly={ readonly }
						/>
					);
				})
			}
		</div>
	);
}
