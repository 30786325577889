import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserEmail, TUserEmailResponse, TUserEmailPayloadUpdate, adaptUserEmailFromResponse, adaptUserEmailToRequest } from '../../types';


export const userEmailUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/email`,
	(payload: TUserEmailPayloadUpdate, thunkApi) => {
		const { data, nickname } = payload;
		const dataRequest = adaptUserEmailToRequest(data);
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/email`;

		return HttpService.put<TUserEmailResponse>(path, dataRequest).then(result => {
			return adaptUserEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserEmail> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userEmailUpdateSlice = createSlice({
	name: 'userEmailUpdateSlice',
	initialState,
	reducers: {
		clearUserEmailUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userEmailUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userEmailUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userEmailUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserEmailUpdateSelector = () => useAppSelector(state => state[userEmailUpdateSlice.name]);

export const { clearUserEmailUpdate } = userEmailUpdateSlice.actions;
