import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ScheduleEventsTypesSourceSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TScheduleEventsTypesSourceSortCol = TTableSortCols<typeof ScheduleEventsTypesSourceSortCol>;

export type TScheduleEventTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TScheduleEventsTypesSourceSortCol;
};

export type TScheduleEventsTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptScheduleEventsTypesSourceQueryToRequest(data?: TScheduleEventTypeSourceQuery): TScheduleEventsTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TScheduleEventTypeSource = {
  id: number;
  name: string;
  color: string;
};

export type TScheduleEventTypeSourceResponse = TScheduleEventTypeSource;

export type TScheduleEventTypeSourceListResponse = TListResponse<TScheduleEventTypeSourceResponse>;

export type TScheduleEventTypeSourceList = TListResponse<TScheduleEventTypeSource>;

export function adaptScheduleEventTypeSourceFromListResponse(data: TScheduleEventTypeSourceListResponse): TScheduleEventTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptScheduleEventTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptScheduleEventTypeSourceFromResponse(data: TScheduleEventTypeSourceResponse): TScheduleEventTypeSource {
	const {
		id,
		name,
		color,
	} = data;

	return {
		id,
		name,
		color,
	};
}
