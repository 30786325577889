import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TExpenseType, TExpenseTypeResponse, adaptExpenseTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_EXPENSES_TYPES } from '../../backend-paths';


export const expenseTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_EXPENSES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EXPENSES_TYPES}/${payload}`;

		return HttpService.get<TExpenseTypeResponse>(path).then(result => {
			return adaptExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const expenseTypeReadSlice = createSlice({
	name: 'expenseTypeReadSlice',
	initialState,
	reducers: {
		setExpenseTypeRead(state, action: PayloadAction<TExpenseType>) {
			state.item = action.payload;
		},

		clearExpenseTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[expenseTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[expenseTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[expenseTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useExpenseTypeReadSelector = () => useAppSelector(state => state[expenseTypeReadSlice.name]);

export const { clearExpenseTypeRead, setExpenseTypeRead } = expenseTypeReadSlice.actions;
