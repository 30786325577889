import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsOrdersSortCol, TProjectOrderList, TProjectOrderListResponse, TProjectOrderPayloadQuery, TProjectOrderQuery, TProjectsOrdersSortCol, adaptProjectOrderFromListResponse, adaptProjectsOrdersQueryRequest } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectsOrdersGetApi = createAsyncThunk(
	`${BACKEND_PROJECTS}/:projectId/orders`,
	(payload: TProjectOrderPayloadQuery, thunkApi) => {
		const { projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders`;
		const params = data ? adaptProjectsOrdersQueryRequest(data) : {};

		return HttpService.get<TProjectOrderListResponse>(path, { params }).then(result => {
			return adaptProjectOrderFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectOrderList,
	query: TProjectOrderQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsOrdersSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectsOrdersSlice = createSlice({
	name: 'projectsOrdersSlice',
	initialState,
	reducers: {
		setProjectsOrdersQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsOrdersQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsOrdersQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsOrdersQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsOrdersQuerySortCol(state, action: PayloadAction<TProjectsOrdersSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectsOrders(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsOrdersGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsOrdersGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsOrdersGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsOrdersSelector = () => useAppSelector(state => state[projectsOrdersSlice.name]);

export const { clearProjectsOrders, setProjectsOrdersQueryLimit, setProjectsOrdersQueryOffset, setProjectsOrdersQuerySort, setProjectsOrdersQuerySortCol, setProjectsOrdersQuerySearch } = projectsOrdersSlice.actions;
