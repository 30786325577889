import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingBookkeepingBillChangesConstraint, TSystemSettingBookkeepingBillChangesConstraintResponse, adaptSystemSettingBookkeepingBillChangesConstraintFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT } from '../../backend-paths';


export const systemSettingBookkeepingBillChangesConstraintReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT;

		return HttpService.get<TSystemSettingBookkeepingBillChangesConstraintResponse>(path).then(result => {
			return adaptSystemSettingBookkeepingBillChangesConstraintFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingBookkeepingBillChangesConstraint> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingBookkeepingBillChangesConstraintReadSlice = createSlice({
	name: 'systemSettingBookkeepingBillChangesConstraintReadSlice',
	initialState,
	reducers: {
		setSystemSettingBookkeepingBillChangesConstraintRead(state, action: PayloadAction<TSystemSettingBookkeepingBillChangesConstraint>) {
			state.item = action.payload;
		},

		clearSystemSettingBookkeepingBillChangesConstraintRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingBookkeepingBillChangesConstraintReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingBookkeepingBillChangesConstraintReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingBookkeepingBillChangesConstraint>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingBookkeepingBillChangesConstraintReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingBookkeepingBillChangesConstraintReadSelector = () => useAppSelector(state => state[systemSettingBookkeepingBillChangesConstraintReadSlice.name]);

export const { clearSystemSettingBookkeepingBillChangesConstraintRead, setSystemSettingBookkeepingBillChangesConstraintRead } = systemSettingBookkeepingBillChangesConstraintReadSlice.actions;
