export type TUserFinancialChangeBookkeeping = {
	name: string;
	value: number;
	date: string;
};

export type TUserFinancialChangeBookkeepingResponse = TUserFinancialChangeBookkeeping;

export function adaptUserFinancialChangeBookkeepingListFromResponse(list?: TUserFinancialChangeBookkeepingResponse[]): TUserFinancialChangeBookkeeping[] {
	if (!list) {
		return [];
	}

	return list.map(item => adaptUserFinancialChangeBookkeepingFromResponse(item));
}

export function adaptUserFinancialChangeBookkeepingFromResponse(data: TUserFinancialChangeBookkeepingResponse): TUserFinancialChangeBookkeeping {
	const {
		name,
		value,
		date,
	} = data;

	return {
		name,
		value,
		date,
	};
}
