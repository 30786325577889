import { FormButtonLink, Icon, TFormButtonLinkProps } from 'src/components';


type TProps = TFormButtonLinkProps & {
	text?: string;
};

export function ButtonAdd(props: TProps) {
	const { text, ...restProps } = props;

	return (
		<FormButtonLink { ...restProps }>
			<Icon icon="icon-plus" />
			{ text }
		</FormButtonLink>
	);
}
