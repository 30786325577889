import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserCategoryUpdatePage = lazy(() => import('../views/update/UserCategoryUpdatePage'));

export function UserCategoryUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserCategoryUpdatePage />
		</PageSuspense>
	);
}
