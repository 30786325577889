import { FormButtonLink, Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';

import styles from '../styles/RecoveryStage.module.scss';


export function RecoveryStageSuccess() {
	const classNames = [styles.paragraph, styles.percepted];

	return (
		<div className={ styles.recoveryPageStage }>
			<p className={ classNames.join(' ') }>
				Новый пароль от Вашего аккаунта установлен успешно!
			</p>

			<p className={ styles.paragraph }>
				Теперь Вы можете <Link to={ PATH_SIGN_IN }>Войти</Link> в свой аккаунт
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>Хорошо</FormButtonLink>
		</div>
	);
}

