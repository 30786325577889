import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountExpensesSortCol, TAccountExpenseList, TAccountExpenseListResponse, TAccountExpensesQuery, TAccountExpensesQueryPayload, TAccountExpensesSortCol, adaptAccountExpenseFromListResponse, adaptAccountExpensesQueryToRequest } from '../../types';
import { BACKEND_ACCOUNT_EXPENSES } from '../../backend-paths';


export const accountExpensesGetApi = createAsyncThunk(
	`${BACKEND_ACCOUNT_EXPENSES}/:id`,
	(payload: TAccountExpensesQueryPayload, thunkApi) => {
		const { data } = payload;
		const params = adaptAccountExpensesQueryToRequest(data);

		const path = `${BACKEND_ACCOUNT_EXPENSES}`;

		return HttpService.get<TAccountExpenseListResponse>(path, { params }).then(result => {
			return adaptAccountExpenseFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TAccountExpenseList;
	query: TAccountExpensesQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountExpensesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const accountExpensesSlice = createSlice({
	name: 'accountExpensesSlice',
	initialState,
	reducers: {
		setAccountExpensesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountExpensesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountExpensesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountExpensesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountExpensesQuerySortCol(state, action: PayloadAction<TAccountExpensesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountExpenses(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountExpensesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExpensesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountExpenseList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountExpensesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExpensesSelector = () => useAppSelector(state => state[accountExpensesSlice.name]);

export const { clearAccountExpenses, setAccountExpensesQueryLimit, setAccountExpensesQueryOffset, setAccountExpensesQuerySort, setAccountExpensesQuerySortCol, setAccountExpensesQuerySearch } = accountExpensesSlice.actions;
