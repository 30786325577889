import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TUserExpenseTypeSource, TUserExpenseTypeSourceResponse, adaptUserExpenseTypeSourceFromResponse } from './user-expense-type-source.type';
import { TPaymentTypeSource, TPaymentTypeSourceResponse, adaptPaymentTypeSourceFromResponse } from './payment-type-source.type';
import { TProjectOrderSource, TProjectOrderSourceResponse, adaptProjectOrderSourceFromResponse } from './project-order-source.type';


export const AccountExpensesSortCol = {
	USER_EXPENSE_TYPE_ID: 'expense_type_id',
	PAYMENT_TYPE_ID: 'payment_type_id',
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountExpensesSortCol = TTableSortCols<typeof AccountExpensesSortCol>;

export type TAccountExpensesQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountExpensesSortCol;
};

export type TAccountExpensesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountExpensesQueryPayload = {
	data?: TAccountExpensesQuery;
};

export function adaptAccountExpensesQueryToRequest(data?: TAccountExpensesQuery): TAccountExpensesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TAccountExpense = {
	id: number;
	date: string;
	value: number;
	description?: string;
	projectOrder: TProjectOrderSource;
	paymentType: TPaymentTypeSource;
	userExpenseType: TUserExpenseTypeSource;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	files?: TAccountExpenseFile[];
};

export type TAccountExpenseResponse = {
	id: number;
	date: string;
	value: number;
	description?: string;
	project_order: TProjectOrderSourceResponse;
	payment_type: TPaymentTypeSourceResponse;
	user_expense_type: TUserExpenseTypeSourceResponse;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;

	files?: TAccountExpenseFile[];
};

export type TAccountExpenseListResponse = TListResponse<TAccountExpenseResponse>;

export type TAccountExpenseList = TListResponse<TAccountExpense>;

export type TAccountExpenseBodyRequest = {
	projectOrderId?: number;
	value: number;
	date: string;
	paymentTypeId: number;
	description?: string;
	userExpenseTypeId: number;
};

export type TAccountExpenseRequest = {
	project_order_id?: number;
	date: string;
	value: number;
	description?: string;
	payment_type_id: number;
	user_expense_type_id: number;
};

export type TAccountExpenseFile = {
	name: string;
	path: string;
};

export type TAccountExpenseFileRequest = Array<File | string>;

export type TAccountExpensePayloadCreate = {
	data: TAccountExpenseBodyRequest;
	files: TAccountExpenseFileRequest;
};

export type TAccountExpensePayloadUpdate = {
	id: number;
	data: TAccountExpenseBodyRequest;
	files: TAccountExpenseFileRequest;
};

export function adaptAccountExpenseFromListResponse(data: TAccountExpenseListResponse): TAccountExpenseList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountExpenseFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountExpenseFromResponse(data: TAccountExpenseResponse): TAccountExpense {
	const {
		id,
		date,
		value,
		description: descriptionRaw,
		project_order: projectOrderRaw,
		user_expense_type: userExpenseTypeRaw,
		payment_type: paymentTypeRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		files = [],
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const paymentType = adaptPaymentTypeSourceFromResponse(paymentTypeRaw);
	const userExpenseType = adaptUserExpenseTypeSourceFromResponse(userExpenseTypeRaw);
	const projectOrder = adaptProjectOrderSourceFromResponse(projectOrderRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		date,
		value,
		description,
		projectOrder,
		paymentType,
		userExpenseType,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		files,
	};
}

export function adaptAccountExpenseToRequest(data: TAccountExpenseBodyRequest): TAccountExpenseRequest {
	const {
		projectOrderId = undefined,
		date,
		value,
		description,
		userExpenseTypeId,
		paymentTypeId,
	} = data;

	return {
		project_order_id: projectOrderId,
		date,
		value,
		description,
		user_expense_type_id: userExpenseTypeId,
		payment_type_id: paymentTypeId,
	};
}
