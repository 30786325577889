import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TEquipmentGroupHistory, TEquipmentGroupHistoryParams, TEquipmentGroupHistoryResponse, adaptEquipmentGroupHistoryFromResponse } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_GROUPS } from '../../backend-paths';


export const equipmentGroupHistoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/:group_id/history/:id`,
	(payload: TEquipmentGroupHistoryParams, thunkApi) => {
		const { groupId, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/${groupId}/history/${id}`;

		return HttpService.get<TEquipmentGroupHistoryResponse>(path).then(result => {
			return adaptEquipmentGroupHistoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentGroupHistory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentGroupHistoryReadSlice = createSlice({
	name: 'equipmentGroupHistoryReadSlice',
	initialState,
	reducers: {
		setEquipmentGroupHistoryRead(state, action: PayloadAction<TEquipmentGroupHistory>) {
			state.item = action.payload;
		},

		clearEquipmentGroupHistoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentGroupHistoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentGroupHistoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentGroupHistory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentGroupHistoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentGroupHistoryReadSelector = () => useAppSelector(state => state[equipmentGroupHistoryReadSlice.name]);

export const { clearEquipmentGroupHistoryRead, setEquipmentGroupHistoryRead } = equipmentGroupHistoryReadSlice.actions;
