import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountOverwork, TAccountOverworkPayloadCreate, TAccountOverworkResponse, adaptAccountOverworkFromResponse, adaptAccountOverworkToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_OVERWORKS } from '../../backend-paths';


export const accountOverworkCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_OVERWORKS}`,
	(payload: TAccountOverworkPayloadCreate, thunkApi) => {
		const { data } = payload;

		const dataRequest = adaptAccountOverworkToRequest(data);

		return HttpService.post<TAccountOverworkResponse>(BACKEND_ACCOUNT_OVERWORKS, dataRequest).then(result => {
			return adaptAccountOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountOverworkCreateSlice = createSlice({
	name: 'accountOverworkCreateSlice',
	initialState,
	reducers: {
		clearAccountOverworkCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountOverworkCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountOverworkCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountOverworkCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountOverworkCreateSelector = () => useAppSelector(state => state[accountOverworkCreateSlice.name]);

export const { clearAccountOverworkCreate } = accountOverworkCreateSlice.actions;
