import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectsOrdersTagsTypesPage = lazy(() => import('../views/list/ProjectsOrdersTagsTypesPage'));

export function ProjectsOrdersTagsTypesPageLazy() {
	return (
		<PageSuspense>
			<ProjectsOrdersTagsTypesPage />
		</PageSuspense>
	);
}
