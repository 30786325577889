import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingShiftsNotCountingOverworkTimeUpdatePage = lazy(() => import('../views/update-not-counting-overwork-time/SystemSettingShiftsNotCountingOverworkTimeUpdatePage'));

export function SystemSettingShiftsNotCountingOverworkTimeUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingShiftsNotCountingOverworkTimeUpdatePage />
		</PageSuspense>
	);
}
