import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingShiftsNotCountingOverworkTime, TSystemSettingShiftsNotCountingOverworkTimeResponse, adaptSystemSettingShiftsNotCountingOverworkTimeFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME } from '../../backend-paths';


export const systemSettingShiftsNotCountingOverworkTimeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME;

		return HttpService.get<TSystemSettingShiftsNotCountingOverworkTimeResponse>(path).then(result => {
			return adaptSystemSettingShiftsNotCountingOverworkTimeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingShiftsNotCountingOverworkTime> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingShiftsNotCountingOverworkTimeReadSlice = createSlice({
	name: 'systemSettingShiftsNotCountingOverworkTimeReadSlice',
	initialState,
	reducers: {
		setSystemSettingShiftsNotCountingOverworkTimeRead(state, action: PayloadAction<TSystemSettingShiftsNotCountingOverworkTime>) {
			state.item = action.payload;
		},

		clearSystemSettingShiftsNotCountingOverworkTimeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingShiftsNotCountingOverworkTimeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingShiftsNotCountingOverworkTimeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingShiftsNotCountingOverworkTime>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingShiftsNotCountingOverworkTimeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingShiftsNotCountingOverworkTimeReadSelector = () => useAppSelector(state => state[systemSettingShiftsNotCountingOverworkTimeReadSlice.name]);

export const { clearSystemSettingShiftsNotCountingOverworkTimeRead, setSystemSettingShiftsNotCountingOverworkTimeRead } = systemSettingShiftsNotCountingOverworkTimeReadSlice.actions;
