import { TEquipmentSource, TEquipmentSourceResponse, adaptEquipmentSourceFromResponse } from './equipment-source.type';


export type TEquipmentGroupHistoryDataRelationEquipment = {
  amount: number;

  equipment: TEquipmentSource;
};

export type TEquipmentGroupHistoryDataRelationEquipmentResponse = {
  amount: number;

	equipment: TEquipmentSourceResponse;
};

export type TEquipmentGroupHistoryDataRelationEquipmentCreate = Pick<TEquipmentGroupHistoryDataRelationEquipment, 'amount' | 'equipment'>;

export function adaptEquipmentGroupHistoryDataRelationEquipmentFromResponse(data: TEquipmentGroupHistoryDataRelationEquipmentResponse): TEquipmentGroupHistoryDataRelationEquipment {
	const {
		amount,

		equipment: equipmentRaw,
	} = data;

	const equipment = adaptEquipmentSourceFromResponse(equipmentRaw);

	return {
		amount,
		equipment,
	};
}
