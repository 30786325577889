import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserData, TUserDataPayload, TUserDataResponse, adaptUserDataFromResponse, adaptUserDataToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDataUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/data`,
	(payload: TUserDataPayload, thunkApi) => {
		const { data, nickname } = payload;

		const path = `${BACKEND_ADMIN_USERS}/${nickname}/data`;

		const dataRequest = adaptUserDataToRequest(data);

		return HttpService.put<TUserDataResponse>(path, dataRequest).then(result => {
			return adaptUserDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDataUpdateSlice = createSlice({
	name: 'userDataUpdateSlice',
	initialState,
	reducers: {
		clearUserDataUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDataUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDataUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDataUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDataUpdateSelector = () => useAppSelector(state => state[userDataUpdateSlice.name]);

export const { clearUserDataUpdate } = userDataUpdateSlice.actions;
