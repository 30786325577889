import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountOverworksSortCol, TAccountOverworkList, TAccountOverworkListResponse, TAccountOverworksQuery, TAccountOverworksQueryPayload, TAccountOverworksSortCol, adaptAccountOverworkFromListResponse, adaptAccountOverworksQueryToRequest } from '../../types';
import { BACKEND_ACCOUNT_OVERWORKS } from '../../backend-paths';


export const accountOverworksGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_OVERWORKS,
	(payload: TAccountOverworksQueryPayload, thunkApi) => {
		const { data } = payload;
		const params = adaptAccountOverworksQueryToRequest(data);

		return HttpService.get<TAccountOverworkListResponse>(BACKEND_ACCOUNT_OVERWORKS, { params }).then(result => {
			return adaptAccountOverworkFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TAccountOverworkList;
	query: TAccountOverworksQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountOverworksSortCol.CREATED_AT,
	},
	error: undefined,
};

export const accountOverworksSlice = createSlice({
	name: 'accountOverworksSlice',
	initialState,
	reducers: {
		setAccountOverworksQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountOverworksQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountOverworksQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountOverworksQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountOverworksQuerySortCol(state, action: PayloadAction<TAccountOverworksSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountOverworks(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountOverworksGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountOverworksGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountOverworkList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountOverworksGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountOverworksSelector = () => useAppSelector(state => state[accountOverworksSlice.name]);

export const { clearAccountOverworks, setAccountOverworksQueryLimit, setAccountOverworksQueryOffset, setAccountOverworksQuerySort, setAccountOverworksQuerySortCol, setAccountOverworksQuerySearch } = accountOverworksSlice.actions;
