import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectOrderGroup, TProjectOrderGroupPayload, TProjectOrderGroupResponse, adaptProjectOrderGroupFromResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:id`,
	(payload: TProjectOrderGroupPayload, thunkApi) => {
		const { id, orderId, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${id}`;

		return HttpService.get<TProjectOrderGroupResponse>(path).then(result => {
			return adaptProjectOrderGroupFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroup> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupReadSlice = createSlice({
	name: 'projectOrderGroupReadSlice',
	initialState,
	reducers: {
		setProjectOrderGroupRead(state, action: PayloadAction<TProjectOrderGroup>) {
			state.item = action.payload;
		},

		clearProjectOrderGroupRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroup>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupReadSelector = () => useAppSelector(state => state[projectOrderGroupReadSlice.name]);

export const { clearProjectOrderGroupRead, setProjectOrderGroupRead } = projectOrderGroupReadSlice.actions;
