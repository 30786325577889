import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingBookkeepingBillChangesConstraintUpdatePage = lazy(() => import('../views/update-bill-changes-constraint/SystemSettingBookkeepingBillChangesConstraintUpdatePage'));

export function SystemSettingBookkeepingBillChangesConstraintUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingBookkeepingBillChangesConstraintUpdatePage />
		</PageSuspense>
	);
}
