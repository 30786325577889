import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountTaxationReceiptUpdatePage = lazy(() => import('../views/update/AccountTaxationReceiptUpdatePage'));

export function AccountTaxationReceiptUpdatePageLazy() {
	return (
		<PageSuspense>
			<AccountTaxationReceiptUpdatePage />
		</PageSuspense>
	);
}
