import { TEquipmentGroupSource, TEquipmentGroupSourceResponse, adaptEquipmentGroupSourceFromResponse } from './equipment-group-source.type';


export type TEquipmentOrderRelationEquipmentGroup = {
  amount: number;

  group: TEquipmentGroupSource;
};

export type TEquipmentOrderRelationEquipmentGroupResponse = {
  amount: number;

	group: TEquipmentGroupSourceResponse;
};

export type TEquipmentOrderRelationEquipmentGroupCreate = Pick<TEquipmentOrderRelationEquipmentGroup, 'amount' | 'group'>;

export function adaptEquipmentOrderRelationEquipmentGroupFromResponse(data: TEquipmentOrderRelationEquipmentGroupResponse): TEquipmentOrderRelationEquipmentGroup {
	const {
		amount,

		group: groupRaw,
	} = data;

	const group = adaptEquipmentGroupSourceFromResponse(groupRaw);

	return {
		amount,
		group,
	};
}
