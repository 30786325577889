import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TUserDayCategory, TUserDayCategoryQuery, TUserDayCategoryQueryPayload, TUserDayCategoryResponse, adaptUserDayCategoryFromResponse, adaptUserDayCategoryQueryToRequest } from '../../types';
import { BACKEND_ADMIN_USERS, BACKEND_USER_DAY_CATEGORY } from '../../backend-paths';


export const userDayCategoryGetApi = createAsyncThunk(
	`${BACKEND_ADMIN_USERS}/:nickname/${BACKEND_USER_DAY_CATEGORY}`,
	(payload: TUserDayCategoryQueryPayload, thunkApi) => {
		const { nickname, query } = payload;

		const params = adaptUserDayCategoryQueryToRequest(query);
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/${BACKEND_USER_DAY_CATEGORY}`;

		return HttpService.get<TUserDayCategoryResponse>(path, { params }).then(result => {
			return adaptUserDayCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	item?: TUserDayCategory;
	query: TUserDayCategoryQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	item: undefined,
	query: {
		dateStart: (new Date()).toISOString().slice(0, 10),
		dateEnd: undefined,
	},
	error: undefined,
};

export const userDayCategorySlice = createSlice({
	name: 'userDayCategorySlice',
	initialState,
	reducers: {
		setUserDayCategoryQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserDayCategoryQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearUserDayCategory(state) {
			return { ...initialState, query: state.query };
		},
	},

	extraReducers: {
		[userDayCategoryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDayCategoryGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDayCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.error = undefined;
		},

		[userDayCategoryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDayCategorySelector = () => useAppSelector(state => state[userDayCategorySlice.name]);

export const { clearUserDayCategory, setUserDayCategoryQueryDateStart, setUserDayCategoryQueryDateEnd } = userDayCategorySlice.actions;
