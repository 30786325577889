import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TSystemSettingCalendarEventColorSourceList, TSystemSettingCalendarEventColorSourceListResponse, adaptSystemSettingCalendarEventColorSourceFromListResponse } from '../../types';
import { BACKEND_ADMIN_SOURCE_SYSTEM_SETTINGS_CALENDAR_EVENT_COLORS } from '../../backend-paths';


export const sourceSystemSettingCalendarEventColorsGetApi = createAsyncThunk(
	BACKEND_ADMIN_SOURCE_SYSTEM_SETTINGS_CALENDAR_EVENT_COLORS,
	(payload, thunkApi) => {
		return HttpService.get<TSystemSettingCalendarEventColorSourceListResponse>(BACKEND_ADMIN_SOURCE_SYSTEM_SETTINGS_CALENDAR_EVENT_COLORS).then(result => {
			return adaptSystemSettingCalendarEventColorSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TSystemSettingCalendarEventColorSourceList;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	error: undefined,
};

export const sourceSystemSettingCalendarEventColorsSlice = createSlice({
	name: 'sourceSystemSettingCalendarEventColorsSlice',
	initialState,
	reducers: {
		clearSourceSystemSettingCalendarEventColors(state) {
			return { ...initialState, list: state.list };
		},
	},

	extraReducers: {
		[sourceSystemSettingCalendarEventColorsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceSystemSettingCalendarEventColorsGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingCalendarEventColorSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceSystemSettingCalendarEventColorsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceSystemSettingCalendarEventColorsSelector = () => useAppSelector(state => state[sourceSystemSettingCalendarEventColorsSlice.name]);

export const { clearSourceSystemSettingCalendarEventColors } = sourceSystemSettingCalendarEventColorsSlice.actions;
