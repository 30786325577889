import { Icon } from 'src/components/icon';

import styles from './TablePaginationLabel.module.scss';


type TProps = {
	icon: string;
};

export function TablePaginationLabel(props: TProps) {
	const { icon } = props;

	return (
		<Icon icon={ icon } className={ styles.iconChevron } />
	);
}
