import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { adaptSystemSettingTypeFromResponse, TSystemSettingType, TSystemSettingTypeResponse } from './system-setting-type.type';


export const SystemSettingsSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TSystemSettingsSortCol = TTableSortCols<typeof SystemSettingsSortCol>;

export type TSystemSettingQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TSystemSettingsSortCol;
};

export type TSystemSettingsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptSystemSettingsQueryToRequest(data?: TSystemSettingQuery): TSystemSettingsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TSystemSetting = {
  publicId: string;
  name: string;
  description?: string;
  value: string;
	createdAt: string;
  updatedAt: string;

	type?: TSystemSettingType;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TSystemSettingResponse = {
  public_id: string;
  name: string;
  description?: string;
  value: string;
  created_at: string;
  updated_at: string;

	type: TSystemSettingTypeResponse;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TSystemSettingListResponse = TListResponse<TSystemSettingResponse>;

export type TSystemSettingList = TListResponse<TSystemSetting>;

export type TSystemSettingBodyRequest = {
	name: string;
	description?: string;
};

export type TSystemSettingRequest = {
	name: string;
	description?: string;
};

export type TSystemSettingUpdate = {
	id: string;
	data: TSystemSettingBodyRequest;
};

export function adaptSystemSettingFromListResponse(data: TSystemSettingListResponse): TSystemSettingList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptSystemSettingFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptSystemSettingFromResponse(data: TSystemSettingResponse): TSystemSetting {
	const {
		public_id: publicId,
		name,
		description,
		value,
		created_at: createdAt,
		updated_at: updatedAt,
		type: typeRaw,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const type = typeRaw ? adaptSystemSettingTypeFromResponse(typeRaw) : undefined;
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		description,
		value,
		createdAt,
		updatedAt,
		type,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingToRequest(data: TSystemSettingBodyRequest): TSystemSettingRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
