import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingReportsWritingDelayPenalty = TSystemSettingValueCommon & {
  value?: number;
};

export type TSystemSettingReportsWritingDelayPenaltyResponse = TSystemSettingValueCommonResponse & {
  value?: number;
};

export type TSystemSettingReportsWritingDelayPenaltyPayload = {
	value?: number;
};

export type TSystemSettingReportsWritingDelayPenaltyRequest = {
	value?: number;
};

export function adaptSystemSettingReportsWritingDelayPenaltyFromResponse(data: TSystemSettingReportsWritingDelayPenaltyResponse): TSystemSettingReportsWritingDelayPenalty {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingReportsWritingDelayPenaltyToRequest(data: TSystemSettingReportsWritingDelayPenaltyPayload): TSystemSettingReportsWritingDelayPenaltyRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
