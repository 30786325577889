import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TEquipmentOrder, adaptEquipmentOrderFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS } from '../../backend-paths';


export const equipmentOrderDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptEquipmentOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderDeleteSlice = createSlice({
	name: 'equipmentOrderDeleteSlice',
	initialState,
	reducers: {
		clearEquipmentOrderDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentOrderDeleteApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentOrderDeleteSelector = () => useAppSelector(state => state[equipmentOrderDeleteSlice.name]);

export const { clearEquipmentOrderDelete } = equipmentOrderDeleteSlice.actions;
