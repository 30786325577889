import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectsClientsPage = lazy(() => import('../views/list/ProjectsClientsPage'));

export function ProjectsClientsPageLazy() {
	return (
		<PageSuspense>
			<ProjectsClientsPage />
		</PageSuspense>
	);
}
