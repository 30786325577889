import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountShiftsSortCol, ShiftReportListStatus, TAccountShiftList, TAccountShiftListResponse, TAccountShiftQuery, TAccountShiftsSortCol, TAccountShiftsStatus, adaptAccountShiftFromListResponse, adaptAccountShiftsQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftsGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_SHIFTS,
	(payload: TAccountShiftQuery, thunkApi) => {
		const params = payload ? adaptAccountShiftsQueryRequest(payload) : {};

		return HttpService.get<TAccountShiftListResponse>(BACKEND_ACCOUNT_SHIFTS, { params }).then(result => {
			return adaptAccountShiftFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TAccountShiftList;
	query: TAccountShiftQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.ASC,
		sortCol: AccountShiftsSortCol.CREATED_AT,
		types: [
			ShiftReportListStatus.NONE,
			ShiftReportListStatus.CREATED,
		],
	},
};

export const accountShiftsSlice = createSlice({
	name: 'accountShiftsSlice',
	initialState,
	reducers: {
		setAccountShiftsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountShiftsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountShiftsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountShiftsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountShiftsQuerySortCol(state, action: PayloadAction<TAccountShiftsSortCol>) {
			state.query.sortCol = action.payload;
		},

		setAccountShiftsQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountShiftsQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		setAccountShiftsQueryTypes(state, action: PayloadAction<TAccountShiftsStatus[]>) {
			state.query.types = [...action.payload];
		},

		clearAccountShifts(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountShiftsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftsGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountShiftsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftsSelector = () => useAppSelector(state => state[accountShiftsSlice.name]);

export const { clearAccountShifts, setAccountShiftsQueryLimit, setAccountShiftsQueryOffset, setAccountShiftsQuerySort, setAccountShiftsQuerySortCol, setAccountShiftsQuerySearch, setAccountShiftsQueryDateStart, setAccountShiftsQueryDateEnd, setAccountShiftsQueryTypes } = accountShiftsSlice.actions;
