import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountInternationalPassport, TAccountInternationalPassportResponse, adaptAccountInternationalPassportFromResponse } from '../../types';
import { BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT } from '../../backend-paths';


export const accountInternationalPassportReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountInternationalPassportResponse>(BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT).then(result => {
			return adaptAccountInternationalPassportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountInternationalPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountInternationalPassportReadSlice = createSlice({
	name: 'accountInternationalPassportReadSlice',
	initialState,
	reducers: {
		setAccountInternationalPassportRead(state, action: PayloadAction<TAccountInternationalPassport>) {
			state.item = action.payload;
		},

		clearAccountInternationalPassportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountInternationalPassportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountInternationalPassportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountInternationalPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountInternationalPassportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountInternationalPassportReadSelector = () => useAppSelector(state => state[accountInternationalPassportReadSlice.name]);

export const { clearAccountInternationalPassportRead, setAccountInternationalPassportRead } = accountInternationalPassportReadSlice.actions;
