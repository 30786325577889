import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroupShiftExpense, TProjectOrderGroupShiftExpensePayloadUpdate, TProjectOrderGroupShiftExpenseResponse, adaptProjectOrderGroupShiftExpenseFromResponse, adaptProjectOrderGroupShiftExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES } from '../../backend-paths';


export const projectOrderGroupShiftExpenseUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES}/:id`,
	(payload: TProjectOrderGroupShiftExpensePayloadUpdate, thunkApi) => {
		const { id, data, files } = payload;
		const path = `${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES}/${id}`;

		const dataRequest = adaptProjectOrderGroupShiftExpenseToRequest(data);

		return HttpService.put<TProjectOrderGroupShiftExpenseResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TProjectOrderGroupShiftExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptProjectOrderGroupShiftExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftExpenseUpdateSlice = createSlice({
	name: 'projectOrderGroupShiftExpenseUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupShiftExpenseUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftExpenseUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftExpenseUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftExpenseUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftExpenseUpdateSelector = () => useAppSelector(state => state[projectOrderGroupShiftExpenseUpdateSlice.name]);

export const { clearProjectOrderGroupShiftExpenseUpdate } = projectOrderGroupShiftExpenseUpdateSlice.actions;
