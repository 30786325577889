import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserExpense, TUserExpensePayloadUpdate, TUserExpenseResponse, adaptUserExpenseFromResponse, adaptUserExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_EXPENSES } from '../../backend-paths';


export const userExpenseUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_USERS_EXPENSES}/:id`,
	(payload: TUserExpensePayloadUpdate, thunkApi) => {
		const { id, data, files } = payload;
		const path = `${BACKEND_USERS_EXPENSES}/${id}`;

		const dataRequest = adaptUserExpenseToRequest(data);

		return HttpService.put<TUserExpenseResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseUpdateSlice = createSlice({
	name: 'userExpenseUpdateSlice',
	initialState,
	reducers: {
		clearUserExpenseUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userExpenseUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserExpenseUpdateSelector = () => useAppSelector(state => state[userExpenseUpdateSlice.name]);

export const { clearUserExpenseUpdate } = userExpenseUpdateSlice.actions;
