import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserPassport, TUserPassportResponse, adaptUserPassportFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPassportReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/passport`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/passport`;

		return HttpService.get<TUserPassportResponse>(path).then(result => {
			return adaptUserPassportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPassportReadSlice = createSlice({
	name: 'userPassportReadSlice',
	initialState,
	reducers: {
		setUserPassportRead(state, action: PayloadAction<TUserPassport>) {
			state.item = action.payload;
		},

		clearUserPassportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPassportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPassportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPassportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPassportReadSelector = () => useAppSelector(state => state[userPassportReadSlice.name]);

export const { clearUserPassportRead, setUserPassportRead } = userPassportReadSlice.actions;
