import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { EmptyLayout } from 'src/layouts';

import styles from './RecoveryLayer.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function RecoveryLayer(props: TProps) {
	const { children } = props;

	return (
		<EmptyLayout>
			<div className={ styles.recoveryPage }>
				<div className={ styles.logo }>
					<img className={ styles.image } src="/images/logo.svg" alt="" />
					<div className={ styles.headline }>Восстановление аккаунта</div>
				</div>

				<div className={ styles.container }>
					{ children }
				</div>
			</div>
		</EmptyLayout>
	);
}

