import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { getAgeHelper } from 'src/common/helpers';

import styles from './PublicCvAge.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	sign?: string;
	sex?: string;
	date: string;
};

export function PublicCvAge(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		date,
		sex,
		sign
	} = props;

	const age = getAgeHelper(date, ['год', 'года', 'лет']);
	const classNames = [styles.box, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	if (sex) {
		if (sign) {
			classNames.push(styles[sign]);
		}

		return (
			<div className={ classNames.join(' ') }>
				{ sex }, { age }
			</div>
		);
	}

	return (
		<div className={ styles.box }>
			{ age }
		</div>
	);
}
