import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserOverwork, TUserOverworkPayloadUpdate, TUserOverworkResponse, adaptUserOverworkFromResponse, adaptUserOverworkToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_OVERWORKS } from '../../backend-paths';


export const userOverworkUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_USERS_OVERWORKS}/:id`,
	(payload: TUserOverworkPayloadUpdate, thunkApi) => {
		const { id, data } = payload;
		const path = `${BACKEND_USERS_OVERWORKS}/${id}`;

		const dataRequest = adaptUserOverworkToRequest(data);

		return HttpService.put<TUserOverworkResponse>(path, dataRequest).then(result => {
			return adaptUserOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userOverworkUpdateSlice = createSlice({
	name: 'userOverworkUpdateSlice',
	initialState,
	reducers: {
		clearUserOverworkUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userOverworkUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userOverworkUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userOverworkUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserOverworkUpdateSelector = () => useAppSelector(state => state[userOverworkUpdateSlice.name]);

export const { clearUserOverworkUpdate } = userOverworkUpdateSlice.actions;
