import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserDepartmentChange, TUserDepartmentChangeResponse, adaptUserDepartmentChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDepartmentChangeCurrentReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/department`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/department`;

		return HttpService.get<TUserDepartmentChangeResponse>(path).then(result => {
			return adaptUserDepartmentChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserDepartmentChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDepartmentChangeCurrentReadSlice = createSlice({
	name: 'userDepartmentChangeCurrentReadSlice',
	initialState,
	reducers: {
		setUserDepartmentChangeCurrentRead(state, action: PayloadAction<TUserDepartmentChange>) {
			state.item = action.payload;
		},

		clearUserDepartmentChangeCurrentRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDepartmentChangeCurrentReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDepartmentChangeCurrentReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartmentChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDepartmentChangeCurrentReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDepartmentChangeCurrentReadSelector = () => useAppSelector(state => state[userDepartmentChangeCurrentReadSlice.name]);

export const { clearUserDepartmentChangeCurrentRead, setUserDepartmentChangeCurrentRead } = userDepartmentChangeCurrentReadSlice.actions;
