import { ChangeEvent, DetailedHTMLProps, HTMLAttributes } from 'react';

import { Form, FormInput, FormSelect } from 'src/components';

import styles from './TableControl.module.scss';


type TOptionValue = number | string;

type TOptionData = {
	value: string;
};

export type TTableControl = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	limit?: number;
	setLimit?: (value: number) => void;
	search?: string;
	searchPlaceholder?: string;
	searchTitle?: string;
	setSearch?: (value: string) => void;
	onSubmit?: () => void;
	isLoading?: boolean;
	options?: TOptionData[] | TOptionValue[];
};

const defaultOptions = [
	{ value: '1' },
	{ value: '2' },
	{ value: '3' },
	{ value: '4' },
	{ value: '5' },
	{ value: '10' },
	{ value: '25' },
	{ value: '50' },
	{ value: '100' },
];

export function TableControl(props: TTableControl) {
	const {
		limit,
		setLimit,
		search = '',
		setSearch,
		searchPlaceholder = 'Поиск',
		searchTitle = 'Введите текст для поиска',
		onSubmit,
		isLoading = false,
		options = defaultOptions,
	} = props;

	const limitInput = {
		value: limit,
		onChange: setLimit ? (e: ChangeEvent<HTMLSelectElement>) => setLimit(parseInt(e.target.value)) : undefined,
	};

	const searchInput = {
		value: search,
		onChange: setSearch ? (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value) : undefined,
	};

	return (
		<Form className={ styles.tableControl } onSubmit={ onSubmit }>
			{ setSearch &&
				<div className={ styles.col }>
					<FormInput
						placeholder={ searchPlaceholder }
						title={ searchTitle }
						{ ...searchInput }
					/>

					{/* <FormButton type="button" mini busy={ isLoading }>
						<Icon icon="icon-search"></Icon>
					</FormButton> */}
				</div>
			}

			{ limitInput &&
				<div className={ styles.col }>
					<FormSelect
						items={ options }
						{ ...limitInput }
						disabled={ isLoading }
					/>
				</div>
			}
		</Form>
	);
}
