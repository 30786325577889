import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserPositionUpdatePage = lazy(() => import('../views/update/UserPositionUpdatePage'));

export function UserPositionUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserPositionUpdatePage />
		</PageSuspense>
	);
}
