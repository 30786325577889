import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { libFormatPrice } from 'src/toolkit';

import { TEquipmentValue } from './EquipmentList';

import styles from './EquipmentList.module.scss';
import { FormInputFieldReadonlyFontColor } from 'src/components';


export type TEquipmentListItem = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	index: number;
	item: TEquipmentValue;
};

export function EquipmentListItem(props: TProps) {
	const {
		item,
		index,
		...restProps
	} = props;

	const { equipment, amount, fontColorName, fontColorAmount } = item;
	const { name, categories, publicId } = equipment;

	const classNames = [styles.item];

	const classNamesName = [styles.name];
	if (fontColorName) {
		if (fontColorName === FormInputFieldReadonlyFontColor.RED) {
			classNamesName.push(styles.colorRed);
		} else if (fontColorName === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesName.push(styles.colorGreen);
		}
	}

	const classNamesAmount = [styles.amount];
	if (fontColorAmount) {
		if (fontColorAmount === FormInputFieldReadonlyFontColor.RED) {
			classNamesAmount.push(styles.colorRed);
		} else if (fontColorAmount === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesAmount.push(styles.colorGreen);
		}
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			<div className={ styles.text }>
				<span className={ styles.index }>{ index }.</span>
				<span className={ styles.categories }>{ categories.join(' / ') } /</span>
				<span className={ classNamesName.join(' ') }>{ name }</span>
				<span className={ styles.categories }>•</span>
				<span className={ styles.publicId }>{ publicId }</span>
				<span className={ classNamesAmount.join(' ') }>({ libFormatPrice(amount) })</span>
			</div>
		</div>
	);
}
