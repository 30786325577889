import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserTaxationData, TUserTaxationDataPayload, TUserTaxationDataResponse, adaptUserTaxationDataFromResponse, adaptUserTaxationDataToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userTaxationDataUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/taxation-data`,
	(payload: TUserTaxationDataPayload, thunkApi) => {
		const {
			nickname,
			data,
			files = [],
		} = payload;

		const path = `${BACKEND_ADMIN_USERS}/${nickname}/taxation-data`;

		const dataRequest = adaptUserTaxationDataToRequest(data);

		return HttpService.put<TUserTaxationDataResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserTaxationDataResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserTaxationDataFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxationData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationDataUpdateSlice = createSlice({
	name: 'userTaxationDataUpdateSlice',
	initialState,
	reducers: {
		clearUserTaxationDataUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationDataUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTaxationDataUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationDataUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTaxationDataUpdateSelector = () => useAppSelector(state => state[userTaxationDataUpdateSlice.name]);

export const { clearUserTaxationDataUpdate } = userTaxationDataUpdateSlice.actions;
