import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountSalaryReadPage = lazy(() => import('../views/read/AccountSalaryReadPage'));

export function AccountSalaryReadPageLazy() {
	return (
		<PageSuspense>
			<AccountSalaryReadPage />
		</PageSuspense>
	);
}
