import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountCategory, TAccountCategoryResponse, adaptAccountCategoryFromResponse } from '../../types';
import { BACKEND_ACCOUNT_CATEGORY } from '../../backend-paths';


export const accountCategoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CATEGORY}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountCategoryResponse>(BACKEND_ACCOUNT_CATEGORY).then(result => {
			return adaptAccountCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCategoryReadSlice = createSlice({
	name: 'accountCategoryReadSlice',
	initialState,
	reducers: {
		setAccountCategoryRead(state, action: PayloadAction<TAccountCategory>) {
			state.item = action.payload;
		},

		clearAccountCategoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCategoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCategoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCategoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountCategoryReadSelector = () => useAppSelector(state => state[accountCategoryReadSlice.name]);

export const { clearAccountCategoryRead, setAccountCategoryRead } = accountCategoryReadSlice.actions;
