import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingUsersAccountUserTypeUpdatePage = lazy(() => import('../views/update-account-user-type/SystemSettingUsersAccountUserTypeUpdatePage'));

export function SystemSettingUsersAccountUserTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<SystemSettingUsersAccountUserTypeUpdatePage />
		</PageSuspense>
	);
}
