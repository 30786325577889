import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserCategory, TUserCategoryRequest, TUserCategoryResponse, adaptUserCategoryFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_CATEGORIES } from '../../backend-paths';


export const userCategoryCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_CATEGORIES}`,
	(payload: TUserCategoryRequest, thunkApi) => {
		return HttpService.post<TUserCategoryResponse>(BACKEND_ADMIN_USERS_CATEGORIES, payload).then(result => {
			return adaptUserCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryCreateSlice = createSlice({
	name: 'userCategoryCreateSlice',
	initialState,
	reducers: {
		clearUserCategoryCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userCategoryCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserCategoryCreateSelector = () => useAppSelector(state => state[userCategoryCreateSlice.name]);

export const { clearUserCategoryCreate } = userCategoryCreateSlice.actions;
