import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountTaxationReceipt, TAccountTaxationReceiptResponse, adaptAccountTaxationReceiptFromResponse } from '../../types';
import { BACKEND_ACCOUNT_TAXATION_RECEIPTS } from '../../backend-paths';


export const accountTaxationReceiptReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ACCOUNT_TAXATION_RECEIPTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_TAXATION_RECEIPTS}/${payload}`;

		return HttpService.get<TAccountTaxationReceiptResponse>(path).then(result => {
			return adaptAccountTaxationReceiptFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTaxationReceipt> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTaxationReceiptReadSlice = createSlice({
	name: 'accountTaxationReceiptReadSlice',
	initialState,
	reducers: {
		setAccountTaxationReceiptRead(state, action: PayloadAction<TAccountTaxationReceipt>) {
			state.item = action.payload;
		},

		clearAccountTaxationReceiptRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTaxationReceiptReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountTaxationReceiptReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxationReceipt>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTaxationReceiptReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountTaxationReceiptReadSelector = () => useAppSelector(state => state[accountTaxationReceiptReadSlice.name]);

export const { clearAccountTaxationReceiptRead, setAccountTaxationReceiptRead } = accountTaxationReceiptReadSlice.actions;
