import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserCategory, TUserCategoryUpdate, TUserCategoryResponse, adaptUserCategoryFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_CATEGORIES } from '../../backend-paths';


export const userCategoryUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_CATEGORIES}/:id`,
	(payload: TUserCategoryUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_CATEGORIES}/${id}`;

		return HttpService.put<TUserCategoryResponse>(path, data).then(result => {
			return adaptUserCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryUpdateSlice = createSlice({
	name: 'userCategoryUpdateSlice',
	initialState,
	reducers: {
		clearUserCategoryUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryUpdateSelector = () => useAppSelector(state => state[userCategoryUpdateSlice.name]);

export const { clearUserCategoryUpdate } = userCategoryUpdateSlice.actions;
