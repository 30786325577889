import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TArticle, TArticleBodyRequest, TArticleResponse, adaptArticleFromResponse, adaptArticleToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ARTICLES } from '../../backend-paths';


export const articleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ARTICLES}`,
	(payload: TArticleBodyRequest, thunkApi) => {
		const dataRequest = adaptArticleToRequest(payload);

		return HttpService.post<TArticleResponse>(BACKEND_ARTICLES, dataRequest).then(result => {
			return adaptArticleFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TArticle> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const articleCreateSlice = createSlice({
	name: 'articleCreateSlice',
	initialState,
	reducers: {
		clearArticleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[articleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[articleCreateApi.fulfilled.type]: (state, action: PayloadAction<TArticle>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[articleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useArticleCreateSelector = () => useAppSelector(state => state[articleCreateSlice.name]);

export const { clearArticleCreate } = articleCreateSlice.actions;
