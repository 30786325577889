import { libPad } from 'src/toolkit';
import { TProductionCalendar, TProductionCalendarResponse, adaptProductionCalendarFromResponse } from './production-calendar.type';


export type TProductionCalendarDatesOff = TProductionCalendar & {
	datesOff: number[][];
};

export type TProductionCalendarDatesOffResponse = TProductionCalendarResponse & {
	dates_off: number[][];
};

export type TProductionCalendarDatesOffBodyRequest = {
	datesOff: number[][];
};

export type TProductionCalendarDatesOffRequest = {
	items: string[];
};

export type TProductionCalendarDatesOffUpdate = {
	id: number;
	data: TProductionCalendarDatesOffBodyRequest;
};

export function adaptProductionCalendarDatesOffFromResponse(data: TProductionCalendarDatesOffResponse): TProductionCalendarDatesOff {
	const {
		dates_off: datesOff,
		...restFields
	} = data;

	const item = adaptProductionCalendarFromResponse(restFields);

	return {
		...item,
		datesOff,
	};
}

export function adaptProductionCalendarDatesOffToRequest(data: TProductionCalendarDatesOffBodyRequest, yearNumber: number): TProductionCalendarDatesOffRequest {
	const { datesOff } = data;
	const items: string[] = [];

	datesOff.forEach((monthItems, index) => {
		const month = libPad(index + 1);

		monthItems.forEach(item => {
			items.push(`${yearNumber}-${month}-${libPad(item)}`);
		});
	});

	return { items };
}
