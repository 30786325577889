import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TPaymentTypeSourceList, TPaymentTypeSourceListResponse, TPaymentTypeSourceQuery, adaptPaymentTypeSourceFromListResponse, adaptEquipmentsOrdersStatusesQueryToRequest } from '../../types';
import { BACKEND_SOURCE_PAYMENTS_TYPES } from '../../backend-paths';


export const sourcePaymentTypeListGetApi = createAsyncThunk(
	BACKEND_SOURCE_PAYMENTS_TYPES,
	(payload: TPaymentTypeSourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsOrdersStatusesQueryToRequest(payload);

		return HttpService.get<TPaymentTypeSourceListResponse>(BACKEND_SOURCE_PAYMENTS_TYPES, { params }).then(result => {
			return adaptPaymentTypeSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TPaymentTypeSourceList;
	query: TPaymentTypeSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourcePaymentTypeListSlice = createSlice({
	name: 'sourcePaymentTypeListSlice',
	initialState,
	reducers: {
		setSourcePaymentTypeQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourcePaymentTypeList(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourcePaymentTypeListGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourcePaymentTypeListGetApi.fulfilled.type]: (state, action: PayloadAction<TPaymentTypeSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourcePaymentTypeListGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourcePaymentTypeListSelector = () => useAppSelector(state => state[sourcePaymentTypeListSlice.name]);

export const { clearSourcePaymentTypeList, setSourcePaymentTypeQuerySearch } = sourcePaymentTypeListSlice.actions;
