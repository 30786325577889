import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountShiftReportUpdatePage = lazy(() => import('../views/update/AccountShiftReportUpdatePage'));

export function AccountShiftReportUpdatePageLazy() {
	return (
		<PageSuspense>
			<AccountShiftReportUpdatePage />
		</PageSuspense>
	);
}
