import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { SystemSettingValuesSortCol, TSystemSettingValueList, TSystemSettingValueListResponse, TSystemSettingValueQuery, TSystemSettingValuesSortCol, adaptSystemSettingValueFromListResponse, adaptSystemSettingValuesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_USERS } from '../../backend-paths';


export const systemSettingUsersGetApi = createAsyncThunk(
	BACKEND_ADMIN_SYSTEM_SETTINGS_USERS,
	(payload: TSystemSettingValueQuery | undefined, thunkApi) => {
		const params = adaptSystemSettingValuesQueryToRequest(payload);

		return HttpService.get<TSystemSettingValueListResponse>(BACKEND_ADMIN_SYSTEM_SETTINGS_USERS, { params }).then(result => {
			return adaptSystemSettingValueFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TSystemSettingValueList;
	query: TSystemSettingValueQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: SystemSettingValuesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const systemSettingUsersSlice = createSlice({
	name: 'systemSettingUsersSlice',
	initialState,
	reducers: {
		setSystemSettingUsersQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setSystemSettingUsersQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setSystemSettingUsersQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setSystemSettingUsersQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setSystemSettingUsersQuerySortCol(state, action: PayloadAction<TSystemSettingValuesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearSystemSettingUsers(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[systemSettingUsersGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingUsersGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingValueList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[systemSettingUsersGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingUsersSelector = () => useAppSelector(state => state[systemSettingUsersSlice.name]);

export const { clearSystemSettingUsers, setSystemSettingUsersQueryLimit, setSystemSettingUsersQueryOffset, setSystemSettingUsersQuerySort, setSystemSettingUsersQuerySortCol, setSystemSettingUsersQuerySearch } = systemSettingUsersSlice.actions;
