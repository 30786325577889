import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TArticle, adaptArticleFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ARTICLES } from '../../backend-paths';


export const articleDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ARTICLES}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ARTICLES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptArticleFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TArticle> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const articleDeleteSlice = createSlice({
	name: 'articleDeleteSlice',
	initialState,
	reducers: {
		clearArticleDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[articleDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[articleDeleteApi.fulfilled.type]: (state, action: PayloadAction<TArticle>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[articleDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useArticleDeleteSelector = () => useAppSelector(state => state[articleDeleteSlice.name]);

export const { clearArticleDelete } = articleDeleteSlice.actions;
