import { KeyboardEvent, useEffect } from 'react';
import { FormField, FormInput } from 'src/components';
import { TInputField, libHandlePriceFloat } from 'src/toolkit';


export type TFormInputPriceFloatField = TInputField & {
	legend?: string;
	placeholder?: string;
	title?: string;
	type?: string;

	isLoading?: boolean;
	readonly?: boolean;

	min?: string;
	max?: string;

	replacedFromChar?: string;
	replacedToChar?: string;
};

export function FormInputPriceFloatField(props: TFormInputPriceFloatField) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,

		readonly = false,

		legend = '',
		placeholder = '',
		title = '',

		replacedFromChar = ',',
		replacedToChar = '.',
	} = props;

	useEffect(() => {
		onChange(value);
	}, []);

	const onChange = (value: string) => {
		const formatted = libHandlePriceFloat(value);

		setValue(formatted);
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === replacedFromChar) {
			setValue(value + replacedToChar);
		}
	};

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
		>
			<FormInput
				placeholder={ placeholder }
				name={ name }
				title={ title }
				value={ value }
				onKeyDown={ onKeyDown }
				onChange={ e => onChange(e.target.value) }
				disabled={ isLoading }
				readOnly={ readonly }
			/>
		</FormField>
	);
}
