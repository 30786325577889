import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { EquipmentsGroupsHistorySortCol, TEquipmentGroupHistoryList, TEquipmentGroupHistoryListResponse, TEquipmentGroupHistoryQuery, TEquipmentGroupHistoryQueryPayload, TEquipmentsGroupsHistorySortCol, adaptEquipmentGroupHistoryFromListResponse, adaptEquipmentsGroupsHistoryQueryToRequest } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_GROUPS } from '../../backend-paths';


export const equipmentsGroupsHistoryGetApi = createAsyncThunk(
	`${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/:group_id/history`,
	(payload: TEquipmentGroupHistoryQueryPayload, thunkApi) => {
		const { groupId, query } = payload;
		const params = adaptEquipmentsGroupsHistoryQueryToRequest(query);
		const path = `${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/${groupId}/history`;

		return HttpService.get<TEquipmentGroupHistoryListResponse>(path, { params }).then(result => {
			return adaptEquipmentGroupHistoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentGroupHistoryList;
	query: TEquipmentGroupHistoryQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.DESC,
		sortCol: EquipmentsGroupsHistorySortCol.CREATED_AT,
	},
	error: undefined,
};

export const equipmentsGroupsHistorySlice = createSlice({
	name: 'equipmentsGroupsHistorySlice',
	initialState,
	reducers: {
		setEquipmentsGroupsHistoryQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setEquipmentsGroupsHistoryQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setEquipmentsGroupsHistoryQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setEquipmentsGroupsHistoryQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setEquipmentsGroupsHistoryQuerySortCol(state, action: PayloadAction<TEquipmentsGroupsHistorySortCol>) {
			state.query.sortCol = action.payload;
		},

		clearEquipmentsGroupsHistory(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[equipmentsGroupsHistoryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsGroupsHistoryGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentGroupHistoryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[equipmentsGroupsHistoryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsGroupsHistorySelector = () => useAppSelector(state => state[equipmentsGroupsHistorySlice.name]);

export const { clearEquipmentsGroupsHistory, setEquipmentsGroupsHistoryQueryLimit, setEquipmentsGroupsHistoryQueryOffset, setEquipmentsGroupsHistoryQuerySort, setEquipmentsGroupsHistoryQuerySortCol, setEquipmentsGroupsHistoryQuerySearch } = equipmentsGroupsHistorySlice.actions;
