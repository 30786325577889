import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TScheduleEventTypeSource, TUserSchedulePayload, adaptScheduleEventTypeSourceFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_SCHEDULE } from '../../backend-paths';


export const userScheduleDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_SCHEDULE}/:user_nickname/:date`,
	(payload: TUserSchedulePayload, thunkApi) => {
		const { userNickname, date } = payload;
		const path = `${BACKEND_SCHEDULE}/${userNickname}/${date}`;

		return HttpService.delete(path).then(result => {
			return adaptScheduleEventTypeSourceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEventTypeSource> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userScheduleDeleteSlice = createSlice({
	name: 'userScheduleDeleteSlice',
	initialState,
	reducers: {
		clearUserScheduleDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userScheduleDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userScheduleDeleteApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventTypeSource>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userScheduleDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserScheduleDeleteSelector = () => useAppSelector(state => state[userScheduleDeleteSlice.name]);

export const { clearUserScheduleDelete } = userScheduleDeleteSlice.actions;
