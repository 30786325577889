import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentOrderStatusUpdatePage = lazy(() => import('../views/update/EquipmentOrderStatusUpdatePage'));

export function EquipmentOrderStatusUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentOrderStatusUpdatePage />
		</PageSuspense>
	);
}
