import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const EquipmentsOrdersStatusesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsOrdersStatusesSortCol = TTableSortCols<typeof EquipmentsOrdersStatusesSortCol>;

export type TEquipmentOrderStatusQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsOrdersStatusesSortCol;
};

export type TEquipmentsOrdersStatusesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsOrdersStatusesQueryToRequest(data?: TEquipmentOrderStatusQuery): TEquipmentsOrdersStatusesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentOrderStatus = {
  id: number;
  name: string;
  description?: string;
  isBlockEquipments?: boolean;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentOrderStatusResponse = {
  id: number;
  name: string;
  description?: string;
  is_block_equipments?: boolean;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentOrderStatusListResponse = TListResponse<TEquipmentOrderStatusResponse>;

export type TEquipmentOrderStatusList = TListResponse<TEquipmentOrderStatus>;

export type TEquipmentOrderStatusBodyRequest = {
	name: string;
	description?: string;
  isBlockEquipments?: boolean;
};

export type TEquipmentOrderStatusRequest = {
	name: string;
	description?: string;
  is_block_equipments?: boolean;
};

export type TEquipmentOrderStatusUpdate = {
	id: number;
	data: TEquipmentOrderStatusBodyRequest;
};

export function adaptEquipmentOrderStatusFromListResponse(data: TEquipmentOrderStatusListResponse): TEquipmentOrderStatusList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentOrderStatusFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentOrderStatusFromResponse(data: TEquipmentOrderStatusResponse): TEquipmentOrderStatus {
	const {
		id,
		name,
		description,
		is_block_equipments: isBlockEquipments,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		isBlockEquipments,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentOrderStatusToRequest(data: TEquipmentOrderStatusBodyRequest): TEquipmentOrderStatusRequest {
	const {
		name,
		description = undefined,
		isBlockEquipments = undefined,
	} = data;

	return {
		name,
		description,
		is_block_equipments: isBlockEquipments,
	};
}
