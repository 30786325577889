import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Preloader } from 'src/components/preloader';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function PagePreloader(props: TProps) {
	return (
		<div>
			<Preloader />
		</div>
	);
}
