import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserCategory, adaptUserCategoryFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_CATEGORIES } from '../../backend-paths';


export const userCategoryDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS_CATEGORIES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_CATEGORIES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptUserCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryDeleteSlice = createSlice({
	name: 'userCategoryDeleteSlice',
	initialState,
	reducers: {
		clearUserCategoryDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryDeleteSelector = () => useAppSelector(state => state[userCategoryDeleteSlice.name]);

export const { clearUserCategoryDelete } = userCategoryDeleteSlice.actions;
