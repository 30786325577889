import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProductionCalendarUpdatePage = lazy(() => import('../views/update/ProductionCalendarUpdatePage'));

export function ProductionCalendarUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProductionCalendarUpdatePage />
		</PageSuspense>
	);
}
