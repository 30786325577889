import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingShiftsNotCountingOverworkTime, TSystemSettingShiftsNotCountingOverworkTimePayload, TSystemSettingShiftsNotCountingOverworkTimeResponse, adaptSystemSettingShiftsNotCountingOverworkTimeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME } from '../../backend-paths';


export const systemSettingShiftsNotCountingOverworkTimeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME}`,
	(payload: TSystemSettingShiftsNotCountingOverworkTimePayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME;

		return HttpService.put<TSystemSettingShiftsNotCountingOverworkTimeResponse>(path, payload).then(result => {
			return adaptSystemSettingShiftsNotCountingOverworkTimeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingShiftsNotCountingOverworkTime> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingShiftsNotCountingOverworkTimeUpdateSlice = createSlice({
	name: 'systemSettingShiftsNotCountingOverworkTimeUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingShiftsNotCountingOverworkTimeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingShiftsNotCountingOverworkTimeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingShiftsNotCountingOverworkTimeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingShiftsNotCountingOverworkTime>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingShiftsNotCountingOverworkTimeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingShiftsNotCountingOverworkTimeUpdateSelector = () => useAppSelector(state => state[systemSettingShiftsNotCountingOverworkTimeUpdateSlice.name]);

export const { clearSystemSettingShiftsNotCountingOverworkTimeUpdate } = systemSettingShiftsNotCountingOverworkTimeUpdateSlice.actions;
