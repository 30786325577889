import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const UsersMetaSortCol = {
	NICKNAME: 'nickname',
	LAST_NAME: 'last_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersMetaSortCol = TTableSortCols<typeof UsersMetaSortCol>;

export type TUsersMetaQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersMetaSortCol;
};

export type TUsersMetaQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersMetaQueryToRequest(data?: TUsersMetaQuery): TUsersMetaQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserMetaListResponse = TListResponse<TUserMetaResponse>;

export type TUserMetaList = TListResponse<TUserMeta>;

export type TUserMeta = {
  nickname: string;
	lastName: string;
	firstName: string;
  thirdName: string;
};

export type TUserMetaBodyRequest = {
  nickname?: string;
	lastName?: string;
	firstName?: string;
  thirdName?: string;
};

export type TUserMetaResponse = {
  nickname: string;
	last_name: string;
	first_name: string;
  third_name: string;
};

export function adaptUserMetaFromListResponse(data: TUserMetaListResponse): TUserMetaList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserMetaFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserMetaUndefinedFromResponse(data: TUserMetaResponse | undefined): TUserMeta | undefined {
	if (!data) {
		return undefined;
	}

	return adaptUserMetaFromResponse(data);
}

export function adaptUserMetaFromResponse(data: TUserMetaResponse): TUserMeta {
	const {
		nickname = '',
		last_name: lastName = '',
		first_name: firstName = '',
		third_name: thirdName = '',
	} = data;

	return {
		nickname,
		lastName,
		firstName,
		thirdName,
	};
}

