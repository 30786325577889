import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const EquipmentsStoragesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsStoragesSourceSortCol = TTableSortCols<typeof EquipmentsStoragesSourceSortCol>;

export type TEquipmentStorageSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsStoragesSourceSortCol;
};

export type TEquipmentsStoragesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsStoragesSourceQueryToRequest(data?: TEquipmentStorageSourceQuery): TEquipmentsStoragesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentStorageSource = {
  id: number;
  name: string;
};

export type TEquipmentStorageSourceResponse = {
  id: number;
  name: string;
};

export type TEquipmentStorageSourceListResponse = TListResponse<TEquipmentStorageSourceResponse>;

export type TEquipmentStorageSourceList = TListResponse<TEquipmentStorageSource>;

export function adaptEquipmentStorageSourceFromListResponse(data: TEquipmentStorageSourceListResponse): TEquipmentStorageSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentStorageSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentStorageSourceFromResponse(data: TEquipmentStorageSourceResponse): TEquipmentStorageSource {
	const { id, name } = data;

	return { id, name };
}
