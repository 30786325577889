import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TProjectClientContact, TProjectClientContactPayloadParams, TProjectClientContactResponse, adaptProjectClientContactFromResponse } from '../../types';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientContactReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS_CLIENTS}/:client_id/contacts/:id`,
	(payload: TProjectClientContactPayloadParams, thunkApi) => {
		const { id, clientId } = payload;
		const path = `${BACKEND_PROJECTS_CLIENTS}/${clientId}/contacts/${id}`;

		return HttpService.get<TProjectClientContactResponse>(path).then(result => {
			return adaptProjectClientContactFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TProjectClientContact | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientContactReadSlice = createSlice({
	name: 'projectClientContactReadSlice',
	initialState,
	reducers: {
		setProjectClientContactRead(state, action: PayloadAction<TProjectClientContact>) {
			state.item = action.payload;
		},

		clearProjectClientContactRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientContactReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectClientContactReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientContact>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientContactReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectClientContactReadSelector = () => useAppSelector(state => state[projectClientContactReadSlice.name]);

export const { clearProjectClientContactRead, setProjectClientContactRead } = projectClientContactReadSlice.actions;
