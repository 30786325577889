import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserTaxationReceipt, TUserTaxationReceiptPayloadCreate, TUserTaxationReceiptResponse, adaptUserTaxationReceiptFromResponse, adaptUserTaxationReceiptToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_TAXATIONS_RECEIPTS } from '../../backend-paths';


export const userTaxationReceiptCreateApi = createAsyncThunk(
	`POST/${BACKEND_USERS_TAXATIONS_RECEIPTS}`,
	(payload: TUserTaxationReceiptPayloadCreate, thunkApi) => {
		const { data } = payload;

		const dataRequest = adaptUserTaxationReceiptToRequest(data);

		return HttpService.post<TUserTaxationReceiptResponse>(BACKEND_USERS_TAXATIONS_RECEIPTS, dataRequest).then(result => {
			return adaptUserTaxationReceiptFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxationReceipt> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationReceiptCreateSlice = createSlice({
	name: 'userTaxationReceiptCreateSlice',
	initialState,
	reducers: {
		clearUserTaxationReceiptCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationReceiptCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userTaxationReceiptCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationReceipt>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationReceiptCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserTaxationReceiptCreateSelector = () => useAppSelector(state => state[userTaxationReceiptCreateSlice.name]);

export const { clearUserTaxationReceiptCreate } = userTaxationReceiptCreateSlice.actions;
