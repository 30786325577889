import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserFinancialChange, TUserFinancialChangeResponse, adaptUserFinancialChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_FINANCIAL_CHANGES } from '../../backend-paths';


export const userFinancialChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/${payload}`;

		return HttpService.get<TUserFinancialChangeResponse>(path).then(result => {
			return adaptUserFinancialChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserFinancialChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userFinancialChangeReadSlice = createSlice({
	name: 'userFinancialChangeReadSlice',
	initialState,
	reducers: {
		setUserFinancialChangeRead(state, action: PayloadAction<TUserFinancialChange>) {
			state.item = action.payload;
		},

		clearUserFinancialChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userFinancialChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userFinancialChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserFinancialChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userFinancialChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserFinancialChangeReadSelector = () => useAppSelector(state => state[userFinancialChangeReadSlice.name]);

export const { clearUserFinancialChangeRead, setUserFinancialChangeRead } = userFinancialChangeReadSlice.actions;
