// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportContent_box__H8vOm {\n  margin: 1em auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/report-content/ReportContent.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD","sourcesContent":[".box {\n\tmargin: 1em auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "ReportContent_box__H8vOm"
};
export default ___CSS_LOADER_EXPORT___;
