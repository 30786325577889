import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentHistoryReadPage = lazy(() => import('../views/read/EquipmentHistoryReadPage'));

export function EquipmentHistoryReadPageLazy() {
	return (
		<PageSuspense>
			<EquipmentHistoryReadPage />
		</PageSuspense>
	);
}
