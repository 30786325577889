import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import styles from './Image.module.scss';


type TProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export function Image(props: TProps) {
	const {
		children,
		className = '',
		...restProps
	} = props;

	const classNames = [styles.img];

	if (className) {
		classNames.push(className);
	}

	return (
		<img className={ classNames.join(' ') } { ...restProps } />
	);
}
