import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const SystemSettingValuesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TSystemSettingValuesSortCol = TTableSortCols<typeof SystemSettingValuesSortCol>;

export type TSystemSettingValueQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TSystemSettingValuesSortCol;
};

export type TSystemSettingValuesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptSystemSettingValuesQueryToRequest(data?: TSystemSettingValueQuery): TSystemSettingValuesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TSystemSettingValue = {
  publicId: string;
  name: string;
  value?: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TSystemSettingValueCommon = Omit<TSystemSettingValue, 'value'>;

export type TSystemSettingValueResponse = {
  public_id: string;
  name: string;
  value?: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TSystemSettingValueCommonResponse = Omit<TSystemSettingValueResponse, 'value'>;


export type TSystemSettingValueListResponse = TListResponse<TSystemSettingValueResponse>;

export type TSystemSettingValueList = TListResponse<TSystemSettingValue>;

export type TSystemSettingValueBodyRequest = {
	value?: string;
};

export type TSystemSettingValueRequest = {
	value?: string;
};

export type TSystemSettingValueUpdate = {
	id: string;
	data: TSystemSettingValueBodyRequest;
};

export function adaptSystemSettingValueFromListResponse(data: TSystemSettingValueListResponse): TSystemSettingValueList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptSystemSettingValueFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptSystemSettingValueCommonFromResponse(data: TSystemSettingValueCommonResponse): TSystemSettingValueCommon {
	const {
		public_id: publicId,
		name,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingValueFromResponse(data: TSystemSettingValueResponse): TSystemSettingValue {
	const { value, ...restFields } = data;

	const commonFields = adaptSystemSettingValueCommonFromResponse(restFields);

	return {
		...commonFields,
		value,
	};
}

export function adaptSystemSettingValueToRequest(data: TSystemSettingValueBodyRequest): TSystemSettingValueRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
