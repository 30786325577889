import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ScheduleEventsPage = lazy(() => import('../views/list/ScheduleEventsPage'));

export function ScheduleEventsPageLazy() {
	return (
		<PageSuspense>
			<ScheduleEventsPage />
		</PageSuspense>
	);
}
