import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserRequisites, TUserRequisitesResponse, adaptUserRequisitesFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userRequisitesReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/requisites`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/requisites`;

		return HttpService.get<TUserRequisitesResponse>(path).then(result => {
			return adaptUserRequisitesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserRequisites> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userRequisitesReadSlice = createSlice({
	name: 'userRequisitesReadSlice',
	initialState,
	reducers: {
		setUserRequisitesRead(state, action: PayloadAction<TUserRequisites>) {
			state.item = action.payload;
		},

		clearUserRequisitesRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userRequisitesReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userRequisitesReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserRequisites>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userRequisitesReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserRequisitesReadSelector = () => useAppSelector(state => state[userRequisitesReadSlice.name]);

export const { clearUserRequisitesRead, setUserRequisitesRead } = userRequisitesReadSlice.actions;
