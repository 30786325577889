import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const UsersTypesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersTypesSortCol = TTableSortCols<typeof UsersTypesSortCol>;

export type TUserTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersTypesSortCol;
};

export type TUsersTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUserType = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

  permissions?: string[];
};

export type TUserTypeResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;

  permissions?: string[];
};

export type TUserTypeListResponse = TListResponse<TUserTypeResponse>;

export type TUserTypeList = TListResponse<TUserType>;

export type TUserTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TUserTypeRequest = {
	name: string;
	description?: string;
};

export type TUserTypeUpdate = {
	id: number;
	data: TUserTypeBodyRequest;
};

export function adaptUserTypeFromListResponse(data: TUserTypeListResponse): TUserTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserTypeFromResponse(data: TUserTypeResponse): TUserType {
	const {
		id,
		name,
		description,
		permissions,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		permissions,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserTypeToRequest(data: TUserTypeBodyRequest): TUserTypeRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
