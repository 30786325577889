import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserSalaryUpdatePage = lazy(() => import('../views/update/UserSalaryUpdatePage'));

export function UserSalaryUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserSalaryUpdatePage />
		</PageSuspense>
	);
}
