import { FormButtonLink, FormField, FormRow, Icon } from 'src/components';

import { FormSelectBoxFieldLogic, TFormSelectBoxFieldLogic } from './FormSelectBoxFieldLogic';


type TProps = TFormSelectBoxFieldLogic & {
	to?: string;
	icon?: string;
	buttonDisabled?: boolean;
	buttonTitle?: string;
	marginRight?: boolean;
};

export function FormSelectBoxField(props: TProps) {
	const {
		errors,
		required,
		legend,

		to,
		icon,
		buttonDisabled,
		buttonTitle,

		marginRight = false,
		width100 = false,
	} = props;

	if (icon && to) {
		return (
			<FormField
				legend={ legend }
				errors={ errors }
				required={ required }
				marginRight={ marginRight }
				width100={ width100 }
			>
				<FormRow>
					<FormSelectBoxFieldLogic { ...props } />

					<FormButtonLink
						mini
						type="button"
						disabled={ buttonDisabled }
						tabIndex={ -1 }
						to={ to }
						title={ buttonTitle }
					>
						<Icon icon={ icon } />
					</FormButtonLink>
				</FormRow>
			</FormField>
		);
	}

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			marginRight={ marginRight }
			width100={ width100 }
		>
			<FormSelectBoxFieldLogic { ...props } />
		</FormField>
	);
}
