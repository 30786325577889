import { useEffect } from 'react';

import { Link } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_SIGN_IN } from 'src/routes';
import { RecoveryPageStageState, clearRecoveryAction, useAppDispatch, useRecoverySelector } from 'src/store';

import { RecoveryStageSuccess, RecoveryLayer } from '../../components';

import { RecoveryStageEmailSuccess, RecoveryRouter } from './components';

import styles from './RecoveryPage.module.scss';


export function RecoveryPage() {
	const dispatch = useAppDispatch();

	const { stage, email } = useRecoverySelector();

	useEffect(() => {
		return () => {
			dispatch(clearRecoveryAction());
		};
	}, []);

	setTitle('Восстановление аккаунта');

	if (stage === RecoveryPageStageState.SUCCESS) {
		return (
			<RecoveryLayer>
				<RecoveryStageSuccess />
			</RecoveryLayer>
		);
	}

	if (stage === RecoveryPageStageState.EMAIL_SUCCESS) {
		return (
			<RecoveryLayer>
				<RecoveryStageEmailSuccess email={ email } />
			</RecoveryLayer>
		);
	}

	return (
		<RecoveryLayer>
			<RecoveryRouter />

			<p className={ styles.underText }>
				<span>Уже есть логин и пароль? </span>
				<Link to={ PATH_SIGN_IN }>Войти</Link>
			</p>
		</RecoveryLayer>
	);
}

