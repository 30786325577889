import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentCategory, TEquipmentCategoryUpdate, TEquipmentCategoryResponse, adaptEquipmentCategoryFromResponse, adaptEquipmentCategoryToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentCategoryUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/:id`,
	(payload: TEquipmentCategoryUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/${id}`;
		const dataRequest = adaptEquipmentCategoryToRequest(data);

		return HttpService.put<TEquipmentCategoryResponse>(path, dataRequest).then(result => {
			return adaptEquipmentCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentCategoryUpdateSlice = createSlice({
	name: 'equipmentCategoryUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentCategoryUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentCategoryUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentCategoryUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentCategoryUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentCategoryUpdateSelector = () => useAppSelector(state => state[equipmentCategoryUpdateSlice.name]);

export const { clearEquipmentCategoryUpdate } = equipmentCategoryUpdateSlice.actions;
