import { Link } from 'react-router-dom';

import { Icon } from 'src/components/icon';

import styles from './NavLogo.module.scss';


export function NavLogo() {
	return (
		<Link to="/" className={ styles.logo } >
			<Icon icon="icon-logo" className={ styles.icon } />
		</Link>
	);
}
