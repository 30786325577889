import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ProjectsOrdersTagsTypesSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersTagsTypesSortCol = TTableSortCols<typeof ProjectsOrdersTagsTypesSortCol>;

export type TProjectOrderTagTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersTagsTypesSortCol;
};

export type TProjectsOrdersTagsTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsOrdersTagsTypesQueryToRequest(data?: TProjectOrderTagTypeQuery): TProjectsOrdersTagsTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderTagType = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectOrderTagTypeResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectOrderTagTypeListResponse = TListResponse<TProjectOrderTagTypeResponse>;

export type TProjectOrderTagTypeList = TListResponse<TProjectOrderTagType>;

export type TProjectOrderTagTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TProjectOrderTagTypeRequest = {
	name: string;
	description?: string;
};

export type TProjectOrderTagTypeUpdate = {
	id: number;
	data: TProjectOrderTagTypeBodyRequest;
};

export function adaptProjectOrderTagTypeFromListResponse(data: TProjectOrderTagTypeListResponse): TProjectOrderTagTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderTagTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderTagTypeFromResponse(data: TProjectOrderTagTypeResponse): TProjectOrderTagType {
	const {
		id,
		name,
		description,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptProjectOrderTagTypeToRequest(data: TProjectOrderTagTypeBodyRequest): TProjectOrderTagTypeRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
