import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserExpenseType, TUserExpenseTypeBodyRequest, TUserExpenseTypeResponse, adaptUserExpenseTypeFromResponse, adaptUserExpenseTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_EXPENSES_TYPES } from '../../backend-paths';


export const userExpenseTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_EXPENSES_TYPES}`,
	(payload: TUserExpenseTypeBodyRequest, thunkApi) => {
		const dataRequest = adaptUserExpenseTypeToRequest(payload);

		return HttpService.post<TUserExpenseTypeResponse>(BACKEND_ADMIN_USERS_EXPENSES_TYPES, dataRequest).then(result => {
			return adaptUserExpenseTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpenseType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseTypeCreateSlice = createSlice({
	name: 'userExpenseTypeCreateSlice',
	initialState,
	reducers: {
		clearUserExpenseTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userExpenseTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserExpenseType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserExpenseTypeCreateSelector = () => useAppSelector(state => state[userExpenseTypeCreateSlice.name]);

export const { clearUserExpenseTypeCreate } = userExpenseTypeCreateSlice.actions;
