import { libClearIntstr } from 'src/toolkit';

export type TUserData = {
	trustedPhoneNumber: string;
  trustedName: string;
  carData: string;
  telegram: string;
  createdAt: string;
  updatedAt: string;
};

export type TUserDataBodyRequest = {
	trustedPhoneNumber?: string;
  trustedName?: string;
  carData?: string;
  telegram?: string;
};

export type TUserDataResponse = {
	trusted_phone_number: string;
  trusted_name: string;
  car_data: string;
  telegram: string;
  created_at: string;
  updated_at: string;
};

export type TUserDataRequest = {
	trusted_phone_number?: string;
  trusted_name?: string;
  car_data?: string;
  telegram?: string;
};

export type TUserDataPayload = {
	nickname: string;
	data: TUserDataBodyRequest;
};

export function adaptUserDataFromResponse(data: TUserDataResponse): TUserData {
	const {
		trusted_phone_number: trustedPhoneNumber = '',
		trusted_name: trustedName,
		car_data: carData,
		telegram,
		updated_at: createdAt,
		created_at: updatedAt,
	} = data;

	return {
		trustedPhoneNumber,
		trustedName,
		carData,
		telegram,
		createdAt,
		updatedAt,
	};
}

export function adaptUserDataToRequest(data: TUserDataBodyRequest): TUserDataRequest {
	const {
		trustedPhoneNumber,
		trustedName,
		carData,
		telegram,
	} = data;

	return {
		trusted_phone_number: libClearIntstr(trustedPhoneNumber),
		trusted_name: trustedName,
		car_data: carData,
		telegram,
	};
}

