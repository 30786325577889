import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserSalary, TUserSalaryParams, adaptUserSalaryFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userSalaryDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:user_nickname/salaries/:id`,
	(payload: TUserSalaryParams, thunkApi) => {
		const { userNickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/salaries/${id}`;

		return HttpService.delete(path).then(result => {
			return adaptUserSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserSalary> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userSalaryDeleteSlice = createSlice({
	name: 'userSalaryDeleteSlice',
	initialState,
	reducers: {
		clearUserSalaryDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userSalaryDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userSalaryDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userSalaryDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserSalaryDeleteSelector = () => useAppSelector(state => state[userSalaryDeleteSlice.name]);

export const { clearUserSalaryDelete } = userSalaryDeleteSlice.actions;
