import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './FormListBoxField.module.scss';
import { Icon } from 'src/components/icon';


export type TFormListBoxFieldItem = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	index: number;
};

export function FormListBoxFieldItem(props: TProps) {
	const {
		children,
		index,
		onClick = () => {},
		...restProps
	} = props;

	const classNames = [styles.item];

	return (

		<div className={ classNames.join(' ') } { ...restProps } >
			<div className={ styles.text }>{ index }. { children }</div>

			<Icon className={ styles.icon } onClick={ onClick } icon="icon-cancel"/>
		</div>
	);
}
