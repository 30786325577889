// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextBlock_text__1rFjR {\n  box-sizing: border-box;\n  color: rgb(150, 150, 150);\n  text-indent: 1em;\n  text-align: justify;\n  margin: 0.25em auto;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/text-block/TextBlock.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,yBAAA;EAEA,gBAAA;EACA,mBAAA;EAEA,mBAAA;EACA,WAAA;AADD","sourcesContent":[".text {\n\tbox-sizing: border-box;\n\tcolor: rgba(150, 150, 150, 1);\n\n\ttext-indent: 1em;\n\ttext-align: justify;\n\n\tmargin: 0.25em auto;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "TextBlock_text__1rFjR"
};
export default ___CSS_LOADER_EXPORT___;
