import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ArticlesPublicPage = lazy(() => import('../views/list/ArticlesPublicPage'));

export function ArticlesPublicPageLazy() {
	return (
		<PageSuspense>
			<ArticlesPublicPage />
		</PageSuspense>
	);
}
