import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { FormListBoxFieldItem, TFormListBoxFieldItem } from './FormListBoxFieldItem';

import styles from './FormListBoxField.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TFormListBoxFieldItem[];
	onDelete: (value: string) => void;
};

export function FormListBoxFieldWrap(props: TProps) {
	const {
		items = [],
		placeholder,
		onDelete,
		...restProps
	} = props;

	if (items.length <= 0) {
		return (
			<div className={ styles.empty } { ...restProps }>
				{ placeholder }
			</div>
		);
	}

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map((item, index) => {
					const { text, value } = item;

					return (
						<FormListBoxFieldItem
							key={ value }
							index={ index + 1 }
							onClick={ e => onDelete(value) }
						>{ text }</FormListBoxFieldItem>
					);
				})
			}
		</div>
	);
}
