import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountPositionChangesPage = lazy(() => import('../views/list/AccountPositionChangesPage'));

export function AccountPositionChangesPageLazy() {
	return (
		<PageSuspense>
			<AccountPositionChangesPage />
		</PageSuspense>
	);
}
