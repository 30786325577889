import { LinkProps, NavLink } from 'react-router-dom';

import { Icon } from 'src/components';

import styles from './NavItem.module.scss';


export type TNavItem = Omit<LinkProps, 'to'> & {
	icon?: string;
	to?: string;
};

export function NavItem(props: TNavItem) {
	const {
		icon = 'icon-home',
		to = '#',
		children,
	} = props;

	const classNames = [styles.link];

	return (
		<NavLink
			to={ to }
			className={
				(options) => {
					if (options.isActive) {
						classNames.push(styles.active);
					}

					return classNames.join(' ');
				}
			}
		>
			{ icon && <Icon icon={ icon } className={ styles.icon } /> }

			<span>{ children }</span>
		</NavLink>
	);
}
