import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserPhoneNumberPage = lazy(() => import('../views/update/UserPhoneNumberPage'));

export function UserPhoneNumberPageLazy() {
	return (
		<PageSuspense>
			<UserPhoneNumberPage />
		</PageSuspense>
	);
}
