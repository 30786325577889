import { ReactNode } from 'react';

import { FormImage } from 'src/components/form/form-image';


type TProps = {
	nickname?: string;
	src?: string;
	children?: ReactNode;
};

export function UserImageForm(props: TProps) {
	const localeText = {
		legend: 'Аватар',

		buttonTextChange: 'Сменить аватар',
		buttonTextSelect: 'Выбрать аватар',
	};

	return (
		<FormImage localeText={ localeText } />
	);
}
