import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountShiftExpenseUpdatePage = lazy(() => import('../views/update/AccountShiftExpenseUpdatePage'));

export function AccountShiftExpenseUpdatePageLazy() {
	return (
		<PageSuspense>
			<AccountShiftExpenseUpdatePage />
		</PageSuspense>
	);
}
