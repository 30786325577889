import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserCategoryChangePayload, TUserResponse, adaptUserFromResponse, adaptUserCategoryChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCategoryChangeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS}/:nickname/categories`,
	(payload: TUserCategoryChangePayload, thunkApi) => {
		const { nickname, data } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/categories`;
		const dataRequest = adaptUserCategoryChangeToRequest(data);

		return HttpService.post<TUserResponse>(path, dataRequest).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryChangeCreateSlice = createSlice({
	name: 'userCategoryChangeCreateSlice',
	initialState,
	reducers: {
		clearUserCategoryChangeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryChangeCreateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryChangeCreateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryChangeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryChangeCreateSelector = () => useAppSelector(state => state[userCategoryChangeCreateSlice.name]);

export const { clearUserCategoryChangeCreate } = userCategoryChangeCreateSlice.actions;
