import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { EquipmentsCategoriesSortCol, TEquipmentCategoryList, TEquipmentCategoryListResponse, TEquipmentCategoryHierarchyQuery, TEquipmentsCategoriesSortCol, adaptEquipmentCategoryFromListResponse, adaptEquipmentsCategoriesHierarchyQueryToRequest } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentsCategoriesHierarchyGetApi = createAsyncThunk(
	BACKEND_ADMIN_EQUIPMENTS_CATEGORIES,
	(payload: TEquipmentCategoryHierarchyQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsCategoriesHierarchyQueryToRequest(payload);

		return HttpService.get<TEquipmentCategoryListResponse>(BACKEND_ADMIN_EQUIPMENTS_CATEGORIES, { params }).then(result => {
			return adaptEquipmentCategoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentCategoryList;
	query: TEquipmentCategoryHierarchyQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: EquipmentsCategoriesSortCol.CREATED_AT,
		parentId: null,
	},
	error: undefined,
};

export const equipmentsCategoriesHierarchySlice = createSlice({
	name: 'equipmentsCategoriesHierarchySlice',
	initialState,
	reducers: {
		setEquipmentsCategoriesHierarchyQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setEquipmentsCategoriesHierarchyQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setEquipmentsCategoriesHierarchyQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setEquipmentsCategoriesHierarchyQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setEquipmentsCategoriesHierarchyQuerySortCol(state, action: PayloadAction<TEquipmentsCategoriesSortCol>) {
			state.query.sortCol = action.payload;
		},

		setEquipmentsCategoriesHierarchyQueryParentId(state, action: PayloadAction<number | undefined | null>) {
			state.query.parentId = action.payload;
		},

		clearEquipmentsCategoriesHierarchy(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[equipmentsCategoriesHierarchyGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsCategoriesHierarchyGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategoryList>) => {
			return {
				isLoading: false,
				list: action.payload,
				error: undefined,
				query: { ...state.query },
			};
		},

		[equipmentsCategoriesHierarchyGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsCategoriesHierarchySelector = () => useAppSelector(state => state[equipmentsCategoriesHierarchySlice.name]);

export const { clearEquipmentsCategoriesHierarchy, setEquipmentsCategoriesHierarchyQueryLimit, setEquipmentsCategoriesHierarchyQueryOffset, setEquipmentsCategoriesHierarchyQuerySort, setEquipmentsCategoriesHierarchyQuerySortCol, setEquipmentsCategoriesHierarchyQuerySearch, setEquipmentsCategoriesHierarchyQueryParentId } = equipmentsCategoriesHierarchySlice.actions;
