import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderTagType, TProjectOrderTagTypeUpdate, TProjectOrderTagTypeResponse, adaptProjectOrderTagTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES } from '../../backend-paths';


export const projectOrderTagTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES}/:id`,
	(payload: TProjectOrderTagTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES}/${id}`;

		return HttpService.put<TProjectOrderTagTypeResponse>(path, data).then(result => {
			return adaptProjectOrderTagTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderTagType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderTagTypeUpdateSlice = createSlice({
	name: 'projectOrderTagTypeUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderTagTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderTagTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderTagTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderTagType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderTagTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderTagTypeUpdateSelector = () => useAppSelector(state => state[projectOrderTagTypeUpdateSlice.name]);

export const { clearProjectOrderTagTypeUpdate } = projectOrderTagTypeUpdateSlice.actions;
