import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from './user-type.type';


export const UserTypeHistorySortCol = {
	CREATED_AT: 'created_at',
};

export type TUserTypeHistorySortCol = TTableSortCols<typeof UserTypeHistorySortCol>;

export type TUserTypeHistoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserTypeHistorySortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TUserTypeHistoryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptUserTypeHistoryQueryRequest(data: TUserTypeHistoryQuery): TUserTypeHistoryQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserTypeHistoryListResponse = TListResponse<TUserTypeHistoryResponse>;

export type TUserTypeHistoryList = TListResponse<TUserTypeHistory>;

export function adaptUserTypeHistoryFromListResponse(data: TUserTypeHistoryListResponse): TUserTypeHistoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserTypeHistoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TUserTypeHistory = {
  id: number;
  type: TUserType;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserTypeHistoryResponse = {
  id: number;
  type: TUserTypeResponse;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserTypeHistoryBodyRequest = {
	typeId: number;
};

export type TUserTypeHistoryRequest = {
	value: number;
	date_start: string;
	date_end?: string;
};

type TUserTypeHistoryParam = {
	userNickname: string;
};

export type TUserTypeHistoryParams = TUserTypeHistoryParam & {
	id: number;
};

export type TUserTypeHistoryPayload = {
	nickname: string;
	data: TUserTypeHistoryBodyRequest;
};

export type TUserTypeHistoriesPayload = TUserTypeHistoryParam & {
	query: TUserTypeHistoryQuery;
};

export function adaptUserTypeHistoryFromResponse(data: TUserTypeHistoryResponse): TUserTypeHistory {
	const {
		id,
		type: typeRaw,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const type = adaptUserTypeFromResponse(typeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		type,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}
