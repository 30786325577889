// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PerceptedBlock_text__AD5QT {\n  background-color: rgb(230, 230, 230);\n  border: 1px solid rgb(220, 220, 220);\n  border-left: 5px solid rgb(150, 150, 150);\n  box-sizing: border-box;\n  color: rgb(120, 120, 120);\n  margin: 0.5em auto;\n  padding: 1em;\n  width: 100%;\n}\n.PerceptedBlock_text__AD5QT.PerceptedBlock_info__siR5h {\n  background-color: rgb(255, 245, 175);\n  border: 1px solid rgb(225, 200, 175);\n  border-left: 5px solid rgb(225, 200, 0);\n  box-sizing: border-box;\n  color: rgb(255, 145, 0);\n}", "",{"version":3,"sources":["webpack://./src/pages/components/percepted-block/PerceptedBlock.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,oCAAA;EACA,yCAAA;EACA,sBAAA;EAEA,yBAAA;EAEA,kBAAA;EACA,YAAA;EAEA,WAAA;AAFD;AAIC;EACC,oCAAA;EACA,oCAAA;EACA,uCAAA;EACA,sBAAA;EAEA,uBAAA;AAHF","sourcesContent":[".text {\n\tbackground-color: rgba(230, 230, 230, 1);\n\tborder: 1px solid rgba(220, 220, 220, 1);\n\tborder-left: 5px solid rgba(150, 150, 150, 1);\n\tbox-sizing: border-box;\n\n\tcolor: rgba(120, 120, 120, 1);\n\n\tmargin: 0.5em auto;\n\tpadding: 1em;\n\n\twidth: 100%;\n\n\t&.info {\n\t\tbackground-color: rgba(255, 245, 175, 1);\n\t\tborder: 1px solid rgba(225, 200, 175, 1);\n\t\tborder-left: 5px solid rgba(225, 200, 0, 1);\n\t\tbox-sizing: border-box;\n\n\t\tcolor: rgba(255, 145, 0, 1);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PerceptedBlock_text__AD5QT",
	"info": "PerceptedBlock_info__siR5h"
};
export default ___CSS_LOADER_EXPORT___;
