import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingAmocrmClientId, TSystemSettingAmocrmClientIdPayload, TSystemSettingAmocrmClientIdResponse, adaptSystemSettingAmocrmClientIdFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID } from '../../backend-paths';


export const systemSettingAmocrmClientIdUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID}`,
	(payload: TSystemSettingAmocrmClientIdPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID;

		return HttpService.put<TSystemSettingAmocrmClientIdResponse>(path, payload).then(result => {
			return adaptSystemSettingAmocrmClientIdFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingAmocrmClientId> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingAmocrmClientIdUpdateSlice = createSlice({
	name: 'systemSettingAmocrmClientIdUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingAmocrmClientIdUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingAmocrmClientIdUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingAmocrmClientIdUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingAmocrmClientId>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingAmocrmClientIdUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingAmocrmClientIdUpdateSelector = () => useAppSelector(state => state[systemSettingAmocrmClientIdUpdateSlice.name]);

export const { clearSystemSettingAmocrmClientIdUpdate } = systemSettingAmocrmClientIdUpdateSlice.actions;
