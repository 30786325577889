import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingShiftsIsNoticeUsersByShiftChanges, TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse, adaptSystemSettingShiftsIsNoticeUsersByShiftChangesFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES } from '../../backend-paths';


export const systemSettingShiftsIsNoticeUsersByShiftChangesReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES;

		return HttpService.get<TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse>(path).then(result => {
			return adaptSystemSettingShiftsIsNoticeUsersByShiftChangesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingShiftsIsNoticeUsersByShiftChanges> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingShiftsIsNoticeUsersByShiftChangesReadSlice = createSlice({
	name: 'systemSettingShiftsIsNoticeUsersByShiftChangesReadSlice',
	initialState,
	reducers: {
		setSystemSettingShiftsIsNoticeUsersByShiftChangesRead(state, action: PayloadAction<TSystemSettingShiftsIsNoticeUsersByShiftChanges>) {
			state.item = action.payload;
		},

		clearSystemSettingShiftsIsNoticeUsersByShiftChangesRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingShiftsIsNoticeUsersByShiftChangesReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingShiftsIsNoticeUsersByShiftChangesReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingShiftsIsNoticeUsersByShiftChanges>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingShiftsIsNoticeUsersByShiftChangesReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingShiftsIsNoticeUsersByShiftChangesReadSelector = () => useAppSelector(state => state[systemSettingShiftsIsNoticeUsersByShiftChangesReadSlice.name]);

export const { clearSystemSettingShiftsIsNoticeUsersByShiftChangesRead, setSystemSettingShiftsIsNoticeUsersByShiftChangesRead } = systemSettingShiftsIsNoticeUsersByShiftChangesReadSlice.actions;
