import { useState } from 'react';

import { FormField } from 'src/components';

import { TagListBoxModal } from './modal';
import { TagListBoxWrap } from './TagListBoxWrap';

import styles from './TagListBox.module.scss';


export type TTagListBoxItem = {
	id: number;
	name: string;
	description?: string;
};

type TProps = {
	legend?: string;
	placeholder?: string;

	setErrors?: (value: string[]) => void;
	errors?: string[];
	isLoading?: boolean;
	required?: boolean;
	readonly?: boolean;

	items: TTagListBoxItem[];
	setItems?: (values: TTagListBoxItem[]) => void;
};

export function TagListBox(props: TProps) {
	const {
		items,
		setItems,

		errors,
		setErrors,
		isLoading,
		required,
		readonly,

		legend,
		placeholder,
	} = props;

	const onDelete = (value: number) => {
		const filtered = items.filter(item => item.id !== value);

		if (setItems) {
			setItems(filtered);
		}

		if (setErrors) {
			setErrors([]);
		}
	};

	const classNames = [styles.box];

	if (isLoading) {
		classNames.push(styles.isLoading);
	}

	const [tag, setTag] = useState<TTagListBoxItem>();

	const onBlur = () => {
		setTag(undefined);
	};

	const onFocus = (value: TTagListBoxItem) => {
		setTag(value);
	};

	return (
		<FormField
			legend={ legend }
			required={ required }
			requiredMark={ false }
			className={ classNames.join(' ') }
			errors={ errors }
		>

			<TagListBoxWrap
				placeholder={ placeholder }
				items={ items }
				onDelete={ onDelete }
				onFocus={ onFocus }
				onBlur={ onBlur }
				readonly={ readonly }
			/>

			{ tag && <TagListBoxModal item={ tag } /> }
		</FormField>
	);
}
