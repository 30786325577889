import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const SystemSettingCalendarPage = lazy(() => import('../views/list/SystemSettingCalendarPage'));

export function SystemSettingCalendarPageLazy() {
	return (
		<PageSuspense>
			<SystemSettingCalendarPage />
		</PageSuspense>
	);
}
