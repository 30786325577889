import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserRequisites, TUserRequisitesPayload, TUserRequisitesResponse, adaptUserRequisitesFromResponse, adaptUserRequisitesToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userRequisitesUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/requisites`,
	(payload: TUserRequisitesPayload, thunkApi) => {
		const { data, nickname } = payload;

		const path = `${BACKEND_ADMIN_USERS}/${nickname}/requisites`;

		const dataRequest = adaptUserRequisitesToRequest(data);

		return HttpService.put<TUserRequisitesResponse>(path, dataRequest).then(result => {
			return adaptUserRequisitesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserRequisites> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userRequisitesUpdateSlice = createSlice({
	name: 'userRequisitesUpdateSlice',
	initialState,
	reducers: {
		clearUserRequisitesUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userRequisitesUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userRequisitesUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserRequisites>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userRequisitesUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserRequisitesUpdateSelector = () => useAppSelector(state => state[userRequisitesUpdateSlice.name]);

export const { clearUserRequisitesUpdate } = userRequisitesUpdateSlice.actions;
