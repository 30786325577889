import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { TScheduleEventTypeSource } from './schedule-event-type-source.type';


export type TScheduleQuery = {
	dateStart?: string;
	dateEnd?: string;
};

export type TScheduleQueryRequest = {
	date_start?: string;
	date_end?: string;
};

export function adaptScheduleQueryToRequest(data?: TScheduleQuery): TScheduleQueryRequest {
	if (!data) {
		return {};
	}

	const {
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TSchedule = Pick<TScheduleEventTypeSource, 'id' | 'name' | 'color'> & {
	date: string;
	description?: string;
};

export type TScheduleShift = {
	id: number;
	date: string;
	projectOrderName: string;
	projectName: string;
};

type TScheduleShiftResponse = {
	id: number;
	date: string;
	project_order_name: string;
	project_name: string;
};

export type TUserSchedule = TUserMeta & {
	birthday: string;
	schedule: TSchedule[];
	shifts: TScheduleShift[];
};

type TScheduleResponse = TUserMetaResponse & {
	birthday: string;
	schedule: TSchedule[];
	shifts: TScheduleShiftResponse[];
};

export type TScheduleListResponse = {
	items: TScheduleResponse[];
	dates_off: string[];
};

export type TScheduleList = {
	items: TUserSchedule[];
	datesOff: string[];
};

export type TUserSchedulePayload = {
	userNickname: string;
	date: string;
	typeId: number;
};

export function adaptScheduleFromListResponse(data: TScheduleListResponse): TScheduleList {
	const {
		items: itemsRaw = [],
		dates_off: datesOff = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptScheduleFromResponse(item));

	return {
		items,
		datesOff,
		...restFields
	};
}

export function adaptScheduleFromResponse(data: TScheduleResponse): TUserSchedule {
	const {
		birthday = '',
		schedule: scheduleRaw = [],
		shifts: shiftsRaw = [],
		...restFields
	} = data;

	const user = adaptUserMetaFromResponse(restFields);
	const schedule = scheduleRaw.map(item => adaptUserScheduleFromResponse(item));
	const shifts = shiftsRaw.map(item => adaptUserScheduleShiftFromResponse(item));

	return { ...user, birthday, schedule, shifts };
}

export function adaptUserScheduleFromResponse(data: TSchedule): TSchedule {
	const {
		id,
		date,
		name,
		color,
		description,
	} = data;

	return {
		id,
		date,
		name,
		color,
		description,
	};
}

export function adaptUserScheduleShiftFromResponse(data: TScheduleShiftResponse): TScheduleShift {
	const {
		id,
		date,
		project_name: projectName,
		project_order_name: projectOrderName,
	} = data;

	return {
		id,
		date,
		projectName,
		projectOrderName,
	};
}
