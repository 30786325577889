import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import styles from './IconInfoDetails.module.scss';


export type TIconInfo = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
	title?: string;
	headline?: string;
	description?: string;
	descriptionComponent?: ReactNode;
};

type TProps = {
	item: TIconInfo;
	parent?: Element;
};

export function IconInfoDetails(props: TProps) {
	const { item, parent: parentElement } = props;
	const {
		headline: headlineRaw,
		description: descriptionRaw,
		descriptionComponent,
	} = item;

	const headline = headlineRaw ? headlineRaw : '—';
	const description = descriptionRaw ? descriptionRaw : 'Нет описания';
	const classNamesDescription = [styles.text];

	if (!descriptionRaw) {
		classNamesDescription.push(styles.empty);
	}

	if (descriptionComponent) {
		const elem = (
			<div className={ styles.veil }>
				<div className={ styles.container }>
					<div className={ styles.headline }>
						<span className={ styles.title }>{ headline }</span>
					</div>
					{ descriptionComponent }
				</div>
			</div>
		);

		const parent = parentElement || document.body;

		return createPortal(elem, parent);
	}

	const elem = (
		<div className={ styles.veil }>
			<div className={ styles.container }>
				<div className={ styles.headline }>
					<span className={ styles.title }>{ headline }</span>
				</div>
				<p className={ classNamesDescription.join(' ') }>{ description }</p>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
