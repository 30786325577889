import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsOrdersTagsTypesSortCol, TProjectOrderTagTypeList, TProjectOrderTagTypeListResponse, TProjectOrderTagTypeQuery, TProjectsOrdersTagsTypesSortCol, adaptProjectOrderTagTypeFromListResponse, adaptProjectsOrdersTagsTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES } from '../../backend-paths';


export const projectsOrdersTagsTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES,
	(payload: TProjectOrderTagTypeQuery | undefined, thunkApi) => {
		const params = adaptProjectsOrdersTagsTypesQueryToRequest(payload);

		return HttpService.get<TProjectOrderTagTypeListResponse>(BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES, { params }).then(result => {
			return adaptProjectOrderTagTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectOrderTagTypeList,
	query: TProjectOrderTagTypeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsOrdersTagsTypesSortCol.CREATED_AT,
	},
};

export const projectsOrdersTagsTypesSlice = createSlice({
	name: 'projectsOrdersTagsTypesSlice',
	initialState,
	reducers: {
		setProjectsOrdersTagsTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsOrdersTagsTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsOrdersTagsTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsOrdersTagsTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsOrdersTagsTypesQuerySortCol(state, action: PayloadAction<TProjectsOrdersTagsTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectsOrdersTagsTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsOrdersTagsTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsOrdersTagsTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderTagTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsOrdersTagsTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsOrdersTagsTypesSelector = () => useAppSelector(state => state[projectsOrdersTagsTypesSlice.name]);

export const { clearProjectsOrdersTagsTypes, setProjectsOrdersTagsTypesQueryLimit, setProjectsOrdersTagsTypesQueryOffset, setProjectsOrdersTagsTypesQuerySort, setProjectsOrdersTagsTypesQuerySortCol, setProjectsOrdersTagsTypesQuerySearch } = projectsOrdersTagsTypesSlice.actions;
