import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';

import { TExpenseType, TExpenseTypeResponse, adaptExpenseTypeFromResponse } from './expense-type.type';


export const UsersExpensesTypesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersExpensesTypesSortCol = TTableSortCols<typeof UsersExpensesTypesSortCol>;

export type TUserExpenseTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersExpensesTypesSortCol;
};

export type TUsersExpensesTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersExpensesTypesQueryToRequest(data?: TUserExpenseTypeQuery): TUsersExpensesTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserExpenseType = {
  id: number;
  expenseTypeId: number;
	createdAt: string;
  updatedAt: string;

	expenseType: TExpenseType;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserExpenseTypeResponse = {
  id: number;
  expense_type_id: number;
  created_at: string;
  updated_at: string;

	expense_type: TExpenseTypeResponse;
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserExpenseTypeListResponse = TListResponse<TUserExpenseTypeResponse>;

export type TUserExpenseTypeList = TListResponse<TUserExpenseType>;

export type TUserExpenseTypeBodyRequest = {
	expenseTypeId: number;
};

export type TUserExpenseTypeRequest = {
	expense_type_id: number;
};

export type TUserExpenseTypeUpdate = {
	id: number;
	data: TUserExpenseTypeBodyRequest;
};

export function adaptUserExpenseTypeFromListResponse(data: TUserExpenseTypeListResponse): TUserExpenseTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserExpenseTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserExpenseTypeFromResponse(data: TUserExpenseTypeResponse): TUserExpenseType {
	const {
		id,
		expense_type_id: expenseTypeId,
		expense_type: expenseTypeRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const expenseType = adaptExpenseTypeFromResponse(expenseTypeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		expenseTypeId,
		expenseType,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserExpenseTypeToRequest(data: TUserExpenseTypeBodyRequest): TUserExpenseTypeRequest {
	const { expenseTypeId } = data;

	return { expense_type_id: expenseTypeId };
}
