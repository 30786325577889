import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserDepartmentChange, TUserDepartmentChangeResponse, adaptUserDepartmentChangeFromResponse } from '../../types';
import { BACKEND_ACCOUNT_DEPARTMENT_CHANGES } from '../../backend-paths';


export const accountDepartmentChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_DEPARTMENT_CHANGES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_DEPARTMENT_CHANGES}/${payload}`;

		return HttpService.get<TUserDepartmentChangeResponse>(path).then(result => {
			return adaptUserDepartmentChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserDepartmentChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountDepartmentChangeReadSlice = createSlice({
	name: 'accountDepartmentChangeReadSlice',
	initialState,
	reducers: {
		setAccountDepartmentChangeRead(state, action: PayloadAction<TUserDepartmentChange>) {
			state.item = action.payload;
		},

		clearAccountDepartmentChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountDepartmentChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountDepartmentChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartmentChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountDepartmentChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountDepartmentChangeReadSelector = () => useAppSelector(state => state[accountDepartmentChangeReadSlice.name]);

export const { clearAccountDepartmentChangeRead, setAccountDepartmentChangeRead } = accountDepartmentChangeReadSlice.actions;
