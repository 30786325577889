import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsClientsSortCol, TProjectClientList, TProjectClientListResponse, TProjectClientQuery, TProjectsClientsSortCol, adaptProjectClientFromListResponse, adaptProjectsClientsQueryToRequest } from '../../types';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectsClientsGetApi = createAsyncThunk(
	BACKEND_PROJECTS_CLIENTS,
	(payload: TProjectClientQuery | undefined, thunkApi) => {
		const params = payload ? adaptProjectsClientsQueryToRequest(payload) : {};

		return HttpService.get<TProjectClientListResponse>(BACKEND_PROJECTS_CLIENTS, { params }).then(result => {
			return adaptProjectClientFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectClientList,
	query: TProjectClientQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsClientsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectsClientsSlice = createSlice({
	name: 'projectsClientsSlice',
	initialState,
	reducers: {
		setProjectsClientsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsClientsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsClientsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsClientsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsClientsQuerySortCol(state, action: PayloadAction<TProjectsClientsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectsClients(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsClientsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsClientsGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsClientsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsClientsSelector = () => useAppSelector(state => state[projectsClientsSlice.name]);

export const { clearProjectsClients, setProjectsClientsQueryLimit, setProjectsClientsQueryOffset, setProjectsClientsQuerySort, setProjectsClientsQuerySortCol, setProjectsClientsQuerySearch } = projectsClientsSlice.actions;
