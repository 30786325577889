import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountShiftExpense, adaptAccountShiftExpenseFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_SHIFT_EXPENSE } from '../../backend-paths';


export const accountShiftExpenseDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_SHIFT_EXPENSE}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SHIFT_EXPENSE}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountShiftExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftExpenseDeleteSlice = createSlice({
	name: 'accountShiftExpenseDeleteSlice',
	initialState,
	reducers: {
		clearAccountShiftExpenseDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftExpenseDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftExpenseDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftExpenseDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftExpenseDeleteSelector = () => useAppSelector(state => state[accountShiftExpenseDeleteSlice.name]);

export const { clearAccountShiftExpenseDelete } = accountShiftExpenseDeleteSlice.actions;
