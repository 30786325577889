import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountOverwork, TAccountOverworkPayloadUpdate, TAccountOverworkResponse, adaptAccountOverworkFromResponse, adaptAccountOverworkToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_OVERWORKS } from '../../backend-paths';


export const accountOverworkUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_OVERWORKS}/:id`,
	(payload: TAccountOverworkPayloadUpdate, thunkApi) => {
		const { id, data } = payload;
		const path = `${BACKEND_ACCOUNT_OVERWORKS}/${id}`;

		const dataRequest = adaptAccountOverworkToRequest(data);

		return HttpService.put<TAccountOverworkResponse>(path, dataRequest).then(result => {
			return adaptAccountOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountOverworkUpdateSlice = createSlice({
	name: 'accountOverworkUpdateSlice',
	initialState,
	reducers: {
		clearAccountOverworkUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountOverworkUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountOverworkUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountOverworkUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountOverworkUpdateSelector = () => useAppSelector(state => state[accountOverworkUpdateSlice.name]);

export const { clearAccountOverworkUpdate } = accountOverworkUpdateSlice.actions;
