import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersTaxationsReceiptsSortCol, TUserTaxationReceiptList, TUserTaxationReceiptListResponse, TUsersTaxationsReceiptsQuery, TUsersTaxationsReceiptsQueryPayload, TUsersTaxationsReceiptsSortCol, adaptUserTaxationReceiptFromListResponse, adaptUsersTaxationsReceiptsQueryToRequest } from '../../types';
import { BACKEND_USERS_TAXATIONS_RECEIPTS } from '../../backend-paths';


export const usersTaxationsReceiptsGetApi = createAsyncThunk(
	`${BACKEND_USERS_TAXATIONS_RECEIPTS}/:id`,
	(payload: TUsersTaxationsReceiptsQueryPayload, thunkApi) => {
		const { data } = payload;
		const params = adaptUsersTaxationsReceiptsQueryToRequest(data);

		const path = `${BACKEND_USERS_TAXATIONS_RECEIPTS}`;

		return HttpService.get<TUserTaxationReceiptListResponse>(path, { params }).then(result => {
			return adaptUserTaxationReceiptFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserTaxationReceiptList;
	query: TUsersTaxationsReceiptsQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersTaxationsReceiptsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersTaxationsReceiptsSlice = createSlice({
	name: 'usersTaxationsReceiptsSlice',
	initialState,
	reducers: {
		setUsersTaxationsReceiptsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersTaxationsReceiptsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersTaxationsReceiptsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersTaxationsReceiptsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersTaxationsReceiptsQuerySortCol(state, action: PayloadAction<TUsersTaxationsReceiptsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersTaxationsReceipts(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersTaxationsReceiptsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersTaxationsReceiptsGetApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationReceiptList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersTaxationsReceiptsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersTaxationsReceiptsSelector = () => useAppSelector(state => state[usersTaxationsReceiptsSlice.name]);

export const { clearUsersTaxationsReceipts, setUsersTaxationsReceiptsQueryLimit, setUsersTaxationsReceiptsQueryOffset, setUsersTaxationsReceiptsQuerySort, setUsersTaxationsReceiptsQuerySortCol, setUsersTaxationsReceiptsQuerySearch } = usersTaxationsReceiptsSlice.actions;
