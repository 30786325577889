import { FormButtonLink, Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';

import styles from './SignUpStageSuccess.module.scss';


type Props = {
	headline: string;
};

export function SignUpStageSuccess(props: Props) {
	const classNames = [styles.paragraph, styles.percepted];
	const { headline } = props;

	return (
		<div className={ styles.succesContainer }>
			<p className={ classNames.join(' ') }>
				{ headline }
			</p>

			<p className={ styles.paragraph }>
				Теперь вы можете <Link to={ PATH_SIGN_IN }>Войти</Link> в Ваш аккаунт
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>Готово</FormButtonLink>
		</div>
	);
}
