import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import { FormImageFieldPreloader } from './components';

import styles from './FormImageFieldPreview.module.scss';


type TProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	isLoading?: boolean;
};

export function FormImageFieldPreview(props: TProps) {
	const {
		isLoading,
		className = '',
		...restProps
	} = props;

	if (isLoading) {
		return (
			<FormImageFieldPreloader />
		);
	}

	const classNames = [styles.preview];

	if (className) {
		classNames.push(className);
	}

	return (
		<img title="Просмотреть изображение" { ...restProps } className={ classNames.join(' ') } />
	);
}
