import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectClientContact, TProjectClientContactPayloadUpdate, TProjectClientContactResponse, adaptProjectClientContactFromResponse, adaptProjectClientContactToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientContactUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS_CLIENTS}/:client_id/contacts/:id`,
	(payload: TProjectClientContactPayloadUpdate, thunkApi) => {
		const { id, clientId, data } = payload;
		const path = `${BACKEND_PROJECTS_CLIENTS}/${clientId}/contacts/${id}`;
		const dataRequest = adaptProjectClientContactToRequest(data);

		return HttpService.put<TProjectClientContactResponse>(path, dataRequest).then(result => {
			return adaptProjectClientContactFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TProjectClientContact | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientContactUpdateSlice = createSlice({
	name: 'projectClientContactUpdateSlice',
	initialState,
	reducers: {
		clearProjectClientContactUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientContactUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectClientContactUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientContact>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientContactUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectClientContactUpdateSelector = () => useAppSelector(state => state[projectClientContactUpdateSlice.name]);

export const { clearProjectClientContactUpdate } = projectClientContactUpdateSlice.actions;
