import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountOverwork, TAccountOverworkResponse, adaptAccountOverworkFromResponse } from '../../types';
import { BACKEND_ACCOUNT_OVERWORKS } from '../../backend-paths';


export const accountOverworkReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ACCOUNT_OVERWORKS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_OVERWORKS}/${payload}`;

		return HttpService.get<TAccountOverworkResponse>(path).then(result => {
			return adaptAccountOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountOverworkReadSlice = createSlice({
	name: 'accountOverworkReadSlice',
	initialState,
	reducers: {
		setAccountOverworkRead(state, action: PayloadAction<TAccountOverwork>) {
			state.item = action.payload;
		},

		clearAccountOverworkRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountOverworkReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountOverworkReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountOverworkReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountOverworkReadSelector = () => useAppSelector(state => state[accountOverworkReadSlice.name]);

export const { clearAccountOverworkRead, setAccountOverworkRead } = accountOverworkReadSlice.actions;
