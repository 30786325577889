import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const UsersDepartmentsSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersDepartmentsSortCol = TTableSortCols<typeof UsersDepartmentsSortCol>;

export type TUserDepartmentQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersDepartmentsSortCol;
};

export type TUsersDepartmentsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptUsersDepartmentsQueryToRequest(data?: TUserDepartmentQuery): TUsersDepartmentsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserDepartment = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserDepartmentResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserDepartmentListResponse = TListResponse<TUserDepartmentResponse>;

export type TUserDepartmentList = TListResponse<TUserDepartment>;

export type TUserDepartmentBodyRequest = {
	name: string;
	description?: string;
};

export type TUserDepartmentRequest = {
	name: string;
	description?: string;
};

export type TUserDepartmentUpdate = {
	id: number;
	data: TUserDepartmentBodyRequest;
};

export function adaptUserDepartmentFromListResponse(data: TUserDepartmentListResponse): TUserDepartmentList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserDepartmentFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserDepartmentFromResponse(data: TUserDepartmentResponse): TUserDepartment {
	const {
		id,
		name,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserDepartmentToRequest(data: TUserDepartmentBodyRequest): TUserDepartmentRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
