import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_SOURCE_USER_POSITION } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserPosition, TUserPositionList, TUserPositionListResponse, adaptUserPositionFromListResponse } from '../../types';


export const sourceUserPositionListGetApi = createAsyncThunk(
	`FETCH/${BACKEND_SOURCE_USER_POSITION}`,
	(payload, thunkApi) => {
		return HttpService.get<TUserPositionListResponse>(BACKEND_SOURCE_USER_POSITION).then(result => {
			return adaptUserPositionFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TUserPosition[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
};

export const sourceUserPositionListSlice = createSlice({
	name: 'sourceUserPositionListSlice',
	initialState,
	reducers: {
		clearSourceUserPositionList(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[sourceUserPositionListGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceUserPositionListGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPositionList>) => {
			state.isLoading = false;
			state.items = action.payload.items;
			state.error = undefined;
		},

		[sourceUserPositionListGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceUserPositionListSelector = () => useAppSelector(state => state[sourceUserPositionListSlice.name]);

export const { clearSourceUserPositionList } = sourceUserPositionListSlice.actions;
