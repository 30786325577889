import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { FinancialChangesTypesSortCol, TFinancialChangeTypeList, TFinancialChangeTypeListResponse, TFinancialChangeTypeQuery, TFinancialChangesTypesSortCol, adaptFinancialChangeTypeFromListResponse, adaptFinancialChangesTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES } from '../../backend-paths';


export const financialChangesTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES,
	(payload: TFinancialChangeTypeQuery | undefined, thunkApi) => {
		const params = adaptFinancialChangesTypesQueryToRequest(payload);

		return HttpService.get<TFinancialChangeTypeListResponse>(BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES, { params }).then(result => {
			return adaptFinancialChangeTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TFinancialChangeTypeList,
	query: TFinancialChangeTypeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: FinancialChangesTypesSortCol.CREATED_AT,
	},
};

export const financialChangesTypesSlice = createSlice({
	name: 'financialChangesTypesSlice',
	initialState,
	reducers: {
		setFinancialChangesTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setFinancialChangesTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setFinancialChangesTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setFinancialChangesTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setFinancialChangesTypesQuerySortCol(state, action: PayloadAction<TFinancialChangesTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearFinancialChangesTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[financialChangesTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[financialChangesTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TFinancialChangeTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[financialChangesTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useFinancialChangesTypesSelector = () => useAppSelector(state => state[financialChangesTypesSlice.name]);

export const { clearFinancialChangesTypes, setFinancialChangesTypesQueryLimit, setFinancialChangesTypesQueryOffset, setFinancialChangesTypesQuerySort, setFinancialChangesTypesQuerySortCol, setFinancialChangesTypesQuerySearch } = financialChangesTypesSlice.actions;
