import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './PageActionBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
};

export function PageActionBox(props: TProps) {
	const {
		classNames: classNamesOuter = [],
		className = '',
		children,
		...restProps
	} = props;

	const classNames = [styles.pageActionBox, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</div>
	);
}
