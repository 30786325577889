import { MouseEvent, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAccountRolesSelector } from 'src/store';

import { AsideItemOptions, AsideItemRow } from './components';

import styles from './AsideItem.module.scss';
import { AsideItemMenu } from './components/menu';
import { ASIDE_MENU_ROW_CLASS_NAME, ASIDE_SCROLL_CONTAINER_ID } from '../aside/Aside';
import { useIsMobile } from 'src/toolkit';


export type TAsideItemOption = {
	links?: string[];
	permission?: string;
	re?: RegExp;
	href?: string;
	text?: string;
	onClick?: MouseEventHandler<HTMLElement>;
};

export type TAsideItem = {
	options?: TAsideItemOption[];
	permission?: string;
	includes?: string[];
	text?: string;
	icon?: string;
	scroll?: number;
	onClick?: MouseEventHandler<HTMLElement>;
};

export function AsideItem(props: TAsideItem) {
	const permissions = useAccountRolesSelector();

	const { pathname } = useLocation();
	const [isHover, setIsHover] = useState(false);
	const { isTablet, height } = useIsMobile();
	const [position, setPosition] = useState(0);
	const [totalPosition, setTotalPosition] = useState(0);

	const {
		icon = 'icon-home',
		includes = [],
		text = 'Default',
		permission,
		options = [],
		scroll = 0,
	} = props;

	const isActive = useMemo(() => {
		const isActiveItem = includes.find(item => {
			return item === pathname || pathname.indexOf(item) === 0;
		});

		return !!isActiveItem;
	}, [pathname, includes]);

	const [value, setValue] = useState(isActive);

	useEffect(() => {
		setTotalPosition(position - scroll);
	}, [scroll]);

	if (permission) {
		if (!permissions.includes(permission)) {
			return (
				<></>
			);
		}
	}

	const classNameParentBox = ASIDE_MENU_ROW_CLASS_NAME;
	const classNames = [classNameParentBox, styles.box];

	if (value) {
		classNames.push(styles.active);
	}

	const onMouseEnter = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		setIsHover(true);

		const elem = e.target as HTMLElement;
		const parent = elem.closest(`.${classNameParentBox}`);

		if (parent instanceof HTMLElement) {
			const rowY = parent.offsetTop;
			const rowHeight = parent.offsetHeight;
			const requiredHeight = rowHeight * options.length;
			const scrollBoxElem = document.getElementById(ASIDE_SCROLL_CONTAINER_ID);
			const scrollBoxTop = scrollBoxElem instanceof HTMLElement ? scrollBoxElem.offsetTop : 0;
			const availabledHeight = height - rowY - scrollBoxTop - rowHeight;

			let totalY = rowY;

			if (requiredHeight >= availabledHeight) {
				totalY = rowY + rowHeight - requiredHeight - 4;
			}

			setPosition(totalY);
			setTotalPosition(totalY - scroll);
		}
	};

	const onMouseLeave = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		setIsHover(false);
	};

	const isMenu = !isTablet && isHover && !value;

	return (
		<div className={ classNames.join(' ') }>
			<AsideItemRow
				isTablet={ isTablet }
				isActive={ isActive }
				isHover={ isMenu }
				text={ text }
				icon={ icon }
				value={ value }
				setValue={ setValue }
				onMouseEnter={ e => onMouseEnter(e) }
				onMouseLeave={ e => onMouseLeave(e) }
			/>

			{ isMenu &&
				<AsideItemMenu
					items={ options }
					onMouseEnter={ e => setIsHover(true) }
					onMouseLeave={ e => setIsHover(false) }
					position={ totalPosition }
				/>
			}

			{ value && options.length > 0 && <AsideItemOptions items={ options } /> }
		</div>
	);
}
