import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TScheduleEventType, TScheduleEventTypeUpdate, TScheduleEventTypeResponse, adaptScheduleEventTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES } from '../../backend-paths';


export const scheduleEventTypeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES}/:id`,
	(payload: TScheduleEventTypeUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES}/${id}`;

		return HttpService.put<TScheduleEventTypeResponse>(path, data).then(result => {
			return adaptScheduleEventTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEventType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventTypeUpdateSlice = createSlice({
	name: 'scheduleEventTypeUpdateSlice',
	initialState,
	reducers: {
		clearScheduleEventTypeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventTypeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventTypeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEventType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventTypeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventTypeUpdateSelector = () => useAppSelector(state => state[scheduleEventTypeUpdateSlice.name]);

export const { clearScheduleEventTypeUpdate } = scheduleEventTypeUpdateSlice.actions;
