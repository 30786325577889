import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserExpense, TUserExpensePayloadCreate, TUserExpenseResponse, adaptUserExpenseFromResponse, adaptUserExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_USERS_EXPENSES } from '../../backend-paths';


export const userExpenseCreateApi = createAsyncThunk(
	`POST/${BACKEND_USERS_EXPENSES}`,
	(payload: TUserExpensePayloadCreate, thunkApi) => {
		const { data, files } = payload;

		const dataRequest = adaptUserExpenseToRequest(data);

		return HttpService.post<TUserExpenseResponse>(BACKEND_USERS_EXPENSES, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_USERS_EXPENSES}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseCreateSlice = createSlice({
	name: 'userExpenseCreateSlice',
	initialState,
	reducers: {
		clearUserExpenseCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userExpenseCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserExpenseCreateSelector = () => useAppSelector(state => state[userExpenseCreateSlice.name]);

export const { clearUserExpenseCreate } = userExpenseCreateSlice.actions;
