import { RefObject, useRef, useState } from 'react';

import { FormButton, FormField, FormInputFile } from 'src/components';
import { TInputFilesField } from 'src/toolkit';

import { FormImageFieldList, FormImageFieldListValue, FormImageFieldModal } from './components';


export type TFormImageFieldExistedValue = {
	path: string;
	name: string;
};

function clearInput(ref: RefObject<HTMLInputElement>): void {
	const elem = ref.current;

	if (elem) {
		elem.value = '';
	}
};

type TProps = TInputFilesField<TFormImageFieldExistedValue> & {
	setFiles?: (value: File[]) => void;
	legend?: string;

	baseUrl?: string;
	selectImageText?: string;
	changeImageText?: string;
};

export function FormImageField(props: TProps) {
	const {
		legend = '',
		required = false,

		value,
		setValue,
		name,
		errors,
		setFiles = () => {},

		// httpService

		baseUrl = '',
		selectImageText = 'Select image',
		changeImageText = 'Change image',
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [imageFull, setImageFull] = useState<TFormImageFieldExistedValue>();

	const [filesValue, setFilesValue] = useState<File[]>([]);

	const onDeleteExistedValue = (existedValue: TFormImageFieldExistedValue) => {
		const { path: pathDeleted } = existedValue;

		const updatedFileArr = value.filter(item => {
			const { path } = item;
			const isMatched = path === pathDeleted;

			return !isMatched;
		});

		setValue(updatedFileArr);
	};

	const onDelete = (value: File) => {
		const {
			name: nameDeleted,
			size: sizeDeleted,
			lastModified: lastModifiedDeleted,
		} = value;

		const updatedFileArr = filesValue.filter(item => {
			const { name, size, lastModified } = item;
			const isMatched = name === nameDeleted && size === sizeDeleted && lastModified === lastModifiedDeleted;

			return !isMatched;
		});

		setFiles(updatedFileArr);
		setFilesValue(updatedFileArr);
	};

	const onChange = (files: FileList | null) => {
		if (!files) {
			return;
		}

		const arr = [];

		for (let i = 0; i < files.length; i++) {
			const item = files[i];
			arr.push(item);
		}

		setFiles(arr);
		setFilesValue(arr);

		if (!files) {
			return;
		}

		clearInput(inputRef);
	};

	const show = (path: string, name: string) => {
		const item = { path, name };

		setImageFull(item);
	};

	const hide = () => {
		setImageFull(undefined);
	};

	const buttonText = value || filesValue.length > 0 ? changeImageText : selectImageText;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
		>
			<FormButton
				title="Выберите изображения"
				file
			>
				{ buttonText }

				<FormInputFile
					type="file"
					name={ name }
					accept="image/*"
					onChange={ e => onChange(e.target.files) }
					ref={ inputRef }
					multiple
				/>
			</FormButton>

			{ value.length > 0 &&
				<FormImageFieldListValue
					onDelete={ onDeleteExistedValue }
					show={ show }
					// onBlur={ hide }
					files={ value }
					baseUrl={ baseUrl }
				/>
			}

			{ filesValue.length > 0 &&
				<FormImageFieldList
					onDelete={ onDelete }
					// onFocus={ show }
					// onBlur={ hide }
					files={ filesValue }
				/>
			}

			{ imageFull && <FormImageFieldModal src={ imageFull.path } alt={ imageFull.name } hide={ hide } /> }
		</FormField>
	);
}
