import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountPositionChangeReadPage = lazy(() => import('../views/read/AccountPositionChangeReadPage'));

export function AccountPositionChangeReadPageLazy() {
	return (
		<PageSuspense>
			<AccountPositionChangeReadPage />
		</PageSuspense>
	);
}
