import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { UserSalarySortCol, TUserSalaryList, TUserSalaryQuery, TUserSalaryListResponse, TUserSalarySortCol, adaptUserSalaryFromListResponse, adaptUserSalaryQueryRequest, TUserSalariesPayload } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const usersSalariesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/salaries`,
	(payload: TUserSalariesPayload, thunkApi) => {
		const { userNickname, query } = payload;
		const params = query ? adaptUserSalaryQueryRequest(query) : {};
		const path = `${BACKEND_ADMIN_USERS}/${userNickname}/salaries`;

		return HttpService.get<TUserSalaryListResponse>(path, { params }).then(result => {
			return adaptUserSalaryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserSalaryList,
	query: TUserSalaryQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: UserSalarySortCol.CREATED_AT,
	},
};

export const usersSalariesSlice = createSlice({
	name: 'usersSalariesSlice',
	initialState,
	reducers: {
		setUserSalariesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUserSalariesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUserSalariesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUserSalariesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUserSalariesQuerySortCol(state, action: PayloadAction<TUserSalarySortCol>) {
			state.query.sortCol = action.payload;
		},

		setUserSalariesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserSalariesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearUserSalaries(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersSalariesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersSalariesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserSalaryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersSalariesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserSalarySelector = () => useAppSelector(state => state[usersSalariesSlice.name]);

export const { clearUserSalaries, setUserSalariesQueryLimit, setUserSalariesQueryOffset, setUserSalariesQuerySort, setUserSalariesQuerySortCol, setUserSalariesQuerySearch, setUserSalariesQueryDateStart, setUserSalariesQueryDateEnd } = usersSalariesSlice.actions;
