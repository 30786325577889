import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSetting, TSystemSettingResponse, adaptSystemSettingFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS } from '../../backend-paths';


export const systemSettingReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_SYSTEM_SETTINGS}/${payload}`;

		return HttpService.get<TSystemSettingResponse>(path).then(result => {
			return adaptSystemSettingFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSetting> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingReadSlice = createSlice({
	name: 'systemSettingReadSlice',
	initialState,
	reducers: {
		setSystemSettingRead(state, action: PayloadAction<TSystemSetting>) {
			state.item = action.payload;
		},

		clearSystemSettingRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSetting>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingReadSelector = () => useAppSelector(state => state[systemSettingReadSlice.name]);

export const { clearSystemSettingRead, setSystemSettingRead } = systemSettingReadSlice.actions;
