import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountPassport, TAccountPassportPayload, TAccountPassportResponse, adaptAccountPassportFromResponse, adaptAccountPassportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_PASSPORT } from '../../backend-paths';


export const accountPassportUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_PASSPORT}`,
	(payload: TAccountPassportPayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptAccountPassportToRequest(data);

		return HttpService.put<TAccountPassportResponse>(BACKEND_ACCOUNT_PASSPORT, dataRequest).then(result => {
			const pathFiles = `${BACKEND_ACCOUNT_PASSPORT}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountPassportResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountPassportFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountPassport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountPassportUpdateSlice = createSlice({
	name: 'accountPassportUpdateSlice',
	initialState,
	reducers: {
		clearAccountPassportUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountPassportUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPassportUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountPassport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountPassportUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountPassportUpdateSelector = () => useAppSelector(state => state[accountPassportUpdateSlice.name]);

export const { clearAccountPassportUpdate } = accountPassportUpdateSlice.actions;
