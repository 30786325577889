import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const AccountSalarySortCol = {
	VALUE: 'value',
	DATE_START: 'date_start',
	DATE_END: 'date_end',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountSalarySortCol = TTableSortCols<typeof AccountSalarySortCol>;

export type TAccountSalaryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountSalarySortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TAccountSalaryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptAccountSalaryQueryRequest(data: TAccountSalaryQuery): TAccountSalaryQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TAccountSalaryListResponse = TListResponse<TAccountSalaryResponse>;

export type TAccountSalaryList = TListResponse<TAccountSalary>;

export function adaptAccountSalaryFromListResponse(data: TAccountSalaryListResponse): TAccountSalaryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountSalaryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TAccountSalary = {
  id: number;
  value: string;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountSalaryResponse = {
  id: number;
  value: string;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export function adaptAccountSalaryFromResponse(data: TAccountSalaryResponse): TAccountSalary {
	const {
		id,
		value,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		value,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

