import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';

import { TExpenseType, TExpenseTypeResponse, adaptExpenseTypeFromResponse } from './expense-type.type';


export const GroupsShiftsExpensesTypesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TGroupsShiftsExpensesTypesSortCol = TTableSortCols<typeof GroupsShiftsExpensesTypesSortCol>;

export type TGroupShiftExpenseTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TGroupsShiftsExpensesTypesSortCol;
};

export type TGroupsShiftsExpensesTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptGroupsShiftsExpensesTypesQueryToRequest(data?: TGroupShiftExpenseTypeQuery): TGroupsShiftsExpensesTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TGroupShiftExpenseType = {
  id: number;
  expenseTypeId: number;
	createdAt: string;
  updatedAt: string;

	expenseType: TExpenseType;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TGroupShiftExpenseTypeResponse = {
  id: number;
  expense_type_id: number;
  created_at: string;
  updated_at: string;

	expense_type: TExpenseTypeResponse;
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TGroupShiftExpenseTypeListResponse = TListResponse<TGroupShiftExpenseTypeResponse>;

export type TGroupShiftExpenseTypeList = TListResponse<TGroupShiftExpenseType>;

export type TGroupShiftExpenseTypeBodyRequest = {
	expenseTypeId: number;
};

export type TGroupShiftExpenseTypeRequest = {
	expense_type_id: number;
};

export type TGroupShiftExpenseTypeUpdate = {
	id: number;
	data: TGroupShiftExpenseTypeBodyRequest;
};

export function adaptGroupShiftExpenseTypeFromListResponse(data: TGroupShiftExpenseTypeListResponse): TGroupShiftExpenseTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptGroupShiftExpenseTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptGroupShiftExpenseTypeFromResponse(data: TGroupShiftExpenseTypeResponse): TGroupShiftExpenseType {
	const {
		id,
		expense_type_id: expenseTypeId,
		expense_type: expenseTypeRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const expenseType = adaptExpenseTypeFromResponse(expenseTypeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		expenseTypeId,
		expenseType,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptGroupShiftExpenseTypeToRequest(data: TGroupShiftExpenseTypeBodyRequest): TGroupShiftExpenseTypeRequest {
	const { expenseTypeId } = data;

	return { expense_type_id: expenseTypeId };
}
