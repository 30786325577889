import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProjectOrderGroup, TProjectOrderGroupPayload, adaptProjectOrderGroupFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:id`,
	(payload: TProjectOrderGroupPayload, thunkApi) => {
		const { id, orderId, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${id}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectOrderGroupFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroup> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupDeleteSlice = createSlice({
	name: 'projectOrderGroupDeleteSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroup>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupDeleteSelector = () => useAppSelector(state => state[projectOrderGroupDeleteSlice.name]);

export const { clearProjectOrderGroupDelete } = projectOrderGroupDeleteSlice.actions;
