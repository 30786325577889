	export const PATH_HOME = '/';

	export const PATH_PUBLIC_IMAGES = '/images';
	export const PATH_DEFAULT_PROJECT = `${PATH_PUBLIC_IMAGES}/projects.svg`;

	export const PATH_SIGN_IN = '/login';
	export const PATH_SIGN_UP = '/sign-up';
	export const PATH_RECOVERY = '/account/recovery';

	export const PATH_ACCOUNT = '/account';
	export const PATH_ACCOUNT_PHONE_NUMBER = '/account/phone-number';
	export const PATH_ACCOUNT_EMAIL = '/account/email';

	export const PATH_ACCOUNT_SHIFTS = `${PATH_ACCOUNT}/shifts`;
	export const PATH_ACCOUNT_SHIFTS_CREATE_END = `#create`;

	export const PATH_ACCOUNT_PASSWORD = `${PATH_ACCOUNT}/password`;
	export const PATH_ACCOUNT_PASSPORT = `${PATH_ACCOUNT}/passport`;
	export const PATH_ACCOUNT_INTERNATIONAL_PASSPORT = `${PATH_ACCOUNT}/international-passport`;
	export const PATH_ACCOUNT_DATA = `${PATH_ACCOUNT}/data`;
	export const PATH_ACCOUNT_WORK_DATA = `${PATH_ACCOUNT}/work-data`;
	export const PATH_ACCOUNT_TAXATION_DATA = `${PATH_ACCOUNT}/taxation`;
	export const PATH_ACCOUNT_REQUISITES = `${PATH_ACCOUNT}/requisites`;
	export const PATH_ACCOUNT_TYPE_CHANGES = `${PATH_ACCOUNT_WORK_DATA}/type/changes`;
	export const PATH_ACCOUNT_POSITION_CHANGES = `${PATH_ACCOUNT_WORK_DATA}/position/changes`;
	export const PATH_ACCOUNT_CATEGORY_CHANGES = `${PATH_ACCOUNT_WORK_DATA}/category/changes`;
	export const PATH_ACCOUNT_DEPARTMENT_CHANGES = `${PATH_ACCOUNT_WORK_DATA}/department/changes`;
	export const PATH_ACCOUNT_SALARY_CHANGES = `${PATH_ACCOUNT_WORK_DATA}/salary/changes`;
	export const PATH_ACCOUNT_BOOKKEEPING = `${PATH_ACCOUNT}/bookkeeping`;

	export const PATH_ACCOUNT_TAXATION_RECEIPTS = `${PATH_ACCOUNT}/taxation-receipts`;
	export const PATH_ACCOUNT_TAXATION_RECEIPTS_CREATE_END = '#create';
	export const PATH_ACCOUNT_TAXATION_RECEIPTS_CREATE = `${PATH_ACCOUNT_TAXATION_RECEIPTS}${PATH_ACCOUNT_TAXATION_RECEIPTS_CREATE_END}`;

	export const PATH_ACCOUNT_EXPENSES = `${PATH_ACCOUNT}/expenses`;
	export const PATH_ACCOUNT_EXPENSES_CREATE_END = '#create';
	export const PATH_ACCOUNT_EXPENSES_CREATE = `${PATH_ACCOUNT_EXPENSES}${PATH_ACCOUNT_EXPENSES_CREATE_END}`;

	export const PATH_ACCOUNT_OVERWORKS = `${PATH_ACCOUNT}/overworks`;
	export const PATH_ACCOUNT_OVERWORKS_CREATE_END = '#create';
	export const PATH_ACCOUNT_OVERWORKS_CREATE = `${PATH_ACCOUNT_OVERWORKS}${PATH_ACCOUNT_OVERWORKS_CREATE_END}`;

	export const PATH_BOOKKEEPING = '/bookkeeping';
	export const PATH_SCHEDULE = '/schedule';

	export const PATH_USERS = '/users';
	export const PATH_USERS_CREATE_END = '#create';
	export const PATH_USERS_CREATE = `${PATH_USERS}${PATH_USERS_CREATE_END}`;

	export const PATH_USERS_TYPES = '/users-types';
	export const PATH_USERS_TYPES_CREATE_END = '#create';
	export const PATH_USERS_TYPES_CREATE = `${PATH_USERS_TYPES}${PATH_USERS_TYPES_CREATE_END}`;

	export const PATH_USERS_POSITIONS = '/users-positions';
	export const PATH_USERS_POSITIONS_CREATE_END = '#create';
	export const PATH_USERS_POSITIONS_CREATE = `${PATH_USERS_POSITIONS}${PATH_USERS_POSITIONS_CREATE_END}`;

	export const PATH_USERS_CATEGORIES = '/users-categories';
	export const PATH_USERS_CATEGORIES_CREATE_END = '#create';
	export const PATH_USERS_CATEGORIES_CREATE = `${PATH_USERS_CATEGORIES}${PATH_USERS_CATEGORIES_CREATE_END}`;

	export const PATH_USERS_DEPARTMENTS = '/users-departments';
	export const PATH_USERS_DEPARTMENTS_CREATE_END = '#create';
	export const PATH_USERS_DEPARTMENTS_CREATE = `${PATH_USERS_DEPARTMENTS}${PATH_USERS_DEPARTMENTS_CREATE_END}`;

	export const PATH_USERS_TAXATIONS = '/users-taxations';
	export const PATH_USERS_TAXATIONS_CREATE_END = '#create';
	export const PATH_USERS_TAXATIONS_CREATE = `${PATH_USERS_TAXATIONS}${PATH_USERS_TAXATIONS_CREATE_END}`;

	export const PATH_USERS_TAXATIONS_RECEIPTS = '/users-taxations-receipts';
	export const PATH_USERS_TAXATIONS_RECEIPTS_CREATE_END = '#create';
	export const PATH_USERS_TAXATIONS_RECEIPTS_CREATE = `${PATH_USERS_TAXATIONS_RECEIPTS}${PATH_USERS_TAXATIONS_RECEIPTS_CREATE_END}`;

	export const PATH_USERS_TYPES_HISTORY_UPDATE_END = '#update';

	export const PATH_USERS_SHIFTS = '/projects-shifts';

	export const PATH_EXPENSES_TYPES = '/expenses-types';
	export const PATH_EXPENSES_TYPES_CREATE_END = '#create';
	export const PATH_EXPENSES_TYPES_CREATE = `${PATH_EXPENSES_TYPES}${PATH_EXPENSES_TYPES_CREATE_END}`;

	export const PATH_FINANCIAL_CHANGES_TYPES = '/financial-changes-types';
	export const PATH_FINANCIAL_CHANGES_TYPES_CREATE_END = '#create';
	export const PATH_FINANCIAL_CHANGES_TYPES_CREATE = `${PATH_FINANCIAL_CHANGES_TYPES}${PATH_FINANCIAL_CHANGES_TYPES_CREATE_END}`;

	export const PATH_USERS_FINANCIAL_CHANGES = '/users-financial-changes';
	export const PATH_USERS_FINANCIAL_CHANGES_CREATE_END = '#create';
	export const PATH_USERS_FINANCIAL_CHANGES_CREATE = `${PATH_USERS_FINANCIAL_CHANGES}${PATH_USERS_FINANCIAL_CHANGES_CREATE_END}`;

	export const PATH_GROUPS_SHIFTS_EXPENSES_TYPES = '/groups-shifts-expenses-types';
	export const PATH_GROUPS_SHIFTS_EXPENSES_TYPES_CREATE_END = '#create';
	export const PATH_GROUPS_SHIFTS_EXPENSES_TYPES_CREATE = `${PATH_GROUPS_SHIFTS_EXPENSES_TYPES}${PATH_GROUPS_SHIFTS_EXPENSES_TYPES_CREATE_END}`;

	export const PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES = '/groups-shifts-reports-tags-types';
	export const PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES_CREATE_END = '#create';
	export const PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES_CREATE = `${PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES}${PATH_GROUPS_SHIFTS_REPORTS_TAGS_TYPES_CREATE_END}`;

	export const PATH_USERS_EXPENSES_TYPES = '/users-expenses-types';
	export const PATH_USERS_EXPENSES_TYPES_CREATE_END = '#create';
	export const PATH_USERS_EXPENSES_TYPES_CREATE = `${PATH_USERS_EXPENSES_TYPES}${PATH_USERS_EXPENSES_TYPES_CREATE_END}`;

	export const PATH_USERS_EXPENSES = '/users-expenses';
	export const PATH_USERS_EXPENSES_CREATE_END = '#create';
	export const PATH_USERS_EXPENSES_CREATE = `${PATH_USERS_EXPENSES}${PATH_USERS_EXPENSES_CREATE_END}`;

	export const PATH_USERS_OVERWORKS = '/users-overworks';
	export const PATH_USERS_OVERWORKS_CREATE_END = '#create';
	export const PATH_USERS_OVERWORKS_CREATE = `${PATH_USERS_OVERWORKS}${PATH_USERS_OVERWORKS_CREATE_END}`;

	export const PATH_PAYMENTS_TYPES = '/payments-types';
	export const PATH_PAYMENTS_TYPES_CREATE_END = '#create';
	export const PATH_PAYMENTS_TYPES_CREATE = `${PATH_PAYMENTS_TYPES}${PATH_PAYMENTS_TYPES_CREATE_END}`;

	export const PATH_WORK_AREAS = '/work-areas';
	export const PATH_WORK_AREAS_CREATE_END = '#create';
	export const PATH_WORK_AREAS_CREATE = `${PATH_WORK_AREAS}${PATH_WORK_AREAS_CREATE_END}`;

	export const PATH_PRODUCTION_CALENDARS = '/production-calendars';
	export const PATH_PRODUCTION_CALENDARS_CREATE_END = '#create';
	export const PATH_PRODUCTION_CALENDARS_CREATE = `${PATH_PRODUCTION_CALENDARS}${PATH_PRODUCTION_CALENDARS_CREATE_END}`;

	export const PATH_SCHEDULE_EVENTS_TYPES = '/schedules-events-types';
	export const PATH_SCHEDULE_EVENTS_TYPES_CREATE_END = '#create';
	export const PATH_SCHEDULE_EVENTS_TYPES_CREATE = `${PATH_SCHEDULE_EVENTS_TYPES}${PATH_SCHEDULE_EVENTS_TYPES_CREATE_END}`;

	export const PATH_SCHEDULE_EVENTS = '/schedules-events';
	export const PATH_SCHEDULE_EVENTS_CREATE_END = '#create';
	export const PATH_SCHEDULE_EVENTS_CREATE = `${PATH_SCHEDULE_EVENTS}${PATH_SCHEDULE_EVENTS_CREATE_END}`;

	export const PATH_ARTICLES = '/articles';
	export const PATH_ARTICLES_CREATE_END = '#create';
	export const PATH_ARTICLES_CREATE = `${PATH_ARTICLES}${PATH_ARTICLES_CREATE_END}`;

	export const PATH_ARTICLES_PUBLIC = '/info';

	export const PATH_SYSTEM_SETTINGS_TYPES = '/system-settings-types';
	export const PATH_SYSTEM_SETTINGS = '/system-settings';
	export const PATH_SYSTEM_SETTINGS_USERS = `${PATH_SYSTEM_SETTINGS}-users`;
	export const PATH_SYSTEM_SETTINGS_SHIFTS = `${PATH_SYSTEM_SETTINGS}-shifts`;
	export const PATH_SYSTEM_SETTINGS_REPORTS = `${PATH_SYSTEM_SETTINGS}-reports`;
	export const PATH_SYSTEM_SETTINGS_BOOKKEEPING = `${PATH_SYSTEM_SETTINGS}-bookkeeping`;
	export const PATH_SYSTEM_SETTINGS_CALENDAR_EVENTS = `${PATH_SYSTEM_SETTINGS}-google-calendar-events`;
	export const PATH_SYSTEM_SETTINGS_AMOCRM = `${PATH_SYSTEM_SETTINGS}-amocrm`;

	export const PATH_PROJECTS_STATUSES = '/projects-statuses';
	export const PATH_PROJECTS_STATUSES_CREATE_END = '#create';
	export const PATH_PROJECTS_STATUSES_CREATE = `${PATH_PROJECTS_STATUSES}${PATH_PROJECTS_STATUSES_CREATE_END}`;

	export const PATH_PROJECTS = '/projects';
	export const PATH_PROJECTS_CREATE_END = '#create';
	export const PATH_PROJECTS_CREATE = `${PATH_PROJECTS}${PATH_PROJECTS_CREATE_END}`;

	export const PATH_PROJECTS_CLIENTS = '/projects-clients';
	export const PATH_PROJECTS_CLIENTS_CREATE_END = '#create';
	export const PATH_PROJECTS_CLIENTS_CREATE = `${PATH_PROJECTS_CLIENTS}${PATH_PROJECTS_CLIENTS_CREATE_END}`;

	export const PATH_PROJECTS_ORDERS_TAGS_TYPES = '/projects-orders-tags-types';
	export const PATH_PROJECTS_ORDERS_TAGS_TYPES_CREATE_END = '#create';
	export const PATH_PROJECTS_ORDERS_TAGS_TYPES_CREATE = `${PATH_PROJECTS_ORDERS_TAGS_TYPES}${PATH_PROJECTS_ORDERS_TAGS_TYPES_CREATE_END}`;

	export const PATH_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES = '/projects-orders-groups-shifts-expenses';
	export const PATH_PROJECTS_ORDERS_GROUPS_SHIFTS_CREATE_END_EXPENSES = '#create';
	export const PATH_PROJECTS_ORDERS_GROUPS_SHIFTS_CREATE_EXPENSES = `${PATH_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES}${PATH_PROJECTS_ORDERS_GROUPS_SHIFTS_CREATE_END_EXPENSES}`;

	export const PATH_EQUIPMENTS_STORAGES = '/equipments-storages';
	export const PATH_EQUIPMENTS_STORAGES_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_STORAGES_CREATE = `${PATH_EQUIPMENTS_STORAGES}${PATH_EQUIPMENTS_STORAGES_CREATE_END}`;

	export const PATH_EQUIPMENTS_CATEGORIES = '/equipments-categories';
	export const PATH_EQUIPMENTS_CATEGORIES_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_CATEGORIES_CREATE = `${PATH_EQUIPMENTS_CATEGORIES}${PATH_EQUIPMENTS_CATEGORIES_CREATE_END}`;

	export const PATH_EQUIPMENTS_CATEGORIES_HIERARCHY = '/equipments-categories-hierarchy';
	export const PATH_EQUIPMENTS_CATEGORIES_HIERARCHY_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_CATEGORIES_HIERARCHY_CREATE = `${PATH_EQUIPMENTS_CATEGORIES_HIERARCHY}${PATH_EQUIPMENTS_CATEGORIES_HIERARCHY_CREATE_END}`;

	export const PATH_EQUIPMENTS = '/equipments';
	export const PATH_EQUIPMENTS_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_CREATE = `${PATH_EQUIPMENTS}${PATH_EQUIPMENTS_CREATE_END}`;

	export const PATH_EQUIPMENTS_HIERARCHY = '/equipments-hierarchy';
	export const PATH_EQUIPMENTS_HIERARCHY_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_HIERARCHY_CREATE = `${PATH_EQUIPMENTS_HIERARCHY}${PATH_EQUIPMENTS_HIERARCHY_CREATE_END}`;

	export const PATH_EQUIPMENTS_GROUPS = '/equipments-groups';
	export const PATH_EQUIPMENTS_GROUPS_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_GROUPS_CREATE = `${PATH_EQUIPMENTS_GROUPS}${PATH_EQUIPMENTS_GROUPS_CREATE_END}`;

	export const PATH_EQUIPMENTS_ORDERS = '/equipments-orders';
	export const PATH_EQUIPMENTS_ORDERS_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_ORDERS_CREATE = `${PATH_EQUIPMENTS_ORDERS}${PATH_EQUIPMENTS_ORDERS_CREATE_END}`;

	export const PATH_EQUIPMENTS_ORDERS_STATUSES = '/equipments-orders-statuses';
	export const PATH_EQUIPMENTS_ORDERS_STATUSES_CREATE_END = '#create';
	export const PATH_EQUIPMENTS_ORDERS_STATUSES_CREATE = `${PATH_EQUIPMENTS_ORDERS_STATUSES}${PATH_EQUIPMENTS_ORDERS_STATUSES_CREATE_END}`;

	export const PATH_ERROR = '*';

	export const PATH_STATIC = `${process.env.REACT_APP_BACKEND_URL}/static`;
	export const PATH_STATIC_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS_FILES = `${PATH_STATIC}/projects-orders-groups-shifts-reports-files`;
	export const PATH_STATIC_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES_FILES = `${PATH_STATIC}/projects-orders-groups-shifts-expenses-files`;
	export const PATH_STATIC_USER_IMAGES = `${PATH_STATIC}/users-images`;
	export const PATH_STATIC_USERS_PASSPORTS_FILES = `${PATH_STATIC}/users-passports-files`;
	export const PATH_STATIC_USERS_TAXATION_DATA_FILES = `${PATH_STATIC}/users-taxations-data-files`;
	export const PATH_STATIC_USERS_WORK_DATA_FILES = `${PATH_STATIC}/users-work-data-files`;
	export const PATH_STATIC_USERS_INTERNATIONAL_PASSPORTS_FILES = `${PATH_STATIC}/users-international-passports-files`;
	export const PATH_STATIC_EQUIPMENTS_FILES = `${PATH_STATIC}/equipments-files`;
	export const PATH_STATIC_PROJECTS_FILES = `${PATH_STATIC}/projects-files`;
	export const PATH_STATIC_PROJECTS_IMAGES = `${PATH_STATIC}/projects-images`;
	export const PATH_STATIC_EXPERIENCE_IMAGES = `${PATH_STATIC}/experiences-images`;
	export const PATH_STATIC_USERS_EXPENSES_FILES = `${PATH_STATIC}/users-expenses-files`;
	export const PATH_STATIC_USERS_FINANCIAL_CHANGES_FILES = `${PATH_STATIC}/users-financial-changes-files`;
	export const PATH_STATIC_SCHEDULE_EVENTS_FILES = `${PATH_STATIC}/schedule-events-files`;
