import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountEmailPage = lazy(() => import('../views/update/AccountEmailPage'));

export function AccountEmailPageLazy() {
	return (
		<PageSuspense>
			<AccountEmailPage />
		</PageSuspense>
	);
}
