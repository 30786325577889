import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserTaxationReceiptUpdatePage = lazy(() => import('../views/update/UserTaxationReceiptUpdatePage'));

export function UserTaxationReceiptUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserTaxationReceiptUpdatePage />
		</PageSuspense>
	);
}
