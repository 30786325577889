import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserCategoryChangePayloadUpdate, TUserResponse, adaptUserFromResponse, adaptUserCategoryChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCategoryChangeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/categories/:id`,
	(payload: TUserCategoryChangePayloadUpdate, thunkApi) => {
		const { nickname, id, data } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/categories/${id}`;
		const dataRequest = adaptUserCategoryChangeToRequest(data);

		return HttpService.put<TUserResponse>(path, dataRequest).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryChangeUpdateSlice = createSlice({
	name: 'userCategoryChangeUpdateSlice',
	initialState,
	reducers: {
		clearUserCategoryChangeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryChangeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryChangeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryChangeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryChangeUpdateSelector = () => useAppSelector(state => state[userCategoryChangeUpdateSlice.name]);

export const { clearUserCategoryChangeUpdate } = userCategoryChangeUpdateSlice.actions;
