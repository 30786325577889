import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroupShiftReport, TProjectOrderGroupShiftReportPayloadUpdate, TProjectOrderGroupShiftReportResponse, adaptProjectOrderGroupShiftReportFromResponse, adaptProjectOrderGroupShiftReportToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupShiftReportUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts/:shiftId/report`,
	(payload: TProjectOrderGroupShiftReportPayloadUpdate, thunkApi) => {
		const { shiftId, groupId, orderId, projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts/${shiftId}/report`;

		const dataRequest = adaptProjectOrderGroupShiftReportToRequest(data);

		return HttpService.put<TProjectOrderGroupShiftReportResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderGroupShiftReportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftReportUpdateSlice = createSlice({
	name: 'projectOrderGroupShiftReportUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupShiftReportUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftReportUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftReportUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftReportUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftReportUpdateSelector = () => useAppSelector(state => state[projectOrderGroupShiftReportUpdateSlice.name]);

export const { clearProjectOrderGroupShiftReportUpdate } = projectOrderGroupShiftReportUpdateSlice.actions;
