import { FormField } from 'src/components';
import { TEquipmentOrderRelationEquipmentGroupCreate } from 'src/store';

import { EquipmentGroupListBoxWrap } from './EquipmentGroupListBoxWrap';

import styles from './EquipmentGroupListBox.module.scss';
import { libCloneObject } from 'src/toolkit';


type TProps = {
	legend?: string;
	title?: string;
	placeholder?: string;

	setErrors?: (value: string[]) => void;
	errors?: string[];
	isLoading?: boolean;
	required?: boolean;

	items: TEquipmentOrderRelationEquipmentGroupCreate[];
	setItems: (values: TEquipmentOrderRelationEquipmentGroupCreate[]) => void;
};

export function EquipmentGroupListBox(props: TProps) {
	const {
		items,
		setItems,

		errors,
		setErrors,
		isLoading,
		required,

		legend,
		title,
		placeholder,
	} = props;

	const onDelete = (value: number) => {
		const filtered = items.filter(item => item.group.id !== value);

		setItems(filtered);

		if (setErrors) {
			setErrors([]);
		}
	};

	const changeAmount = (groupId: number, amount: number) => {
		let i = 0;

		const equipmentGroupRelation = items.find((item, index) => {
			i = index;

			return item.group.id === groupId;
		});

		if (equipmentGroupRelation && equipmentGroupRelation.group.amount && amount <= equipmentGroupRelation.group.amount) {
			const equipmentsRelationsClonned = libCloneObject(items);
			const equipmentRelatGroupionClone = equipmentsRelationsClonned[i];

			equipmentRelatGroupionClone.amount = amount;

			setItems(equipmentsRelationsClonned);

			if (setErrors) {
				setErrors([]);
			}
		}
	};

	const classNames = [styles.box];

	if (isLoading) {
		classNames.push(styles.isLoading);
	}

	return (
		<FormField
			legend={ legend }
			required={ required }
			requiredMark={ false }
			className={ classNames.join(' ') }
			errors={ errors }
		>

			<EquipmentGroupListBoxWrap
				title={ title }
				placeholder={ placeholder }
				items={ items }
				onDelete={ onDelete }
				changeAmount={ changeAmount }
			/>
		</FormField>
	);
}
