import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectsOrdersTagsTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersTagsTypesSourceSortCol = TTableSortCols<typeof ProjectsOrdersTagsTypesSourceSortCol>;

export type TProjectOrderTagTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersTagsTypesSourceSortCol;
};

export type TProjectsOrdersTagsTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsOrdersTagsTypesSourceQueryToRequest(data?: TProjectOrderTagTypeSourceQuery): TProjectsOrdersTagsTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderTagTypeSource = {
  id: number;
  name: string;
  description?: string;
};

export type TProjectOrderTagTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TProjectOrderTagTypeSourceListResponse = TListResponse<TProjectOrderTagTypeSourceResponse>;

export type TProjectOrderTagTypeSourceList = TListResponse<TProjectOrderTagTypeSource>;

export function adaptProjectOrderTagTypeSourceFromListResponse(data: TProjectOrderTagTypeSourceListResponse): TProjectOrderTagTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderTagTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderTagTypeSourceFromResponse(data: TProjectOrderTagTypeSourceResponse): TProjectOrderTagTypeSource {
	const {
		id,
		name,
		description,
	} = data;

	return {
		id,
		name,
		description,
	};
}
