import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserShift, TUserShiftPayloadUpdate, TUserShiftResponse, adaptUserShiftFromResponse, adaptUserShiftToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_SHIFTS } from '../../backend-paths';


export const usersShiftUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_SHIFTS}/:id`,
	(payload: TUserShiftPayloadUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_SHIFTS}/${id}`;

		const dataRequest = adaptUserShiftToRequest(data);

		return HttpService.put<TUserShiftResponse>(path, dataRequest).then(result => {
			return adaptUserShiftFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserShift> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const usersShiftUpdateSlice = createSlice({
	name: 'usersShiftUpdateSlice',
	initialState,
	reducers: {
		clearUserShiftUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[usersShiftUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersShiftUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserShift>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[usersShiftUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserShiftUpdateSelector = () => useAppSelector(state => state[usersShiftUpdateSlice.name]);

export const { clearUserShiftUpdate } = usersShiftUpdateSlice.actions;
