import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectsOrdersPage = lazy(() => import('../views/list/ProjectsOrdersPage'));

export function ProjectsOrdersPageLazy() {
	return (
		<PageSuspense>
			<ProjectsOrdersPage />
		</PageSuspense>
	);
}
