import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentOrder, TEquipmentOrderUpdate, TEquipmentOrderResponse, adaptEquipmentOrderFromResponse, adaptEquipmentOrderToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS } from '../../backend-paths';


export const equipmentOrderUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/:id`,
	(payload: TEquipmentOrderUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/${id}`;

		const dataRequest = adaptEquipmentOrderToRequest(data);

		return HttpService.put<TEquipmentOrderResponse>(path, dataRequest).then(result => {
			return adaptEquipmentOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderUpdateSlice = createSlice({
	name: 'equipmentOrderUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentOrderUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentOrderUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentOrderUpdateSelector = () => useAppSelector(state => state[equipmentOrderUpdateSlice.name]);

export const { clearEquipmentOrderUpdate } = equipmentOrderUpdateSlice.actions;
