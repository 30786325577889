import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { GroupsShiftsExpensesTypesSortCol, TGroupShiftExpenseTypeList, TGroupShiftExpenseTypeListResponse, TGroupShiftExpenseTypeQuery, TGroupsShiftsExpensesTypesSortCol, adaptGroupShiftExpenseTypeFromListResponse, adaptGroupsShiftsExpensesTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES } from '../../backend-paths';


export const groupsShiftsExpensesTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES,
	(payload: TGroupShiftExpenseTypeQuery | undefined, thunkApi) => {
		const params = adaptGroupsShiftsExpensesTypesQueryToRequest(payload);

		return HttpService.get<TGroupShiftExpenseTypeListResponse>(BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES, { params }).then(result => {
			return adaptGroupShiftExpenseTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TGroupShiftExpenseTypeList;
	query: TGroupShiftExpenseTypeQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: GroupsShiftsExpensesTypesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const groupsShiftsExpensesTypesSlice = createSlice({
	name: 'groupsShiftsExpensesTypesSlice',
	initialState,
	reducers: {
		setGroupsShiftsExpensesTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setGroupsShiftsExpensesTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setGroupsShiftsExpensesTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setGroupsShiftsExpensesTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setGroupsShiftsExpensesTypesQuerySortCol(state, action: PayloadAction<TGroupsShiftsExpensesTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearGroupsShiftsExpensesTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[groupsShiftsExpensesTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[groupsShiftsExpensesTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftExpenseTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[groupsShiftsExpensesTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useGroupsShiftsExpensesTypesSelector = () => useAppSelector(state => state[groupsShiftsExpensesTypesSlice.name]);

export const { clearGroupsShiftsExpensesTypes, setGroupsShiftsExpensesTypesQueryLimit, setGroupsShiftsExpensesTypesQueryOffset, setGroupsShiftsExpensesTypesQuerySort, setGroupsShiftsExpensesTypesQuerySortCol, setGroupsShiftsExpensesTypesQuerySearch } = groupsShiftsExpensesTypesSlice.actions;
