import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './PageButtonLink.module.scss';


type TProps = {
	className?: string;
	href?: string;
	text?: string;
	style?: Record<string, string>;
	children?: ReactNode;
	disabled?: boolean;
};

export function PageButtonLink(props: TProps) {
	const {
		className = '',
		href = '#',
		text = '',
		children,
		disabled = false,
	} = props;

	const classNames = [styles.pageButtonLink];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	if (disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<NavLink
			to={ href }
			className={ classNames.join(' ') }
		>
			{ children }
			<div className={ styles.text }>{ text }</div>
		</NavLink>
	);
}
