import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersExpensesPage = lazy(() => import('../views/list/UsersExpensesPage'));

export function UsersExpensesPageLazy() {
	return (
		<PageSuspense>
			<UsersExpensesPage />
		</PageSuspense>
	);
}
