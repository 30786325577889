import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectsSourceSortCol = {
	NAME: 'name',
	WORK_AREA: 'work_area_id',
	TRADE_ID: 'trade_id',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsSourceSortCol = TTableSortCols<typeof ProjectsSourceSortCol>;

export type TProjectSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsSourceSortCol;
};

export type TProjectsSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsSourceQueryToRequest(data?: TProjectSourceQuery): TProjectsSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectSource = {
  id: number;
  name: string;
  tradeId?: string;
};

export type TProjectSourceResponse = {
  id: number;
  name: string;
	trade_id?: string;
};

export type TProjectSourceListResponse = TListResponse<TProjectSourceResponse>;

export type TProjectSourceList = TListResponse<TProjectSource>;

export function adaptProjectSourceFromListResponse(data: TProjectSourceListResponse): TProjectSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectSourceFromResponse(data: TProjectSourceResponse): TProjectSource {
	const {
		id,
		name,
		trade_id: tradeId,
	} = data;

	return {
		id,
		name,
		tradeId,
	};
}
