import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingAmocrmClientId = TSystemSettingValueCommon & {
  value?: string;
};

export type TSystemSettingAmocrmClientIdResponse = TSystemSettingValueCommonResponse & {
  value?: string;
};

export type TSystemSettingAmocrmClientIdPayload = {
	value?: string;
};

export type TSystemSettingAmocrmClientIdRequest = {
	value?: string;
};

export function adaptSystemSettingAmocrmClientIdFromResponse(data: TSystemSettingAmocrmClientIdResponse): TSystemSettingAmocrmClientId {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingAmocrmClientIdToRequest(data: TSystemSettingAmocrmClientIdPayload): TSystemSettingAmocrmClientIdRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
