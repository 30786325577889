import { libClearIntstr } from 'src/toolkit';

export type TUserRequisites = {
	accountNumber: string;
  bankName: string;
  bik: string;
  corAccount: string;
	createdAt: string;
  updatedAt: string;
};

export type TUserRequisitesBodyRequest = {
	accountNumber?: string;
  bankName?: string;
  bik?: string;
  corAccount?: string;
};

export type TUserRequisitesResponse = {
	account_number: string;
  bank_name: string;
  bik: string;
  cor_account: string;
  created_at: string;
  updated_at: string;
};

export type TUserRequisitesRequest = {
	account_number?: string;
  bank_name?: string;
  bik?: string;
  cor_account?: string;
};

export type TUserRequisitesPayload = {
	nickname: string;
	data: TUserRequisitesBodyRequest;
};

export function adaptUserRequisitesFromResponse(data: TUserRequisitesResponse): TUserRequisites {
	const {
		account_number: accountNumber = '',
		bank_name: bankName = '',
		bik = '',
		cor_account: corAccount = '',
		updated_at: createdAt,
		created_at: updatedAt,
	} = data;

	return {
		accountNumber,
		bankName,
		bik,
		corAccount,
		createdAt,
		updatedAt,
	};
}

export function adaptUserRequisitesToRequest(data: TUserRequisitesBodyRequest): TUserRequisitesRequest {
	const {
		accountNumber,
		bankName,
		bik,
		corAccount,
	} = data;

	return {
		account_number: libClearIntstr(accountNumber),
		bank_name: bankName,
		bik: libClearIntstr(bik),
		cor_account: libClearIntstr(corAccount),
	};
}

