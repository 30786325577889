import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { UserDepartmentChangeSortCol, TUserDepartmentChangeList, TUserDepartmentChangeQuery, TUserDepartmentChangeListResponse, TUserDepartmentChangeSortCol, adaptUserDepartmentChangeFromListResponse, adaptUserDepartmentChangeQueryRequest } from '../../types';
import { BACKEND_ACCOUNT_DEPARTMENT_CHANGES } from '../../backend-paths';


export const accountDepartmentChangesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_DEPARTMENT_CHANGES}`,
	(payload: TUserDepartmentChangeQuery, thunkApi) => {
		const params = payload ? adaptUserDepartmentChangeQueryRequest(payload) : {};

		return HttpService.get<TUserDepartmentChangeListResponse>(BACKEND_ACCOUNT_DEPARTMENT_CHANGES, { params }).then(result => {
			return adaptUserDepartmentChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserDepartmentChangeList,
	query: TUserDepartmentChangeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: UserDepartmentChangeSortCol.CREATED_AT,
	},
};

export const accountDepartmentChangesSlice = createSlice({
	name: 'accountDepartmentChangesSlice',
	initialState,
	reducers: {
		setAccountDepartmentChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountDepartmentChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountDepartmentChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountDepartmentChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountDepartmentChangesQuerySortCol(state, action: PayloadAction<TUserDepartmentChangeSortCol>) {
			state.query.sortCol = action.payload;
		},

		setAccountDepartmentChangesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountDepartmentChangesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearAccountDepartmentChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[accountDepartmentChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountDepartmentChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartmentChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountDepartmentChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountDepartmentChangeSelector = () => useAppSelector(state => state[accountDepartmentChangesSlice.name]);

export const { clearAccountDepartmentChanges, setAccountDepartmentChangesQueryLimit, setAccountDepartmentChangesQueryOffset, setAccountDepartmentChangesQuerySort, setAccountDepartmentChangesQuerySortCol, setAccountDepartmentChangesQuerySearch, setAccountDepartmentChangesQueryDateStart, setAccountDepartmentChangesQueryDateEnd } = accountDepartmentChangesSlice.actions;
