import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserShiftExpenseReadPage = lazy(() => import('../views/update/UserShiftExpenseReadPage'));

export function UserShiftExpenseReadPageLazy() {
	return (
		<PageSuspense>
			<UserShiftExpenseReadPage />
		</PageSuspense>
	);
}
