// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_layout__4V69M {\n  background-color: rgb(10, 10, 30);\n  color: rgb(230, 230, 230);\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/layouts/default/DefaultLayout.module.scss"],"names":[],"mappings":"AAAA;EACC,iCAAA;EACA,yBAAA;EACA,sBAAA;AACD","sourcesContent":[".layout {\n\tbackground-color: rgba(10, 10, 30, 1);\n\tcolor: rgba(230, 230, 230, 1);\n\tbox-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "DefaultLayout_layout__4V69M"
};
export default ___CSS_LOADER_EXPORT___;
