import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderSource, TProjectOrderSourceResponse, adaptProjectOrderSourceFromResponse } from './project-order-source.type';
import { TEquipmentOrderStatusSource, TEquipmentOrderStatusSourceResponse, adaptEquipmentOrderStatusSourceFromResponse } from './equipment-order-status-source.type';
import { TEquipmentOrderRelationEquipment, TEquipmentOrderRelationEquipmentResponse, adaptEquipmentOrderRelationEquipmentFromResponse } from './equipment-order-relation-equipment.type';
import { TEquipmentOrderRelationEquipmentGroup, TEquipmentOrderRelationEquipmentGroupResponse, adaptEquipmentOrderRelationEquipmentGroupFromResponse } from './equipment-order-relation-equipment-group.type';


export const EquipmentsOrdersSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsOrdersSortCol = TTableSortCols<typeof EquipmentsOrdersSortCol>;

export type TEquipmentOrderQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsOrdersSortCol;
};

export type TEquipmentsOrdersQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsOrdersQueryToRequest(data?: TEquipmentOrderQuery): TEquipmentsOrdersQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentOrder = {
  id: number;
	dateStart: string;
	dateEnd: string;
	createdAt: string;
  updatedAt: string;

	user: TUserMeta;
	projectOrder: TProjectOrderSource;
	status: TEquipmentOrderStatusSource;
	equipmentsRelations: TEquipmentOrderRelationEquipment[];
	equipmentsGroupsRelations: TEquipmentOrderRelationEquipmentGroup[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentOrderResponse = {
  id: number;
	date_start: string;
	date_end: string;
  created_at: string;
  updated_at: string;

	user: TUserMetaResponse;
	project_order: TProjectOrderSourceResponse;
	status: TEquipmentOrderStatusSourceResponse;
	equipments_relations: TEquipmentOrderRelationEquipmentResponse[];
	equipments_groups_relations: TEquipmentOrderRelationEquipmentGroupResponse[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentOrderListResponse = TListResponse<TEquipmentOrderResponse>;

export type TEquipmentOrderList = TListResponse<TEquipmentOrder>;

type TEquipmentDataPayload = {
	id?: number;
	amount: number;
	equipmentId: number;
};

type TEquipmentData = {
	id?: number;
	amount: number;
	equipment_id: number;
};

type TEquipmentGroupDataPayload = {
	id?: number;
	amount: number;
	groupId: number;
};

type TEquipmentGroupData = {
	id?: number;
	amount: number;
	group_id: number;
};

export type TEquipmentOrderBodyRequest = {
	userNickname: string;
	projectOrderId: number;
	dateStart: string;
	timeStart: string;
	dateEnd: string;
	timeEnd: string;
	statusId: number;
	equipments: TEquipmentDataPayload[];
	equipmentsGroups: TEquipmentGroupDataPayload[];
};

export type TEquipmentOrderRequest = {
	user_nickname: string;
	project_order_id: number;
	date_start: string;
	date_end: string;
	status_id: number;
	equipments: TEquipmentData[];
	equipments_groups: TEquipmentGroupData[];
};

export type TEquipmentOrderUpdate = {
	id: number;
	data: TEquipmentOrderBodyRequest;
};

export function adaptEquipmentOrderFromListResponse(data: TEquipmentOrderListResponse): TEquipmentOrderList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentOrderFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentOrderFromResponse(data: TEquipmentOrderResponse): TEquipmentOrder {
	const {
		id,
		date_start: dateStart,
		date_end: dateEnd,
		user: userRaw,
		project_order: projectOrderRaw,
		status: statusRaw,
		equipments_relations: equipmentsRelationsRaw = [],
		equipments_groups_relations: equipmentsGroupsRelationsRaw = [],
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const user = adaptUserMetaFromResponse(userRaw);
	const projectOrder = adaptProjectOrderSourceFromResponse(projectOrderRaw);
	const status = adaptEquipmentOrderStatusSourceFromResponse(statusRaw);
	const equipmentsRelations = equipmentsRelationsRaw.map(item => adaptEquipmentOrderRelationEquipmentFromResponse(item));
	const equipmentsGroupsRelations = equipmentsGroupsRelationsRaw.map(item => adaptEquipmentOrderRelationEquipmentGroupFromResponse(item));
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		dateStart,
		dateEnd,
		user,
		projectOrder,
		status,
		equipmentsRelations,
		equipmentsGroupsRelations,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentOrderToRequest(data: TEquipmentOrderBodyRequest): TEquipmentOrderRequest {
	const {
		userNickname,
		projectOrderId,
		dateStart,
		timeStart,
		dateEnd,
		timeEnd,
		statusId,
		equipments: equipmentsRaw = [],
		equipmentsGroups: equipmentsGroupsRaw = [],
	} = data;

	const equipments = equipmentsRaw.map(item => {
		const { id, amount, equipmentId } = item;

		return {
			id,
			amount,
			equipment_id: equipmentId,
		};
	});

	const equipmentsGroups = equipmentsGroupsRaw.map(item => {
		const { id, amount, groupId } = item;

		return {
			id,
			amount,
			group_id: groupId,
		};
	});

	return {
		user_nickname: userNickname,
		project_order_id: projectOrderId,
		date_start: `${dateStart}T${timeStart}:00.000`,
		date_end: `${dateEnd}T${timeEnd}:00.000`,
		status_id: statusId,
		equipments,
		equipments_groups: equipmentsGroups,
	};
}
