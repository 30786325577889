import { FormField, TFormInputFieldReadonlyFontColor } from 'src/components';
import { TEquipmentOrderRelationEquipmentCreate } from 'src/store';

import { EquipmentListWrap } from './EquipmentListWrap';

import styles from './EquipmentList.module.scss';


export type TEquipmentValue = TEquipmentOrderRelationEquipmentCreate & {
	fontColorName?: TFormInputFieldReadonlyFontColor;
	fontColorAmount?: TFormInputFieldReadonlyFontColor;
};

type TProps = {
	legend?: string;
	items?: TEquipmentValue[];
};

export function EquipmentList(props: TProps) {
	const {
		legend,

		items,
	} = props;

	const classNames = [styles.box];

	return (
		<FormField
			legend={ legend }
			className={ classNames.join(' ') }
		>

			<EquipmentListWrap
				items={ items }
			/>
		</FormField>
	);
}
