import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountTaxationDataPage = lazy(() => import('../views/update/AccountTaxationDataPage'));

export function AccountTaxationDataPageLazy() {
	return (
		<PageSuspense>
			<AccountTaxationDataPage />
		</PageSuspense>
	);
}
