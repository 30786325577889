import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TEquipmentStorage, TEquipmentStorageResponse, adaptEquipmentStorageFromResponse } from './equipment-storage.type';
import { TEquipmentCategory, TEquipmentCategoryResponse, adaptEquipmentCategoryFromResponse } from './equipment-category.type';
import { TEquipmentUser, TEquipmentUserResponse, adaptEquipmentUserFromResponse } from './equipment-user.type';


export const EquipmentsSortCol = {
	NAME: 'name',
	PUBLIC_ID: 'public_id',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsSortCol = TTableSortCols<typeof EquipmentsSortCol>;

export type TEquipmentQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsSortCol;
};

export type TEquipmentsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsQueryToRequest(data?: TEquipmentQuery): TEquipmentsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentHierarchyQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsSortCol;
	categoryId?: number | null;
};

export type TEquipmentsHierarchyQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
	category_id?: string;
};

export function adaptEquipmentsHierarchyQueryToRequest(data?: TEquipmentHierarchyQuery): TEquipmentsHierarchyQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		categoryId,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		category_id: categoryId !== undefined ? JSON.stringify(categoryId) : categoryId,
		...restFields
	};
}

export type TEquipment = {
	id: number;
	name: string;
	description?: string;
	publicId?: string;
	serialNumber: string;
	storagePlace: string;
	amount: number;
	isActive: boolean;
	createdAt: string;
	updatedAt: string;

	availableAmount?: number;

	files: TEquipmentFile[];
	equipmentsUsers: TEquipmentUser[];

	category: TEquipmentCategory;
	storage: TEquipmentStorage;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentResponse = {
	id: number;
	name: string;
	description?: string;
	public_id?: string;
	serial_number: string;
	storage_place: string;
	amount: number;
	is_active: boolean;
	created_at: string;
	updated_at: string;

	files: TEquipmentFile[];
	equipments_users: TEquipmentUserResponse[];

	available_amount?: number;

	category: TEquipmentCategoryResponse;
	storage: TEquipmentStorageResponse;
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentListResponse = TListResponse<TEquipmentResponse>;

export type TEquipmentList = TListResponse<TEquipment>;

export type TEquipmentBodyRequest = {
	name: string;
	description?: string;
  publicId: string;
	serialNumber?: string;
	storagePlace?: string;
	amount: number;
	isActive: boolean;
	categoryId: number;
	storageId: number;
};

export type TEquipmentRequest = {
	name: string;
	description?: string;
  public_id: string;
	serial_number?: string;
	storage_place?: string;
	amount: number;
	is_active: boolean;
	category_id: number;
	storage_id: number;
};

export type TEquipmentFile = {
	name: string;
	path: string;
};

export type TEquipmentFileRequest = Array<File | string>;

export type TEquipmentPayload = {
	data: TEquipmentBodyRequest;
	files: TEquipmentFileRequest;
};

export type TEquipmentUpdatePayload = {
	id: number;
	data: TEquipmentBodyRequest;
	files: TEquipmentFileRequest;
};

export function adaptEquipmentFromListResponse(data: TEquipmentListResponse): TEquipmentList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentFromResponse(data: TEquipmentResponse): TEquipment {
	const {
		id,
		name,
		description,
		public_id: publicId,
		serial_number: serialNumber,
		storage_place: storagePlace,
		amount,
		is_active: isActive,

		files = [],
		equipments_users: equipmentsUsersRaw = [],

		available_amount: availableAmount,

		category: categoryRaw,
		storage: storageRaw,

		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const category = adaptEquipmentCategoryFromResponse(categoryRaw);
	const storage = adaptEquipmentStorageFromResponse(storageRaw);
	const equipmentsUsers = equipmentsUsersRaw.map(item => adaptEquipmentUserFromResponse(item));
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		publicId,
		serialNumber,
		storagePlace,
		amount,
		isActive,
		createdAt,
		updatedAt,
		files,
		equipmentsUsers,
		availableAmount,
		category,
		storage,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentToRequest(data: TEquipmentBodyRequest): TEquipmentRequest {
	const {
		name,
		description = undefined,
		publicId,
		serialNumber = undefined,
		storagePlace = undefined,
		amount,
		isActive,
		categoryId,
		storageId,
	} = data;

	return {
		name,
		description,
		public_id: publicId,
		serial_number: serialNumber,
		storage_place: storagePlace,
		amount,
		is_active: isActive,
		category_id: categoryId,
		storage_id: storageId,
	};
}
