import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ExpensesTypesSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TExpensesTypesSortCol = TTableSortCols<typeof ExpensesTypesSortCol>;

export type TExpenseTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TExpensesTypesSortCol;
};

export type TExpensesTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptExpensesTypesQueryToRequest(data?: TExpenseTypeQuery): TExpensesTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TExpenseType = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TExpenseTypeResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TExpenseTypeListResponse = TListResponse<TExpenseTypeResponse>;

export type TExpenseTypeList = TListResponse<TExpenseType>;

export type TExpenseTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TExpenseTypeRequest = {
	name: string;
	description?: string;
};

export type TExpenseTypeUpdate = {
	id: number;
	data: TExpenseTypeBodyRequest;
};

export function adaptExpenseTypeFromListResponse(data: TExpenseTypeListResponse): TExpenseTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptExpenseTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptExpenseTypeFromResponse(data: TExpenseTypeResponse): TExpenseType {
	const {
		id,
		name,
		description,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptExpenseTypeToRequest(data: TExpenseTypeBodyRequest): TExpenseTypeRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
