import { SignUpPageStage, useSignUpSelector } from 'src/store';

import { SignUpStageSuccess } from '../../../../components';

import { SignUpStageEmailSuccess, SignUpStageMain, SignUpStagePhoneNumberConfirm } from './components';


export function SignUpRouter() {
	const { stage, email } = useSignUpSelector();

	if (stage === SignUpPageStage.EMAIL_SUCCESS) {
		return (
			<SignUpStageEmailSuccess email={ email } />
		);
	}

	if (stage === SignUpPageStage.PHONE_NUMBER_CONFIRM) {
		return (
			<SignUpStagePhoneNumberConfirm />
		);
	}

	if (stage === SignUpPageStage.PHONE_NUMBER_SUCCESS) {
		return (
			<SignUpStageSuccess
				headline="Ваш аккаунт был создан и успешно подтверждён!"
			/>
		);
	}

	return (
		<SignUpStageMain />
	);
}

