import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectOrderGroupShiftExpense, TProjectOrderGroupShiftExpenseResponse, adaptProjectOrderGroupShiftExpenseFromResponse } from '../../types';
import { BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES } from '../../backend-paths';


export const projectOrderGroupShiftExpenseReadGetApi = createAsyncThunk(
	`GET/${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES}/${payload}`;

		return HttpService.get<TProjectOrderGroupShiftExpenseResponse>(path).then(result => {
			return adaptProjectOrderGroupShiftExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftExpenseReadSlice = createSlice({
	name: 'projectOrderGroupShiftExpenseReadSlice',
	initialState,
	reducers: {
		setProjectOrderGroupShiftExpenseRead(state, action: PayloadAction<TProjectOrderGroupShiftExpense>) {
			state.item = action.payload;
		},

		clearProjectOrderGroupShiftExpenseRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftExpenseReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftExpenseReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftExpenseReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftExpenseReadSelector = () => useAppSelector(state => state[projectOrderGroupShiftExpenseReadSlice.name]);

export const { clearProjectOrderGroupShiftExpenseRead, setProjectOrderGroupShiftExpenseRead } = projectOrderGroupShiftExpenseReadSlice.actions;
