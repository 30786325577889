import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const EquipmentsStoragesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsStoragesSortCol = TTableSortCols<typeof EquipmentsStoragesSortCol>;

export type TEquipmentStorageQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsStoragesSortCol;
};

export type TEquipmentsStoragesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsStoragesQueryToRequest(data?: TEquipmentStorageQuery): TEquipmentsStoragesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentStorage = {
  id: number;
  name: string;
  description?: string;
  address?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentStorageResponse = {
  id: number;
  name: string;
  description?: string;
  address?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentStorageListResponse = TListResponse<TEquipmentStorageResponse>;

export type TEquipmentStorageList = TListResponse<TEquipmentStorage>;

export type TEquipmentStorageBodyRequest = {
	name: string;
	description?: string;
  address?: string;
};

export type TEquipmentStorageRequest = {
	name: string;
	description?: string;
  address?: string;
};

export type TEquipmentStorageUpdate = {
	id: number;
	data: TEquipmentStorageBodyRequest;
};

export function adaptEquipmentStorageFromListResponse(data: TEquipmentStorageListResponse): TEquipmentStorageList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentStorageFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentStorageFromResponse(data: TEquipmentStorageResponse): TEquipmentStorage {
	const {
		id,
		name,
		description,
		address,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		address,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentStorageToRequest(data: TEquipmentStorageBodyRequest): TEquipmentStorageRequest {
	const {
		name,
		description = undefined,
		address = undefined,
	} = data;

	return {
		name,
		description,
		address,
	};
}
