import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectsStatusesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsStatusesSourceSortCol = TTableSortCols<typeof ProjectsStatusesSourceSortCol>;

export type TProjectStatusSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsStatusesSourceSortCol;
};

export type TProjectsStatusesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsStatusesSourceQueryToRequest(data?: TProjectStatusSourceQuery): TProjectsStatusesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectStatusSource = {
  id: number;
  name: string;
};

export type TProjectStatusSourceResponse = {
  id: number;
  name: string;
};

export type TProjectStatusSourceListResponse = TListResponse<TProjectStatusSourceResponse>;

export type TProjectStatusSourceList = TListResponse<TProjectStatusSource>;

export function adaptProjectStatusSourceFromListResponse(data: TProjectStatusSourceListResponse): TProjectStatusSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectStatusSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectStatusSourceFromResponse(data: TProjectStatusSourceResponse): TProjectStatusSource {
	const { id, name } = data;

	return { id, name };
}
