import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_SOURCE_USER_CATEGORY } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserCategory, TUserCategoryList, TUserCategoryListResponse, adaptUserCategoryFromListResponse } from '../../types';


export const sourceUserCategoryListGetApi = createAsyncThunk(
	`FETCH/${BACKEND_SOURCE_USER_CATEGORY}`,
	(payload, thunkApi) => {
		return HttpService.get<TUserCategoryListResponse>(BACKEND_SOURCE_USER_CATEGORY).then(result => {
			return adaptUserCategoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TUserCategory[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
};

export const sourceUserCategoryListSlice = createSlice({
	name: 'sourceUserCategoryListSlice',
	initialState,
	reducers: {
		clearSourceUserCategoryList(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[sourceUserCategoryListGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceUserCategoryListGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategoryList>) => {
			state.isLoading = false;
			state.items = action.payload.items;
			state.error = undefined;
		},

		[sourceUserCategoryListGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceUserCategoryListSelector = () => useAppSelector(state => state[sourceUserCategoryListSlice.name]);

export const { clearSourceUserCategoryList } = sourceUserCategoryListSlice.actions;
