import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountCard, TAccountCardResponse, adaptAccountCardFromResponse } from '../../types';
import { BACKEND_ACCOUNT_CARD } from '../../backend-paths';


export const accountCardReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CARD}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountCardResponse>(BACKEND_ACCOUNT_CARD).then(result => {
			return adaptAccountCardFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountCard> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCardReadSlice = createSlice({
	name: 'accountCardReadSlice',
	initialState,
	reducers: {
		setAccountCardRead(state, action: PayloadAction<TAccountCard>) {
			state.item = action.payload;
		},

		clearAccountCardRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCardReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCardReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCard>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCardReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountCardReadSelector = () => useAppSelector(state => state[accountCardReadSlice.name]);

export const { clearAccountCardRead, setAccountCardRead } = accountCardReadSlice.actions;
