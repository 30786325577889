import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TFormImageFieldExistedValue } from '../../../../FormImageField';
import { FormImageFieldPreview } from '../../../form-image-field-preview';

import styles from '../../../styles/FormImageFieldListValueRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	baseUrl: string;
	file: TFormImageFieldExistedValue;
	onDelete: (value: TFormImageFieldExistedValue) => void;
	show: (src: string, name: string) => void;
};

export function FormImageFieldListValueRow(props: TProps) {
	const {
		onDelete,
		baseUrl,
		show,
		file,
	} = props;

	const { name, path } = file;

	const src = `${baseUrl}/${path}`;

	const classNames = [styles.row];

	return (
		<div className={ classNames.join(' ') }>
			<FormImageFieldPreview
				src={ src }
				alt={ name }
				onClick={ () => show(src, name) }
				tabIndex={ -1 }
			/>

			<span className={ styles.name } title={ name }>{ name }</span>

			<span className={ styles.nowrap }>
				<span className={ styles.delete } onClick={ e => onDelete(file) }>X</span>
			</span>
		</div>
	);
}
