import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentStorageUpdatePage = lazy(() => import('../views/update/EquipmentStorageUpdatePage'));

export function EquipmentStorageUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentStorageUpdatePage />
		</PageSuspense>
	);
}
