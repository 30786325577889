import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsClientsContactsSortCol, TProjectClientContactList, TProjectClientContactListResponse, TProjectClientContactQuery, TProjectClientContactQueryPayload, TProjectsClientsContactsSortCol, adaptProjectClientContactFromListResponse, adaptProjectsClientsContactsQueryToRequest } from '../../types';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectsClientsContactsGetApi = createAsyncThunk(
	`GET/${BACKEND_PROJECTS_CLIENTS}/:client_id/contacts`,
	(payload: TProjectClientContactQueryPayload, thunkApi) => {
		const { clientId, data } = payload;
		const path = `${BACKEND_PROJECTS_CLIENTS}/${clientId}/contacts`;

		const params = data ? adaptProjectsClientsContactsQueryToRequest(data) : {};

		return HttpService.get<TProjectClientContactListResponse>(path, { params }).then(result => {
			return adaptProjectClientContactFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectClientContactList,
	query: TProjectClientContactQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsClientsContactsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectsClientsContactsSlice = createSlice({
	name: 'projectsClientsContactsSlice',
	initialState,
	reducers: {
		setProjectsClientsContactsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsClientsContactsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsClientsContactsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsClientsContactsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsClientsContactsQuerySortCol(state, action: PayloadAction<TProjectsClientsContactsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectsClientsContacts(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsClientsContactsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsClientsContactsGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientContactList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsClientsContactsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsClientsContactsSelector = () => useAppSelector(state => state[projectsClientsContactsSlice.name]);

export const { clearProjectsClientsContacts, setProjectsClientsContactsQueryLimit, setProjectsClientsContactsQueryOffset, setProjectsClientsContactsQuerySort, setProjectsClientsContactsQuerySortCol, setProjectsClientsContactsQuerySearch } = projectsClientsContactsSlice.actions;
