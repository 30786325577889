import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserType, TUserTypeRequest, TUserTypeResponse, adaptUserTypeFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_TYPES } from '../../backend-paths';


export const userTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_TYPES}`,
	(payload: TUserTypeRequest, thunkApi) => {
		return HttpService.post<TUserTypeResponse>(BACKEND_ADMIN_USERS_TYPES, payload).then(result => {
			return adaptUserTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeCreateSlice = createSlice({
	name: 'userTypeCreateSlice',
	initialState,
	reducers: {
		clearUserTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserTypeCreateSelector = () => useAppSelector(state => state[userTypeCreateSlice.name]);

export const { clearUserTypeCreate } = userTypeCreateSlice.actions;
