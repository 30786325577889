import { useEffect, useState } from 'react';

import { FormField, FormInput } from 'src/components';
import { TInputField } from 'src/toolkit';
import { FormSearchBoxFieldList } from './FormSearchBoxFieldList';
import { TFormSearchBoxFieldListOption } from './FormSearchBoxFieldListItem';

import styles from './FormSearchBoxField.module.scss';


type TProps = TInputField & {
	legend?: string;
	title?: string;
	placeholder?: string;
	placeholderDefault?: string;

	isLoading?: boolean;
	required?: boolean;

	isClearAfterSelect?: boolean;

	items: TFormSearchBoxFieldListOption[];
	value: string;

	marginRight?: boolean;

	onInput?: (value: string) => void;

	autocomplete?: boolean;
};

export function FormSearchBoxField(props: TProps) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		required,
		items: itemsOuter = [],

		isClearAfterSelect,

		legend,
		title,
		placeholder: placeholderOuter,
		placeholderDefault,

		marginRight,
		onInput,

		autocomplete = false,
	} = props;

	const [placeholder, setPlaceHolder] = useState(placeholderOuter);
	const [isActive, setIsActive] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [options, setOptions] = useState(itemsOuter);
	const [items, setItems] = useState(itemsOuter);
	const [fieldValue, setFieldValue] = useState('');

	useEffect(() => {
		const items = itemsOuter.map(item => item);

		setOptions(items);
		setItems(items);

		const item = items.find(item => item.value === value);
		if (item) {
			setPlaceHolder(item.text);
		} else if (value && placeholderDefault) {
			setPlaceHolder(placeholderDefault);
		}
	}, [itemsOuter]);

	useEffect(() => {
		if (!onInput) {
			const items = options.filter(item => {
				const valueLower = item.text.toLowerCase();
				const fieldValueLower = fieldValue.toLowerCase();

				return valueLower.includes(fieldValueLower);
			});

			setItems(items);
		}
	}, [fieldValue]);

	const onInputFieldValue = (value: string) => {
		setFieldValue(value);

		if (onInput) {
			onInput(value);
		}
	};

	const onClick = (selectedValue: string) => {
		const item = items.find(item => item.value === selectedValue);

		if (!item) {
			return;
		}

		const { text, value } = item;

		setValue(value);

		if (isClearAfterSelect) {
			setPlaceHolder(placeholderOuter);
		} else {
			setPlaceHolder(text);
		}

		setFieldValue('');

		setItems(options);

		if (onInput) {
			onInput('');
		}

		setIsActive(false);
	};

	const onBlur = () => {
		if (!isOver) {
			setIsActive(false);
		}
	};

	const classNames = [styles.box];

	if (isActive) {
		classNames.push(styles.zIndex);
	}

	if (marginRight) {
		classNames.push(styles.marginRight);
	}

	const autoComplete = autocomplete ? 'on' : 'off';

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			className={ classNames.join(' ') }
		>
			<FormInput
				placeholder={ placeholder }
				name={ name }
				title={ title }
				onFocus={ e => setIsActive(true) }
				onBlur={ onBlur }
				value={ fieldValue }
				onChange={ e => onInputFieldValue(e.target.value) }
				disabled={ isLoading }
				autoComplete={ autoComplete }
			/>

			{ isActive &&
				<FormSearchBoxFieldList
					items={ items }
					value={ value }
					setValue={ onClick }
					// onMouseEnter={ e => setIsOver(true) }
					// onMouseLeave={ e => setIsOver(false) }
					onMouseOver={ e => setIsOver(true) }
					onMouseOut={ e => setIsOver(false) }
				/>
			}
		</FormField>
	);
}
