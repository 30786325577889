import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderGroup, TProjectOrderGroupResponse, adaptProjectOrderGroupFromResponse } from './project-order-group.type';
import { TProjectOrderGroupShiftReport, TProjectOrderGroupShiftReportResponse, adaptProjectOrderGroupShiftReportFromResponse } from './project-order-group-shift-report.type';


export const ProjectsOrdersGroupsShiftsSortCol = {
	ID: 'id',
	LAST_NAME: 'last_name',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersGroupsShiftsSortCol = TTableSortCols<typeof ProjectsOrdersGroupsShiftsSortCol>;

export type TProjectOrderGroupShiftQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersGroupsShiftsSortCol;
};

export type TProjectsOrdersGroupsShiftsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderGroupShiftPayloadQuery = {
	groupId: number;
	orderId: number;
	projectId: number;
	data?: TProjectOrderGroupShiftQuery;
};

export function adaptProjectsOrdersGroupsShiftsQueryRequest(data: TProjectOrderGroupShiftQuery): TProjectsOrdersGroupsShiftsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderGroupShift = {
  id: number;
  groupId: number;
  userNickname: string;
  rateTypeId?: number;
  rate?: number;
  isSalaryDayNotCount: boolean;
  rateTypeName?: string;
	group: TProjectOrderGroup;
	user?: TUserMeta;
	report?: TProjectOrderGroupShiftReport;
	createdAt?: string;
  updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectOrderGroupShiftResponse = {
  id: number;
  group_id: number;
  user_nickname: string;
	rate_type_id: number;
	rate: number;
	is_salary_day_not_count: boolean;
  rate_type_name?: string;
	group: TProjectOrderGroupResponse;
	user: TUserMetaResponse;
	report?: TProjectOrderGroupShiftReportResponse;
  created_at?: string;
  updated_at?: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectOrderGroupShiftListResponse = TListResponse<TProjectOrderGroupShiftResponse>;

export type TProjectOrderGroupShiftList = TListResponse<TProjectOrderGroupShift>;

export type TProjectOrderGroupShiftBodyRequest = {
	userNickname: string;
	rateTypeId: number;
	rate: number;
	isSalaryDayNotCount: boolean;
};

export type TProjectOrderGroupShiftRequest = {
	user_nickname: string;
	rate_type_id: number;
	rate: number;
	is_salary_day_not_count: boolean;
};

export type TProjectOrderGroupShiftPayload = {
	id: number;
	groupId: number;
	orderId: number;
	projectId: number;
};

export type TProjectOrderGroupShiftPayloadCreate = {
	groupId: number;
	orderId: number;
	projectId: number;
	data: TProjectOrderGroupShiftBodyRequest;
};

export type TProjectOrderGroupShiftPayloadUpdate = TProjectOrderGroupShiftPayloadCreate & {
	id: number;
};

export function adaptProjectOrderGroupShiftFromListResponse(data: TProjectOrderGroupShiftListResponse): TProjectOrderGroupShiftList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderGroupShiftFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderGroupShiftFromResponse(data: TProjectOrderGroupShiftResponse): TProjectOrderGroupShift {
	const {
		id,
		group_id: groupId,
		user_nickname: userNickname,
		rate_type_id: rateTypeId,
		rate: rate,
		is_salary_day_not_count: isSalaryDayNotCount,
		rate_type_name: rateTypeName,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		group: groupRaw,
		user: userRaw,
		report: reportRaw,
	} = data;

	const group = adaptProjectOrderGroupFromResponse(groupRaw);
	const user = adaptUserMetaUndefinedFromResponse(userRaw);

	const report = reportRaw ? adaptProjectOrderGroupShiftReportFromResponse(reportRaw) : undefined;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		groupId,
		userNickname,
		rateTypeId,
		rate,
		isSalaryDayNotCount,
		rateTypeName,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
		group,
		user,
		report,
	};
}

export function adaptProjectOrderGroupShiftToRequest(data: TProjectOrderGroupShiftBodyRequest): TProjectOrderGroupShiftRequest {
	const {
		userNickname,
		rateTypeId,
		rate,
		isSalaryDayNotCount,
	} = data;

	return {
		user_nickname: userNickname,
		rate_type_id: rateTypeId,
		rate,
		is_salary_day_not_count: isSalaryDayNotCount,
	};
}
