export const VALIDATION_IS_DEFINED = 'The field must be defined';

export const VALIDATION_IS_STRING = 'The field must be a string';

export const VALIDATION_IS_NUMBER = 'The field must be a number';

export const VALIDATION_IS_NUMBER_STRING = 'The field must be a number-string';

export const VALIDATION_IS_DATE = 'The field must be a date-string and contain correct date';

export const VALIDATION_DATE_MORE_MAX = 'The field more than max date';

export const VALIDATION_DATE_LESS_MIN = 'The field less than min date';

export const VALIDATION_IS_TIME = 'The field must be a time-string and contain correct time of clock';

export const VALIDATION_TIME_HOURS_MORE_MAX = 'The field`s hours more than max number';

export const VALIDATION_TIME_HOURS_LESS_MIN = 'The field`s hours less than min number';

export const VALIDATION_TIME_MINUTES_MORE_MAX = 'The field`s minutes more than max number';

export const VALIDATION_TIME_MINUTES_LESS_MIN = 'The field`s minutes less than min number';

export const VALIDATION_IS_ALIAS = 'The field must contain a-zA-Z characters, 0-9 numbers and -_ special symbols only';

export const VALIDATION_IS_HASHCODE = 'The field must contain a-zA-Z characters and 0-9 numbers only';

export const VALIDATION_MIN_LENGTH_8 = 'The field must be 8 characters length minimum';

export const VALIDATION_DUPLICATE_PASSWORDS = 'The new password should not repeat current one';

export const VALIDATION_LENGTH_10 = 'The field must be 10 characters length';

export const VALIDATION_LENGTH_4_16 = 'The field must be from 4 to 16 characters length';

export const VALIDATION_LENGTH_1_32 = 'The field must be from 1 to 32 characters length';

export const VALIDATION_LENGTH_1_128 = 'The field must be from 1 to 128 characters length';

export const VALIDATION_LENGTH_64 = 'The field must be 64 characters length';

export const VALIDATION_LENGTH_128 = 'The field must be 128 characters length';

export const VALIDATION_PHONE_NUMBER = 'The field must contain phone number format';

export const VALIDATION_EMAIL = 'The field must contain email characters format';

export const VALIDATION_PASSPORT_NUMBER = 'The field must contain the series and passport number';

export const VALIDATION_NAME = 'The field must contain Cyrillic and Latin alphabetic, numbers, spaces and dashes';

export const VALIDATION_CODE_FOR_REGISTRATION = 'The field must be 6 numbers length';

export const VALIDATION_STRINGS_LENGTH_START = 'The field must be from ';
export const VALIDATION_STRINGS_LENGTH_CENTER = ' to ';
export const VALIDATION_STRINGS_LENGTH_END = ' characters length';

export const VALIDATION_STRING_LENGTH_START = 'The field must be contains ';
export const VALIDATION_STRING_LENGTH_END = ' characters length';

export const VALIDATION_NUMBERS_LENGTH_START = 'The field must be from ';
export const VALIDATION_NUMBERS_LENGTH_CENTER = ' to ';
export const VALIDATION_NUMBERS_LENGTH_END = ' digitals length';

export const VALIDATION_NUMBER_LENGTH_START = 'The field must be contains ';
export const VALIDATION_NUMBER_LENGTH_END = ' digitals length';
