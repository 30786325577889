export type TAccountHourSalaryQueryPayload = {
	dateStart: string;
	dateEnd?: string;
};

export type TAccountHourSalaryQueryRequest = {
	date_start: string;
	date_end?: string;
};

export function adaptAccountHourSalaryQueryToRequest(data: TAccountHourSalaryQueryPayload): TAccountHourSalaryQueryRequest {
	const {
		dateStart,
		dateEnd,
	} = data;

	return {
		date_start: dateStart,
		date_end: dateEnd,
	};
}

export type TAccountHourSalary = {
  hourSalary: number;
};

export type TAccountHourSalaryResponse = {
  hour_salary: number;
};

export function adaptAccountHourSalaryFromResponse(data: TAccountHourSalaryResponse): TAccountHourSalary {
	const { hour_salary: hourSalary = 0 } = data;

	return { hourSalary };
}
