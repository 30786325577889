import { adaptUserExpenseBookkeepingListFromResponse, TUserExpenseBookkeeping, TUserExpenseBookkeepingResponse } from './user-expense-bookkeeping.type';
import { adaptUserFinancialChangeBookkeepingListFromResponse, TUserFinancialChangeBookkeeping, TUserFinancialChangeBookkeepingResponse } from './user-financial-change-bookkeeping.type';
import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { adaptUserOverworkBookkeepingListFromResponse, TUserOverworkBookkeeping, TUserOverworkBookkeepingResponse } from './user-overwork-bookkeeping.type';
import { TUserSalaryBookkeeping, TUserSalaryBookkeepingResponse, TUserShiftBookkeeping, TUserShiftBookkeepingResponse, adaptUserSalaryBookkeepingFromResponse, adaptUserShiftBookkeepingFromResponse } from './user-shift-bookkeeping.type';
import { TTableSortCols } from 'src/components/table/thead/col';


export const AccountBookkeepingSortCol = {
	NICKNAME: 'nickname',
	LAST_NAME: 'last_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',

	ID: 'id',
	PROJECT_NAME: 'project_name',
	ORDER_NAME: 'order_name',
	DATE: 'date',
	RATE: 'rate',
};

export type TAccountBookkeepingSortCol = TTableSortCols<typeof AccountBookkeepingSortCol>;

export type TAccountBookkeepingQuery = {
	types: string,
	dateStart?: string;
	dateEnd?: string;
};

export type TAccountBookkeepingQueryRequest = {
	types?: string;
	date_start?: string;
	date_end?: string;
};

export function adaptAccountBookkeepingQueryToRequest(data?: TAccountBookkeepingQuery): TAccountBookkeepingQueryRequest {
	if (!data) {
		return {};
	}

	const {
		types,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		types: !!types ? types : undefined,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TAccountBookkeeping = TUserMeta & {
  salaries: TUserSalaryBookkeeping[];
  financialChanges: TUserFinancialChangeBookkeeping[];
  expenses: TUserExpenseBookkeeping[];
  overworks: TUserOverworkBookkeeping[];
  shifts: TUserShiftBookkeeping[];
};

export type TAccountBookkeepingResponse = TUserMetaResponse & {
  salaries: TUserSalaryBookkeepingResponse[];
  financial_changes: TUserFinancialChangeBookkeepingResponse[];
  expenses: TUserExpenseBookkeepingResponse[];
  overworks: TUserOverworkBookkeepingResponse[];
  shifts: TUserShiftBookkeepingResponse[];
};

export function adaptAccountBookkeepingUndefinedFromResponse(data: TAccountBookkeepingResponse | undefined): TAccountBookkeeping | undefined {
	if (!data) {
		return undefined;
	}

	return adaptAccountBookkeepingFromResponse(data);
}

export function adaptAccountBookkeepingFromResponse(data: TAccountBookkeepingResponse): TAccountBookkeeping {
	const {
		salaries: salariesRaw = [],
		financial_changes: financialChangesRaw,
		expenses: expensesRaw,
		overworks: overworksRaw,
		shifts: shiftsRaw = [],
		...restFields
	} = data;

	const salaries = salariesRaw.map(item => adaptUserSalaryBookkeepingFromResponse(item));
	const financialChanges = adaptUserFinancialChangeBookkeepingListFromResponse(financialChangesRaw);
	const expenses = adaptUserExpenseBookkeepingListFromResponse(expensesRaw);
	const overworks = adaptUserOverworkBookkeepingListFromResponse(overworksRaw);
	const shifts = shiftsRaw.map(item => adaptUserShiftBookkeepingFromResponse(item));
	const meta = adaptUserMetaFromResponse(restFields);

	return {
		...meta,
		salaries,
		financialChanges,
		expenses,
		overworks,
		shifts,
	};
}

