import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { EquipmentsSortCol, TEquipmentList, TEquipmentListResponse, TEquipmentHierarchyQuery, TEquipmentsSortCol, adaptEquipmentFromListResponse, adaptEquipmentsHierarchyQueryToRequest } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS } from '../../backend-paths';


export const equipmentsHierarchyGetApi = createAsyncThunk(
	BACKEND_ADMIN_EQUIPMENTS,
	(payload: TEquipmentHierarchyQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsHierarchyQueryToRequest(payload);

		return HttpService.get<TEquipmentListResponse>(BACKEND_ADMIN_EQUIPMENTS, { params }).then(result => {
			return adaptEquipmentFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentList;
	query: TEquipmentHierarchyQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: EquipmentsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const equipmentsHierarchySlice = createSlice({
	name: 'equipmentsHierarchySlice',
	initialState,
	reducers: {
		setEquipmentsHierarchyQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setEquipmentsHierarchyQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setEquipmentsHierarchyQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setEquipmentsHierarchyQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setEquipmentsHierarchyQuerySortCol(state, action: PayloadAction<TEquipmentsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearEquipmentsHierarchy(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[equipmentsHierarchyGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsHierarchyGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[equipmentsHierarchyGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsHierarchySelector = () => useAppSelector(state => state[equipmentsHierarchySlice.name]);

export const { clearEquipmentsHierarchy, setEquipmentsHierarchyQueryLimit, setEquipmentsHierarchyQueryOffset, setEquipmentsHierarchyQuerySort, setEquipmentsHierarchyQuerySortCol, setEquipmentsHierarchyQuerySearch } = equipmentsHierarchySlice.actions;
