// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableFilters_filters__tGkKT {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n  margin: 0 auto 0.3em;\n  max-width: 800px;\n}\n.TableFilters_filters__tGkKT > * {\n  margin-right: 0.3em;\n}\n.TableFilters_filters__tGkKT > *:last-child {\n  margin-right: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/table/filters/TableFilters.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,oBAAA;EACA,gBAAA;AACD;AACC;EACC,mBAAA;AACF;AAEC;EACC,eAAA;AAAF","sourcesContent":[".filters {\n\tbox-sizing: border-box;\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tjustify-content: flex-end;\n\tmargin: 0 auto 0.3em;\n\tmax-width: 800px;\n\n\t& > * {\n\t\tmargin-right: 0.3em;\n\t}\n\n\t& > *:last-child {\n\t\tmargin-right: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "TableFilters_filters__tGkKT"
};
export default ___CSS_LOADER_EXPORT___;
