import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TFinancialChangeType, TFinancialChangeTypeResponse, adaptFinancialChangeTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES } from '../../backend-paths';


export const financialChangeTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}/${payload}`;

		return HttpService.get<TFinancialChangeTypeResponse>(path).then(result => {
			return adaptFinancialChangeTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TFinancialChangeType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const financialChangeTypeReadSlice = createSlice({
	name: 'financialChangeTypeReadSlice',
	initialState,
	reducers: {
		setFinancialChangeTypeRead(state, action: PayloadAction<TFinancialChangeType>) {
			state.item = action.payload;
		},

		clearFinancialChangeTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[financialChangeTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[financialChangeTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TFinancialChangeType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[financialChangeTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useFinancialChangeTypeReadSelector = () => useAppSelector(state => state[financialChangeTypeReadSlice.name]);

export const { clearFinancialChangeTypeRead, setFinancialChangeTypeRead } = financialChangeTypeReadSlice.actions;
