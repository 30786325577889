// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavLogo_logo__p22oZ {\n  box-sizing: border-box;\n  color: rgb(200, 200, 200);\n  display: inline-block;\n  font-size: 2em;\n  margin: 0;\n  overflow: hidden;\n  white-space: nowrap;\n  transition: color 0.1s ease-in-out;\n}\n.NavLogo_logo__p22oZ:hover, .NavLogo_logo__p22oZ:active {\n  color: rgb(240, 240, 240);\n}\n\n.NavLogo_icon__O\\+5JX {\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/nav/nav-logo/NavLogo.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,yBAAA;EAEC,qBAAA;EAED,cAAA;EAEA,SAAA;EAEA,gBAAA;EACA,mBAAA;EAEA,kCAAA;AALD;AAOC;EAEC,yBAAA;AANF;;AAUA;EACC,SAAA;AAPD","sourcesContent":[".logo {\n\tbox-sizing: border-box;\n\n\tcolor: rgba(200, 200, 200, 1);\n\n  display: inline-block;\n\n\tfont-size: 2em;\n\n\tmargin: 0;\n\n\toverflow: hidden;\n\twhite-space: nowrap;\n\n\ttransition: color 0.1s ease-in-out;\n\n\t&:hover,\n\t&:active {\n\t\tcolor: rgba(240, 240, 240, 1);\n\t}\n}\n\n.icon {\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "NavLogo_logo__p22oZ",
	"icon": "NavLogo_icon__O+5JX"
};
export default ___CSS_LOADER_EXPORT___;
