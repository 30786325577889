export function libElementOffsetTop(elem?: HTMLElement): number {
	if (!elem) {
		return 0;
	}

	let offsetTop = 0;
	let item: HTMLElement = elem;

	do {
		if (!isNaN(item.offsetTop)) {
			offsetTop += item.offsetTop;
		}
	} while(item = item.offsetParent as HTMLElement);

	return offsetTop;
}

export function libElementOffsetLeft(elem?: HTMLElement): number {
	if (!elem) {
		return 0;
	}

	let offsetLeft = 0;
	let item: HTMLElement = elem;

	do {
		if (!isNaN(item.offsetLeft)) {
			offsetLeft += item.offsetLeft;
		}
	} while(item = item.offsetParent as HTMLElement);

	return offsetLeft;
}

export function libElementOffset(elem?: HTMLElement): [number, number] {
	if (!elem) {
		return [0, 0];
	}

	let offsetTop = 0;
	let offsetLeft = 0;
	let item: HTMLElement = elem;

	do {
		if (!isNaN(item.offsetLeft)) {
			offsetLeft += item.offsetLeft;
		}

		if (!isNaN(item.offsetTop)) {
			offsetTop += item.offsetTop;
		}
	} while(item = item.offsetParent as HTMLElement);

	return [offsetLeft, offsetTop];
}
