import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserDepartmentChangesPage = lazy(() => import('../views/list/UserDepartmentChangesPage'));

export function UserDepartmentChangesPageLazy() {
	return (
		<PageSuspense>
			<UserDepartmentChangesPage />
		</PageSuspense>
	);
}
