import { TEquipmentSource, TEquipmentSourceResponse, adaptEquipmentSourceFromResponse } from './equipment-source.type';


export type TEquipmentOrderRelationEquipment = {
  amount: number;

  equipment: TEquipmentSource;
};

export type TEquipmentOrderRelationEquipmentResponse = {
  amount: number;

	equipment: TEquipmentSourceResponse;
};

export type TEquipmentOrderRelationEquipmentCreate = Pick<TEquipmentOrderRelationEquipment, 'amount' | 'equipment'>;

export function adaptEquipmentOrderRelationEquipmentFromResponse(data: TEquipmentOrderRelationEquipmentResponse): TEquipmentOrderRelationEquipment {
	const {
		amount,

		equipment: equipmentRaw,
	} = data;

	const equipment = adaptEquipmentSourceFromResponse(equipmentRaw);

	return {
		amount,
		equipment,
	};
}
