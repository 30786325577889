import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentStorage, TEquipmentStorageRequest, TEquipmentStorageResponse, adaptEquipmentStorageFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_STORAGES } from '../../backend-paths';


export const equipmentStorageCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EQUIPMENTS_STORAGES}`,
	(payload: TEquipmentStorageRequest, thunkApi) => {
		return HttpService.post<TEquipmentStorageResponse>(BACKEND_ADMIN_EQUIPMENTS_STORAGES, payload).then(result => {
			return adaptEquipmentStorageFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentStorage> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentStorageCreateSlice = createSlice({
	name: 'equipmentStorageCreateSlice',
	initialState,
	reducers: {
		clearEquipmentStorageCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentStorageCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[equipmentStorageCreateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentStorage>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentStorageCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useEquipmentStorageCreateSelector = () => useAppSelector(state => state[equipmentStorageCreateSlice.name]);

export const { clearEquipmentStorageCreate } = equipmentStorageCreateSlice.actions;
