import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserTaxationData, TUserTaxationDataResponse, adaptUserTaxationDataFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userTaxationDataReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/taxation-data`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/taxation-data`;

		return HttpService.get<TUserTaxationDataResponse>(path).then(result => {
			return adaptUserTaxationDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxationData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationDataReadSlice = createSlice({
	name: 'userTaxationDataReadSlice',
	initialState,
	reducers: {
		setUserTaxationDataRead(state, action: PayloadAction<TUserTaxationData>) {
			state.item = action.payload;
		},

		clearUserTaxationDataRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationDataReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTaxationDataReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxationData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationDataReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTaxationDataReadSelector = () => useAppSelector(state => state[userTaxationDataReadSlice.name]);

export const { clearUserTaxationDataRead, setUserTaxationDataRead } = userTaxationDataReadSlice.actions;
