import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountPasswordPage = lazy(() => import('../views/update/AccountPasswordPage'));

export function AccountPasswordPageLazy() {
	return (
		<PageSuspense>
			<AccountPasswordPage />
		</PageSuspense>
	);
}
