import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TWorkArea, TWorkAreaUpdate, TWorkAreaResponse, adaptWorkAreaFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_WORK_AREAS } from '../../backend-paths';


export const workAreaUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_WORK_AREAS}/:id`,
	(payload: TWorkAreaUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_WORK_AREAS}/${id}`;

		return HttpService.put<TWorkAreaResponse>(path, data).then(result => {
			return adaptWorkAreaFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TWorkArea> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const workAreaUpdateSlice = createSlice({
	name: 'workAreaUpdateSlice',
	initialState,
	reducers: {
		clearWorkAreaUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[workAreaUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[workAreaUpdateApi.fulfilled.type]: (state, action: PayloadAction<TWorkArea>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[workAreaUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useWorkAreaUpdateSelector = () => useAppSelector(state => state[workAreaUpdateSlice.name]);

export const { clearWorkAreaUpdate } = workAreaUpdateSlice.actions;
