import { FormField, TFormInputFieldReadonlyFontColor } from 'src/components';
import { TEquipmentOrderRelationEquipmentGroupCreate } from 'src/store';

import { EquipmentGroupListWrap } from './EquipmentGroupListWrap';

import styles from './EquipmentGroupList.module.scss';


export type TEquipmentGroupValue = TEquipmentOrderRelationEquipmentGroupCreate & {
	fontColorName?: TFormInputFieldReadonlyFontColor;
	fontColorAmount?: TFormInputFieldReadonlyFontColor;
};

type TProps = {
	legend?: string;
	items?: TEquipmentGroupValue[];
};

export function EquipmentGroupList(props: TProps) {
	const {
		legend,

		items,
	} = props;

	const classNames = [styles.box];

	return (
		<FormField
			legend={ legend }
			className={ classNames.join(' ') }
		>

			<EquipmentGroupListWrap
				items={ items }
			/>
		</FormField>
	);
}
