import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserSalary, TUserSalaryResponse, adaptUserSalaryFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userSalaryCurrentReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/salaries/current`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/salaries/current`;

		return HttpService.get<TUserSalaryResponse>(path).then(result => {
			return adaptUserSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserSalary> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userSalaryCurrentReadSlice = createSlice({
	name: 'userSalaryCurrentReadSlice',
	initialState,
	reducers: {
		setUserSalaryCurrentRead(state, action: PayloadAction<TUserSalary>) {
			state.item = action.payload;
		},

		clearUserSalaryCurrentRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userSalaryCurrentReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userSalaryCurrentReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userSalaryCurrentReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserSalaryCurrentReadSelector = () => useAppSelector(state => state[userSalaryCurrentReadSlice.name]);

export const { clearUserSalaryCurrentRead, setUserSalaryCurrentRead } = userSalaryCurrentReadSlice.actions;
