import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserPhoneNumber, adaptUserPhoneNumberFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPhoneNumberDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:nickname/phone-number`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/phone-number`;

		return HttpService.delete(path).then(result => {
			return adaptUserPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPhoneNumber> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPhoneNumberDeleteSlice = createSlice({
	name: 'userPhoneNumberDeleteSlice',
	initialState,
	reducers: {
		clearUserPhoneNumberDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPhoneNumberDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPhoneNumberDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPhoneNumberDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPhoneNumberDeleteSelector = () => useAppSelector(state => state[userPhoneNumberDeleteSlice.name]);

export const { clearUserPhoneNumberDelete } = userPhoneNumberDeleteSlice.actions;
