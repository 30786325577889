import { DetailedHTMLProps, HTMLAttributes, Suspense } from 'react';

import { Preloader } from 'src/components/preloader';
import { AccountLayout } from 'src/layouts';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function PageSuspense(props: TProps) {
	const { children } = props;

	return (
		<AccountLayout>
			<Suspense fallback={ <div><Preloader /></div> }>
				{ children }
			</Suspense>
		</AccountLayout>
	);
}
