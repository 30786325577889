export function libClearIntstr(val: number | string | boolean = ''): string {
	const value = val.toString() || '';

	return value.replace(/\D/ig, '');
}

export function libPriceToInt(val: number | string): number {
	const value = libClearIntstr(val);

	return parseInt(value);
}

export function libFormatPrice(value: string | number = '', scale: number = 0): string {
	let val = '';

	if (scale > 0) {
		const isNumber = typeof value === 'number';
		const num = isNumber ? value : parseFloat(value);

		val = num.toFixed(scale);
	} else {
		const noEmptyValue = value || 0;
		val = noEmptyValue.toString();
	}

	val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	val = val.replace(/\./i, ',');
	return val;
}

type TLibHandlePriceIntOptions = {
	min?: number;
	max?: number;
	divChar?: string;
};

export function libHandlePriceInt(value: string, customOptions: TLibHandlePriceIntOptions = {}) {
	const options = {
		divChar: ' ',
		...customOptions,
	};

	const { divChar, min, max } = options;

	const re = new RegExp('[^\\d]', 'gi');
	let val = value.replace(re, '');

	if (typeof min === 'number' && parseInt(val) < min) {
		val = min.toString();
	}

	if (typeof max === 'number' && parseInt(val) > max) {
		val = max.toString();
	}

	return val.replace(/\B(?=(\d{3})+(?!\d))/g, divChar);
}

type TLibHandlePriceFloatOptions = {
	length?: number;
	divChar?: string;
	dotChar?: string;
};

export function libHandlePriceFloat(value: string, customOptions: TLibHandlePriceFloatOptions = {}) {
	const options = {
		length: 2,
		divChar: ' ',
		dotChar: '.',
		...customOptions,
	};

	const { length, divChar, dotChar } = options;

	const re = new RegExp('[^\\d' + dotChar + ']', 'gi');
	let val = value.replace(re, '');
	const i = val.indexOf(dotChar);

	if (i === 0) {
		return '';
	}

	if (i > 0) {
		const j = val.indexOf(dotChar, i + 1);

		if (j >= 0) {
			val = val.slice(0, i + (j - i));
		} else if (length > 0) {
			val = val.slice(0, i + 1 + length);
		}
	}

	const splits = val.split(dotChar);
	splits[0] = splits[0].replace(/\B(?=(\d{3})+(?!\d))/g, divChar);

	return splits.join(dotChar);
	// replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + divChar);
}

export function libCropFloat(value: number, precision?: number) {
	const size = precision && precision > 0 ? precision : 2;
	const arr = new Array(size).fill(0);
	const stringTemplate = `1${arr.join('')}`;
	const numberTemplate = parseInt(stringTemplate);

	const valueIncreased = value * numberTemplate;
	const valueIncreasedString = valueIncreased.toString();
	const valueIncreasedNumber = parseInt(valueIncreasedString);
	const valueNumber = valueIncreasedNumber / numberTemplate;

	return valueNumber;
}
