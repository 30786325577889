import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { EquipmentListBoxItem } from './EquipmentListBoxItem';
import { TEquipmentOrderRelationEquipmentCreate } from 'src/store';

import styles from './EquipmentListBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TEquipmentOrderRelationEquipmentCreate[];
	onDelete: (value: number) => void;
	changeAmount: (equipmentId: number, amount: number) => void;
};

export function EquipmentListBoxWrap(props: TProps) {
	const {
		items = [],
		placeholder,
		onDelete,
		changeAmount,
		...restProps
	} = props;

	if (items.length <= 0) {
		return (
			<div className={ styles.empty } { ...restProps }>
				{ placeholder }
			</div>
		);
	}

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map((item, index) => {
					const { equipment } = item;
					const { id: equipmentId } = equipment;

					return (
						<EquipmentListBoxItem
							key={ equipmentId }
							index={ index + 1 }
							onClick={ e => onDelete(equipmentId) }
							item={ item }
							changeAmount={ changeAmount }
						/>
					);
				})
			}
		</div>
	);
}
