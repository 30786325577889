import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TWorkArea, TWorkAreaRequest, TWorkAreaResponse, adaptWorkAreaFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_WORK_AREAS } from '../../backend-paths';


export const workAreaCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_WORK_AREAS}`,
	(payload: TWorkAreaRequest, thunkApi) => {
		return HttpService.post<TWorkAreaResponse>(BACKEND_ADMIN_WORK_AREAS, payload).then(result => {
			return adaptWorkAreaFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TWorkArea> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const workAreaCreateSlice = createSlice({
	name: 'workAreaCreateSlice',
	initialState,
	reducers: {
		clearWorkAreaCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[workAreaCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[workAreaCreateApi.fulfilled.type]: (state, action: PayloadAction<TWorkArea>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[workAreaCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useWorkAreaCreateSelector = () => useAppSelector(state => state[workAreaCreateSlice.name]);

export const { clearWorkAreaCreate } = workAreaCreateSlice.actions;
