import { TListResponse } from 'src/common/types';


export type TSystemSettingCalendarEventColorSource = {
  id: number;
  background: string;
  foreground?: string;
};

export type TSystemSettingCalendarEventColorSourceResponse = TSystemSettingCalendarEventColorSource;

export type TSystemSettingCalendarEventColorSourceListResponse = TListResponse<TSystemSettingCalendarEventColorSourceResponse>;

export type TSystemSettingCalendarEventColorSourceList = TListResponse<TSystemSettingCalendarEventColorSource>;

export function adaptSystemSettingCalendarEventColorSourceFromListResponse(data: TSystemSettingCalendarEventColorSourceListResponse): TSystemSettingCalendarEventColorSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptSystemSettingCalendarEventColorSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptSystemSettingCalendarEventColorSourceFromResponse(data: TSystemSettingCalendarEventColorSourceResponse): TSystemSettingCalendarEventColorSource {
	const {
		id,
		background,
		foreground,
	} = data;

	return {
		id,
		background,
		foreground,
	};
}
