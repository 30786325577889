import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserEmail, adaptUserEmailFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userEmailDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:nickname/email`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/email`;

		return HttpService.delete(path).then(result => {
			return adaptUserEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserEmail> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userEmailDeleteSlice = createSlice({
	name: 'userEmailDeleteSlice',
	initialState,
	reducers: {
		clearUserEmailDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userEmailDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userEmailDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userEmailDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserEmailDeleteSelector = () => useAppSelector(state => state[userEmailDeleteSlice.name]);

export const { clearUserEmailDelete } = userEmailDeleteSlice.actions;
