// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalHeadline_modalHeadline__BIuzI {\n  color: rgb(200, 200, 200);\n  font-size: 1em;\n  margin: 0.5em auto;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/modal/modal-headline/ModalHeadline.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACD","sourcesContent":[".modalHeadline {\n\tcolor: rgba(200, 200, 200, 1);\n\tfont-size: 1em;\n\tmargin: 0.5em auto;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeadline": "ModalHeadline_modalHeadline__BIuzI"
};
export default ___CSS_LOADER_EXPORT___;
