import { TListResponse } from 'src/common/types';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TEquipmentCategorySource, TEquipmentCategorySourceResponse, adaptEquipmentCategorySourceFromResponse } from './equipment-category-source.type';
import { TEquipmentStorageSource, TEquipmentStorageSourceResponse, adaptEquipmentStorageSourceFromResponse } from './equipment-storage-source.type';


export type TEquipmentHistoryData = {
	name: string;
	description?: string;
	publicId?: string;
	serialNumber: string;
	storagePlace: string;
	amount: number;
	isActive: boolean;
	createdAt: string;
	updatedAt: string;

	files: TEquipmentHistoryDataFile[];

	category: TEquipmentCategorySource;
	storage: TEquipmentStorageSource;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentHistoryDataResponse = {
	name: string;
	description?: string;
	public_id?: string;
	serial_number: string;
	storage_place: string;
	amount: number;
	is_active: boolean;
	created_at: string;
	updated_at: string;

	files: TEquipmentHistoryDataFile[];

	category: TEquipmentCategorySourceResponse;
	storage: TEquipmentStorageSourceResponse;
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentHistoryDataListResponse = TListResponse<TEquipmentHistoryDataResponse>;

export type TEquipmentHistoryDataList = TListResponse<TEquipmentHistoryData>;

export type TEquipmentHistoryDataBodyRequest = {
	name: string;
	description?: string;
  publicId: string;
	serialNumber?: string;
	storagePlace?: string;
	amount: number;
	isActive: boolean;
	categoryId: number;
	storageId: number;
};

export type TEquipmentHistoryDataRequest = {
	name: string;
	description?: string;
  public_id: string;
	serial_number?: string;
	storage_place?: string;
	amount: number;
	is_active: boolean;
	category_id: number;
	storage_id: number;
};

export type TEquipmentHistoryDataFile = {
	name: string;
	path: string;
};

export function adaptEquipmentHistoryDataFromResponse(data: TEquipmentHistoryDataResponse): TEquipmentHistoryData {
	const {
		name,
		description,
		public_id: publicId,
		serial_number: serialNumber,
		storage_place: storagePlace,
		amount,
		is_active: isActive,

		files = [],

		category: categoryRaw,
		storage: storageRaw,

		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const category = adaptEquipmentCategorySourceFromResponse(categoryRaw);
	const storage = adaptEquipmentStorageSourceFromResponse(storageRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		name,
		description,
		publicId,
		serialNumber,
		storagePlace,
		amount,
		isActive,
		createdAt,
		updatedAt,
		files,
		category,
		storage,
		createdBy,
		updatedBy,
	};
}
