import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsCategoriesHierarchyParamPage = lazy(() => import('../views/list/EquipmentsCategoriesHierarchyParamPage'));

export function EquipmentsCategoriesHierarchyParamPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsCategoriesHierarchyParamPage />
		</PageSuspense>
	);
}
