import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TAccountShiftReport, TAccountShiftReportResponse, adaptAccountShiftReportFromResponse } from './account-shift-report.type';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
// import { TAccount, TAccountResponse, adaptAccountFromResponse } from './project-order-group.type';


export const ShiftReportListStatus = {
	NONE: 'none',
	CREATED: 'created',
	CHECKED: 'checked',
};

export const AccountShiftsSortCol = {
	ID: 'id',
	PROJECT_NAME: 'project_name',
	ORDER_NAME: 'order_name',
	DATE: 'date',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountShiftsSortCol = TTableSortCols<typeof AccountShiftsSortCol>;
export type TAccountShiftsStatus = TTableSortCols<typeof ShiftReportListStatus>;

export type TAccountShiftQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountShiftsSortCol;

	types: TAccountShiftsStatus[],
	dateStart?: string;
	dateEnd?: string;
};

export type TAccountShiftsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	types?: string;
	date_start?: string;
	date_end?: string;
};

export type TAccountShiftPayloadQuery = {
	groupId: number;
	orderId: number;
	projectId: number;
	data?: TAccountShiftQuery;
};

export function adaptAccountShiftsQueryRequest(data: TAccountShiftQuery): TAccountShiftsQueryRequest {
	const {
		sortCol,
		types,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		types: types && types.length > 0 ? types.join(',') : undefined,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TAccountShift = {
	id: number;
	groupId: number;
	date: string;
	orderName: string;
	projectName: string;
	rateTypeName?: string;
	rate?: number;
	isSalaryDayNotCount: boolean;
	overwork?: number;
	deadlinePenalty?: number;
	photoPenalty?: number;
	isChecked: boolean;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	report?: TAccountShiftReport;

	totalExpenses?: number;
};

export type TAccountShiftResponse = {
	id: number;
	group_id: number;
	date: string;
	order_name: string;
	project_name: string;
	rate_type_name?: string;
	rate: number;
	is_salary_day_not_count: boolean;
	overwork?: number;
	deadline_penalty?: number;
	photo_penalty?: number;
	is_checked: boolean;
	created_at?: string;
	updated_at?: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;

	report?: TAccountShiftReportResponse;

	total_expenses?: number;
};

export type TAccountShiftListResponse = TListResponse<TAccountShiftResponse>;

export type TAccountShiftList = TListResponse<TAccountShift>;

// export type TAccountShiftBodyRequest = {
// 	userNickname: string;
// 	rateTypeId: number;
// 	rate: number;
// 	isSalaryDayNotCount: boolean;
// };

// export type TAccountShiftRequest = {
// 	user_nickname: string;
// 	rate_type_id: number;
// 	rate: number;
// 	is_salary_day_not_count: boolean;
// };

export type TAccountShiftPayload = {
	id: number;
	groupId: number;
	orderId: number;
	projectId: number;
};

// export type TAccountShiftPayloadCreate = {
// 	groupId: number;
// 	orderId: number;
// 	projectId: number;
// 	data: TAccountShiftBodyRequest;
// };

// export type TAccountShiftPayloadUpdate = TAccountShiftPayloadCreate & {
// 	id: number;
// };

export function adaptAccountShiftFromListResponse(data: TAccountShiftListResponse): TAccountShiftList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountShiftFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountShiftFromResponse(data: TAccountShiftResponse): TAccountShift {
	const {
		id,
		group_id: groupId,
		date,
		order_name: orderName,
		project_name: projectName,
		rate_type_name: rateTypeName,
		rate,
		is_salary_day_not_count: isSalaryDayNotCount,
		overwork,
		deadline_penalty: deadlinePenalty,
		photo_penalty: photoPenalty,
		is_checked: isChecked,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		report: reportRaw,

		total_expenses: totalExpenses,
	} = data;

	const report = reportRaw ? adaptAccountShiftReportFromResponse(reportRaw) : undefined;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		groupId,
		date,
		orderName,
		projectName,
		rateTypeName,
		rate,
		isSalaryDayNotCount,
		overwork,
		deadlinePenalty,
		photoPenalty,
		isChecked,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		report,

		totalExpenses,
	};
}

// export function adaptAccountShiftToRequest(data: TAccountShiftBodyRequest): TAccountShiftRequest {
// 	const {
// 		userNickname,
// 		rateTypeId,
// 		rate,
// 		isSalaryDayNotCount,
// 	} = data;

// 	return {
// 		user_nickname: userNickname,
// 		rate_type_id: rateTypeId,
// 		rate,
// 		is_salary_day_not_count: isSalaryDayNotCount,
// 	};
// }
