import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentHistoryHierarchyReadPage = lazy(() => import('../views/read/EquipmentHistoryHierarchyReadPage'));

export function EquipmentHistoryHierarchyReadPageLazy() {
	return (
		<PageSuspense>
			<EquipmentHistoryHierarchyReadPage />
		</PageSuspense>
	);
}
