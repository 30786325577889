import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectClientContact, TProjectClientContactPayloadCreate, TProjectClientContactResponse, adaptProjectClientContactFromResponse, adaptProjectClientContactToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientContactCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS_CLIENTS}/:client_id/contacts`,
	(payload: TProjectClientContactPayloadCreate, thunkApi) => {
		const { clientId, data } = payload;
		const path = `${BACKEND_PROJECTS_CLIENTS}/${clientId}/contacts`;

		const dataRequest = adaptProjectClientContactToRequest(data);

		return HttpService.post<TProjectClientContactResponse>(path, dataRequest).then(result => {
			return adaptProjectClientContactFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TProjectClientContact | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientContactCreateSlice = createSlice({
	name: 'projectClientContactCreateSlice',
	initialState,
	reducers: {
		clearProjectClientContactCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientContactCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectClientContactCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectClientContact>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientContactCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectClientContactCreateSelector = () => useAppSelector(state => state[projectClientContactCreateSlice.name]);

export const { clearProjectClientContactCreate } = projectClientContactCreateSlice.actions;
