import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectClient, TProjectClientBodyRequest, TProjectClientResponse, adaptProjectClientFromResponse, adaptProjectClientToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS_CLIENTS}`,
	(payload: TProjectClientBodyRequest, thunkApi) => {
		const dataRequest = adaptProjectClientToRequest(payload);

		return HttpService.post<TProjectClientResponse>(BACKEND_PROJECTS_CLIENTS, dataRequest).then(result => {
			return adaptProjectClientFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectClient> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientCreateSlice = createSlice({
	name: 'projectClientCreateSlice',
	initialState,
	reducers: {
		clearProjectClientCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectClientCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectClient>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectClientCreateSelector = () => useAppSelector(state => state[projectClientCreateSlice.name]);

export const { clearProjectClientCreate } = projectClientCreateSlice.actions;
