import { Link } from 'react-router-dom';

import { Icon } from 'src/components';

import styles from './RepositoryUri.module.scss';


type TProps = {
	children: string;
	className?: string;
	classNames?: string[];
};

export function RepositoryUri(props: TProps) {
	const {
		children,
		className,
		classNames: classNamesOuter = [],
	} = props;

	if (!children) {
		return (
			<></>
		);
	}

	const classNames = [styles.box, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			<span className={ styles.legend }>Репозиторий: </span>
			<div className={ styles.row }>
				<Icon className={ styles.icon } icon="icon-git"></Icon>
				<Link to={ children } target="_blank" className={ styles.link }>{ children }</Link>
			</div>
		</div>
	);
}
