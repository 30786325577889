import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './ShiftMark.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
	className?: string;
	classNames?: string[];

	none?: boolean;
	created?: boolean;
	checked?: boolean;
};

export function ShiftMark(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		children,

		none,
		created,
		checked,

		...restProps
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	let title = 'Ожидает отчёта';

	if (none) {
		classNames.push(styles.none);
	}

	if (created) {
		classNames.push(styles.created);

		title = 'Отчёт написан';
	}

	if (checked) {
		classNames.push(styles.checked);

		title = 'Отчёт проверен';
	}

	return (
		<span className={ classNames.join(' ') } { ...restProps } title={ title }></span>
	);
}
