import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountHourSalary, TAccountHourSalaryQueryPayload, TAccountHourSalaryResponse, adaptAccountHourSalaryFromResponse, adaptAccountHourSalaryQueryToRequest } from '../../types';
import { BACKEND_ACCOUNT_HOUR_SALARY } from '../../backend-paths';


export const accountHourSalaryGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_HOUR_SALARY,
	(payload: TAccountHourSalaryQueryPayload, thunkApi) => {
		const params = adaptAccountHourSalaryQueryToRequest(payload);

		return HttpService.get<TAccountHourSalaryResponse>(BACKEND_ACCOUNT_HOUR_SALARY, { params }).then(result => {
			return adaptAccountHourSalaryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	item?: TAccountHourSalary;
	query: TAccountHourSalaryQueryPayload;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	item: undefined,
	query: {
		dateStart: (new Date()).toISOString().slice(0, 10),
		dateEnd: undefined,
	},
	error: undefined,
};

export const accountHourSalarySlice = createSlice({
	name: 'accountHourSalarySlice',
	initialState,
	reducers: {
		setAccountHourSalaryQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setAccountHourSalaryQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearAccountHourSalary(state) {
			return { ...initialState, query: state.query };
		},
	},

	extraReducers: {
		[accountHourSalaryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountHourSalaryGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountHourSalary>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.error = undefined;
		},

		[accountHourSalaryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountHourSalarySelector = () => useAppSelector(state => state[accountHourSalarySlice.name]);

export const { clearAccountHourSalary, setAccountHourSalaryQueryDateStart, setAccountHourSalaryQueryDateEnd } = accountHourSalarySlice.actions;
