import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountRequisitesPage = lazy(() => import('../views/update/AccountRequisitesPage'));

export function AccountRequisitesPageLazy() {
	return (
		<PageSuspense>
			<AccountRequisitesPage />
		</PageSuspense>
	);
}
