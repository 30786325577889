import { useEffect } from 'react';
import { FormField, FormInput } from 'src/components';
import { TInputField, libHandlePriceInt } from 'src/toolkit';


export type TFormInputPriceField = TInputField & {
	legend?: string;
	placeholder?: string;
	title?: string;
	type?: string;

	isLoading?: boolean;
	readonly?: boolean;
	autocomplete?: boolean;

	min?: string;
	max?: string;

	marginLeft?: boolean;
	width100?: boolean;
};

export function FormInputPriceField(props: TFormInputPriceField) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,

		readonly = false,
		autocomplete = true,

		legend = '',
		placeholder = '',
		title = '',

		marginLeft = false,
		width100 = false,
	} = props;

	useEffect(() => {
		onChange(value);
	}, []);

	const onChange = (value: string) => {
		const formatted = libHandlePriceInt(value);

		setValue(formatted);
	};

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
			marginLeft={ marginLeft }
			width100={ width100 }
		>
			<FormInput
				placeholder={ placeholder }
				name={ name }
				title={ title }
				value={ value }
				onChange={ e => onChange(e.target.value) }
				disabled={ isLoading }
				readOnly={ readonly }
				autoComplete={ autocomplete ? 'on' : 'off' }
			/>
		</FormField>
	);
}
