import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersOverworksSortCol, TUserOverworkList, TUserOverworkListResponse, TUsersOverworksQuery, TUsersOverworksQueryPayload, TUsersOverworksSortCol, adaptUserOverworkFromListResponse, adaptUsersOverworksQueryToRequest } from '../../types';
import { BACKEND_USERS_OVERWORKS } from '../../backend-paths';


export const usersOverworksGetApi = createAsyncThunk(
	`${BACKEND_USERS_OVERWORKS}/:id`,
	(payload: TUsersOverworksQueryPayload, thunkApi) => {
		const { data } = payload;
		const params = adaptUsersOverworksQueryToRequest(data);

		const path = `${BACKEND_USERS_OVERWORKS}`;

		return HttpService.get<TUserOverworkListResponse>(path, { params }).then(result => {
			return adaptUserOverworkFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserOverworkList;
	query: TUsersOverworksQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersOverworksSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersOverworksSlice = createSlice({
	name: 'usersOverworksSlice',
	initialState,
	reducers: {
		setUsersOverworksQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersOverworksQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersOverworksQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersOverworksQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersOverworksQuerySortCol(state, action: PayloadAction<TUsersOverworksSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersOverworks(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersOverworksGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersOverworksGetApi.fulfilled.type]: (state, action: PayloadAction<TUserOverworkList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersOverworksGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersOverworksSelector = () => useAppSelector(state => state[usersOverworksSlice.name]);

export const { clearUsersOverworks, setUsersOverworksQueryLimit, setUsersOverworksQueryOffset, setUsersOverworksQuerySort, setUsersOverworksQuerySortCol, setUsersOverworksQuerySearch } = usersOverworksSlice.actions;
