import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserRolesPage = lazy(() => import('../views/roles/UserRolesPage'));

export function UserRolesPageLazy() {
	return (
		<PageSuspense>
			<UserRolesPage />
		</PageSuspense>
	);
}
