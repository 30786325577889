import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingShiftsIsNoticeUsersByShiftChanges = TSystemSettingValueCommon & {
  value?: boolean;
};

export type TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse = TSystemSettingValueCommonResponse & {
  value?: boolean;
};

export type TSystemSettingShiftsIsNoticeUsersByShiftChangesPayload = {
	value?: boolean;
};

export type TSystemSettingShiftsIsNoticeUsersByShiftChangesRequest = {
	value?: boolean;
};

export function adaptSystemSettingShiftsIsNoticeUsersByShiftChangesFromResponse(data: TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse): TSystemSettingShiftsIsNoticeUsersByShiftChanges {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingShiftsIsNoticeUsersByShiftChangesToRequest(data: TSystemSettingShiftsIsNoticeUsersByShiftChangesPayload): TSystemSettingShiftsIsNoticeUsersByShiftChangesRequest {
	const { value = false } = data;

	return {
		value: value ? value : false,
	};
}
