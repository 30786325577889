import { libClearIntstr, libFormatDate } from 'src/toolkit';


export type TUserPassportFile = {
	name: string;
	path: string;
};

export type TUserPassportFileRequest = Array<File | string>;

export type TUserPassport = {
	lastName: string;
	firstName: string;
  thirdName: string;
  passportNumber: string;
  departmentCode: string;
  birthday: string;
  issueDate: string;
  issuedBy: string;
  registrationAddress: string;
  bornPlace: string;
  files: TUserPassportFile[];
	createdAt: string;
	updatedAt: string;
};

export type TUserPassportBodyRequest = {
	lastName?: string;
	firstName?: string;
  thirdName?: string;
  passportNumber?: string;
  departmentCode?: string;
  birthday?: string;
  issueDate?: string;
  issuedBy?: string;
  registrationAddress?: string;
  bornPlace?: string;
};

export type TUserPassportResponse = {
	last_name: string;
	first_name: string;
  third_name: string;
  passport_number: string;
  department_code: string;
  birthday: string;
  issue_date: string;
  issued_by: string;
  registration_address: string;
  born_place: string;
  files: TUserPassportFile[];
	created_at: string;
	updated_at: string;
};

export type TUserPassportRequest = Omit<TUserPassportResponse, 'files' | 'created_at' | 'updated_at'>;

export type TUserPassportPayload = {
	nickname: string;
	data: TUserPassportBodyRequest;
	files: TUserPassportFileRequest;
};

export function adaptUserPassportFromResponse(data: TUserPassportResponse): TUserPassport {
	const {
		last_name: lastName = '',
		first_name: firstName = '',
		third_name: thirdName = '',
		passport_number: passportNumber = '',
		department_code: departmentCode = '',
		birthday: birthdayRaw,
		issue_date: issueDateRaw,
		issued_by: issuedBy = '',
		registration_address: registrationAddress = '',
		born_place: bornPlace = '',
		files = [],
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const birthday = birthdayRaw ? libFormatDate(birthdayRaw, { template: 'yyyy-MM-dd' }) : '';
	const issueDate = issueDateRaw ? libFormatDate(issueDateRaw, { template: 'yyyy-MM-dd' }) : '';

	return {
		lastName,
		firstName,
		thirdName,
		passportNumber,
		departmentCode,
		birthday,
		issueDate,
		issuedBy,
		registrationAddress,
		bornPlace,
		files,
		createdAt,
		updatedAt,
	};
}

export function adaptUserPassportToRequest(data: TUserPassportBodyRequest): TUserPassportRequest {
	const {
		lastName = '',
		firstName = '',
		thirdName = '',
		passportNumber = '',
		departmentCode = '',
		birthday = '',
		issueDate = '',
		issuedBy = '',
		registrationAddress = '',
		bornPlace = '',
	} = data;

	return {
		last_name: lastName,
		first_name: firstName,
		third_name: thirdName,
		passport_number: libClearIntstr(passportNumber),
		department_code: libClearIntstr(departmentCode),
		birthday,
		issue_date: issueDate,
		issued_by: issuedBy,
		registration_address: registrationAddress,
		born_place: bornPlace,
	};
}

