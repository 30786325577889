import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountShiftExpense, TAccountShiftExpenseResponse, adaptAccountShiftExpenseFromResponse } from '../../types';
import { BACKEND_ACCOUNT_SHIFT_EXPENSE } from '../../backend-paths';


export const accountShiftExpenseReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ACCOUNT_SHIFT_EXPENSE}/:expenseId`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SHIFT_EXPENSE}/${payload}`;

		return HttpService.get<TAccountShiftExpenseResponse>(path).then(result => {
			return adaptAccountShiftExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftExpenseReadSlice = createSlice({
	name: 'accountShiftExpenseReadSlice',
	initialState,
	reducers: {
		setAccountShiftExpenseRead(state, action: PayloadAction<TAccountShiftExpense>) {
			state.item = action.payload;
		},

		clearAccountShiftExpenseRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftExpenseReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftExpenseReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftExpenseReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftExpenseReadSelector = () => useAppSelector(state => state[accountShiftExpenseReadSlice.name]);

export const { clearAccountShiftExpenseRead, setAccountShiftExpenseRead } = accountShiftExpenseReadSlice.actions;
