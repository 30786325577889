import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingType, TSystemSettingTypeResponse, adaptSystemSettingTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES } from '../../backend-paths';


export const systemSettingTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES}/${payload}`;

		return HttpService.get<TSystemSettingTypeResponse>(path).then(result => {
			return adaptSystemSettingTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingTypeReadSlice = createSlice({
	name: 'systemSettingTypeReadSlice',
	initialState,
	reducers: {
		setSystemSettingTypeRead(state, action: PayloadAction<TSystemSettingType>) {
			state.item = action.payload;
		},

		clearSystemSettingTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingTypeReadSelector = () => useAppSelector(state => state[systemSettingTypeReadSlice.name]);

export const { clearSystemSettingTypeRead, setSystemSettingTypeRead } = systemSettingTypeReadSlice.actions;
