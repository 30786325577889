// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormImageFieldPreview_preview__6-DI1 {\n  border: 1px solid rgb(60, 60, 60);\n  box-sizing: border-box;\n  cursor: pointer;\n  margin-right: 0.5em;\n  max-height: 1.5em;\n  opacity: 0.75;\n  vertical-align: middle;\n}\n.FormImageFieldPreview_preview__6-DI1:hover {\n  border: 1px solid rgb(150, 150, 150);\n  opacity: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-image-field/components/form-image-field-preview/FormImageFieldPreview.module.scss"],"names":[],"mappings":"AAAA;EACC,iCAAA;EACA,sBAAA;EACA,eAAA;EAEA,mBAAA;EACA,iBAAA;EAEA,aAAA;EAEA,sBAAA;AAFD;AAIC;EACC,oCAAA;EACA,UAAA;AAFF","sourcesContent":[".preview {\n\tborder: 1px solid rgba(60, 60, 60, 1);\n\tbox-sizing: border-box;\n\tcursor: pointer;\n\n\tmargin-right: 0.5em;\n\tmax-height: 1.5em;\n\n\topacity: 0.75;\n\n\tvertical-align: middle;\n\n\t&:hover {\n\t\tborder: 1px solid rgba(150, 150, 150, 1);\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "FormImageFieldPreview_preview__6-DI1"
};
export default ___CSS_LOADER_EXPORT___;
