import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserPosition, TUserPositionUpdate, TUserPositionResponse, adaptUserPositionFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_POSITIONS } from '../../backend-paths';


export const userPositionUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_POSITIONS}/:id`,
	(payload: TUserPositionUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_USERS_POSITIONS}/${id}`;

		return HttpService.put<TUserPositionResponse>(path, data).then(result => {
			return adaptUserPositionFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPosition> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionUpdateSlice = createSlice({
	name: 'userPositionUpdateSlice',
	initialState,
	reducers: {
		clearUserPositionUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserPosition>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionUpdateSelector = () => useAppSelector(state => state[userPositionUpdateSlice.name]);

export const { clearUserPositionUpdate } = userPositionUpdateSlice.actions;
