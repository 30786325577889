import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectOrderGroupUpdatePage = lazy(() => import('../views/update/ProjectOrderGroupUpdatePage'));

export function ProjectOrderGroupUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectOrderGroupUpdatePage />
		</PageSuspense>
	);
}
