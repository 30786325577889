import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const AccountTaxationReceiptsSortCol = {
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountTaxationReceiptsSortCol = TTableSortCols<typeof AccountTaxationReceiptsSortCol>;

export type TAccountTaxationReceiptsQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountTaxationReceiptsSortCol;
};

export type TAccountTaxationReceiptsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountTaxationReceiptsQueryPayload = {
	data?: TAccountTaxationReceiptsQuery;
	// userId?: number;
};

export function adaptAccountTaxationReceiptsQueryToRequest(data?: TAccountTaxationReceiptsQuery): TAccountTaxationReceiptsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TAccountTaxationReceipt = {
	id: number;
  path: string;
  date: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountTaxationReceiptResponse = {
	id: number;
  path: string;
  date: string;
	description?: string;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TAccountTaxationReceiptListResponse = TListResponse<TAccountTaxationReceiptResponse>;

export type TAccountTaxationReceiptList = TListResponse<TAccountTaxationReceipt>;

export type TAccountTaxationReceiptPayload = {
	path: string;
	date: string;
	description?: string;
};

export type TAccountTaxationReceiptPayloadUpdate = {
	id: number;
	data: TAccountTaxationReceiptPayload;
};

export function adaptAccountTaxationReceiptFromListResponse(data: TAccountTaxationReceiptListResponse): TAccountTaxationReceiptList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountTaxationReceiptFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountTaxationReceiptFromResponse(data: TAccountTaxationReceiptResponse): TAccountTaxationReceipt {
	const {
		id,
		path,
		date,
		description: descriptionRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		path,
		date,
		description,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,
	};
}

export function adaptAccountTaxationReceiptToRequest(data: TAccountTaxationReceiptPayload): TAccountTaxationReceiptPayload {
	return { ...data };
}
