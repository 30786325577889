import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingUsersValue, TSystemSettingUsersValueResponse, adaptSystemSettingUsersValueFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE } from '../../backend-paths';


export const systemSettingUsersAccountUserTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE;

		return HttpService.get<TSystemSettingUsersValueResponse>(path).then(result => {
			return adaptSystemSettingUsersValueFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingUsersValue> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingUsersAccountUserTypeReadSlice = createSlice({
	name: 'systemSettingUsersAccountUserTypeReadSlice',
	initialState,
	reducers: {
		setSystemSettingUsersAccountUserTypeRead(state, action: PayloadAction<TSystemSettingUsersValue>) {
			state.item = action.payload;
		},

		clearSystemSettingUsersAccountUserTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingUsersAccountUserTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingUsersAccountUserTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingUsersValue>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingUsersAccountUserTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingUsersAccountUserTypeReadSelector = () => useAppSelector(state => state[systemSettingUsersAccountUserTypeReadSlice.name]);

export const { clearSystemSettingUsersAccountUserTypeRead, setSystemSettingUsersAccountUserTypeRead } = systemSettingUsersAccountUserTypeReadSlice.actions;
