import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrder, TProjectOrderPayloadCreate, TProjectOrderResponse, adaptProjectOrderFromResponse, adaptProjectOrderToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS}/:projectId/orders`,
	(payload: TProjectOrderPayloadCreate, thunkApi) => {
		const { projectId, data } = payload;
		const dataRequest = adaptProjectOrderToRequest(data);
		const path = `${BACKEND_PROJECTS}/${projectId}/orders`;

		return HttpService.post<TProjectOrderResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrder> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderCreateSlice = createSlice({
	name: 'projectOrderCreateSlice',
	initialState,
	reducers: {
		clearProjectOrderCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectOrderCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrder>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectOrderCreateSelector = () => useAppSelector(state => state[projectOrderCreateSlice.name]);

export const { clearProjectOrderCreate } = projectOrderCreateSlice.actions;
