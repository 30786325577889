import { useEffect, useRef, useState, MouseEvent } from 'react';

import { Icon } from 'src/components';
import { TInputField } from 'src/toolkit';

import { FormSelectBoxFieldList } from './FormSelectBoxFieldList';
import { TFormSelectBoxFieldListOption } from './FormSelectBoxFieldListItem';

import styles from './FormSelectBoxField.module.scss';


export type TFormSelectBoxFieldLogic = TInputField & {
	legend?: string;
	title?: string;
	placeholder?: string;

	isLoading?: boolean;
	disabled?: boolean;
	required?: boolean;

	items: TFormSelectBoxFieldListOption[];
	value: string;

	className?: string;

	width100?: boolean;
	marginRight?: boolean;
	marginLeft?: boolean;
};

export function FormSelectBoxFieldLogic(props: TFormSelectBoxFieldLogic) {
	const {
		// errors,
		value,
		setValue,
		isLoading,
		// required,
		items: itemsOuter = [],

		className: classNameOuter = '',

		// legend,
		title,
		placeholder: placeholderOuter,
		disabled,

		width100 = false,
		marginRight = false,
		marginLeft = false,
	} = props;

	const [placeholder, setPlaceHolder] = useState(placeholderOuter);
	const [isActive, setIsActive] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [options, setOptions] = useState(itemsOuter);
	const [items, setItems] = useState(itemsOuter);
	const [fieldValue, setFieldValue] = useState('');
	const [isBeforeFocus, setIsBeforeFocus] = useState(false);

	const inputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const items = itemsOuter.map(item => item);

		setOptions(items);

		const item = items.find(item => item.value === value);
		if (item) {
			setPlaceHolder(item.text);
		}
	}, []);

	useEffect(() => {
		const item = options.find(item => item.value === value);
		if (item) {
			setPlaceHolder(item.text);
		}
	}, [value]);

	useEffect(() => {
		if (disabled) {
			setIsActive(false);
		}
	}, [disabled]);

	useEffect(() => {
		const items = options.filter(item => {
			const valueLower = item.text.toLowerCase();
			const fieldValueLower = fieldValue.toLowerCase();

			return valueLower.includes(fieldValueLower);
		});

		setItems(items);
	}, [fieldValue]);

	const onClick = (selectedValue: string) => {
		const item = items.find(item => item.value === selectedValue);

		if (!item) {
			return;
		}

		const { text, value } = item;

		setValue(value);

		setPlaceHolder(text);
		setFieldValue('');
		setItems(options);

		setIsActive(false);
	};

	const onMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		if (disabled) {
			return;
		}

		if (e.button !== 0) {
			return setIsActive(false);
		}

		setIsBeforeFocus(document.activeElement !== inputRef.current);
	};

	const toggle = () => {
		if (!disabled && !isBeforeFocus) {
			setIsActive(!isActive);
		}
	};

	const onBlur = () => {
		if (!disabled && !isOver) {
			setIsActive(false);
		}
	};

	const onFocus = () => {
		if (!disabled) {
			setIsActive(true);
		}
	};

	const classNames = [styles.box];
	const labelClassNames = [styles.label];

	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	if (isActive) {
		classNames.push(styles.zIndex);
		labelClassNames.push(styles.focus);
	}

	if (width100) {
		classNames.push(styles.width100);
	}

	if (marginRight) {
		classNames.push(styles.marginRight);
	}

	if (marginLeft) {
		classNames.push(styles.marginLeft);
	}

	if (isLoading || disabled) {
		labelClassNames.push(styles.disabled);
	}

	const icon = isActive ? 'icon-chevron-top' : 'icon-chevron-bottom';

	return (
		<div className={ classNames.join(' ') }>
			<div
				ref={ inputRef }
				title={ title }
				onClick={ toggle }
				onMouseDown={ onMouseDown }
				onFocus={ onFocus }
				onBlur={ onBlur }
				className={ labelClassNames.join(' ') }
				tabIndex={ -1 }
			>
				{ placeholder }

				<Icon className={ styles.icon } icon={ icon } />
			</div>

			{ isActive &&
				<FormSelectBoxFieldList
					items={ items }
					value={ value }
					setValue={ onClick }
					setIsActive={ setIsActive }
					onMouseOver={ e => setIsOver(true) }
					onMouseOut={ e => setIsOver(false) }
				/>
			}
		</div>
	);
}
