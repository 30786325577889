import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserDepartment, TUserDepartmentRequest, TUserDepartmentResponse, adaptUserDepartmentFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_DEPARTMENTS } from '../../backend-paths';


export const userDepartmentCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_DEPARTMENTS}`,
	(payload: TUserDepartmentRequest, thunkApi) => {
		return HttpService.post<TUserDepartmentResponse>(BACKEND_ADMIN_USERS_DEPARTMENTS, payload).then(result => {
			return adaptUserDepartmentFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserDepartment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDepartmentCreateSlice = createSlice({
	name: 'userDepartmentCreateSlice',
	initialState,
	reducers: {
		clearUserDepartmentCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDepartmentCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userDepartmentCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserDepartment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDepartmentCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserDepartmentCreateSelector = () => useAppSelector(state => state[userDepartmentCreateSlice.name]);

export const { clearUserDepartmentCreate } = userDepartmentCreateSlice.actions;
