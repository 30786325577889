import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroupShift, TProjectOrderGroupShiftPayloadUpdate, TProjectOrderGroupShiftResponse, adaptProjectOrderGroupShiftFromResponse, adaptProjectOrderGroupShiftToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupShiftUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts/:id`,
	(payload: TProjectOrderGroupShiftPayloadUpdate, thunkApi) => {
		const { id, groupId, orderId, projectId, data } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts/${id}`;

		const dataRequest = adaptProjectOrderGroupShiftToRequest(data);

		return HttpService.put<TProjectOrderGroupShiftResponse>(path, dataRequest).then(result => {
			return adaptProjectOrderGroupShiftFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShift> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftUpdateSlice = createSlice({
	name: 'projectOrderGroupShiftUpdateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupShiftUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShift>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftUpdateSelector = () => useAppSelector(state => state[projectOrderGroupShiftUpdateSlice.name]);

export const { clearProjectOrderGroupShiftUpdate } = projectOrderGroupShiftUpdateSlice.actions;
