import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectOrderGroupShiftRateTypeSortCol, TProjectOrderGroupShiftRateTypeQuery, TProjectOrderGroupShiftRateTypeSortCol, adaptProjectOrderGroupShiftRateTypeFromListResponse, adaptProjectOrderGroupShiftRateTypeQueryToRequest, TProjectOrderGroupShiftRateTypeList, TProjectOrderGroupShiftRateTypeListResponse } from '../../types';
import { BACKEND_SOURCE_PROJECTS_ORDERS_GROUPS_SHIFTS_RATES_TYPES } from '../../backend-paths';


export const projectOrderGroupShiftRateTypeGetApi = createAsyncThunk(
	BACKEND_SOURCE_PROJECTS_ORDERS_GROUPS_SHIFTS_RATES_TYPES,
	(payload: TProjectOrderGroupShiftRateTypeQuery | undefined, thunkApi) => {
		const params = adaptProjectOrderGroupShiftRateTypeQueryToRequest(payload);

		return HttpService.get<TProjectOrderGroupShiftRateTypeListResponse>(BACKEND_SOURCE_PROJECTS_ORDERS_GROUPS_SHIFTS_RATES_TYPES, { params }).then(result => {
			return adaptProjectOrderGroupShiftRateTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TProjectOrderGroupShiftRateTypeList,
	query: TProjectOrderGroupShiftRateTypeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectOrderGroupShiftRateTypeSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectOrderGroupShiftRateTypeSlice = createSlice({
	name: 'projectOrderGroupShiftRateTypeSlice',
	initialState,
	reducers: {
		setProjectOrderGroupShiftRateTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectOrderGroupShiftRateTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectOrderGroupShiftRateTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectOrderGroupShiftRateTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectOrderGroupShiftRateTypesQuerySortCol(state, action: PayloadAction<TProjectOrderGroupShiftRateTypeSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearProjectOrderGroupShiftRateType(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftRateTypeGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftRateTypeGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftRateTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectOrderGroupShiftRateTypeGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftRateTypeSelector = () => useAppSelector(state => state[projectOrderGroupShiftRateTypeSlice.name]);

export const { setProjectOrderGroupShiftRateTypesQueryLimit, setProjectOrderGroupShiftRateTypesQueryOffset, setProjectOrderGroupShiftRateTypesQuerySort, setProjectOrderGroupShiftRateTypesQuerySortCol, setProjectOrderGroupShiftRateTypesQuerySearch, clearProjectOrderGroupShiftRateType } = projectOrderGroupShiftRateTypeSlice.actions;
