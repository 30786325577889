import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService, libFirstDate, libFormatDate, libLastDate } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UserBookkeepingSortCol, ShiftReportListStatus, TUserBookkeepingList, TUserBookkeepingListResponse, TUserBookkeepingQuery, TUserBookkeepingSortCol, adaptUserBookkeepingFromListResponse, TUserShiftBookkeepingStatus, adaptUsersBookkeepingQueryToRequest, TUserBookkeepingQueryRequest } from '../../types';
import { BACKEND_ADMIN_BOOKKEEPING } from '../../backend-paths';


function defaultStartDate() {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	const firstDate = libFirstDate(date.getFullYear(), date.getMonth());

	return libFormatDate(firstDate, 'yyyy-MM-dd');
}

function defaultEndDate() {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	const lastDate = libLastDate(date.getFullYear(), date.getMonth());

	return libFormatDate(lastDate, 'yyyy-MM-dd');
}

export const bookkeepingGetApi = createAsyncThunk(
	BACKEND_ADMIN_BOOKKEEPING,
	(payload: TUserBookkeepingQuery, thunkApi) => {
		const params = payload ? adaptUsersBookkeepingQueryToRequest(payload) : {};

		return HttpService.get<TUserBookkeepingListResponse>(BACKEND_ADMIN_BOOKKEEPING, { params }).then(result => {
			return adaptUserBookkeepingFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TUserBookkeepingQueryRequestKeys = keyof TUserBookkeepingQueryRequest;
type TUserBookkeepingQueryKeys = keyof TUserBookkeepingQuery;

type TUserBookkeepingQueryDictionaryKeys = [TUserBookkeepingQueryRequestKeys, TUserBookkeepingQueryKeys];

export type TUserBookkeepingQueryDictionary = TUserBookkeepingQueryDictionaryKeys[];
export type TUserBookkeepingQuerySearchParams = Partial<Record<TUserBookkeepingQueryRequestKeys, string>>;

type TInitialState = {
	isLoading: boolean,
	list: TUserBookkeepingList,
	query: TUserBookkeepingQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		dateStart: defaultStartDate(),
		dateEnd: defaultEndDate(),
		limit: 10,
		offset: 0,
		sort: TableSort.ASC,
		sortCol: UserBookkeepingSortCol.CREATED_AT,
		types: [
			ShiftReportListStatus.NONE,
			ShiftReportListStatus.CREATED,
		].join(','),
	},
};

export const bookkeepingSlice = createSlice({
	name: 'bookkeepingSlice',
	initialState,
	reducers: {
		setBookkeepingQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setBookkeepingQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setBookkeepingQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setBookkeepingQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setBookkeepingQuerySortCol(state, action: PayloadAction<TUserBookkeepingSortCol>) {
			state.query.sortCol = action.payload;
		},

		setBookkeepingQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setBookkeepingQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		setBookkeepingQueryTypes(state, action: PayloadAction<TUserShiftBookkeepingStatus[]>) {
			state.query.types = action.payload.filter(item => !!item).join(',');
		},

		clearBookkeeping(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[bookkeepingGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[bookkeepingGetApi.fulfilled.type]: (state, action: PayloadAction<TUserBookkeepingList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[bookkeepingGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useBookkeepingSelector = () => useAppSelector(state => state[bookkeepingSlice.name]);

export const { clearBookkeeping, setBookkeepingQueryLimit, setBookkeepingQueryOffset, setBookkeepingQuerySort, setBookkeepingQuerySortCol, setBookkeepingQuerySearch, setBookkeepingQueryDateStart, setBookkeepingQueryDateEnd, setBookkeepingQueryTypes } = bookkeepingSlice.actions;
