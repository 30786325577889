import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentCategoryHierarchyUpdatePage = lazy(() => import('../views/update/EquipmentCategoryHierarchyUpdatePage'));

export function EquipmentCategoryHierarchyUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentCategoryHierarchyUpdatePage />
		</PageSuspense>
	);
}
