import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountTaxationData, TAccountTaxationDataPayload, TAccountTaxationDataResponse, adaptAccountTaxationDataFromResponse, adaptAccountTaxationDataToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_TAXATION_DATA } from '../../backend-paths';


export const accountTaxationDataUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_TAXATION_DATA}`,
	(payload: TAccountTaxationDataPayload, thunkApi) => {
		const { data, files = [] } = payload;

		const dataRequest = adaptAccountTaxationDataToRequest(data);

		return HttpService.put<TAccountTaxationDataResponse>(BACKEND_ACCOUNT_TAXATION_DATA, dataRequest).then(result => {
			const pathFiles = `${BACKEND_ACCOUNT_TAXATION_DATA}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountTaxationDataResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountTaxationDataFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTaxationData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTaxationDataUpdateSlice = createSlice({
	name: 'accountTaxationDataUpdateSlice',
	initialState,
	reducers: {
		clearAccountTaxationDataUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTaxationDataUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountTaxationDataUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxationData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTaxationDataUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountTaxationDataUpdateSelector = () => useAppSelector(state => state[accountTaxationDataUpdateSlice.name]);

export const { clearAccountTaxationDataUpdate } = accountTaxationDataUpdateSlice.actions;
