import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserDepartment, TUserDepartmentResponse, adaptUserDepartmentFromResponse } from './user-department.type';


export const UserDepartmentChangeSortCol = {
	CREATED_AT: 'created_at',
};

export type TUserDepartmentChangeSortCol = TTableSortCols<typeof UserDepartmentChangeSortCol>;

export type TUserDepartmentChangeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserDepartmentChangeSortCol;

	dateStart?: string;
	dateEnd?: string;
};

export type TUserDepartmentChangeQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	date_start?: string;
	date_end?: string;
};

export function adaptUserDepartmentChangeQueryRequest(data: TUserDepartmentChangeQuery): TUserDepartmentChangeQueryRequest {
	const {
		sortCol,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserDepartmentChangeListResponse = TListResponse<TUserDepartmentChangeResponse>;

export type TUserDepartmentChangeList = TListResponse<TUserDepartmentChange>;

export function adaptUserDepartmentChangeFromListResponse(data: TUserDepartmentChangeListResponse): TUserDepartmentChangeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserDepartmentChangeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export type TUserDepartmentChange = {
  id: number;
  department: TUserDepartment;
  dateStart: string;
  dateEnd?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserDepartmentChangeResponse = {
  id: number;
  department: TUserDepartmentResponse;
  date_start: string;
  date_end?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserDepartmentChangeBodyPayload = {
	departmentId: number;
	dateStart: string;
	dateEnd?: string;
};

export type TUserDepartmentChangeBodyPayloadRequest = {
	department_id: number;
	date_start: string;
	date_end?: string;
};

type TUserDepartmentChangeParam = {
	nickname: string;
};

export type TUserDepartmentChangePayload = TUserDepartmentChangeParam & {
	data: TUserDepartmentChangeBodyPayload;
};

export type TUserDepartmentChangeParams = TUserDepartmentChangeParam & {
	id: number;
};

export type TUserDepartmentChangePayloadUpdate = TUserDepartmentChangeParams & {
	nickname: string;
	data: TUserDepartmentChangeBodyPayload;
};

export type TUserDepartmentChangesPayload = TUserDepartmentChangeParam & {
	query: TUserDepartmentChangeQuery;
};

export function adaptUserDepartmentChangeFromResponse(data: TUserDepartmentChangeResponse): TUserDepartmentChange {
	const {
		id,
		department: departmentRaw,
		date_start: dateStart,
		date_end: dateEnd,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const department = adaptUserDepartmentFromResponse(departmentRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		department,
		dateStart,
		dateEnd,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptUserDepartmentChangeToRequest(data: TUserDepartmentChangeBodyPayload): TUserDepartmentChangeBodyPayloadRequest {
	const {
		departmentId,
		dateStart,
		dateEnd,
	} = data;

	return {
		department_id: departmentId,
		date_start: dateStart,
		date_end: dateEnd,
	};
}
