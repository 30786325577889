import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { FormInputFieldReadonlyFontColor } from 'src/components';

import { ImagePreview } from '../../../preview';
import { TImageValue } from '../../../../ImageList';

import styles from '../../../styles/ImageListValueRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	baseUrl: string;
	file: TImageValue;
	show: (src: string, name: string) => void;
};

export function ImageListRow(props: TProps) {
	const {
		baseUrl,
		show,
		file,
	} = props;

	const { name, path, fontColor } = file;

	const src = `${baseUrl}/${path}`;

	const classNames = [styles.row];

	const classNamesName = [styles.name];

	if (fontColor) {
		if (fontColor === FormInputFieldReadonlyFontColor.RED) {
			classNamesName.push(styles.colorRed);
		} else if (fontColor === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesName.push(styles.colorGreen);
		}
	}

	return (
		<div className={ classNames.join(' ') }>
			<ImagePreview
				src={ src }
				alt={ name }
				onClick={ () => show(src, name) }
				tabIndex={ -1 }
			/>

			<span className={ classNamesName.join(' ') } title={ name }>{ name }</span>
		</div>
	);
}
