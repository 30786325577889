import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserSalariesPage = lazy(() => import('../views/list/UserSalariesPage'));

export function UserSalariesPageLazy() {
	return (
		<PageSuspense>
			<UserSalariesPage />
		</PageSuspense>
	);
}
