import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountTypeChangesPage = lazy(() => import('../views/list/AccountTypeChangesPage'));

export function AccountTypeChangesPageLazy() {
	return (
		<PageSuspense>
			<AccountTypeChangesPage />
		</PageSuspense>
	);
}
