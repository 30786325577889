import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentOrderUpdatePage = lazy(() => import('../views/update/EquipmentOrderUpdatePage'));

export function EquipmentOrderUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentOrderUpdatePage />
		</PageSuspense>
	);
}
