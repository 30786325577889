import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormField, FormInputMask, FormNotice, FormPassword } from 'src/components';
import { useAppDispatch, useRecoverySelector, recoveryPhoneNumberConfirmApi } from 'src/store';
import { formHandleServerError, formValidateCode, formValidatePassword, formValidatePasswordConfirm, useInputField } from 'src/toolkit';

import styles from '../../../../../../components/styles/RecoveryStage.module.scss';


export function RecoveryStageFormPhoneNumber() {
	const dispatch = useAppDispatch();
	const { phoneNumber, isLoading, error } = useRecoverySelector();

	const codeField = useInputField('code');
	const passwordField = useInputField('password');
	const passwordConfirmField = useInputField('password_confirm');

	const [notice, setNotice] = useState('');

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, codeField, passwordField);
		}
	}, [error]);

	const submit = async () => {
		const errors = [];

		errors.push(formValidateCode(codeField.value));
		codeField.setErrors(errors[errors.length - 1]);

		errors.push(formValidatePassword(passwordField.value));
		passwordField.setErrors(errors[errors.length - 1]);

		errors.push(formValidatePasswordConfirm(passwordField.value, passwordConfirmField.value));
		passwordConfirmField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			phoneNumber,
			code: codeField.value,
			password: passwordField.value,
		};

		dispatch(recoveryPhoneNumberConfirmApi(dataRequest));
	};

	return (
		<Form className={ styles.recoveryPageStageForm }>
			<FormField
				legend="SMS-код"
				errors={ codeField.errors }
				required
			>
				<FormInputMask
					placeholder="___ - ___"
					mask="999 - 999"
					title="Введите SMS-код"
					name={ codeField.name }
					value={ codeField.value }
					onChange={ e => codeField.setValue(e.target.value) }
				/>
			</FormField>

			<FormPassword
				legend="Новый пароль"
				errors={ passwordField.errors }
				placeholder="••••••••"
				name={ passwordField.name }
				value={ passwordField.value }
				title="Введите новый пароль"
				onChange={ e => passwordField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<FormPassword
				legend="Подтвердите новый пароль"
				errors={ passwordConfirmField.errors }
				placeholder="••••••••"
				title="Повторите новый пароль"
				name={ passwordConfirmField.name }
				value={ passwordConfirmField.value }
				onChange={ e => passwordConfirmField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton onClick={ submit } busy={ isLoading }>Установить пароль</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
