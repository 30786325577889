import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserExpense, TUserExpenseResponse, adaptUserExpenseFromResponse } from '../../types';
import { BACKEND_USERS_EXPENSES } from '../../backend-paths';


export const userExpenseReadGetApi = createAsyncThunk(
	`GET/${BACKEND_USERS_EXPENSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_USERS_EXPENSES}/${payload}`;

		return HttpService.get<TUserExpenseResponse>(path).then(result => {
			return adaptUserExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userExpenseReadSlice = createSlice({
	name: 'userExpenseReadSlice',
	initialState,
	reducers: {
		setUserExpenseRead(state, action: PayloadAction<TUserExpense>) {
			state.item = action.payload;
		},

		clearUserExpenseRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userExpenseReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userExpenseReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userExpenseReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserExpenseReadSelector = () => useAppSelector(state => state[userExpenseReadSlice.name]);

export const { clearUserExpenseRead, setUserExpenseRead } = userExpenseReadSlice.actions;
