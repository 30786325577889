import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { EquipmentGroupListBoxItem } from './EquipmentGroupListBoxItem';
import { TEquipmentOrderRelationEquipmentGroupCreate } from 'src/store';

import styles from './EquipmentGroupListBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TEquipmentOrderRelationEquipmentGroupCreate[];
	onDelete: (value: number) => void;
	changeAmount: (groupId: number, amount: number) => void;
};

export function EquipmentGroupListBoxWrap(props: TProps) {
	const {
		items = [],
		placeholder,
		onDelete,
		changeAmount,
		...restProps
	} = props;

	if (items.length <= 0) {
		return (
			<div className={ styles.empty } { ...restProps }>
				{ placeholder }
			</div>
		);
	}

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map((item, index) => {
					const { group } = item;
					const { id: groupId } = group;

					return (
						<EquipmentGroupListBoxItem
							key={ groupId }
							index={ index + 1 }
							onClick={ e => onDelete(groupId) }
							item={ item }
							changeAmount={ changeAmount }
						/>
					);
				})
			}
		</div>
	);
}
