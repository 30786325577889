import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { TEquipmentHistoryData, TEquipmentHistoryDataResponse, adaptEquipmentHistoryDataFromResponse } from './equipment-history-data.type';
import { TEquipment, TEquipmentResponse, adaptEquipmentFromResponse } from './equipment.type';


export const EquipmentsHistorySortCol = {
	CREATED_AT: 'created_at',
};

export type TEquipmentsHistorySortCol = TTableSortCols<typeof EquipmentsHistorySortCol>;

export type TEquipmentHistoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsHistorySortCol;
};

export type TEquipmentsHistoryQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TEquipmentHistoryQueryPayload = {
	equipmentId: number;
	query: TEquipmentHistoryQuery;
};

export function adaptEquipmentsHistoryQueryToRequest(data?: TEquipmentHistoryQuery): TEquipmentsHistoryQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentHistory = {
	id: number;
	description?: string;
	createdAt: string;

	equipment: TEquipment;
	dataBefore?: TEquipmentHistoryData;
	dataAfter?: TEquipmentHistoryData;
	createdBy: TUserMeta;
};

export type TEquipmentHistoryResponse = {
	id: number;
	description?: string;
	created_at: string;

	equipment: TEquipmentResponse;
	data_before?: TEquipmentHistoryDataResponse;
	data_after?: TEquipmentHistoryDataResponse;
	created_by: TUserMetaResponse;
};

export type TEquipmentHistoryListResponse = TListResponse<TEquipmentHistoryResponse>;

export type TEquipmentHistoryList = TListResponse<TEquipmentHistory>;

export type TEquipmentHistoryParams = {
	equipmentId: number;
	id: number;
};

export function adaptEquipmentHistoryFromListResponse(data: TEquipmentHistoryListResponse): TEquipmentHistoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentHistoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentHistoryFromResponse(data: TEquipmentHistoryResponse): TEquipmentHistory {
	const {
		id,
		description,
		created_at: createdAt,

		equipment: equipmentRaw,
		data_before: dataBeforeRaw,
		data_after: dataAfterRaw,

		created_by: createdByRaw,
	} = data;

	const equipment = adaptEquipmentFromResponse(equipmentRaw);
	const dataBefore = dataBeforeRaw ? adaptEquipmentHistoryDataFromResponse(dataBeforeRaw) : undefined;
	const dataAfter = dataAfterRaw ? adaptEquipmentHistoryDataFromResponse(dataAfterRaw) : undefined;
	const createdBy = adaptUserMetaFromResponse(createdByRaw);

	return {
		id,
		description,
		createdAt,

		equipment,
		dataBefore,
		dataAfter,

		createdBy,
	};
}
