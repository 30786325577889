import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UserShiftsSortCol, ShiftReportListStatus, TUserShiftList, TUserShiftListResponse, TUserShiftQuery, TUserShiftsSortCol, TUserShiftsStatus, adaptUserShiftFromListResponse, adaptUsersShiftsQueryRequest } from '../../types';
import { BACKEND_ADMIN_USERS_SHIFTS } from '../../backend-paths';


export const userShiftsGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_SHIFTS,
	(payload: TUserShiftQuery, thunkApi) => {
		const params = payload ? adaptUsersShiftsQueryRequest(payload) : {};

		return HttpService.get<TUserShiftListResponse>(BACKEND_ADMIN_USERS_SHIFTS, { params }).then(result => {
			return adaptUserShiftFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserShiftList,
	query: TUserShiftQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.ASC,
		sortCol: UserShiftsSortCol.CREATED_AT,
		types: [
			ShiftReportListStatus.NONE,
			ShiftReportListStatus.CREATED,
		],
	},
};

export const usersShiftsSlice = createSlice({
	name: 'usersShiftsSlice',
	initialState,
	reducers: {
		setUserShiftsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUserShiftsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUserShiftsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUserShiftsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUserShiftsQuerySortCol(state, action: PayloadAction<TUserShiftsSortCol>) {
			state.query.sortCol = action.payload;
		},

		setUserShiftsQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserShiftsQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		setUserShiftsQueryTypes(state, action: PayloadAction<TUserShiftsStatus[]>) {
			state.query.types = [...action.payload];
		},

		clearUserShifts(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[userShiftsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userShiftsGetApi.fulfilled.type]: (state, action: PayloadAction<TUserShiftList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[userShiftsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserShiftsSelector = () => useAppSelector(state => state[usersShiftsSlice.name]);

export const { clearUserShifts, setUserShiftsQueryLimit, setUserShiftsQueryOffset, setUserShiftsQuerySort, setUserShiftsQuerySortCol, setUserShiftsQuerySearch, setUserShiftsQueryDateStart, setUserShiftsQueryDateEnd, setUserShiftsQueryTypes } = usersShiftsSlice.actions;
