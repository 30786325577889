import { TUserTaxation, TUserTaxationResponse, adaptUserTaxationFromResponse } from './user-taxation.type';


export type TUserTaxationDataFile = {
	name: string;
	path: string;
};

export type TUserTaxationDataFileRequest = Array<File | string>;

export type TUserTaxationData = {
	taxation: TUserTaxation;
  files: TUserTaxationDataFile[];
	createdAt: string;
	updatedAt: string;
};

export type TUserTaxationDataBodyRequest = {
	taxationId?: string;
};

export type TUserTaxationDataResponse = {
	taxation: TUserTaxationResponse;
  files: TUserTaxationDataFile[];
	created_at: string;
	updated_at: string;
};

export type TUserTaxationDataRequest = {
	taxation_id?: number;
};

export type TUserTaxationDataPayload = {
	nickname: string;
	data: TUserTaxationDataBodyRequest;
	files: TUserTaxationDataFileRequest;
};

export function adaptUserTaxationDataFromResponse(data: TUserTaxationDataResponse): TUserTaxationData {
	const {
		taxation: taxationRaw,
		files = [],
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const taxation = adaptUserTaxationFromResponse(taxationRaw);

	return {
		taxation,
		createdAt,
		updatedAt,
		files,
	};
}

export function adaptUserTaxationDataToRequest(data: TUserTaxationDataBodyRequest): TUserTaxationDataRequest {
	const { taxationId } = data;

	return {
		taxation_id: taxationId ? parseInt(taxationId) : undefined,
	};
}

