import { TUserTaxation, TUserTaxationResponse, adaptUserTaxationFromResponse } from './user-taxation.type';


export type TAccountTaxationDataFile = {
	name: string;
	path: string;
};

export type TAccountTaxationDataFileRequest = Array<File | string>;

export type TAccountTaxationData = {
	taxation: TUserTaxation;
  files: TAccountTaxationDataFile[];
};

export type TAccountTaxationDataBodyRequest = {
	taxationId?: string;
};

export type TAccountTaxationDataResponse = {
	taxation: TUserTaxationResponse;
  files: TAccountTaxationDataFile[];
};

export type TAccountTaxationDataRequest = {
	taxation_id?: number;
};

export type TAccountTaxationDataPayload = {
	data: TAccountTaxationDataBodyRequest;
	files: TAccountTaxationDataFileRequest;
};

export function adaptAccountTaxationDataFromResponse(data: TAccountTaxationDataResponse): TAccountTaxationData {
	const {
		taxation: taxationRaw,
		files = [],
	} = data;

	const taxation = adaptUserTaxationFromResponse(taxationRaw);

	return {
		taxation,
		files,
	};
}

export function adaptAccountTaxationDataToRequest(data: TAccountTaxationDataBodyRequest): TAccountTaxationDataRequest {
	const { taxationId } = data;

	return {
		taxation_id: taxationId ? parseInt(taxationId) : undefined,
	};
}

