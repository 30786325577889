import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingShiftsIsNoticeUsersByShiftChanges, TSystemSettingShiftsIsNoticeUsersByShiftChangesPayload, TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse, adaptSystemSettingShiftsIsNoticeUsersByShiftChangesFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES } from '../../backend-paths';


export const systemSettingShiftsIsNoticeUsersByShiftChangesUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES}`,
	(payload: TSystemSettingShiftsIsNoticeUsersByShiftChangesPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES;

		return HttpService.put<TSystemSettingShiftsIsNoticeUsersByShiftChangesResponse>(path, payload).then(result => {
			return adaptSystemSettingShiftsIsNoticeUsersByShiftChangesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingShiftsIsNoticeUsersByShiftChanges> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingShiftsIsNoticeUsersByShiftChangesUpdateSlice = createSlice({
	name: 'systemSettingShiftsIsNoticeUsersByShiftChangesUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingShiftsIsNoticeUsersByShiftChangesUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingShiftsIsNoticeUsersByShiftChangesUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingShiftsIsNoticeUsersByShiftChangesUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingShiftsIsNoticeUsersByShiftChanges>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingShiftsIsNoticeUsersByShiftChangesUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingShiftsIsNoticeUsersByShiftChangesUpdateSelector = () => useAppSelector(state => state[systemSettingShiftsIsNoticeUsersByShiftChangesUpdateSlice.name]);

export const { clearSystemSettingShiftsIsNoticeUsersByShiftChangesUpdate } = systemSettingShiftsIsNoticeUsersByShiftChangesUpdateSlice.actions;
