import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectOrderGroupShiftRateTypeSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectOrderGroupShiftRateTypeSortCol = TTableSortCols<typeof ProjectOrderGroupShiftRateTypeSortCol>;

export type TProjectOrderGroupShiftRateTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectOrderGroupShiftRateTypeSortCol;
};

export type TProjectOrderGroupShiftRateTypeQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectOrderGroupShiftRateTypeQueryToRequest(data?: TProjectOrderGroupShiftRateTypeQuery): TProjectOrderGroupShiftRateTypeQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderGroupShiftRateType = {
  id: number;
  name: string;
  description?: string;
};

export type TProjectOrderGroupShiftRateTypeResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TProjectOrderGroupShiftRateTypeListResponse = TListResponse<TProjectOrderGroupShiftRateTypeResponse>;

export type TProjectOrderGroupShiftRateTypeList = TListResponse<TProjectOrderGroupShiftRateType>;

export type TProjectOrderGroupShiftRateTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TProjectOrderGroupShiftRateTypeRequest = {
	name: string;
	description?: string;
};

export type TProjectOrderGroupShiftRateTypeUpdate = {
	id: number;
	data: TProjectOrderGroupShiftRateTypeBodyRequest;
};

export function adaptProjectOrderGroupShiftRateTypeFromListResponse(data: TProjectOrderGroupShiftRateTypeListResponse): TProjectOrderGroupShiftRateTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderGroupShiftRateTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderGroupShiftRateTypeFromResponse(data: TProjectOrderGroupShiftRateTypeResponse): TProjectOrderGroupShiftRateType {
	const {
		id,
		name,
		description,
	} = data;

	return {
		id,
		name,
		description,
	};
}
