import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const EquipmentsCategoriesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsCategoriesSortCol = TTableSortCols<typeof EquipmentsCategoriesSortCol>;

export type TEquipmentCategoryQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsCategoriesSortCol;
};

export type TEquipmentsCategoriesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsCategoriesQueryToRequest(data?: TEquipmentCategoryQuery): TEquipmentsCategoriesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentCategoryHierarchyQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsCategoriesSortCol;
	parentId?: number | null;
};

export type TEquipmentsCategoriesHierarchyQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
	parent_id?: string;
};

export function adaptEquipmentsCategoriesHierarchyQueryToRequest(data?: TEquipmentCategoryHierarchyQuery): TEquipmentsCategoriesHierarchyQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		parentId,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		parent_id: parentId !== undefined ? JSON.stringify(parentId) : parentId,
		...restFields
	};
}

type TEquipmentCategoryParent = {
	id: number;
	name: string;
};

export type TEquipmentCategory = {
  id: number;
  name: string;
  description?: string;
  parentId?: number;
	createdAt: string;
  updatedAt: string;

  parents: TEquipmentCategoryParent[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentCategoryResponse = {
  id: number;
  name: string;
  description?: string;
  parent_id?: number;
  created_at: string;
  updated_at: string;

  parents?: TEquipmentCategoryParent[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentCategoryListResponse = TListResponse<TEquipmentCategoryResponse>;

export type TEquipmentCategoryList = TListResponse<TEquipmentCategory>;

export type TEquipmentCategoryBodyRequest = {
	name: string;
	description?: string;
  parentId?: number;
};

export type TEquipmentCategoryRequest = {
	name: string;
	description?: string;
  parent_id?: number;
};

export type TEquipmentCategoryUpdate = {
	id: number;
	data: TEquipmentCategoryBodyRequest;
};

export function adaptEquipmentCategoryFromListResponse(data: TEquipmentCategoryListResponse): TEquipmentCategoryList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentCategoryFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentCategoryFromResponse(data: TEquipmentCategoryResponse): TEquipmentCategory {
	const {
		id,
		name,
		description,
		parent_id: parentId,
		parents: parentsRaw = [],
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);
	const parents = parentsRaw.map(item => ({ ...item }));

	return {
		id,
		name,
		description,
		createdAt,
		updatedAt,
		parentId,
		parents,
		createdBy,
		updatedBy,
	};
}

export function adaptEquipmentCategoryToRequest(data: TEquipmentCategoryBodyRequest): TEquipmentCategoryRequest {
	const {
		name,
		description = undefined,
		parentId = undefined,
	} = data;

	return {
		name,
		description,
		parent_id: parentId,
	};
}
