import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingBookkeepingWorkDayHoursCount = TSystemSettingValueCommon & {
  value?: number;
};

export type TSystemSettingBookkeepingWorkDayHoursCountResponse = TSystemSettingValueCommonResponse & {
  value?: number;
};

export type TSystemSettingBookkeepingWorkDayHoursCountPayload = {
	value?: number;
};

export type TSystemSettingBookkeepingWorkDayHoursCountRequest = {
	value?: number;
};

export function adaptSystemSettingBookkeepingWorkDayHoursCountFromResponse(data: TSystemSettingBookkeepingWorkDayHoursCountResponse): TSystemSettingBookkeepingWorkDayHoursCount {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingBookkeepingWorkDayHoursCountToRequest(data: TSystemSettingBookkeepingWorkDayHoursCountPayload): TSystemSettingBookkeepingWorkDayHoursCountRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
