import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProject, TProjectResponse, adaptProjectFromResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PROJECTS}/${payload}`;

		return HttpService.get<TProjectResponse>(path).then(result => {
			return adaptProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectReadSlice = createSlice({
	name: 'projectReadSlice',
	initialState,
	reducers: {
		setProjectRead(state, action: PayloadAction<TProject>) {
			state.item = action.payload;
		},

		clearProjectRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectReadSelector = () => useAppSelector(state => state[projectReadSlice.name]);

export const { clearProjectRead, setProjectRead } = projectReadSlice.actions;
