import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TGroupShiftExpenseTypeSource, TGroupShiftExpenseTypeSourceResponse, adaptGroupShiftExpenseTypeSourceFromResponse } from './group-shift-expense-type-source.type';
import { TAccountShift, TAccountShiftResponse, adaptAccountShiftFromResponse } from './account-shift.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectOrderGroupShiftExpensesSortCol = {
	NAME: 'name',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectOrderGroupShiftExpensesSortCol = TTableSortCols<typeof ProjectOrderGroupShiftExpensesSortCol>;

export type TProjectOrderGroupShiftExpensesQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectOrderGroupShiftExpensesSortCol;
};

export type TProjectOrderGroupShiftExpensesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderGroupShiftExpensesQueryPayload = {
	data?: TProjectOrderGroupShiftExpensesQuery;
	shiftId: number;
};

export function adaptProjectOrderGroupShiftExpensesQueryToRequest(data?: TProjectOrderGroupShiftExpensesQuery): TProjectOrderGroupShiftExpensesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderGroupShiftExpense = {
	id: number;
	shiftId: number;
	value: number;
	userDescription?: string;
	adminDescription?: string;
	shift?: TAccountShift;
	shiftExpenseType: TGroupShiftExpenseTypeSource;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	files?: TProjectOrderGroupShiftExpenseFile[];
};

export type TProjectOrderGroupShiftExpenseResponse = {
	id: number;
	shift_id: number;
	value: number;
	user_description?: string;
	admin_description?: string;
	shift?: TAccountShiftResponse;
	shift_expense_type: TGroupShiftExpenseTypeSourceResponse;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;

	files?: TProjectOrderGroupShiftExpenseFile[];
};

export type TProjectOrderGroupShiftExpenseListResponse = TListResponse<TProjectOrderGroupShiftExpenseResponse>;

export type TProjectOrderGroupShiftExpenseList = TListResponse<TProjectOrderGroupShiftExpense>;

export type TProjectOrderGroupShiftExpenseBodyRequest = {
	value: number;
	adminDescription?: string;
	shiftExpenseTypeId: number;
};

export type TProjectOrderGroupShiftExpenseRequest = {
	value: number;
	admin_description?: string;
	shift_expense_type_id: number;
};

export type TProjectOrderGroupShiftExpenseFile = {
	name: string;
	path: string;
};

export type TProjectOrderGroupShiftExpenseFileRequest = Array<File | string>;

export type TProjectOrderGroupShiftExpensePayload = {
	shiftId: number;
};

export type TProjectOrderGroupShiftExpensePayloadCreate = TProjectOrderGroupShiftExpensePayload & {
	data: TProjectOrderGroupShiftExpenseBodyRequest;
	files: TProjectOrderGroupShiftExpenseFileRequest;
};

export type TProjectOrderGroupShiftExpensePayloadUpdate = {
	id: number;
	data: TProjectOrderGroupShiftExpenseBodyRequest;
	files: TProjectOrderGroupShiftExpenseFileRequest;
};

export function adaptProjectOrderGroupShiftExpenseFromListResponse(data: TProjectOrderGroupShiftExpenseListResponse): TProjectOrderGroupShiftExpenseList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderGroupShiftExpenseFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderGroupShiftExpenseFromResponse(data: TProjectOrderGroupShiftExpenseResponse): TProjectOrderGroupShiftExpense {
	const {
		id,
		shift_id: shiftId,
		value: value,
		user_description: userDescriptionRaw,
		admin_description: adminDescriptionRaw,
		shift_expense_type: shiftExpenseTypeRaw,
		shift: shiftRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		files = [],
	} = data;

	const userDescription = userDescriptionRaw ? userDescriptionRaw : '';
	const adminDescription = adminDescriptionRaw ? adminDescriptionRaw : '';
	const shift = shiftRaw ? adaptAccountShiftFromResponse(shiftRaw) : shiftRaw;
	const shiftExpenseType = adaptGroupShiftExpenseTypeSourceFromResponse(shiftExpenseTypeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		shiftId,
		value,
		userDescription,
		adminDescription,
		shift,
		shiftExpenseType,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		files,
	};
}

export function adaptProjectOrderGroupShiftExpenseToRequest(data: TProjectOrderGroupShiftExpenseBodyRequest): TProjectOrderGroupShiftExpenseRequest {
	const {
		value,
		adminDescription,
		shiftExpenseTypeId,
	} = data;

	return {
		value,
		admin_description: adminDescription,
		shift_expense_type_id: shiftExpenseTypeId,
	};
}
