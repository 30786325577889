import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingAmocrmClientId, TSystemSettingAmocrmClientIdResponse, adaptSystemSettingAmocrmClientIdFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID } from '../../backend-paths';


export const systemSettingAmocrmClientIdReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID;

		return HttpService.get<TSystemSettingAmocrmClientIdResponse>(path).then(result => {
			return adaptSystemSettingAmocrmClientIdFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingAmocrmClientId> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingAmocrmClientIdReadSlice = createSlice({
	name: 'systemSettingAmocrmClientIdReadSlice',
	initialState,
	reducers: {
		setSystemSettingAmocrmClientIdRead(state, action: PayloadAction<TSystemSettingAmocrmClientId>) {
			state.item = action.payload;
		},

		clearSystemSettingAmocrmClientIdRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingAmocrmClientIdReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingAmocrmClientIdReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingAmocrmClientId>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingAmocrmClientIdReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingAmocrmClientIdReadSelector = () => useAppSelector(state => state[systemSettingAmocrmClientIdReadSlice.name]);

export const { clearSystemSettingAmocrmClientIdRead, setSystemSettingAmocrmClientIdRead } = systemSettingAmocrmClientIdReadSlice.actions;
