import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountPhoneNumberPage = lazy(() => import('../views/update/AccountPhoneNumberPage'));

export function AccountPhoneNumberPageLazy() {
	return (
		<PageSuspense>
			<AccountPhoneNumberPage />
		</PageSuspense>
	);
}
