import { FormField } from 'src/components';

import { FormListBoxFieldWrap } from './FormListBoxFieldWrap';
import { TFormListBoxFieldItem } from './FormListBoxFieldItem';

import styles from './FormListBoxField.module.scss';


type TProps = {
	legend?: string;
	title?: string;
	placeholder?: string;

	isLoading?: boolean;
	required?: boolean;

	items: TFormListBoxFieldItem[];
	setItems: (values: TFormListBoxFieldItem[]) => void;
};

export function FormListBoxField(props: TProps) {
	const {
		items,
		setItems,

		isLoading,
		required,

		legend,
		title,
		placeholder,
	} = props;

	const onDelete = (value: string) => {
		const filtered = items.filter(item => value !== item.value);

		setItems(filtered);
	};

	const classNames = [styles.box];

	if (isLoading) {
		classNames.push(styles.isLoading);
	}

	return (
		<FormField
			legend={ legend }
			required={ required }
			className={ classNames.join(' ') }
		>

			<FormListBoxFieldWrap
				title={ title }
				placeholder={ placeholder }
				items={ items }
				onDelete={ onDelete }
			/>
		</FormField>
	);
}
