import { useState } from 'react';

import { Icon } from 'src/components';

import { IconInfoDetails, TIconInfo } from './components';

import styles from './IconInfo.module.scss';


export function IconInfo(props: TIconInfo) {
	const { title, headline, className } = props;

	const [detailed, setDetaied] = useState<TIconInfo>();

	const onBlur = () => {
		setDetaied(undefined);
	};

	const onFocus = (value: TIconInfo) => {
		setDetaied(value);
	};

	const lable = title || `Подробнее о функции «${headline}»`;

	const classNames = [styles.icon];

	if (className) {
		classNames.push(className);
	}

	return (
		<>
		<Icon
			icon="icon-info"
			tabIndex={ -1 }
			title={ lable }
			className={ classNames.join(' ') }
			onFocus={ e => onFocus(props) }
			onBlur={ onBlur }
		/>

			{ detailed && <IconInfoDetails item={ detailed }/> }
		</>
	);
}
