import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingAmocrmClientSecret = TSystemSettingValueCommon & {
  value?: string;
};

export type TSystemSettingAmocrmClientSecretResponse = TSystemSettingValueCommonResponse & {
  value?: string;
};

export type TSystemSettingAmocrmClientSecretPayload = {
	value?: string;
};

export type TSystemSettingAmocrmClientSecretRequest = {
	value?: string;
};

export function adaptSystemSettingAmocrmClientSecretFromResponse(data: TSystemSettingAmocrmClientSecretResponse): TSystemSettingAmocrmClientSecret {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingAmocrmClientSecretToRequest(data: TSystemSettingAmocrmClientSecretPayload): TSystemSettingAmocrmClientSecretRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
