import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS_CATEGORIES } from '../../backend-paths';


export const userCategoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_CATEGORIES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_CATEGORIES}/${payload}`;

		return HttpService.get<TUserCategoryResponse>(path).then(result => {
			return adaptUserCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCategoryReadSlice = createSlice({
	name: 'userCategoryReadSlice',
	initialState,
	reducers: {
		setUserCategoryRead(state, action: PayloadAction<TUserCategory>) {
			state.item = action.payload;
		},

		clearUserCategoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCategoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCategoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryReadSelector = () => useAppSelector(state => state[userCategoryReadSlice.name]);

export const { clearUserCategoryRead, setUserCategoryRead } = userCategoryReadSlice.actions;
