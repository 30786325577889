import { Link, LinkProps } from 'react-router-dom';

import styles from './FormButtonLink.module.scss';


export type TFormButtonLinkProps = LinkProps & {
	mini?: boolean;
	disabled?: boolean;
};

export function FormButtonLink(props: TFormButtonLinkProps) {
	const {
		children,
		mini = false,
		disabled = false,
		className = '',
		...restProps
	} = props;

	const classNameArr = [styles.formButtonLink];

	if (mini) {
		classNameArr.push(styles.mini);
	}

	if (disabled) {
		classNameArr.push(styles.disabled);
	}

	if (className && styles[className]) {
		classNameArr.push(styles[className]);
	} else if (className) {
		classNameArr.push(className);
	}

	return (
		<Link className={ classNameArr.join(' ') } { ...restProps }>
			{ children }
		</Link>
	);
}
