import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountDepartmentChangesPage = lazy(() => import('../views/list/AccountDepartmentChangesPage'));

export function AccountDepartmentChangesPageLazy() {
	return (
		<PageSuspense>
			<AccountDepartmentChangesPage />
		</PageSuspense>
	);
}
