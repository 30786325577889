import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentUpdatePage = lazy(() => import('../views/update/EquipmentUpdatePage'));

export function EquipmentUpdatePageLazy() {
	return (
		<PageSuspense>
			<EquipmentUpdatePage />
		</PageSuspense>
	);
}
