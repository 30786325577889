import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProductionCalendarsPage = lazy(() => import('../views/list/ProductionCalendarsPage'));

export function ProductionCalendarsPageLazy() {
	return (
		<PageSuspense>
			<ProductionCalendarsPage />
		</PageSuspense>
	);
}
