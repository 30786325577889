import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TEquipmentOrderHistory, TEquipmentOrderHistoryParams, TEquipmentOrderHistoryResponse, adaptEquipmentOrderHistoryFromResponse } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS } from '../../backend-paths';


export const equipmentOrderHistoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/:order_id/history/:id`,
	(payload: TEquipmentOrderHistoryParams, thunkApi) => {
		const { orderId, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/${orderId}/history/${id}`;

		return HttpService.get<TEquipmentOrderHistoryResponse>(path).then(result => {
			return adaptEquipmentOrderHistoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrderHistory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderHistoryReadSlice = createSlice({
	name: 'equipmentOrderHistoryReadSlice',
	initialState,
	reducers: {
		setEquipmentOrderHistoryRead(state, action: PayloadAction<TEquipmentOrderHistory>) {
			state.item = action.payload;
		},

		clearEquipmentOrderHistoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderHistoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentOrderHistoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderHistory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderHistoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentOrderHistoryReadSelector = () => useAppSelector(state => state[equipmentOrderHistoryReadSlice.name]);

export const { clearEquipmentOrderHistoryRead, setEquipmentOrderHistoryRead } = equipmentOrderHistoryReadSlice.actions;
