import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserPosition, adaptUserPositionFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_POSITIONS } from '../../backend-paths';


export const userPositionDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS_POSITIONS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_POSITIONS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptUserPositionFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPosition> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionDeleteSlice = createSlice({
	name: 'userPositionDeleteSlice',
	initialState,
	reducers: {
		clearUserPositionDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserPosition>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionDeleteSelector = () => useAppSelector(state => state[userPositionDeleteSlice.name]);

export const { clearUserPositionDelete } = userPositionDeleteSlice.actions;
