import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { WorkAreasSortCol, TWorkAreaList, TWorkAreaListResponse, TWorkAreaQuery, TWorkAreasSortCol, adaptWorkAreaFromListResponse, adaptWorkAreasQueryToRequest } from '../../types';
import { BACKEND_ADMIN_WORK_AREAS } from '../../backend-paths';


export const workAreasGetApi = createAsyncThunk(
	BACKEND_ADMIN_WORK_AREAS,
	(payload: TWorkAreaQuery | undefined, thunkApi) => {
		const params = adaptWorkAreasQueryToRequest(payload);

		return HttpService.get<TWorkAreaListResponse>(BACKEND_ADMIN_WORK_AREAS, { params }).then(result => {
			return adaptWorkAreaFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TWorkAreaList,
	query: TWorkAreaQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: WorkAreasSortCol.CREATED_AT,
	},
};

export const workAreasSlice = createSlice({
	name: 'workAreasSlice',
	initialState,
	reducers: {
		setWorkAreasQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setWorkAreasQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setWorkAreasQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setWorkAreasQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setWorkAreasQuerySortCol(state, action: PayloadAction<TWorkAreasSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearWorkAreas(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[workAreasGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[workAreasGetApi.fulfilled.type]: (state, action: PayloadAction<TWorkAreaList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[workAreasGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useWorkAreasSelector = () => useAppSelector(state => state[workAreasSlice.name]);

export const { clearWorkAreas, setWorkAreasQueryLimit, setWorkAreasQueryOffset, setWorkAreasQuerySort, setWorkAreasQuerySortCol, setWorkAreasQuerySearch } = workAreasSlice.actions;
