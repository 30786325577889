import { DetailedHTMLProps, Dispatch, HTMLAttributes, SetStateAction } from 'react';

import { Icon } from 'src/components/icon';

import styles from './AsideItemRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	text: string;
	icon: string;
	value: boolean;
	isHover: boolean;
	isActive: boolean;
	isTablet: boolean;
	setValue: Dispatch<SetStateAction<boolean>>;
};

export function AsideItemRow(props: TProps) {
	const {
		text,
		icon,
		setValue,
		value,
		isHover,
		isActive,
		isTablet,
		className: classNameOuter,
		...restProps
	} = props;

	const inActiveChevronIcon = isTablet ? 'icon-chevron-bottom' : 'icon-chevron-right';
	const activeChevronIcon = isTablet ? 'icon-chevron-top' : 'icon-chevron-bottom';
	const chevronIcon = value ? activeChevronIcon : inActiveChevronIcon;

	const classNames = [styles.box];
	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	if (isHover) {
		classNames.push(styles.hover);
	}

	if (isActive) {
		if (!value) {
			classNames.push(styles.collapsed);
		}
	} else {
		if (value) {
			classNames.push(styles.active);
		}
	}

	return (
		<div className={ classNames.join(' ') } onClick={ e => setValue(!value) } { ...restProps }>
			<span>
				<Icon icon={ icon } className={ styles.iconLink }/>
				{ text }
			</span>

			<Icon icon={ chevronIcon } className={ styles.iconDirection } />
		</div>
	);
}
