import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountShiftReport, TAccountShiftReportResponse, adaptAccountShiftReportFromResponse } from '../../types';
import { BACKEND_ACCOUNT_SHIFTS } from '../../backend-paths';


export const accountShiftReportReadGetApi = createAsyncThunk(
	`GET/${BACKEND_ACCOUNT_SHIFTS}/:shiftId/report`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SHIFTS}/${payload}/report`;

		return HttpService.get<TAccountShiftReportResponse>(path).then(result => {
			return adaptAccountShiftReportFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountShiftReport> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountShiftReportReadSlice = createSlice({
	name: 'accountShiftReportReadSlice',
	initialState,
	reducers: {
		setAccountShiftReportRead(state, action: PayloadAction<TAccountShiftReport>) {
			state.item = action.payload;
		},

		clearAccountShiftReportRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountShiftReportReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountShiftReportReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountShiftReport>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountShiftReportReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountShiftReportReadSelector = () => useAppSelector(state => state[accountShiftReportReadSlice.name]);

export const { clearAccountShiftReportRead, setAccountShiftReportRead } = accountShiftReportReadSlice.actions;
