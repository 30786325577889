import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingCalendarEventColorProject, TSystemSettingCalendarEventColorProjectPayload, TSystemSettingCalendarEventColorProjectResponse, adaptSystemSettingCalendarEventColorProjectFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT } from '../../backend-paths';


export const systemSettingCalendarEventColorProjectUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT}`,
	(payload: TSystemSettingCalendarEventColorProjectPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT;

		return HttpService.put<TSystemSettingCalendarEventColorProjectResponse>(path, payload).then(result => {
			return adaptSystemSettingCalendarEventColorProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingCalendarEventColorProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingCalendarEventColorProjectUpdateSlice = createSlice({
	name: 'systemSettingCalendarEventColorProjectUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingCalendarEventColorProjectUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingCalendarEventColorProjectUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingCalendarEventColorProjectUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingCalendarEventColorProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingCalendarEventColorProjectUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingCalendarEventColorProjectUpdateSelector = () => useAppSelector(state => state[systemSettingCalendarEventColorProjectUpdateSlice.name]);

export const { clearSystemSettingCalendarEventColorProjectUpdate } = systemSettingCalendarEventColorProjectUpdateSlice.actions;
