import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsStoragesPage = lazy(() => import('../views/list/EquipmentsStoragesPage'));

export function EquipmentsStoragesPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsStoragesPage />
		</PageSuspense>
	);
}
