import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProjectStatus, adaptProjectStatusFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_PROJECTS_STATUSES } from '../../backend-paths';


export const projectStatusDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_PROJECTS_STATUSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_PROJECTS_STATUSES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptProjectStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectStatusDeleteSlice = createSlice({
	name: 'projectStatusDeleteSlice',
	initialState,
	reducers: {
		clearProjectStatusDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectStatusDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectStatusDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProjectStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectStatusDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectStatusDeleteSelector = () => useAppSelector(state => state[projectStatusDeleteSlice.name]);

export const { clearProjectStatusDelete } = projectStatusDeleteSlice.actions;
