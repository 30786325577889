import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

import { FormInput } from 'src/components';
import { Icon } from 'src/components/icon';
import { TEquipmentOrderRelationEquipmentGroupCreate } from 'src/store';
import { libPriceToInt, libHandlePriceInt } from 'src/toolkit';

import styles from './EquipmentGroupListBox.module.scss';


export type TEquipmentGroupListBoxItem = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	index: number;
	item: TEquipmentOrderRelationEquipmentGroupCreate;
	changeAmount: (groupId: number, amount: number) => void;
};

export function EquipmentGroupListBoxItem(props: TProps) {
	const {
		item,
		index,
		onClick = () => {},
		changeAmount,
		...restProps
	} = props;

	const { amount, group } = item;
	const { name, amount: maxAmount } = group;

	useEffect(() => {
		onChange(amount.toString());
	}, [amount]);

	const [value, setValue] = useState(amount.toString());

	const onChange = (value: string) => {
		const formatted = libHandlePriceInt(value, { min: 1, max: maxAmount });

		setValue(formatted);

		changeAmount(item.group.id, libPriceToInt(formatted));
	};

	const classNames = [styles.item];

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			<div className={ styles.text }>
				<span className={ styles.index }>{ index }.</span>
				{/* <span className={ styles.categories }>{ categories.join(' / ') } /</span> */}
				<span className={ styles.name }>{ name }</span>
				{/* <span className={ styles.categories }>•</span> */}
				{/* <span className={ styles.publicId }>{ publicId }</span> */}
			</div>

			<FormInput
				className={ styles.amount }
				type="text"
				placeholder="1"
				name="amount"
				title="Укажите количество"
				value={ value }
				onChange={ e => onChange(e.target.value) }
				autoComplete="off"
				// disabled={ isLoading }
				// readOnly={ readonly }
			/>

			<Icon className={ styles.icon } onClick={ onClick } icon="icon-cancel"/>
		</div>
	);
}
