import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_STATIC_USER_IMAGES } from 'src/routes';
import { TUserType, TUserTypeResponse, adaptUserTypeFromResponse } from './user-type.type';
import { TUserPosition, TUserPositionResponse, adaptUserPositionFromResponse } from './user-position.type';
import { TUserCategory, TUserCategoryResponse, adaptUserCategoryFromResponse } from './user-category.type';


export const UsersSortCol = {
	LAST_NAME: 'last_name',
	NICKNAME: 'nickname',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
	PHONE_NUMBER: 'phone_number',
	EMAIL: 'email',
};

export type TUsersSortCol = TTableSortCols<typeof UsersSortCol>;

export type TUserQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersSortCol;
	isActive?: boolean;
};

export type TUsersQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
	is_active?: boolean;
};

export type TUser = {
  nickname: string;
  password?: string;
  isActive?: boolean;
  image: string;
	createdAt: string;
  updatedAt: string;

	phoneNumber?: string,
	email?: string,
	type?: TUserType,
	position?: TUserPosition,
	category?: TUserCategory,
};

export type TUserResponse = {
  nickname: string;
  is_active: boolean;
  image?: string;
  created_at: string;
  updated_at: string;

	phone_number?: string,
	email?: string,
	type?: TUserTypeResponse,
	position?: TUserPositionResponse,
	category?: TUserCategoryResponse,
};

export type TUserListResponse = TListResponse<TUserResponse>;

export type TUserList = TListResponse<TUser>;

export type TUserBodyRequest = {
	nickname: string;
	isActive?: boolean;
	password?: string;
};

export type TUserRequest = {
	nickname: string;
	is_active?: boolean;
	password?: string;
};

export type TUserPasswordRequest = Pick<TUser, 'password'>;

export type TUserPasswordUpdate = {
	nickname: string;
	data: TUserPasswordRequest;
};

export type TUserUpdate = {
	nickname: string;
	data: TUserBodyRequest;
};

export type TUserTypeRelationPayload = {
	nickname: string;
	typeId: number;
};

export function adaptUserFromListResponse(data: TUserListResponse): TUserList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserFromResponse(data: TUserResponse): TUser {
	const {
		is_active: isActive,
		created_at: createdAt,
		updated_at: updatedAt,
		image: imageRaw,
		phone_number: phoneNumber,
		email,
		type: typeRaw,
		position: positionRaw,
		category: categoryRaw,
		...restFields
	} = data;

	const image = imageRaw ? `${PATH_STATIC_USER_IMAGES}/${imageRaw}` : PATH_STATIC_USER_IMAGES;
	const type = typeRaw ? adaptUserTypeFromResponse(typeRaw) : undefined;
	const position = positionRaw ? adaptUserPositionFromResponse(positionRaw) : undefined;
	const category = categoryRaw ? adaptUserCategoryFromResponse(categoryRaw) : undefined;

	return {
		...restFields,
		isActive,
		image,
		phoneNumber,
		email,
		type,
		position,
		category,
		createdAt,
		updatedAt,
	};
}

export function adaptUserToRequest(data: TUserBodyRequest): TUserRequest {
	const {
		nickname,
		isActive = false,
		password = undefined,
	} = data;

	return {
		nickname,
		is_active: isActive,
		password,
	};
}
