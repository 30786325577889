import { InputHTMLAttributes, ReactNode } from 'react';

import { FormInputFieldReadonlyFontColor, TFormInputFieldReadonlyFontColor } from '../form-input-field-readonly';

import styles from './FormCheckboxInfo.module.scss';


type TProps = InputHTMLAttributes<HTMLInputElement> & {
	classNameBox?: string;
	nodeBefore?: ReactNode;
	marginBottom?: boolean;

	fontColor?: TFormInputFieldReadonlyFontColor;
};

export function FormCheckboxInfo(props: TProps) {
	const {
		id,
		classNameBox = '',
		className = '',
		children,
		nodeBefore,
		marginBottom = false,
		fontColor,
		...restProps
	} = props;

	const classNamesBox = [styles.box];
	if (marginBottom) {
		classNamesBox.push(styles.marginBottom);
	}

	const classNamesRow = [styles.row];
	if (classNameBox) {
		classNamesRow.push(classNameBox);
	}

	const classNames = [styles.formCheckbox];
	if (className) {
		classNames.push(className);
	}

	const classNamesLabel = [];

	if (fontColor) {
		if (fontColor === FormInputFieldReadonlyFontColor.RED) {
			classNamesLabel.push(styles.colorRed);
		} else if (fontColor === FormInputFieldReadonlyFontColor.GREEN) {
			classNamesLabel.push(styles.colorGreen);
		}
	}

	return (
		<div className={ classNamesBox.join(' ') }>
			{ nodeBefore }

			<div className={ classNamesRow.join(' ') }>
				<input
					id={ id }
					className={ classNames.join(' ') }
					{ ...restProps }
					type="checkbox"
					required={ false }
				/>
				<label className={ classNamesLabel.join(' ') } htmlFor={ id }>{ children }</label>
			</div>
		</div>
	);
}
