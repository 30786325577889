import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TPaymentType, TPaymentTypeResponse, adaptPaymentTypeFromResponse } from '../../types';
import { BACKEND_ADMIN_PAYMENTS_TYPES } from '../../backend-paths';


export const paymentTypeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_PAYMENTS_TYPES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_PAYMENTS_TYPES}/${payload}`;

		return HttpService.get<TPaymentTypeResponse>(path).then(result => {
			return adaptPaymentTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TPaymentType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const paymentTypeReadSlice = createSlice({
	name: 'paymentTypeReadSlice',
	initialState,
	reducers: {
		setPaymentTypeRead(state, action: PayloadAction<TPaymentType>) {
			state.item = action.payload;
		},

		clearPaymentTypeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[paymentTypeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[paymentTypeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TPaymentType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[paymentTypeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const usePaymentTypeReadSelector = () => useAppSelector(state => state[paymentTypeReadSlice.name]);

export const { clearPaymentTypeRead, setPaymentTypeRead } = paymentTypeReadSlice.actions;
