import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipment, TEquipmentUpdatePayload, TEquipmentResponse, adaptEquipmentFromResponse, adaptEquipmentToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS } from '../../backend-paths';


export const equipmentUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS}/:id`,
	(payload: TEquipmentUpdatePayload, thunkApi) => {
		const { id, data, files } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS}/${id}`;
		const dataRequest = adaptEquipmentToRequest(data);

		return HttpService.put<TEquipmentResponse>(path, dataRequest).then(result => {
			const pathFiles = `${path}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TEquipmentResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptEquipmentFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipment> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentUpdateSlice = createSlice({
	name: 'equipmentUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipment>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentUpdateSelector = () => useAppSelector(state => state[equipmentUpdateSlice.name]);

export const { clearEquipmentUpdate } = equipmentUpdateSlice.actions;
