import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ScheduleEventUpdatePage = lazy(() => import('../views/update/ScheduleEventUpdatePage'));

export function ScheduleEventUpdatePageLazy() {
	return (
		<PageSuspense>
			<ScheduleEventUpdatePage />
		</PageSuspense>
	);
}
