import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ProjectsOrdersSourceSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersSourceSortCol = TTableSortCols<typeof ProjectsOrdersSourceSortCol>;

export type TProjectOrderSourceQueryPayload = {
	projectId: number;
	data: TProjectOrderSourceQuery;
};

export type TProjectOrderSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersSourceSortCol;
};

export type TProjectsOrdersSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderSourcePayloadQuery = {
	projectId: number;
	data?: TProjectOrderSourceQuery;
};

export function adaptProjectsOrdersSourceQueryToRequest(data?: TProjectOrderSourceQuery): TProjectsOrdersSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderSource = {
  orderId: number;
  projectId: number;
  orderName: string;
  projectName: string;
};

export type TProjectOrderSourceResponse = {
  order_id: number;
  order_name: string;
  project_id: number;
  project_name: string;
};

export type TProjectOrderSourceListResponse = TListResponse<TProjectOrderSourceResponse>;

export type TProjectOrderSourceList = TListResponse<TProjectOrderSource>;

export function adaptProjectOrderSourceFromListResponse(data: TProjectOrderSourceListResponse): TProjectOrderSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderSourceFromResponse(data: TProjectOrderSourceResponse): TProjectOrderSource {
	const {
		order_id: orderId = 0,
		order_name: orderName = '—',
		project_id: projectId = 0,
		project_name: projectName = '—',
	} = data || {};

	return {
		orderId,
		orderName,
		projectId,
		projectName,
	};
}
