import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersPage = lazy(() => import('../views/list/UsersPage'));

export function UsersPageLazy() {
	return (
		<PageSuspense>
			<UsersPage />
		</PageSuspense>
	);
}
