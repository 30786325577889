import { useEffect } from 'react';

import { Link } from 'src/components';
import { PATH_SIGN_UP } from 'src/routes';
import { clearSignIn, clearSignUpLoginAction, useAppDispatch } from 'src/store';
import { setTitle } from 'src/common/helpers';

import { AuthLayer } from '../../components';

import { SignInForm } from './components';

import styles from './SignInPage.module.scss';


export function SignInPage() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearSignUpLoginAction());
			dispatch(clearSignIn());
		};
	}, []);

	setTitle('Авторизация');

	return (
		<AuthLayer
			contentLinkPath={ PATH_SIGN_UP }
			headline="Вход в аккаунт"
			contentLeftHalf="Вы можете авторизироваться, если у вас уже есть аккаунт, или "
			contentLink="Зарегистрироваться"
			contentRightHalf=" если вы этого ещё не сделали."
		>
			<SignInForm />

			<p className={ styles.underText }>
				<span>Ещё нет аккаунта? </span>
				<Link to={ PATH_SIGN_UP }>Зарегистрироваться</Link>
			</p>
		</AuthLayer>
	);
}

