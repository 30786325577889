import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountTaxationReceipt, TAccountTaxationReceiptPayloadUpdate, TAccountTaxationReceiptResponse, adaptAccountTaxationReceiptFromResponse, adaptAccountTaxationReceiptToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_TAXATION_RECEIPTS } from '../../backend-paths';


export const accountTaxationReceiptUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_TAXATION_RECEIPTS}/:id`,
	(payload: TAccountTaxationReceiptPayloadUpdate, thunkApi) => {
		const { id, data } = payload;
		const path = `${BACKEND_ACCOUNT_TAXATION_RECEIPTS}/${id}`;

		const dataRequest = adaptAccountTaxationReceiptToRequest(data);

		return HttpService.put<TAccountTaxationReceiptResponse>(path, dataRequest).then(result => {
			return adaptAccountTaxationReceiptFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTaxationReceipt> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTaxationReceiptUpdateSlice = createSlice({
	name: 'accountTaxationReceiptUpdateSlice',
	initialState,
	reducers: {
		clearAccountTaxationReceiptUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTaxationReceiptUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountTaxationReceiptUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxationReceipt>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTaxationReceiptUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountTaxationReceiptUpdateSelector = () => useAppSelector(state => state[accountTaxationReceiptUpdateSlice.name]);

export const { clearAccountTaxationReceiptUpdate } = accountTaxationReceiptUpdateSlice.actions;
