import { useState } from 'react';

import { PageCol } from 'src/components';

import { HeadlineBlock } from '../headline-block';
import { TextBlock } from '../text-block';

import { TagModal } from './modal';
import { TagListItem } from './TagListItem';

import styles from './TagList.module.scss';


export type TTagListItem = {
	id: number;
	name: string;
	description?: string;
};

type TProps = {
	title?: string;
	placeholder?: string;

	items: TTagListItem[];
};

export function TagList(props: TProps) {
	const {
		items = [],
		title,
		placeholder,
	} = props;

	const [tag, setTag] = useState<TTagListItem>();

	const onBlur = () => {
		setTag(undefined);
	};

	const onFocus = (value: TTagListItem) => {
		setTag(value);
	};

	if (items.length <= 0) {
		return (
			<PageCol className={ styles.box }>
				<HeadlineBlock>{ title }</HeadlineBlock>
				<TextBlock>{ placeholder }</TextBlock>
			</PageCol>
		);
	}

	return (
		<PageCol className={ styles.box }>
			<HeadlineBlock>{ title }</HeadlineBlock>
		 	<div className={ styles.container }>
			 	{ items.map(item => <TagListItem key={ item.id } item={ item } onFocus={ onFocus } onBlur={ onBlur } />) }
			</div>
			{ tag && <TagModal item={ tag } /> }
		</PageCol>
	);
}
