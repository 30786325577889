import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSystemSettingReportsPhotoNotAttachingPenalty, TSystemSettingReportsPhotoNotAttachingPenaltyPayload, TSystemSettingReportsPhotoNotAttachingPenaltyResponse, adaptSystemSettingReportsPhotoNotAttachingPenaltyFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY } from '../../backend-paths';


export const systemSettingReportsPhotoNotAttachingPenaltyUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY}`,
	(payload: TSystemSettingReportsPhotoNotAttachingPenaltyPayload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY;

		return HttpService.put<TSystemSettingReportsPhotoNotAttachingPenaltyResponse>(path, payload).then(result => {
			return adaptSystemSettingReportsPhotoNotAttachingPenaltyFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingReportsPhotoNotAttachingPenalty> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingReportsPhotoNotAttachingPenaltyUpdateSlice = createSlice({
	name: 'systemSettingReportsPhotoNotAttachingPenaltyUpdateSlice',
	initialState,
	reducers: {
		clearSystemSettingReportsPhotoNotAttachingPenaltyUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingReportsPhotoNotAttachingPenaltyUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingReportsPhotoNotAttachingPenaltyUpdateApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingReportsPhotoNotAttachingPenalty>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingReportsPhotoNotAttachingPenaltyUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingReportsPhotoNotAttachingPenaltyUpdateSelector = () => useAppSelector(state => state[systemSettingReportsPhotoNotAttachingPenaltyUpdateSlice.name]);

export const { clearSystemSettingReportsPhotoNotAttachingPenaltyUpdate } = systemSettingReportsPhotoNotAttachingPenaltyUpdateSlice.actions;
