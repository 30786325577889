// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsideItemMenu_box__IXH-\\+ {\n  background-color: rgb(0, 20, 20);\n  border: 1px solid rgb(0, 60, 60);\n  box-sizing: border-box;\n  list-style: none;\n  margin: 0;\n  z-index: 1;\n  position: absolute;\n  top: 0;\n  left: 100%;\n  transform: translate3d(-0.7em, 1px, 0);\n}", "",{"version":3,"sources":["webpack://./src/components/nav/aside-item/components/menu/AsideItemMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACD,gCAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;EAEA,UAAA;EAEA,kBAAA;EACA,MAAA;EACA,UAAA;EAEA,sCAAA;AAFD","sourcesContent":[".box {\n  background-color: rgba(0, 20, 20, 1);\n\tborder: 1px solid rgba(0, 60, 60, 1);\n\tbox-sizing: border-box;\n\tlist-style: none;\n\tmargin: 0;\n\n\tz-index: 1;\n\n\tposition: absolute;\n\ttop: 0;\n\tleft: 100%;\n\n\ttransform: translate3d(-0.7em, 1px, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AsideItemMenu_box__IXH-+"
};
export default ___CSS_LOADER_EXPORT___;
