import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderGroupShift, TProjectOrderGroupShiftResponse, adaptProjectOrderGroupShiftFromResponse } from './project-order-group-shift.type';
import { adaptGroupShiftReportTagTypeSourceFromResponse, TGroupShiftReportTagTypeSource, TGroupShiftReportTagTypeSourceResponse } from './group-shift-report-tag-type-source.type';


export const ProjectsOrdersGroupsShiftsReportsSortCol = {
	ID: 'id',
	LAST_NAME: 'last_name',
	RATE: 'rate',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersGroupsShiftsReportsSortCol = TTableSortCols<typeof ProjectsOrdersGroupsShiftsReportsSortCol>;

export type TProjectOrderGroupShiftReportQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersGroupsShiftsReportsSortCol;
};

export type TProjectsOrdersGroupsShiftsReportsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderGroupShiftReportPayloadQuery = {
	groupId: number;
	orderId: number;
	projectId: number;
	data?: TProjectOrderGroupShiftReportQuery;
};

export function adaptProjectsOrdersGroupsShiftsReportsQueryRequest(data: TProjectOrderGroupShiftReportQuery): TProjectsOrdersGroupsShiftsReportsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrderGroupShiftReportFile = {
	name: string;
	path: string;
};

export type TProjectOrderGroupShiftReportFileRequest = Array<File | string>;

export type TProjectOrderGroupShiftReport = {
  shiftId: number;
  datetimeStart: string;
  datetimeEnd: string;
  equipmentsContent?: string;
  taskContent: string;
	reportContent: string;

	shift: TProjectOrderGroupShift;
	files?: TProjectOrderGroupShiftReportFile[];
	tags: TGroupShiftReportTagTypeSource[];

	createdAt?: string;
  updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectOrderGroupShiftReportResponse = {
  shift_id: number;
  datetime_start: string;
  datetime_end: string;
  equipments_content?: string;
  task_content: string;
	report_content: string;

	shift: TProjectOrderGroupShiftResponse;
	files?: TProjectOrderGroupShiftReportFile[];
	tags?: TGroupShiftReportTagTypeSourceResponse[];

  created_at?: string;
  updated_at?: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectOrderGroupShiftReportListResponse = TListResponse<TProjectOrderGroupShiftReportResponse>;

export type TProjectOrderGroupShiftReportList = TListResponse<TProjectOrderGroupShiftReport>;

export type TProjectOrderGroupShiftReportBodyRequest = {
	datetimeStart: string;
	datetimeEnd: string;
	equipmentsContent: string;
	taskContent: string;
	reportContent: string;
};

export type TProjectOrderGroupShiftReportRequest = {
	datetime_start: string;
	datetime_end: string;
	equipments_content: string;
	task_content: string;
	report_content: string;
};

export type TProjectOrderGroupShiftReportPayload = {
	shiftId: number;
	groupId: number;
	orderId: number;
	projectId: number;
};

export type TProjectOrderGroupShiftReportPayloadCreate = {
	shiftId: number;
	groupId: number;
	orderId: number;
	projectId: number;
	data: TProjectOrderGroupShiftReportBodyRequest;
};

export type TProjectOrderGroupShiftReportPayloadUpdate = TProjectOrderGroupShiftReportPayloadCreate & {
	id: number;
};

export function adaptProjectOrderGroupShiftReportFromListResponse(data: TProjectOrderGroupShiftReportListResponse): TProjectOrderGroupShiftReportList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderGroupShiftReportFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderGroupShiftReportFromResponse(data: TProjectOrderGroupShiftReportResponse): TProjectOrderGroupShiftReport {
	const {
		shift_id: shiftId,
		datetime_start: datetimeStart,
		datetime_end: datetimeEnd,
		equipments_content: equipmentsContent,
		task_content: taskContent,
		report_content: reportContent,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		shift: shiftRaw,
		files = [],
		tags: tagsRaw = [],
	} = data;

	const shift = shiftRaw ? adaptProjectOrderGroupShiftFromResponse(shiftRaw) : shiftRaw;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);
	const tags = tagsRaw.map(item => adaptGroupShiftReportTagTypeSourceFromResponse(item));

	return {
		shiftId,
		datetimeStart,
		datetimeEnd,
		equipmentsContent,
		taskContent,
		reportContent,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
		shift,
		files,
		tags,
	};
}

export function adaptProjectOrderGroupShiftReportToRequest(data: TProjectOrderGroupShiftReportBodyRequest): TProjectOrderGroupShiftReportRequest {
	const {
		datetimeStart,
		datetimeEnd,
		equipmentsContent,
		taskContent,
		reportContent,
	} = data;

	return {
		datetime_start: datetimeStart,
		datetime_end: datetimeEnd,
		equipments_content: equipmentsContent,
		task_content: taskContent,
		report_content: reportContent,
	};
}
