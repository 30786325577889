import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TUserPositionChange, TUserPositionChangeParams, TUserPositionChangeResponse, adaptUserPositionChangeFromResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPositionChangeReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/positions/:id`,
	(payload: TUserPositionChangeParams, thunkApi) => {
		const { nickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/positions/${id}`;

		return HttpService.get<TUserPositionChangeResponse>(path).then(result => {
			return adaptUserPositionChangeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserPositionChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionChangeReadSlice = createSlice({
	name: 'userPositionChangeReadSlice',
	initialState,
	reducers: {
		setUserPositionChangeRead(state, action: PayloadAction<TUserPositionChange>) {
			state.item = action.payload;
		},

		clearUserPositionChangeRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionChangeReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionChangeReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPositionChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionChangeReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionChangeReadSelector = () => useAppSelector(state => state[userPositionChangeReadSlice.name]);

export const { clearUserPositionChangeRead, setUserPositionChangeRead } = userPositionChangeReadSlice.actions;
