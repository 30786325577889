import { libFormatDate } from 'src/toolkit';


export type TAccountWorkDataFile = {
	name: string;
	path: string;
};

export type TAccountWorkDataFileRequest = Array<File | string>;

export type TAccountWorkData = {
	domainLogin: string;
  stateDate: string;

  files: TAccountWorkDataFile[];
};

export type TAccountWorkDataBodyRequest = {
	domainLogin?: string;
  stateDate?: string;
};

export type TAccountWorkDataResponse = {
	domain_login: string;
  state_date: string;

  files: TAccountWorkDataFile[];
};

export type TAccountWorkDataRequest = {
	domain_login?: string;
  state_date?: string;
};

export type TAccountWorkDataPayload = {
	data: TAccountWorkDataBodyRequest;

	files: TAccountWorkDataFileRequest;
};

export function adaptAccountWorkDataFromResponse(data: TAccountWorkDataResponse): TAccountWorkData {
	const {
		domain_login: domainLogin = '',
		state_date: stateDateRaw,

		files = [],
	} = data;

	const stateDate = stateDateRaw ? libFormatDate(stateDateRaw, { template: 'yyyy-MM-dd' }) : '';

	return {
		domainLogin,
		stateDate,

		files,
	};
}

export function adaptAccountWorkDataToRequest(data: TAccountWorkDataBodyRequest): TAccountWorkDataRequest {
	const {
		domainLogin,
		stateDate,
	} = data;

	return {
		domain_login: domainLogin,
		state_date: stateDate,
	};
}

