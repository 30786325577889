export const SYSTEM_SETTINGS = {
	USERS_ACCOUNT_USER_TYPE: 'users-account-user-type',
	SHIFTS_NOT_COUNTING_OVERWORK_TIME: 'shifts-not-counting-overwork-time',
	SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES: 'shifts-is-notice-users-by-shift-changes',
	REPORTS_WRITING_DELAY_PENALTY: 'reports-writing-delay-penalty',
	REPORTS_PHOTO_NOT_ATTACHING_PENALTY: 'reports-photo-not-attaching-penalty',
	BOOKKEEPING_BILL_CHANGES_CONSTRAINT: 'bookkeeping-bill-changes-next-month-day-constraint',
	BOOKKEEPING_WORK_DAY_HOURS_COUNT: 'bookkeeping-work-day-hours-count',
	CALENDAR_EVENT_COLOR_BIRTHDAY: 'calendar-event-color-birthday',
	CALENDAR_EVENT_COLOR_PROJECT: 'calendar-event-color-project',
	AMOCRM_CLIENT_ID: 'amocrm-client-id',
	AMOCRM_CLIENT_SECRET: 'amocrm-client-secret',
};
