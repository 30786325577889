import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { EquipmentsOrdersHistorySortCol, TEquipmentOrderHistoryList, TEquipmentOrderHistoryListResponse, TEquipmentOrderHistoryQuery, TEquipmentOrderHistoryQueryPayload, TEquipmentsOrdersHistorySortCol, adaptEquipmentOrderHistoryFromListResponse, adaptEquipmentsOrdersHistoryQueryToRequest } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS } from '../../backend-paths';


export const equipmentsOrdersHistoryGetApi = createAsyncThunk(
	`${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/:order_id/history`,
	(payload: TEquipmentOrderHistoryQueryPayload, thunkApi) => {
		const { orderId, query } = payload;
		const params = adaptEquipmentsOrdersHistoryQueryToRequest(query);
		const path = `${BACKEND_ADMIN_EQUIPMENTS_ORDERS}/${orderId}/history`;

		return HttpService.get<TEquipmentOrderHistoryListResponse>(path, { params }).then(result => {
			return adaptEquipmentOrderHistoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentOrderHistoryList;
	query: TEquipmentOrderHistoryQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.DESC,
		sortCol: EquipmentsOrdersHistorySortCol.CREATED_AT,
	},
	error: undefined,
};

export const equipmentsOrdersHistorySlice = createSlice({
	name: 'equipmentsOrdersHistorySlice',
	initialState,
	reducers: {
		setEquipmentsOrdersHistoryQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setEquipmentsOrdersHistoryQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setEquipmentsOrdersHistoryQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setEquipmentsOrdersHistoryQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setEquipmentsOrdersHistoryQuerySortCol(state, action: PayloadAction<TEquipmentsOrdersHistorySortCol>) {
			state.query.sortCol = action.payload;
		},

		clearEquipmentsOrdersHistory(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[equipmentsOrdersHistoryGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsOrdersHistoryGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderHistoryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[equipmentsOrdersHistoryGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsOrdersHistorySelector = () => useAppSelector(state => state[equipmentsOrdersHistorySlice.name]);

export const { clearEquipmentsOrdersHistory, setEquipmentsOrdersHistoryQueryLimit, setEquipmentsOrdersHistoryQueryOffset, setEquipmentsOrdersHistoryQuerySort, setEquipmentsOrdersHistoryQuerySortCol, setEquipmentsOrdersHistoryQuerySearch } = equipmentsOrdersHistorySlice.actions;
