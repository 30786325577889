import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserPasswordPage = lazy(() => import('../views/update/UserPasswordPage'));

export function UserPasswordPageLazy() {
	return (
		<PageSuspense>
			<UserPasswordPage />
		</PageSuspense>
	);
}
