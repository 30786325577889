import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const WorkAreasPage = lazy(() => import('../views/list/WorkAreasPage'));

export function WorkAreasPageLazy() {
	return (
		<PageSuspense>
			<WorkAreasPage />
		</PageSuspense>
	);
}
