import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProjectOrderGroupShiftExpenseFile, TProjectOrderGroupShiftExpenseFileRequest } from './project-order-group-shift-expense.type';
import { TGroupShiftExpenseTypeSource, TGroupShiftExpenseTypeSourceResponse, adaptGroupShiftExpenseTypeSourceFromResponse } from './group-shift-expense-type-source.type';
import { TAccountShift, TAccountShiftResponse, adaptAccountShiftFromResponse } from './account-shift.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const AccountShiftExpensesSortCol = {
	NAME: 'name',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountShiftExpensesSortCol = TTableSortCols<typeof AccountShiftExpensesSortCol>;

export type TAccountShiftExpensesQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountShiftExpensesSortCol;
};

export type TAccountShiftExpensesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountShiftExpensesQueryPayload = {
	data?: TAccountShiftExpensesQuery;
	shiftId: number;
};

export function adaptAccountShiftExpensesQueryToRequest(data?: TAccountShiftExpensesQuery): TAccountShiftExpensesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TAccountShiftExpense = {
	id: number;
	shiftId: number;
	value: number;
	userDescription?: string;
	adminDescription?: string;
	shift?: TAccountShift;
	shiftExpenseType: TGroupShiftExpenseTypeSource;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;

	files?: TProjectOrderGroupShiftExpenseFile[];
};

export type TAccountShiftExpenseResponse = {
	id: number;
	shift_id: number;
	value: number;
	user_description?: string;
	admin_description?: string;
	shift?: TAccountShiftResponse;
	shift_expense_type: TGroupShiftExpenseTypeSourceResponse;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;

	files?: TProjectOrderGroupShiftExpenseFile[];
};

export type TAccountShiftExpenseListResponse = TListResponse<TAccountShiftExpenseResponse>;

export type TAccountShiftExpenseList = TListResponse<TAccountShiftExpense>;

export type TAccountShiftExpenseBodyRequest = {
	value: number;
	userDescription?: string;
	shiftExpenseTypeId: number;
};

export type TAccountShiftExpenseRequest = {
	value: number;
	user_description?: string;
	shift_expense_type_id: number;
};

export type TAccountShiftExpensePayload = {
	shiftId: number;
};

export type TAccountShiftExpensePayloadCreate = TAccountShiftExpensePayload & {
	data: TAccountShiftExpenseBodyRequest;
	files: TProjectOrderGroupShiftExpenseFileRequest;
};

export type TAccountShiftExpensePayloadUpdate = {
	id: number;
	data: TAccountShiftExpenseBodyRequest;
	files: TProjectOrderGroupShiftExpenseFileRequest;
};

export function adaptAccountShiftExpenseFromListResponse(data: TAccountShiftExpenseListResponse): TAccountShiftExpenseList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountShiftExpenseFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountShiftExpenseFromResponse(data: TAccountShiftExpenseResponse): TAccountShiftExpense {
	const {
		id,
		shift_id: shiftId,
		value: value,
		user_description: userDescriptionRaw,
		admin_description: adminDescriptionRaw,
		shift_expense_type: shiftExpenseTypeRaw,
		shift: shiftRaw,
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,

		files = [],
	} = data;

	const userDescription = userDescriptionRaw ? userDescriptionRaw : '';
	const adminDescription = adminDescriptionRaw ? adminDescriptionRaw : '';
	const shift = shiftRaw ? adaptAccountShiftFromResponse(shiftRaw) : shiftRaw;
	const shiftExpenseType = adaptGroupShiftExpenseTypeSourceFromResponse(shiftExpenseTypeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		shiftId,
		value,
		userDescription,
		adminDescription,
		shift,
		shiftExpenseType,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,

		files,
	};
}

export function adaptAccountShiftExpenseToRequest(data: TAccountShiftExpenseBodyRequest): TAccountShiftExpenseRequest {
	const {
		value,
		userDescription,
		shiftExpenseTypeId,
	} = data;

	return {
		value,
		user_description: userDescription,
		shift_expense_type_id: shiftExpenseTypeId,
	};
}
