import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectClient, TProjectClientUpdate, TProjectClientResponse, adaptProjectClientFromResponse, adaptProjectClientToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_CLIENTS } from '../../backend-paths';


export const projectClientUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS_CLIENTS}/:id`,
	(payload: TProjectClientUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_PROJECTS_CLIENTS}/${id}`;
		const dataRequest = adaptProjectClientToRequest(data);

		return HttpService.put<TProjectClientResponse>(path, dataRequest).then(result => {
			return adaptProjectClientFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectClient> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectClientUpdateSlice = createSlice({
	name: 'projectClientUpdateSlice',
	initialState,
	reducers: {
		clearProjectClientUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectClientUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectClientUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProjectClient>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectClientUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectClientUpdateSelector = () => useAppSelector(state => state[projectClientUpdateSlice.name]);

export const { clearProjectClientUpdate } = projectClientUpdateSlice.actions;
