import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TFinancialChangeType, TFinancialChangeTypeBodyRequest, TFinancialChangeTypeResponse, adaptFinancialChangeTypeFromResponse, adaptFinancialChangeTypeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES } from '../../backend-paths';


export const financialChangeTypeCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES}`,
	(payload: TFinancialChangeTypeBodyRequest, thunkApi) => {
		const dataRequest = adaptFinancialChangeTypeToRequest(payload);

		return HttpService.post<TFinancialChangeTypeResponse>(BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES, dataRequest).then(result => {
			return adaptFinancialChangeTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TFinancialChangeType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const financialChangeTypeCreateSlice = createSlice({
	name: 'financialChangeTypeCreateSlice',
	initialState,
	reducers: {
		clearFinancialChangeTypeCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[financialChangeTypeCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[financialChangeTypeCreateApi.fulfilled.type]: (state, action: PayloadAction<TFinancialChangeType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[financialChangeTypeCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useFinancialChangeTypeCreateSelector = () => useAppSelector(state => state[financialChangeTypeCreateSlice.name]);

export const { clearFinancialChangeTypeCreate } = financialChangeTypeCreateSlice.actions;
