import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { SystemSettingsSortCol, TSystemSettingList, TSystemSettingListResponse, TSystemSettingQuery, TSystemSettingsSortCol, adaptSystemSettingFromListResponse, adaptSystemSettingsQueryToRequest } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS } from '../../backend-paths';


export const systemSettingsGetApi = createAsyncThunk(
	BACKEND_ADMIN_SYSTEM_SETTINGS,
	(payload: TSystemSettingQuery | undefined, thunkApi) => {
		const params = adaptSystemSettingsQueryToRequest(payload);

		return HttpService.get<TSystemSettingListResponse>(BACKEND_ADMIN_SYSTEM_SETTINGS, { params }).then(result => {
			return adaptSystemSettingFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TSystemSettingList;
	query: TSystemSettingQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: SystemSettingsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const systemSettingsSlice = createSlice({
	name: 'systemSettingsSlice',
	initialState,
	reducers: {
		setSystemSettingsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setSystemSettingsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setSystemSettingsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setSystemSettingsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setSystemSettingsQuerySortCol(state, action: PayloadAction<TSystemSettingsSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearSystemSettings(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[systemSettingsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingsGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[systemSettingsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingsSelector = () => useAppSelector(state => state[systemSettingsSlice.name]);

export const { clearSystemSettings, setSystemSettingsQueryLimit, setSystemSettingsQueryOffset, setSystemSettingsQuerySort, setSystemSettingsQuerySortCol, setSystemSettingsQuerySearch } = systemSettingsSlice.actions;
