import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingReportsPhotoNotAttachingPenalty, TSystemSettingReportsPhotoNotAttachingPenaltyResponse, adaptSystemSettingReportsPhotoNotAttachingPenaltyFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY } from '../../backend-paths';


export const systemSettingReportsPhotoNotAttachingPenaltyReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY;

		return HttpService.get<TSystemSettingReportsPhotoNotAttachingPenaltyResponse>(path).then(result => {
			return adaptSystemSettingReportsPhotoNotAttachingPenaltyFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingReportsPhotoNotAttachingPenalty> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingReportsPhotoNotAttachingPenaltyReadSlice = createSlice({
	name: 'systemSettingReportsPhotoNotAttachingPenaltyReadSlice',
	initialState,
	reducers: {
		setSystemSettingReportsPhotoNotAttachingPenaltyRead(state, action: PayloadAction<TSystemSettingReportsPhotoNotAttachingPenalty>) {
			state.item = action.payload;
		},

		clearSystemSettingReportsPhotoNotAttachingPenaltyRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingReportsPhotoNotAttachingPenaltyReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingReportsPhotoNotAttachingPenaltyReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingReportsPhotoNotAttachingPenalty>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingReportsPhotoNotAttachingPenaltyReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingReportsPhotoNotAttachingPenaltyReadSelector = () => useAppSelector(state => state[systemSettingReportsPhotoNotAttachingPenaltyReadSlice.name]);

export const { clearSystemSettingReportsPhotoNotAttachingPenaltyRead, setSystemSettingReportsPhotoNotAttachingPenaltyRead } = systemSettingReportsPhotoNotAttachingPenaltyReadSlice.actions;
