import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentOrderStatus, TEquipmentOrderStatusRequest, TEquipmentOrderStatusResponse, adaptEquipmentOrderStatusFromResponse, adaptEquipmentOrderStatusToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES } from '../../backend-paths';


export const equipmentOrderStatusCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES}`,
	(payload: TEquipmentOrderStatusRequest, thunkApi) => {
		const dataRequest = adaptEquipmentOrderStatusToRequest(payload);

		return HttpService.post<TEquipmentOrderStatusResponse>(BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES, dataRequest).then(result => {
			return adaptEquipmentOrderStatusFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentOrderStatus> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentOrderStatusCreateSlice = createSlice({
	name: 'equipmentOrderStatusCreateSlice',
	initialState,
	reducers: {
		clearEquipmentOrderStatusCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentOrderStatusCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[equipmentOrderStatusCreateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentOrderStatus>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentOrderStatusCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useEquipmentOrderStatusCreateSelector = () => useAppSelector(state => state[equipmentOrderStatusCreateSlice.name]);

export const { clearEquipmentOrderStatusCreate } = equipmentOrderStatusCreateSlice.actions;
