export const PERMISSIONS = {
	ACCOUNT: 'ACCOUNT',

	ACCOUNT_DATA: 'ACCOUNT_DATA',
	ACCOUNT_PASSPORT: 'ACCOUNT_PASSPORT',
	ACCOUNT_INTERNATIONAL_PASSPORT: 'ACCOUNT_INTERNATIONAL_PASSPORT',
	ACCOUNT_WORK_DATA: 'ACCOUNT_WORK_DATA',
	ACCOUNT_TAXATION_DATA: 'ACCOUNT_TAXATION_DATA',
	ACCOUNT_TAXATION_RECEIPTS: 'ACCOUNT_TAXATION_RECEIPTS',
	ACCOUNT_REQUISITES: 'ACCOUNT_REQUISITES',
	ACCOUNT_REPORTS: 'ACCOUNT_REPORTS',
	ACCOUNT_SHIFTS_EXPENSES: 'ACCOUNT_SHIFTS_EXPENSES',
	ACCOUNT_TYPE_CHANGES: 'ACCOUNT_TYPE_CHANGES',
	ACCOUNT_CATEGORY_CHANGES: 'ACCOUNT_CATEGORY_CHANGES',
	ACCOUNT_POSITION_CHANGES: 'ACCOUNT_POSITION_CHANGES',
	ACCOUNT_DEPARTMENT_CHANGES: 'ACCOUNT_DEPARTMENT_CHANGES',
	ACCOUNT_SALARY_CHANGES: 'ACCOUNT_SALARY_CHANGES',
	ACCOUNT_BOOKKEEPING: 'ACCOUNT_BOOKKEEPING',
	ACCOUNT_ARTICLES: 'ACCOUNT_ARTICLES',
	ACCOUNT_EXPENSES: 'ACCOUNT_EXPENSES',
	ACCOUNT_OVERWORKS: 'ACCOUNT_OVERWORKS',

	CREATE_USERS: 'CREATE_USERS',
	RETRIEVE_USERS: 'RETRIEVE_USERS',
	UPDATE_USERS: 'UPDATE_USERS',
	DELETE_USERS: 'DELETE_USERS',

	CREATE_USERS_TYPES: 'CREATE_USERS_TYPES',
	RETRIEVE_USERS_TYPES: 'RETRIEVE_USERS_TYPES',
	UPDATE_USERS_TYPES: 'UPDATE_USERS_TYPES',
	DELETE_USERS_TYPES: 'DELETE_USERS_TYPES',

	CREATE_USERS_POSITIONS: 'CREATE_USERS_POSITIONS',
	RETRIEVE_USERS_POSITIONS: 'RETRIEVE_USERS_POSITIONS',
	UPDATE_USERS_POSITIONS: 'UPDATE_USERS_POSITIONS',
	DELETE_USERS_POSITIONS: 'DELETE_USERS_POSITIONS',

	CREATE_USERS_POSITIONS_CHANGES: 'CREATE_USERS_POSITIONS_CHANGES',
	RETRIEVE_USERS_POSITIONS_CHANGES: 'RETRIEVE_USERS_POSITIONS_CHANGES',
	UPDATE_USERS_POSITIONS_CHANGES: 'UPDATE_USERS_POSITIONS_CHANGES',
	DELETE_USERS_POSITIONS_CHANGES: 'DELETE_USERS_POSITIONS_CHANGES',

	CREATE_USERS_CATEGORIES: 'CREATE_USERS_CATEGORIES',
	RETRIEVE_USERS_CATEGORIES: 'RETRIEVE_USERS_CATEGORIES',
	UPDATE_USERS_CATEGORIES: 'UPDATE_USERS_CATEGORIES',
	DELETE_USERS_CATEGORIES: 'DELETE_USERS_CATEGORIES',

	CREATE_USERS_CATEGORIES_CHANGES: 'CREATE_USERS_CATEGORIES_CHANGES',
	RETRIEVE_USERS_CATEGORIES_CHANGES: 'RETRIEVE_USERS_CATEGORIES_CHANGES',
	UPDATE_USERS_CATEGORIES_CHANGES: 'UPDATE_USERS_CATEGORIES_CHANGES',
	DELETE_USERS_CATEGORIES_CHANGES: 'DELETE_USERS_CATEGORIES_CHANGES',

	CREATE_USERS_DEPARTMENTS: 'CREATE_USERS_DEPARTMENTS',
	RETRIEVE_USERS_DEPARTMENTS: 'RETRIEVE_USERS_DEPARTMENTS',
	UPDATE_USERS_DEPARTMENTS: 'UPDATE_USERS_DEPARTMENTS',
	DELETE_USERS_DEPARTMENTS: 'DELETE_USERS_DEPARTMENTS',

	CREATE_USERS_DEPARTMENTS_CHANGES: 'CREATE_USERS_DEPARTMENTS_CHANGES',
	RETRIEVE_USERS_DEPARTMENTS_CHANGES: 'RETRIEVE_USERS_DEPARTMENTS_CHANGES',
	UPDATE_USERS_DEPARTMENTS_CHANGES: 'UPDATE_USERS_DEPARTMENTS_CHANGES',
	DELETE_USERS_DEPARTMENTS_CHANGES: 'DELETE_USERS_DEPARTMENTS_CHANGES',

	CREATE_USERS_TAXATIONS: 'CREATE_USERS_TAXATIONS',
	RETRIEVE_USERS_TAXATIONS: 'RETRIEVE_USERS_TAXATIONS',
	UPDATE_USERS_TAXATIONS: 'UPDATE_USERS_TAXATIONS',
	DELETE_USERS_TAXATIONS: 'DELETE_USERS_TAXATIONS',

	CREATE_USERS_TAXATIONS_RECEIPTS: 'CREATE_USERS_TAXATIONS_RECEIPTS',
	RETRIEVE_USERS_TAXATIONS_RECEIPTS: 'RETRIEVE_USERS_TAXATIONS_RECEIPTS',
	UPDATE_USERS_TAXATIONS_RECEIPTS: 'UPDATE_USERS_TAXATIONS_RECEIPTS',
	DELETE_USERS_TAXATIONS_RECEIPTS: 'DELETE_USERS_TAXATIONS_RECEIPTS',

	CREATE_USERS_SALARIES: 'CREATE_USERS_SALARIES',
	RETRIEVE_USERS_SALARIES: 'RETRIEVE_USERS_SALARIES',
	UPDATE_USERS_SALARIES: 'UPDATE_USERS_SALARIES',
	DELETE_USERS_SALARIES: 'DELETE_USERS_SALARIES',

	RETRIEVE_USERS_TYPES_HISTORY: 'RETRIEVE_USERS_TYPES_HISTORY',
	UPDATE_USERS_TYPES_HISTORY: 'UPDATE_USERS_TYPES_HISTORY',

	RETRIEVE_USERS_SHIFTS: 'RETRIEVE_USERS_SHIFTS',
	UPDATE_USERS_SHIFTS: 'UPDATE_USERS_SHIFTS',

	RETRIEVE_SCHEDULE: 'RETRIEVE_SCHEDULE',
	UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
	FILTER_SCHEDULE: 'FILTER_SCHEDULE',

	CREATE_SCHEDULE_EVENTS: 'CREATE_SCHEDULE_EVENTS',
	RETRIEVE_SCHEDULE_EVENTS: 'RETRIEVE_SCHEDULE_EVENTS',
	UPDATE_SCHEDULE_EVENTS: 'UPDATE_SCHEDULE_EVENTS',
	DELETE_SCHEDULE_EVENTS: 'DELETE_SCHEDULE_EVENTS',

	CREATE_SCHEDULE_EVENTS_TYPES: 'CREATE_SCHEDULE_EVENTS_TYPES',
	RETRIEVE_SCHEDULE_EVENTS_TYPES: 'RETRIEVE_SCHEDULE_EVENTS_TYPES',
	UPDATE_SCHEDULE_EVENTS_TYPES: 'UPDATE_SCHEDULE_EVENTS_TYPES',
	DELETE_SCHEDULE_EVENTS_TYPES: 'DELETE_SCHEDULE_EVENTS_TYPES',

	RETRIEVE_USERS_BOOKKEEPING: 'RETRIEVE_USERS_BOOKKEEPING',

	CREATE_PAYMENTS_TYPES: 'CREATE_PAYMENTS_TYPES',
	RETRIEVE_PAYMENTS_TYPES: 'RETRIEVE_PAYMENTS_TYPES',
	UPDATE_PAYMENTS_TYPES: 'UPDATE_PAYMENTS_TYPES',
	DELETE_PAYMENTS_TYPES: 'DELETE_PAYMENTS_TYPES',

	CREATE_EXPENSES_TYPES: 'CREATE_EXPENSES_TYPES',
	RETRIEVE_EXPENSES_TYPES: 'RETRIEVE_EXPENSES_TYPES',
	UPDATE_EXPENSES_TYPES: 'UPDATE_EXPENSES_TYPES',
	DELETE_EXPENSES_TYPES: 'DELETE_EXPENSES_TYPES',

	CREATE_USERS_EXPENSES: 'CREATE_USERS_EXPENSES',
	RETRIEVE_USERS_EXPENSES: 'RETRIEVE_USERS_EXPENSES',
	UPDATE_USERS_EXPENSES: 'UPDATE_USERS_EXPENSES',
	DELETE_USERS_EXPENSES: 'DELETE_USERS_EXPENSES',

	CREATE_USERS_OVERWORKS: 'CREATE_USERS_OVERWORKS',
	RETRIEVE_USERS_OVERWORKS: 'RETRIEVE_USERS_OVERWORKS',
	UPDATE_USERS_OVERWORKS: 'UPDATE_USERS_OVERWORKS',
	DELETE_USERS_OVERWORKS: 'DELETE_USERS_OVERWORKS',

	CREATE_FINANCIAL_CHANGES_TYPES: 'CREATE_FINANCIAL_CHANGES_TYPES',
	RETRIEVE_FINANCIAL_CHANGES_TYPES: 'RETRIEVE_FINANCIAL_CHANGES_TYPES',
	UPDATE_FINANCIAL_CHANGES_TYPES: 'UPDATE_FINANCIAL_CHANGES_TYPES',
	DELETE_FINANCIAL_CHANGES_TYPES: 'DELETE_FINANCIAL_CHANGES_TYPES',

	CREATE_WORK_AREAS: 'CREATE_WORK_AREAS',
	RETRIEVE_WORK_AREAS: 'RETRIEVE_WORK_AREAS',
	UPDATE_WORK_AREAS: 'UPDATE_WORK_AREAS',
	DELETE_WORK_AREAS: 'DELETE_WORK_AREAS',

	CREATE_USERS_FINANCIAL_CHANGES: 'CREATE_USERS_FINANCIAL_CHANGES',
	RETRIEVE_USERS_FINANCIAL_CHANGES: 'RETRIEVE_USERS_FINANCIAL_CHANGES',
	UPDATE_USERS_FINANCIAL_CHANGES: 'UPDATE_USERS_FINANCIAL_CHANGES',
	DELETE_USERS_FINANCIAL_CHANGES: 'DELETE_USERS_FINANCIAL_CHANGES',

	CREATE_PROJECTS: 'CREATE_PROJECTS',
	RETRIEVE_PROJECTS: 'RETRIEVE_PROJECTS',
	UPDATE_PROJECTS: 'UPDATE_PROJECTS',
	DELETE_PROJECTS: 'DELETE_PROJECTS',

	CREATE_PROJECTS_ORDERS: 'CREATE_PROJECTS_ORDERS',
	RETRIEVE_PROJECTS_ORDERS: 'RETRIEVE_PROJECTS_ORDERS',
	UPDATE_PROJECTS_ORDERS: 'UPDATE_PROJECTS_ORDERS',
	DELETE_PROJECTS_ORDERS: 'DELETE_PROJECTS_ORDERS',

	CREATE_PROJECTS_ORDERS_TAGS_TYPES: 'CREATE_PROJECTS_ORDERS_TAGS_TYPES',
	RETRIEVE_PROJECTS_ORDERS_TAGS_TYPES: 'RETRIEVE_PROJECTS_ORDERS_TAGS_TYPES',
	UPDATE_PROJECTS_ORDERS_TAGS_TYPES: 'UPDATE_PROJECTS_ORDERS_TAGS_TYPES',
	DELETE_PROJECTS_ORDERS_TAGS_TYPES: 'DELETE_PROJECTS_ORDERS_TAGS_TYPES',

	CREATE_PROJECTS_ORDERS_GROUPS: 'CREATE_PROJECTS_ORDERS_GROUPS',
	RETRIEVE_PROJECTS_ORDERS_GROUPS: 'RETRIEVE_PROJECTS_ORDERS_GROUPS',
	UPDATE_PROJECTS_ORDERS_GROUPS: 'UPDATE_PROJECTS_ORDERS_GROUPS',
	DELETE_PROJECTS_ORDERS_GROUPS: 'DELETE_PROJECTS_ORDERS_GROUPS',

	RETRIEVE_EQUIPMENTS_GROUPS_HISTORY: 'RETRIEVE_EQUIPMENTS_GROUPS_HISTORY',

	CREATE_PROJECTS_ORDERS_GROUPS_SHIFTS: 'CREATE_PROJECTS_ORDERS_GROUPS_SHIFTS',
	RETRIEVE_PROJECTS_ORDERS_GROUPS_SHIFTS: 'RETRIEVE_PROJECTS_ORDERS_GROUPS_SHIFTS',
	UPDATE_PROJECTS_ORDERS_GROUPS_SHIFTS: 'UPDATE_PROJECTS_ORDERS_GROUPS_SHIFTS',
	DELETE_PROJECTS_ORDERS_GROUPS_SHIFTS: 'DELETE_PROJECTS_ORDERS_GROUPS_SHIFTS',

	CREATE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES: 'CREATE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES',
	RETRIEVE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES: 'RETRIEVE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES',
	UPDATE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES: 'UPDATE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES',
	DELETE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES: 'DELETE_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES',

	CREATE_GROUPS_SHIFTS_EXPENSES_TYPES: 'CREATE_GROUPS_SHIFTS_EXPENSES_TYPES',
	RETRIEVE_GROUPS_SHIFTS_EXPENSES_TYPES: 'RETRIEVE_GROUPS_SHIFTS_EXPENSES_TYPES',
	UPDATE_GROUPS_SHIFTS_EXPENSES_TYPES: 'UPDATE_GROUPS_SHIFTS_EXPENSES_TYPES',
	DELETE_GROUPS_SHIFTS_EXPENSES_TYPES: 'DELETE_GROUPS_SHIFTS_EXPENSES_TYPES',

	CREATE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES: 'CREATE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES',
	RETRIEVE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES: 'RETRIEVE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES',
	UPDATE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES: 'UPDATE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES',
	DELETE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES: 'DELETE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES',

	CREATE_USERS_EXPENSES_TYPES: 'CREATE_USERS_EXPENSES_TYPES',
	RETRIEVE_USERS_EXPENSES_TYPES: 'RETRIEVE_USERS_EXPENSES_TYPES',
	UPDATE_USERS_EXPENSES_TYPES: 'UPDATE_USERS_EXPENSES_TYPES',
	DELETE_USERS_EXPENSES_TYPES: 'DELETE_USERS_EXPENSES_TYPES',

	CREATE_PROJECTS_CLIENTS: 'CREATE_PROJECTS_CLIENTS',
	RETRIEVE_PROJECTS_CLIENTS: 'RETRIEVE_PROJECTS_CLIENTS',
	UPDATE_PROJECTS_CLIENTS: 'UPDATE_PROJECTS_CLIENTS',
	DELETE_PROJECTS_CLIENTS: 'DELETE_PROJECTS_CLIENTS',

	CREATE_PROJECTS_CLIENTS_CONTACTS: 'CREATE_PROJECTS_CLIENTS_CONTACTS',
	RETRIEVE_PROJECTS_CLIENTS_CONTACTS: 'RETRIEVE_PROJECTS_CLIENTS_CONTACTS',
	UPDATE_PROJECTS_CLIENTS_CONTACTS: 'UPDATE_PROJECTS_CLIENTS_CONTACTS',
	DELETE_PROJECTS_CLIENTS_CONTACTS: 'DELETE_PROJECTS_CLIENTS_CONTACTS',

	CREATE_PROJECTS_STATUSES: 'CREATE_PROJECTS_STATUSES',
	RETRIEVE_PROJECTS_STATUSES: 'RETRIEVE_PROJECTS_STATUSES',
	UPDATE_PROJECTS_STATUSES: 'UPDATE_PROJECTS_STATUSES',
	DELETE_PROJECTS_STATUSES: 'DELETE_PROJECTS_STATUSES',

	CREATE_PRODUCTION_CALENDARS: 'CREATE_PRODUCTION_CALENDARS',
	RETRIEVE_PRODUCTION_CALENDARS: 'RETRIEVE_PRODUCTION_CALENDARS',
	UPDATE_PRODUCTION_CALENDARS: 'UPDATE_PRODUCTION_CALENDARS',
	DELETE_PRODUCTION_CALENDARS: 'DELETE_PRODUCTION_CALENDARS',

	RETRIEVE_PRODUCTION_CALENDARS_DATES_OFF: 'RETRIEVE_PRODUCTION_CALENDARS_DATES_OFF',
	UPDATE_PRODUCTION_CALENDARS_DATES_OFF: 'UPDATE_PRODUCTION_CALENDARS_DATES_OFF',
	DELETE_PRODUCTION_CALENDARS_DATES_OFF: 'DELETE_PRODUCTION_CALENDARS_DATES_OFF',

	RETRIEVE_BOOKKEEPING: 'RETRIEVE_BOOKKEEPING',

	CREATE_ARTICLES: 'CREATE_ARTICLES',
	RETRIEVE_ARTICLES: 'RETRIEVE_ARTICLES',
	UPDATE_ARTICLES: 'UPDATE_ARTICLES',
	DELETE_ARTICLES: 'DELETE_ARTICLES',

	CREATE_EQUIPMENTS_STORAGES: 'CREATE_EQUIPMENTS_STORAGES',
	RETRIEVE_EQUIPMENTS_STORAGES: 'RETRIEVE_EQUIPMENTS_STORAGES',
	UPDATE_EQUIPMENTS_STORAGES: 'UPDATE_EQUIPMENTS_STORAGES',
	DELETE_EQUIPMENTS_STORAGES: 'DELETE_EQUIPMENTS_STORAGES',

	CREATE_EQUIPMENTS_CATEGORIES: 'CREATE_EQUIPMENTS_CATEGORIES',
	RETRIEVE_EQUIPMENTS_CATEGORIES: 'RETRIEVE_EQUIPMENTS_CATEGORIES',
	UPDATE_EQUIPMENTS_CATEGORIES: 'UPDATE_EQUIPMENTS_CATEGORIES',
	DELETE_EQUIPMENTS_CATEGORIES: 'DELETE_EQUIPMENTS_CATEGORIES',

	CREATE_EQUIPMENTS: 'CREATE_EQUIPMENTS',
	RETRIEVE_EQUIPMENTS: 'RETRIEVE_EQUIPMENTS',
	UPDATE_EQUIPMENTS: 'UPDATE_EQUIPMENTS',
	DELETE_EQUIPMENTS: 'DELETE_EQUIPMENTS',

	UPDATE_EQUIPMENTS_FILES: 'UPDATE_EQUIPMENTS_FILES',

	RETRIEVE_EQUIPMENTS_HISTORY: 'RETRIEVE_EQUIPMENTS_HISTORY',

	CREATE_EQUIPMENTS_GROUPS: 'CREATE_EQUIPMENTS_GROUPS',
	RETRIEVE_EQUIPMENTS_GROUPS: 'RETRIEVE_EQUIPMENTS_GROUPS',
	UPDATE_EQUIPMENTS_GROUPS: 'UPDATE_EQUIPMENTS_GROUPS',
	DELETE_EQUIPMENTS_GROUPS: 'DELETE_EQUIPMENTS_GROUPS',

	CREATE_EQUIPMENTS_ORDERS_STATUSES: 'CREATE_EQUIPMENTS_ORDERS_STATUSES',
	RETRIEVE_EQUIPMENTS_ORDERS_STATUSES: 'RETRIEVE_EQUIPMENTS_ORDERS_STATUSES',
	UPDATE_EQUIPMENTS_ORDERS_STATUSES: 'UPDATE_EQUIPMENTS_ORDERS_STATUSES',
	DELETE_EQUIPMENTS_ORDERS_STATUSES: 'DELETE_EQUIPMENTS_ORDERS_STATUSES',

	CREATE_EQUIPMENTS_ORDERS: 'CREATE_EQUIPMENTS_ORDERS',
	RETRIEVE_EQUIPMENTS_ORDERS: 'RETRIEVE_EQUIPMENTS_ORDERS',
	UPDATE_EQUIPMENTS_ORDERS: 'UPDATE_EQUIPMENTS_ORDERS',
	DELETE_EQUIPMENTS_ORDERS: 'DELETE_EQUIPMENTS_ORDERS',

	RETRIEVE_EQUIPMENTS_ORDERS_HISTORY: 'RETRIEVE_EQUIPMENTS_ORDERS_HISTORY',
	RETRIEVE_EQUIPMENTS_USERS: 'RETRIEVE_EQUIPMENTS_USERS',

	RETRIEVE_SOURCE_EQUIPMENTS_CATEGORIES: 'RETRIEVE_SOURCE_EQUIPMENTS_CATEGORIES',
	RETRIEVE_SOURCE_EQUIPMENTS_STORAGES: 'RETRIEVE_SOURCE_EQUIPMENTS_STORAGES',
	RETRIEVE_SOURCE_EQUIPMENTS: 'RETRIEVE_SOURCE_EQUIPMENTS',
	RETRIEVE_SOURCE_EQUIPMENTS_GROUPS: 'RETRIEVE_SOURCE_EQUIPMENTS_GROUPS',
	RETRIEVE_SOURCE_EQUIPMENTS_ORDERS_STATUSES: 'RETRIEVE_SOURCE_EQUIPMENTS_ORDERS_STATUSES',

	RETRIEVE_SYSTEM_SETTINGS_TYPES: 'RETRIEVE_SYSTEM_SETTINGS_TYPES',
	UPDATE_SYSTEM_SETTINGS_TYPES: 'UPDATE_SYSTEM_SETTINGS_TYPES',

	RETRIEVE_SYSTEM_SETTINGS: 'RETRIEVE_SYSTEM_SETTINGS',
	UPDATE_SYSTEM_SETTINGS: 'UPDATE_SYSTEM_SETTINGS',

	RETRIEVE_SYSTEM_SETTINGS_USERS: 'RETRIEVE_SYSTEM_SETTINGS_USERS',
	UPDATE_SYSTEM_SETTINGS_USERS: 'UPDATE_SYSTEM_SETTINGS_USERS',

	RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS: 'RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS',
	UPDATE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS: 'UPDATE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS',

	RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS: 'RETRIEVE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS',
	UPDATE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS: 'UPDATE_SYSTEM_SETTINGS_PROJECTS_ORDERS_GROUPS_SHIFTS_REPORTS',

	RETRIEVE_SYSTEM_SETTINGS_BOOKKEEPING: 'RETRIEVE_SYSTEM_SETTINGS_BOOKKEEPING',
	UPDATE_SYSTEM_SETTINGS_BOOKKEEPING: 'UPDATE_SYSTEM_SETTINGS_BOOKKEEPING',

	RETRIEVE_SYSTEM_SETTINGS_CALENDAR_EVENTS: 'RETRIEVE_SYSTEM_SETTINGS_CALENDAR_EVENTS',
	UPDATE_SYSTEM_SETTINGS_CALENDAR_EVENTS: 'UPDATE_SYSTEM_SETTINGS_CALENDAR_EVENTS',

	RETRIEVE_SYSTEM_SETTINGS_AMOCRM: 'RETRIEVE_SYSTEM_SETTINGS_AMOCRM',
	UPDATE_SYSTEM_SETTINGS_AMOCRM: 'UPDATE_SYSTEM_SETTINGS_AMOCRM',
};
