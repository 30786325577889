import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountExpense, TAccountExpensePayloadCreate, TAccountExpenseResponse, adaptAccountExpenseFromResponse, adaptAccountExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPENSES } from '../../backend-paths';


export const accountExpenseCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_EXPENSES}`,
	(payload: TAccountExpensePayloadCreate, thunkApi) => {
		const { data, files } = payload;

		const dataRequest = adaptAccountExpenseToRequest(data);

		return HttpService.post<TAccountExpenseResponse>(BACKEND_ACCOUNT_EXPENSES, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ACCOUNT_EXPENSES}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TAccountExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptAccountExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExpenseCreateSlice = createSlice({
	name: 'accountExpenseCreateSlice',
	initialState,
	reducers: {
		clearAccountExpenseCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExpenseCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountExpenseCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExpenseCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountExpenseCreateSelector = () => useAppSelector(state => state[accountExpenseCreateSlice.name]);

export const { clearAccountExpenseCreate } = accountExpenseCreateSlice.actions;
