import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const SystemSettingTypesSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TSystemSettingTypesSortCol = TTableSortCols<typeof SystemSettingTypesSortCol>;

export type TSystemSettingTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TSystemSettingTypesSortCol;
};

export type TSystemSettingTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptSystemSettingTypesQueryToRequest(data?: TSystemSettingTypeQuery): TSystemSettingTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TSystemSettingType = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TSystemSettingTypeResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TSystemSettingTypeListResponse = TListResponse<TSystemSettingTypeResponse>;

export type TSystemSettingTypeList = TListResponse<TSystemSettingType>;

export type TSystemSettingTypeBodyRequest = {
	name: string;
	description?: string;
};

export type TSystemSettingTypeRequest = {
	name: string;
	description?: string;
};

export type TSystemSettingTypeUpdate = {
	id: number;
	data: TSystemSettingTypeBodyRequest;
};

export function adaptSystemSettingTypeFromListResponse(data: TSystemSettingTypeListResponse): TSystemSettingTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptSystemSettingTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptSystemSettingTypeFromResponse(data: TSystemSettingTypeResponse): TSystemSettingType {
	const {
		id,
		name,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingTypeToRequest(data: TSystemSettingTypeBodyRequest): TSystemSettingTypeRequest {
	const {
		name,
		description = undefined,
	} = data;

	return {
		name,
		description,
	};
}
