import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountSalariesPage = lazy(() => import('../views/list/AccountSalariesPage'));

export function AccountSalariesPageLazy() {
	return (
		<PageSuspense>
			<AccountSalariesPage />
		</PageSuspense>
	);
}
