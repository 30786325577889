import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserTypeHistoryPayload, TUserResponse, adaptUserFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userTypeHistoryUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/type`,
	(payload: TUserTypeHistoryPayload, thunkApi) => {
		const { nickname, data } = payload;
		const { typeId } = data;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/type`;
		const dataRequest = { type_id: typeId };

		return HttpService.put<TUserResponse>(path, dataRequest).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeHistoryUpdateSlice = createSlice({
	name: 'userTypeHistoryUpdateSlice',
	initialState,
	reducers: {
		clearUserTypeHistoryUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeHistoryUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeHistoryUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTypeHistoryUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeHistoryUpdateSelector = () => useAppSelector(state => state[userTypeHistoryUpdateSlice.name]);

export const { clearUserTypeHistoryUpdate } = userTypeHistoryUpdateSlice.actions;
