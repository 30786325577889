// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsideItemOptions_box__1zsH7 {\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/components/nav/aside-item/components/options/AsideItemOptions.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;AACD","sourcesContent":[".box {\n\tbox-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AsideItemOptions_box__1zsH7"
};
export default ___CSS_LOADER_EXPORT___;
