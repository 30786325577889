import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EMAIL } from '../../backend-paths';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountEmail, TAccountEmailResponse, adaptAccountEmailFromResponse } from '../../types';


export const accountEmailReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_EMAIL}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountEmailResponse>(BACKEND_ACCOUNT_EMAIL).then(result => {
			return adaptAccountEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = TSliceInitialState<TAccountEmail> & {
	timeExpired: number;
};

const initialState: TInitialState = {
	item: undefined,
	timeExpired: 0,
	isLoading: false,
	error: undefined,
};

export const accountEmailReadSlice = createSlice({
	name: 'accountEmailReadSlice',
	initialState,
	reducers: {
		setAccountEmailRead(state, action: PayloadAction<TAccountEmail>) {
			state.item = action.payload;
			state.timeExpired = action.payload.timeExpired;
		},

		setAccountEmailTimeExpiredRead(state, action: PayloadAction<number>) {
			state.timeExpired = action.payload;
		},

		clearAccountEmailRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEmailReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEmailReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.timeExpired = action.payload.timeExpired;
		},

		[accountEmailReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountEmailReadSelector = () => useAppSelector(state => state[accountEmailReadSlice.name]);

export const { clearAccountEmailRead, setAccountEmailRead, setAccountEmailTimeExpiredRead } = accountEmailReadSlice.actions;
