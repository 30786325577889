import { useEffect, useRef, useState, MouseEvent } from 'react';

import { FormField, Icon } from 'src/components';
import { TInputField, libDeclinationByNum } from 'src/toolkit';

import { FormSelectRangeBoxFieldList } from './FormSelectRangeBoxFieldList';
import { TFormSelectRangeBoxFieldListOption } from './FormSelectRangeBoxFieldListItem';

import styles from './FormSelectRangeBoxField.module.scss';


type TProps = TInputField & {
	legend?: string;
	title?: string;
	placeholder?: string;

	isLoading?: boolean;
	disabled?: boolean;
	required?: boolean;

	items: TFormSelectRangeBoxFieldListOption[];
	setValues: (value: string[]) => void;
	values: string[];

	className?: string;

	width100?: boolean;
	marginRight?: boolean;
	marginLeft?: boolean;
};

export function getLabelText(value: number) {
	const verb = libDeclinationByNum(value, ['Выбран', 'Выбрано', 'Выбрано']);

	return `${verb} ${value}`;
}

export function FormSelectRangeBoxField(props: TProps) {
	const {
		errors,
		value,
		isLoading,
		required,
		items: itemsOuter = [],

		className: classNameOuter = '',

		legend,
		title,
		placeholder: placeholderOuter,
		disabled,

		values,
		setValues,

		width100 = false,
		marginRight = false,
		marginLeft = false,
	} = props;

	const [placeholder, setPlaceHolder] = useState(placeholderOuter);
	const [isActive, setIsActive] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [options, setOptions] = useState(itemsOuter);
	const [isBeforeFocus, setIsBeforeFocus] = useState(false);

	const inputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const items = itemsOuter.map(item => item);

		setOptions(items);

		const filtered = items.filter(item => values.includes(item.value));
		const title = getLabelText(filtered.length);

		setPlaceHolder(title);
	}, []);

	useEffect(() => {
		const filtered = values.filter(item => !!item);

		const title = getLabelText(filtered.length);

		setPlaceHolder(title);
	}, [values]);

	const onClick = (selectedValue: string) => {
		const item = options.find(item => item.value === selectedValue);

		if (!item) {
			return;
		}

		const { value } = item;

		const filtered = values.filter(item => item !== value);

		if (filtered.length === values.length) {
			filtered.push(value);
		}

		setValues(filtered);

		const title = getLabelText(filtered.length);

		setPlaceHolder(title);
	};

	const onMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		if (disabled) {
			return;
		}

		if (e.button !== 0) {
			return setIsActive(false);
		}

		setIsBeforeFocus(document.activeElement !== inputRef.current);
	};

	const toggle = () => {
		if (!disabled && !isBeforeFocus) {
			setIsActive(!isActive);
		}
	};

	const onBlur = () => {
		if (!isOver && document.activeElement !== inputRef.current) {
			setIsActive(false);
		}
	};

	const onFocus = () => {
		if (!disabled) {
			setIsActive(true);
		}
	};

	const classNames = [styles.box];
	const labelClassNames = [styles.label];

	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	if (isActive) {
		classNames.push(styles.zIndex);
		labelClassNames.push(styles.focus);
	}

	if (width100) {
		classNames.push(styles.width100);
	}

	if (marginRight) {
		classNames.push(styles.marginRight);
	}

	if (marginLeft) {
		classNames.push(styles.marginLeft);
	}

	if (isLoading || disabled) {
		labelClassNames.push(styles.disabled);
	}

	const icon = isActive ? 'icon-chevron-top' : 'icon-chevron-bottom';

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			className={ classNames.join(' ') }
		>
			<div
				ref={ inputRef }
				title={ title }
				onClick={ toggle }
				onMouseDown={ onMouseDown }
				onMouseOver={ e => setIsOver(true) }
				onMouseOut={ e => setIsOver(false) }
				onFocus={ onFocus }
				onBlur={ onBlur }
				className={ labelClassNames.join(' ') }
				tabIndex={ -1 }
			>
				{ placeholder }

				<Icon className={ styles.icon } icon={ icon } />
			</div>

			{ isActive &&
				<FormSelectRangeBoxFieldList
					items={ options }
					value={ value }
					values={ values }
					setValue={ onClick }
					setIsActive={ setIsActive }
					onMouseOver={ e => setIsOver(true) }
					onMouseOut={ e => setIsOver(false) }
					onFocus={ onFocus }
					onBlur={ onBlur }
				/>
			}
		</FormField>
	);
}
