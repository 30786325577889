import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserTaxation, TUserTaxationRequest, TUserTaxationResponse, adaptUserTaxationFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_TAXATIONS } from '../../backend-paths';


export const userTaxationCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS_TAXATIONS}`,
	(payload: TUserTaxationRequest, thunkApi) => {
		return HttpService.post<TUserTaxationResponse>(BACKEND_ADMIN_USERS_TAXATIONS, payload).then(result => {
			return adaptUserTaxationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserTaxation> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userTaxationCreateSlice = createSlice({
	name: 'userTaxationCreateSlice',
	initialState,
	reducers: {
		clearUserTaxationCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTaxationCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userTaxationCreateApi.fulfilled.type]: (state, action: PayloadAction<TUserTaxation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userTaxationCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserTaxationCreateSelector = () => useAppSelector(state => state[userTaxationCreateSlice.name]);

export const { clearUserTaxationCreate } = userTaxationCreateSlice.actions;
