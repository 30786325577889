import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ArticlesSortCol, TArticleList, TArticleListResponse, TArticleQuery, TArticlesSortCol, adaptArticleFromListResponse, toArticlesQueryRequest } from '../../types';
import { BACKEND_ARTICLES } from '../../backend-paths';


export const articlesGetApi = createAsyncThunk(
	BACKEND_ARTICLES,
	(payload: TArticleQuery | undefined, thunkApi) => {
		const params = payload ? toArticlesQueryRequest(payload) : {};

		return HttpService.get<TArticleListResponse>(BACKEND_ARTICLES, { params }).then(result => {
			return adaptArticleFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TArticleList,
	query: TArticleQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.DESC,
		sortCol: ArticlesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const articlesSlice = createSlice({
	name: 'articlesSlice',
	initialState,
	reducers: {
		setArticlesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setArticlesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setArticlesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setArticlesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setArticlesQuerySortCol(state, action: PayloadAction<TArticlesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearArticles(state) {
			return { ...initialState, query: state.query };
		},
	},

	extraReducers: {
		[articlesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[articlesGetApi.fulfilled.type]: (state, action: PayloadAction<TArticleList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[articlesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useArticlesSelector = () => useAppSelector(state => state[articlesSlice.name]);

export const { clearArticles, setArticlesQueryLimit, setArticlesQueryOffset, setArticlesQuerySort, setArticlesQuerySortCol, setArticlesQuerySearch } = articlesSlice.actions;
