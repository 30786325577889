import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccount, TAccountResponse, adaptAccountFromResponse } from '../../types';


export const accountReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountResponse>(BACKEND_ACCOUNT).then(result => {
			return adaptAccountFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data?: TAccount;
	isLoading: boolean;
	error?: HttpError;
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountReadSlice = createSlice({
	name: 'accountReadSlice',
	initialState,
	reducers: {
		setAccountRead(state, action: PayloadAction<TAccount>) {
			state.data = action.payload;
		},

		clearAccountRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccount>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountReadSelector = () => useAppSelector(state => state[accountReadSlice.name]);

export const { clearAccountRead, setAccountRead } = accountReadSlice.actions;
