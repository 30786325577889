import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const WorkAreaUpdatePage = lazy(() => import('../views/update/WorkAreaUpdatePage'));

export function WorkAreaUpdatePageLazy() {
	return (
		<PageSuspense>
			<WorkAreaUpdatePage />
		</PageSuspense>
	);
}
