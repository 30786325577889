import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TUserType, adaptUserTypeFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptUserTypeFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserType> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userDeleteSlice = createSlice({
	name: 'userDeleteSlice',
	initialState,
	reducers: {
		clearUserDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUserType>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserDeleteSelector = () => useAppSelector(state => state[userDeleteSlice.name]);

export const { clearUserDelete } = userDeleteSlice.actions;
