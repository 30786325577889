import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProjectOrderGroupShiftExpense, TProjectOrderGroupShiftExpensePayloadCreate, TProjectOrderGroupShiftExpenseResponse, adaptProjectOrderGroupShiftExpenseFromResponse, adaptProjectOrderGroupShiftExpenseToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS, BACKEND_ACCOUNT_SHIFT_EXPENSE } from '../../backend-paths';


export const projectOrderGroupShiftExpenseCreateApi = createAsyncThunk(
	`POST/${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS}/:shiftId/expenses`,
	(payload: TProjectOrderGroupShiftExpensePayloadCreate, thunkApi) => {
		const { data, shiftId, files } = payload;
		const path = `${BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS}/${shiftId}/expenses`;

		const dataRequest = adaptProjectOrderGroupShiftExpenseToRequest(data);

		return HttpService.post<TProjectOrderGroupShiftExpenseResponse>(path, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ACCOUNT_SHIFT_EXPENSE}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TProjectOrderGroupShiftExpenseResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptProjectOrderGroupShiftExpenseFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShiftExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftExpenseCreateSlice = createSlice({
	name: 'projectOrderGroupShiftExpenseCreateSlice',
	initialState,
	reducers: {
		clearProjectOrderGroupShiftExpenseCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftExpenseCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[projectOrderGroupShiftExpenseCreateApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShiftExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftExpenseCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useProjectOrderGroupShiftExpenseCreateSelector = () => useAppSelector(state => state[projectOrderGroupShiftExpenseCreateSlice.name]);

export const { clearProjectOrderGroupShiftExpenseCreate } = projectOrderGroupShiftExpenseCreateSlice.actions;
