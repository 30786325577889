import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountRequisites, TAccountRequisitesPayload, TAccountRequisitesResponse, adaptAccountRequisitesFromResponse, adaptAccountRequisitesToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_REQUISITES } from '../../backend-paths';


export const accountRequisitesUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_REQUISITES}`,
	(payload: TAccountRequisitesPayload, thunkApi) => {
		const { data } = payload;

		const dataRequest = adaptAccountRequisitesToRequest(data);

		return HttpService.put<TAccountRequisitesResponse>(BACKEND_ACCOUNT_REQUISITES, dataRequest).then(result => {
			return adaptAccountRequisitesFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountRequisites> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountRequisitesUpdateSlice = createSlice({
	name: 'accountRequisitesUpdateSlice',
	initialState,
	reducers: {
		clearAccountRequisitesUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountRequisitesUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountRequisitesUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountRequisites>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountRequisitesUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountRequisitesUpdateSelector = () => useAppSelector(state => state[accountRequisitesUpdateSlice.name]);

export const { clearAccountRequisitesUpdate } = accountRequisitesUpdateSlice.actions;
