import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountOverwork, adaptAccountOverworkFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_OVERWORKS } from '../../backend-paths';


export const accountOverworkDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_OVERWORKS}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_OVERWORKS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountOverworkFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountOverwork> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountOverworkDeleteSlice = createSlice({
	name: 'accountOverworkDeleteSlice',
	initialState,
	reducers: {
		clearAccountOverworkDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountOverworkDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountOverworkDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountOverwork>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountOverworkDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountOverworkDeleteSelector = () => useAppSelector(state => state[accountOverworkDeleteSlice.name]);

export const { clearAccountOverworkDelete } = accountOverworkDeleteSlice.actions;
