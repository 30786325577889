import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const EquipmentsCategoriesHierarchyPage = lazy(() => import('../views/list/EquipmentsCategoriesHierarchyPage'));

export function EquipmentsCategoriesHierarchyPageLazy() {
	return (
		<PageSuspense>
			<EquipmentsCategoriesHierarchyPage />
		</PageSuspense>
	);
}
