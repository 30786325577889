import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { GroupsShiftsReportsTagsTypesSortCol, TGroupShiftReportTagTypeList, TGroupShiftReportTagTypeListResponse, TGroupShiftReportTagTypeQuery, TGroupsShiftsReportsTagsTypesSortCol, adaptGroupShiftReportTagTypeFromListResponse, adaptGroupsShiftsReportsTagsTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES } from '../../backend-paths';


export const groupsShiftsReportsTagsTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES,
	(payload: TGroupShiftReportTagTypeQuery | undefined, thunkApi) => {
		const params = adaptGroupsShiftsReportsTagsTypesQueryToRequest(payload);

		return HttpService.get<TGroupShiftReportTagTypeListResponse>(BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES, { params }).then(result => {
			return adaptGroupShiftReportTagTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TGroupShiftReportTagTypeList,
	query: TGroupShiftReportTagTypeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: GroupsShiftsReportsTagsTypesSortCol.CREATED_AT,
	},
};

export const groupsShiftsReportsTagsTypesSlice = createSlice({
	name: 'groupsShiftsReportsTagsTypesSlice',
	initialState,
	reducers: {
		setGroupsShiftsReportsTagsTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setGroupsShiftsReportsTagsTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setGroupsShiftsReportsTagsTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setGroupsShiftsReportsTagsTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setGroupsShiftsReportsTagsTypesQuerySortCol(state, action: PayloadAction<TGroupsShiftsReportsTagsTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearGroupsShiftsReportsTagsTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[groupsShiftsReportsTagsTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[groupsShiftsReportsTagsTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TGroupShiftReportTagTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[groupsShiftsReportsTagsTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useGroupsShiftsReportsTagsTypesSelector = () => useAppSelector(state => state[groupsShiftsReportsTagsTypesSlice.name]);

export const { clearGroupsShiftsReportsTagsTypes, setGroupsShiftsReportsTagsTypesQueryLimit, setGroupsShiftsReportsTagsTypesQueryOffset, setGroupsShiftsReportsTagsTypesQuerySort, setGroupsShiftsReportsTagsTypesQuerySortCol, setGroupsShiftsReportsTagsTypesQuerySearch } = groupsShiftsReportsTagsTypesSlice.actions;
