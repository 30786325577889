import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const BookkeepingPage = lazy(() => import('../views/list/BookkeepingPage'));

export function BookkeepingPageLazy() {
	return (
		<PageSuspense>
			<BookkeepingPage />
		</PageSuspense>
	);
}
