import { useEffect } from 'react';

import { Preloader } from 'src/components';
import { routes, Router } from 'src/routes';
import { useAccountCredentialsSelector, accountCredentialsApi, useAppDispatch } from 'src/store';


function App() {
	const { credentials, isLoading, error } = useAccountCredentialsSelector();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(accountCredentialsApi());
	}, []);

	if (isLoading && !credentials && !error) {
		return (
			<Preloader noLayout />
		);
	}

	return (
		<Router items={ routes } />
	);
}

export default App;
