import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UserExpenseTypeUpdatePage = lazy(() => import('../views/update/UserExpenseTypeUpdatePage'));

export function UserExpenseTypeUpdatePageLazy() {
	return (
		<PageSuspense>
			<UserExpenseTypeUpdatePage />
		</PageSuspense>
	);
}
