import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const AccountOverworksSortCol = {
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountOverworksSortCol = TTableSortCols<typeof AccountOverworksSortCol>;

export type TAccountOverworksQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountOverworksSortCol;
};

export type TAccountOverworksQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountOverworksQueryPayload = {
	data?: TAccountOverworksQuery;
	// userId?: number;
};

export function adaptAccountOverworksQueryToRequest(data?: TAccountOverworksQuery): TAccountOverworksQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TAccountOverwork = {
	id: number;
  dateStart: string;
  dateEnd: string;
	description?: string;
  value: number;
  time: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountOverworkResponse = {
	id: number;
  date_start: string;
  date_end: string;
	description?: string;
  value: number;
  time: string;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TAccountOverworkListResponse = TListResponse<TAccountOverworkResponse>;

export type TAccountOverworkList = TListResponse<TAccountOverwork>;

export type TAccountOverworkBodyRequest = {
	dateStart: string;
	timeStart: string;
	dateEnd: string;
	timeEnd: string;
	description?: string;
};

export type TAccountOverworkRequest = {
	date_start: string;
	date_end: string;
	description?: string;
};

export type TAccountOverworkPayloadCreate = {
	data: TAccountOverworkBodyRequest;
};

export type TAccountOverworkPayloadUpdate = {
	id: number;
	data: TAccountOverworkBodyRequest;
};

export function adaptAccountOverworkFromListResponse(data: TAccountOverworkListResponse): TAccountOverworkList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountOverworkFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountOverworkFromResponse(data: TAccountOverworkResponse): TAccountOverwork {
	const {
		id,
		date_start: dateStart,
		date_end: dateEnd,
		description: descriptionRaw,
		value = 0,
		time = '',
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		dateStart,
		dateEnd,
		description,
		value,
		time,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,
	};
}

export function adaptAccountOverworkToRequest(data: TAccountOverworkBodyRequest): TAccountOverworkRequest {
	const {
		dateStart,
		timeStart,
		dateEnd,
		timeEnd,
		description,
	} = data;

	return {
		date_start: `${dateStart}T${timeStart}:00.000Z`,
		date_end: `${dateEnd}T${timeEnd}:00.000Z`,
		description,
	};
}
