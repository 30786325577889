import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const ExpensesTypesSourceSortCol = {
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TExpensesTypesSourceSortCol = TTableSortCols<typeof ExpensesTypesSourceSortCol>;

export type TExpenseTypeSourceQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TExpensesTypesSourceSortCol;
};

export type TExpensesTypesSourceQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptExpensesTypesSourceQueryToRequest(data?: TExpenseTypeSourceQuery): TExpensesTypesSourceQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TExpenseTypeSource = {
  id: number;
  name: string;
  description: string;
};

export type TExpenseTypeSourceResponse = {
  id: number;
  name: string;
  description?: string;
};

export type TExpenseTypeSourceListResponse = TListResponse<TExpenseTypeSourceResponse>;

export type TExpenseTypeSourceList = TListResponse<TExpenseTypeSource>;

export function adaptExpenseTypeSourceFromListResponse(data: TExpenseTypeSourceListResponse): TExpenseTypeSourceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptExpenseTypeSourceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptExpenseTypeSourceFromResponse(data: TExpenseTypeSourceResponse): TExpenseTypeSource {
	const {
		id,
		name,
		description = '',
	} = data;

	return {
		id,
		name,
		description,
	};
}
