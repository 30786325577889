import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TProductionCalendarDatesOff, adaptProductionCalendarDatesOffFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PRODUCTION_CALENDARS } from '../../backend-paths';


export const productionCalendarDatesOffDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_PRODUCTION_CALENDARS}/:id/dates-off`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_PRODUCTION_CALENDARS}/${payload}/dates-off`;

		return HttpService.delete(path).then(result => {
			return adaptProductionCalendarDatesOffFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProductionCalendarDatesOff> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const productionCalendarDatesOffDeleteSlice = createSlice({
	name: 'productionCalendarDatesOffDeleteSlice',
	initialState,
	reducers: {
		clearProductionCalendarDatesOffDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[productionCalendarDatesOffDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[productionCalendarDatesOffDeleteApi.fulfilled.type]: (state, action: PayloadAction<TProductionCalendarDatesOff>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[productionCalendarDatesOffDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProductionCalendarDatesOffDeleteSelector = () => useAppSelector(state => state[productionCalendarDatesOffDeleteSlice.name]);

export const { clearProductionCalendarDatesOffDelete } = productionCalendarDatesOffDeleteSlice.actions;
