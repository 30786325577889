import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TAccountTaxationData, TAccountTaxationDataResponse, adaptAccountTaxationDataFromResponse } from '../../types';
import { BACKEND_ACCOUNT_TAXATION_DATA } from '../../backend-paths';


export const accountTaxationDataReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_TAXATION_DATA}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountTaxationDataResponse>(BACKEND_ACCOUNT_TAXATION_DATA).then(result => {
			return adaptAccountTaxationDataFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTaxationData> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTaxationDataReadSlice = createSlice({
	name: 'accountTaxationDataReadSlice',
	initialState,
	reducers: {
		setAccountTaxationDataRead(state, action: PayloadAction<TAccountTaxationData>) {
			state.item = action.payload;
		},

		clearAccountTaxationDataRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTaxationDataReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountTaxationDataReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxationData>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTaxationDataReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountTaxationDataReadSelector = () => useAppSelector(state => state[accountTaxationDataReadSlice.name]);

export const { clearAccountTaxationDataRead, setAccountTaxationDataRead } = accountTaxationDataReadSlice.actions;
