import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TEquipmentCategory, TEquipmentCategoryResponse, adaptEquipmentCategoryFromResponse } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentCategoryReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_EQUIPMENTS_CATEGORIES}/${payload}`;

		return HttpService.get<TEquipmentCategoryResponse>(path).then(result => {
			return adaptEquipmentCategoryFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentCategory> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentCategoryReadSlice = createSlice({
	name: 'equipmentCategoryReadSlice',
	initialState,
	reducers: {
		setEquipmentCategoryRead(state, action: PayloadAction<TEquipmentCategory>) {
			state.item = action.payload;
		},

		clearEquipmentCategoryRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentCategoryReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentCategoryReadGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategory>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentCategoryReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentCategoryReadSelector = () => useAppSelector(state => state[equipmentCategoryReadSlice.name]);

export const { clearEquipmentCategoryRead, setEquipmentCategoryRead } = equipmentCategoryReadSlice.actions;
