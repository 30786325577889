import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TProjectOrderGroupShift, TProjectOrderGroupShiftPayload, TProjectOrderGroupShiftResponse, adaptProjectOrderGroupShiftFromResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectOrderGroupShiftReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_PROJECTS}/:projectId/orders/:orderId/groups/:groupId/shifts/:id`,
	(payload: TProjectOrderGroupShiftPayload, thunkApi) => {
		const { id, groupId, orderId, projectId } = payload;
		const path = `${BACKEND_PROJECTS}/${projectId}/orders/${orderId}/groups/${groupId}/shifts/${id}`;

		return HttpService.get<TProjectOrderGroupShiftResponse>(path).then(result => {
			return adaptProjectOrderGroupShiftFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProjectOrderGroupShift> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectOrderGroupShiftReadSlice = createSlice({
	name: 'projectOrderGroupShiftReadSlice',
	initialState,
	reducers: {
		setProjectOrderGroupShiftRead(state, action: PayloadAction<TProjectOrderGroupShift>) {
			state.item = action.payload;
		},

		clearProjectOrderGroupShiftRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectOrderGroupShiftReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectOrderGroupShiftReadGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectOrderGroupShift>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectOrderGroupShiftReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectOrderGroupShiftReadSelector = () => useAppSelector(state => state[projectOrderGroupShiftReadSlice.name]);

export const { clearProjectOrderGroupShiftRead, setProjectOrderGroupShiftRead } = projectOrderGroupShiftReadSlice.actions;
