import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TAccountPosition = {
  id: number;
  name: string;
  description?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TAccountPositionResponse = {
  id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export function adaptAccountPositionFromResponse(data: TAccountPositionResponse): TAccountPosition {
	const {
		id,
		name,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

