import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TEquipmentGroup, TEquipmentGroupUpdate, TEquipmentGroupResponse, adaptEquipmentGroupFromResponse, adaptEquipmentGroupToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_EQUIPMENTS_GROUPS } from '../../backend-paths';


export const equipmentGroupUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/:id`,
	(payload: TEquipmentGroupUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_EQUIPMENTS_GROUPS}/${id}`;

		const dataRequest = adaptEquipmentGroupToRequest(data);

		return HttpService.put<TEquipmentGroupResponse>(path, dataRequest).then(result => {
			return adaptEquipmentGroupFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TEquipmentGroup> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const equipmentGroupUpdateSlice = createSlice({
	name: 'equipmentGroupUpdateSlice',
	initialState,
	reducers: {
		clearEquipmentGroupUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[equipmentGroupUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentGroupUpdateApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentGroup>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[equipmentGroupUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentGroupUpdateSelector = () => useAppSelector(state => state[equipmentGroupUpdateSlice.name]);

export const { clearEquipmentGroupUpdate } = equipmentGroupUpdateSlice.actions;
