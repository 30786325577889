// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsideItem_box__LcVmE {\n  box-sizing: border-box;\n  border-top: 1px solid transparent;\n  border-bottom: 1px solid transparent;\n  margin: 0.25em 0;\n  position: relative;\n}\n\n.AsideItem_active__axN-6 {\n  background-color: rgb(30, 30, 30);\n  border-top: 1px solid rgb(0, 60, 60);\n  border-bottom: 1px solid rgb(0, 60, 60);\n}", "",{"version":3,"sources":["webpack://./src/components/nav/aside-item/AsideItem.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,iCAAA;EACA,oCAAA;EACA,gBAAA;EACA,kBAAA;AACD;;AAEA;EACC,iCAAA;EACA,oCAAA;EACA,uCAAA;AACD","sourcesContent":[".box {\n\tbox-sizing: border-box;\n\tborder-top: 1px solid transparent;\n\tborder-bottom: 1px solid transparent;\n\tmargin: 0.25em 0;\n\tposition: relative;\n}\n\n.active {\n\tbackground-color: rgba(30, 30, 30, 1);\n\tborder-top: 1px solid rgba(0, 60, 60, 1);\n\tborder-bottom: 1px solid rgba(0, 60, 60, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AsideItem_box__LcVmE",
	"active": "AsideItem_active__axN-6"
};
export default ___CSS_LOADER_EXPORT___;
