import { TListResponse } from 'src/common/types';
import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse } from './user-meta.type';
import { TUserSalaryBookkeeping, TUserSalaryBookkeepingResponse, TUserShiftBookkeeping, TUserShiftBookkeepingResponse, adaptUserSalaryBookkeepingFromResponse, adaptUserShiftBookkeepingFromResponse } from './user-shift-bookkeeping.type';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { ShiftReportListStatus } from './account-shift.type';
import { TUserFinancialChangeBookkeeping, TUserFinancialChangeBookkeepingResponse, adaptUserFinancialChangeBookkeepingListFromResponse } from './user-financial-change-bookkeeping.type';
import { adaptUserExpenseBookkeepingListFromResponse, TUserExpenseBookkeeping, TUserExpenseBookkeepingResponse } from './user-expense-bookkeeping.type';
import { adaptUserOverworkBookkeepingListFromResponse, TUserOverworkBookkeeping, TUserOverworkBookkeepingResponse } from './user-overwork-bookkeeping.type';


export const UserBookkeepingSortCol = {
	NICKNAME: 'nickname',
	LAST_NAME: 'last_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',

	ID: 'id',
	PROJECT_NAME: 'project_name',
	ORDER_NAME: 'order_name',
	DATE: 'date',
	RATE: 'rate',
};

export type TUserBookkeepingSortCol = TTableSortCols<typeof UserBookkeepingSortCol>;
export type TUserShiftBookkeepingStatus = TTableSortCols<typeof ShiftReportListStatus>;

export type TUserBookkeepingQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUserBookkeepingSortCol;

	types: string,
	dateStart?: string;
	dateEnd?: string;
};

export type TUserBookkeepingQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;

	types?: string;
	date_start?: string;
	date_end?: string;
};

export function adaptUsersBookkeepingQueryToRequest(data?: TUserBookkeepingQuery): TUserBookkeepingQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		types,
		dateStart,
		dateEnd,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		types: types ? types : undefined,
		date_start: dateStart ? dateStart : undefined,
		date_end: dateEnd ? dateEnd : undefined,
		...restFields
	};
}

export type TUserBookkeepingListResponse = TListResponse<TUserBookkeepingResponse>;

export type TUserBookkeepingList = TListResponse<TUserBookkeeping>;

export type TUserBookkeeping = TUserMeta & {
  taxationName: string;
  salaries: TUserSalaryBookkeeping[];
  financialChanges: TUserFinancialChangeBookkeeping[];
  expenses: TUserExpenseBookkeeping[];
  overworks: TUserOverworkBookkeeping[];
  shifts: TUserShiftBookkeeping[];
};

export type TUserBookkeepingResponse = TUserMetaResponse & {
  taxation_name: string;
  salaries: TUserSalaryBookkeepingResponse[];
  financial_changes: TUserFinancialChangeBookkeepingResponse[];
  expenses: TUserExpenseBookkeepingResponse[];
  overworks: TUserOverworkBookkeepingResponse[];
  shifts: TUserShiftBookkeepingResponse[];
};

export function adaptUserBookkeepingFromListResponse(data: TUserBookkeepingListResponse): TUserBookkeepingList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserBookkeepingFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserBookkeepingUndefinedFromResponse(data: TUserBookkeepingResponse | undefined): TUserBookkeeping | undefined {
	if (!data) {
		return undefined;
	}

	return adaptUserBookkeepingFromResponse(data);
}

export function adaptUserBookkeepingFromResponse(data: TUserBookkeepingResponse): TUserBookkeeping {
	const {
		taxation_name: taxationName = '',
		salaries: salariesRaw = [],
		financial_changes: financialChangesRaw,
		expenses: expensesRaw,
		overworks: overworksRaw,
		shifts: shiftsRaw = [],
		...restFields
	} = data;

	const salaries = salariesRaw.map(item => adaptUserSalaryBookkeepingFromResponse(item));
	const financialChanges = adaptUserFinancialChangeBookkeepingListFromResponse(financialChangesRaw);
	const expenses = adaptUserExpenseBookkeepingListFromResponse(expensesRaw);
	const overworks = adaptUserOverworkBookkeepingListFromResponse(overworksRaw);
	const shifts = shiftsRaw.map(item => adaptUserShiftBookkeepingFromResponse(item));
	const meta = adaptUserMetaFromResponse(restFields);

	return {
		...meta,
		taxationName,
		salaries,
		financialChanges,
		expenses,
		overworks,
		shifts,
	};
}

