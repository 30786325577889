import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { clearSignOut, useAccountCredentialsSelector, useAppDispatch } from 'src/store';
import { LC_KEY_IS_AUTH, LC_KEY_IS_VISIBLE_ASIDE, LocalStorage } from 'src/toolkit';
import { PATH_SIGN_IN } from 'src/routes';

import { Aside } from './aside';
import { Nav } from './nav/Nav';

import styles from './NavMenu.module.scss';


type TProps = {
	children?: ReactNode;
};

export function NavMenu(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { credentials } = useAccountCredentialsSelector();

	const isAuth = LocalStorage.get(LC_KEY_IS_AUTH);

	useEffect(() => {
		if (isAuth && !credentials) {
			dispatch(clearSignOut());

			LocalStorage.removeFrom(LC_KEY_IS_AUTH);

			navigate(PATH_SIGN_IN);
		}
	}, [credentials, isAuth]);

	const isVisible = isAuth ? !!LocalStorage.get(LC_KEY_IS_VISIBLE_ASIDE) : false;

	const [state, setState] = useState(isVisible);

	const { children } = props;

	const pageWrapClassNames = [styles.pageWrap];
	const veilClassNames = [styles.veil];

	if (state) {
		pageWrapClassNames.push(styles.active);
		veilClassNames.push(styles.veilActive);
	}

	const toggle = () => {
		const bool = !state;

		setState(bool);
		LocalStorage.set(LC_KEY_IS_VISIBLE_ASIDE, bool);
	};

	const hideAside = () => {
		const bool = false;

		setState(bool);
		LocalStorage.set(LC_KEY_IS_VISIBLE_ASIDE, bool);
	};

	return (
		<>
			<Nav
				onClick={ toggle }
				active={ state }
				isVisibleButton={ !!credentials }
			/>

			<div className={ pageWrapClassNames.join(' ') }>
				{ credentials &&
					<Aside
						onClick={ hideAside }
						credentials={ credentials }
						isVisible={ state }
					/>
				}

				<div className={ styles.contentWrap }>
					{ children && children }
				</div>

				{ credentials &&
					<div
						className={ veilClassNames.join(' ') }
						onClick={ hideAside }
					></div>
				}
			</div>
		</>
	);
}
