import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ProjectsClientsSortCol = {
	ID: 'id',
	NAME: 'name',
	OFFICIAL_NAME: 'official_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsClientsSortCol = TTableSortCols<typeof ProjectsClientsSortCol>;

export type TProjectClientQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsClientsSortCol;
};

export type TProjectsClientsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptProjectsClientsQueryToRequest(data: TProjectClientQuery): TProjectsClientsQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectClient = {
  id: number;
  name: string;
  officialName?: string;
  inn?: string;
  description?: string;
  projectsDescription?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectClientResponse = {
  id: number;
  name: string;
  official_name?: string;
  inn?: string;
  description?: string;
	projects_description?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectClientListResponse = TListResponse<TProjectClientResponse>;

export type TProjectClientList = TListResponse<TProjectClient>;

export type TProjectClientBodyRequest = {
	name: string;
	officialName?: string;
	inn?: string;
	description?: string;
	projectsDescription?: string;
};

export type TProjectClientRequest = {
	name: string;
	official_name?: string;
	inn?: string;
	description?: string;
	projects_description?: string;
};

export type TProjectClientUpdate = {
	id: number;
	data: TProjectClientBodyRequest;
};

export function adaptProjectClientFromListResponse(data: TProjectClientListResponse): TProjectClientList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectClientFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectClientFromResponse(data: TProjectClientResponse): TProjectClient {
	const {
		id,
		name,
		official_name: officialName,
		inn,
		description,
		projects_description: projectsDescription,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		officialName,
		inn,
		description,
		projectsDescription,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptProjectClientToRequest(data: TProjectClientBodyRequest): TProjectClientRequest {
	const {
		name,
		officialName = undefined,
		inn = undefined,
		description = undefined,
		projectsDescription = undefined,
	} = data;

	return {
		name,
		official_name: officialName,
		inn,
		description: description,
		projects_description: projectsDescription,
	};
}
