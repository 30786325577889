import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './FormSelectBoxField.module.scss';


export type TFormSelectBoxFieldListOption = {
	value: string;
	text: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	disabled?: boolean;
};

export function FormSelectBoxFieldListItem(props: TProps) {
	const {
		children,
		disabled = false,
		...restProps
	} = props;

	const classNames = [styles.item];

	if (disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps } >
			{ children }
		</div>
	);
}
