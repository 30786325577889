import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ExpensesTypesPage = lazy(() => import('../views/list/ExpensesTypesPage'));

export function ExpensesTypesPageLazy() {
	return (
		<PageSuspense>
			<ExpensesTypesPage />
		</PageSuspense>
	);
}
