import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TProject, TProjectUpdate, TProjectResponse, adaptProjectFromResponse, adaptProjectToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_PROJECTS}/:id`,
	(payload: TProjectUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_PROJECTS}/${id}`;

		const dataRequest = adaptProjectToRequest(data);

		return HttpService.put<TProjectResponse>(path, dataRequest).then(result => {
			return adaptProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TProject> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const projectUpdateSlice = createSlice({
	name: 'projectUpdateSlice',
	initialState,
	reducers: {
		clearProjectUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[projectUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectUpdateApi.fulfilled.type]: (state, action: PayloadAction<TProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[projectUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectUpdateSelector = () => useAppSelector(state => state[projectUpdateSlice.name]);

export const { clearProjectUpdate } = projectUpdateSlice.actions;
