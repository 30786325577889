import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersExpensesTypesSortCol, TUserExpenseTypeList, TUserExpenseTypeListResponse, TUserExpenseTypeQuery, TUsersExpensesTypesSortCol, adaptUserExpenseTypeFromListResponse, adaptUsersExpensesTypesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_USERS_EXPENSES_TYPES } from '../../backend-paths';


export const usersExpensesTypesGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS_EXPENSES_TYPES,
	(payload: TUserExpenseTypeQuery | undefined, thunkApi) => {
		const params = adaptUsersExpensesTypesQueryToRequest(payload);

		return HttpService.get<TUserExpenseTypeListResponse>(BACKEND_ADMIN_USERS_EXPENSES_TYPES, { params }).then(result => {
			return adaptUserExpenseTypeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TUserExpenseTypeList;
	query: TUserExpenseTypeQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersExpensesTypesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersExpensesTypesSlice = createSlice({
	name: 'usersExpensesTypesSlice',
	initialState,
	reducers: {
		setUsersExpensesTypesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersExpensesTypesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersExpensesTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersExpensesTypesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersExpensesTypesQuerySortCol(state, action: PayloadAction<TUsersExpensesTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsersExpensesTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[usersExpensesTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersExpensesTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserExpenseTypeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersExpensesTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUsersExpensesTypesSelector = () => useAppSelector(state => state[usersExpensesTypesSlice.name]);

export const { clearUsersExpensesTypes, setUsersExpensesTypesQueryLimit, setUsersExpensesTypesQueryOffset, setUsersExpensesTypesQuerySort, setUsersExpensesTypesQuerySortCol, setUsersExpensesTypesQuerySearch } = usersExpensesTypesSlice.actions;
