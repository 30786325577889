import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const UsersShiftsPage = lazy(() => import('../views/list/UsersShiftsPage'));

export function UsersShiftsPageLazy() {
	return (
		<PageSuspense>
			<UsersShiftsPage />
		</PageSuspense>
	);
}
