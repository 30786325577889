import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountExpense, adaptAccountExpenseFromResponse } from 'src/store/types';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPENSES } from '../../backend-paths';


export const accountExpenseDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_EXPENSES}/:id`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_EXPENSES}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountExpenseFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountExpense> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExpenseDeleteSlice = createSlice({
	name: 'accountExpenseDeleteSlice',
	initialState,
	reducers: {
		clearAccountExpenseDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExpenseDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExpenseDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountExpense>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExpenseDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExpenseDeleteSelector = () => useAppSelector(state => state[accountExpenseDeleteSlice.name]);

export const { clearAccountExpenseDelete } = accountExpenseDeleteSlice.actions;
