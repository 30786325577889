type TLibBoolean = boolean | number | string | undefined | null;

export function libBoolean(value: TLibBoolean): boolean {
	if (typeof value === 'boolean') {
		return value;
	}

	if (typeof value === 'number') {
		return value > 0;
	}

	if (typeof value === 'string') {
		return parseInt(value) > 0 || value === 'true';
	}

	return !!value;
}
