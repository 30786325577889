import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserFinancialChange, TUserFinancialChangeUpdate, TUserFinancialChangeResponse, adaptUserFinancialChangeFromResponse, adaptUserFinancialChangeToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS_FINANCIAL_CHANGES } from '../../backend-paths';


export const userFinancialChangeUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/:id`,
	(payload: TUserFinancialChangeUpdate, thunkApi) => {
		const { id, data, files = [] } = payload;
		const path = `${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/${id}`;

		const dataRequest = adaptUserFinancialChangeToRequest(data);

		return HttpService.put<TUserFinancialChangeResponse>(path, dataRequest).then(result => {
			const { id } = result.data;
			const pathFiles = `${BACKEND_ADMIN_USERS_FINANCIAL_CHANGES}/${id}/files`;

			const filesDataRequest = { files };

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return HttpService.put<TUserFinancialChangeResponse>(pathFiles, filesDataRequest, config).then(result => {
				return adaptUserFinancialChangeFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUserFinancialChange> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userFinancialChangeUpdateSlice = createSlice({
	name: 'userFinancialChangeUpdateSlice',
	initialState,
	reducers: {
		clearUserFinancialChangeUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userFinancialChangeUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userFinancialChangeUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserFinancialChange>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userFinancialChangeUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserFinancialChangeUpdateSelector = () => useAppSelector(state => state[userFinancialChangeUpdateSlice.name]);

export const { clearUserFinancialChangeUpdate } = userFinancialChangeUpdateSlice.actions;
