import { TSystemSettingValueCommon, TSystemSettingValueCommonResponse } from './system-setting-value.type';
import { adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export type TSystemSettingBookkeepingBillChangesConstraint = TSystemSettingValueCommon & {
  value?: number;
};

export type TSystemSettingBookkeepingBillChangesConstraintResponse = TSystemSettingValueCommonResponse & {
  value?: number;
};

export type TSystemSettingBookkeepingBillChangesConstraintPayload = {
	value?: number;
};

export type TSystemSettingBookkeepingBillChangesConstraintRequest = {
	value?: number;
};

export function adaptSystemSettingBookkeepingBillChangesConstraintFromResponse(data: TSystemSettingBookkeepingBillChangesConstraintResponse): TSystemSettingBookkeepingBillChangesConstraint {
	const {
		public_id: publicId,
		name,
		value,
		description,
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		publicId,
		name,
		value,
		description,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}

export function adaptSystemSettingBookkeepingBillChangesConstraintToRequest(data: TSystemSettingBookkeepingBillChangesConstraintPayload): TSystemSettingBookkeepingBillChangesConstraintRequest {
	const { value = '' } = data;

	return {
		value: value ? value : undefined,
	};
}
