import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const ProjectClientUpdatePage = lazy(() => import('../views/update/ProjectClientUpdatePage'));

export function ProjectClientUpdatePageLazy() {
	return (
		<PageSuspense>
			<ProjectClientUpdatePage />
		</PageSuspense>
	);
}
