import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const UsersOverworksSortCol = {
	USER_NAME: 'user_name',
	DATE: 'date',
	VALUE: 'value',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TUsersOverworksSortCol = TTableSortCols<typeof UsersOverworksSortCol>;

export type TUsersOverworksQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersOverworksSortCol;
};

export type TUsersOverworksQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUsersOverworksQueryPayload = {
	data?: TUsersOverworksQuery;
	// userId?: number;
};

export function adaptUsersOverworksQueryToRequest(data?: TUsersOverworksQuery): TUsersOverworksQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TUserOverwork = {
	id: number;
	user: TUserMeta;
  dateStart: string;
  dateEnd: string;
	description?: string;
  value: number;
  time: string;
	createdAt?: string;
	updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TUserOverworkResponse = {
	id: number;
	user: TUserMetaResponse;
  date_start: string;
  date_end: string;
	description?: string;
  value: number;
  time: string;
	created_at?: string;
	updated_at?: string;

	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TUserOverworkListResponse = TListResponse<TUserOverworkResponse>;

export type TUserOverworkList = TListResponse<TUserOverwork>;

export type TUserOverworkBodyRequest = {
	userNickname: string;
	dateStart: string;
	timeStart: string;
	dateEnd: string;
	timeEnd: string;
	description?: string;
};

export type TUserOverworkRequest = {
	user_nickname: string;
	date_start: string;
	date_end: string;
	description?: string;
};

export type TUserOverworkPayloadCreate = {
	data: TUserOverworkBodyRequest;
};

export type TUserOverworkPayloadUpdate = {
	id: number;
	data: TUserOverworkBodyRequest;
};

export function adaptUserOverworkFromListResponse(data: TUserOverworkListResponse): TUserOverworkList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserOverworkFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserOverworkFromResponse(data: TUserOverworkResponse): TUserOverwork {
	const {
		id,
		user: userRaw,
		date_start: dateStart,
		date_end: dateEnd,
		description: descriptionRaw,
		value = 0,
		time = '',
		created_at: createdAt,
		updated_at: updatedAt,

		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const description = descriptionRaw ? descriptionRaw : '';
	const user = adaptUserMetaFromResponse(userRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		user,
		dateStart,
		dateEnd,
		description,
		value,
		time,
		createdAt,
		updatedAt,

		createdBy,
		updatedBy,
	};
}

export function adaptUserOverworkToRequest(data: TUserOverworkBodyRequest): TUserOverworkRequest {
	const {
		userNickname,
		dateStart,
		timeStart,
		dateEnd,
		timeEnd,
		description,
	} = data;

	return {
		user_nickname: userNickname,
		date_start: `${dateStart}T${timeStart}:00.000Z`,
		date_end: `${dateEnd}T${timeEnd}:00.000Z`,
		description,
	};
}
