import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS_TYPES } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserRole, TUserRolesList, adaptUserRolesListFromResponse } from '../../types';


export const userTypeRolesReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS_TYPES}/:id/roles`,
	(payload: number, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS_TYPES}/${payload}/roles`;

		return HttpService.get<TUserRolesList>(path).then(result => {
			return adaptUserRolesListFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	items: TUserRole[] | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	items: undefined,
	isLoading: false,
	error: undefined,
};

export const userTypeRolesReadSlice = createSlice({
	name: 'userTypeRolesReadSlice',
	initialState,
	reducers: {
		setUserTypeRolesRead(state, action: PayloadAction<TUserRolesList>) {
			const { items } = action.payload;

			state.items = items;
		},

		clearUserTypeRolesRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userTypeRolesReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userTypeRolesReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserRolesList>) => {
			const { items } = action.payload;

			state.items = items;
			state.isLoading = false;
		},

		[userTypeRolesReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserTypeRolesReadSelector = () => useAppSelector(state => state[userTypeRolesReadSlice.name]);

export const { clearUserTypeRolesRead, setUserTypeRolesRead } = userTypeRolesReadSlice.actions;
