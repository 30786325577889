import { TTagListItem } from './TagList';

import styles from './TagList.module.scss';


type TProps = {
	item: TTagListItem;
	onFocus: (item: TTagListItem) => void;
	onBlur: () => void;
};

export function TagListItem(props: TProps) {
	const { item, onFocus, onBlur } = props;
	const { name } = item;

	return (
		<div
			className={ styles.item }
			title="Смотреть описание тега"
			onFocus={ () => onFocus(item) }
			onBlur={ onBlur }
			tabIndex={ -1 }
		>{ name }</div>
	);
}
