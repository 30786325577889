import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserPositionChangeParams, TUserResponse, adaptUserFromResponse } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPositionChangeDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_USERS}/:nickname/positions/:id`,
	(payload: TUserPositionChangeParams, thunkApi) => {
		const { nickname, id } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/positions/${id}`;

		return HttpService.delete<TUserResponse>(path).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TUser> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPositionChangeDeleteSlice = createSlice({
	name: 'userPositionChangeDeleteSlice',
	initialState,
	reducers: {
		clearUserPositionChangeDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPositionChangeDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionChangeDeleteApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPositionChangeDeleteApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionChangeDeleteSelector = () => useAppSelector(state => state[userPositionChangeDeleteSlice.name]);

export const { clearUserPositionChangeDelete } = userPositionChangeDeleteSlice.actions;
