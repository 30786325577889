import { lazy } from 'react';

import { PageSuspense } from 'src/components';


const AccountInternationalPassportPage = lazy(() => import('../views/update/AccountInternationalPassportPage'));

export function AccountInternationalPassportPageLazy() {
	return (
		<PageSuspense>
			<AccountInternationalPassportPage />
		</PageSuspense>
	);
}
