import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '../../hooks';
import { HttpError, HttpService } from 'src/toolkit';


const enum BackendRecovery {
	EMAIL = '/auth/recovery/email',
	EMAIL_CONFIRM = '/auth/recovery/email/confirm',
	PHONE_NUMBER = '/auth/recovery/phone-number',
	PHONE_NUMBER_CONFIRM = '/auth/recovery/phone-number/confirm',
}

export const recoveryEmailApi = createAsyncThunk(
	BackendRecovery.EMAIL,
	(payload: string, thunkApi) => {
		const dataRequest = { email: payload };

		return HttpService.post(BackendRecovery.EMAIL, dataRequest).then(result => {
			return payload;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TRecoveryEmailConfirm = {
	hashcode: string;
	password: string;
};

export const recoveryEmailConfirmApi = createAsyncThunk(
	BackendRecovery.EMAIL_CONFIRM,
	(payload: TRecoveryEmailConfirm, thunkApi) => {
		return HttpService.post(BackendRecovery.EMAIL_CONFIRM, payload).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TRecoveryPhoneNumberRequest = {
	phone_number: string;
};

export const recoveryPhoneNumberApi = createAsyncThunk(
	BackendRecovery.PHONE_NUMBER,
	(payload: string, thunkApi) => {
		const dataRequest = toRequestPhoneNumber(payload);

		return HttpService.post(BackendRecovery.PHONE_NUMBER, dataRequest).then(result => {
			return payload;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toRequestPhoneNumber(data: string): TRecoveryPhoneNumberRequest {
	return {
		phone_number: data,
	};
}

type TRecoveryPhoneNumberConfirm = {
	phoneNumber: string;
	code: string;
	password: string;
};

type TRecoveryPhoneNumberConfirmRequest = {
	phone_number: string;
	code: string;
	password: string;
};

export const recoveryPhoneNumberConfirmApi = createAsyncThunk(
	BackendRecovery.PHONE_NUMBER_CONFIRM,
	(payload: TRecoveryPhoneNumberConfirm, thunkApi) => {
		const dataRequest = toRequestPhoneNumberConfirm(payload);

		return HttpService.post(BackendRecovery.PHONE_NUMBER_CONFIRM, dataRequest).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toRequestPhoneNumberConfirm(data: TRecoveryPhoneNumberConfirm): TRecoveryPhoneNumberConfirmRequest {
	const { phoneNumber, ...restFields } = data;

	return {
		phone_number: phoneNumber,
		...restFields
	};
}

type TRecoveryPageStageState = typeof RecoveryPageStageState[keyof typeof RecoveryPageStageState];

export const enum RecoveryPageStageState {
	MAIN = 0,
	PHONE_NUMBER_CONFIRM = 1,
	EMAIL_CONFIRM = 2,
	EMAIL_SUCCESS = 3,
	SUCCESS = 4,
}

type TInitialState = {
	phoneNumber: string;
	email: string;
	hashcode: string;
	stage: TRecoveryPageStageState;
	isLoading: boolean;
	error: HttpError | undefined,
};

const initialState: TInitialState = {
	phoneNumber: '',
	email: '',
	hashcode: '',
	stage: RecoveryPageStageState.MAIN,
	isLoading: false,
	error: undefined,
};

export const recoverySlice = createSlice({
	name: 'recoverySlice',
	initialState,
	reducers: {
		clearRecoveryAction(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[recoveryEmailApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[recoveryEmailApi.fulfilled.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false;
			state.email = action.payload;
			state.stage = RecoveryPageStageState.EMAIL_SUCCESS;
		},

		[recoveryEmailApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[recoveryEmailConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[recoveryEmailConfirmApi.fulfilled.type]: (state) => {
			state.isLoading = false;
			state.stage = RecoveryPageStageState.SUCCESS;
		},

		[recoveryEmailConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[recoveryPhoneNumberApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[recoveryPhoneNumberApi.fulfilled.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false;
			state.phoneNumber = action.payload;
			state.stage = RecoveryPageStageState.PHONE_NUMBER_CONFIRM;
		},

		[recoveryPhoneNumberApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[recoveryPhoneNumberConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[recoveryPhoneNumberConfirmApi.fulfilled.type]: (state) => {
			state.isLoading = false;
			state.stage = RecoveryPageStageState.SUCCESS;
		},

		[recoveryPhoneNumberConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

	},
});

export const useRecoverySelector = () => useAppSelector(state => state[recoverySlice.name]);

export const { clearRecoveryAction } = recoverySlice.actions;
