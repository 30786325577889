// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeadline_pageHeadline__kPVC4 {\n  color: rgb(200, 200, 200);\n  font-size: 1.5em;\n  margin: 0.5em auto;\n}\n\n.PageHeadline_margin__KsHJA {\n  margin: 0.5em auto 1.5em;\n}\n\n.PageHeadline_small__W7AU9 {\n  font-size: 1em;\n}\n\n.PageHeadline_center__s44EP {\n  text-align: center;\n}\n\n.PageHeadline_bold__JE0HA {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/page/page-headline/PageHeadline.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,gBAAA;EACA,kBAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".pageHeadline {\n\tcolor: rgba(200, 200, 200, 1);\n\tfont-size: 1.5em;\n\tmargin: 0.5em auto;\n}\n\n.margin {\n\tmargin: 0.5em auto 1.5em;\n}\n\n.small {\n\tfont-size: 1em;\n}\n\n.center {\n\ttext-align: center;\n}\n\n.bold {\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeadline": "PageHeadline_pageHeadline__kPVC4",
	"margin": "PageHeadline_margin__KsHJA",
	"small": "PageHeadline_small__W7AU9",
	"center": "PageHeadline_center__s44EP",
	"bold": "PageHeadline_bold__JE0HA"
};
export default ___CSS_LOADER_EXPORT___;
