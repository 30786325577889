import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { EquipmentGroupListItem } from './EquipmentGroupListItem';
import { TEquipmentGroupValue } from './EquipmentGroupList';

import styles from './EquipmentGroupList.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items?: TEquipmentGroupValue[];
};

export function EquipmentGroupListWrap(props: TProps) {
	const {
		items = [],
		placeholder,
		...restProps
	} = props;

	if (items.length <= 0) {
		return (
			<div className={ styles.empty } { ...restProps }>
				{ placeholder }
			</div>
		);
	}

	return (
		<div className={ styles.container } { ...restProps }>
			{
				items.map((item, index) => {
					const { group } = item;
					const { id: groupId } = group;

					return (
						<EquipmentGroupListItem
							key={ groupId }
							index={ index + 1 }
							item={ item }
						/>
					);
				})
			}
		</div>
	);
}
