import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TEquipmentGroupHistoryDataRelationEquipment, TEquipmentGroupHistoryDataRelationEquipmentResponse, adaptEquipmentGroupHistoryDataRelationEquipmentFromResponse } from './equipment-group-history-data-relation-equipment.type';


export const EquipmentsGroupsHistoryDataSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TEquipmentsGroupsHistoryDataSortCol = TTableSortCols<typeof EquipmentsGroupsHistoryDataSortCol>;

export type TEquipmentGroupHistoryDataQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TEquipmentsGroupsHistoryDataSortCol;
};

export type TEquipmentsGroupsHistoryDataQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptEquipmentsGroupsHistoryDataQueryToRequest(data?: TEquipmentGroupHistoryDataQuery): TEquipmentsGroupsHistoryDataQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TEquipmentGroupHistoryData = {
  id: number;
	name: string;
	description: string;
	amount: number;
	createdAt: string;
  updatedAt: string;

	equipmentsRelations: TEquipmentGroupHistoryDataRelationEquipment[];
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TEquipmentGroupHistoryDataResponse = {
  id: number;
	name: string;
	description: string;
	amount: number;
  created_at: string;
  updated_at: string;

	equipments_relations: TEquipmentGroupHistoryDataRelationEquipmentResponse[];
	created_by?: TUserMetaResponse;
	updated_by?: TUserMetaResponse;
};

export type TEquipmentGroupHistoryDataListResponse = TListResponse<TEquipmentGroupHistoryDataResponse>;

export type TEquipmentGroupHistoryDataList = TListResponse<TEquipmentGroupHistoryData>;

type TEquipmentDataPayload = {
	id?: number;
	amount: number;
	equipmentId: number;
};

type TEquipmentData = {
	id?: number;
	amount: number;
	equipment_id: number;
};

export type TEquipmentGroupHistoryDataBodyRequest = {
	equipments: TEquipmentDataPayload[];
};

export type TEquipmentGroupHistoryDataRequest = {
	equipments: TEquipmentData[];
};

export type TEquipmentGroupHistoryDataUpdate = {
	id: number;
	data: TEquipmentGroupHistoryDataBodyRequest;
};

export function adaptEquipmentGroupHistoryDataFromListResponse(data: TEquipmentGroupHistoryDataListResponse): TEquipmentGroupHistoryDataList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptEquipmentGroupHistoryDataFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptEquipmentGroupHistoryDataFromResponse(data: TEquipmentGroupHistoryDataResponse): TEquipmentGroupHistoryData {
	const {
		id,
		name,
		description,
		amount,
		equipments_relations: equipmentsRelationsRaw = [],
		created_at: createdAt,
		updated_at: updatedAt,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
	} = data;

	const equipmentsRelations = equipmentsRelationsRaw.map(item => adaptEquipmentGroupHistoryDataRelationEquipmentFromResponse(item));
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		amount,
		equipmentsRelations,
		createdAt,
		updatedAt,
		createdBy,
		updatedBy,
	};
}
