import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';


export const ScheduleEventsTypesSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TScheduleEventsTypesSortCol = TTableSortCols<typeof ScheduleEventsTypesSortCol>;

export type TScheduleEventTypeQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TScheduleEventsTypesSortCol;
};

export type TScheduleEventsTypesQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptScheduleEventsTypesQueryToRequest(data?: TScheduleEventTypeQuery): TScheduleEventsTypesQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TScheduleEventType = {
  id: number;
  name: string;
  description?: string;
  color?: string;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TScheduleEventTypeResponse = {
  id: number;
  name: string;
  description?: string;
  color?: string;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TScheduleEventTypeListResponse = TListResponse<TScheduleEventTypeResponse>;

export type TScheduleEventTypeList = TListResponse<TScheduleEventType>;

export type TScheduleEventTypeBodyRequest = {
	name: string;
	description?: string;
  color?: string;
};

export type TScheduleEventTypeRequest = {
	name: string;
	description?: string;
  color?: string;
};

export type TScheduleEventTypeUpdate = {
	id: number;
	data: TScheduleEventTypeBodyRequest;
};

export function adaptScheduleEventTypeFromListResponse(data: TScheduleEventTypeListResponse): TScheduleEventTypeList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptScheduleEventTypeFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptScheduleEventTypeFromResponse(data: TScheduleEventTypeResponse): TScheduleEventType {
	const {
		id,
		name,
		description,
		color,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		description,
		color,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptScheduleEventTypeToRequest(data: TScheduleEventTypeBodyRequest): TScheduleEventTypeRequest {
	const {
		name,
		description = undefined,
		color = undefined,
	} = data;

	return {
		name,
		description,
		color,
	};
}
