import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { UserPositionChangeSortCol, TUserPositionChangeList, TUserPositionChangeQuery, TUserPositionChangeListResponse, TUserPositionChangeSortCol, adaptUserPositionChangeFromListResponse, adaptUserPositionChangeQueryRequest, TUserPositionChangesPayload } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPositionChangesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/positions`,
	(payload: TUserPositionChangesPayload, thunkApi) => {
		const { nickname, query } = payload;
		const params = query ? adaptUserPositionChangeQueryRequest(query) : {};
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/positions`;

		return HttpService.get<TUserPositionChangeListResponse>(path, { params }).then(result => {
			return adaptUserPositionChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserPositionChangeList,
	query: TUserPositionChangeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: UserPositionChangeSortCol.CREATED_AT,
	},
};

export const usersPositionChangesSlice = createSlice({
	name: 'usersPositionChangesSlice',
	initialState,
	reducers: {
		setUserPositionChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUserPositionChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUserPositionChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUserPositionChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUserPositionChangesQuerySortCol(state, action: PayloadAction<TUserPositionChangeSortCol>) {
			state.query.sortCol = action.payload;
		},

		setUserPositionChangesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserPositionChangesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearUserPositionChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[userPositionChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPositionChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPositionChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[userPositionChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPositionChangeSelector = () => useAppSelector(state => state[usersPositionChangesSlice.name]);

export const { clearUserPositionChanges, setUserPositionChangesQueryLimit, setUserPositionChangesQueryOffset, setUserPositionChangesQuerySort, setUserPositionChangesQuerySortCol, setUserPositionChangesQuerySearch, setUserPositionChangesQueryDateStart, setUserPositionChangesQueryDateEnd } = usersPositionChangesSlice.actions;
