import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TSliceInitialState, useAppSelector } from '../../hooks';
import { TSystemSettingCalendarEventColorBirthday, TSystemSettingCalendarEventColorBirthdayResponse, adaptSystemSettingCalendarEventColorBirthdayFromResponse } from '../../types';
import { BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY } from '../../backend-paths';


export const systemSettingCalendarEventColorBirthdayReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY}`,
	(payload, thunkApi) => {
		const path = BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY;

		return HttpService.get<TSystemSettingCalendarEventColorBirthdayResponse>(path).then(result => {
			return adaptSystemSettingCalendarEventColorBirthdayFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TSystemSettingCalendarEventColorBirthday> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const systemSettingCalendarEventColorBirthdayReadSlice = createSlice({
	name: 'systemSettingCalendarEventColorBirthdayReadSlice',
	initialState,
	reducers: {
		setSystemSettingCalendarEventColorBirthdayRead(state, action: PayloadAction<TSystemSettingCalendarEventColorBirthday>) {
			state.item = action.payload;
		},

		clearSystemSettingCalendarEventColorBirthdayRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[systemSettingCalendarEventColorBirthdayReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[systemSettingCalendarEventColorBirthdayReadGetApi.fulfilled.type]: (state, action: PayloadAction<TSystemSettingCalendarEventColorBirthday>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[systemSettingCalendarEventColorBirthdayReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSystemSettingCalendarEventColorBirthdayReadSelector = () => useAppSelector(state => state[systemSettingCalendarEventColorBirthdayReadSlice.name]);

export const { clearSystemSettingCalendarEventColorBirthdayRead, setSystemSettingCalendarEventColorBirthdayRead } = systemSettingCalendarEventColorBirthdayReadSlice.actions;
