import Axios, { isAxiosError, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';


export enum HTTP_STATUS {
	OK = 200,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	REQUEST_TIMEOUT = 408,
	TOO_MANY_REQUESTS = 429,
	INTERNAL_SERVER_ERROR = 500,
};

export type HttpResponse<T> = {
	data: T;
	status: number;
	statusText: string;
	request: XMLHttpRequest;
	error: HttpError | undefined;
};

type TFieldError = {
	field_name: string;
	message: string;
};

type TDataError = {
	message: string;
	validation?: TFieldError[];
};

type THttpError = {
	status: number;
	text: string;
	data?: TDataError;
};

export class HttpError implements THttpError {
	public status: number;
	public text: string;
	public data?: TDataError;

	constructor (status: number, text: string, data: TDataError | undefined) {
		this.status = status;
		this.text = text;
		this.data = data;
	}
};

type TAny = unknown;

type RequestConfig = InternalAxiosRequestConfig & {
	metadata?: {
		startDate?: Date;
		endDate?: Date;
	};
};

const defaultConfig = {
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: {
		'Content-type': 'application/json',
		// 'Referrer-Policy': 'no-referrer',
	},
	withCredentials: true,
};

const HttpService = Axios.create(defaultConfig);

HttpService.defaults.timeout = 5000;
HttpService.defaults.maxRedirects = 5;

HttpService.interceptors.request.use(interceptorBeforeRequest, interceptorBeforeRequestError);
HttpService.interceptors.response.use(interceptorBeforeResponse, interceptorBeforeResponseError);

export default HttpService;


const logger = {
	log: (error: Error | TAny) => {
		console.log(error); // eslint-disable-line no-console
	},

	warn: (error: Error | TAny) => {
		console.warn(error); // eslint-disable-line no-console
	},

	error: (error: Error | TAny) => {
		console.error(error); // eslint-disable-line no-console
	},
};


function interceptorBeforeRequest(config: RequestConfig): RequestConfig {
	config.metadata = { ...config.metadata, startDate: new Date() };

	return config;
}

function interceptorBeforeRequestError(error: AxiosError | Error): Promise<HttpError> {
	const httpError = getErrorStack(error);

	logger.error(httpError);

	return Promise.reject(httpError);
}

function interceptorBeforeResponse(response: AxiosResponse): AxiosResponse {
	const config = response.config as RequestConfig;

	const date = new Date();
	config.metadata = { ...config.metadata, endDate: new Date() };
	const { startDate = date, endDate = date } = config.metadata;
	const { method = '', url } = config;
	const duration = endDate.getTime() - startDate.getTime();

	logger.log(`${method.toUpperCase()} ${url} ${duration}ms`);

	return response;
}

function interceptorBeforeResponseError(error: AxiosError | Error): Promise<HttpError> {
	const httpError = getErrorStack(error);

	logger.error(httpError);

	return Promise.reject(httpError);
}

function getErrorStack(error: AxiosError | Error): HttpError {
	if (isAxiosError(error)) {
		const { message, response } = error;

		if (response) {
			const { status, statusText: text, data } = response;

			return new HttpError(status, text, data);
		}

		return new HttpError(HTTP_STATUS.INTERNAL_SERVER_ERROR, message, undefined);
	}

	return new HttpError(HTTP_STATUS.INTERNAL_SERVER_ERROR, 'INTERN', undefined);
}
