import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './TextBlock.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
};

export function TextBlock(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		children,
		...restProps
	} = props;

	const classNames = [styles.text, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>{ children }</div>
	);
}
