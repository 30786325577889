import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { EquipmentsCategoriesSortCol, TEquipmentCategoryList, TEquipmentCategoryListResponse, TEquipmentCategoryQuery, TEquipmentsCategoriesSortCol, adaptEquipmentCategoryFromListResponse, adaptEquipmentsCategoriesQueryToRequest } from '../../types';
import { BACKEND_ADMIN_EQUIPMENTS_CATEGORIES } from '../../backend-paths';


export const equipmentsCategoriesGetApi = createAsyncThunk(
	BACKEND_ADMIN_EQUIPMENTS_CATEGORIES,
	(payload: TEquipmentCategoryQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsCategoriesQueryToRequest(payload);

		return HttpService.get<TEquipmentCategoryListResponse>(BACKEND_ADMIN_EQUIPMENTS_CATEGORIES, { params }).then(result => {
			return adaptEquipmentCategoryFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentCategoryList;
	query: TEquipmentCategoryQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: EquipmentsCategoriesSortCol.CREATED_AT,
	},
	error: undefined,
};

export const equipmentsCategoriesSlice = createSlice({
	name: 'equipmentsCategoriesSlice',
	initialState,
	reducers: {
		setEquipmentsCategoriesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setEquipmentsCategoriesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setEquipmentsCategoriesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setEquipmentsCategoriesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setEquipmentsCategoriesQuerySortCol(state, action: PayloadAction<TEquipmentsCategoriesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearEquipmentsCategories(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[equipmentsCategoriesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[equipmentsCategoriesGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentCategoryList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[equipmentsCategoriesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useEquipmentsCategoriesSelector = () => useAppSelector(state => state[equipmentsCategoriesSlice.name]);

export const { clearEquipmentsCategories, setEquipmentsCategoriesQueryLimit, setEquipmentsCategoriesQueryOffset, setEquipmentsCategoriesQuerySort, setEquipmentsCategoriesQuerySortCol, setEquipmentsCategoriesQuerySearch } = equipmentsCategoriesSlice.actions;
