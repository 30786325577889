import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { UserCategoryChangeSortCol, TUserCategoryChangeList, TUserCategoryChangeQuery, TUserCategoryChangeListResponse, TUserCategoryChangeSortCol, adaptUserCategoryChangeFromListResponse, adaptUserCategoryChangeQueryRequest, TUserCategoryChangesPayload } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCategoryChangesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:user_nickname/categories`,
	(payload: TUserCategoryChangesPayload, thunkApi) => {
		const { nickname, query } = payload;
		const params = query ? adaptUserCategoryChangeQueryRequest(query) : {};
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/categories`;

		return HttpService.get<TUserCategoryChangeListResponse>(path, { params }).then(result => {
			return adaptUserCategoryChangeFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list: TUserCategoryChangeList,
	query: TUserCategoryChangeQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		sort: TableSort.DESC,
		sortCol: UserCategoryChangeSortCol.CREATED_AT,
	},
};

export const usersCategoryChangesSlice = createSlice({
	name: 'usersCategoryChangesSlice',
	initialState,
	reducers: {
		setUserCategoryChangesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUserCategoryChangesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUserCategoryChangesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUserCategoryChangesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUserCategoryChangesQuerySortCol(state, action: PayloadAction<TUserCategoryChangeSortCol>) {
			state.query.sortCol = action.payload;
		},

		setUserCategoryChangesQueryDateStart(state, action: PayloadAction<string>) {
			state.query.dateStart = action.payload;
		},

		setUserCategoryChangesQueryDateEnd(state, action: PayloadAction<string>) {
			state.query.dateEnd = action.payload;
		},

		clearUserCategoryChanges(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[userCategoryChangesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userCategoryChangesGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCategoryChangeList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[userCategoryChangesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserCategoryChangeSelector = () => useAppSelector(state => state[usersCategoryChangesSlice.name]);

export const { clearUserCategoryChanges, setUserCategoryChangesQueryLimit, setUserCategoryChangesQueryOffset, setUserCategoryChangesQuerySort, setUserCategoryChangesQuerySortCol, setUserCategoryChangesQuerySearch, setUserCategoryChangesQueryDateStart, setUserCategoryChangesQueryDateEnd } = usersCategoryChangesSlice.actions;
