import { SYSTEM_SETTINGS } from "src/routes";

export const BACKEND_PUBLIC_CVS = '/cv';

export const BACKEND_ACCOUNT = '/account';
export const BACKEND_ACCOUNT_CREDENTIALS = '/account/credentials';
export const BACKEND_ACCOUNT_PHONE_NUMBER = '/account/phone-number';
export const BACKEND_ACCOUNT_PHONE_NUMBER_CONFIRM = '/account/phone-number/confirm';

export const BACKEND_ACCOUNT_PASSPORT = '/account/passport';
export const BACKEND_ACCOUNT_INTERNATIONAL_PASSPORT = '/account/international-passport';
export const BACKEND_ACCOUNT_DATA = '/account/data';
export const BACKEND_ACCOUNT_WORK_DATA = '/account/work-data';
export const BACKEND_ACCOUNT_TYPE = `${BACKEND_ACCOUNT}/type`;
export const BACKEND_ACCOUNT_CATEGORY = `${BACKEND_ACCOUNT}/category`;
export const BACKEND_ACCOUNT_POSITION = `${BACKEND_ACCOUNT}/position`;
export const BACKEND_ACCOUNT_DEPARTMENT = `${BACKEND_ACCOUNT}/department`;
export const BACKEND_ACCOUNT_SALARY = `${BACKEND_ACCOUNT}/salary`;
export const BACKEND_ACCOUNT_SALARY_HISTORY = `${BACKEND_ACCOUNT_SALARY}/history`;
export const BACKEND_ACCOUNT_TYPE_CHANGES = `${BACKEND_ACCOUNT_TYPE}/changes`;
export const BACKEND_ACCOUNT_CATEGORY_CHANGES = `${BACKEND_ACCOUNT_CATEGORY}/changes`;
export const BACKEND_ACCOUNT_POSITION_CHANGES = `${BACKEND_ACCOUNT_POSITION}/changes`;
export const BACKEND_ACCOUNT_DEPARTMENT_CHANGES = `${BACKEND_ACCOUNT_DEPARTMENT}/changes`;
export const BACKEND_ACCOUNT_TAXATION_DATA = '/account/taxation-data';
export const BACKEND_ACCOUNT_REQUISITES = '/account/requisites';
export const BACKEND_ACCOUNT_BOOKKEEPING = '/account/bookkeeping';
export const BACKEND_ACCOUNT_EXPENSES = '/account/expenses';
export const BACKEND_ACCOUNT_HOUR_SALARY = '/account/hour-salary';
export const BACKEND_ACCOUNT_OVERWORKS = '/account/overworks';
export const BACKEND_ACCOUNT_TAXATION_RECEIPTS = '/account/taxation-receipts';
export const BACKEND_ACCOUNT_CARD = '/account/card';

export const BACKEND_ACCOUNT_SHIFTS = '/account/shifts';
export const BACKEND_ACCOUNT_SHIFT_EXPENSE = '/account/shift-expenses';

export const BACKEND_ACCOUNT_EMAIL = '/account/email';
export const BACKEND_ACCOUNT_EMAIL_CONFIRM = '/account/email/confirm';

export const BACKEND_ACCOUNT_SOURCE_TAXATIONS = '/account/source/taxations';

export const BACKEND_ADMIN_BOOKKEEPING = '/admin/bookkeeping';

export const BACKEND_SIGN_IN = '/auth/sign-in';

export const BACKEND_SIGN_OUT = '/auth/sign-out';

export const BACKEND_ADMIN_USERS = '/admin/users';
export const BACKEND_ADMIN_USERS_CARDS = '/admin/users-cards';
export const BACKEND_SOURCE_USERS = '/admin/source/users';
export const BACKEND_SOURCE_PROJECTS_ORDERS_GROUPS_SHIFTS_RATES_TYPES = '/admin/source/rates-types';
export const BACKEND_SOURCE_PROJECTS = '/admin/source/projects';
export const BACKEND_SOURCE_USER_POSITION = '/admin/source/users-positions';
export const BACKEND_SOURCE_USER_DEPARTMENT = '/admin/source/users-departments';
export const BACKEND_SOURCE_SCHEDULE_EVENT_TYPE = '/admin/source/schedule-events-types';
export const BACKEND_SOURCE_EQUIPMENTS_CATEGORIES = '/admin/source/equipments-categories';
export const BACKEND_SOURCE_EQUIPMENTS_STORAGES = '/admin/source/equipments-storages';
export const BACKEND_SOURCE_EQUIPMENTS = '/admin/source/equipments';
export const BACKEND_SOURCE_EQUIPMENTS_GROUPS = '/admin/source/equipments-groups';
export const BACKEND_SOURCE_EQUIPMENTS_ORDERS_STATUSES = '/admin/source/equipments-orders-statuses';
export const BACKEND_SOURCE_EXPENSES_TYPES = '/admin/source/expenses-types';
export const BACKEND_SOURCE_GROUPS_SHIFTS_EXPENSES_TYPES = '/admin/source/groups-shifts-expenses-types';
export const BACKEND_SOURCE_GROUPS_SHIFTS_REPORTS_TAGS_TYPES = '/admin/source/groups-shifts-reports-tags-types';
export const BACKEND_SOURCE_FINANCIAL_CHANGES_TYPES = '/admin/source/financial-changes-types';
export const BACKEND_SOURCE_USERS_EXPENSES_TYPES = '/admin/source/users-expenses-types';
export const BACKEND_SOURCE_PAYMENTS_TYPES = '/admin/source/payments-types';
export const BACKEND_SOURCE_PROJECTS_STATUSES = '/admin/source/projects-statuses';
export const BACKEND_SOURCE_PROJECTS_ORDERS_TAGS_TYPES = '/admin/source/projects-orders-tags-types';

export const BACKEND_SOURCE_USER_CATEGORY = '/admin/source/users-categories';
export const BACKEND_USER_DAY_CATEGORY = 'day-category';

export const BACKEND_ADMIN_USERS_TYPES = '/admin/users-types';
export const BACKEND_ADMIN_USERS_POSITIONS = '/admin/users-positions';
export const BACKEND_ADMIN_USERS_CATEGORIES = '/admin/users-categories';
export const BACKEND_ADMIN_USERS_DEPARTMENTS = '/admin/users-departments';
export const BACKEND_ADMIN_USERS_TAXATIONS = '/admin/users-taxations';

export const BACKEND_ADMIN_USERS_SHIFTS = '/admin/users-shifts';

export const BACKEND_ADMIN_PAYMENTS_TYPES = '/admin/payments-types';
export const BACKEND_ADMIN_EXPENSES_TYPES = '/admin/expenses-types';
export const BACKEND_ADMIN_WORK_AREAS = '/admin/work-areas';
export const BACKEND_ADMIN_FINANCIAL_CHANGES_TYPES = '/admin/financial-changes-types';
export const BACKEND_SOURCE_WORK_AREAS = '/admin/source/work-areas';

export const BACKEND_ADMIN_USERS_FINANCIAL_CHANGES = '/admin/users-financial-changes';

export const BACKEND_ADMIN_PROJECTS_STATUSES = '/admin/projects-statuses';
export const BACKEND_PROJECTS = '/projects';
export const BACKEND_PROJECTS_CLIENTS = '/clients';
export const BACKEND_SOURCE_PROJECTS_CLIENTS = '/admin/source/projects-clients';

export const BACKEND_ADMIN_PROJECTS_ORDERS_TAGS_TYPES = '/admin/projects-orders-tags-types';

export const BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS = '/admin/projects-orders-groups-shifts';
export const BACKEND_PROJECTS_ORDERS_GROUPS_SHIFTS_EXPENSES = '/admin/project-order-group-shift-expenses';

export const BACKEND_PRODUCTION_CALENDARS = '/admin/production-calendars';

export const BACKEND_SCHEDULE = '/schedule';
export const BACKEND_ADMIN_SCHEDULE_EVENTS_TYPES = '/admin/schedule-events-types';
export const BACKEND_ADMIN_SCHEDULE_EVENTS = '/admin/schedule-events';

export const BACKEND_ARTICLES = '/admin/articles';
export const BACKEND_ARTICLES_PUBLIC = '/articles';

export const BACKEND_ADMIN_EQUIPMENTS_STORAGES = '/admin/equipments-storages';
export const BACKEND_ADMIN_EQUIPMENTS_CATEGORIES = '/admin/equipments-categories';
export const BACKEND_ADMIN_EQUIPMENTS = '/admin/equipments';
export const BACKEND_ADMIN_EQUIPMENTS_GROUPS = '/admin/equipments-groups';
export const BACKEND_ADMIN_EQUIPMENTS_ORDERS_STATUSES = '/admin/equipments-orders-statuses';
export const BACKEND_ADMIN_EQUIPMENTS_ORDERS = '/admin/equipments-orders';

export const BACKEND_ADMIN_GROUPS_SHIFTS_EXPENSES_TYPES = '/admin/groups-shifts-expenses-types';
export const BACKEND_ADMIN_GROUPS_SHIFTS_REPORTS_TAGS_TYPES = '/admin/groups-shifts-reports-tags-types';

export const BACKEND_ADMIN_USERS_EXPENSES_TYPES = '/admin/users-expenses-types';
export const BACKEND_USERS_EXPENSES = '/admin/users-expenses';
export const BACKEND_USERS_OVERWORKS = '/admin/users-overworks';
export const BACKEND_USERS_TAXATIONS_RECEIPTS = '/admin/users-taxations-receipts';

export const BACKEND_ADMIN_SYSTEM_SETTINGS = '/admin/system-settings';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_TYPES = '/admin/system-settings-types';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_USERS = '/admin/system-settings-users';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_USERS_ACCOUNT_USER_TYPE = `${BACKEND_ADMIN_SYSTEM_SETTINGS_USERS}/${SYSTEM_SETTINGS.USERS_ACCOUNT_USER_TYPE}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS = '/admin/system-settings-shifts';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES = `${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS}/${SYSTEM_SETTINGS.SHIFTS_IS_NOTICE_USERS_BY_SHIFT_CHANGES}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS_NOT_COUNTING_OVERWORK_TIME = `${BACKEND_ADMIN_SYSTEM_SETTINGS_SHIFTS}/${SYSTEM_SETTINGS.SHIFTS_NOT_COUNTING_OVERWORK_TIME}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS = '/admin/system-settings-reports';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_PHOTO_NOT_ATTACHING_PENALTY = `${BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS}/${SYSTEM_SETTINGS.REPORTS_PHOTO_NOT_ATTACHING_PENALTY}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS_WRITING_DELAY_PENALTY = `${BACKEND_ADMIN_SYSTEM_SETTINGS_REPORTS}/${SYSTEM_SETTINGS.REPORTS_WRITING_DELAY_PENALTY}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING = '/admin/system-settings-bookkeeping';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_BILL_CHANGES_CONSTRAINT = `${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING}/${SYSTEM_SETTINGS.BOOKKEEPING_BILL_CHANGES_CONSTRAINT}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING_WORK_DAY_HOURS_COUNT = `${BACKEND_ADMIN_SYSTEM_SETTINGS_BOOKKEEPING}/${SYSTEM_SETTINGS.BOOKKEEPING_WORK_DAY_HOURS_COUNT}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR = '/admin/system-settings-calendar';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_BIRTHDAY = `${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR}/${SYSTEM_SETTINGS.CALENDAR_EVENT_COLOR_BIRTHDAY}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR_EVENT_COLOR_PROJECT = `${BACKEND_ADMIN_SYSTEM_SETTINGS_CALENDAR}/${SYSTEM_SETTINGS.CALENDAR_EVENT_COLOR_PROJECT}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM = '/admin/system-settings-amocrm';
export const BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_ID = `${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM}/${SYSTEM_SETTINGS.AMOCRM_CLIENT_ID}`;
export const BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM_CLIENT_SECRET = `${BACKEND_ADMIN_SYSTEM_SETTINGS_AMOCRM}/${SYSTEM_SETTINGS.AMOCRM_CLIENT_SECRET}`;

export const BACKEND_ADMIN_SOURCE_SYSTEM_SETTINGS_CALENDAR_EVENT_COLORS = '/admin-source/system-settings-calendar-event-colors';
