import { useState } from 'react';

import { FormField, TFormInputFieldReadonlyFontColor } from 'src/components';

import { ImageListValue, ImageModal } from './components';


export type TImageValue = {
	path: string;
	name: string;
	fontColor?: TFormInputFieldReadonlyFontColor;
};

type TProps = {
	legend?: string;
	items?: TImageValue[];

	baseUrl?: string;
};

export function ImageList(props: TProps) {
	const {
		legend = '',
		items = [],
		baseUrl = '',
	} = props;

	const [image, setImage] = useState<TImageValue>();

	const show = (path: string, name: string) => {
		const item = { path, name };

		setImage(item);
	};

	const hide = () => {
		setImage(undefined);
	};

	return (
		<FormField
			legend={ legend }
		>
			{ items.length > 0 &&
				<ImageListValue
					show={ show }
					files={ items }
					baseUrl={ baseUrl }
				/>
			}

			{ image && <ImageModal src={ image.path } alt={ image.name } hide={ hide } /> }
		</FormField>
	);
}
