import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountTaxationReceipt, TAccountTaxationReceiptPayload, TAccountTaxationReceiptResponse, adaptAccountTaxationReceiptFromResponse, adaptAccountTaxationReceiptToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_TAXATION_RECEIPTS } from '../../backend-paths';


export const accountTaxationReceiptCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_TAXATION_RECEIPTS}`,
	(payload: TAccountTaxationReceiptPayload, thunkApi) => {
		const dataRequest = adaptAccountTaxationReceiptToRequest(payload);

		return HttpService.post<TAccountTaxationReceiptResponse>(BACKEND_ACCOUNT_TAXATION_RECEIPTS, dataRequest).then(result => {
			return adaptAccountTaxationReceiptFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TAccountTaxationReceipt> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountTaxationReceiptCreateSlice = createSlice({
	name: 'accountTaxationReceiptCreateSlice',
	initialState,
	reducers: {
		clearAccountTaxationReceiptCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountTaxationReceiptCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountTaxationReceiptCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountTaxationReceipt>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountTaxationReceiptCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountTaxationReceiptCreateSelector = () => useAppSelector(state => state[accountTaxationReceiptCreateSlice.name]);

export const { clearAccountTaxationReceiptCreate } = accountTaxationReceiptCreateSlice.actions;
